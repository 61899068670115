import { combineReducers } from "redux";
//Reducers
import DashboardReducer from "./dashboard";
import ApplicationReducer from "./app-root";
import AllProjectsReducer from "./all-projects";
import ProcessDesigner from "./process-designer";
import ProjectDetails from "./project-detail";
import BusinessFunction from "./business-fucntion";
import PreviewAndPublish from "./preview-and-publish";
import Transport from "./app-transport";
import SettingsReducer from "./settings";
import UserTaskDetails from "./user-task";
import TaskManagementList from "./task-management-list";
import DatabaseDesigner from "./database-designer";
import SignInReducer from "./sign-in";
import DriveFolderTable from "./drivefoldertable";
import ProfileReducer from "./user-profile";
import ProjectSettingsReducer from "./project-settings";
import MastersReducer from "./masters-data";
import schedulerList from "./scheduler-list";
import databaseTablesList from "./Library/database-tables";
import libraryDrive from "./Library/drive";
import appDesigner from "./app-designer";
import jsBuilder from "./jsbuilder";
import AdminReducer from "./admin";
import IntegrationBuilder from "./integrationbuilder";
import AnalyticsRecucer from "./analytics";
import CreateProejctDetailsReducer from "./create-project-details";
import ContainerAppReducer from "./container-app-generation";
import Collaborator from "./collaborator";
import AllItemsReducer from "./all-items";
import AppPreviewReducer from "./app-preview";
import ModernizeReducer from "./modernizer";
import GlobalVariable from "./global-variable";
import Asset from "./assets";
import WorkflowVariable from "./workflow-variable";
import ServiceList from "./service-list";
import serviceList from "../containers/library/services/component/service-list";
import AllLoginsReducer from "./all-logins";
import signOutReducer from "./sign-out";

var reducers = combineReducers({
  admin: AdminReducer,
  allItems: AllItemsReducer,
  application: ApplicationReducer,
  dashboard: DashboardReducer,
  allprojects: AllProjectsReducer,
  alllogins: AllLoginsReducer,
  process: ProcessDesigner,
  project: ProjectDetails,
  businessFunction: BusinessFunction,
  settings: SettingsReducer,
  userTaskDetails: UserTaskDetails,
  taskManagementList: TaskManagementList,
  databaseDesigner: DatabaseDesigner,
  signIn: SignInReducer,
  drive: DriveFolderTable,
  master: MastersReducer,
  profile: ProfileReducer,
  projectSettings: ProjectSettingsReducer,
  scheduler: schedulerList,
  dbTables: databaseTablesList,
  libraryDrive: libraryDrive,
  appDesigner: appDesigner,
  jsBuilder: jsBuilder,
  IntegrationBuilder: IntegrationBuilder,
  analytics: AnalyticsRecucer,
  CreateProejctDetails: CreateProejctDetailsReducer,
  containerApp: ContainerAppReducer,
  collaborator: Collaborator,
  appPreview: AppPreviewReducer,
  previewAndPublish: PreviewAndPublish,
  transport: Transport,
  modernize: ModernizeReducer,
  globalVariable: GlobalVariable,
  workflowVariable: WorkflowVariable,
  service: ServiceList,
  assets: Asset,
  signOut: signOutReducer,
});

export default reducers;
