const messages = {
    title: "What’s your function about?",
    subtitle: "A business function consists of structured activities executed in a predetermined sequence by people (or systems) in their given roles. A combination of a business function and a role constitutes an application.",
    name: "Name",
    description: "Description",
    reqName: "Invalid Name",
    reqDesc: "Invalid Description",
    type1: "From Scratch",
    type2: "From SAP Abap Program",
    type1Desc: "For the innovators. Start from scratch and start inventing the future of your business.",
    type2Desc: "Leverage your investments in SAP. Modernize your ABAP customizations into cloud-native apps.",
    type1Button: "Start from scratch",
    type2Button: "Start to Modernize",
    scratchTypeTitle: "Would you like to build workflow apps?",
    scratchTypeDesc: "A workflow consists of an orchestrated and repeatable pattern of activities and tasks performed by various people and systems to execute a business function or a business process. A workflow app manages these activities and tasks such as automatic routing and automated processes between systems and people in various roles. Simple examples are Purchase order approvals, Expense approvals, Time capture, etc.",
    sapAbapTitle: "SAP Objects selected for modernization",
    createTitle: "Creating Business Function..."
};

export default messages;