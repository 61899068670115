import { getUrl, get, put } from "../../url"


export async function getPasswordPolicy() {
    let path = `${getUrl()}/configurator/v1.0/policy/detail`;
    const [error, data] = await get(path);
    return [error, data];
}

export async function updatePasswordPolicy(input) {
    let path = `${getUrl()}/configurator/v1.0/policy/update`;
    const [error, data] = await put(path, input);
    return [error, data];
}
