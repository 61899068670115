import Accordion from "./accordion/accordion";
import styles from "./integrationbuilder.module.css";
import React,{ useEffect, useState } from "react";
import CanvasPanel from "./canvas";
import Properties from "./properties/properties";
import ContextMenu from "./contextmenu/contextmenu";
import Test from "./test/generateTest";
import  items from "./properties/leftPanel";
import  menuItems from "./properties/contextMenu"
import { useAppContext } from "../../components/activity/AppContext";
import PopupMenu from "./contextmenu/popup";
import ActionPanel from "../../components/header/actionheader";
import { MappingColumnEvent, redoEvent, undoEvent } from "./resources/js/builderEvents";
import { useParams } from "react-router-dom";
import { IntegrationbuilderActions } from "./integrationbuilder.actions";
import SearchInput from "../../components/searchBar/search";
import MappingPopup from "./contextmenu/mappingPopup";
import SmallPopup from "../../components/layouts/smallPopup";
import { Messages } from "./resources/js/message";
import AddressBar from "./addressBar/addressBar";
import { Application } from "./resources/application/application";

function IntegrationBuilder(){
    const [parameter,setParameter] = useState({});
    const [popupDetails,setPopupDetails] = useState({});
    const [testInput,setTestInput] = useState({});
    const [showContextMenu,setShowContextMenu]=useState(false);
    const [showPopupMenu,setShowPopupMenu]=useState(false);
    const [showTest,setShowTest]=useState(false);
    const [menuSearch,setMenuSearch]=useState('');
    const [blockMenus,setBlockMenus]=useState(items);
    const [showMappingPopup,setShowMappingPopup]=useState(false);
    const [showMappingConformation,setShowMappingConformation]=useState(false);
    const [mappingConformation,setMappingConformation]=useState('');
    const [mappingData,setMappingData] = useState({});
    const [addressBarLinks,setAddressBarLinks] = useState([]);
    const app = useAppContext(); 

    const delay = ms => new Promise(res => setTimeout(res, ms));

    let [position,setPosition] = useState([0,0]);

    const { 
        serviceId: uuid,
        pid: projectName
    } = useParams();
    
    

    const links = [
        {name:"undo",image:"/images/header/nav_undo.svg"},
        {name:"redo",image:"/images/header/nav_redo.svg"},
        {name:"expand",image:"/images/header/nav_expand.svg"},
        {name:"open",image:"/images/header/nav_open.svg"},
        {name:"test",image:"/images/header/nav_play.svg", text:"Test"}
    ];
    
    const closeContextMenu =()=> {
        setShowContextMenu(false);
        setShowPopupMenu(false);
    }

    const closePopupMenu =()=> {
        setShowPopupMenu(false);
        setShowContextMenu(false);
    }

    const toggleContextMenu = async (events)=>{
        let left = events.clientX + 20;
        let top = events.clientY - 20;
        events.preventDefault();
        setPosition([top, left]);
        await delay(150);
        setShowContextMenu(true);
    }

    const togglePopupMenu = async (events, type, menuType, items, clicked)=>{
        console.log('--------- The togglePopupMenu data : events - ', events, ', type - ', type, ', menuType - ', menuType, ', items - ', items, ', clicked - ', clicked);
        let left = events.event.clientX + 20;
        let top = events.event.clientY - 20;
        events.event.preventDefault();
        setPosition([top, left]);
        setPopupDetails({items:items, clicked:clicked, type:type, event:events, menuType:menuType});
        await delay(150);
        setShowPopupMenu(true);
    }

    const toggleMappingPopup = (obj, type)=>{
        mapTableData(obj, type);
    }

    const mapTableData = (obj, type) => {
        if (type === 'map'){
            let tableMapping = {};
            for (let toItem of obj.toCol) {
                for (let setItem of obj.setCol){
                    if(obj.mappings && obj.mappings[toItem.name]){
                        tableMapping[toItem.name] = obj.mappings[toItem.name];
                        break;
                    } else if (setItem.name === toItem.name){
                        tableMapping[toItem.name] = {name:setItem.name, toCol:toItem, setCol:setItem };
                        break;
                    }
                }
                if(!tableMapping[toItem.name])
                    tableMapping[toItem.name] = {name:Messages.E004 };
            }
            obj.mappings = tableMapping;
            obj.mapType = type;
            console.log('-------- The mapping obj after the matching : ', obj, ', type ; ', type);
            setMappingData(obj);
            setShowMappingPopup(true);
        } else if (type === 'con') {
            setMappingData(obj);
            if (obj.setCol.length === 0)
                setMappingConformation(`Are you sure you want to copy from left ${obj.toTable.split('$')[2]} to right ${obj.setTable.split('$')[2]} ?`);
            else
                setMappingConformation(`Are you sure you want to copy from right ${obj.setTable.split('$')[2]} to left ${obj.toTable.split('$')[2]} ?`);
            setShowMappingConformation(true);
        }
        
    }

    const mappingConformationEvent = (obj)=>{
        MappingColumnEvent(obj, 'con');
        setShowMappingConformation(false);
    }


    const showNotifcation=(data)=>{
        app().notify(data);
    }

    const handleEvent = (action) => {
        switch(action){
            case "undo":
                new undoEvent().execute();
                break;
            case "redo":
                new redoEvent().execute();
                break;
            case "test":
                prepareTestInput();
                setShowTest(true);
                break;
            case "open":
                window.open(window.location.href, '_blank', 'noopener,noreferrer');
                break;
            case "expand": 
                document.documentElement.requestFullscreen();
                break;
            default:
                break;
        }
    }


    const  generateTest = async (obj, isDebug, system) => {
        const jsonValue = window.application?.ui?.toRunTimeJSON();
        let dataObj = {doc: JSON.stringify(jsonValue), bosName: window.application.integrationBuilderName,
            input: obj, isDebug: isDebug, systems: system};
        const [error, data] = await IntegrationbuilderActions(app()).generateTest(dataObj, window.application.projectName);
        if (error) {
			this.showNotifcation({ message: error || data.status?.message, type: "E" });
            return false;
        } else {
            prepareTestInput(data);
        }
    }

    const closeTest = () => {
        setShowTest(false);
    }

    const searchChangeHandler = (e) => {
        const value = e.target.value;
        menuFilter(value);
        setMenuSearch(value);
    };

    const prepareTestInput = (debugResp = '') => {
        let inputVal = {};
        for (let key of Object.keys(window.application.ui.parameter.Input)) {
            inputVal[key] = { ...window.application.ui.parameter.Input[key], testVal:''};
        };
        let system = window.application.ui.parameter.system ? window.application.ui.parameter.system : [];
        let inputObj = {Input:inputVal, debug:false, debugTest:debugResp, system:system};
        setTestInput(inputObj);
    }

    const menuFilter = (value) => {
        let filterItems = [];
        for (let item of items) {
            if (item.name.search(value) !== -1){
                filterItems.push(item);
            } else {
                let tempBlocks = JSON.parse(JSON.stringify(item));
                tempBlocks.blocks = []; 
                for (let block of items.blocks) {
                    if (block.name.search(value) !== -1){
                        tempBlocks.blocks.push(block);
                    }
                }
                if(tempBlocks.blocks.length !== 0)
                    filterItems.push(tempBlocks);
            }
        }
        setBlockMenus(filterItems);
    }


    const refresh = () => {
        setParameter(window.application.ui.parameter);
    }

    useEffect(()=>{
        window["application"]= new Application();
        window.application.app =  app();
        window.application.projectName = projectName ? projectName : null;
        window.application.integrationBuilderId = uuid;
        window.application.load();
        // window["utils"] = new Utils();
        setParameter(window.application.ui.parameter);
        window.application.showContextMenu = toggleContextMenu;
        window.application.hideContextMenu = closeContextMenu;
        window.application.showNotifcation = showNotifcation;
        window.application.toggleDropdown = togglePopupMenu;
        window.application.closePopupMenu = closePopupMenu;
        window.application.toggleMatchFields = toggleMappingPopup;
        window.application.reload = refresh;
        window.application.setLinks = setAddressBarLinks;
    },[]);


    const load = () => {
        setParameter(JSON.parse(JSON.stringify(window.application.ui.parameter)));
    }
    
    return (
        <>
            <div>
                <ActionPanel links={links} handler={handleEvent}/>
                <div className={styles.builder}>
                    <div>
                        <div className={styles.leftPanel}>
                            <Accordion items={blockMenus}/>
                        </div>
                        <SearchInput 
                            placeholder="Search"
                            value={menuSearch} 
                            onChange={searchChangeHandler} />
                    </div>
                    <div className={styles.canvas}>
                        <CanvasPanel/>
                    </div>
                    <div className={styles.propertiesHead}>
                        <div className={styles.properties}>
                            <Properties parameter={parameter} load={load}/>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div>
                {showContextMenu?<>
                    <ContextMenu menuItems={menuItems} top={position[0]} left={position[1]}/>
                </> : <></>}
            </div>
            {showPopupMenu?<>
                <PopupMenu top={position[0]} left={position[1]} popupDetails = {popupDetails}/>
            </> : <></>}
            {showTest &&
                <Test parameter={testInput} onTest={generateTest} closePopup={closeTest} />
            }
            {showMappingPopup && 
                <MappingPopup mappingData={mappingData}  closePopup = {setShowMappingPopup} mappingPopup ={showMappingPopup} />
            }
            {showMappingConformation
                && <SmallPopup
                    popUp={showMappingConformation}
                    title={'Copy Table'}
                    subtitle={mappingConformation}
                    isErrorIcon = {false}
                    primaryButtonMsg = {'Yes'}
                    secondaryButtonMsg = {'No'}
                    handlePopUp={() => setShowMappingConformation(false)}
                    handleSubmit={() => mappingConformationEvent(mappingData)} 
                    />
            }
            <AddressBar links = {addressBarLinks} />
        </>
    )

    
}






export default IntegrationBuilder;