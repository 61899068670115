import { checkSchdulerExists, createSchedulerBasedOnTime, fetchUser, getAllProject, getBOS, getParameter, getReport, getReportURL, getSchdulerList, getSchedulerLoglist, updateScheduler } from "../../../api/library/scheduler/scheduler"
import ActivityIndicator from "../../../components/activity/ActivityIndicator"
import React from "react"


export function SchedulerActions({ show, close, notify }) {

    const GetSchdulerList = async (offset, limit, schedulerFilter) => {
        show(() => <ActivityIndicator text={"Loading Scheduler list"} />)
        const [error, data] = await getSchdulerList(offset, limit, schedulerFilter);
        close()
        if (error)
            return [error, null]
        if (data.code >= 400)
            return [data.message, null]
        return [null, data]
    }

    const verifySchedulerExists = async (name) => {
        show(() => <ActivityIndicator text={"Loading Scheduler list"} />)
        const [error, data] = await checkSchdulerExists(name);
        close()
        if (error)
            return [error, null]
        if (data.code >= 400)
            return [data.message, null]
        return [null, data]
    }

    const GetSchedulerLoglist = async (offset, limit, schedulerId, loglist) => {
        show(() => <ActivityIndicator text={"Loading Scheduler Log list"} />)
        const [error, data] = await getSchedulerLoglist(offset, limit, schedulerId, loglist)
        close()
        if (error)
            return [error, null]
        if (data.code >= 400)
            return [data.message, null]
        return [null, data]
    }

    const GetAllProject = async () => {
        show(() => <ActivityIndicator text={"Loading all the Projects"} />)
        const [error, data] = await getAllProject()
        close()
        if (error)
            return [error, null]
        if (data.code >= 400)
            return [data.message, null]
        return [null, data]
    }

    const GetBos = async (name) => {
        show(() => <ActivityIndicator text={"Loading BOS"} />)
        const [error, data] = await getBOS(name)
        close()
        if (error)
            return [error, null]
        if (data.code >= 400)
            return [data.message, null]
        return [null, data]
    }

    const GetParameter = async (projectName, bosName, serviceId) => {
        show(() => <ActivityIndicator text={"Loading Parameter"} />)
        const [error, data] = await getParameter(projectName, bosName, serviceId)
        close()
        if (error)
            return [error, null]
        if (data.code >= 400)
            return [data.message, null]
        return [null, data]
    }

    const CreateSchedulerBasedonTime = async (payload) => {
        show(() => <ActivityIndicator text={"Creating Scheduler Based on Time"} />)
        const [error, data] = await createSchedulerBasedOnTime(payload)
        close()
        notify({ message: "Scheduler created successfully", type: "S" });

        if (error) {
            notify({ message: error, type: "E" });
            return [error, null]
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null]
        }
        return [null, data]
    }
    const UpdateScheduler = async (payload, name) => {
        show(() => <ActivityIndicator text={"Updated Scheduler"} />)
        const [error, data] = await updateScheduler(payload, name)
        close()
        notify({ message: "Scheduler updated successfully", type: "S" });

        if (error) {
            notify({ message: error, type: "E" });
            return [error, null]
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null]
        }
        return [null, data]
    }

    const GetReportURL = async (schedulerId, logId) => {
        show(() => <ActivityIndicator text={"Loading Log Report"} />)
        const [error, data] = await getReportURL(schedulerId, logId)
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null]
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, null]
        }
        return [null, data]
    }

    const getUser = async (userName) => {
        show(() => <ActivityIndicator text={"Verifying user"} />)
        const [error, data] = await fetchUser(userName)
        close()
        return [error, data]
    }

    const GetReport = async (url) => {
        show(() => <ActivityIndicator text={"Loading Report"} />)
        const res = await getReport(url)
        close()
        return res;
    }

    return {
        GetSchdulerList,
        GetSchedulerLoglist,
        GetAllProject,
        GetBos,
        GetParameter,
        CreateSchedulerBasedonTime,
        UpdateScheduler,
        GetReportURL,
        GetReport,
        verifySchedulerExists,
        getUser
    }
}