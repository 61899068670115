import { useAppContext } from "../../components/activity/AppContext";
import FileUpload from "../../components/fileUpload";
import PopUp from "../../components/layouts/PopUp";
import React ,{ useEffect, useState } from "react";
import { ColumnMapping } from "./columnsMapping";
import styles from "./database.module.css";
import { messages } from "./messages";
import { download } from "../../api/url";
import { DatabaseActions } from "./database.actions";

function ImportTable(props) {
    const [logs, setLogs] = useState("");
    const [error, setError] = useState('');
    const [importId, setImportId] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [tableColumns, setTableColumns] = useState([]);
    const [importedColumns, setImportedColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState({});
    const app = useAppContext();
    const { 
        show = false,
        projectName = "",
        tableName = "",
        setShow = () => null
    } = props;

    useEffect(() => {
        setError("");
    }, [show]);

    const handleClose = () => {
        setFileData(null);
        setError("");
        setCurrentStep(1);
        setLogs("");
        setShow("");
    }

    const validate = () => {
        return !!fileData;
    }

    const onWatch = (file) => {
        setError("");
        setFileData(file);
    }
  
    const onNext = async () =>{
        let parent = "";
        setError('');
        
        if(!validate()){
          setError(messages.invalidFile);
          return false;
        }
        let formData = new FormData();
        formData.append('file', fileData);
        const databaseAction = DatabaseActions(app());
        let [err,data] = await databaseAction.importFile(tableName,projectName,formData);
        setImportedColumns(data.list);
        setImportId(data.data);
        setTableColumns(data.columns);
        setCurrentStep(2);
    }

    const onSubmit = async () =>{
        setCurrentStep(3);
        setError('');
        
        if(!validate()){
          setError(messages.invalidFile);
          return false;
        }
        let payload = {};
        Object.keys(selectedColumns).map((item) => {
            if(selectedColumns[item]){
                payload[item] = selectedColumns[item];
            }
        })
        const database = DatabaseActions(app());
        let [err,data] = await database.importTable(tableName,projectName,importId,payload);
        if(data.status === "Pending") {
            setLogs(messages.pendingStatus)
            setCurrentStep(3);
        } else {
            if(!error && data?.url){
                let text = await download(data.url);
                setLogs(text);
                setCurrentStep(3);
            }
        }  
    }

    if(currentStep === 1){
        return (
            <PopUp
                popUp={show}
                setPopUp={handleClose}
                title={messages.uploadFile}
                titleClassName = {styles.popupTitle}
                testId={messages.uploadFile}

            >
                <div className={styles.formArea}>
                    <FileUpload onChange = {onWatch} error={error} setError={setError} accept={".csv"}/>
                </div>

                <div className={styles.afFooter}>
                    <button className="secondary" data-clickable-testid='cancel' onClick={handleClose}> Cancel </button>
                    <button className="primary" data-clickable-testid='next' onClick={onNext} > Next </button>
                </div>
            </PopUp>
        );
    } else if(currentStep === 2){
        return (
            <PopUp
                popUp={show}
                setPopUp={handleClose}
                title={messages.uploadPageTwo}
                titleClassName = {styles.popupTitle}
                testId={messages.uploadPageTwo}
            >
                <ColumnMapping 
                    tableColumns={tableColumns}
                    importedColumns={importedColumns}
                    selectedColumns = {selectedColumns}
                    onChange = {(key, value) => setSelectedColumns((prev) => ({ ...prev, [key]: value}))}
                />
                <div className={styles.afFooter}>
                    <button className="secondary" data-clickable-testid='cancel' onClick={handleClose}> Cancel </button>
                    <button className="primary" data-clickable-testid='submit' onClick={onSubmit} > Submit </button>
                </div>
            </PopUp>
        );
    } else {
        return (
            <PopUp
                popUp={show}
                setPopUp={handleClose}
                title={""}
                testId={""}
                titleClassName = {styles.popupTitle}
            >
                <div data-text-testid={'logs'} className={styles.logContainer}>
                    {logs.split("\n").map((line, index)=>(
                        <p>{line}</p>
                    ))}
                </div>
                <div className={styles.afFooter}>
                    <button className="secondary" data-clickable-testid='close' onClick={handleClose}> Close </button>
                </div>
            </PopUp>
        );
    }
}

export default ImportTable;