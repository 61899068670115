import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/pagination";
import { ModuleTitle } from "../../../components/module-title";
import Table from "../../../components/Table/table";
import BasApiPopUp from "./basApi/basApiPopUp";
import { useAppContext } from "../../../components/activity/AppContext";
import { ServiceActions } from "./services.actions";
import IntegrationBuilder from "../../integrationBuilder";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NewService from "./newBas/newService";
import { generateUuid } from "../../../common";
import newBaasData from "./newBas/newBaasData";
import MetadataPopup from "./metadata/metadataPopup";
import PublishService from "./publish/publishService";
import TransportService from "./transport/transportService";
import RevertService from "./revert/revertService";
import { useLocation } from "react-router-dom"; 

function Services({perm}) {
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [list, setList] = useState({ total: 0, data: [] });
  const [filter, setFilter] = useState({});
  const [apipopup, setApipopup] = useState(false);
  const [newServicepopup, setNewServicepopup] = useState(false);
  const [update, setUpdate] = useState(false);
  const [metadata, setMetadata] = useState({ show: false, item: "" });
  const [publish, setPublish] = useState({ show: false, item: "" })
  const [transport, setTransport] = useState({ show: false, item: "" });
  const [revert, setRevert] = useState({ show: false, item: "" });
  const app = useAppContext();
  //const navigate = useNavigate();
  const history = useHistory();
  const location = useLocation();
  location.state = {permission:perm}

  const menus = (item) => {
    let cont =[]
    if (item.baasEnable) {
      cont = ["Open", "Metadata", "Rename", "Publish", "Transport", "Revert"]
    } else {
      cont = ["Open", "Metadata", "Rename"]
    }
    if(!location.state.permission?.canUpdate) cont = ["Open", "Metadata"]
    return cont
  }

  const onHeaderChange = (id, value) => {
    setFilter((prev) => ({ ...prev, [id]: value }));
  };

  const handleBasApi = () => {
    setApipopup(true);
  };

  const closeNewServices = () => {
    setNewServicepopup(false);
  };

  const handleNewService = () => {
    setUpdate(false)
    setNewServicepopup(true);
  };

  const handleImagePopup = async (name, item) => {
    if(location.state.permission?.canUpdate) {
      const payLoad = { baasEnable: !item.baasEnable }
      const actions = ServiceActions(app());
      const [error, data] = await actions.updateBaasEnabled(item, payLoad);

      if (!error) {
        getList();
      }
    } else {

    }

  }

  const menuOnClick = (option, value) => {

    switch (option) {
      case "Open":
        //navigate("/library/services/" + value.serviceId);
        history.push("/library/services/" + value.serviceId);
        break;
      case "Metadata":
        setMetadata({ show: true, item: value });
        break;
      case "Rename":
        setUpdate({ ...value, isUpdate: true });
        setNewServicepopup(true);
        break;
      case "Publish":
        setPublish({ show: true, item: value })
        break;
      case "Transport":
        setTransport({ show: true, item: value })
        break;
      case "Revert":
        setRevert({ show: true, item: value })
        break;
      default:
    }
  };

  const itemOnClick = (item, event) => {
    //navigate("/library/services/" + item.serviceId);
    history.push("/Library/services/" + item.serviceId);
  };

  const checkNewService = async (name, setApiError) => {
    let uuid = generateUuid();
    let baasData = { ...newBaasData, name: name, uuid: uuid };
    const actions = ServiceActions(app());
    const [error, data] = await actions.createNewServices(baasData);
    if (error) {
      setApiError({ message: error || data.status?.message });
      return false;
    } else {
      if (data.status.type === "E") {
        setApiError(data.status.message);
      } else {
        //navigate("/library/services/" + uuid);
        history.push("/Library/services/" + uuid);
      }
    }
  };

  const updateService = async (name, bosData, setApiError) => {
    let baasData = { ...bosData, name: name, uuid: bosData.serviceId };
    const actions = ServiceActions(app())
    const [error, data] = await actions.updateServices(baasData);
    if (error) {
      setApiError(error || data.status?.message);
    } else {
      setUpdate({ isUpdate: false });
      setNewServicepopup(false);
      getList();
    }
    // if (error) {
    //   setApiError({ message: error || data.status?.message });
    //   return false;
    // } else {
    //   if (data.status.type === "E") {
    //     setApiError(data.status.message);
    //   } else {
    //     //navigate("/library/services/" + bosData.serviceId);
    //     // history.push("/Library/services/" + bosData.serviceId);
    //   }
    // }
  };

  const getList = async () => {
    const actions = ServiceActions(app());
    const [error, data] = await actions.getList(
      pagination.limit,
      pagination.offset,
      filter
    );
    setList({ total: data.noOfRecords, data: data.data });
  };

  useEffect(() => {
    getList();
  }, [filter, pagination]);

  const roleData = [
    { name: "Enabled" },
    { name: "Disabled" },
  ]

  const headers = [
    { name: "Service", id: "name", type: "input" },
    {
      name: "Last Published On",
      id: "lastPublishedDateTime",
      type: "input",
      disabled: true,
    },
    { name: "Version", id: "version", type: "input" },
    {
      id: "baasEnable",
      name: "baasEnable",
      type: "role",
      icon: true,
      width: "180px",
      handleImagePopup: handleImagePopup,
      disabled: !location.state.permission?.canUpdate
    },
    { name: "moreVertIcon", id: "moreVertIcon", type: "moreVertIcon" },
  ];

  return (
    <>
      <div>
        <ModuleTitle title="Services">
        {
          location.state.permission.childPermissions[0]?.canView
          ?<button
            className="secondary"
            data-clickable-testid="baasApiKey"
            onClick={handleBasApi}
          >
            Baas Api Key
          </button>
          :<></>
        }
        {
          location.state.permission?.canCreate
          ?<button
            className="primary"
            style={{ width: "auto" }}
            data-clickable-testid="newService"
            onClick={handleNewService}
          >
            + New Service
          </button>
          :<></>
        }
        </ModuleTitle>
      </div>
      <div className="afModule">
        <div style={{ boxShadow: "0px 2px 6px rgb(0 0 0 / 8%)" }}>
          <Table
            name={"service-list-table"}
            modifyPopUp={menus}
            data={list.data || []}
            roleData={roleData}
            headerColumns={headers}
            contextOnChange={menuOnClick}
            onRowDoubleClick={itemOnClick}
            handleHeaderInputChange={onHeaderChange}
            handleHeaderSelectChange={onHeaderChange}
          />
          <Pagination
            displayRows={pagination}
            userDataCount={list.total}
            setDisplayRows={setPagination}
          />
        </div>
      </div>
      {apipopup ? (
        <BasApiPopUp userModal={apipopup} setUserModal={setApipopup} />
      ) : (
        <></>
      )}
      {newServicepopup && (
        <NewService
          closepopup={closeNewServices}
          show={newServicepopup}
          apiService={update ? updateService : checkNewService}
          update={update}
        />
      )}
      {metadata.show ? <MetadataPopup setMetadata={setMetadata} metadata={metadata} /> : ""}
      {publish.show ? <PublishService getList={getList} setPublish={setPublish} publish={publish} /> : ""}
      {transport.show ? <TransportService getList={getList} setTransport={setTransport} transport={transport} /> : ""}
      {revert.show ? <RevertService getList={getList} setRevert={setRevert} revert={revert} /> : ""}
    </>
  );
}

export default Services;
