import React, { useRef, useState, useEffect } from "react";
import styles from "./customScrollbar.module.css";

function CustomScrollbar({ children }) {
  const containerRef = useRef(null);
  const horizontalSliderRef = useRef(null);
  const verticalSliderRef = useRef(null);
  const [horizontalSliderPosition, setHorizontalSliderPosition] = useState(0);
  const [horizontalSliderWidth, setHorizontalSliderWidth] = useState(0);
  const [verticalSliderPosition, setVerticalSliderPosition] = useState(0);
  const [verticalSliderHeight, setVerticalSliderHeight] = useState(0);
  const [showHorizontalSlider, setShowHorizontalSlider] = useState(false);
  const [showVerticalSlider, setShowVerticalSlider] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const contentWidth = container.scrollWidth;
    const contentHeight = container.scrollHeight;
    const maxScrollLeft = contentWidth - containerWidth;
    const maxScrollTop = contentHeight - containerHeight;
    const horizontalSliderWidth = (containerWidth / contentWidth) * containerWidth;
    const verticalSliderHeight = (containerHeight / contentHeight) * containerHeight;
    const horizontalSliderMaxScrollLeft = containerWidth - horizontalSliderWidth;
    const verticalSliderMaxScrollTop = containerHeight - verticalSliderHeight;
    setHorizontalSliderWidth(horizontalSliderWidth);
    setVerticalSliderHeight(verticalSliderHeight);

    const handleScroll = () => {
      const scrollLeft = container.scrollLeft;
      const scrollTop = container.scrollTop;
      const horizontalSliderRatio = horizontalSliderMaxScrollLeft / containerWidth;
      const verticalSliderRatio = verticalSliderMaxScrollTop / containerHeight;
      const horizontalScrollPercentage = (scrollLeft / maxScrollLeft) * 100;
      const verticalScrollPercentage = (scrollTop / maxScrollTop) * 100;
      const horizontalSliderPosition = horizontalScrollPercentage * horizontalSliderRatio;
      const verticalSliderPosition = verticalScrollPercentage * verticalSliderRatio;
      setHorizontalSliderPosition(horizontalSliderPosition);
      setVerticalSliderPosition(verticalSliderPosition);
    };

    if (horizontalSliderWidth < containerWidth) setShowHorizontalSlider(true);
    if (verticalSliderHeight < containerHeight) setShowVerticalSlider(true);

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleHorizontalSliderDrag = (event) => {
    event.preventDefault();
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const maxScrollLeft = container.scrollWidth - containerWidth;

    const dragStartX = event.clientX;
    const initialSliderPosition = horizontalSliderRef.current.offsetLeft;

    const handleMouseMove = (event) => {
      const dragOffset = event.clientX - dragStartX;
      const sliderPosition = Math.min(
        Math.max(initialSliderPosition + dragOffset, 0),
        containerWidth - horizontalSliderWidth
      );
      const scrollPosition =
        (sliderPosition / (containerWidth - horizontalSliderWidth)) * maxScrollLeft;

      container.scrollLeft = scrollPosition;
      setHorizontalSliderPosition((sliderPosition / containerWidth) * 100);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleVerticalSliderDrag = (event) => {
    event.preventDefault();
    const container = containerRef.current;
    const containerHeight = container.clientHeight;
    const maxScrollTop = container.scrollHeight - containerHeight;

    const dragStartY = event.clientY;
    const initialSliderPosition = verticalSliderRef.current.offsetTop;

    const handleMouseMove = (event) => {
      const dragOffset = event.clientY - dragStartY;
      const sliderPosition = Math.min(
        Math.max(initialSliderPosition + dragOffset, 0),
        containerHeight - verticalSliderHeight
      );
      const scrollPosition =
        (sliderPosition / (containerHeight - verticalSliderHeight)) * maxScrollTop;

      container.scrollTop = scrollPosition;
      setVerticalSliderPosition((sliderPosition / containerHeight) * 100);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleHorizontalSliderContainerClick = (event) => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const maxScrollLeft = container.scrollWidth - containerWidth;

    const clickX = event.clientX - container.getBoundingClientRect().left;
    const clickPosition = clickX / containerWidth;
    const scrollLeft = clickPosition * maxScrollLeft;

    container.scrollLeft = scrollLeft;
    setHorizontalSliderPosition((scrollLeft / maxScrollLeft) * 100);
  };

  const handleVerticalSliderContainerClick = (event) => {
    const container = containerRef.current;
    const containerHeight = container.clientHeight;
    const maxScrollTop = container.scrollHeight - containerHeight;

    const clickY = event.clientY - container.getBoundingClientRect().top;
    const clickPosition = clickY / containerHeight;
    const scrollTop = clickPosition * maxScrollTop;

    container.scrollTop = scrollTop;
    setVerticalSliderPosition((scrollTop / maxScrollTop) * 100);
  };

  return (
    <div className={styles.customScrollbar}>
      <div className={styles.scrollContainer} ref={containerRef}>
        {children}
      </div>
      {showHorizontalSlider && (
        <div
          className={styles.horizontalSliderContainer}
          onClick={handleHorizontalSliderContainerClick}
        >
          <div
            className={styles.horizontalSlider}
            style={{ left: `${horizontalSliderPosition}%`, width: `${horizontalSliderWidth}px` }}
            onMouseDown={handleHorizontalSliderDrag}
            ref={horizontalSliderRef}
          />
        </div>
      )}
      {showVerticalSlider && (
        <div
          className={styles.verticalSliderContainer}
          onClick={handleVerticalSliderContainerClick}
        >
          <div
            className={styles.verticalSlider}
            style={{ top: `${verticalSliderPosition}%`, height: `${verticalSliderHeight}px` }}
            onMouseDown={handleVerticalSliderDrag}
            ref={verticalSliderRef}
          />
        </div>
      )}
    </div>
  );
}

export default CustomScrollbar;
