import React, { useRef, useEffect, useState } from 'react';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import { ReactComponent as UserIcon } from '../../../assets/userrole.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/filter.svg';
import { Avatar, IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import Photo from '../../../assets/images/profile-pic.svg';
import { NavButton } from '../NavButton';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import useStyles from './style';
import AddIcon from '@material-ui/icons/Add';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import CellIcon from '../../../assets/images/input-cell-icon';
import ListIcon from '../../../assets/images/list-icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { businessProcessConstants } from '../../../constants';
import { UserOutlineIcon } from '../../../assets/designer-icons';
import { configMessage } from '../../messages/config';
import { apmMessage } from "../../../common/messages/apm";
export default function NavItem(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newRole, setNewRole] = React.useState('');
  const [items,setItems]=React.useState(props.items);
  const [hasFilter, setHasFilter] = React.useState(false);
  let {title, identifier, enableImage, profilePic,showWorkItemFilterOption } = props;
  const refClick = useRef();
  const filterRef = useRef();
  const collapseRef = useRef();
  const navigateProcessRef = useRef();
  // const [items, setItems] = useState(props.items);
  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (identifier === 'role') {
      refClick.current.addEventListener('click', (e) => handleClick(this, e));
      if(props.isWorkflow){
        collapseRef.current.addEventListener('click', 
          () => props.onCollapseExpand(!props.isCollapsed)
        )
      }
      if(props.hasFilter(setHasFilter)){
        filterRef.current.addEventListener('click', 
          () => { 
            setTimeout(() => {
              setAnchorEl(false); 
              props.openWorkItemFilter(setHasFilter);
            }, 0);
          }
        )
      }
      return () => {
        refClick.current.removeEventListener('click', (e) =>
          handleClick(this, e)
        );
        if(props.isWorkflow){
          collapseRef.current.removeEventListener('click', () => null);
        }
        if(props.hasFilter){
          filterRef.current.removeEventListener('click');
        }
      };
    }
  }, []);

  useEffect(() => {
    if (navigateProcessRef && navigateProcessRef.current) {
      navigateProcessRef.current.addEventListener('click', (e) =>
        handleProcessNav(e)
      );
    }

    return () => {
      if (navigateProcessRef && navigateProcessRef.current) {
        navigateProcessRef.current.removeEventListener('click', (e) =>
          handleProcessNav(e)
        );
      }
    };
  }, []);

  useEffect(() => {
    if (navigateProcessRef && navigateProcessRef.current) {
      navigateProcessRef.current.addEventListener('click', (e) =>
        handleProcessNav(e)
      );
    }

    return () => {
      if (navigateProcessRef && navigateProcessRef.current) {
        navigateProcessRef.current.removeEventListener('click', (e) =>
          handleProcessNav(e)
        );
      }
    };
  }, [props.processLinked]);

  const handleProcessNav = (e) => {
    props.navigateToProcess();
  };

  const handleClick = (self, event) => {
    if(props.isWorkflow){
      let i=[...items];
      if(showWorkItemFilterOption && showWorkItemFilterOption()){
        if(!i.find(ii=>ii.name==='WorkItem Filter')){
          i.push({ name: 'WorkItem Filter' });
        }
      }else{
        i=i.filter(ii=>ii.name!='WorkItem Filter');
      }
      setItems(i);
    }
    if (!props.noExp) setAnchorEl(event.currentTarget);
    if (props.onClick) {
      props.onClick(self, event, () => {
        setAnchorEl(false);
      });
    }
    if(typeof props.getNavItems === "function"){
      setItems(props.getNavItems())
    }
  };

  const profileImageRender = () => {
    return profilePic ? (
      <Avatar variant='rounded' src={profilePic}></Avatar>
    ) : (
      <Avatar variant='rounded' src={Photo}></Avatar>
    );
  };

  const handleClose = (e, item) => {
    const { name } = item;
    setAnchorEl(null);
    if (props.onSelect != undefined) {
      props.onSelect(e, name);
    }

    if (props.identifier === 'dmnColumn' && name === 'Delete') {
      props.deleteColumn(props.index);
      return 0;
    }

    if (props.identifier === 'add-input' && name) {
      props.addVariable(name);
      return 0;
    }

    if (name === 'Profile') history.push('/UserProfile');
    if (name === 'Sign out') props.logout();
    if (name === 'Settings') props.editFunction();
    if (name === 'Database Tables') history.push('/Library/Database');
    if (name === 'Shared Drive') history.push('/Library/Drive');
    if (name === 'Scheduler') history.push('/Library/Schedulers');
    if (name === 'Variables') history.push('/Library/Variables');
    if (name === 'Services') history.push('/Library/Services');
    if (name === 'Assets') history.push('/Library/Assets');
    if (name === 'Logins') history.push('/Library/Logins');
    if (name === 'Edit') props.editVariable();
    if (name === 'Delete') props.deleteVariable();
    if (name === 'Change Role')
      props.changeRole((newRole) => {
        setNewRole(newRole);
        props.valueChanged(newRole);
      });
    if (name === 'Edit App Details') props.openEditAppDetails();
    if (["Enable Menu", "Disable Menu"].indexOf(name) > -1) props.handleToogleMenu();
    if (name === apmMessage.T3518) props.openDeleteRole();
    if (name === 'Inbox Preview') props.openInboxPreview();
    if (name === 'WorkItem Filter') props.openWorkItemFilter(setHasFilter);
    if (name === 'Enable language') props.openMultiLingual();
    // Check if the role is Admin for the below block to work
    if (item && item.value) {
      if (props.setSelected !== undefined) {
        props.setSelected('/Settings');
      }

      props.currentAdminTab(item);
      history.push(item.url);
    }
  };

  return (
    <>
      {props.identifier === 'variableMoreIcon' ||
      props.identifier === 'dmnColumn' ? (
        <MoreVertSharpIcon
          ref={refClick}
          aria-controls={identifier}
          className={props.className}
          aria-haspopup='true'
          onClick={(e) => {
            handleClick(this, e);
          }}
        />
      ) : (
        <>
          {enableImage &&
            identifier === 'headerNavbarItem' &&
            profileImageRender()}

          {
            identifier === 'role' &&
            props.isWorkflow &&
            <IconButton 
              ref={collapseRef}
              data-role={props.id || ''}
              aria-controls={identifier} 
              className={classes.collapseIcon}
            >
              { props.isCollapsed && <BsArrowsAngleExpand className = {classes.collapse} /> }
              { !props.isCollapsed && <BsArrowsAngleContract className = {classes.collapse}/> }
            </IconButton>
          }
          {!!props.processLinked && (
            <NavButton
              ref={navigateProcessRef}
              data-role={props.id || ''}
              aria-controls={identifier}
              className={'roleCss'}
              aria-haspopup='true'
              onClick={(e) => {
                handleProcessNav(this, e);
              }}
              startIcon={
                identifier === 'role' && 
                  <UserIcon />
              }
              disableRipple
            >
              {configMessage.T4767} {'  /'}
            </NavButton>
          )}
          <NavButton
            ref={refClick}
            data-role={props.id || ''}
            aria-controls={identifier}
            className={`${
              identifier === 'role'
                ? !!props.processLinked
                  ? 'role'
                  : 'roleCss'
                : props.className
            } ${props.currentPage === '/admin' && props.selectedStyle}`}
            aria-haspopup='true'
            onClick={(e) => {
              handleClick(this, e);
            }}
            endIcon={
              identifier !== 'add-input' && 
              <>
                { hasFilter && <FilterIcon ref={filterRef} className={classes.filterIcon}/> }
                <ExpandMoreIcon fontSize='small' />
              </>
            }
            startIcon={
              identifier === 'add-input' ? <AddIcon fontSize='small' /> :
              (identifier === 'role' && !props.processLinked ? <UserIcon /> : null )
            }
            disableRipple
            data-clickable-testid={title}
          >
            {newRole !== '' ? newRole : title}
          </NavButton>
        </>
      )}
      {items.length > 0 && (
        <Menu
          disableScrollLock={true}
          id={identifier}
          anchorEl={anchorEl}
          className={
            props.title === 'Library' ||
            props.title === 'Admin' ||
            props.identifier === 'headerNavbarItemProxyLogin' ||
            props.enableImage
              ? classes.menuItem
              : props.identifier !== 'variableMoreIcon' &&
                classes.dropdownenuItem
          }
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          data-clickable-testid={"header-menu"}
        >
          {items.map((item, i) => (
            <MenuItem onClick={(e) => handleClose(e, item)} key={i} data-clickable-testid={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
