import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  NativeSelect,
  ClickAwayListener,
  OutlinedInput,
  Input,
  InputAdornment,
  Slider,
  Checkbox,
  Collapse,
  Switch,
  FilledInput,
  ListItem,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "../../../../views/dialog";
import CellIcon from "../../../../assets/images/input-cell-icon.js";
import {
  AnchorLinkIcon,
  LeftAlignmentIcon,
  RightAlignmentIcon,
  CenterAlignmentIcon,
  TextBoldIcon,
  TextItalicIcon,
  TextUnderlineIcon,
  LineHeightIcon,
  FontSizeIcon,
  AddIcon,
  TopPositionIcon,
  RightPositionIcon,
  BottomPositionIcon,
  LeftPositionIcon,
} from "../../../../assets/designer-icons";
import MenuProperties from "../../../business-function/components/menu-properties";
import {
  headerTypes,
  keypadTypes,
  labelTypes,
  icons,
  panelIconPosition,
  buttonIconPosition,
  stepperProperties,
  sizings,
  fontFamilies,
  fontWeights,
  wrapText,
  getUnitLessValue,
} from "../../utils/style";
import useStyles, { customSliderStyles, checkBoxStyles } from "./style";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { COMPONENT_NAMES } from "../../utils/configuration/componentName.contants";
import DataSourceToggle from "./components/dataSource/dataSource";
import {
  ExpandLess,
  ExpandMore,
  Search as SearchIcon,
  Image as ImageIcon,
  LineStyle as LineStyleIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  Cancel as CancelIcon,
  Add,
} from "@material-ui/icons";

import { ColorPicker, createColor } from "material-ui-color";
import constants from "../../const";
import useDebounce from "../../../../utils/use-debounce";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import DropDown from "./popper";
import SearchDropDown from "../../../../common/components/SearchDropdown";
import { alertShow } from "../../../../helpers/app-designer";
import {
  VARIABLE_EXCEPTION_COMPONENTS,
  DEFAULT_ICON_POSITION,
} from "../../../../common/constants";
import {
  getStepTransitions,
  getStepNavigation,
  _deepCloneJSON, capitalize,
} from '../../../../utils/common';
import TextInputField from './components/inputText';
import IconPicker from '../../../../common/components/icon-viewer/iconPicker';
import * as mui from '@material-ui/icons';
import { toCSS, toJSON } from 'cssjson';
import CodeMirror from 'react-codemirror';
import TextFieldInput from './components/inputText';
import TrashIcon from '../../../../assets/designer-icons/trash.svg';
import AddInput from './components/add-input/index.js';
import EnableDiableInput from './components/enable-diable/index.js';
import {
  state,
} from '../../../../libraries/react-page-maker';
import getComponentDefaultSchema from '../../utils/configuration/defaultSchema/components.defaultSchema';
import { configMessage } from '../../../../common/messages/config';
import { getScreenSnapshot } from "../../utils/screen-snapshot";
import { isEnabled } from '../../utils/common/index.js';
import { SHAPE_TYPES } from '../../../../libraries/mxgraph-wrapper/shape/types.js';
import FilterPopup from "./components/filter-popup";
import LibraryIcon from '../../utils/configuration/library-icons.js';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'

var _ = require('lodash');
require('codemirror/mode/css/css');
// require('codemirror/addon/lint/lint.css');
// require('codemirror/addon/lint/lint.js');
// require('codemirror/addon/lint/css-lint.js');

const CustomizedSlider = customSliderStyles(Slider);

const getNumericValue = (value) => parseInt(value, 10);
const showProperty = (data, key) => {
  if (data) {
    const index = data.findIndex((datum) => datum === key);
    return index > -1;
  }
  return false;
};

const LayoutInputField = (props) => {
  const { onChange, value, calltype, id, ...rest } = props;
  const [val, setVal] = useState(props.value);

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  useEffect(() => {
    let newVal = val;
    if (
      newVal &&
      typeof newVal === "string" &&
      !newVal.endsWith("px") &&
      !newVal.endsWith("%") &&
      !newVal.endsWith("em") &&
      newVal !== "auto"
    ) {
      newVal = isNaN(parseFloat(val)) ? val : parseFloat(val);
    }

    let iframe = document.getElementById("iframe");

    if (iframe && calltype) {
      let elmnt = iframe.contentWindow.document.getElementById(`${id}_parent`);
      if (!elmnt) {
        elmnt = iframe.contentWindow.document.getElementById(id);
      }
      if (elmnt) {
        if (elmnt.style[calltype]) {
          elmnt.style[calltype] = newVal;
        }
      }
    }

    let timeId = setTimeout(() => {
      if (val != props.value) {
        onChange(newVal);
      }
    }, 300);
    return () => {
      clearTimeout(timeId);
    };
  }, [val]);

  const _handleKeyDown = (e) => {
    // console.log('_handleKeyDown_______________________')
    let keynum;
    if (window.event) {
      // IE
      keynum = e.keyCode;
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which;
    }

    if (keynum == 38 || keynum == 40) {
      // Key Up/Down
      e.preventDefault();
      let styleUnit = "";
      let currentVal = val;
      if (currentVal && typeof currentVal === "string") {
        let currentValArr = currentVal.split(/(\d+)/).filter(Boolean);
        if (currentValArr.length > 1) {
          let unitPart = currentValArr[currentValArr.length - 1];
          if (["px", "em", "%"].indexOf(unitPart) > -1) {
            styleUnit = unitPart;
          }
        }
      }

      let newVal = parseFloat(val);
      if (isNaN(newVal)) newVal = 0;
      if (keynum == 38) {
        // Key Up
        newVal = newVal + 1;
      }
      if (keynum == 40) {
        // Key Down
        newVal = newVal - 1;
      }
      setVal(`${newVal}${styleUnit}`);
    }
  };
  return (
    <input
      value={val}
      onChange={(e) => setVal(e.target.value)}
      onKeyDown={_handleKeyDown}
      {...rest}
    />
  );
};

const BoxModel = (props) => {
  const { selectedComponent, updateOptions, updateCanvas } = props;
  const { propertyValue, property } = selectedComponent;
  const onChange = (key, value) => {
    let layout = { ...propertyValue.layout, [key]: value };
    updateOptions({ ...propertyValue, layout }, "propertyValue");
  };

  return (
    <Box className="box-model-layout">
      <Box component="div" className="horizontal">
        <Grid
          className="box-title"
          container
          justify="center"
          alignItems="center"
          direction="row"
        >
          <span>Margin</span>
          <LayoutInputField
            className="layout-input"
            value={propertyValue.layout.marginTop}
            onChange={(v) => onChange("marginTop", v)}
            disabled={
              (selectedComponent.id && selectedComponent.dropzoneID) ||
                (propertyValue?.component?.menuStyle || !property["disableMargin"])
                ? false : true
            }
            calltype="marginTop"
            id={selectedComponent.id}
          />
          {/* <input
            className="layout-input"
            value={propertyValue.layout.marginTop}
            disabled={
              selectedComponent.id && selectedComponent.dropzoneID
                ? false
                : true
            }
            type="text"
            onChange={(e) => onChange("marginTop", e.target.value)}
          /> */}
        </Grid>
        <Box component="div" className="vertical">
          {/* <input
            className="layout-input mr6"
            value={propertyValue.layout.marginLeft}
            disabled={
              selectedComponent.id && selectedComponent.dropzoneID
                ? false
                : true
            }
            type="text"
            onChange={(e) => onChange("marginLeft", e.target.value)}
          /> */}
          <LayoutInputField
            className="layout-input mr6"
            value={propertyValue.layout.marginLeft}
            onChange={(v) => onChange("marginLeft", v)}
            disabled={
              (selectedComponent.id && selectedComponent.dropzoneID) ||
                (propertyValue?.component?.menuStyle || !property["disableMargin"])
                ? false : true
            }
            calltype="marginLeft"
            id={selectedComponent.id}
          />
          <Box component="div" className="horizontal">
            <Grid
              className="box-title"
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <span>Padding</span>
              {/* <input
                className="layout-input"
                value={propertyValue.layout.paddingTop}
                type="text"
                onChange={(e) => onChange("paddingTop", e.target.value)}
              /> */}
              <LayoutInputField
                className="layout-input"
                value={propertyValue.layout.paddingTop}
                onChange={(v) => onChange("paddingTop", v)}
                calltype="paddingTop"
                id={selectedComponent.id}
              />
            </Grid>
            <Box component="div" className="vertical">
              {/* <input
                className="layout-input mr6"
                value={propertyValue.layout.paddingLeft}
                type="text"
                onChange={(e) =>
                  onChange("paddingLeft", e.target.value)
                }
              /> */}
              <LayoutInputField
                className="layout-input mr6"
                value={propertyValue.layout.paddingLeft}
                onChange={(v) => onChange("paddingLeft", v)}
                calltype="paddingLeft"
                id={selectedComponent.id}
              />
              <span className="content-box" />
              {/* <input
                className="layout-input ml6"
                value={propertyValue.layout.paddingRight}
                type="text"
                onChange={(e) =>
                  onChange("paddingRight", e.target.value)
                }
              /> */}
              <LayoutInputField
                className="layout-input ml6"
                value={propertyValue.layout.paddingRight}
                onChange={(v) => onChange("paddingRight", v)}
                calltype="paddingRight"
                id={selectedComponent.id}
              />
            </Box>
            {/* <input
              className="layout-input"
              value={propertyValue.layout.paddingBottom}
              type="text"
              onChange={(e) =>
                onChange("paddingBottom", e.target.value)
              }
            /> */}
            <LayoutInputField
              className="layout-input"
              value={propertyValue.layout.paddingBottom}
              onChange={(v) => onChange("paddingBottom", v)}
              calltype="paddingBottom"
              id={selectedComponent.id}
            />
          </Box>
          {/* <input
            className="layout-input ml6"
            value={propertyValue.layout.marginRight}
            disabled={
              selectedComponent.id && selectedComponent.dropzoneID
                ? false
                : true
            }
            type="text"
            onChange={(e) => onChange("marginRight", e.target.value)}
          /> */}

          <LayoutInputField
            className="layout-input ml6"
            value={propertyValue.layout.marginRight}
            onChange={(v) => onChange("marginRight", v)}
            disabled={
              (selectedComponent.id && selectedComponent.dropzoneID) ||
                (propertyValue?.component?.menuStyle || !property["disableMargin"])
                ? false : true
            }
            calltype="marginRight"
            id={selectedComponent.id}
          />
        </Box>
        {/* <input
          className="layout-input"
          value={propertyValue.layout.marginBottom}
          disabled={
            selectedComponent.id && selectedComponent.dropzoneID ? false : true
          }
          type="text"
          onChange={(e) => onChange("marginBottom", e.target.value)}
        /> */}

        <LayoutInputField
          className="layout-input"
          value={propertyValue.layout.marginBottom}
          onChange={(v) => onChange("marginBottom", v)}
          disabled={
            (selectedComponent.id && selectedComponent.dropzoneID) ||
              (propertyValue?.component?.menuStyle || !property["disableMargin"])
              ? false : true
          }
          calltype="marginBottom"
          id={selectedComponent.id}
        />
      </Box>
    </Box>
  );
};

const TextInputProperty = (props) => {
  const {
    label,
    type,
    icon,
    inputStyle,
    defaultValue,
    onChange,
    value,
    disabled = false,
    validateProperty = null,
    selectedComponent,
    endIcon,
    placeHolder,
    additonalProps = {},
    options=[], // Added options for dropdown
  } = props;
  const [textValue, setTextValue] = useState(value);
  const [isValid, setIsValid] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  const [invalidMessage, setInvalidMessage] = useState("Mandatory Field");
  const setValue = useDebounce(textValue, 300);
  useEffect(() => {
    if (textValue != value) {
      setTextValue(value);
    }
  }, [value]);
  useEffect(() => {
    if (setValue != value) {
      if (
        setValue &&
        validateProperty &&
        (validateProperty === "Width" || validateProperty === "Height")
      ) {
        onChange(getUnitLessValue(textValue, ""));
      } else if (validateProperty && validateProperty === "ID") {
        if (isValid) {
          onChange(textValue);
        }
      } else {
        onChange(textValue);
      }
    }
  }, [setValue]);
  const validate = (text = textValue, showError = true) => {
    let value = text;
    switch (validateProperty) {
      case "ID":
        if (value === "") {
          setIsValid(false);
          setInvalidMessage("Mandatory Field");
          return false;
        }
        if (value != "" && value.length > 50) {
          setIsValid(false);
          setInvalidMessage("Max Length");
          return false;
        }
        let regEx = /^[a-zA-Z0-9\_\-]+$/i;
        let result = true;
        result = regEx.test(value);
        if (!result) {
          setIsValid(result);
          setInvalidMessage("Invalid ID");
          return false;
        }
        if (value != selectedComponent.propertyValue.component.id) {
          let idocument = document.getElementById("iframe").contentDocument;
          if (
            idocument.getElementById(value) &&
            idocument.getElementById(value).getAttribute("dataid")
          ) {
            let dataId = idocument.getElementById(value).getAttribute("dataid");
            if (selectedComponent.id && dataId !== selectedComponent.id) {
              result = false;
            } else if (
              selectedComponent.cellId &&
              dataId !== selectedComponent?.cellId
            ) {
              result = false;
            }
          }
        }
        if (!result) {
          setInvalidMessage("Duplicate Id");
        }
        setIsValid(result);
        return result;
      default:
        return true;
    }
  };

  const handleOptionSelect = (val) => {
    setTextValue(val)
    onChange(val)
    setAnchorEl(null)
  }

  const toggleDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const onTextChange = (textChangeValue) => {
    /*if (validateProperty && (validateProperty === 'Width' || validateProperty === 'Height')) {
      onChange(getUnitLessValue(value, ''));
    } else {
      onChange(value);
    }*/
    setTextValue(textChangeValue);

    let idocument = document.getElementById("iframe").contentDocument;
    if (!validate(textChangeValue)) {
      idocument.getElementsByTagName("body")[0].className = "disablePage";
      document.getElementById("overlayDiv").style.display = "block";
    } else {
      idocument.getElementsByTagName("body")[0].className = "";
      if (document.getElementById("overlayDiv")) {
        document.getElementById("overlayDiv").style.display = "none";
      }
    }
  };

  const handleEndIconClick = (e) => {
    e.stopPropagation();
    props.handleEndIconClick && props.handleEndIconClick(e);
  };

  let endAdornments = null;

  if (endIcon) {
    endAdornments = [
      <InputAdornment position="end" onClick={handleEndIconClick}>
        {endIcon}
      </InputAdornment>,
    ];
  } else if (props.endIcons && props.endIcons.length > 0) {
    endAdornments = props.endIcons;
  }

  const renderTextInput = () => {
    return (
      <>
        {label && (
          <span>
            {label}{" "}
            {validateProperty && (
              <>
                <div
                  id={"overlayDiv"}
                  style={{
                    zIndex: 19999,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    /* background: 'rgba(76, 149, 205, 0.7)',*/
                    display: "none",
                  }}
                />
                <Tooltip title={"Mandatory field"}>
                  <span style={{ color: "red" }}>*</span>
                </Tooltip>
              </>
            )}
          </span>
        )}
        <TextField
          className={inputStyle}
          variant="outlined"
          disabled={disabled}
          type={type ? type : "text"}
          defaultValue={defaultValue}
          placeholder={placeHolder || ""}
          value={textValue}
          onClick={toggleDropdown}
          onChange={(e) => onTextChange(e.target.value)}
          onBlur={(e) => {
            e.persist();
            if (!isValid) {
              e.target.focus();
            }
          }}
          InputProps={{
            startAdornment: icon ? (
              <InputAdornment position="start">{icon}</InputAdornment>
            ) : null,
            endAdornment: endAdornments ? endAdornments : null,
          }}
          // InputProps={
          //   (icon && {
          //     startAdornment: (
          //       <InputAdornment position="start">{icon}</InputAdornment>
          //     ),
          //   },
          //   endAdornments &&
          //     endAdornments.length > 0 && {
          //       endAdornment: endAdornments,
          //     })
          // }

          style={props.inlineStyle || {}}
          {...additonalProps}
        />
        {options.length?
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={()=>setAnchorEl(null)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} onClick={()=>handleOptionSelect(option.value)} style={{fontSize: "13px"}}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        :<></>}
      </>
    );
  };

  return (
    <>
      {validateProperty ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {renderTextInput()}
          </div>
          {!isValid && (
            <p
              style={{
                marginTop: 0,
                marginBottom: 0,
                fontSize: 12,
                marginLeft: "30%",
                color: "red",
              }}
            >
              {invalidMessage}
            </p>
          )}
        </>
      ) : (
        renderTextInput()
      )}
    </>
  );
};

const DropdownProperty = (props) => {
  const {
    data,
    propertyName,
    handleOnChange,
    label,
    inputStyle,
    value,
    textWrap = 0,
    updateLinkTo,
    disabled = false,
  } = props;

  const onChange = (event) => {
    handleOnChange(propertyName, event.target.value);
    if (propertyName === "link") {
      updateLinkTo(event.target.value);
    }
  };

  const _getOptionsUI = () => {
    switch (propertyName) {
      case "link":
        if (Array.isArray(data)) {
          const groupedData = _.groupBy(data, "type");
          let optionsUI = [];
          for (const property in groupedData) {
            let options =
              Array.isArray(groupedData[property]) &&
              groupedData[property].map((datum) => (
                <option value={datum.value}>
                  {textWrap > 0 ? wrapText(datum.label, textWrap) : datum.label}
                </option>
              ));
            if (property !== 'undefined') {
              let replaceLabel = { [SHAPE_TYPES.XOR]: "Condition" };
              let labelProp = replaceLabel[property] || property;
              optionsUI.push(<optgroup label={labelProp}>{options}</optgroup>);
            } else {
              optionsUI.push(options);
            }
          }
          return optionsUI;
        }
        break;
      default:
        if (Array.isArray(data)) {
          return data.map((datum) => (
            <option value={datum.value}>
              {textWrap > 0 ? wrapText(datum.label, textWrap) : datum.label}
            </option>
          ));
        }
        break;
    }
    return null;
  };

  return (
    <>
      {label && <span>{label}</span>}
      <NativeSelect
        variant="filled"
        id="environment"
        className={`${inputStyle} native-select`}
        input={props.input || <OutlinedInput />}
        value={value}
        onChange={(event) => onChange(event)}
        disabled={disabled}
      >
        {_getOptionsUI()}
      </NativeSelect>
    </>
  );
};

const ButtonProperty = (props) => {
  const { label, icon } = props;
  return (
    <Button className="property-button">
      {icon && icon}
      <span>{label}</span>
    </Button>
  );
};

const PaletteProperty = (props) => {
  let transparentColor = "#FFFFFF00";
  let transparentKey = "transparent";
  const {
    label,
    propertyName,
    selectedComponent,
    updateOptions,
    updateCanvas,
    propertySection,
  } = props;
  const { propertyValue } = selectedComponent;
  const [color, setColor] = useState(createColor(transparentColor));

  useEffect(() => {
    let currentColor = propertyValue[propertySection][propertyName];
    if (currentColor !== color?.css?.backgroundColor) {
      if (currentColor === transparentKey) {
        currentColor = transparentColor;
      }
      setColor(createColor(currentColor));
    }
  }, [propertyValue[propertySection][propertyName]]);

  const onChange = (e, key, type = '') => {
    setColor(e);

    let value = '';
    if (type === 'textinput') value = e;
    else value = e?.css?.backgroundColor;
    let data = {
      ...propertyValue[propertySection],
      [key]: typeof e === "object" ? e?.css?.backgroundColor : e,
    };
    updateOptions(
      { ...propertyValue, [propertySection]: data },
      "propertyValue"
    );
  };

  return (
    <>
      {label && (
        <Box
          display="flex"
          justify="center"
          alignItems="center"
          direction="row"
        >
          <span>{label}</span>
        </Box>
      )}
      <Box display="flex" justify="center" alignItems="center" direction="row">
        {/*<input className="property-palette" type="color" opacity rgba cmyk hsla  value={getColor()} onChange={(e) => onChange(e.target.value, propertyName)} />*/}
        <ColorPicker
          className="property-palette"
          size={30}
          borderWidth={1}
          hideTextfield
          value={color}
          onChange={(e) => {
            onChange(e, propertyName, 'picker');
          }}
        />
        <TextInputProperty
          inputStyle="property-palette-textinput"
          defaultValue={propertyValue[propertySection][propertyName]}
          value={propertyValue[propertySection][propertyName]}
          onChange={(value) => onChange(value, propertyName, 'textinput')}
        />
      </Box>
    </>
  );
};

const IconProperty = (props) => {
  const { icon, handleOnClick, selected } = props;
  return (
    <span
      className={selected ? "icon-wrapper active" : "icon-wrapper"}
      onClick={handleOnClick}
    >
      {icon}
    </span>
  );
};

const TooltipIconProperty = (props) => {
  const { icon, handleOnClick, selected, label } = props;
  return (
    <Tooltip title={label || ""} placement={label?.toLowerCase() || "bottom"}>
      <span
        className={selected ? "icon-wrapper active" : "icon-wrapper"}
        onClick={handleOnClick}
      >
        {icon}
      </span>
    </Tooltip>
  );
};

const InputSliderProperty = (props) => {
  const {
    selectedComponent,
    updateOptions,
    label,
    propertyName,
    propertySection,
    updateCanvas,
  } = props;
  const { propertyValue } = selectedComponent;
  const handleSliderChange = (event, newValue) =>
    onChange(propertyName, newValue);

  const onChange = (key, value) => {
    let data = { ...propertyValue[propertySection], [key]: Number(value) };
    updateOptions(
      { ...propertyValue, [propertySection]: data },
      "propertyValue"
    );
  };

  return (
    <Grid container alignItems="center" display="flex">
      {label && (
        <Box
          display="flex"
          justify="center"
          alignItems="center"
          direction="row"
        >
          <span>{label}</span>
        </Box>
      )}
      <Grid
        item
        xs
        className="slider-wrapper"
        container
        display="flex"
        justify="flex-end"
      >
        <CustomizedSlider
          value={
            propertyValue && propertyValue[propertySection]
              ? Number(propertyValue[propertySection][propertyName])
              : 0
          }
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          step={0.1}
          min={0}
          max={1}
        />
      </Grid>
      <Grid item>
        <TextInputProperty
          type="number"
          inputStyle="property-textInput"
          value={
            propertyValue && propertyValue[propertySection]
              ? Number(propertyValue[propertySection][propertyName])
              : 0
          }
          onChange={(value) => onChange(propertyName, value)}
        />
      </Grid>
    </Grid>
  );
};

const CheckboxProperty = (props) => {
  const {
    label,
    isChecked = false,
    propertyName,
    handleOnChange,
    isDisabled = false,
  } = props;
  const classes = checkBoxStyles();
  const handleChange = (event) => {
    if (propertyName === "exposedVar" && event.target.checked) {
      props.openAddVariable(props.idVal);
    } else {
      handleOnChange(propertyName, event.target.checked);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      className={propertyName === "exposedVar" ? classes.checkBox : ""}
      display="flex"
      justify="flex-end"
    >
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checked={isChecked}
        disabled={isDisabled}
        checkedIcon={
          <span className={`${classes.icon} ${classes.checkedIcon}`} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        onChange={handleChange}
      />

      {label && (
        <Box
          display="flex"
          justify="center"
          alignItems="center"
          direction="row"
        >
          <span>{label}</span>
        </Box>
      )}
      {/* <Checkbox color="primary" checked={isChecked} onChange={handleChange} /> */}
    </Grid>
  );
};

export const AddDropzoneSection = (props) => {
  const { updateOptions, selectedComponent, label } = props;
  const [options, setOptions] = useState(
    selectedComponent.options
      ? selectedComponent.options
      : [{ label: "", value: "", checked: false }]
  );

  const addItem = () => {
    if (options.length === 0 || !_canNotHaveMultiItems()) {
      let data = [...options, { label: "", value: "", checked: false }];
      updateOptions(data);
      setOptions(data);
    } else {
      alertShow({
        type: "error",
        message:
          "To add more item(s), remove data-source mapping from this component first!",
      });
    }
  };

  useEffect(() => {
    if (JSON.stringify(options) !== JSON.stringify(selectedComponent.options)) {
      setOptions(selectedComponent.options);
    }
  }, [selectedComponent]);

  const _canNotHaveMultiItems = () => {
    //If Listing components are mapped with datasource, then we need to keep only one option (row) and remove rest options
    if (
      ["LISTVIEW"].indexOf(selectedComponent.type) > -1 &&
      selectedComponent.propertyValue.dataSource &&
      selectedComponent.propertyValue.dataSource[0].Data.selfVal
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (_canNotHaveMultiItems() && options.length > 1) {
      let data = [...options];
      data.length = 1;
      setOptions(data);
      updateOptions(data);
    }
  });

  const removeItem = (index) => {
    let data = [...options];
    data.splice(index, 1);
    setOptions(data);
    updateOptions(data);
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Add {label}</span>
        <span className="icon">
          <img src={AddIcon} className="eye-icon" onClick={addItem} />
        </span>
      </Grid>
      <Grid container className="grid-property-container">
        {options &&
          options.map((item, index) => {
            return (
              <Grid
                container
                display="flex"
                justify="space-between"
                alignItems="center"
                direction="row"
                className="text-property-cell"
              >
                <Box
                  display="flex"
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <span>
                    {label} {index + 1}
                  </span>
                </Box>
                <img
                  src={TrashIcon}
                  className="eye-icon"
                  onClick={() => removeItem(index)}
                />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

const CUSTOM_SELECTED_COMPONENTS = [
  "SELECT",
  "RADIO_BUTTON",
  "CHECKBOX",
  "AUTOCOMPLETE",
  "STEPPER",
];

export const OptionsSection = (props) => {
  const optionTypes = {
    CUSTOM: "custom",
    SOURCE: "source",
  };
  const { updateOptions, selectedComponent } = props;
  const [options, setOptions] = useState(
    selectedComponent.options
      ? selectedComponent.options
      : [{ label: "", value: "", checked: false }]
  );
  const [optionType, setOptionType] = useState();

  React.useEffect(() => {
    let type = optionTypes.CUSTOM;
    let dataSrc = selectedComponent.propertyValue.dataSource;
    if (dataSrc) {
      for (let index = 0; index < dataSrc.length; index++) {
        const dsItems = dataSrc[index];
        for (const key in dsItems) {
          if (
            CUSTOM_SELECTED_COMPONENTS.indexOf(selectedComponent.type) > -1 &&
            key === "Selected"
          ) {
            // For some of the components like "Radio" and "Select" we will not compare with "Selected" datasrouce
            continue;
          }

          for (const nestedKey in dsItems[key]) {
            if (dsItems[key][nestedKey]) {
              type = optionTypes.SOURCE;
            }
          }
        }
      }
    }
    setOptionType(type);
  }, [selectedComponent.propertyValue.dataSource]);

  React.useEffect(() => {
    if (selectedComponent.options && selectedComponent.options !== options) {
      setOptions(selectedComponent.options);
    }
    if (!selectedComponent.options) {
      setOptions([]);
    }
  }, [selectedComponent.id]);

  const removeOption = (index) => {
    let data = [...options];
    data.splice(index, 1);
    setOptions(data);
    updateOptions(data);
  };

  const handleOptionsUpdate = () => {
    if (
      options.length &&
      options[options.length - 1].label !== "" &&
      options[options.length - 1].value !== ""
    ) {
      updateOptions(options);
    }
  };

  const addOption = () => {
    let isAllowed = true;
    if (options.length > 0) {
      options.map((o) => {
        if (!o.label.trim()) {
          isAllowed = false;
          alertShow({
            message: "Option label can not be blank!",
            type: "error",
          });
          return false;
        }
      });
    }

    if (isAllowed) {
      setOptions([...options, { label: "", value: "", checked: false }]);
    }

    // if ((options.length && options[options.length - 1].label !== '') || options.length === 0) {
    //   setOptions([...options, { label: '', value: '', checked: false }]);
    // }
  };

  const updateValue = (index, key, value) => {
    let data = [...options];
    data[index][key] = value;
    setOptions(data);
  };

  useEffect(() => {
    if (optionType === optionTypes.CUSTOM) {
      // Reset Datasource mapping, if switches to custom
      let dataSource = _.cloneDeep(selectedComponent.propertyValue.dataSource);
      if (dataSource) {
        for (let index = 0; index < dataSource.length; index++) {
          const dsItems = dataSource[index];
          for (const key in dsItems) {
            if (
              CUSTOM_SELECTED_COMPONENTS.indexOf(selectedComponent.type) > -1 &&
              key === "Selected"
            ) {
              // For some of the components like "Radio" and "Select" we will not reset the value of "Selected" datasrouce
              continue;
            }
            for (const nestedKey in dsItems[key]) {
              dsItems[key][nestedKey] = "";
            }
          }
        }

        let data = {
          ...selectedComponent,
          propertyValue: {
            ...selectedComponent.propertyValue,
            dataSource: _.cloneDeep(dataSource),
          },
        };
        props.onSelect(data, true);
        //// updateOptions({ ...selectedComponent.propertyValue, dataSource: dataSrc }, 'propertyValue');
      }
    }
  }, [optionType]);

  const toggleOptionType = () => {
    const newtype =
      optionType === optionTypes.SOURCE
        ? optionTypes.CUSTOM
        : optionTypes.SOURCE;
    setOptionType(newtype);
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-options"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Options</span>

        <Grid justify="space-between" alignItems="center" direction="row">
          <DataSourceToggle
            checked={optionType === optionTypes.SOURCE}
            onChange={toggleOptionType}
            optionType={optionType}
            label={"Data source"}
          />

          {optionType === optionTypes.CUSTOM && (
            <span className="icon">
              <img src={AddIcon} className="eye-icon" onClick={addOption} />
            </span>
          )}
        </Grid>
      </Grid>
      <Grid container className="grid-property-container">
        {optionType === optionTypes.CUSTOM ? (
          <>
            <Grid
              container
              xs={12}
              className='text-property-cell'
              justify='space-between'
              alignItems='center'
              direction='row'
            >
              <span>Label</span>
              <span>Value</span>
              <span></span>
            </Grid>
            <Grid
              container
              xs={12}
              className='text-property-cell'
              justify='space-around'
              alignItems='center'
              direction='row'
            >
              {options &&
                options.map((option, idx) => {
                  return (
                    <>
                      <TextField
                        className="options-input"
                        type={"text"}
                        placeholder="Label"
                        disableUnderline
                        onChange={(e) =>
                          updateValue(idx, "label", e.target.value)
                        }
                        onBlur={handleOptionsUpdate}
                        value={option.label}
                      />
                      <TextField
                        className="options-input"
                        type={"text"}
                        placeholder="Value"
                        disableUnderline
                        onChange={(e) =>
                          updateValue(idx, "value", e.target.value)
                        }
                        onBlur={handleOptionsUpdate}
                        value={option.value}
                      />
                      <img
                        src={TrashIcon}
                        className="eye-icon trash-icon"
                        onClick={() => removeOption(idx)}
                      />
                    </>
                  );
                })}
            </Grid>
            {CUSTOM_SELECTED_COMPONENTS.indexOf(selectedComponent.type) >
              -1 && <DataSourceNew {...props} fieldsRequired={["Selected"]} />}
          </>
        ) : (
          <DataSourceNew {...props} />
        )}
      </Grid>
    </Grid>
  );
};

export const ArcOptionsSection = (props) => {
  const optionTypes = {
    CUSTOM: 'custom',
    SOURCE: 'source',
  };
  const { updateOptions, selectedComponent } = props;
  const [options, setOptions] = useState(
    selectedComponent.options
      ? selectedComponent.options
      : [{ percentage: '', color: '' }]
  );
  const [optionType, setOptionType] = useState();

  React.useEffect(() => {
    let type = optionTypes.CUSTOM;
    let dataSrc = selectedComponent.propertyValue.dataSource;
    if (dataSrc) {
      for (let index = 0; index < dataSrc.length; index++) {
        const dsItems = dataSrc[index];
        for (const key in dsItems) {
          if (
            CUSTOM_SELECTED_COMPONENTS.indexOf(selectedComponent.type) > -1 &&
            key === 'Selected'
          ) {
            // For some of the components like "Radio" and "Select" we will not compare with "Selected" datasrouce
            continue;
          }

          for (const nestedKey in dsItems[key]) {
            if (dsItems[key][nestedKey]) {
              type = optionTypes.SOURCE;
            }
          }
        }
      }
    }
    setOptionType(type);
  }, [selectedComponent.propertyValue.dataSource]);

  React.useEffect(() => {
    if (selectedComponent.options && selectedComponent.options !== options) {
      setOptions(selectedComponent.options);
    }
    if (!selectedComponent.options) {
      setOptions([]);
    }
  }, [selectedComponent.id]);

  const removeOption = (index) => {
    let data = [...options];
    data.splice(index, 1);
    setOptions(data);
    updateOptions(data);
  };

  const handleOptionsUpdate = () => {
    if (
      options.length &&
      options[options.length - 1].label !== '' &&
      options[options.length - 1].value !== ''
    ) {
      updateOptions(options);
    }
  };

  const addOption = () => {
    let isAllowed = true;
    if (options.length > 0) {
      options.map((o) => {
        if (!o.percentage.trim()) {
          isAllowed = false;
          alertShow({
            message: 'Option percentage can not be blank!',
            type: 'error',
          });
          return false;
        }
      });
    }

    if (isAllowed) {
      setOptions([...options, { percentage: '', color: 'black' }]);
    }
  };

  const updateValue = (index, key, value) => {
    let data = [...options];
    data[index][key] = value;
    setOptions(data);
  };

  useEffect(() => {
    if (optionType === optionTypes.CUSTOM) {
      // Reset Datasource mapping, if switches to custom
      let dataSource = _.cloneDeep(selectedComponent.propertyValue.dataSource);
      if (dataSource) {
        for (let index = 0; index < dataSource.length; index++) {
          const dsItems = dataSource[index];
          for (const key in dsItems) {
            if (
              CUSTOM_SELECTED_COMPONENTS.indexOf(selectedComponent.type) > -1 &&
              key === 'Selected'
            ) {
              // For some of the components like "Radio" and "Select" we will not reset the value of "Selected" datasrouce
              continue;
            }
            for (const nestedKey in dsItems[key]) {
              dsItems[key][nestedKey] = '';
            }
          }
        }

        let data = {
          ...selectedComponent,
          propertyValue: {
            ...selectedComponent.propertyValue,
            dataSource: _.cloneDeep(dataSource),
          },
        };
        props.onSelect(data, true);
        //// updateOptions({ ...selectedComponent.propertyValue, dataSource: dataSrc }, 'propertyValue');
      }
    }
  }, [optionType]);

  const toggleOptionType = () => {
    const newtype =
      optionType === optionTypes.SOURCE
        ? optionTypes.CUSTOM
        : optionTypes.SOURCE;
    setOptionType(newtype);
  };

  return (
    <Grid className='section-wrapper'>
      <Grid
        className='grid-list-options'
        container
        justify='space-between'
        alignItems='center'
        direction='row'
      >
        <span>Arc(s)</span>

        <Grid justify='space-between' alignItems='center' direction='row'>
          <DataSourceToggle
            checked={optionType === optionTypes.SOURCE}
            onChange={toggleOptionType}
            optionType={optionType}
            label={'Data source'}
          />

          {optionType === optionTypes.CUSTOM && (
            <span className='icon'>
              <img src={AddIcon} className='eye-icon' onClick={addOption} />
            </span>
          )}
        </Grid>
      </Grid>
      <Grid container className='grid-property-container'>
        {optionType === optionTypes.CUSTOM ? (
          <>
            <Grid
              container
              xs={12}
              className='text-property-cell'
              justify='space-between'
              alignItems='center'
              direction='row'
            >
              <span>Percentage</span>
              <span>Color</span>
              <span></span>
            </Grid>
            <Grid
              container
              xs={12}
              className='text-property-cell'
              justify='space-around'
              alignItems='center'
              direction='row'
            >
              {options &&
                options.map((option, idx) => {
                  return (
                    <>
                      <TextField
                        className='options-input'
                        type={'text'}
                        placeholder='Percentage'
                        disableUnderline
                        onChange={(e) => {
                          updateValue(idx, 'percentage', e.target.value)
                          handleOptionsUpdate()
                        }}
                        value={option.percentage}
                      />
                      <ColorPicker
                        className='property-palette'
                        size={30}
                        borderWidth={1}
                        hideTextfield
                        value={createColor(option.color)}
                        onChange={(e) => {
                          updateValue(idx, 'color', e?.css?.backgroundColor)
                          handleOptionsUpdate();
                        }}
                      />
                      <img
                        src={TrashIcon}
                        className='eye-icon trash-icon'
                        onClick={() => removeOption(idx)}
                      />
                    </>
                  );
                })}
            </Grid>
            {CUSTOM_SELECTED_COMPONENTS.indexOf(selectedComponent.type) >
              -1 && <DataSourceNew {...props} />}
          </>
        ) : (
          <DataSourceNew {...props} />
        )}
      </Grid>
    </Grid>
  );
};

const ExpandProperty = (props) => {
  const {
    label,
    icon = AddIcon,
    errorMsg = '',
    customCss,
    selectedComponent: { propertyValue },
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [id, setId] = useState(propertyValue.component.id);

  useEffect(() => {
    if (id == propertyValue.component.id) {
      if (
        propertyValue.style.custom &&
        Object.keys(propertyValue.style.custom).length > 0
      ) {
        if (!isExpanded) {
          setIsExpanded(true);
        }
      } else {
        if (!customCss) {
          setIsExpanded(false);
        }
      }
    } else {
      setIsExpanded(false);
      if (
        propertyValue.style.custom &&
        Object.keys(propertyValue.style.custom).length > 0
      ) {
        setTimeout(() => {
          setIsExpanded(true);
        }, 0);
      }
    }
  }, [propertyValue.style.custom]);

  useEffect(() => {
    setId(propertyValue.component.id);
  }, [propertyValue.component.id]);

  return (
    <Grid container display="flex" direction="column">
      <Grid
        container
        display="flex"
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        {label && (
          <Box
            display="flex"
            justify="center"
            alignItems="center"
            direction="row"
          >
            <span>
              {label}{" "}
              {errorMsg && (
                <Tooltip title="Invalid Json">
                  <span style={{ color: "red" }}>*</span>
                </Tooltip>
              )}
            </span>
          </Box>
        )}
        <img
          onClick={() => setIsExpanded(!isExpanded)}
          src={icon}
          className="eye-icon"
        />
      </Grid>
      {isExpanded && props.children}
    </Grid>
  );
};

const AddInputMenu = (props) => {
  const { label, value, propertyName, addInputProps, onChange, setVariableKeyName } = props
  const [targetAnchor, setTargetAnchor] = useState(null);
  return (
    <>
      <TextInputProperty
        label={label}
        disabled={true}
        value={value}
        onChange={(value) => { }}
        inputStyle='property-textInput component-text-input'
        handleEndIconClick={(e) => setTargetAnchor(e.currentTarget)}
        endIcon={<CellIcon style={{ fontSize: '1.5em', paddingRight: 8, color: '#000' }} />}
      />
      {
        targetAnchor &&
        <AddInput
          anchorEl={targetAnchor}
          setAnchorEl={setTargetAnchor}
          handleAddValue={(value) => onChange(propertyName, value)}
          openVariablePanel={() => props.setVariableKeyName(propertyName)}
          {...addInputProps}
        />
      }
    </>
  )
}

export const ComponentSection = (props) => {
  const {
    updateCanvas,
    updateOptions,
    selectedComponent,
    taskVariables,
    updateLinkTo,
    graphJson,
    pageName,
    details,
  } = props;

  const { property, propertyValue, type: componentType } = selectedComponent;
  const DEFAULT_ICON = "AddOutlined";
  const USER_TASK = "UserTask";
  const [openPicker, setOpenPicker] = useState(null);
  const [selectedIcon, setselectedIcon] = useState(null);
  const [navigateData, setNavigateData] = useState([]);
  const [isWorkflow, setWorkflow] = useState(details.type === "WFM");
  const iconRef = useRef(null);
  const classes = useStyles();
  const [showEnableDropdownAnchor, setEnebleDropdownAnchor] = useState(null);
  const [showHideDropdownAnchor, setShowHideDropdownAnchor] = useState(null);
  let schemaValue = getComponentDefaultSchema({
    data: {},
    isPage: false,
    gComponentName: "",
    componentId: "",
    componentName: componentType?.toLowerCase(),
  });
  // useEffect(() => {
  //   if (props.exposeVarSuccess.status === 'success') {
  //     propertyValue.component.exposedVar = true;
  //     propertyValue.component.isDisabled = true;
  //     updateOptions({ ...propertyValue }, 'propertyValue');
  //     if (props.clearApiMsg) {
  //       props.clearApiMsg();
  //     }
  //   }
  // }, [props.exposeVarSuccess]);

  useEffect(() => {
    if (details.type === "WFM") {
      setWorkflow(true);
    }
    _getStepTransitions();
  }, [details]);

  useEffect(() => {
    if (!!propertyValue.component.iconName) {
      setselectedIcon({
        Component: mui[`${propertyValue.component.iconName}Outlined`],
      });
    } else {
      setselectedIcon(null);
    }
  }, [propertyValue.component.iconName]);

  const onChange = (key, value) => {
    // console.log(key, value)
    let component = { ...propertyValue.component, [key]: value };
    if (key === "iconName" && value && !propertyValue.component.iconPosition) {
      // Incase of selecting icon for first time, we need to set the default icon position
      component['libraryIconName'] = "";
      component['iconPosition'] = DEFAULT_ICON_POSITION;
    } else if (key === "libraryIconName") {
      component['iconName'] = "";
      component['iconPosition'] = DEFAULT_ICON_POSITION;
    }
    let pv = { ...propertyValue };
    if (key === 'enabled' && selectedComponent.type != COMPONENT_NAMES.PANEL.toUpperCase()) {
      component = { ...propertyValue.component, [key]: value };
      let style = { ...pv.style, opacity: 1 };
      if (!isEnabled(value)) {
        style = { ...style, opacity: 0.5 };
      }
      pv = { ...pv, style };
    } else if (key === 'enabled' && selectedComponent.type === COMPONENT_NAMES.PANEL.toUpperCase()) {
      component = { ...propertyValue.component, [key]: isEnabled(value) };
    }
    updateOptions({ ...pv, component }, 'propertyValue');
  };

  const _getStepTransitions = () => {
    let stepTransitions = null;
    // if (isWorkflow) {
    //   const getTask = graphJson.steps.filter((item) => {
    //     return item.type === USER_TASK;
    //   });
    //   if (getTask && getTask.length > 0) {
    //     let arrSteps = [];
    //     let arrStepTransitions = [];
    //     getTask.map((item) => {
    //       arrSteps = [...arrSteps, ...(item?.steps || [])];
    //       graphJson.stepTransitions.map((val) => {
    //         if (item.name === val.stepName) {
    //           arrStepTransitions = [
    //             ...arrStepTransitions,
    //             ...(val?.stepTransitions || []),
    //           ];
    //         }
    //       });
    //       let groups = item.steps.filter((sT) => {
    //         return sT.type === 'Task';
    //       });
    //       groups &&
    //         groups.map((group) => {
    //           if (group.hasOwnProperty('steps'))
    //             arrSteps = [...arrSteps, ...group.steps];
    //           if (group.hasOwnProperty('stepTransitions'))
    //             arrStepTransitions = [
    //               ...arrStepTransitions,
    //               ...(group.stepTransitions || []),
    //             ];
    //         });
    //     });

    //     let graphSteps = {
    //       ...graphJson,
    //       steps: arrSteps,
    //       stepTransitions: arrStepTransitions,
    //       parentStepTransitions: graphJson.stepTransitions,
    //       parentSteps: graphJson.steps,
    //     };
    //     // stepTransitions = getStepTransitions(graphSteps, pageName, isWorkflow);
    //     stepTransitions = getStepNavigation(graphSteps, pageName, isWorkflow);
    //   }
    // } else {
    //   // stepTransitions = getStepNavigation(graphSteps, pageName, isWorkflow);
    //   stepTransitions = getStepTransitions(graphJson, pageName);
    // }
    stepTransitions = getStepNavigation(graphJson, pageName);
    if (stepTransitions && stepTransitions.length > 0) {
      setNavigateData([
        { label: "Select", value: "" },
        ...stepTransitions.map((s) => ({
          label: s.name,
          value: s.objectId,
          type: s.type,
        })),
      ]);
    }
  };

  // const _workflowTransitions = (data, page, val) => {
  //   if (data) {
  //     if (val.name) {
  //       let stepsTransitionsInfo = data.stepTransitions.filter((item) => {
  //         return item.stepName === val.name;
  //       });

  //       console.log('Steps: ', stepsInfo);
  //       console.log('Stepstransitions: ', stepsTransitionsInfo);

  //       data = {
  //         ...data,
  //         steps: item,
  //         stepTransitions: stepsTransitionsInfo,
  //       };
  //       return getStepTransitions(data, page);
  //     }
  //   }
  // };

  const _getDefaultIcon = () => {
    let defaultIcon = { Component: mui[DEFAULT_ICON] };
    return <defaultIcon.Component color="primary" />;
  };

  const getDefaultDataType = () => {
    let dataType = 'String';
    let componentName = propertyValue?.component?.componentName || "";
    if (componentName === COMPONENT_NAMES.TEXT_BOX) {
      let value = propertyValue?.component?.["keypadType"] || "";
      dataType = keypadTypes.find(e => e.value === value)?.type || "";
    }
    return dataType;
  }

  const removePageBGImage = () => {
    let jsonData = {
      ...props.selectedComponent,
      propertyValue: {
        ...props.selectedComponent.propertyValue,
        component: { ...props.selectedComponent.propertyValue.component },
      },
    };
    delete jsonData.imageUrl;
    delete jsonData.propertyValue.component.imageName;
    props.onSelect(jsonData, true);
  };

  const _checkIfExposedAsVariable = () => {
    let value = "";
    let index = schemaValue?.exposeVariableDSIndex || 0;
    let dataSrcProperty = property.dataSource?.[index] || {};
    let dataSrcValue = propertyValue.dataSource || [];
    if (schemaValue?.exposeVariableField)
      value = selectedComponent[schemaValue?.exposeVariableField] || "";
    else if (dataSrcProperty?.name)
      value = dataSrcValue?.[0]?.[dataSrcProperty?.name || ""]?.selfVal || "";
    return !!value && value?.startsWith("{{");
  };

  const canExposeVar = () => {
    return (
      ["GPS", "MAP", "ADDRESS_BOOK"].indexOf(selectedComponent?.type) === -1 &&
      (!!schemaValue?.exposeVariableField || property.dataSource?.length > 0)
    );
  };

  const handleExposeClick = () => {
    let compId = propertyValue.component.id;
    let index = schemaValue?.exposeVariableDSIndex || 0;
    let dataSrc = property.dataSource?.[index] || {};
    let varKey = "";
    if (schemaValue?.exposeVariableField)
      varKey = schemaValue.exposeVariableField;
    else if (dataSrc)
      varKey = `datasource_${JSON.stringify({
        index: 0,
        src: dataSrc?.name || "",
        label: dataSrc?.name || "",
        isArray: dataSrc?.isArray || false,
      })}`;
    props.setDataSourceKey(varKey);
    const variables = props?.varList?.variable || [];
    let existingVar = variables.find((v) => v?.name === compId);
    if (existingVar) {
      props.setConfirmVarExpose({
        flag: true,
        obj: existingVar,
        cb: props.assignExposedVar,
      });
    } else {
      let type = props.getCompatibleVarType(varKey);
      let dataType = getDefaultDataType();
      props.openAddVariable(compId, type, dataType);
    }
  };

  const getImage = (list = []) => {
    return list.filter(e => e.type === "IMG") || [];
  }

  const onLibraryFileClick = (icon = {}) => {
    onChange('libraryIconName', icon.name);
  }

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Component</span>
        <span className="icon">
          <AnchorLinkIcon />
        </span>
      </Grid>
      <Grid container className="grid-property-container">
        {showProperty(property.componentSection, "ID") && (
          <Grid container xs={12}>
            <Grid item xs={canExposeVar() ? 10 : 12}>
              <TextInputProperty
                label="ID"
                inputStyle="property-textInput component-text-input"
                value={propertyValue.component.id}
                onChange={(value) => onChange("id", value)}
                validateProperty={"ID"}
                selectedComponent={selectedComponent}
              />
            </Grid>
            {canExposeVar() && (
              <Grid item xs={2} className={classes.exposeGrid}>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleExposeClick}
                  disabled={_checkIfExposedAsVariable()}
                  className={`${classes.exposeButton} ${_checkIfExposedAsVariable() ? classes.disabled : ""
                    }`}
                />
              </Grid>
            )}
          </Grid>
        )}

        {/* {showProperty(property.componentSection, 'ExposeAsVariable') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <CheckboxProperty
              label='Expose As Variable'
              // isDisabled={propertyValue.component.isDisabled}
              // isChecked={propertyValue.component.exposedVar}
              isChecked={_checkIfExposedAsVariable()}
              isDisabled={_checkIfExposedAsVariable()}
              openAddVariable={props.openAddVariable}
              idVal={propertyValue.component.id}
              propertyName='exposedVar'
              handleOnChange={(key, value) => onChange(key, value)}
            />
          </Grid>
        )} */}

        {showProperty(property.componentSection, "MapType") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginBottom: 8 }}
          >
            <DropdownProperty
              label="MapType"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Hybrid", value: "Hybrid" },
                { label: "Roadmap", value: "Roadmap" },
                { label: "Satellite", value: "Satellite" },
                { label: "Terrain", value: "Terrain" },
              ]}

              value={propertyValue.component?.mapType ?? "Terrain"}
              propertyName="mapType"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "MapDraw") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginBottom: 8 }}
          >
            <DropdownProperty
              label="MapDraw"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Circle", value: "Circle" },
                { label: "Marker", value: "Marker" },
                // { label: "Polyline", value: "Polyline" },
                { label: "Polygon", value: "Polygon" },
                // { label: "Rectangle", value: "Rectangle" },
              ]}

              value={propertyValue.component?.mapDraw ?? "Markers"}
              propertyName="mapDraw"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "Title") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label={'Title'}
              inputStyle='property-textInput component-text-input title-property'
              value={selectedComponent.label}
              onChange={(value) => updateOptions(value, "label")}
              endIcon={
                VARIABLE_EXCEPTION_COMPONENTS.indexOf(componentType) === -1 ? (
                  <ArrowRightAltIcon />
                ) : undefined
              }
              handleEndIconClick={() => props.setVariableKeyName("label")}
              additonalProps={{
                rowsMax: 1,
                multiline: true,
              }}
            />
          </Grid>
        )}

        {/*Gauge Chart -> Data*/}
        {showProperty(property.componentSection, 'DataPercentage') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <TextInputProperty
              label='Data'
              placeHolder='Between 0 and 1'
              inputStyle='property-textInput component-text-input title-property'
              value={propertyValue.component.percentageValue || ""}
              onChange={(value) => onChange('percentageValue', value)}
              endIcon={<ArrowRightAltIcon />}
              handleEndIconClick={() => props.setVariableKeyName('percentageValue')}
            />
          </Grid>
        )}

        {/*Gauge Chart -> ArcGap*/}
        {showProperty(property.componentSection, 'ArcGap') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <TextInputProperty
              label='Arc Gap'
              inputStyle='property-textInput component-text-input title-property'
              value={propertyValue.component.arcGap || ""}
              onChange={(value) => onChange('arcGap', value)}
            />
          </Grid>
        )}

        {/*Gauge Chart -> NeedleColor*/}
        {showProperty(property.componentSection, 'NeedleColor') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <span>Needle Color</span>
            <ColorPicker
              className='property-palette'
              size={30}
              borderWidth={1}
              hideTextfield
              value={createColor(propertyValue.component.needleColor || "black")}
              onChange={(e) => {
                onChange('needleColor', e.css.backgroundColor)
              }}
            />
          </Grid>
        )}

        {showProperty(property.componentSection, 'Label') && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Label"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.placeholder || ""}
              onChange={(value) => onChange("placeholder", value)}
              endIcon={
                VARIABLE_EXCEPTION_COMPONENTS.indexOf(componentType) === -1 ? (
                  <ArrowRightAltIcon />
                ) : undefined
              }
              handleEndIconClick={() => props.setVariableKeyName("placeholder")}
            />
          </Grid>
        )}

        {/* {
          showProperty(property.componentSection, 'Source') &&
          <Grid container xs={12} className='text-property-cell' justify='space-between' alignItems='center' direction='row'>
            <DropdownProperty
              label='Source'
              inputStyle='property-textInput component-text-input'
              data={sources}
              value={propertyValue.component.source}
              propertyName='source'
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        } */}
        {/* {
          showProperty(property.componentSection, 'Variant') &&
          <Grid container xs={12} className='text-property-cell' justify='space-between' alignItems='center' direction='row'>
            <DropdownProperty
              label='Variant'
              inputStyle='property-textInput component-text-input'
              data={variants}
              value={propertyValue.component.variant}
              propertyName='variant'
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        } */}

        {(showProperty(property.componentSection, 'IconPosition') ||
          showProperty(property.componentSection, 'IconList')) && (
            <Grid
              container
              xs={12}
              className='text-property-cell'
              justify='space-between'
              alignItems='center'
              direction='row'
            >
              <span>{selectedComponent.type === 'PANEL' ? 'Position' : 'Icon'}</span>
              <Box
                style={{
                  width: 'calc(100% - 75px)',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {showProperty(property.componentSection, 'IconList') && (
                  <>
                    <Box
                      ref={iconRef}
                      onClick={() => {
                        setOpenPicker({ ref: iconRef.current });
                      }}
                      style={{
                        width: '32px',
                        height: '32px',
                        background: 'rgba(240, 242, 247, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      {!!selectedIcon &&
                        (selectedComponent.type === 'BOTTOMBAR' ||
                          selectedComponent.type === 'NAVBAR'
                          ? 'top'
                          : propertyValue.component.iconPosition) ? (
                        <selectedIcon.Component />
                      ) : (
                        !propertyValue.component.libraryIconName ?
                          _getDefaultIcon() :
                          <LibraryIcon iconName={propertyValue.component.libraryIconName} style={{ fontSize: 30 }} />
                      )}
                    </Box>
                    <IconPicker
                      openPicker={openPicker}
                      setOpenPicker={setOpenPicker}
                      placement='bottom-start'
                      setselectedIcon={(icon) => {
                        onChange('iconName', icon.name);
                        setselectedIcon(icon);
                      }}
                      theme='Outlined'
                      closeOnSelect={true}
                      libraryList={getImage(props.assetsList || [])}
                      onLibraryFileClick={onLibraryFileClick}
                    />
                  </>
                )}
                {showProperty(property.componentSection, "IconPosition") && (
                  <DropdownProperty
                    inputStyle="property-textInput component-text-input component-2nd-dropdown-input"
                    style={{ width: "calc(100% - 50px)" }}
                    data={
                      selectedComponent.type === "PANEL"
                        ? panelIconPosition
                        : buttonIconPosition
                    }
                    value={
                      selectedComponent.type === "BOTTOMBAR" ||
                        selectedComponent.type === "NAVBAR"
                        ? "top"
                        : propertyValue.component.iconPosition
                    }
                    disabled={
                      selectedComponent.type === "BOTTOMBAR" ||
                        selectedComponent.type === "NAVBAR"
                        ? true
                        : false
                    }
                    propertyName="iconPosition"
                    handleOnChange={(key, value) => onChange(key, value)}
                    {...props}
                  />
                )}
              </Box>
            </Grid>
          )}

        {/* Stepper Component > Step Optional Title */}
        {showProperty(property.componentSection, "optionalTitle") === true && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Optional Title"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.optionalTitle || ""}
              onChange={(value) => onChange("optionalTitle", value)}
              // endIcon={
              //   VARIABLE_EXCEPTION_COMPONENTS.indexOf(componentType) === -1 ? (
              //     <ArrowRightAltIcon />
              //   ) : undefined
              // }
              handleEndIconClick={() =>
                props.setVariableKeyName("optionalTitle")
              }
              inlineStyle={{ width: "calc(100% - 84px)" }}
            />
          </Grid>
        )}

        {/* Stepper Component stepper type */}
        {showProperty(property.componentSection, "stepperType") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <span>Stepper Type</span>
            <Box
              style={{
                width: "calc(100% - 82px)",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <DropdownProperty
                inputStyle="property-textInput component-text-input component-2nd-dropdown-input"
                style={{ width: "calc(100% - 50px)" }}
                data={stepperProperties.typeOptions}
                value={propertyValue.component.stepperType || ""}
                propertyName="stepperType"
                handleOnChange={(key, value) => onChange(key, value)}
                {...props}
              />
            </Box>
          </Grid>
        )}
        {/* Stepper Component > Step label position */}
        {showProperty(property.componentSection, "labelPosition") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <span>Label Position</span>
            <Box
              style={{
                width: "calc(100% - 85px)",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <DropdownProperty
                inputStyle="property-textInput component-text-input component-2nd-dropdown-input"
                style={{ width: "calc(100% - 50px)" }}
                data={stepperProperties.labelPositionOptions}
                value={propertyValue.component.labelPosition || ""}
                propertyName="labelPosition"
                handleOnChange={(key, value) => onChange(key, value)}
                {...props}
              />
            </Box>
          </Grid>
        )}

        {/* Stepper Component > Step stauts */}

        {showProperty(property.componentSection, "status") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Status"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.status || ""}
              onChange={(value) => onChange("status", value)} // completed/error
              endIcon={<ArrowRightAltIcon />}
              handleEndIconClick={() => props.setVariableKeyName("status")}
            />
          </Grid>
        )}

        {/* DataGrid Component > Header Cell > Sorting */}
        {showProperty(property.componentSection, "Sorting") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginBottom: 8 }}
          >
            <DropdownProperty
              label="Sorting"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Enable", value: "enable" },
                { label: "Disable", value: "disable" },
              ]}
              value={propertyValue.component.sortable ?? "enable"}
              propertyName="sortable"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}

        {/* DataGrid Component > Header Cell > Resizable */}
        {showProperty(property.componentSection, "Resizable") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginBottom: 8 }}
          >
            <DropdownProperty
              label="Resizable"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Enable", value: "enable" },
                { label: "Disable", value: "disable" },
              ]}
              value={propertyValue.component.resizable ?? "enable"}
              propertyName="resizable"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}

        {/* DataGrid Component > Header Cell > Editable */}
        {showProperty(property.componentSection, "Editable") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginBottom: 8 }}
          >
            <DropdownProperty
              label="Editable"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Enable", value: "enable" },
                { label: "Disable", value: "disable" },
              ]}
              value={propertyValue.component.editable ?? "enable"}
              propertyName="editable"
              handleOnChange={(key, value) => {
                let allowChange = true;
                if (value === "enable") {
                  if (
                    selectedComponent.type ===
                    COMPONENT_NAMES.DATAGRID_HEADER_CELL
                  ) {
                    const pv = props.getParentElementPropertyValue();
                    if (
                      ["checkbox", "row"].indexOf(pv?.component?.selection) ===
                      -1
                    ) {
                      allowChange = false;
                      alertShow({
                        message: `In order to enable editable, please make sure you have enabled the selection type for the Data grid.`,
                        type: "error",
                      });
                    }
                    if (allowChange) {
                      let hasDs = false;
                      if (propertyValue?.dataSource && Array.isArray(propertyValue.dataSource)) {
                        const [ds] = propertyValue.dataSource;
                        if (ds?.Data?.selfVal && ds.Data.selfVal.startsWith("{{")) {
                          hasDs = true;
                        }
                      }
                      if (!hasDs) {
                        allowChange = false;
                        alertShow({
                          message: `Please select Data to enable the edit option`,
                          type: 'error'
                        })
                      }
                    }

                  }
                }
                if (allowChange) {
                  onChange(key, value);
                }
              }}
              {...props}
            />
          </Grid>
        )}

        {/* Dialog > showHide */}
        {showProperty(property.componentSection, 'showHide') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            {/* <TextInputProperty
                  label='Show'
                  disabled={true}
                  value={propertyValue?.component?.show}
                  onChange={(value) => { }}
                  inputStyle='property-textInput component-text-input'
                  handleEndIconClick={(e) => setShowHideDropdownAnchor(e.currentTarget)}
                  endIcon={<CellIcon style={{ fontSize: '1.5em', paddingRight: 8, color: '#000' }} />}
                />
                {
                  showHideDropdownAnchor &&
                  <AddInput
                    anchorEl={showHideDropdownAnchor}
                    setAnchorEl={setShowHideDropdownAnchor}
                    handleAddValue={(value) => onChange('show', value)}
                    openVariablePanel={() => props.setVariableKeyName('show')}
                    showVariableOption
                    showBoolianOption
                  />
                } */}

            <AddInputMenu
              label='Show'
              value={propertyValue?.component?.show}
              propertyName={"show"}
              onChange={onChange}
              setVariableKeyName={props.setVariableKeyName}
              addInputProps={{
                showVariableOption: true,
                showBoolianOption: true,
              }}
            />

          </Grid>
        )}

        {/* Progress Bar > Autoclose */}
        {showProperty(property.componentSection, 'autoclose') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <AddInputMenu
              label='Autoclose'
              value={propertyValue?.component?.autoclose}
              propertyName={"autoclose"}
              onChange={onChange}
              setVariableKeyName={props.setVariableKeyName}
              addInputProps={{
                showVariableOption: true,
                showBoolianOption: true,
              }}
            />

          </Grid>
        )}

        {/* Progress Bar > ProgressStatus */}
        {showProperty(property.componentSection, 'progressStatus') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <AddInputMenu
              label='Status'
              value={propertyValue?.component?.progressStatus}
              propertyName={"progressStatus"}
              onChange={onChange}
              setVariableKeyName={props.setVariableKeyName}
              addInputProps={{
                inlineOptions: [
                  { value: 'STATIC.inprogress', label: 'In Progress', Icon: null },
                  { value: 'STATIC.failed', label: 'Failed', Icon: null },
                  { value: 'STATIC.completed', label: 'Completed', Icon: null },
                ],
                showVariableOption: true,
              }}
            />

          </Grid>
        )}

        {showProperty(property.componentSection, 'progressPercentage') && (
          <Grid
            container
            xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <TextInputProperty
              label='Progress'
              type={typeof propertyValue.component.progressPercentage === "string" && propertyValue.component.progressPercentage.startsWith("{{") ? "text" : "number"}
              inputStyle='property-textInput component-text-input'
              value={propertyValue.component.progressPercentage}
              onChange={(value) => onChange('progressPercentage', value)}
              endIcon={
                VARIABLE_EXCEPTION_COMPONENTS.indexOf(componentType) === -1 ? (
                  <ArrowRightAltIcon />
                ) : undefined
              }
              handleEndIconClick={() => props.setVariableKeyName('progressPercentage')}
            />

          </Grid>
        )}
        {showProperty(property.componentSection, 'maxWidth') && (
          <Grid
            container
            xs={12}
            className='text-property-cell'
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <DropdownProperty
              label='maxWidth'
              inputStyle='property-textInput component-text-input'
              data={[{
                id: '1',
                value: true,
                label: 'Center',
              }, {
                id: '2',
                value: false,
                label: 'Full Width',
              }]}
              value={propertyValue.component.maxWidth}
              propertyName='maxWidth'
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, 'Sizing') && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Sizing"
              inputStyle="property-textInput component-text-input"
              data={sizings}
              value={propertyValue.component.sizing}
              propertyName="sizing"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {/* 
          showProperty(property.componentSection, 'Color') &&
          <Grid container xs={12} className='text-property-cell' justify='space-between' alignItems='center' direction='row'>
            <DropdownProperty
              label='Color'
              inputStyle='property-textInput component-text-input'
              data={colors}
              propertyName='color'
              value={propertyValue.component.color}
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
         */}
        {showProperty(property.componentSection, "Type") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Type"
              inputStyle="property-textInput component-text-input"
              data={headerTypes}
              propertyName="type"
              value={propertyValue.component.type}
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "LabelType") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Type"
              inputStyle="property-textInput component-text-input"
              data={labelTypes}
              propertyName="labelType"
              value={propertyValue.component.labelType}
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "KeypadType") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Type"
              inputStyle="property-textInput component-text-input"
              data={keypadTypes}
              propertyName="keypadType"
              value={propertyValue.component.keypadType}
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "Placeholder") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Placeholder"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.placeholder}
              onChange={(value) => onChange("placeholder", value)}
              endIcon={
                VARIABLE_EXCEPTION_COMPONENTS.indexOf(componentType) === -1 ? (
                  <ArrowRightAltIcon />
                ) : undefined
              }
              handleEndIconClick={() => props.setVariableKeyName("placeholder")}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "MultiSelect") && (
          <Grid
            container
            xs={9}
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{ paddingRight: "10px" }}
          >
            <CheckboxProperty
              label="Multi Select"
              propertyName={"multiselect"}
              idVal={propertyValue.component.id}
              handleOnChange={(key, value) => onChange(key, value)}
              isChecked={propertyValue?.component?.multiselect || false}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, 'InlineSearch') && (
          <Grid
            container
            xs={12}
            className='text-property-cell'
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <CheckboxProperty
              label='Inline Search'
              propertyName={'inlinesearch'}
              idVal={propertyValue.component.id}
              handleOnChange={(key, value) => onChange(key, value)}
              isChecked={(propertyValue?.component?.inlinesearch != undefined) ? propertyValue?.component?.inlinesearch : true}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, 'Rows') && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Rows"
              type="number"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.rows}
              onChange={(value) => onChange("rows", value)}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "Selection") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Selection"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Dont Allow", value: "none" },
                { label: "Row Selection", value: "row" },
                { label: "Checkbox", value: "checkbox" },
              ]}
              value={propertyValue.component.selection ?? "none"}
              propertyName="selection"
              updateLinkTo={updateLinkTo}
              // handleOnChange={(key, value) => onChange(key, value)}
              handleOnChange={(key, value) => {
                let allowChange = true;
                if (
                  value === "none" &&
                  [COMPONENT_NAMES.DATAGRID.toUpperCase()].indexOf(
                    selectedComponent.type
                  ) > -1
                ) {
                  let editable = false;
                  Object.keys(propertyValue).forEach((pvKey) => {
                    const pv = propertyValue[pvKey];
                    if (!editable && pvKey.startsWith("table-heading-col-")) {
                      const cellIndex = pvKey.split("-")[3] || "";
                      if (
                        cellIndex < propertyValue.component?.columns &&
                        pv.component?.editable === "enable"
                      ) {
                        editable = true;
                      }
                    }
                  });
                  if (editable) {
                    allowChange = false;
                    alertShow({
                      message: `In order to remove selection type, please make sure the Datagrid columns are non editable.`,
                      type: "error",
                    });
                  }
                }
                if (allowChange) {
                  onChange(key, value);
                }
              }}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "Columns") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Columns"
              type="number"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.columns}
              onChange={(value) => onChange("columns", value)}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "Toolbar") && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Toolbar"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Hide", value: "hide" },
                { label: "Show", value: "show" },
              ]}
              value={propertyValue.component.toolbar ?? "hide"}
              propertyName="toolbar"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.componentSection, "Step") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              label="Step"
              type="number"
              inputStyle="property-textInput component-text-input"
              value={propertyValue.component.step}
              onChange={(value) => onChange("step", value)}
            />
          </Grid>
        )}

        {(showProperty(property.componentSection, "Min") ||
          showProperty(property.componentSection, "Max")) && (
            <Grid
              container
              xs={12}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              {showProperty(property.componentSection, "Min") && (
                <Grid
                  container
                  xs={6}
                  className="property-cell"
                  justify="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <TextInputProperty
                    label="Min"
                    type="number"
                    inputStyle="property-textInput"
                    value={propertyValue.component.min}
                    onChange={(value) => onChange("min", value)}
                  />
                </Grid>
              )}
              {showProperty(property.componentSection, "Max") && (
                <Grid
                  container
                  xs={6}
                  className="property-cell"
                  justify="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <TextInputProperty
                    label="Max"
                    type="number"
                    inputStyle="property-textInput"
                    value={propertyValue.component.max}
                    onChange={(value) => onChange("max", value)}
                  />
                </Grid>
              )}
            </Grid>
          )}
        {/* {showProperty(property.componentSection, 'Latitude') && (
          <Grid container xs={12} justify="space-between" alignItems="center" direction="row">
            <TextInputProperty label="Latitude" type="number" inputStyle="property-textInput component-text-input" value={propertyValue.component.latitude} onChange={(value) => onChange('latitude', value)} />
          </Grid>
        )}
        {showProperty(property.componentSection, 'Longitude') && (
          <Grid container xs={12} justify="space-between" alignItems="center" direction="row">
            <TextInputProperty label="Longitude" type="number" inputStyle="property-textInput component-text-input" value={propertyValue.component.longitude} onChange={(value) => onChange('longitude', value)} />
          </Grid>
        )} */}
        {/* {showProperty(property.componentSection, 'Link') && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              label="Navigate To"
              inputStyle="property-textInput component-text-input"
              data={
                // taskVariables &&
                // taskVariables?.data &&
                // taskVariables?.data?.screens?.length > 0 && [{ label: 'Select', value: '' }, ...taskVariables.data.screens.map((taskVariable) => ({ label: taskVariable.name, value: taskVariable.objectId })), { label: 'End', value: taskVariables?.data?.end?.objectId }]

                // graphJson &&
                // graphJson?.steps &&
                // graphJson?.steps?.length > 0 && [{ label: 'Select', value: '' }, ...graphJson.steps.map((stepInfo) => ({ label: stepInfo.name, value: stepInfo.uid }))]

                navigateData
              }
              value={propertyValue.component.link}
              propertyName="link"
              updateLinkTo={updateLinkTo}
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )} */}
        {/* FileUpload Options*/}
        {showProperty(property.componentSection, "UploadOptions") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginTop: 8 }}
          >
            <DropdownProperty
              label="Options"
              inputStyle="property-textInput component-text-input"
              data={[
                { label: "Default", value: "Default" },
                { label: "Camera", value: "Camera" },
                { label: "Gallery", value: "Gallery" },
              ]}

              value={propertyValue.component?.uploadOptions ?? "Default"}
              propertyName="uploadOptions"
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            />
          </Grid>
        )}
        
        {showProperty(property.componentSection, 'Enabled') && (
          <Grid
            container
            xs={12}
            className="text-property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            {/*<CheckboxProperty
              label={
                propertyValue.component.componentName === "panel"
                  ? "Always open"
                  : "Enabled"
              }
              isChecked={propertyValue.component.enabled}
              propertyName="enabled"
              handleOnChange={(key, value) => onChange(key, value)}
            />*/}

            <TextInputProperty
              disabled={true}
              label={
                selectedComponent.type === COMPONENT_NAMES.PANEL.toUpperCase()
                  ? 'Show'
                  : 'Enabled'
              }
              value={propertyValue?.component?.enabled}
              onChange={(value) => { }}
              inputStyle='property-textInput component-text-input'
              handleEndIconClick={(e) => setEnebleDropdownAnchor(e.currentTarget)}
              endIcon={<CellIcon style={{ fontSize: '1.5em', paddingRight: 8, color: '#000' }} />}
            />
            {
              showEnableDropdownAnchor &&
              <EnableDiableInput
                anchorEl={showEnableDropdownAnchor}
                setAnchorEl={setEnebleDropdownAnchor}
                handleAddValue={(value) => onChange('enabled', value)}
                openVariablePanel={() => props.setVariableKeyName('enabled')}
                showVariableOption
                showBoolianOption
              />
            }
          </Grid>
        )}

        {showProperty(property.componentSection, "ImageName") &&
          propertyValue.component.imageName !== "" && (
            <Grid
              container
              xs={12}
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <TextInputProperty
                label="Image"
                inputStyle="property-textInput component-text-input"
                value={propertyValue.component.imageName || ""}
                disabled
                endIcons={[
                  ...(propertyValue.component.imageName
                    ? [
                      <CancelIcon
                        className="cancel"
                        onClick={removePageBGImage}
                      />,
                    ]
                    : []),
                ]}
              />
            </Grid>
          )}

        {showProperty(property.componentSection, "Image") && (
          <Grid
            container
            xs={12}
            className="property-cell edit-grid"
            justify="space-between"
            alignItems="center"
            direction="row"
            onClick={() => props.setState({ showChangeImageDialog: true })}
          >
            <ButtonProperty label="Change Image" icon={<ImageIcon />} />
          </Grid>
        )}

        {(!property.optionsSection &&
          property.dataSource?.length > 0 &&
          [constants.GAUGE_CHART].indexOf(selectedComponent.type) === -1) && (
            <Grid
              container
              xs={12}
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <DataSourceNew {...props} />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export const MenuSection = (props) => {
  const rawData = {
    BOTTOMBAR: {
      type: "BOTTOMBAR",
      label: "Bottom bar",
      payload: null,
      propertyValue: {
        component: {
          id: "bottom_bar",
          source: "",
          variant: "",
        },
        style: {
          backgroundColor: "rgb(0, 98, 255)",
          opacity: "1",
          custom: {},
        },
        layout: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
        events: [["click", "click"]],
      },
      options: [1, 2, 3, 4],
      imageUrl: "",
      disableDraggable: false,
      showPreview: true,
      isMenuPage: true,
    },
    NAVBAR: {
      type: "NAVBAR",
      label: "Navbar",
      payload: null,
      propertyValue: {
        component: {
          id: "navbar",
          source: "",
          variant: "",
        },
        style: {
          backgroundColor: "rgb(255, 255, 255)",
          opacity: "1",
          custom: {},
        },
        layout: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
        events: [
          ["click", "click"],
          ["change", "change"],
        ],
      },
      options: [1, 2, 3, 4],
      imageUrl: "",
      disableDraggable: false,
      showPreview: true,
      isMenuPage: true,
    },
    PANEL: {
      type: "PANEL",
      label: "Panel",
      payload: null,
      propertyValue: {
        component: {
          id: "panel",
          variant: "",
          enabled: false,
          iconPosition: "left",
        },
        style: {
          backgroundColor: "rgb(0, 98, 255)",
          opacity: "1",
          custom: {},
        },
        layout: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
        events: [
          ["click", "click"],
          ["change", "change"],
        ],
      },
      options: [
        {
          label: "",
          value: "",
          checked: false,
        },
        {
          label: "",
          value: "",
          checked: false,
        },
        {
          label: "",
          value: "",
          checked: false,
        },
      ],
      imageUrl: "",
      disableDraggable: false,
      showPreview: true,
      isMenuPage: true,
    },
  };
  const menuStyleOptions = [
    { id: "1", value: "BOTTOMBAR", label: "Bottom" },
    { id: "2", value: "NAVBAR", label: "Navigation" },
    { id: "3", value: "PANEL", label: "Panel" },
  ];

  const { propertyValue, updateOptions, graphJson, details } = props;

  const onChange = (key, value) => {
    let component = { ...propertyValue.component, [key]: value };

    props.onSelect({
      ...props._getPageProperties(),
      propertyValue: { ...propertyValue, component },
    });
    props.setState({ addAction: true });
    setTimeout(() => {
      updateOptions({ ...propertyValue, component }, "propertyValue");
      props.updateAPMMenu(value);
      props.setState({ addAction: false });
    }, 510);
  };

  const handleMenuStyleChange = (key, value) => {
    if (!value) return;

    let component = { ...propertyValue.component, [key]: value };
    let newPropertyValue = { ...propertyValue, component };
    if (value === "BOTTOMBAR") {
      if (!newPropertyValue?.text?.color) {
        newPropertyValue.text = { ...newPropertyValue.text, color: "rgb(255, 255, 255)" }
      }
    } else {
      if (newPropertyValue?.text?.color === "rgb(255, 255, 255)") {
        newPropertyValue.text = { ...newPropertyValue.text, color: "" }
      }
    }
    updateOptions({ ...newPropertyValue }, "propertyValue");

    const { onDrop } = props;
    state.clearState(() => {
      onDrop(rawData[value]);
    });
  };

  const menyStyleComponent = (
    <ListItem>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          Menu Style
        </Grid>
        <Grid item xs={6}>
          <DropdownProperty
            data={menuStyleOptions}
            value={propertyValue.component?.menuStyle}
            propertyName="menuStyle"
            handleOnChange={handleMenuStyleChange}
            input={<Input />}
          />
        </Grid>
      </Grid>
    </ListItem>
  );

  return (
    <MenuProperties
      onClose={() => { }}
      updateMenuProperties={({ data }) => onChange("links", data)}
      links={props.selectedComponent.propertyValue?.component?.links}
      options={props.getMenuLinkOptions()}
      navigateToMenuDesigner={() => { }}
      showEditStyleButton={false}
      renderMenyStyleComponent={menyStyleComponent}
      containerStyle={{ width: "100%" }}
      showCloseIcon={false}
      isWorkflow={details.type === "WFM"}
      isLoginMicroApp={props.isLoginMicroApp}
    />
  );
};
export const InteractionSection = (props) => {
  const { selectedComponent, updateOptions, details, updateLinkTo, graphJson, pageName } = props;
  const { handeleUpdateInteractions = () => null } = props;
  const { propertyValue, property } = selectedComponent;
  const { component } = propertyValue;
  const [navigateData, setNavigateData] = useState([]);
  const [interactions, setInteractions] = useState([]);
  let defaultSelectObj = { label: 'Select', value: '' }

  useEffect(() => {
    setNavigateData(_getStepTransitions());
  }, [details, graphJson]);

  useEffect(() => {
    let connector = _getStepTransitions();
    let interAction = component?.interactions || [];
    interAction = interAction.map(e => {
      let flag = false;
      if (["opendialog", "closedialog"].indexOf(e.navigationType) > -1) {
        flag = getDialogOptions().find(o => o.value === e?.transitionId)
      } else {
        flag = connector.find(o => o.value === e?.transitionId)

        // connector.forEach(f => { 
        //   if(f?.value === e?.transitionId){ flag = true; }
        // });
      }
      return !flag ? null : e;
    });
    interAction = interAction.filter(e => e);
    setInteractions(interAction);
    if (JSON.stringify(interAction) !== JSON.stringify(component?.interactions)) {
      updatePayload(interAction);
    }
  }, [selectedComponent]);

  const isValidInteractions = (obj = {}) => {
    return obj.type && obj.transitionId;
  }

  const updatePayload = (navActions = []) => {
    updateOptions(
      {
        ...propertyValue,
        component: {
          ...(component || {}),
          interactions: navActions
        }
      },
      'propertyValue'
    );
  }

  const updateAPMJson = (transitionId = "", oldObj = {}, newObj = {}, isDelete = false, replaceId = "") => {
    let payload = {
      type: newObj.type,
      name: propertyValue.component?.id,
      id: selectedComponent.id,
      cellId: selectedComponent.cellId,
    }
    oldObj = { ...payload, ...oldObj };
    handeleUpdateInteractions(transitionId, oldObj, payload, isDelete, replaceId);
  }

  const onChange = (key, value, index) => {
    let navActions = [...interactions];
    let oldObj = { ...navActions[index] };
    let replaceId = key === "transitionId" ? navActions[index]?.transitionId : "";
    navActions[index][key] = value;
    setInteractions(navActions);
    if (isValidInteractions(navActions[index])) {
      updateAPMJson(
        navActions[index]?.transitionId,
        oldObj,
        navActions[index],
        false,
        replaceId
      );
      updatePayload(navActions);
    }
  };

  const onAdd = () => {
    let navActions = [...(interactions), { type: "", transitionId: "", navigationType: "navigate" }];
    setInteractions(navActions);
  }

  const onDelete = (index) => {
    let navActions = [...interactions];
    let deletedObj = navActions[index];
    navActions = navActions.filter((e, i) => index !== i);
    setInteractions(navActions);
    updateAPMJson(deletedObj?.transitionId, deletedObj, {}, true);
    updatePayload(navActions);
  }

  const _getStepTransitions = () => {
    let stepTransitions = null;
    let gson = graphJson;
    let taskName = props.location.state?.TaskName || "";
    if (details.type === 'WFM') {
      const getTask = graphJson.steps?.filter((item) => item.type === "UserTask");
      if (getTask && getTask.length > 0) {
        let arrSteps = [], arrStepTransitions = [];
        getTask.map((item) => {
          arrSteps = [...arrSteps, ...(item?.steps || [])];
          graphJson.stepTransitions.map((val) => {
            if (item.name === val.stepName) {
              arrStepTransitions = [...arrStepTransitions, ...(val?.stepTransitions || [])];
            }
          });
          let groups = item.steps.filter((sT) => sT.type === 'Task');
          groups && groups.map((group) => {
            if (group.hasOwnProperty('steps'))
              arrSteps = [...arrSteps, ...group.steps];
            if (group.hasOwnProperty('stepTransitions'))
              arrStepTransitions = [...arrStepTransitions, ...(group.stepTransitions || [])];
          });
        });
        gson = {
          ...graphJson,
          steps: arrSteps,
          stepTransitions: arrStepTransitions,
          parentStepTransitions: graphJson.stepTransitions,
          parentSteps: graphJson.steps,
        };
      }
    } else if (!!taskName) {
      let arrSteps = [], arrStepTransitions = [];
      let group = graphJson.steps?.find((sT) => sT.name === taskName);
      if (group && group.hasOwnProperty('steps'))
        arrSteps = [...arrSteps, ...group.steps];
      if (group && group.hasOwnProperty('stepTransitions'))
        arrStepTransitions = [...arrStepTransitions, ...(group.stepTransitions || [])];

      gson = {
        ...graphJson,
        steps: arrSteps,
        parentSteps: graphJson.steps,
        stepTransitions: arrStepTransitions,
        parentStepTransitions: graphJson.stepTransitions,
      };
    }
    stepTransitions = getStepTransitions(gson, pageName, details.type === "WFM", true, !!taskName);
    if (stepTransitions && stepTransitions.length > 0) {
      return [
        defaultSelectObj,
        ...stepTransitions.map((s) => ({
          label: s.name,
          value: s.objectId,
          type: s.type,
        })),
      ];
    } else {
      return [];
    }
  };

  const getEvents = (interaction = {}) => {
    let alreadySelectedEvents = interactions.map(e => e.type);
    alreadySelectedEvents = alreadySelectedEvents.filter(e => !!e && interaction.type !== e);
    let requiredEvents = property?.interactionSection?.filter(e => !alreadySelectedEvents.includes(e[1])) || [];
    let initObj = interaction?.type ? [] : [defaultSelectObj];
    return [
      ...initObj,
      ...(requiredEvents?.map(e => {
        let label = e[0];
        if (label && !label.startsWith("on")) {
          label = "on" + capitalize(label);
        }
        return { label, value: e[1] }
      }) || [])
    ];
  }

  const getNavigationData = (interaction = {}) => {
    let navData = [...navigateData];
    if (interaction?.transitionId) {
      navData = navData.filter(e => !!e.value);
    }
    return navData;
  }

  const canAddInteraction = () => {
    let anyEmpty = interactions?.filter(e => !e.type || !e.transitionId) || [];
    return property?.interactionSection?.length > interactions?.length && !anyEmpty.length;
  }

  const getNavigationType = () => {
    let navData = [
      {
        id: 'action_1',
        value: 'navigate',
        label: 'Navigate to',
      },
      {
        id: 'action_2',
        value: 'opendialog',
        label: 'Open Dialog',
      },
      {
        id: 'action_3',
        value: 'closedialog',
        label: 'Close Dialog',
      }
    ];

    return navData;
  }

  const getDialogOptions = () => {
    const EXCEPTIONAL_DIALOG = [];
    if (selectedComponent.type === "DIALOG") {
      EXCEPTIONAL_DIALOG.push(selectedComponent.id);
    }

    const [page] = state.getState();
    let dialogData = page.fields.filter(f => f.type === "DIALOG" && EXCEPTIONAL_DIALOG.indexOf(f.id) === -1).map(f => {
      return {
        id: f.id,
        value: f.id,
        label: `Dialog #${f.propertyValue.component.id}`,
      }
    })
    dialogData = [
      {
        id: 'action_0',
        value: '',
        label: 'Select Dialog',
      },
      ...dialogData
    ];

    return dialogData;
  }

  return (
    <Grid className='section-wrapper'>
      <Grid
        className='grid-list-title'
        container
        justify='space-between'
        alignItems='center'
        direction='row'
      >
        <span>Interactions</span>
        <span className='icon tooltip-icon'>
          {canAddInteraction() && <mui.AddOutlined onClick={onAdd} />}
        </span>
      </Grid>
      {
        interactions?.length ?
          <Grid
            container xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
            className='grid-property-container'
          >
            {
              interactions.map((e, index) => (
                <Grid container xs={12} className='fields' justify='space-between' alignItems='center' direction='row'>
                  <Grid item xs={6} className='fields' justify='space-between' alignItems='center' direction='row'>
                    <DropdownProperty
                      {...props}
                      label=''
                      value={e.type}
                      data={getEvents(e)}
                      propertyName={'type'}
                      inputStyle={'property-textInput component-text-input2'}
                      handleOnChange={(key, value) => onChange(key, value, index)}
                    />
                  </Grid>
                  <Grid item xs={6} className='fields' justify='space-between' alignItems='center' direction='row'>
                    <DropdownProperty
                      {...props}
                      label=''
                      propertyName={'link'}
                      value={e.navigationType}
                      updateLinkTo={updateLinkTo}
                      data={getNavigationType(e)}
                      inputStyle={'property-textInput component-text-input2'}
                      handleOnChange={(key, value) => onChange("navigationType", value, index)}
                    />
                  </Grid>
                  <Grid item xs={11} className='fields' justify='space-between' alignItems='center' direction='row'>
                    {e.navigationType === "navigate" ? (
                      <DropdownProperty
                        {...props}
                        label=''
                        propertyName={'link'}
                        value={e.transitionId}
                        updateLinkTo={updateLinkTo}
                        data={getNavigationData(e)}
                        inputStyle={'property-textInput component-text-input2'}
                        handleOnChange={(key, value) => onChange("transitionId", value, index)}
                      />
                    ) : (
                      <DropdownProperty
                        {...props}
                        label=''
                        propertyName={'link'}
                        value={e.transitionId}
                        updateLinkTo={updateLinkTo}
                        data={getDialogOptions(e)}
                        inputStyle={'property-textInput component-text-input2'}
                        handleOnChange={(key, value) => onChange("transitionId", value, index)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1} className='fields' justify='space-between' alignItems='center' direction='row'>
                    <img src={TrashIcon} onClick={() => onDelete(index)} className='cursor' />
                  </Grid>
                </Grid>
              ))
            }
          </Grid> : null
      }
    </Grid>
  );
}

export const GridSection = (props) => {
  const MAX_GRID_ROWS = 5;
  const MAX_GRID_COLS = 12;
  const COLUMNS_ALLOWED = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const ROWS_ALLOWED = [1, 2, 3, 4, 5];

  const { updateCanvas, updateOptions, selectedComponent } = props;
  const { property, propertyValue } = selectedComponent;
  const onChange = (key, value) => {
    let grid = { ...propertyValue.grid, [key]: value };
    updateOptions({ ...propertyValue, grid }, "propertyValue");
  };
  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Grid</span>
        <span className="icon">
          <AnchorLinkIcon />
        </span>
      </Grid>
      <Grid container cols={2} className="grid-property-container">
        {showProperty(property.gridSection, "Rows") && (
          <Grid
            container
            xs={6}
            className="property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            {/* <TextInputProperty
              type="number"
              label="Rows"
              value={propertyValue.grid.rows}
              inputStyle="property-textInput"
              onChange={(value) => {
                if (Number(value) <= MAX_GRID_ROWS){
                  onChange('rows', Number(value));
                }else{
                  alertShow({
                    message: `Maximum ${MAX_GRID_ROWS} rows are allowed!`,
                    type: 'error'
                  })
                }
              }}
            /> */}
            <span>Rows</span>
            <NativeSelect
              variant="filled"
              id="environment"
              className={`native-select`}
              input={<OutlinedInput />}
              value={propertyValue.grid.rows}
              onChange={(e) => {
                onChange("rows", Number(e.target.value));
              }}
            >
              {ROWS_ALLOWED.map((row) => (
                <option value={row}>{row}</option>
              ))}
            </NativeSelect>
          </Grid>
        )}
        {showProperty(property.gridSection, "Columns") && (
          <Grid
            container
            xs={6}
            className="property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            {/* <TextInputProperty
              type="number"
              label="Columns"
              value={propertyValue.grid.columns}
              inputStyle="property-textInput"
              onChange={(value) => {
                if (Number(value) <= MAX_GRID_COLS) {
                  onChange('columns', Number(value));
                } else {
                  alertShow({
                    message: `Maximum ${MAX_GRID_COLS} columns are allowed!`,
                    type: 'error'
                  })
                }
              }}
            /> */}
            <span>Columns</span>
            <NativeSelect
              variant="filled"
              id="environment"
              className={`native-select`}
              input={<OutlinedInput />}
              value={propertyValue.grid.columns}
              onChange={(e) => {
                onChange("columns", Number(e.target.value));
              }}
            >
              {COLUMNS_ALLOWED.map((col) => (
                <option value={col}>{col}</option>
              ))}
            </NativeSelect>
          </Grid>
        )}
        {/*
          showProperty(property.gridSection, 'Width') && (
            <Grid container xs={6} className='property-cell' justify='space-between' alignItems='center' direction='row'>
              <TextInputProperty
                label='Width'
                inputStyle='property-textInput'
              />
            </Grid>
          )*/}
        {showProperty(property.gridSection, "Gutter") && (
          <Grid
            container
            xs={6}
            className="property-cell"
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <TextInputProperty
              type="number"
              label="Gutter"
              inputStyle="property-textInput"
              value={propertyValue.grid.gutter}
              onChange={(value) => {
                onChange("gutter", Number(value));
              }}
            />
          </Grid>
        )}
        {/*
          showProperty(property.gridSection, 'Edit Grid') && (
            <Grid container xs={12} className='property-cell edit-grid' justify='space-between' alignItems='center' direction='row'>
              <ButtonProperty
                label='Edit Grid'
                icon={<EditGridIcon />}
              />
            </Grid>
          )*/}
      </Grid>
    </Grid>
  );
};

export const TooltipSection = (props) => {
  const { selectedComponent, updateOptions } = props;
  const { propertyValue } = selectedComponent;
  const [textValue, setTextValue] = useState(
    propertyValue?.tooltip?.title || ""
  );

  useEffect(() => {
    setTextValue(propertyValue?.tooltip?.title || "");
  }, [propertyValue?.tooltip?.title]);

  const onChange = (key, value) => {
    let tooltip = { ...propertyValue.tooltip, [key]: value };
    updateOptions({ ...propertyValue, tooltip }, "propertyValue");
  };

  const onAdd = () => {
    let tooltip = { event: "HOVER", position: "bottom", title: "" };
    updateOptions({ ...propertyValue, tooltip }, "propertyValue");
  };

  const onDelete = () => {
    const { tooltip, ...rest } = propertyValue;
    updateOptions({ ...rest }, "propertyValue");
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Tooltip</span>
        <span className="icon tooltip-icon">
          {!propertyValue?.tooltip && <mui.AddOutlined onClick={onAdd} />}
          {propertyValue?.tooltip && <img src={TrashIcon} onClick={onDelete} />}
        </span>
      </Grid>
      {/* {propertyValue?.tooltip && (
        <Grid
          container
          xs={12}
          justify="space-between"
          alignItems="center"
          direction="row"
          className="grid-property-container"
        >
          <span>Tooltip</span>
          <span className='icon tooltip-icon'>
            { !propertyValue?.tooltip  && <mui.AddOutlined onClick={onAdd}/> }
            { propertyValue?.tooltip  && <img src={TrashIcon} onClick={onDelete}/> }
          </span>
        </Grid> */}
      {
        propertyValue?.tooltip && (
          <Grid
            container xs={12}
            justify='space-between'
            alignItems='center'
            direction='row'
            className='grid-property-container'
          >
            {/* <Grid container xs={12} className='fields' justify='space-between' alignItems='center' direction='row'>
              <Grid xs={4}>
                  Show on
              </Grid>
              <Grid xs={8} justify='space-around' alignItems='center' direction='row' container>
                <Grid container xs={6} item  className={`tipEvent ${
                  propertyValue?.tooltip?.event === 'HOVER' ? 'active' : ''} `}
                  onClick={() => onChange('event', 'HOVER')}
                >
                  Hover
                </Grid>
                <Grid container xs={6} item className={`tipEvent ${
                    propertyValue?.tooltip?.event === 'CLICK' ? 'active' : ''} `}
                    onClick={() => onChange('event', 'CLICK')}
                >
                  Click
                </Grid>
              </Grid>
            </Grid> */}
            <Grid
              container
              xs={12}
              className="fields"
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid xs={4}>Position</Grid>
              <Grid
                xs={8}
                justify="space-between"
                alignItems="center"
                direction="row"
                container
              >
                {selectedComponent.type !== "datagrid_headercell" && (
                  <>
                    <Grid container xs={2} item>
                      <TooltipIconProperty
                        label={"Top"}
                        icon={<TopPositionIcon />}
                        selected={propertyValue?.tooltip?.position === "top"}
                        handleOnClick={(e) => onChange("position", "top")}
                      />
                    </Grid>
                    <Grid container xs={2} item>
                      <TooltipIconProperty
                        label={"Right"}
                        icon={<RightPositionIcon />}
                        selected={propertyValue?.tooltip?.position === "right"}
                        handleOnClick={(e) => onChange("position", "right")}
                      />
                    </Grid>
                  </>
                )}

                <Grid container xs={2} item>
                  <TooltipIconProperty
                    label={"Bottom"}
                    icon={<BottomPositionIcon />}
                    selected={propertyValue?.tooltip?.position === "bottom"}
                    handleOnClick={(e) => onChange("position", "bottom")}
                  />
                </Grid>
                {selectedComponent.type !== "datagrid_headercell" && (
                  <Grid container xs={2} item>
                    <TooltipIconProperty
                      label={"Left"}
                      icon={<LeftPositionIcon />}
                      selected={propertyValue?.tooltip?.position === "left"}
                      handleOnClick={(e) => onChange("position", "left")}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              className="fields"
              justify="space-between"
              direction="row"
            >
              <Grid xs={4} style={{ marginTop: "12px" }}>
                Title
              </Grid>
              <Grid
                xs={8}
                justify="space-between"
                alignItems="center"
                direction="row"
                container
              >
                <TextInputProperty
                  additonalProps={{
                    rows: 4,
                    rowsMax: 4,
                    multiline: true,
                    variant: "filled",
                    inputProps: { maxLength: 200 },
                  }}
                  inputStyle="field-input-area"
                  placeHolder={"Text goes here.. "}
                  selectedComponent={selectedComponent}
                  value={propertyValue?.tooltip?.title || ""}
                  onChange={(value) => onChange("title", value)}
                />
              </Grid>
            </Grid>
          </Grid>
          // </Grid>
        )}
    </Grid>
  );
};
export const PaginationSection = (props) => {
  const { selectedComponent, updateOptions } = props;
  const { propertyValue, type: componentType, property } = selectedComponent;
  const [textValue, setTextValue] = useState(
    propertyValue?.pagination?.title || ""
  );
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  useEffect(() => {
    setTextValue(propertyValue?.pagination?.title || "");
  }, [propertyValue?.pagination?.title]);

  const onChange = (key, value) => {
    let pagination = { ...propertyValue.pagination, [key]: value };
    updateOptions({ ...propertyValue, pagination }, "propertyValue");
  };

  const onAdd = () => {
    let pagination = { rowsPerPage: 5, currentIndex: 1 };
    updateOptions({ ...propertyValue, pagination }, "propertyValue");
  };

  const onDelete = () => {
    const { pagination, ...rest } = propertyValue;
    updateOptions({ ...rest }, "propertyValue");
  };

  const _getInputTypeForCurrentIndex = () => {
    if (
      propertyValue?.pagination?.currentIndex &&
      typeof propertyValue?.pagination?.currentIndex === "string" &&
      propertyValue?.pagination?.currentIndex.startsWith("{{")
    ) {
      return "text";
    }
    return "number";
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Pagination</span>
        <span className="icon tooltip-icon">
          {!propertyValue?.pagination && <mui.AddOutlined onClick={onAdd} />}
          {propertyValue?.pagination && (
            <img src={TrashIcon} onClick={onDelete} />
          )}
        </span>
      </Grid>
      {propertyValue?.pagination && (
        <Grid
          container
          xs={12}
          justify="space-between"
          alignItems="center"
          direction="row"
          className="grid-property-container"
        >
          <Grid
            container
            xs={12}
            className="fields"
            justify="space-between"
            direction="row"
          >
            <Grid xs={6} style={{ marginTop: "12px" }}>
              Rows Per Page
            </Grid>
            <Grid
              xs={6}
              justify="space-between"
              alignItems="center"
              direction="row"
              container
            >
              <TextInputProperty
                disabled={true}
                value={propertyValue?.pagination?.rowsPerPage}
                onChange={(value) => { }}
                inputStyle="property-textInput component-text-input2"
                handleEndIconClick={(e) => setDropdownAnchor(e.currentTarget)}
                endIcon={
                  <CellIcon
                    style={{
                      fontSize: "1.5em",
                      paddingRight: 8,
                      color: "#000",
                    }}
                  />
                }
              />
              {dropdownAnchor && (
                <AddInput
                  anchorEl={dropdownAnchor}
                  setAnchorEl={setDropdownAnchor}
                  handleAddValue={(value) => onChange('rowsPerPage', value)}
                  openVariablePanel={() => props.setVariableKeyName('rowsPerPage')}
                  showValueOption
                />
              )}
            </Grid>
          </Grid>
          {showProperty(property.pagenationSection, "CurrentIndex") && (
            <Grid
              container
              xs={12}
              className="fields"
              justify="space-between"
              direction="row"
            >
              <Grid xs={6} style={{ marginTop: "12px" }}>
                Current Index
              </Grid>
              <Grid
                xs={6}
                justify="space-between"
                alignItems="center"
                direction="row"
                container
              >
                <TextInputProperty
                  type={_getInputTypeForCurrentIndex()}
                  onChange={(value) => { }}
                  endIcon={<ArrowRightAltIcon />}
                  value={propertyValue?.pagination?.currentIndex}
                  inputStyle="property-textInput component-text-input2"
                  handleEndIconClick={() =>
                    props.setVariableKeyName("currentIndex")
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export const PageSettings = (props) => {
  const { selectedComponent, updateOptions } = props;
  const { property, propertyValue } = selectedComponent;

  const onChange = (key, value) => {
    let component = { ...propertyValue.component, [key]: value };
    const data = {
      ...selectedComponent,
      propertyValue: { ...propertyValue, component },
    };
    props.toggleScreenMenu(data);
    // let component = { ...propertyValue.component, [key]: value };
    // updateOptions({ ...propertyValue, component }, 'propertyValue');
    // setTimeout(() => {
    //   props.refreshPageState()
    // }, 1000);
    // updateOptions({ ...propertyValue, component }, "propertyValue");
    // setTimeout(() => {
    //   props.refreshPageState();
    // }, 1000);
  };

  const isEnabled = () => {
    return (
      !propertyValue?.component.hasOwnProperty("enableMenu") ||
      propertyValue?.component?.enableMenu === "enable"
    );
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Settings</span>
      </Grid>
      <Grid container cols={2} className="grid-property-container">
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          direction="row"
        >
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
            style={{ marginBottom: 8 }}
          >
            {/* <DropdownProperty
              label='Show global menu'
              inputStyle='property-textInput component-text-input global-menu' 
              data={[{ label: 'Enable', value: "enable" }, { label: 'Disable', value: "disable" }]}
              value={propertyValue?.component?.enableMenu ?? "enable"}
              propertyName='enableMenu'
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
            /> */}
            <span>Show global menu</span>
            <Switch
              checked={isEnabled()}
              onChange={() => {
                onChange("enableMenu", isEnabled() ? "disable" : "enable");
              }}
              color="primary"
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TextSection = (props) => {
  const { selectedComponent, updateOptions } = props;
  const { property, propertyValue } = selectedComponent;


  const onChange = (key, value) => {
    let text = { ...propertyValue.text, [key]: value };
    updateOptions({ ...propertyValue, text }, "propertyValue");
  };

  const getFontFamily = (text = "") => {
    if (text && !text.includes(',')) {
      return text;
    } else if (text && text.includes(',')) {
      let arr = text.split(', ');
      return arr.length > 0 ? arr?.[0] : "";
    }
    return text;
  }

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Text</span>
        <span className="icon">
          <AnchorLinkIcon />
        </span>
      </Grid>
      <Grid container cols={2} className="grid-property-container">
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          direction="row"
        >
          <Grid
            container
            xs={6}
            className="property-cell"
            alignItems="center"
            direction="row"
          >
            <DropdownProperty
              data={fontFamilies}
              inputStyle='property-textInput w-100'
              value={getFontFamily(propertyValue?.text?.fontFamily)}
              propertyName='fontFamily'
              handleOnChange={(key, value) => onChange(key, value)}
              {...props}
              textWrap={13}
            />
          </Grid>
          {propertyValue?.text && (
            <Grid
              container
              xs={6}
              className="property-cell"
              justify="flex-end"
              alignItems="center"
              direction="row"
            >
              <PaletteProperty
                propertyName="color"
                propertySection="text"
                {...props}
              />
            </Grid>
          )}
        </Grid>

        {(showProperty(property.textSection, "Size") ||
          selectedComponent?.propertyValue.component.menuStyle) && (
            <Grid
              container
              xs={12}
              className="property-cell"
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid
                container
                xs={6}
                justify="center"
                alignItems="center"
                direction="row"
              >
                <TextInputProperty
                  type="number"
                  inputStyle="property-textInput icon-input icon-input-right"
                  value={getNumericValue(propertyValue.text.fontSize)}
                  onChange={(value) => onChange("fontSize", Number(value))}
                  icon={<FontSizeIcon />}
                />
              </Grid>
              <Grid
                container
                xs={6}
                justify="center"
                alignItems="center"
                direction="row"
              >
                <TextInputProperty
                  type="number"
                  inputStyle="property-textInput icon-input icon-input-left"
                  value={getNumericValue(
                    String(propertyValue.text.lineHeight).split("px")[0]
                  )}
                  onChange={(value) => onChange("lineHeight", `${value}px`)}
                  icon={<LineHeightIcon />}
                />
              </Grid>
            </Grid>
          )}
        <Grid
          container
          xs={12}
          className="icon-cell"
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          {(showProperty(property.textSection, "Alignment") ||
            selectedComponent?.propertyValue.component.menuStyle) && (
              <>
                <Grid
                  container
                  xs={2}
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <IconProperty
                    icon={<LeftAlignmentIcon />}
                    selected={propertyValue.text.textAlign === "left"}
                    handleOnClick={(e) => onChange("textAlign", "left")}
                  />
                </Grid>
                <Grid
                  container
                  xs={2}
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <IconProperty
                    icon={<CenterAlignmentIcon />}
                    selected={propertyValue.text.textAlign === "center"}
                    handleOnClick={(e) => onChange("textAlign", "center")}
                  />
                </Grid>
                <Grid
                  container
                  xs={2}
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <IconProperty
                    icon={<RightAlignmentIcon />}
                    selected={propertyValue.text.textAlign === "right"}
                    handleOnClick={(e) => onChange("textAlign", "right")}
                  />
                </Grid>
              </>
            )}
          {(showProperty(property.textSection, "Decoration") ||
            selectedComponent?.propertyValue.component.menuStyle) && (
              <>
                <Grid
                  container
                  xs={2}
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <IconProperty
                    icon={<TextBoldIcon />}
                    selected={propertyValue.text.fontWeight === "bold"}
                    handleOnClick={(e) =>
                      onChange(
                        "fontWeight",
                        propertyValue.text.fontWeight === "bold" ? "" : "bold"
                      )
                    }
                  />
                </Grid>
                <Grid
                  container
                  xs={2}
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <IconProperty
                    icon={<TextItalicIcon />}
                    selected={propertyValue.text.fontStyle === "italic"}
                    handleOnClick={(e) =>
                      onChange(
                        "fontStyle",
                        propertyValue.text.fontStyle === "italic" ? "" : "italic"
                      )
                    }
                  />
                </Grid>
                <Grid
                  container
                  xs={2}
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <IconProperty
                    icon={<TextUnderlineIcon />}
                    selected={propertyValue.text.textDecoration === "underline"}
                    handleOnClick={(e) =>
                      onChange(
                        "textDecoration",
                        propertyValue.text.textDecoration === "underline"
                          ? ""
                          : "underline"
                      )
                    }
                  />
                </Grid>
              </>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const StyleSection = (props) => {
  const { updateOptions, selectedComponent } = props;
  const { property, propertyValue } = selectedComponent;
  const [customCss, setCustomCss] = useState("");
  const [cssVal, setCssVal] = useState("");
  const [cssError, setCssError] = useState("");

  const _getParsedCss = () => {
    return toCSS({ attributes: propertyValue.style.custom, children: {} });
  };
  const onChange = (key, value) => {
    // console.log(key, value)
    let component = { ...propertyValue.component, [key]: value };
    if (key === "iconName" && value && !propertyValue.component.iconPosition) {
      // Incase of selecting icon for first time, we need to set the default icon position
      component["iconPosition"] = DEFAULT_ICON_POSITION;
    }
    updateOptions({ ...propertyValue, component }, "propertyValue");
  };
  useEffect(() => {
    setCustomCss(_getParsedCss());
    setCssVal(_getParsedCss());
  }, [propertyValue.style.custom]);

  useEffect(() => {
    let timeId = setTimeout(() => {
      updateCustomCss();
    }, 500);
    return () => {
      clearTimeout(timeId);
    };
  }, [cssVal]);

  const removePageBGImage = () => {
    let jsonData = {
      ...props.selectedComponent,
      propertyValue: {
        ...props.selectedComponent.propertyValue,
        component: { ...props.selectedComponent.propertyValue.component },
      },
    };
    delete jsonData.imageUrl;
    delete jsonData.propertyValue.component.imageName;
    props.onSelect(jsonData, true);
  };

  const updateCustomCss = () => {
    try {
      if ((cssVal && cssVal.indexOf("{") == -1) || cssVal === "") {
        const json = toJSON(cssVal);
        const prevJson = toJSON(customCss);
        if (json && prevJson && !_.isEqual(json, prevJson)) {
          // console.log("updateCustomCss_____________");
          let css = json.attributes;
          let style = { ...propertyValue.style, custom: css };
          updateOptions({ ...propertyValue, style }, "propertyValue");
        }
        setCssError("");
      } else {
        if (cssVal != "") {
          setCssError("Error");
        }
      }
    } catch (e) {
      setCssError("Error");
    }
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Style</span>
        <span className="icon">
          <AnchorLinkIcon />
        </span>
      </Grid>
      <Grid container className="grid-property-container">
        {(showProperty(property.componentSection, "Classes") ||
          showProperty(property.styleSection, "Classes")) && (
            <Grid
              container
              xs={12}
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <TextInputProperty
                label="Classes"
                inputStyle="property-textInput component-text-input title-property"
                value={propertyValue.component.classes ?? ""}
                onChange={(value) => onChange("classes", value)}
                endIcon={<ArrowRightAltIcon />}
                placeHolder={"CSS Classes eg:- my-button error"}
                handleEndIconClick={() => props.setVariableKeyName('classes')}
              />
            </Grid>
          )}
        {showProperty(property.styleSection, "BackgroundImage") &&
          !propertyValue.component?.menuStyle &&
          propertyValue.component.imageName !== "" && (
            <Grid
              container
              xs={12}
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <TextInputProperty
                label="Background"
                inputStyle="property-textInput component-text-input"
                value={propertyValue.component.imageName || ""}
                disabled
                // endIcon={<ImageIcon />}
                // handleEndIconClick={() => props.setState({ showChangeImageDialog: true })}
                endIcons={[
                  ...(propertyValue.component.imageName
                    ? [
                      <CancelIcon
                        className="cancel"
                        onClick={removePageBGImage}
                      />,
                    ]
                    : []),
                  <ImageIcon
                    onClick={() =>
                      props.setState({ showChangeImageDialog: true })
                    }
                  />,
                ]}
              />
            </Grid>
          )}
        {showProperty(property.styleSection, "Background") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <PaletteProperty
              label="Background Color"
              propertyName="backgroundColor"
              propertySection="style"
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.styleSection, "Opacity") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <InputSliderProperty
              label="Opacity"
              propertyName="opacity"
              propertySection="style"
              {...props}
            />
          </Grid>
        )}
        {showProperty(property.styleSection, "Custom CSS") && (
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <ExpandProperty
              label="Custom CSS"
              errorMsg={cssError}
              propertyName='customcss'
              customCss={customCss}
              {...props}
            >
              <CodeMirror
                className="code-mirror"
                value={customCss}
                onChange={(editor, data, value) => setCssVal(editor)}
                options={{
                  lineNumbers: true,
                  mode: "text/css",
                  theme: "base16-light playground",
                  // gutters: ["CodeMirror-lint-markers"],
                  // lint: true
                }}
              />

              {/* <TextField
                margin="normal"
                multiline
                rows={20}
                variant="outlined"
                value={customCss}
                InputProps={{
                  shrink: true,
                  style: { minHeight: 150, padding: 8 },
                }}
                onChange={(e) => handleCustomCss(e.target.value)}
                onBlur={() => updateCustomCss()}
              /> */}
            </ExpandProperty>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const LayoutSection = (props) => {
  const { selectedComponent, updateCanvas, updateOptions } = props;
  const { property, propertyValue } = selectedComponent;

  const onChange = (key, value) => {
    let stringValue = value.toString();
    if (stringValue && stringValue.split("-")) {
      stringValue = stringValue.split("-");
      value = stringValue[0];
    }
    // console.log('Keyvalue',key,value)
    let layout = { ...propertyValue.layout, [key]: value };
    updateOptions({ ...propertyValue, layout }, "propertyValue");
    if (!selectedComponent.id && !selectedComponent.dropzoneID) {
      updateCanvas(key, value);
    }
  };

  // const dimensionChange = (key, value) => {
  //   if (value !== '' && Number(value) !== 'NaN') {
  //     onChange(key, `${value}px`);
  //   } else {
  //     onChange(key, value);
  //   }
  // }

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Layout</span>
        <span className="icon">
          <AnchorLinkIcon />
        </span>
      </Grid>
      {showProperty(property.layoutSection, "Layout") && (
        <BoxModel handleOnChange={updateCanvas} {...props} />
      )}
      {!propertyValue?.component?.menuStyle && ( // width/height property is not allowed for menu page
        <Grid container className="grid-property-container px-2">
          {showProperty(property.layoutSection, "Width") && (
            <Grid
              container
              xs={6}
              className="property-cell layout-width"
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <TextFieldInput
                label="Width"
                disabled={
                  showProperty(property.disableSection ?? [], "Width")
                    ? true
                    : selectedComponent.id && selectedComponent.dropzoneID
                      ? false
                      : true
                }
                inputStyle="property-textInput"
                value={propertyValue?.layout?.width ?? "auto"}
                onChange={(value) => onChange("width", value)}
                validateProperty={"Width"}
                id={selectedComponent.id}
                calltype={"width"}
                options={
                  selectedComponent.type === "datagrid_headercell"
                    ? ["-", "px"]
                    : null
                }
              />
            </Grid>
          )}
          {showProperty(property.layoutSection, "Height") && (
            <Grid
              container
              xs={6}
              className="property-cell"
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <TextFieldInput
                label="Height"
                // defaultValue={'auto'}
                disabled={
                  selectedComponent.id && selectedComponent.dropzoneID
                    ? false
                    : true
                }
                inputStyle="property-textInput"
                value={propertyValue?.layout?.height ?? "auto"}
                onChange={(value) => onChange("height", value)}
                validateProperty={"Height"}
                id={selectedComponent.id}
                calltype={"height"}
                options={
                  selectedComponent.type === "datagrid_headercell"
                    ? ["-"]
                    : null
                }
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

// delete button icon
function DeleteIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 -4 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0H4V1H8V0ZM0 2V3H1V13C1 13.6 1.4 14 2 14H10C10.6 14 11 13.6 11 13V3H12V2H0ZM2 13V3H10V13H2ZM4 5H5V11H4V5ZM8 5H7V11H8V5Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export const DataSourceNew = (props) => {
  const { selectedComponent, updateCanvas, updateOptions } = props;
  const { property, propertyValue } = selectedComponent;
  const [showFilterComponent, setShowFiltershowComponent] = React.useState();
  const [dataSrc, setDataSrc] = useState(
    propertyValue.dataSource ? propertyValue.dataSource : []
  );
  const classes = useStyles();
  const [showFilterModal, setShowFilterModal] = React.useState('');
  const variablesData = useSelector(
    (state) => state.userTaskDetails.taskVariables
  );
  const { variables } = variablesData;
  const chartTypes = ["BAR_CHART", "LINE_CHART", "COLUMN_CHART"];

  const onChange = (key, src, value, i, option, isArray) => {
    let arr = _.cloneDeep(dataSrc);
    const keyValue = key === "label" ? "value" : key;
    let obj = dataSrc[i] ? dataSrc[i] : {};
    if (key !== src) {
      if (value !== "" && isArray && option.type === "JSON")
        value = `${option.name}.${value}`;
      obj = { ...obj, [src]: { ...obj[src], [keyValue]: value } };
    } else {
      if (value !== "" && !isArray && option.type === "JSON")
        value = `${option.name}.${value}`;
      obj = { ...obj, [src]: { ...obj[src], selfVal: value } };
    }

    if (!dataSrc[i]) {
      arr.push(Object.assign({}, obj));
    } else {
      arr[i] = Object.assign({}, obj);
    }

    setDataSrc(arr);
    updateOptions(
      { ...propertyValue, dataSource: _.cloneDeep(arr) },
      "propertyValue"
    );
    if (!selectedComponent.id && !selectedComponent.dropzoneID) {
      updateCanvas(key, value);
    }

    //Need to forward the varaible mapping on table cell's text component from header cell' Data
    // props.triggerHooks();
  };

  useEffect(() => {
    const selectedComponentType = props.selectedComponent.type;
    if ([
      "TABLE", "LISTVIEW", "GRID", "DATAGRID",
      "SELECT", "AUTOCOMPLETE", "COLLAPSIBLE"
    ].includes(selectedComponentType)) {
      setShowFiltershowComponent(true)
    } else {
      setShowFiltershowComponent(false)
    }
  }, [props.selectedComponent]);

  useEffect(() => {
    if (propertyValue) {
      if (propertyValue.dataSource) {
        setDataSrc(propertyValue.dataSource);
      } else {
        handleAddSeries("single");
      }
    }
  }, [propertyValue]);

  const _isCheckFieldNotAllowed = () => {
    let currentComponent = selectedComponent.type?.toLowerCase();
    let DS_CHECK_FIELD_UNSUPPORTED_COMPONENTS = [COMPONENT_NAMES.SELECT];
    return (
      DS_CHECK_FIELD_UNSUPPORTED_COMPONENTS.indexOf(currentComponent) !== -1 &&
      !propertyValue?.component?.multiselect
    );
  };

  const _getValidFields = (fields = []) => {
    if (fields.indexOf("Check") !== -1 && _isCheckFieldNotAllowed()) {
      return fields.filter((e) => e !== "Check");
    } else return fields;
  };

  const dataItems = [];
  variables &&
    variables.length > 0 &&
    variables.map((item) => {
      let dummyObject = { ...item };
      if (item.type === "JSON") {
        dummyObject.value = JSON.parse(item.value);
      }
      dataItems.push(dummyObject);
    });

  const handleAutocomplete = (
    label,
    src,
    selectedValue,
    setSelectedValue,
    index,
    isArray,
    isNestedField = false
  ) => {
    let endIcons = [];
    if (selectedValue) {
      // Cancel button
      endIcons.push(
        <InputAdornment
          position="end"
          onClick={() => {
            setSelectedValue(label, src, "", index, { type: null }, isArray);
          }}
        >
          <CancelIcon className="cancel" />
        </InputAdornment>
      );
    }

    const _handleArrowClick = (e) => {
      e.stopPropagation();
      let allowed = true;
      if (["LISTVIEW"].indexOf(selectedComponent.type) !== -1) {
        let needConcent = false;
        const hasDS =
          selectedComponent.propertyValue.dataSource &&
          selectedComponent.propertyValue.dataSource[0].Data.selfVal;
        const hasMultiOptions = selectedComponent.options.length > 1;
        if (hasMultiOptions && !hasDS) {
          needConcent = true;
        }
        if (needConcent) {
          allowed = window.confirm(
            "If you will map with data-source, then all other item(s) except first one will be removed. Are you sure to do this?"
          );
        }
      }
      if (allowed) {
        props.setVariableKeyName(
          `datasource_${JSON.stringify({ src, label, index, isArray })}`
        );
      }
    };

    endIcons.push(
      // Arrow icon button
      <InputAdornment position="end" onClick={_handleArrowClick}>
        <ArrowRightAltIcon />
      </InputAdornment>
    );

    let classes = "property-textInput component-text-input";
    if (isNestedField) {
      classes = `${classes} nested-text-input`;
    }

    let inlineStyle = {
      // width: (label == 'Latitude' || label == 'Longitude')
      //   && (label !== src) ? '155px' : '180px',
      visibility: label !== "From" && label !== "To" ? "visible" : "hidden",
    };
    if (isNestedField) {
      inlineStyle.width = "calc(100% - 77px)";
    }

    let isEditable = true;
    // if (!isArray || label === "Latitude" || label === "Longitude") {
    //   isEditable = true;
    // }

    const handleCloseDialog = () => {
      setShowFilterModal('');
    };
    const openFilterPopUp = () => {
      setShowFilterModal(selectedValue);
    };

    const mapTypeOption = [                
      { label: "Hybrid", value: "Hybrid" },
      { label: "Roadmap", value: "Roadmap" },
      { label: "Satellite", value: "Satellite" },
      { label: "Terrain", value: "Terrain" },
    ]

    const mapDrawOption = [
      { label: "Circle", value: "Circle" },
      { label: "Marker", value: "Marker" },
      // { label: "Polyline", value: "Polyline" },
      { label: "Polygon", value: "Polygon" },
      // { label: "Rectangle", value: "Rectangle" },
    ]

    return (
      <>
        <Grid
          container
          className='grid-data-source-container'
          style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}
        >
          <TextInputProperty
            label={label}
            inputStyle={classes}
            value={selectedValue}
            disabled={!isEditable}
            onChange={(value) => {
              setSelectedValue(
                label,
                src,
                value,
                index,
                { type: null },
                isArray
              );
            }}
            endIcons={endIcons}
            placeHolder="Link to data source"
            inlineStyle={inlineStyle}
            options={label==="MapType"?mapTypeOption:label==="MapDraw"?mapDrawOption:[]}
          />
        </Grid>
        {showFilterComponent && isArray && !isNestedField ?
          <Box component="div">
            <Button variant="text" onClick={openFilterPopUp}>
              Filter
            </Button>
            {showFilterModal && (
              <FilterPopup
                name={showFilterModal || ""}
                openModal={!!showFilterModal}
                closeModal={handleCloseDialog}
                projVariable={props.projVariable || []}
                dataFilterUI={propertyValue.dataFilterUI || []}
                bfVariable={props.varList?.variable || []}
                globalVariable={props.globalVariable || []}
                userPropertyList={props.userPropertyList || []}
                updateOptions={(filterData, ui) => {
                  updateOptions(
                    {
                      ...propertyValue,
                      dataFilter: filterData,
                      dataFilterUI: _deepCloneJSON(ui),
                    },
                    "propertyValue"
                  );
                }}
              />
            )}
          </Box> :
          <></>}
      </>
    );
  };

  const handleAddSeries = (type) => {
    let obj = {};
    let arr = type === "single" ? [] : dataSrc;
    property.dataSource.map((source, i) => {
      if (source.fields && source.fields.length > 0) {
        _getValidFields(source.fields).map((field) => {
          obj = {
            ...obj,
            [source.name]: { ...obj[source.name], [field]: "", selfVal: "" },
          };
        });
      } else {
        obj = { ...obj, [source.name]: { ...obj[source.name], selfVal: "" } };
      }
    });
    arr.push(obj);
    setDataSrc(arr);
    if (type != "single") {
      updateOptions(
        { ...propertyValue, dataSource: _.cloneDeep(arr) },
        "propertyValue"
      );
    }
  };

  // const handleDeleteSeries = (i) => {
  //   let arr = dataSrc;
  //   arr.splice(i, 1);
  //   setDataSrc(arr);
  //   updateOptions({ ...propertyValue, dataSource: arr }, 'propertyValue');
  // };

  const renderDataSources = (index) => {
    let allDataSource = property.dataSource;
    if (props.fieldsRequired) {
      allDataSource = property.dataSource.filter((ds) => {
        return props.fieldsRequired.indexOf(ds.name) > -1;
      });
    }
    return allDataSource.map((source, i) => {
      let selectedVal = dataSrc[index][source.name]
        ? dataSrc[index][source.name].selfVal
        : "";
      
      if (source.fields && source.fields.length > 0) {
        return (
          <>
            {handleAutocomplete(
              source.name,
              source.name,
              selectedVal,
              onChange,
              index,
              source.isArray
            )}
            <ul className="data-hierarchy">
              {_getValidFields(source.fields).map((field) => {
                let val = dataSrc[index][source.name]
                  ? dataSrc[index][source.name][field]
                  : "";
                return (
                  <li>
                    {handleAutocomplete(
                      field,
                      source.name,
                      val,
                      onChange,
                      index,
                      source.isArray,
                      true
                    )}
                  </li>
                );
              })}
            </ul>
          </>
        );
      } else {
        return handleAutocomplete(
          source.name,
          source.name,
          selectedVal,
          onChange,
          index,
          source.isArray
        );
      }
    });
  };

  return [...Array(dataSrc.length)].map((a, i) => {
    return renderDataSources(i);
  });
};

export const AddColorSection = (props) => {
  const { updateOptions, selectedComponent } = props;
  const [options, setOptions] = useState(
    selectedComponent.options
      ? selectedComponent.options
      : [{ label: "", value: "", checked: false }]
  );

  React.useEffect(() => {
    if (selectedComponent.options && selectedComponent.options !== options) {
      setOptions(selectedComponent.options);
    }
    if (!selectedComponent.options) {
      setOptions([]);
    }
  }, [selectedComponent]);

  const removeOption = (index) => {
    let data = [...options];
    data.splice(index, 1);
    setOptions(data);
    updateOptions(data);
  };

  const handleOptionsUpdate = () => {
    if (options.length && options[options.length - 1].value !== "") {
      updateOptions(options);
    }
  };

  const addOption = () => {
    setOptions([...options, { label: "", value: "", checked: false }]);
  };

  const updateValue = (index, key, value) => {
    let data = [...options];
    data[index][key] = value;
    setOptions(data);
  };

  return (
    <Grid className="section-wrapper">
      <Grid
        className="grid-list-title"
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <span>Add Color</span>
        <span className="icon">
          <img src={AddIcon} className="eye-icon" onClick={addOption} />
        </span>
      </Grid>
      <Grid container className="grid-property-container">
        {options &&
          options.map((option, idx) => {
            return (
              <Grid
                container
                xs={12}
                className="text-property-cell"
                justify="space-around"
                alignItems="center"
                direction="row"
              >
                <span>Color {idx + 1}</span>
                <TextField
                  className="options-input"
                  type={"text"}
                  disableUnderline
                  placeholder="Value"
                  onChange={(e) => updateValue(idx, "value", e.target.value)}
                  onBlur={handleOptionsUpdate}
                  value={option.value}
                />
                <img
                  src={TrashIcon}
                  className="eye-icon"
                  onClick={() => removeOption(idx)}
                />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
