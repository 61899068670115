import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ContentHeader from "../../../common/components/ContentHeader";
import AlertDialog from "../../../common/components/AlertDialog";
import { configMessage } from "../../../common/messages/config";
import { getPermissions } from "../../../utils/common";
import useStyles from "./style";
import {
  Container,
  Box,
  Button,
  InputBase,
  IconButton,
  Paper,
  Grid,
} from "@material-ui/core";
import NewLogin from "../../../views/new-login";
import ProcessCard from "../../../views/process-card";

export default function (props) {
  const classes = useStyles();
  const { showNewProjectModal, setShowNewProjectModal } = props;

  return (
    <Box component="div" className={classes.projectPage}>
      {getPermissions()?.library?.logins?.canView && (
        <Grid container spacing={0}>
          <Grid item xs={12} className="layout">
            <ContentHeader left={6} right={6} title="Logins">
              <Box variant="div" style={{ display: "flex" }}>
                <Box className="input-icons">
                  <Box component="form" className={classes.searchBox}>
                    <InputBase
                      className={classes.searchInput}
                      placeholder="Search"
                      inputProps={{ "aria-label": "Search" }}
                      onChange={(e) => props.handleSearch(e)}
                    />
                    <IconButton
                      className={classes.searchIconBtn}
                      aria-label="menu"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="buttonLayout">
                  {getPermissions()?.library?.logins?.canCreate && (
                    <Button
                      variant="contained"
                      id="newlogin_btn"
                      title="New login"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setShowNewProjectModal(true)}
                    >
                      New login
                    </Button>
                  )}
                </Box>

                <NewLogin
                  logins={props.logins}
                  show={showNewProjectModal}
                  alertOpen={props.alertOpen}
                  onClose={() => setShowNewProjectModal(false)}
                  handleNewLoginValue={props.handleNewLoginValue}
                />
              </Box>
            </ContentHeader>

            <Container className="loginlist-container">
              {props.logins.length == 0 ? (
                <Box className={classes.panelListTxtMsg}>
                  {configMessage.T4531}
                </Box>
              ) : (
                props.logins.map((data, i) => {
                  data.screenshot = data.snapshot;
                  return (
                    <ProcessCard
                      projectType="businessFunction"
                      project={data}
                      projectId={i}
                      navigateToBusiness={props.navigateToBusiness}
                    />
                  );
                })
              )}
            </Container>
          </Grid>
        </Grid>
      )}

      {props.alertOpen ? (
        <AlertDialog
          open={props.alertOpen}
          status="error"
          response={props.alertData}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
