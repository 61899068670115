import { prototype } from 'react-codemirror';
import { generateUid } from '../../../utils/common';
import { SHAPE_TYPES } from '../shape/types';

var mxgraph = require('mxgraph')({
  mxImageBasePath: '../images',
  mxBasePath: './',
});
let {
  mxConstants,
  mxLayoutManager,
  mxCell,
  mxGeometry,
  mxRubberband,
  mxCodec,
  mxClient,
  mxUtils,
  mxEvent,
  mxUndoManager,
  mxCompactTreeLayout,
  mxClipboard,
  mxDefaultKeyHandler,
  mxEventSource,
  mxPrintPreview,
  mxResources,
  mxSwimlaneManager,
  mxDivResizer,
  mxEventObject,
  mxStackLayout,
  mxDragSource,
  mxGraphModel,
} = mxgraph;

function editor(graph) {
  let self=this;
  this.actions = [];
  this.addActions();

  // Executes the following only if a document has been instanciated.
  // That is, don't execute when the editorcodec is setup.
  // Defines instance fields
  this.cycleAttributeValues = [];
  //this.popupHandler = new mxDefaultPopupMenu();
    /**
   * Function: undoableEditHappened
   * 
   * Method to be called to add new undoable edits to the <history>.
   */
  mxUndoManager.prototype.undoableEditHappened = function(undoableEdit)
  {
    if(!self.graph.self.skipAction){
      this.trim();
      if (this.size > 0 &&
        this.size == this.history.length)
      {
        this.history.shift();
      }
      
      this.history.push(undoableEdit);
      this.indexOfNextAdd = this.history.length;
    }
    this.fireEvent(new mxEventObject(mxEvent.ADD, 'edit', undoableEdit));
  };

  this.undoManager = new mxUndoManager();
  this.undoManager.size = 10;
  // Creates the graph and toolbar without the containers
  //this.graph = this.createGraph();

  this.graph = this.InitGraph(graph);
  //this.toolbar = this.createToolbar();

  // Creates the global keyhandler (requires graph instance)
  this.keyHandler = new mxDefaultKeyHandler(this);

  // Configures the editor using the URI
  // which was passed to the ctor
  //this.configure(config);

  // Assigns the swimlaneIndicatorColorAttribute on the graph
  this.graph.swimlaneIndicatorColorAttribute = this.cycleAttributeName;

  // Checks if the <onInit> hook has been set
  if (this.onInit != null) {
    // Invokes the <onInit> hook
    this.onInit();
  }

  // Automatic deallocation of memory
  if (mxClient.IS_IE) {
    mxEvent.addListener(
      window,
      'unload',
      mxUtils.bind(this, function () {
        this.destroy();
      })
    );
  }
}

/**
 * Extends mxEventSource.
 */
editor.prototype = new mxEventSource();
editor.prototype.constructor = editor;

/**
 * Group: Controls and Handlers
 */

/**
 * Variable: askZoomResource
 *
 * Specifies the resource key for the zoom dialog. If the resource for this
 * key does not exist then the value is used as the error message. Default
 * is 'askZoom'.
 */
editor.prototype.askZoomResource = mxClient.language != 'none' ? 'askZoom' : '';

/**
 * Variable: lastSavedResource
 *
 * Specifies the resource key for the last saved info. If the resource for
 * this key does not exist then the value is used as the error message.
 * Default is 'lastSaved'.
 */
editor.prototype.lastSavedResource =
  mxClient.language != 'none' ? 'lastSaved' : '';

/**
 * Variable: currentFileResource
 *
 * Specifies the resource key for the current file info. If the resource for
 * this key does not exist then the value is used as the error message.
 * Default is 'currentFile'.
 */
editor.prototype.currentFileResource =
  mxClient.language != 'none' ? 'currentFile' : '';

/**
 * Variable: propertiesResource
 *
 * Specifies the resource key for the properties window title. If the
 * resource for this key does not exist then the value is used as the
 * error message. Default is 'properties'.
 */
editor.prototype.propertiesResource =
  mxClient.language != 'none' ? 'properties' : '';

/**
 * Variable: tasksResource
 *
 * Specifies the resource key for the tasks window title. If the
 * resource for this key does not exist then the value is used as the
 * error message. Default is 'tasks'.
 */
editor.prototype.tasksResource = mxClient.language != 'none' ? 'tasks' : '';

/**
 * Variable: helpResource
 *
 * Specifies the resource key for the help window title. If the
 * resource for this key does not exist then the value is used as the
 * error message. Default is 'help'.
 */
editor.prototype.helpResource = mxClient.language != 'none' ? 'help' : '';

/**
 * Variable: outlineResource
 *
 * Specifies the resource key for the outline window title. If the
 * resource for this key does not exist then the value is used as the
 * error message. Default is 'outline'.
 */
editor.prototype.outlineResource = mxClient.language != 'none' ? 'outline' : '';

/**
 * Variable: outline
 *
 * Reference to the <mxWindow> that contains the outline. The <mxOutline>
 * is stored in outline.outline.
 */
editor.prototype.outline = null;

/**
 * Variable: graph
 *
 * Holds a <mxGraph> for displaying the diagram. The graph
 * is created in <setGraphContainer>.
 */
editor.prototype.graph = null;

/**
 * Variable: graphRenderHint
 *
 * Holds the render hint used for creating the
 * graph in <setGraphContainer>. See <mxGraph>.
 * Default is null.
 */
editor.prototype.graphRenderHint = null;

/**
 * Variable: toolbar
 *
 * Holds a <mxDefaultToolbar> for displaying the toolbar. The
 * toolbar is created in <setToolbarContainer>.
 */
editor.prototype.toolbar = null;

/**
 * Variable: status
 *
 * DOM container that holds the statusbar. Default is null.
 * Use <setStatusContainer> to set this value.
 */
editor.prototype.status = null;

/**
 * Variable: popupHandler
 *
 * Holds a <mxDefaultPopupMenu> for displaying
 * popupmenus.
 */
editor.prototype.popupHandler = null;

/**
 * Variable: undoManager
 *
 * Holds an <mxUndoManager> for the command history.
 */
editor.prototype.undoManager = null;

/**
 * Variable: keyHandler
 *
 * Holds a <mxDefaultKeyHandler> for handling keyboard events.
 * The handler is created in <setGraphContainer>.
 */
editor.prototype.keyHandler = null;

/**
 * Group: Actions and Options
 */

/**
 * Variable: actions
 *
 * Maps from actionnames to actions, which are functions taking
 * the editor and the cell as arguments. Use <addAction>
 * to add or replace an action and <execute> to execute an action
 * by name, passing the cell to be operated upon as the second
 * argument.
 */
editor.prototype.actions = null;

/**
 * Variable: dblClickAction
 *
 * Specifies the name of the action to be executed
 * when a cell is double clicked. Default is 'edit'.
 *
 * To handle a singleclick, use the following code.
 *
 * (code)
 * editor.graph.addListener(mxEvent.CLICK, function(sender, evt)
 * {
 *   var e = evt.getProperty('event');
 *   var cell = evt.getProperty('cell');
 *
 *   if (cell != null && !e.isConsumed())
 *   {
 *     // Do something useful with cell...
 *     e.consume();
 *   }
 * });
 * (end)
 */
editor.prototype.dblClickAction = 'edit';

/**
 * Variable: swimlaneRequired
 *
 * Specifies if new cells must be inserted
 * into an existing swimlane. Otherwise, cells
 * that are not swimlanes can be inserted as
 * top-level cells. Default is false.
 */
editor.prototype.swimlaneRequired = false;
editor.prototype.type = undefined;

/**
 * Variable: disableContextMenu
 *
 * Specifies if the context menu should be disabled in the graph container.
 * Default is true.
 */
editor.prototype.disableContextMenu = true;

/**
 * Group: Templates
 */

/**
 * Variable: insertFunction
 *
 * Specifies the function to be used for inserting new
 * cells into the graph. This is assigned from the
 * <mxDefaultToolbar> if a vertex-tool is clicked.
 */
editor.prototype.insertFunction = null;

/**
 * Variable: forcedInserting
 *
 * Specifies if a new cell should be inserted on a single
 * click even using <insertFunction> if there is a cell
 * under the mousepointer, otherwise the cell under the
 * mousepointer is selected. Default is false.
 */
editor.prototype.forcedInserting = false;

/**
 * Variable: templates
 *
 * Maps from names to protoype cells to be used
 * in the toolbar for inserting new cells into
 * the diagram.
 */
editor.prototype.templates = null;

/**
 * Variable: defaultEdge
 *
 * Prototype edge cell that is used for creating
 * new edges.
 */
editor.prototype.defaultEdge = null;

/**
 * Variable: defaultEdgeStyle
 *
 * Specifies the edge style to be returned in <getEdgeStyle>.
 * Default is null.
 */
editor.prototype.defaultEdgeStyle = null;

/**
 * Variable: defaultGroup
 *
 * Prototype group cell that is used for creating
 * new groups.
 */
editor.prototype.defaultGroup = null;

/**
 * Variable: groupBorderSize
 *
 * Default size for the border of new groups. If null,
 * then then <mxGraph.gridSize> is used. Default is
 * null.
 */
editor.prototype.groupBorderSize = null;

/**
 * Group: Backend Integration
 */

/**
 * Variable: filename
 *
 * Contains the URL of the last opened file as a string.
 * Default is null.
 */
editor.prototype.filename = null;

/**
 * Variable: lineFeed
 *
 * Character to be used for encoding linefeeds in <save>. Default is '&#xa;'.
 */
editor.prototype.linefeed = '&#xa;';

/**
 * Variable: postParameterName
 *
 * Specifies if the name of the post parameter that contains the diagram
 * data in a post request to the server. Default is 'xml'.
 */
editor.prototype.postParameterName = 'xml';

/**
 * Variable: escapePostData
 *
 * Specifies if the data in the post request for saving a diagram
 * should be converted using encodeURIComponent. Default is true.
 */
editor.prototype.escapePostData = true;

/**
 * Variable: urlPost
 *
 * Specifies the URL to be used for posting the diagram
 * to a backend in <save>.
 */
editor.prototype.urlPost = null;

/**
 * Variable: urlImage
 *
 * Specifies the URL to be used for creating a bitmap of
 * the graph in the image action.
 */
editor.prototype.urlImage = null;

/**
 * Group: Autolayout
 */

/**
 * Variable: horizontalFlow
 *
 * Specifies the direction of the flow
 * in the diagram. This is used in the
 * layout algorithms. Default is false,
 * ie. vertical flow.
 */
editor.prototype.horizontalFlow = false;

/**
 * Variable: layoutDiagram
 *
 * Specifies if the top-level elements in the
 * diagram should be layed out using a vertical
 * or horizontal stack depending on the setting
 * of <horizontalFlow>. The spacing between the
 * swimlanes is specified by <swimlaneSpacing>.
 * Default is false.
 *
 * If the top-level elements are swimlanes, then
 * the intra-swimlane layout is activated by
 * the <layoutSwimlanes> switch.
 */
editor.prototype.layoutDiagram = false;

/**
 * Variable: swimlaneSpacing
 *
 * Specifies the spacing between swimlanes if
 * automatic layout is turned on in
 * <layoutDiagram>. Default is 0.
 */
editor.prototype.swimlaneSpacing = 0;

/**
 * Variable: maintainSwimlanes
 *
 * Specifies if the swimlanes should be kept at the same
 * width or height depending on the setting of
 * <horizontalFlow>.  Default is false.
 *
 * For horizontal flows, all swimlanes
 * have the same height and for vertical flows, all swimlanes
 * have the same width. Furthermore, the swimlanes are
 * automatically "stacked" if <layoutDiagram> is true.
 */
editor.prototype.maintainSwimlanes = false;

/**
 * Variable: layoutSwimlanes
 *
 * Specifies if the children of swimlanes should
 * be layed out, either vertically or horizontally
 * depending on <horizontalFlow>.
 * Default is false.
 */
editor.prototype.layoutSwimlanes = false;

/**
 * Group: Attribute Cycling
 */

/**
 * Variable: cycleAttributeValues
 *
 * Specifies the attribute values to be cycled when
 * inserting new swimlanes. Default is an empty
 * array.
 */
editor.prototype.cycleAttributeValues = null;

/**
 * Variable: cycleAttributeIndex
 *
 * Index of the last consumed attribute index. If a new
 * swimlane is inserted, then the <cycleAttributeValues>
 * at this index will be used as the value for
 * <cycleAttributeName>. Default is 0.
 */
editor.prototype.cycleAttributeIndex = 0;

/**
 * Variable: cycleAttributeName
 *
 * Name of the attribute to be assigned a <cycleAttributeValues>
 * when inserting new swimlanes. Default is 'fillColor'.
 */
editor.prototype.cycleAttributeName = 'fillColor';

/**
 * Group: Windows
 */

/**
 * Variable: tasks
 *
 * Holds the <mxWindow> created in <showTasks>.
 */
editor.prototype.tasks = null;

/**
 * Variable: tasksWindowImage
 *
 * Icon for the tasks window.
 */
editor.prototype.tasksWindowImage = null;

/**
 * Variable: tasksTop
 *
 * Specifies the top coordinate of the tasks window in pixels.
 * Default is 20.
 */
editor.prototype.tasksTop = 20;

/**
 * Variable: help
 *
 * Holds the <mxWindow> created in <showHelp>.
 */
editor.prototype.help = null;

/**
 * Variable: helpWindowImage
 *
 * Icon for the help window.
 */
editor.prototype.helpWindowImage = null;

/**
 * Variable: urlHelp
 *
 * Specifies the URL to be used for the contents of the
 * Online Help window. This is usually specified in the
 * resources file under urlHelp for language-specific
 * online help support.
 */
editor.prototype.urlHelp = null;

/**
 * Variable: helpWidth
 *
 * Specifies the width of the help window in pixels.
 * Default is 300.
 */
editor.prototype.helpWidth = 300;

/**
 * Variable: helpHeight
 *
 * Specifies the height of the help window in pixels.
 * Default is 260.
 */
editor.prototype.helpHeight = 260;

/**
 * Variable: propertiesWidth
 *
 * Specifies the width of the properties window in pixels.
 * Default is 240.
 */
editor.prototype.propertiesWidth = 240;

/**
 * Variable: propertiesHeight
 *
 * Specifies the height of the properties window in pixels.
 * If no height is specified then the window will be automatically
 * sized to fit its contents. Default is null.
 */
editor.prototype.propertiesHeight = null;

/**
 * Variable: movePropertiesDialog
 *
 * Specifies if the properties dialog should be automatically
 * moved near the cell it is displayed for, otherwise the
 * dialog is not moved. This value is only taken into
 * account if the dialog is already visible. Default is false.
 */
editor.prototype.movePropertiesDialog = false;

/**
 * Variable: validating
 *
 * Specifies if <mxGraph.validateGraph> should automatically be invoked after
 * each change. Default is false.
 */
editor.prototype.validating = false;

/**
 * Variable: modified
 *
 * True if the graph has been modified since it was last saved.
 */
editor.prototype.modified = false;

/**
 * Function: isModified
 *
 * Returns <modified>.
 */
editor.prototype.isModified = function () {
  return this.modified;
};

/**
 * Function: setModified
 *
 * Sets <modified> to the specified boolean value.
 */
editor.prototype.setModified = function (value) {
  this.modified = value;
};

/**
 * Function: addActions
 *
 * Adds the built-in actions to the editor instance.
 *
 * save - Saves the graph using <urlPost>.
 * print - Shows the graph in a new print preview window.
 * show - Shows the graph in a new window.
 * exportImage - Shows the graph as a bitmap image using <getUrlImage>.
 * refresh - Refreshes the graph's display.
 * cut - Copies the current selection into the clipboard
 * and removes it from the graph.
 * copy - Copies the current selection into the clipboard.
 * paste - Pastes the clipboard into the graph.
 * delete - Removes the current selection from the graph.
 * group - Puts the current selection into a new group.
 * ungroup - Removes the selected groups and selects the children.
 * undo - Undoes the last change on the graph model.
 * redo - Redoes the last change on the graph model.
 * zoom - Sets the zoom via a dialog.
 * zoomIn - Zooms into the graph.
 * zoomOut - Zooms out of the graph
 * actualSize - Resets the scale and translation on the graph.
 * fit - Changes the scale so that the graph fits into the window.
 * showProperties - Shows the properties dialog.
 * selectAll - Selects all cells.
 * selectNone - Clears the selection.
 * selectVertices - Selects all vertices.
 * selectEdges = Selects all edges.
 * edit - Starts editing the current selection cell.
 * enterGroup - Drills down into the current selection cell.
 * exitGroup - Moves up in the drilling hierachy
 * home - Moves to the topmost parent in the drilling hierarchy
 * selectPrevious - Selects the previous cell.
 * selectNext - Selects the next cell.
 * selectParent - Selects the parent of the selection cell.
 * selectChild - Selects the first child of the selection cell.
 * collapse - Collapses the currently selected cells.
 * expand - Expands the currently selected cells.
 * bold - Toggle bold text style.
 * italic - Toggle italic text style.
 * underline - Toggle underline text style.
 * alignCellsLeft - Aligns the selection cells at the left.
 * alignCellsCenter - Aligns the selection cells in the center.
 * alignCellsRight - Aligns the selection cells at the right.
 * alignCellsTop - Aligns the selection cells at the top.
 * alignCellsMiddle - Aligns the selection cells in the middle.
 * alignCellsBottom - Aligns the selection cells at the bottom.
 * alignFontLeft - Sets the horizontal text alignment to left.
 * alignFontCenter - Sets the horizontal text alignment to center.
 * alignFontRight - Sets the horizontal text alignment to right.
 * alignFontTop - Sets the vertical text alignment to top.
 * alignFontMiddle - Sets the vertical text alignment to middle.
 * alignFontBottom - Sets the vertical text alignment to bottom.
 * toggleTasks - Shows or hides the tasks window.
 * toggleHelp - Shows or hides the help window.
 * toggleOutline - Shows or hides the outline window.
 * toggleConsole - Shows or hides the console window.
 */
editor.prototype.addActions = function () {
  this.addAction('save', function (editor) {
    editor.save();
  });

  this.addAction('print', function (editor) {
    var preview = new mxPrintPreview(editor.graph, 1);
    preview.open();
  });

  this.addAction('show', function (editor) {
    mxUtils.show(editor.graph, null, 10, 10);
  });

  this.addAction('exportImage', function (editor) {
    var url = editor.getUrlImage();

    if (url == null || mxClient.IS_LOCAL) {
      editor.execute('show');
    } else {
      var node = mxUtils.getViewXml(editor.graph, 1);
      var xml = mxUtils.getXml(node, '\n');

      mxUtils.submit(
        url,
        editor.postParameterName + '=' + encodeURIComponent(xml),
        document,
        '_blank'
      );
    }
  });

  this.addAction('refresh', function (editor) {
    editor.graph.refresh();
  });

  this.addAction('cut', function (editor) {
    if (editor.graph.isEnabled()) {
      mxClipboard.cut(editor.graph);
    }
  });

  this.addAction('copy', function (editor, evt) {
    if (editor.graph.isEnabled()) {
      var requireScreenRender = false;
      var requireDMNArrowRender = false;
      mxClipboard.paste = function (graph) {
        if (!mxClipboard.isEmpty()) {
          var cells = graph.getImportableCells(mxClipboard.getCells());
          var delta = mxClipboard.insertCount * mxClipboard.STEPSIZE;
          var parent = graph.getDefaultParent();
          graph.model.beginUpdate();
          requireScreenRender = false;
          requireDMNArrowRender = false;
          try {
            for (var i = 0; i < cells.length; i++) {
              var tmp =
                mxClipboard.parents != null &&
                  graph.model.contains(mxClipboard.parents[i])
                  ? mxClipboard.parents[i]
                  : parent;
              if (cells[i].edge) {
                cells[i].source = cells[0];
                cells[i].target = cells[1];
                cells[i].setTerminal(cells[0], true);
                cells[i].setTerminal(cells[cells[i].targetIndex], false);
                cells[i] = graph.addEdge(
                  cells[i],
                  tmp,
                  cells[0],
                  cells[cells[i].targetIndex]
                );
              } else {
                if (cells[i].type === SHAPE_TYPES.SCREEN) {
                  requireScreenRender = cells[i];
                  requireScreenRender.name = requireScreenRender.value;
                  let loader = `<div style="margin-top:${95}px;font-size: 13px;padding-left: 28px;pointer-events:none;">${cells[i].isReference?"Page Reference":"No Preview"}</div>`;
                  if (requireScreenRender.requireLoader) {
                    loader = `<div class="loader" style="pointer-events:none;"></div>`;
                  }
                  cells[i].children.map((e) => {
                    if (e.content) {
                      e.value = `<div id="${requireScreenRender.name}" style="width:120px;height:240px;pointer-events:none;max-width: unset;">${loader}</div>`;
                    }
                    return e;
                  });
                }
                cells[i] = graph.importCells([cells[i]], delta, delta, tmp)[0];
                if (cells[i].type === SHAPE_TYPES.SCREEN) {
                  requireScreenRender = cells[i];
                }
                if (cells[i].type === SHAPE_TYPES.DMN) {
                  requireDMNArrowRender = cells[i];
                }
              }
            }
          } finally {
            graph.model.endUpdate();
            if (requireScreenRender) {
              requireScreenRender.name = requireScreenRender.value;
            }
            if (requireDMNArrowRender) {
              let table = requireDMNArrowRender.children?.[0]?.value;
              table = JSON.parse(table || '{}');
              if (table.rows) {
                let a = table.rows?.forEach((row, i) => {
                  graph.self.skipAction = true;
                  graph.self.focusDMNConnector(
                    i,
                    graph,
                    row,
                    requireDMNArrowRender,
                    graph.self,
                    table
                  );
                  graph.self.skipAction = false;

                });
              }
            }
            graph.getSelectionModel().clear();
          }

          // Increments the counter and selects the inserted cells
          mxClipboard.insertCount++;
        }
      };
      mxClipboard.copy = function (graph, cells) {
        cells = graph.getSelectionCells();
        var result = graph.getExportableCells(cells);
        mxClipboard.parents = new Object();
        var cloneMap = new Object();
        mxClipboard.insertCount = 1;
        let clonedCells = graph.cloneCells(result, null, cloneMap);
        let copiedCells = [];
        for (var i = 0; i < result.length; i++) {
          mxClipboard.parents[i] = graph.model.getParent(cells[i]);
        }

        // Uses temporary model to force new IDs to be assigned
        // to avoid having to carry over the mapping from object
        // ID to cell ID to the paste operation
        var model = new mxGraphModel();
        var parent = model.getChildAt(model.getRoot(), 0);
        for (var i = 0; i < clonedCells.length; i++) {
          model.add(parent, clonedCells[i]);
          if(evt && evt.isReference){
            //ToDo Reference Creation
            clonedCells[i].isReference = true;
            clonedCells[i].refuid = cells[i].uid;
            clonedCells[i].parentCellId = cells[i].id;
            clonedCells[i].parentCellVal = cells[i].value;
            clonedCells[i].value = graph.self.getDefaultName(clonedCells[i], clonedCells[i].value,evt.isReference);
            if (cells[i].type === SHAPE_TYPES.BOS) {
              clonedCells[i].isOfflineBOS = false;
              clonedCells[i].connectable = true;
              editor.beforeObjectAdd({ src: [clonedCells[i]], target: null });
              copiedCells.push(clonedCells[i]);
            }else if(cells[i].type === SHAPE_TYPES.SCREEN){
              clonedCells[i].uid = generateUid();
              clonedCells[i].requireLoader = false;
              clonedCells[i].children =clonedCells[i].children.filter(e=>e.type!="eye"&&e.type!="subtitle");
              copiedCells.push(clonedCells[i]);
            }
          }
          else if (
            cells[i].value !== '' &&
            cells[i].value !== ' ' &&
            (cells[i].type === SHAPE_TYPES.BOS ||
              cells[i].type === SHAPE_TYPES.TASK ||
              cells[i].type === SHAPE_TYPES.SCREEN ||
              cells[i].type === SHAPE_TYPES.SCRIPT ||
              cells[i].type === SHAPE_TYPES.ACTIVITY ||
              cells[i].type === SHAPE_TYPES.DMN ||
              cells[i].type === SHAPE_TYPES.XOR||
              cells[i].type === SHAPE_TYPES.ASSIGNMENT||
              cells[i].type === SHAPE_TYPES.EMAIL)
          ) {
            clonedCells[i].parentCellId = cells[i].id;
            clonedCells[i].parentCellVal = cells[i].value;
            clonedCells[i].value = clonedCells[i].value?.replaceAll(' ', '_');
            clonedCells[i].value = graph.self.getDefaultName(clonedCells[i], clonedCells[i].value);
            if (cells[i].type === SHAPE_TYPES.BOS) {
              clonedCells[i].isOfflineBOS = false;
              clonedCells[i].connectable = true;
              editor.beforeObjectAdd({ src: [clonedCells[i]], target: null });
              let payload = [];
              payload.push({
                fromName: cells[i].value || '',
                fromUuid: cells[i].uid || '',
                toName: clonedCells[i].value || '',
                toUuid: clonedCells[i].uid || '',
                type: cells[i].type,
              });
              copiedCells.push(clonedCells[i]);
              editor.cloneAllComponents(payload);
            }
            if (cells[i].type === SHAPE_TYPES.SCREEN) {
              clonedCells[i].uid = generateUid();
              let f = graph.self.screens?.find(
                (s) => s.id === cells[i].value || s.id === cells[i].name
              );
              clonedCells[i].requireLoader = f;
              copiedCells.push(clonedCells[i]);
              let payload = [];
              payload.push({
                fromName: cells[i].value || '',
                fromUuid: cells[i].uid || '',
                toName: clonedCells[i].value || '',
                toUuid: clonedCells[i].uid || '',
                type: cells[i].type,
              });
              let hierarchialEdges = cells[i].edges?.filter(
                (f) => f.isHierarchy && f.target?.type === SHAPE_TYPES.BOS
              );
              let cloneableTargets = hierarchialEdges?.map((f) => {
                cells.push(f);
                return f.target;
              });
              cloneableTargets = cloneableTargets?.map((f) => {
                cells.push(f);
                return f;
              });

              var newResult = graph.getExportableCells(cloneableTargets);
              mxClipboard.insertCount = mxClipboard.insertCount + 1;

              let clonedScreenCells = graph.cloneCells(
                newResult,
                null,
                cloneMap
              );
              clonedScreenCells &&
                clonedScreenCells.forEach((f, index) => {
                  let sourceCell = hierarchialEdges.find(
                    (h) => f.value === h.target?.value
                  );
                  f.uid = generateUid();
                  f.value = f.value + '_' + clonedCells[i].value;
                  f.parent = cells[i].parent;
                  payload.push({
                    fromName: sourceCell?.target?.value || '',
                    fromUuid: sourceCell?.target?.uid || '',
                    toName: f.value || '',
                    toUuid: f.uid || '',
                    type: f.type,
                  });
                  mxClipboard.parents[copiedCells.length] = graph.model.getParent(cells[i]);
                  copiedCells.push(f);
                });
              let exportHE = graph.getExportableCells(hierarchialEdges);
              let cloneHE = graph.cloneCells(exportHE, null, cloneMap);
              cloneHE &&
                cloneHE.forEach((he, index) => {
                  he.uid = generateUid();
                  he.isHierarchy = true;
                  he.targetIndex = index + 1;
                  he.source = clonedCells[i];
                  he.parent = cells[i].parent;
                  mxClipboard.parents[copiedCells.length] = graph.model.getParent(cells[i]);
                  copiedCells.push(he);
                });
              editor.cloneAllComponents(payload, (screen) => {
                setTimeout(() => {
                  if (requireScreenRender) {
                    graph.self.renderPageScreen([requireScreenRender]);
                  }
                });
              });
            } else if (cells[i].type === SHAPE_TYPES.TASK) {
              clonedCells[i].uid = generateUid();
              let payload = [];
              clonedCells[i].childTask =
                clonedCells[i].childTask?.map((g, j) => {
                  g.uid = generateUid();
                  if (
                    g.type === SHAPE_TYPES.BOS ||
                    g.type === SHAPE_TYPES.SCREEN
                  ) {
                    g.name = g.name + '_' + clonedCells[i].value;
                    payload.push({
                      fromName: cells[i].childTask[j]?.name || '',
                      toName: g.name,
                      fromUuid: cells[i].childTask[j]?.uid || '',
                      toUuid: g.uid,
                      type: g.type,
                    });
                  }
                  return g;
                }) || [];
              editor.cloneAllComponents(payload);
              copiedCells.push(clonedCells[i]);
            } else if (
              cells[i].type === SHAPE_TYPES.DMN ||
              cells[i].type === SHAPE_TYPES.XOR ||
              cells[i].type === SHAPE_TYPES.ACTIVITY||
              cells[i].type === SHAPE_TYPES.ASSIGNMENT||
              cells[i].type === SHAPE_TYPES.EMAIL
            ) {
              clonedCells[i].uid = generateUid();
              clonedCells[i].geometry.x = cells[i].geometry.x + 20;
              clonedCells[i].parent = cells[i].parent;
              copiedCells.push(clonedCells[i]);
            }
            //edge
          } else if (
            cells[i].type !== 'Start' &&
            cells[i].type !== 'End' &&
            cells[i].type !== 'EndIcon'
          ) {
            copiedCells.push(clonedCells[i]);
          }
        }
        var lookup = graph.createCellLookup(cells);
        graph.updateCustomLinks(
          graph.createCellMapping(cloneMap, lookup),
          copiedCells
        );
        mxClipboard.setCells(copiedCells);
        return result;
      };
      mxClipboard.copy(editor.graph);
    }
  });

  this.addAction('paste', function (editor) {
    if (editor.graph.isEnabled()) {
      mxClipboard.paste(editor.graph);
      mxClipboard.setCells([]);
    }
  });
  this.addAction('delete', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.removeCells();
    }
  });

  this.addAction('group', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setSelectionCell(editor.groupCells());
    }
  });

  this.addAction('ungroup', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setSelectionCells(editor.graph.ungroupCells());
    }
  });

  this.addAction('removeFromParent', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.removeCellsFromParent();
    }
  });

  this.addAction('undo', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.undo();
    }
  });

  this.addAction('redo', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.redo();
    }
  });

  this.addAction('zoomIn', function (editor) {
    editor.graph.zoomIn();
  });

  this.addAction('zoomOut', function (editor) {
    editor.graph.zoomOut();
  });

  this.addAction('actualSize', function (editor) {
    editor.graph.zoomActual();
  });

  this.addAction('fit', function (editor) {
    editor.graph.fit();
  });

  this.addAction('showProperties', function (editor, cell) {
    editor.showProperties(cell);
  });

  this.addAction('selectAll', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectAll();
    }
  });

  this.addAction('selectNone', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.clearSelection();
    }
  });

  this.addAction('selectVertices', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectVertices();
    }
  });

  this.addAction('selectEdges', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectEdges();
    }
  });

  this.addAction('edit', function (editor, cell) {
    if (editor.graph.isEnabled() && editor.graph.isCellEditable(cell)) {
      editor.graph.startEditingAtCell(cell);
    }
  });

  this.addAction('toBack', function (editor, cell) {
    if (editor.graph.isEnabled()) {
      editor.graph.orderCells(true);
    }
  });

  this.addAction('toFront', function (editor, cell) {
    if (editor.graph.isEnabled()) {
      editor.graph.orderCells(false);
    }
  });

  this.addAction('enterGroup', function (editor, cell) {
    editor.graph.enterGroup(cell);
  });

  this.addAction('exitGroup', function (editor) {
    editor.graph.exitGroup();
  });

  this.addAction('home', function (editor) {
    editor.graph.home();
  });

  this.addAction('selectPrevious', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectPreviousCell();
    }
  });

  this.addAction('selectNext', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectNextCell();
    }
  });

  this.addAction('selectParent', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectParentCell();
    }
  });

  this.addAction('selectChild', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.selectChildCell();
    }
  });

  this.addAction('collapse', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.foldCells(true);
    }
  });

  this.addAction('collapseAll', function (editor) {
    if (editor.graph.isEnabled()) {
      var cells = editor.graph.getChildVertices();
      editor.graph.foldCells(true, false, cells);
    }
  });

  this.addAction('expand', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.foldCells(false);
    }
  });

  this.addAction('expandAll', function (editor) {
    if (editor.graph.isEnabled()) {
      var cells = editor.graph.getChildVertices();
      editor.graph.foldCells(false, false, cells);
    }
  });

  this.addAction('bold', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.toggleCellStyleFlags(
        mxConstants.STYLE_FONTSTYLE,
        mxConstants.FONT_BOLD
      );
    }
  });

  this.addAction('italic', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.toggleCellStyleFlags(
        mxConstants.STYLE_FONTSTYLE,
        mxConstants.FONT_ITALIC
      );
    }
  });

  this.addAction('underline', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.toggleCellStyleFlags(
        mxConstants.STYLE_FONTSTYLE,
        mxConstants.FONT_UNDERLINE
      );
    }
  });

  this.addAction('alignCellsLeft', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.alignCells(mxConstants.ALIGN_LEFT);
    }
  });

  this.addAction('alignCellsCenter', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.alignCells(mxConstants.ALIGN_CENTER);
    }
  });

  this.addAction('alignCellsRight', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.alignCells(mxConstants.ALIGN_RIGHT);
    }
  });

  this.addAction('alignCellsTop', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.alignCells(mxConstants.ALIGN_TOP);
    }
  });

  this.addAction('alignCellsMiddle', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.alignCells(mxConstants.ALIGN_MIDDLE);
    }
  });

  this.addAction('alignCellsBottom', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.alignCells(mxConstants.ALIGN_BOTTOM);
    }
  });

  this.addAction('alignFontLeft', function (editor) {
    editor.graph.setCellStyles(mxConstants.STYLE_ALIGN, mxConstants.ALIGN_LEFT);
  });

  this.addAction('alignFontCenter', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setCellStyles(
        mxConstants.STYLE_ALIGN,
        mxConstants.ALIGN_CENTER
      );
    }
  });

  this.addAction('alignFontRight', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setCellStyles(
        mxConstants.STYLE_ALIGN,
        mxConstants.ALIGN_RIGHT
      );
    }
  });

  this.addAction('alignFontTop', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setCellStyles(
        mxConstants.STYLE_VERTICAL_ALIGN,
        mxConstants.ALIGN_TOP
      );
    }
  });

  this.addAction('alignFontMiddle', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setCellStyles(
        mxConstants.STYLE_VERTICAL_ALIGN,
        mxConstants.ALIGN_MIDDLE
      );
    }
  });

  this.addAction('alignFontBottom', function (editor) {
    if (editor.graph.isEnabled()) {
      editor.graph.setCellStyles(
        mxConstants.STYLE_VERTICAL_ALIGN,
        mxConstants.ALIGN_BOTTOM
      );
    }
  });

  this.addAction('zoom', function (editor) {
    var current = editor.graph.getView().scale * 100;
    var scale =
      parseFloat(
        mxUtils.prompt(
          mxResources.get(editor.askZoomResource) || editor.askZoomResource,
          current
        )
      ) / 100;

    if (!isNaN(scale)) {
      editor.graph.getView().setScale(scale);
    }
  });

  this.addAction('toggleTasks', function (editor) {
    if (editor.tasks != null) {
      //editor.tasks.setVisible(!editor.tasks.isVisible());
    } else {
      //editor.showTasks();
    }
  });

  this.addAction('toggleHelp', function (editor) {
    if (editor.help != null) {
      //editor.help.setVisible(false);
    } else {
      //editor.showHelp();
    }
  });

  this.addAction('toggleOutline', function (editor) {
    if (editor.outline == null) {
      //editor.showOutline();
    } else {
      //editor.outline.setVisible(false);
    }
  });

  this.addAction('toggleConsole', function (editor) {
    //mxLog.setVisible(false);
  });
};

/**
 * Function: configure
 *
 * Configures the editor using the specified node. To load the
 * configuration from a given URL the following code can be used to obtain
 * the XML node.
 *
 * (code)
 * var node = mxUtils.load(url).getDocumentElement();
 * (end)
 *
 * Parameters:
 *
 * node - XML node that contains the configuration.
 */
editor.prototype.configure = function (node) {
  if (node != null) {
    // Creates a decoder for the XML data
    // and uses it to configure the editor
    var dec = new mxCodec(node.ownerDocument);
    dec.decode(node, this);

    // Resets the counters, modified state and
    // command history
    this.resetHistory();
  }
};

/**
 * Function: resetFirstTime
 *
 * Resets the cookie that is used to remember if the editor has already
 * been used.
 */
editor.prototype.resetFirstTime = function () {
  document.cookie =
    'mxgraph=seen; expires=Fri, 27 Jul 2001 02:47:11 UTC; path=/';
};

/**
 * Function: resetHistory
 *
 * Resets the command history, modified state and counters.
 */
editor.prototype.resetHistory = function () {
  this.lastSnapshot = new Date().getTime();
  this.undoManager.clear();
  this.ignoredChanges = 0;
  this.setModified(false);
};

/**
 * Function: addAction
 *
 * Binds the specified actionname to the specified function.
 *
 * Parameters:
 *
 * actionname - String that specifies the name of the action
 * to be added.
 * funct - Function that implements the new action. The first
 * argument of the function is the editor it is used
 * with, the second argument is the cell it operates
 * upon.
 *
 * Example:
 * (code)
 * editor.addAction('test', function(editor, cell)
 * {
 * 		mxUtils.alert("test "+cell);
 * });
 * (end)
 */
editor.prototype.addAction = function (actionname, funct) {
  this.actions[actionname] = funct;
};

/**
 * Function: execute
 *
 * Executes the function with the given name in <actions> passing the
 * editor instance and given cell as the first and second argument. All
 * additional arguments are passed to the action as well. This method
 * contains a try-catch block and displays an error message if an action
 * causes an exception. The exception is re-thrown after the error
 * message was displayed.
 *
 * Example:
 *
 * (code)
 * editor.execute("showProperties", cell);
 * (end)
 */
editor.prototype.execute = function (actionname, cell, evt) {
  var action = this.actions[actionname];

  if (action != null) {
    try {
      // Creates the array of arguments by replacing the actionname
      // with the editor instance in the args of this function
      var args = arguments;
      args[0] = this;

      // Invokes the function on the editor using the args
      action.apply(this, args);
    } catch (e) {
      mxUtils.error(
        'Cannot execute ' + actionname + ': ' + e.message,
        280,
        true
      );

      throw e;
    }
  } else {
    mxUtils.error('Cannot find action ' + actionname, 280, true);
  }
};

/**
 * Function: addTemplate
 *
 * Adds the specified template under the given name in <templates>.
 */
editor.prototype.addTemplate = function (name, template) {
  this.templates[name] = template;
};

/**
 * Function: getTemplate
 *
 * Returns the template for the given name.
 */
editor.prototype.getTemplate = function (name) {
  return this.templates[name];
};

/**
 * Function: createGraph
 *
 * Creates the <graph> for the editor. The graph is created with no
 * container and is initialized from <setGraphContainer>.
 */
editor.prototype.InitGraph = function (graph) {
  //var graph = new mxGraph(null, null, this.graphRenderHint);

  // Enables rubberband, tooltips, panning
  graph.setTooltips(true);
  graph.setPanning(true);

  // Overrides the dblclick method on the graph to
  // invoke the dblClickAction for a cell and reset
  // the selection tool in the toolbar
  this.installDblClickHandler(graph);

  // Installs the command history
  this.installUndoHandler(graph);

  // Installs the handlers for the root event
  this.installDrillHandler(graph);

  // Installs the handler for validation
  this.installChangeHandler(graph);

  // Installs the handler for calling the
  // insert function and consume the
  // event if an insert function is defined
  this.installInsertHandler(graph);
  this.createSwimlaneManager(graph);
  this.createLayoutManager(graph);

  return graph;
};

/**
 * Function: createSwimlaneManager
 *
 * Sets the graph's container using <mxGraph.init>.
 */
editor.prototype.createSwimlaneManager = function (graph) {
  var swimlaneMgr = new mxSwimlaneManager(graph, false);

  swimlaneMgr.isHorizontal = mxUtils.bind(this, function () {
    return this.horizontalFlow;
  });

  swimlaneMgr.isEnabled = mxUtils.bind(this, function () {
    return this.maintainSwimlanes;
  });

  return swimlaneMgr;
};

/**
 * Function: createLayoutManager
 *
 * Creates a layout manager for the swimlane and diagram layouts, that
 * is, the locally defined inter- and intraswimlane layouts.
 */
editor.prototype.createLayoutManager = function (graph) {
  var layoutMgr = new mxLayoutManager(graph);

  var self = this; // closure
  layoutMgr.getLayout = function (cell) {
    var layout = null;
    var model = self.graph.getModel();

    if (model.getParent(cell) != null) {
      // Executes the swimlane layout if a child of
      // a swimlane has been changed. The layout is
      // lazy created in createSwimlaneLayout.
      if (self.layoutSwimlanes && graph.isSwimlane(cell)) {
        if (self.swimlaneLayout == null) {
          self.swimlaneLayout = self.createSwimlaneLayout();
        }

        layout = self.swimlaneLayout;
      }

      // Executes the diagram layout if the modified
      // cell is a top-level cell. The layout is
      // lazy created in createDiagramLayout.
      else if (
        self.layoutDiagram &&
        (graph.isValidRoot(cell) ||
          model.getParent(model.getParent(cell)) == null)
      ) {
        if (self.diagramLayout == null) {
          self.diagramLayout = self.createDiagramLayout();
        }

        layout = self.diagramLayout;
      }
    }

    return layout;
  };

  return layoutMgr;
};

/**
 * Function: setGraphContainer
 *
 * Sets the graph's container using <mxGraph.init>.
 */
editor.prototype.setGraphContainer = function (container) {
  if (this.graph.container == null) {
    // Creates the graph instance inside the given container and render hint
    //this.graph = new mxGraph(container, null, this.graphRenderHint);
    this.graph.init(container);

    // Install rubberband selection as the last
    // action handler in the chain
    this.rubberband = new mxRubberband(this.graph);

    // Disables the context menu
    if (this.disableContextMenu) {
      mxEvent.disableContextMenu(container);
    }

    // Workaround for stylesheet directives in IE
    if (mxClient.IS_QUIRKS) {
      new mxDivResizer(container);
    }
  }
};

/**
 * Function: installDblClickHandler
 *
 * Overrides <mxGraph.dblClick> to invoke <dblClickAction>
 * on a cell and reset the selection tool in the toolbar.
 */
editor.prototype.installDblClickHandler = function (graph) {
  // Installs a listener for double click events
  graph.addListener(
    mxEvent.DOUBLE_CLICK,
    mxUtils.bind(this, function (sender, evt) {
      var cell = evt.getProperty('cell');

      if (cell != null && graph.isEnabled() && this.dblClickAction != null) {
        this.execute(this.dblClickAction, cell);
        evt.consume();
      }
    })
  );
};

/**
 * Function: installUndoHandler
 *
 * Adds the <undoManager> to the graph model and the view.
 */
editor.prototype.installUndoHandler = function (graph) {
  let self;
  var listener = mxUtils.bind(this, function (sender, evt) {
    self = this;
    var edit = evt.getProperty('edit');
    this.undoManager.undoableEditHappened(edit);
  });

  graph.getModel().addListener(mxEvent.UNDO, listener);
  graph.getView().addListener(mxEvent.UNDO, listener);

  // Keeps the selection state in sync
  var undoHandler = function (sender, evt) {
    var changes = evt.getProperty('edit').changes;
    let cell = changes[0].cell;
    if (cell && cell.parent && !cell.edge) {
      // let val = cell.value !== ' ' && JSON.parse(changes[0].cell.value); // this line is throwing error, so updated as below
      // previous version 
      // let val = (cell.value !== ' ' && (cell.parent.type === 'DMN' || cell.type === 'DMN')) && JSON.parse(changes?.[0]?.cell?.value);
      let val = (cell.value !== ' ' && (cell.parent.type === 'DMN' || cell.type === 'DMN')) &&  cell.children[0] &&  JSON.parse(cell.children[0].value);
      if (cell.parent.type === 'DMN' && val !== ' ') {
        self.changeDMN(val);
        graph.setSelectionCells([cell.parent]);
        return 0;
      } else if (
        cell.type === 'DMN' &&
        cell.children[0].value === '{"rows":[], "columns":[]}'
      ) {
        self.closeDMNTable(val);
      }
    }else if((cell && cell.edge && cell.parent && cell.parent.type==='Case') || changes[0].constructor.name === 'mxChildChange' ){
        let previous = changes[0].previous;
        let parent = cell?.parent;
        let caseCell = parent || previous;
        let  { data } = caseCell;
        data = JSON.parse(data);
        let outgoingEdges = graph.getModel().getOutgoingEdges(caseCell);
        data.caseOptions.forEach((e,i)=>{
          let ed= outgoingEdges.find(f1=>f1.uid===e.id);
          if(!ed){
            delete data.caseOptions.splice(i, 1);
          }else{
            data.caseOptions[i].value=ed.value;
          }
        });
        caseCell.data=JSON.stringify(data);
    }
    graph.setSelectionCells(graph.getSelectionCellsForChanges(changes));
  };

  this.undoManager.addListener(mxEvent.UNDO, undoHandler);
  this.undoManager.addListener(mxEvent.REDO, undoHandler);
};

/**
 * Function: installDrillHandler
 *
 * Installs listeners for dispatching the <root> event.
 */
editor.prototype.installDrillHandler = function (graph) {
  var listener = mxUtils.bind(this, function (sender) {
    this.fireEvent(new mxEventObject(mxEvent.ROOT));
  });

  graph.getView().addListener(mxEvent.DOWN, listener);
  graph.getView().addListener(mxEvent.UP, listener);
};

/**
 * Function: installChangeHandler
 *
 * Installs the listeners required to automatically validate
 * the graph. On each change of the root, this implementation
 * fires a <root> event.
 */
editor.prototype.installChangeHandler = function (graph) {
  var listener = mxUtils.bind(this, function (sender, evt) {
    // Updates the modified state
    this.setModified(true);

    // Automatically validates the graph
    // after each change
    if (this.validating == true) {
      graph.validateGraph();
    }

    // Checks if the root has been changed
    var changes = evt.getProperty('edit').changes;
    var undoablecell = null;
    for (var i = 0; i < changes.length; i++) {
      var change = changes[i];
      if (
        change.hasOwnProperty('child') &&
        change.parent !== null &&
        change.parent.id === '1' &&
        change.previous !== null &&
        this.type === 'businessFunction' &&
        change.child.type !== 'Lane' &&
        !change.child.edge
      ) {
        let self = this;
        if(!undoablecell && !change?.child?.edge){
          undoablecell = change.child;
        }
        setTimeout(() => {
          self.undoManager.undo();
          self.graph.self.skipAction = true;
          let childs = undoablecell?.children || [];
          childs = childs.map(e => {
            let geo = e.geometry.clone();
            geo.y = 0;
            if(undoablecell.type === SHAPE_TYPES.SCREEN){
              if(e.type === SHAPE_TYPES.EYE) geo.y = 274;
              else if(e.type === "subtitle") geo.y = 258;
            }
            graph.getModel().setGeometry(e, geo);
          });
          self.graph.self.skipAction = false;
        }, 500);
      } else if (
        change.hasOwnProperty('child') &&
        change.parent !== null &&
        change.parent.id !== '1' &&
        change.previous !== null &&
        this.type === 'userTaskDetail'
      ) {
        // let self = this
        // graph.getModel().beginUpdate();
        // setTimeout(() => {
        // 	self.undoManager.undo();
        // 	graph.getModel().endUpdate();
        // }, 10);
      }
      // if (change instanceof mxRootChange ||
      // 	(change instanceof mxValueChange &&
      // 	change.cell == this.graph.model.root) ||
      // 	(change instanceof mxCellAttributeChange &&
      // 	change.cell == this.graph.model.root))
      // {
      // 	this.fireEvent(new mxEventObject(mxEvent.ROOT));
      // 	break;
      // }
    }
    var edit = evt != null ? evt.getProperty('edit') : null;

    if (edit == null || !edit.ignoreEdit) {
      this.setModified(true);
    }
  });

  graph.getModel().addListener(mxEvent.CHANGE, listener);
  graph.resetViewOnRootChange = false;
};

/**
 * Function: installInsertHandler
 *
 * Installs the handler for invoking <insertFunction> if
 * one is defined.
 */
editor.prototype.installInsertHandler = function (graph) {
  var self = this; // closure
  var insertHandler = {
    mouseDown: function (sender, me) {
      if (
        self.insertFunction != null &&
        !me.isPopupTrigger() &&
        (self.forcedInserting || me.getState() == null)
      ) {
        self.graph.clearSelection();
        self.insertFunction(me.getEvent(), me.getCell());

        // Consumes the rest of the events
        // for this gesture (down, move, up)
        this.isActive = true;
        me.consume();
      }
    },

    mouseMove: function (sender, me) {
      if (this.isActive) {
        me.consume();
      }
    },

    mouseUp: function (sender, me) {
      if (this.isActive) {
        this.isActive = false;
        me.consume();
      }
    },
  };

  graph.addMouseListener(insertHandler);
};

/**
 * Function: createDiagramLayout
 *
 * Creates the layout instance used to layout the
 * swimlanes in the diagram.
 */
editor.prototype.createDiagramLayout = function () {
  var gs = this.graph.gridSize;
  var layout = new mxStackLayout(
    this.graph,
    !this.horizontalFlow,
    this.swimlaneSpacing,
    2 * gs,
    2 * gs
  );

  // Overrides isIgnored to only take into account swimlanes
  layout.isVertexIgnored = function (cell) {
    return !layout.graph.isSwimlane(cell);
  };

  return layout;
};

/**
 * Function: createSwimlaneLayout
 *
 * Creates the layout instance used to layout the
 * children of each swimlane.
 */
editor.prototype.createSwimlaneLayout = function () {
  return new mxCompactTreeLayout(this.graph, this.horizontalFlow);
};

/**
 * Function: createToolbar
 *
 * Creates the <toolbar> with no container.
 */
editor.prototype.createToolbar = function () {
  //return new mxDefaultToolbar(null, this);
};

/**
 * Function: setToolbarContainer
 *
 * Initializes the toolbar for the given container.
 */
editor.prototype.setToolbarContainer = function (container) {
  this.toolbar.init(container);

  // Workaround for stylesheet directives in IE
  if (mxClient.IS_QUIRKS) {
    new mxDivResizer(container);
  }
};

/**
 * Function: setStatusContainer
 *
 * Creates the <status> using the specified container.
 *
 * This implementation adds listeners in the editor to
 * display the last saved time and the current filename
 * in the status bar.
 *
 * Parameters:
 *
 * container - DOM node that will contain the statusbar.
 */
editor.prototype.setStatusContainer = function (container) {
  if (this.status == null) {
    this.status = container;

    // Prints the last saved time in the status bar
    // when files are saved
    this.addListener(
      mxEvent.SAVE,
      mxUtils.bind(this, function () {
        var tstamp = new Date().toLocaleString();
        this.setStatus(
          (mxResources.get(this.lastSavedResource) || this.lastSavedResource) +
            ': ' +
            tstamp
        );
      })
    );

    // Updates the statusbar to display the filename
    // when new files are opened
    this.addListener(
      mxEvent.OPEN,
      mxUtils.bind(this, function () {
        this.setStatus(
          (mxResources.get(this.currentFileResource) ||
            this.currentFileResource) +
            ': ' +
            this.filename
        );
      })
    );

    // Workaround for stylesheet directives in IE
    if (mxClient.IS_QUIRKS) {
      new mxDivResizer(container);
    }
  }
};

/**
 * Function: setStatus
 *
 * Display the specified message in the status bar.
 *
 * Parameters:
 *
 * message - String the specified the message to
 * be displayed.
 */
editor.prototype.setStatus = function (message) {
  if (this.status != null && message != null) {
    this.status.innerHTML = message;
  }
};

/**
 * Function: setTitleContainer
 *
 * Creates a listener to update the inner HTML of the
 * specified DOM node with the value of <getTitle>.
 *
 * Parameters:
 *
 * container - DOM node that will contain the title.
 */
editor.prototype.setTitleContainer = function (container) {
  this.addListener(
    mxEvent.ROOT,
    mxUtils.bind(this, function (sender) {
      container.innerHTML = this.getTitle();
    })
  );

  // Workaround for stylesheet directives in IE
  if (mxClient.IS_QUIRKS) {
    new mxDivResizer(container);
  }
};

/**
 * Function: treeLayout
 *
 * Executes a vertical or horizontal compact tree layout
 * using the specified cell as an argument. The cell may
 * either be a group or the root of a tree.
 *
 * Parameters:
 *
 * cell - <mxCell> to use in the compact tree layout.
 * horizontal - Optional boolean to specify the tree's
 * orientation. Default is true.
 */
editor.prototype.treeLayout = function (cell, horizontal) {
  if (cell != null) {
    var layout = new mxCompactTreeLayout(this.graph, horizontal);
    layout.execute(cell);
  }
};

/**
 * Function: getTitle
 *
 * Returns the string value for the current root of the
 * diagram.
 */
editor.prototype.getTitle = function () {
  var title = '';
  var graph = this.graph;
  var cell = graph.getCurrentRoot();

  while (
    cell != null &&
    graph.getModel().getParent(graph.getModel().getParent(cell)) != null
  ) {
    // Append each label of a valid root
    if (graph.isValidRoot(cell)) {
      title = ' > ' + graph.convertValueToString(cell) + title;
    }

    cell = graph.getModel().getParent(cell);
  }

  var prefix = this.getRootTitle();

  return prefix + title;
};

/**
 * Function: getRootTitle
 *
 * Returns the string value of the root cell in
 * <mxGraph.model>.
 */
editor.prototype.getRootTitle = function () {
  var root = this.graph.getModel().getRoot();
  return this.graph.convertValueToString(root);
};

/**
 * Function: undo
 *
 * Undo the last change in <graph>.
 */
editor.prototype.undo = function () {
  this.undoManager.undo();
};

/**
 * Function: redo
 *
 * Redo the last change in <graph>.
 */
editor.prototype.redo = function () {
  this.undoManager.redo();
};

/**
 * Function: groupCells
 *
 * Invokes <createGroup> to create a new group cell and the invokes
 * <mxGraph.groupCells>, using the grid size of the graph as the spacing
 * in the group's content area.
 */
editor.prototype.groupCells = function () {
  var border =
    this.groupBorderSize != null ? this.groupBorderSize : this.graph.gridSize;
  return this.graph.groupCells(this.createGroup(), border);
};

/**
 * Function: createGroup
 *
 * Creates and returns a clone of <defaultGroup> to be used
 * as a new group cell in <group>.
 */
editor.prototype.createGroup = function () {
  var model = this.graph.getModel();

  return model.cloneCell(this.defaultGroup);
};

/**
 * Function: open
 *
 * Opens the specified file synchronously and parses it using
 * <readGraphModel>. It updates <filename> and fires an <open>-event after
 * the file has been opened. Exceptions should be handled as follows:
 *
 * (code)
 * try
 * {
 *   editor.open(filename);
 * }
 * catch (e)
 * {
 *   mxUtils.error('Cannot open ' + filename +
 *     ': ' + e.message, 280, true);
 * }
 * (end)
 *
 * Parameters:
 *
 * filename - URL of the file to be opened.
 */
editor.prototype.open = function (filename) {
  if (filename != null) {
    var xml = mxUtils.load(filename).getXml();
    this.readGraphModel(xml.documentElement);
    this.filename = filename;

    this.fireEvent(new mxEventObject(mxEvent.OPEN, 'filename', filename));
  }
};

/**
 * Function: readGraphModel
 *
 * Reads the specified XML node into the existing graph model and resets
 * the command history and modified state.
 */
editor.prototype.readGraphModel = function (node) {
  var dec = new mxCodec(node.ownerDocument);
  dec.decode(node, this.graph.getModel());
  this.resetHistory();
};

/**
 * Function: save
 *
 * Posts the string returned by <writeGraphModel> to the given URL or the
 * URL returned by <getUrlPost>. The actual posting is carried out by
 * <postDiagram>. If the URL is null then the resulting XML will be
 * displayed using <mxUtils.popup>. Exceptions should be handled as
 * follows:
 *
 * (code)
 * try
 * {
 *   editor.save();
 * }
 * catch (e)
 * {
 *   mxUtils.error('Cannot save : ' + e.message, 280, true);
 * }
 * (end)
 */
editor.prototype.save = function (url, linefeed) {
  // Gets the URL to post the data to
  url = url || this.getUrlPost();

  // Posts the data if the URL is not empty
  if (url != null && url.length > 0) {
    var data = this.writeGraphModel(linefeed);
    this.postDiagram(url, data);

    // Resets the modified flag
    this.setModified(false);
  }

  // Dispatches a save event
  this.fireEvent(new mxEventObject(mxEvent.SAVE, 'url', url));
};

/**
 * Function: postDiagram
 *
 * Hook for subclassers to override the posting of a diagram
 * represented by the given node to the given URL. This fires
 * an asynchronous <post> event if the diagram has been posted.
 *
 * Example:
 *
 * To replace the diagram with the diagram in the response, use the
 * following code.
 *
 * (code)
 * editor.addListener(mxEvent.POST, function(sender, evt)
 * {
 *   // Process response (replace diagram)
 *   var req = evt.getProperty('request');
 *   var root = req.getDocumentElement();
 *   editor.graph.readGraphModel(root)
 * });
 * (end)
 */
editor.prototype.postDiagram = function (url, data) {
  if (this.escapePostData) {
    data = encodeURIComponent(data);
  }

  mxUtils.post(
    url,
    this.postParameterName + '=' + data,
    mxUtils.bind(this, function (req) {
      this.fireEvent(
        new mxEventObject(
          mxEvent.POST,
          'request',
          req,
          'url',
          url,
          'data',
          data
        )
      );
    })
  );
};

/**
 * Function: writeGraphModel
 *
 * Hook to create the string representation of the diagram. The default
 * implementation uses an <mxCodec> to encode the graph model as
 * follows:
 *
 * (code)
 * var enc = new mxCodec();
 * var node = enc.encode(this.graph.getModel());
 * return mxUtils.getXml(node, this.linefeed);
 * (end)
 *
 * Parameters:
 *
 * linefeed - Optional character to be used as the linefeed. Default is
 * <linefeed>.
 */
editor.prototype.writeGraphModel = function (linefeed) {
  linefeed = linefeed != null ? linefeed : this.linefeed;
  var enc = new mxCodec();
  var node = enc.encode(this.graph.getModel());

  return mxUtils.getXml(node, linefeed);
};

/**
 * Function: getUrlPost
 *
 * Returns the URL to post the diagram to. This is used
 * in <save>. The default implementation returns <urlPost>,
 * adding <code>?draft=true</code>.
 */
editor.prototype.getUrlPost = function () {
  return this.urlPost;
};

/**
 * Function: getUrlImage
 *
 * Returns the URL to create the image with. This is typically
 * the URL of a backend which accepts an XML representation
 * of a graph view to create an image. The function is used
 * in the image action to create an image. This implementation
 * returns <urlImage>.
 */
editor.prototype.getUrlImage = function () {
  return this.urlImage;
};

/**
 * Function: swapStyles
 *
 * Swaps the styles for the given names in the graph's
 * stylesheet and refreshes the graph.
 */
editor.prototype.swapStyles = function (first, second) {
  var style = this.graph.getStylesheet().styles[second];
  this.graph
    .getView()
    .getStylesheet()
    .putCellStyle(second, this.graph.getStylesheet().styles[first]);
  this.graph.getStylesheet().putCellStyle(first, style);
  this.graph.refresh();
};

/**
 * Function: showProperties
 *
 * Creates and shows the properties dialog for the given
 * cell. The content area of the dialog is created using
 * <createProperties>.
 */
editor.prototype.showProperties = function (cell) {
  /*cell = cell || this.graph.getSelectionCell();

	// Uses the root node for the properties dialog
	// if not cell was passed in and no cell is
	// selected
	if (cell == null)
	{
		cell = this.graph.getCurrentRoot();

		if (cell == null)
		{
			cell = this.graph.getModel().getRoot();
		}
	}

	if (cell != null)
	{
		// Makes sure there is no in-place editor in the
		// graph and computes the location of the dialog
		this.graph.stopEditing(true);

		var offset = mxUtils.getOffset(this.graph.container);
		var x = offset.x+10;
		var y = offset.y;

		// Avoids moving the dialog if it is alredy open
		if (this.properties != null && !this.movePropertiesDialog)
		{
			x = this.properties.getX();
			y = this.properties.getY();
		}

		// Places the dialog near the cell for which it
		// displays the properties
		else
		{
			var bounds = this.graph.getCellBounds(cell);

			if (bounds != null)
			{
				x += bounds.x+Math.min(200, bounds.width);
				y += bounds.y;
			}
		}

		// Hides the existing properties dialog and creates a new one with the
		// contents created in the hook method
		this.hideProperties();
		var node = this.createProperties(cell);

		if (node != null)
		{
			// Displays the contents in a window and stores a reference to the
			// window for later hiding of the window
			this.properties = new mxWindow(mxResources.get(this.propertiesResource) ||
				this.propertiesResource, node, x, y, this.propertiesWidth, this.propertiesHeight, false);
		//	this.properties.setVisible(true);
		}
	}*/
};

/**
 * Function: isPropertiesVisible
 *
 * Returns true if the properties dialog is currently visible.
 */
editor.prototype.isPropertiesVisible = function () {
  return this.properties != null;
};

/**
 * Function: createProperties
 *
 * Creates and returns the DOM node that represents the contents
 * of the properties dialog for the given cell. This implementation
 * works for user objects that are XML nodes and display all the
 * node attributes in a form.
 */
editor.prototype.createProperties = function (cell) {
  /*var model = this.graph.getModel();
	var value = model.getValue(cell);

	if (mxUtils.isNode(value))
	{
		// Creates a form for the user object inside
		// the cell
		var form = new mxForm('properties');

		// Adds a readonly field for the cell id
		var id = form.addText('ID', cell.getId());
		id.setAttribute('readonly', 'true');

		var geo = null;
		var yField = null;
		var xField = null;
		var widthField = null;
		var heightField = null;

		// Adds fields for the location and size
		if (model.isVertex(cell))
		{
			geo = model.getGeometry(cell);

			if (geo != null)
			{
				yField = form.addText('top', geo.y);
				xField = form.addText('left', geo.x);
				widthField = form.addText('width', geo.width);
				heightField = form.addText('height', geo.height);
			}
		}

		// Adds a field for the cell style
		var tmp = model.getStyle(cell);
		var style = form.addText('Style', tmp || '');

		// Creates textareas for each attribute of the
		// user object within the cell
		var attrs = value.attributes;
		var texts = [];

		for (var i = 0; i < attrs.length; i++)
		{
			// Creates a textarea with more lines for
			// the cell label
			var val = attrs[i].value;
			texts[i] = form.addTextarea(attrs[i].nodeName, val,
				(attrs[i].nodeName == 'label') ? 4 : 2);
		}

		// Adds an OK and Cancel button to the dialog
		// contents and implements the respective
		// actions below

		// Defines the function to be executed when the
		// OK button is pressed in the dialog
		var okFunction = mxUtils.bind(this, function()
		{
			// Hides the dialog
			this.hideProperties();

			// Supports undo for the changes on the underlying
			// XML structure / XML node attribute changes.
			model.beginUpdate();
			try
			{
				if (geo != null)
				{
					geo = geo.clone();

					geo.x = parseFloat(xField.value);
					geo.y = parseFloat(yField.value);
					geo.width = parseFloat(widthField.value);
					geo.height = parseFloat(heightField.value);

					model.setGeometry(cell, geo);
				}

				// Applies the style
				if (style.value.length > 0)
				{
					model.setStyle(cell, style.value);
				}
				else
				{
					model.setStyle(cell, null);
				}

				// Creates an undoable change for each
				// attribute and executes it using the
				// model, which will also make the change
				// part of the current transaction
				for (var i=0; i<attrs.length; i++)
				{
					var edit = new mxCellAttributeChange(
						cell, attrs[i].nodeName,
						texts[i].value);
					model.execute(edit);
				}

				// Checks if the graph wants cells to
				// be automatically sized and updates
				// the size as an undoable step if
				// the feature is enabled
				if (this.graph.isAutoSizeCell(cell))
				{
					this.graph.updateCellSize(cell);
				}
			}
			finally
			{
				model.endUpdate();
			}
		});

		// Defines the function to be executed when the
		// Cancel button is pressed in the dialog
		var cancelFunction = mxUtils.bind(this, function()
		{
			// Hides the dialog
			this.hideProperties();
		});

		form.addButtons(okFunction, cancelFunction);

		return form.table;
	}

	return null;*/
};

/**
 * Function: hideProperties
 *
 * Hides the properties dialog.
 */
editor.prototype.hideProperties = function () {
  if (this.properties != null) {
    this.properties.destroy();
    this.properties = null;
  }
};

/**
 * Function: showTasks
 *
 * Shows the tasks window. The tasks window is created using <createTasks>. The
 * default width of the window is 200 pixels, the y-coordinate of the location
 * can be specifies in <tasksTop> and the x-coordinate is right aligned with a
 * 20 pixel offset from the right border. To change the location of the tasks
 * window, the following code can be used:
 *
 * (code)
 * var oldShowTasks = editor.prototype.showTasks;
 * editor.prototype.showTasks = function()
 * {
 *   oldShowTasks.apply(this, arguments); // "supercall"
 *
 *   if (this.tasks != null)
 *   {
 *     this.tasks.setLocation(10, 10);
 *   }
 * };
 * (end)
 */
editor.prototype.showTasks = function () {
  /*if (this.tasks == null)
	{
		var div = document.createElement('div');
		div.style.padding = '4px';
		div.style.paddingLeft = '20px';
		var w = document.body.clientWidth;
		var wnd = new mxWindow(
			mxResources.get(this.tasksResource) ||
			this.tasksResource,
			div, w - 220, this.tasksTop, 200);
		wnd.setClosable(true);
		wnd.destroyOnClose = false;

		// Installs a function to update the contents
		// of the tasks window on every change of the
		// model, selection or root.
		var funct = mxUtils.bind(this, function(sender)
		{
			mxEvent.release(div);
			div.innerHTML = '';
			this.createTasks(div);
		});

		this.graph.getModel().addListener(mxEvent.CHANGE, funct);
		this.graph.getSelectionModel().addListener(mxEvent.CHANGE, funct);
		this.graph.addListener(mxEvent.ROOT, funct);

		// Assigns the icon to the tasks window
		if (this.tasksWindowImage != null)
		{
			//wnd.setImage(this.tasksWindowImage);
		}

		this.tasks = wnd;
		this.createTasks(div);
	}

	//this.tasks.setVisible(true);*/
};

/**
 * Function: refreshTasks
 *
 * Updates the contents of the tasks window using <createTasks>.
 */
editor.prototype.refreshTasks = function (div) {
  if (this.tasks != null) {
    var div = this.tasks.content;
    mxEvent.release(div);
    div.innerHTML = '';
    this.createTasks(div);
  }
};

/**
 * Function: createTasks
 *
 * Updates the contents of the given DOM node to
 * display the tasks associated with the current
 * editor state. This is invoked whenever there
 * is a possible change of state in the editor.
 * Default implementation is empty.
 */
editor.prototype.createTasks = function (div) {
  // override
};

/**
 * Function: showHelp
 *
 * Shows the help window. If the help window does not exist
 * then it is created using an iframe pointing to the resource
 * for the <code>urlHelp</code> key or <urlHelp> if the resource
 * is undefined.
 */
editor.prototype.showHelp = function (tasks) {
  /*if (this.help == null)
	{
		var frame = document.createElement('iframe');
		frame.setAttribute('src', mxResources.get('urlHelp') || this.urlHelp);
		frame.setAttribute('height', '100%');
		frame.setAttribute('width', '100%');
		frame.setAttribute('frameBorder', '0');
		frame.style.backgroundColor = 'white';

		var w = document.body.clientWidth;
		var h = (document.body.clientHeight || document.documentElement.clientHeight);

		var wnd = new mxWindow(mxResources.get(this.helpResource) || this.helpResource,
			frame, (w-this.helpWidth)/2, (h-this.helpHeight)/3, this.helpWidth, this.helpHeight);
		wnd.setMaximizable(true);
		wnd.setClosable(true);
		wnd.destroyOnClose = false;
		wnd.setResizable(true);

		// Assigns the icon to the help window
		if (this.helpWindowImage != null)
		{
			//wnd.setImage(this.helpWindowImage);
		}

		// Workaround for ignored iframe height 100% in FF
		if (mxClient.IS_NS)
		{
			var handler = function(sender)
			{
				var h = wnd.div.offsetHeight;
				frame.setAttribute('height', (h-26)+'px');
			};

			wnd.addListener(mxEvent.RESIZE_END, handler);
			wnd.addListener(mxEvent.MAXIMIZE, handler);
			wnd.addListener(mxEvent.NORMALIZE, handler);
			wnd.addListener(mxEvent.SHOW, handler);
		}

		this.help = wnd;
	}

	//this.help.setVisible(true);*/
};

/**
 * Function: showOutline
 *
 * Shows the outline window. If the window does not exist, then it is
 * created using an <mxOutline>.
 */
editor.prototype.showOutline = function () {
  /*var create = this.outline == null;

	if (create)
	{
		var div = document.createElement('div');

		div.style.overflow = 'hidden';
		div.style.position = 'relative';
		div.style.width = '100%';
		div.style.height = '100%';
		div.style.background = 'white';
		div.style.cursor = 'move';

		if (document.documentMode == 8)
		{
			div.style.filter = 'progid:DXImageTransform.Microsoft.alpha(opacity=100)';
		}

		var wnd = new mxWindow(
			mxResources.get(this.outlineResource) ||
			this.outlineResource,
			div, 600, 480, 200, 200, false);

		// Creates the outline in the specified div
		// and links it to the existing graph
		var outline = new mxOutline(this.graph, div);
		wnd.setClosable(true);
		wnd.setResizable(true);
		wnd.destroyOnClose = false;

		wnd.addListener(mxEvent.RESIZE_END, function()
		{
			outline.update();
		});

		this.outline = wnd;
		this.outline.outline = outline;
	}

	// Finally shows the outline
	//this.outline.setVisible(true);
	//this.outline.outline.update(true);*/
};

/**
 * Function: setMode
 *
 * Puts the graph into the specified mode. The following modenames are
 * supported:
 *
 * select - Selects using the left mouse button, new connections
 * are disabled.
 * connect - Selects using the left mouse button or creates new
 * connections if mouse over cell hotspot. See <mxConnectionHandler>.
 * pan - Pans using the left mouse button, new connections are disabled.
 */
editor.prototype.setMode = function (modename) {
  if (modename == 'select') {
    this.graph.panningHandler.useLeftButtonForPanning = false;
    this.graph.setConnectable(false);
  } else if (modename == 'connect') {
    this.graph.panningHandler.useLeftButtonForPanning = false;
    this.graph.setConnectable(true);
  } else if (modename == 'pan') {
    this.graph.panningHandler.useLeftButtonForPanning = true;
    this.graph.setConnectable(false);
  }
};

/**
 * Function: createPopupMenu
 *
 * Uses <popupHandler> to create the menu in the graph's
 * panning handler. The redirection is setup in
 * <setToolbarContainer>.
 */
editor.prototype.createPopupMenu = function (menu, cell, evt) {
  this.popupHandler.createMenu(this, menu, cell, evt);
};

/**
 * Function: createEdge
 *
 * Uses <defaultEdge> as the prototype for creating new edges
 * in the connection handler of the graph. The style of the
 * edge will be overridden with the value returned by
 * <getEdgeStyle>.
 */
editor.prototype.createEdge = function (source, target) {
  // Clones the defaultedge prototype
  var e = null;

  if (this.defaultEdge != null) {
    var model = this.graph.getModel();
    e = model.cloneCell(this.defaultEdge);
  } else {
    e = new mxCell('');
    e.setEdge(true);

    var geo = new mxGeometry();
    geo.relative = true;
    e.setGeometry(geo);
  }

  // Overrides the edge style
  var style = this.getEdgeStyle();

  if (style != null) {
    e.setStyle(style);
  }

  return e;
};

/**
 * Function: getEdgeStyle
 *
 * Returns a string identifying the style of new edges.
 * The function is used in <createEdge> when new edges
 * are created in the graph.
 */
editor.prototype.getEdgeStyle = function () {
  return this.defaultEdgeStyle;
};

/**
 * Function: consumeCycleAttribute
 *
 * Returns the next attribute in <cycleAttributeValues>
 * or null, if not attribute should be used in the
 * specified cell.
 */
editor.prototype.consumeCycleAttribute = function (cell) {
  return this.cycleAttributeValues != null &&
    this.cycleAttributeValues.length > 0 &&
    this.graph.isSwimlane(cell)
    ? this.cycleAttributeValues[
        this.cycleAttributeIndex++ % this.cycleAttributeValues.length
      ]
    : null;
};

/**
 * Function: cycleAttribute
 *
 * Uses the returned value from <consumeCycleAttribute>
 * as the value for the <cycleAttributeName> key in
 * the given cell's style.
 */
editor.prototype.cycleAttribute = function (cell) {
  if (this.cycleAttributeName != null) {
    var value = this.consumeCycleAttribute(cell);

    if (value != null) {
      cell.setStyle(
        cell.getStyle() + ';' + this.cycleAttributeName + '=' + value
      );
    }
  }
};

/**
 * Function: addVertex
 *
 * Adds the given vertex as a child of parent at the specified
 * x and y coordinate and fires an <addVertex> event.
 */
editor.prototype.addVertex = function (parent, vertex, x, y) {
  var model = this.graph.getModel();

  while (parent != null && !this.graph.isValidDropTarget(parent)) {
    parent = model.getParent(parent);
  }

  parent = parent != null ? parent : this.graph.getSwimlaneAt(x, y);
  var scale = this.graph.getView().scale;

  var geo = model.getGeometry(vertex);
  var pgeo = model.getGeometry(parent);

  if (this.graph.isSwimlane(vertex) && !this.graph.swimlaneNesting) {
    parent = null;
  } else if (parent == null && this.swimlaneRequired) {
    return null;
  } else if (parent != null && pgeo != null) {
    // Keeps vertex inside parent
    var state = this.graph.getView().getState(parent);

    if (state != null) {
      x -= state.origin.x * scale;
      y -= state.origin.y * scale;

      if (this.graph.isConstrainedMoving) {
        var width = geo.width;
        var height = geo.height;
        var tmp = state.x + state.width;

        if (x + width > tmp) {
          x -= x + width - tmp;
        }

        tmp = state.y + state.height;

        if (y + height > tmp) {
          y -= y + height - tmp;
        }
      }
    } else if (pgeo != null) {
      x -= pgeo.x * scale;
      y -= pgeo.y * scale;
    }
  }

  geo = geo.clone();
  geo.x = this.graph.snap(
    x / scale - this.graph.getView().translate.x - this.graph.gridSize / 2
  );
  geo.y = this.graph.snap(
    y / scale - this.graph.getView().translate.y - this.graph.gridSize / 2
  );
  vertex.setGeometry(geo);

  if (parent == null) {
    parent = this.graph.getDefaultParent();
  }

  this.cycleAttribute(vertex);
  this.fireEvent(
    new mxEventObject(
      mxEvent.BEFORE_ADD_VERTEX,
      'vertex',
      vertex,
      'parent',
      parent
    )
  );

  model.beginUpdate();
  try {
    vertex = this.graph.addCell(vertex, parent);

    if (vertex != null) {
      this.graph.constrainChild(vertex);

      this.fireEvent(new mxEventObject(mxEvent.ADD_VERTEX, 'vertex', vertex));
    }
  } finally {
    model.endUpdate();
  }

  if (vertex != null) {
    this.graph.setSelectionCell(vertex);
    this.graph.scrollCellToVisible(vertex);
    this.fireEvent(
      new mxEventObject(mxEvent.AFTER_ADD_VERTEX, 'vertex', vertex)
    );
  }

  return vertex;
};

/**
 * Function: destroy
 *
 * Removes the editor and all its associated resources. This does not
 * normally need to be called, it is called automatically when the window
 * unloads.
 */
editor.prototype.destroy = function () {
  if (!this.destroyed) {
    this.destroyed = true;

    if (this.tasks != null) {
      this.tasks.destroy();
    }

    if (this.outline != null) {
      this.outline.destroy();
    }

    if (this.properties != null) {
      this.properties.destroy();
    }

    if (this.keyHandler != null) {
      this.keyHandler.destroy();
    }

    if (this.rubberband != null) {
      this.rubberband.destroy();
    }

    if (this.toolbar != null) {
      this.toolbar.destroy();
    }

    if (this.graph != null) {
      this.graph.destroy();
    }

    this.status = null;
    this.templates = null;
  }
};

export default editor;
