import {
  getAssets,
  uploadAsset,
  downloadAsset,
  deleteAsset,
  updateAsset,
  shareAsset,
  assetContent,
} from "../../../api/library/assets/assets";
import ActivityIndicator from "../../../components/activity/ActivityIndicator";
import React from "react";
import { projectsList } from "../../../api/projects/projects";

export function AssetActions({ show, close, notify }) {
  const getList = async (limit, offset, asset = {}) => {
    show(() => <ActivityIndicator text={"Loading Assets"} />);
    const [error, data] = await getAssets(limit, offset, asset);
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
    return [null, data];
  };

  const upload = async (file) => {
    show(() => <ActivityIndicator text={"Uploading Asset "} />);
    const blob = await readFileAsync(file);
    const [error, data] = await uploadAsset(file.name, blob);
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
    notify(data.status);
    return [null, data];
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  };
  //window.open(url, '_blank', 'noopener,noreferrer');

  const download = async (file) => {
    show(() => <ActivityIndicator text={"Downloading Asset "} />);
    const [error, data] = await downloadAsset(file);
    window.open(data.url, "_blank");
    close();
    notify(data.status);
    return [null, data];
  };

  const deleteFile = async (file) => {
    show(() => <ActivityIndicator text={"Deleting Asset "} />);
    const [error, data] = await deleteAsset(file);
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
    notify(data.status);
    return [null, data];
  };

  const update = async (payLoad, fileName) => {
    show(() => <ActivityIndicator text={"Uploading Asset "} />);
    const [error, data] = await updateAsset(fileName, payLoad);
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
    notify(data.status);
    return [null, data];
  };

  const share = async (fileName, projects) => {
    show(() => <ActivityIndicator text={"Sharing Asset "} />);
    const [error, data] = await shareAsset(fileName, projects);
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
    notify(data.status)
    return [null, data];
  };

  const getProjects = async (limit, offset, asset = {}) => {
    show(() => <ActivityIndicator text={"Loading Projects"} />);
    const [error, data] = await projectsList();
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
    return [null, data];
  };

  const getAssetFileData = async (fileName) => {
    show(() => <ActivityIndicator text={"Loading file"} />);
    const [error, data] = await downloadAsset(fileName);
    if (!error) {
      const res = await assetContent(data.url);
      const assetData = await res.text();
      close();
      return [null, assetData];
    }
    close();
    if (error) return [error, null];
    if (data?.code >= 400) return [data.message, null];
  };

  return {
    getList,
    upload,
    download,
    deleteFile,
    update,
    share,
    getProjects,
    getAssetFileData,
  };
}
