import React, { useEffect, useState } from 'react';
import { AddOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import { containerAppConstants } from '../../../../../../constants';
import { configMessage } from '../../../../../../common/messages/config';
import DeviceFeature from './deviceFeature';
import useStyles from './style';
import {
  InputLabel,
  Input,
  Grid,
  FormGroup,
  FormControl,
  IconButton,
} from '@material-ui/core';
import ExternalAccessory from './externalAccessory';

const advancedOptionFields = [
  {
    name: 'biometric',
    id: 'biometric',
    label: configMessage.T4643,
    input: false,
    inputPlaceHolder: '',
    enable: true,
    kind: 'TOUCH',
  },
  {
    name: 'camera',
    id: 'camera',
    label: configMessage.T4644,
    input: true,
    inputPlaceHolder: configMessage.T4639,
    enable: true,
    kind: 'CAMERA',
  },
  {
    name: 'gps',
    id: 'gps',
    label: configMessage.T4645,
    input: true,
    inputPlaceHolder: configMessage.T4640,
    enable: true,
    kind: 'PLOCATION',
  },
  {
    name: 'bluetooth',
    id: 'bluetooth',
    label: configMessage.T4646,
    input: true,
    inputPlaceHolder: configMessage.T4641,
    enable: true,
    kind: 'BLUETOOTH',
  },
  {
    name: 'contacts',
    id: 'contacts',
    label: configMessage.T4647,
    input: true,
    inputPlaceHolder: configMessage.T4642,
    enable: true,
    kind: 'CONTACTS',
  }
];

const externalLinkValue = [
  {
    kind: 'EXTLINK_URL',
    fileName: '',
    certificate: '',
    password: '',
  },
];

const externalAccessories = [
  {
    name: 'ZebraScanner',
    id: 'ZebraScanner',
    label: 'Zebra Bluetooth Scanner (ios)',
    enable: true,
    kind: 'EXTERNAL_ACCESSORIES'
  }
]

export default function CAGAdvancedConfiguration(props) {
  // const advancedOptionFields = containerAppConstants.advancedOptionFields;
  const {
    containerAppDetails,
    externalLinks,
    setExternalLinks,
    errAdvOptions,
    setErrAdvOptions,
    appPermissions = [],
    setAppPermissions,
    flow,
    setModified,
  } = props;

  const appName = containerAppDetails.containerApp.name;
  const defaultPermissions = [
    { kind: 'TOUCH', fileName: '', certificate: '', password: '' },
    {
      kind: 'CAMERA',
      fileName: '',
      certificate: `This will allow ${appName} to access your camera/camera roll to take/upload photos`,
      password: '',
    },
    {
      kind: 'PLOCATION',
      fileName: '',
      certificate: `This will allow ${appName} to show the best result based on your location`,
      password: '',
    },
    {
      kind: 'BLUETOOTH',
      fileName: '',
      certificate: `This will allow ${appName} to find and connect to Bluetooth accessories`,
      password: '',
    },
    {
      kind: 'CONTACTS',
      fileName: '',
      certificate: `This will allow ${appName} to show your friends information to your contact list`,
      password: '',
    },
  ];

  const classes = useStyles();
  const deviceFeatureDefault = { enable: true, description: '' };
  const externalAccessoriesDefault = { enable: false, description: ''};
  const [biometric, setBiometric] = useState(deviceFeatureDefault);
  const [contacts, setContacts] = useState(deviceFeatureDefault);
  const [camera, setCamera] = useState(deviceFeatureDefault);
  const [gps, setGps] = useState(deviceFeatureDefault);
  const [bluetooth, setBluetooth] = useState(deviceFeatureDefault);
  const [ZebraScanner, setZebraScanner] = useState(externalAccessoriesDefault)

  let eLEmpty =
    externalLinks.length > 0
      ? [...new Set(externalLinks.map((e) => e.certificate === ''))]
      : [false];

  useEffect(() => {
    let kindCheck = ['TOUCH', 'CAMERA', 'PLOCATION', 'BLUETOOTH', 'CONTACTS', 'EXTERNAL_ACCESSORIES'];
    let d = containerAppDetails.containerAppCertificates;
    setBiometric(deviceFeatureDefault);
    setCamera(deviceFeatureDefault);
    setGps(deviceFeatureDefault);
    setBluetooth(deviceFeatureDefault);
    setContacts(deviceFeatureDefault);
    setExternalLinks(externalLinkValue);
    setZebraScanner(externalAccessoriesDefault);

    let filterData = [];
    if (d && d.length !== 0) {
      filterData = d.filter((e) => kindCheck.includes(e.kind));
      if (flow === 'edit') {
        if (filterData.length > 0) {
          setAppPermissions(filterData);
        }

        setBiometric(filterExistData(filterData, 'TOUCH'));
        setCamera(filterExistData(filterData, 'CAMERA'));
        setGps(filterExistData(filterData, 'PLOCATION'));
        setBluetooth(filterExistData(filterData, 'BLUETOOTH'));
        setContacts(filterExistData(filterData, 'CONTACTS'));
        setZebraScanner(filterExistData(filterData, 'ZebraScanner', true)) // third argument is to check whether its extAccessory or not
      } else {
        var contactDesc = `This will allow ${appName} to show your friends information to your contact list`;
        let photoDesc = `This will allow ${appName} to access your camera/camera roll to take/upload photos`;
        let BluetoothDesc = `This will allow ${appName} to find and connect to Bluetooth accessories`;
        let locationDesc = `This will allow ${appName} to show the best result based on your location`;
        let ZebraScannerDesc = `ZebraScanner`
        setAppPermissions(defaultPermissions);
        setBiometric({ enable: true, description: '' }, 'TOUCH');
        setCamera({ enable: true, description: photoDesc }, 'CAMERA');
        setGps({ enable: true, description: locationDesc }, 'PLOCATION');
        setBluetooth({ enable: true, description: BluetoothDesc }, 'BLUETOOTH');
        setContacts({ enable: true, description: contactDesc }, 'CONTACTS');
        setZebraScanner({ enable: false, description: ZebraScannerDesc }, 'EXTERNAL_ACCESSORIES')
      }

      let exLurl = d.filter((e) => ['EXTLINK_URL'].includes(e.kind));
      if (exLurl.length > 0) setExternalLinks(exLurl);
    }
  }, [containerAppDetails]);

  // useEffect(() => {
  //   let consts = ['TOUCH', 'CAMERA', 'PLOCATION', 'BLUETOOTH', 'CONTACTS'];
  //   loadChangeData(consts);
  // },[])

  // const loadChangeData = (data) => {
  //     const val = deviceFeatureDefault;
  //     const arr = [];
  //     data.map((item) => {
  //       arr.push(item, val);
  //     });
  //     setAppPermissions(arr);
  // }

  const filterExistData = (data, kind, isExternalAccessories=false) => { // for extAcc kind arg is the name of the accessory == certificate 

    const item = isExternalAccessories?data.filter((e) => e.certificate === kind):data.filter((e) => e.kind === kind);
    if (item && item.length > 0)
      return { enable: true, description: item[0].certificate };
    else return { enable: false, description: '' };
  };

  const removeKeyFromError = (kind, index) => {
    let err = errAdvOptions.from || [];
    let externLerr = errAdvOptions.externLErr || [];
    if (kind === 'EXTLINK_URL') {
      if (err.length > 0 && externLerr.length > 0)
        externLerr = externLerr.filter((e) => e !== index);
      if (externLerr.length === 0) err = err.filter((e) => e !== 'EXTLINK_URL');
    } else {
      err = err.filter((e) => e !== kind);
    }
    setErrAdvOptions({
      flag: err.length > 0,
      from: err,
      message: '',
      externLErr: externLerr,
    });
  };

  const handleExternalLinkChange = (e, url, index) => {
    let eL = externalLinks.length > 0 ? [...externalLinks] : [];
    let val = e.target.value?.replaceAll(' ', '') || '';
    eL = eL.map((f, i) => {
      if (i === index) return { ...f, certificate: val };
      else return f;
    });
    setExternalLinks(eL);
    removeKeyFromError('EXTLINK_URL', index);
    setModified(true);
  };

  const pushData = (data, kind, value) => {
    data.push({ kind: kind, fileName: '', certificate: value, password: '' });
    return data;
  };

  const constructFinalObject = (kind, value, cb=()=>null, apData=null) => {
    let data;
    if(apData){
      data = apData.length>0?[...apData]:[]
    } else {
      data = appPermissions.length > 0 ? [...appPermissions] : [];
    }
    if (value.enable) {
      /** check if the checkbox is enabled **/
      if (data.length > 0) {
        /** check if already any data exist**/
        let objIndex = data.filter(
          (e) => e.kind === kind
        ); /** check if the checked data exist**/
        if (objIndex.length > 0) {
          /** if it exist :: **/
          data = data.map((e) => {
            if (e.kind !== kind) return e;
            else {
              return {
                ...e,
                certificate: value.description,
              }; /** updates existing data **/
            }
          });
        } else {
          data = pushData(
            data,
            kind,
            value.description
          ); /**  else pushes new object to update **/
        }
      } else {
        data = pushData(
          data,
          kind,
          value.description
        ); /** if no data exist , pushes the data**/
      }
    } else {
      data = data.filter(
        (e) => e.kind !== kind
      ); /** if disabled, removes data from the object**/
    }
    setAppPermissions(data);
    cb(data)
  };

  const setChangedState = (name, value) => {
    let consts = {
      biometric: 'TOUCH',
      camera: 'CAMERA',
      gps: 'PLOCATION',
      bluetooth: 'BLUETOOTH',
      contacts: 'CONTACTS',
      ZebraScanner: 'EXTERNAL_ACCESSORIES',
    };
    if (name === 'biometric') {
      setBiometric(value);
      removeKeyFromError(consts.biometric);
      constructFinalObject(consts.biometric, value);
    } else if (name === 'contacts') {
      setContacts(value);
      removeKeyFromError(consts.contacts);
      constructFinalObject(consts.contacts, value);
    } else if (name === 'gps') {
      setGps(value);
      removeKeyFromError(consts.gps);
      constructFinalObject(consts.gps, value);
    } else if (name === 'bluetooth') {
      if(!ZebraScanner.enable) {
        setBluetooth(value);
        removeKeyFromError(consts.bluetooth);
        constructFinalObject(consts.bluetooth, value);
      }else {
        if(value.enable) {
          setBluetooth(value);
          removeKeyFromError(consts.bluetooth);
          constructFinalObject(consts.bluetooth, value);
        }
      }
    } else if (name === 'camera') {
      setCamera(value);
      removeKeyFromError(consts.camera);
      constructFinalObject(consts.camera, value);
    } else if (name === 'ZebraScanner') {
      setZebraScanner(value);
      // removeKeyFromError(consts.ZebraScanner);
      constructFinalObject(consts.ZebraScanner, value, (data)=>{
        if(value.enable && !bluetooth.enable) {
          // handleToggleChange({target:{checked:true}}, "bluetooth", bluetooth)
          const value = {...bluetooth, enable: true}
          if(value.description.trim().length===0) value.description=`This will allow ${appName} to find and connect to Bluetooth accessories`
          setBluetooth(value);
          removeKeyFromError(consts.bluetooth);
          constructFinalObject(consts.bluetooth, value, ()=>null, data);
        }
      })
    }
  };

  const handleDescriptionChange = (e, name, deviceFeature) => {
    let dF = { ...deviceFeature, description: e.target.value };
    setChangedState(name, dF);
    setModified(true);
  };

  const handleToggleChange = (e, name, deviceFeature) => {
    let dF = { ...deviceFeature, enable: e.target.checked };
    if (name === 'contacts' && dF.description.trim().length == 0) {
      dF.description = `This will allow ${appName} to show your friends information to your contact list`;
    } else if (name === 'camera' && dF.description.trim().length == 0) {
      dF.description = `This will allow ${appName} to access your camera/camera roll to take/upload photos`;
    } else if (name === 'bluetooth' && dF.description.trim().length == 0) {
      dF.description = `This will allow ${appName} to find and connect to Bluetooth accessories`;
    } else if (name === 'gps' && dF.description.trim().length == 0) {
      dF.description = `This will allow ${appName} to show the best result based on your location`;
    } else if (name === 'ZebraScanner' && dF.description.trim().length == 0) { // here for external Accessory description is the name of the accessory itself
      dF.description = `ZebraScanner`
    }
    setChangedState(name, dF);
    setModified(true);
  };

  const addExternalLink = () => {
    let exL = externalLinks.length > 0 ? [...externalLinks] : [];
    let keyy = {
      kind: 'EXTLINK_URL',
      fileName: '',
      certificate: '',
      password: '',
    };
    exL.push(keyy);
    setExternalLinks(exL);
  };

  const deleteExternalLink = (url, index) => {
    let exL =
      externalLinks.length > 0
        ? externalLinks.filter((e, i) => i !== index)
        : [];
    setExternalLinks(exL);
  };

  return (
    <Grid xs={12} container>
      <Grid xs={6} className={classes.appBox} item>
        <FormControl fullWidth>
          <InputLabel classes={{ root: classes.Headerlabels }}>
            {configMessage.T4628}
          </InputLabel>
          <DeviceFeature
            handleToggleChange={handleToggleChange}
            item={advancedOptionFields[0]}
            index={0}
            deviceFeature={biometric}
            handleDescriptionChange={handleDescriptionChange}
            error={errAdvOptions}
          />
          <DeviceFeature
            handleToggleChange={handleToggleChange}
            item={advancedOptionFields[1]}
            index={1}
            deviceFeature={camera}
            handleDescriptionChange={handleDescriptionChange}
            error={errAdvOptions}
          />
          <DeviceFeature
            handleToggleChange={handleToggleChange}
            item={advancedOptionFields[2]}
            index={2}
            deviceFeature={gps}
            handleDescriptionChange={handleDescriptionChange}
            error={errAdvOptions}
          />
          <DeviceFeature
            handleToggleChange={handleToggleChange}
            item={advancedOptionFields[3]}
            index={3}
            deviceFeature={bluetooth}
            handleDescriptionChange={handleDescriptionChange}
            error={errAdvOptions}
          />
          <DeviceFeature
            handleToggleChange={handleToggleChange}
            item={advancedOptionFields[4]}
            index={4}
            deviceFeature={contacts}
            handleDescriptionChange={handleDescriptionChange}
            error={errAdvOptions}
          />
        </FormControl>
      </Grid>
      <Grid xs={6} className={classes.appBox} item>
        <FormControl fullWidth>
          <InputLabel classes={{ root: classes.ExternalLinkslabels }}>
            {configMessage.T4629}
          </InputLabel>
          <FormGroup classes={{ root: classes.formGroupRoot }}>
            {externalLinks.length > 0 &&
              externalLinks.map((url, index) => {
                return (
                  <>
                    <Grid
                      container
                      direction='row'
                      classes={{ root: index === 0 ? classes.textFields : {} }}
                    >
                      <Grid xs={10} item>
                        <Input
                          name={'externallink-' + index}
                          id={'externallink-' + index}
                          onChange={(e) =>
                            handleExternalLinkChange(e, url, index)
                          }
                          placeholder='url'
                          value={url.certificate || ''}
                          classes={{
                            root:
                              errAdvOptions.from.includes('EXTLINK_URL') &&
                              errAdvOptions.externLErr.includes(index)
                                ? classes.textFieldInputsBorderRed
                                : classes.textFieldInputs,
                          }}
                          disableUnderline
                          inputProps={{ maxLength: 200 }}
                        />
                      </Grid>
                      <Grid xs={2} item>
                        {index !== 0 && (
                          <IconButton
                            edge='end'
                            onClick={() => deleteExternalLink(url, index)}
                          >
                            <DeleteOutlineOutlined
                              style={{ fontSize: 24, marginTop: '-10px' }}
                              color='primary'
                            />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    <small
                      className='text-muted red'
                      style={{ marginBottom: '8px' }}
                    >
                      {errAdvOptions.from.includes('EXTLINK_URL') &&
                      errAdvOptions.externLErr.includes(index)
                        ? configMessage.W4511
                        : ''}
                    </small>
                  </>
                );
              })}
          </FormGroup>
          {eLEmpty.length === 1 && !eLEmpty[0] && (
            <IconButton
              edge='start'
              onClick={addExternalLink}
              classes={{
                root: classes.addIcon,
                label: classes.addIconChildren,
              }}
            >
              <AddOutlined
                style={{ fontSize: 24, marginTop: '-10px' }}
                color='primary'
              />
            </IconButton>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel classes={{ root: classes.ExternalLinkslabels }}>
            {"External Accessories"}
          </InputLabel>
          <ExternalAccessory 
            handleToggleChange={handleToggleChange}
            item={externalAccessories[0]}
            index={0}
            externalAccessory={ZebraScanner}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
