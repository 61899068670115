import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CellIcon from '../../../../../assets/images/input-cell-icon.js';
import Plus from '../../../../../assets/images/dmn-operators/plus.svg'
import Minus from '../../../../../assets/images/dmn-operators/minus.svg'
import Multiply from '../../../../../assets/images/dmn-operators/multiply.svg'
import Division from '../../../../../assets/images/dmn-operators/division.svg'
import Percent from '../../../../../assets/images/dmn-operators/percent.svg'
import Not from '../../../../../assets/images/dmn-operators/not.svg'
import Abs from '../../../../../assets/images/dmn-operators/abs.svg'
import Mod from '../../../../../assets/images/dmn-operators/mod.svg'
import Equal from '../../../../../assets/images/dmn-operators/equal.svg'
import NotEqual from '../../../../../assets/images/dmn-operators/not-equal.svg'
import LessEqual from '../../../../../assets/images/dmn-operators/less-equal.svg'
import Less from '../../../../../assets/images/dmn-operators/less.svg'
import Greater from '../../../../../assets/images/dmn-operators/greater.svg'
import GreaterEqual from '../../../../../assets/images/dmn-operators/greater-equal.svg'
import And from '../../../../../assets/images/dmn-operators/and.svg'
import Or from '../../../../../assets/images/dmn-operators/or.svg'


import { FilledInput, Paper, Popper, Fade, ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  operators: {
    height: '32px',
    width: '192px',
    display: "grid",
    gridTemplateColumns: "32px 32px 32px 32px 32px 32px 32px 32px",
    alignItems: 'center'
  },
  cursorPointer: {
    '& .MuiFilledInput-input': {
      cursor: 'pointer'
    }
  },
  item: {
    height: "100%",
    cursor: 'pointer',
    borderRight: "1px solid #F0F2F7",
    borderBottom: "1px solid #F0F2F7",
  }
}));


const operators = [
  // {image:Plus,val:'+'} ,
  // {image:Minus,val:'-'}, 
  // {image:Multiply,val:'*'}, 
  // {image:Division,val:'/' }, 
  // {image:Percent,val:'%'}, 
  // {image:Not,val:'!'}, 
  // {image:Abs,val:'abs'},
  // {image:Mod,val:'mod'},
  { image: Equal, val: '=' },
  { image: NotEqual, val: '!=' },
  { image: LessEqual, val: '<=' },
  { image: Less, val: '<' },
  { image: Greater, val: '>' },
  { image: GreaterEqual, val: '>=' },
  // {image:And,val:'&&'},
  // {image:Or,val:'||'} 
]

export default function CellInput(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [val, setVal] = React.useState(props.value)

  const handleClick = (event) => {
    if(!props?.disabled)
      setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'spring-popper' : undefined;
  const classes = useStyles();

  useEffect(() => {
    //  console.log('val change',props.value)
    //  let newVal = props.value ? props.value : ''
    setVal(props.value)
  }, [props.value])

  const handleChange = (e) => {
    props.handleChange(e, props.r, props.c, () => {
      setVal(e.target.value)
    })
  }

  const handleAddOperator = (e, v) => {
    let str = (val ? val : '') + v
    if (props.callType && props.callType === "condition") {
      str = v
      props.handleChange(str)
    }
    setVal(str)
    setAnchorEl(false)
  }

  const handleClose = (e) => { setAnchorEl(false) }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <FilledInput
          id={props.id}
          name={props.name}
          onChange={(e) => handleChange(e)}
          value={val}
          className={classes.cursorPointer}
          aria-describedby={id}
          onClick={handleClick}
          endAdornment={(props.callType && props.callType === "condition") ? null : <CellIcon />}
          disabled={(props.callType && props.callType === "condition") ? true : false}
          disableUnderline
          autoFocus
          onBlur={(e) => props.handleBlur(e)}
        />
        <Popper id={id} open={open} anchorEl={anchorEl} transition style={{ zIndex: props.zIndex || '1000' }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.operators}>
                {
                  operators.map(operator => {
                    return <div className={classes.item}
                      onClick={(e) => handleAddOperator(e, operator.val)}
                      style={{ backgroundImage: 'url(' + operator.image + ')' }}
                    />
                  })
                }
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
