import { getUrl, get, post, encodeFilter } from "../../url"

const msp = { "is MSP": true, "Not-MSP": false };

export async function getTenantList(offset, limit, tenantFIlter) {
    Object.keys(tenantFIlter).forEach(key => {
        if (tenantFIlter[key] === '') {
            delete tenantFIlter[key];
        }
    });
    let uri = getUrl() + `/configurator/v1.0/company/list?$select=tenantId,packageMasterName,`
        + `primaryContact,name,dateFormat,msp,currency,languageCode,phoneNumber,timeZone,isActive`
    if (limit)
        uri += "&limit=" + limit + "&offset=" + offset;
    if (tenantFIlter) {
        // uri += getFilter(tenantFIlter);
        uri = `${uri}&$filter=`
        let a = 0;
        Object.keys(tenantFIlter).map((item, index) => {
            if (tenantFIlter[item] != '') {
                let value = tenantFIlter[item];
                if (item == 'tenantId') { item = 'c.tenant_id' }
                if (item == 'msp') { item = 'c.msp' }
                if (item == 'packageMasterName') { item = 'p.dimension' }
                if (item == 'phoneNumber') { item = 'c.phone_number' }
                if (item == 'primaryContact') { item = 'c.primary_contact' }
                console.log(value)

                if (a !== 0) {
                    if (item == 'c.msp') {
                        uri = `${uri} AND ${encodeURI(`${item} EQ '${value}'`)}`
                    }
                    else {
                        uri = `${uri} AND ${encodeURI(`${item} LIKE '%${value}%'`)}`
                    }
                }
                else {
                    if (item == 'c.msp') {
                        uri = `${uri} ${item} = '${value}'`
                    }
                    else {
                        uri = `${uri} ${encodeURI(`${item} LIKE '%${value}%'`)}`;
                    }
                }
                a++;
            }
        })
    }
    const [error, data] = await get(uri);
    return [error, data];
}

function getFilter(tenant) {
    console.log(tenant)
    let filter = "&$filter=";
    let obj = [];
    Object.keys(tenant).map(function (key, index) {
        if (tenant[key]) {
            if (key === 'msp')
                obj.push(key + " EQ " + msp[tenant[key]] + "");
            else
                obj.push(key + ' LIKE ' + encodeFilter(tenant[key]));
        }
    });
    obj.length > 0 ? filter += obj.join(" AND ") : filter = "";
    console.log(filter)
    return filter;
}

export async function getPackageList(offset, limit) {
    let uri = getUrl() + `/configurator/v1.0/package/list?$select=dimension,usercount,appcount,apiExecuted,id`;
    const [error, data] = await get(uri);
    return [error, data];
}


export async function createCompany(json) {
    /*
    {
        "company": {
            "name": "sbdfbdfbdfb",
            "msp": "false",
            "phoneNumber": "1234567890",
            "currency": "INR",
            "primaryContact": "dev",
            "dateFormat": "1",
            "languageCode": "en",
            "timeZone": "1"
        },
        "user": {
            "dateFormat": "1",
            "email": "navaneethan.b@pillir.io",
            "firstName": "dev",
            "lastName": "dev",
            "languageCode": "en",
            "loginName": "dev",
            "salutation": "Mr",
            "timeFormat": "hh:mm:ss",
            "timeZone": "1",
            "title": "dev"
        },
        "license": {
            "packageMasterName": "sample1",
            "serviceStartDate": "2022-07-07",
            "term": "1",
            "autoRenewal": "1"
        }
    }   
    */
    const uri = getUrl() + "/configurator/v1.0/company";
    const [error, data] = await post(uri, json);
    return [error, data];
}

export async function generateLicenseKey(value) {
    const uri = getUrl() + `/configurator/v1.0/license/create`;
    const payload = {
        tenantId: value.tenantId,
        serviceStartDate: value.generationDate,
        serviceRenewalDate: value.expirationDate,
        noOfApps: value.appsCount,
        noOfUsers: value.usersCount,
    }
    const [error, data] = await post(uri, payload);
    return [error, data]
}

export async function getLicenseDetails(tenantId) {
    const uri = getUrl() + `/configurator/v1.0/license/${tenantId}/details`
    const [error, data] = await get(uri);
    return [error, data];
}