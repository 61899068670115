import { getUrl, get, put, post ,download, encodeFilter, downloadFileFromUrl, deleteAPI, postFile} from "../../url";

export async function getAllTablesApi(projectName) {
    let filt = "";
    let path = '';
    let selectQuery = "name,projects";
    let sortQuery = "desc%20id";
    if(projectName) {
        path = `${getUrl()}/configurator/v2.0/project/${projectName}/listTables?$filter=type EQ 'DB' or type EQ 'DB_VIEW'&$sort=${sortQuery}`;
    }
    else {
        path = `${getUrl()}/configurator/v2.0/project/services_workspace/listTables?$filter=type EQ 'DB' or type EQ 'DB_VIEW'&$sort=${sortQuery}`;
    }
   // path+=getFilter(filter)
    const [error, data] = await get(path);

    return [error,data];
}

export async function getSharedTablesApi(projectName) {
    let filt = "";
    let path = '';
    let selectQuery = "name,projects";
    let sortQuery = "desc%20id";
    if(projectName)
    {
        path = `${getUrl()}/library/v1.0/tables/${projectName}/listLibrary?$select=name`;
    }
    else
    {
        path = `${getUrl()}/library/v2.0/tables/list?$select=${selectQuery}&$sort=${sortQuery}`;
    }
   // path+=getFilter(filter)
    const [error, data] = await get(path);

    return [error,data];
}

export function getFilter(filter){
    let filt = "&$filter=";
    let obj=[];
    Object.keys(filter).map(function (key, index) {
        if(filter[key]){
            if(key==='projects')
                obj.push('IN (projects,\''+filter[key]+'\')');
            else
                obj.push(key+' LIKE '+encodeFilter(filter[key]));
        }
    });
    obj.length>0?filt+=obj.join(" AND "):filt="";
    return filt;
}

export function getSharedTableFilter(filter){
    let filt = "?$filter=";
    let obj=[];
    if(filter)
        Object.keys(filter).map(function (key, index) {
            if(filter[key]!=undefined && filter[key]!=''){
                if(key==='project')
                    obj.push('projectName LIKE '+encodeFilter(filter[key]));
                else if(key==='use'){
                    if(filter[key]==='Yes')
                        obj.push('isShared = 1');
                    else
                        obj.push('isShared is null');
                } 
            }
        });
    obj.length>0?filt+=obj.join(" AND "):filt="";
    return filt;
}

const getDataType = (type)=>{
    switch(type){
        case 'tinytext':return 'text';
        case 'tinyblob':return 'blob';
        default : return type;
    }
}

export async function getTableColumnsApi(table) {
    let path = `${getUrl()}/ercdatabase/v1.0/table/${table}/columns`;
    const [error, data] = await get(path);
    data.object = JSON.parse(data.object);
    let cols = data.object.columns;
    let indicies = data.object.indices;
    let indicesList = [];
    let columns = [];
    let colNames = [];
    const options = ['float','decimal','double']
    cols.forEach((key,index) => {
        cols[index].name = `${cols[index].columnName}`;
        cols[index].isLeaf = true;
        cols[index].dataType = getDataType(`${cols[index].dataType}`)
        if(options.includes(cols[index].dataType)){
            cols[index].dataLength = cols[index].dataLength+','+cols[index].dataScale
        }
        columns.push(cols[index]);   
        colNames.push(cols[index].name);
    });
    indicies.forEach((key,index) => {
        if(indicies[index].name != 'PRIMARY')
        {
            let refCol, refTab, refColumnsValues = [];
            refCol = (`${indicies[index].cols[0].refColumn}`) === 'undefined' ? '' : `${indicies[index].cols[0].refColumn}`;
            refTab = (`${indicies[index].cols[0].refTable}`) === 'undefined' ? '' : `${indicies[index].cols[0].refTable}`;
            if(refTab && refTab != '' && refTab != undefined){
                // const [err, columnsData] = await getTableColumnNamesApi(table);
                // console.log('inside if nxt');
                refColumnsValues = new Array([refCol]);
            }
            // console.log('outside if');
            let temp = { name : `${indicies[index].name}`, column : `${indicies[index].cols[0].name}`, refTables : `${refTab}`, refColumns :  `${refCol}`,actualColValues:colNames, refColumnsValues:refColumnsValues,type:indicies[index].constraintType};
            indicesList.push(temp);
        }
    });
    if(indicesList.length === 0)
    {
        // console.log('inside 0');
        indicesList.push({ id: 1, name: "", column: '', refTables: '', refColumns: ''});
    }
    data.object.columns = columns;
    data.object.indices = indicesList;
    data.object.totalColumn = cols;
    data.object.colNames = colNames;
    return [error,data.object];
}

export async function getTableColumnNamesApi(table) {
    let path = `${getUrl()}/ercdatabase/v1.0/table/${table}/columns`;
    const [error, data] = await get(path);
    data.object = JSON.parse(data.object);
    let cols = data.object.columns;
    let colNames = [];
    cols.forEach((key,index) => {
        cols[index].name = `${cols[index].columnName}`;
        cols[index].isLeaf = true;  
        colNames.push(cols[index].name);
    });
    return [error,colNames];
}

export async function getTableIndexes(table,project) {
    let path = `${getUrl()}/ercdatabase/v1.0/table/${table}/indexcolumns?project_name=${project}`;
    const [error, data] = await get(path);
    let indexes = data.list;
    indexes.forEach((key,index) => {
        indexes[index].isLeaf = true;
    });
    return [error,indexes];
}

export async function executeQueryApi(query,project,isLib) {
    let path = `${getUrl()}/ercdatabase/v1.0/db/query`;
    path += project ? `?project_name=${project}&` : '?';
    path += isLib ? `is_shared=${isLib}` : '';
    const [error, data] = await post(path,query);
    return [error,data];
}

export async function getTableDetailApi(name,filter){
    const uri = `${getUrl()}/library/v2.0/tables/${name}${getSharedTableFilter(filter)}`//$filter=isShared eq null`;//projectName like '%ak%'`;
    const [error,data] = await get(uri);
    return [error,data];
}

export async function updateTablePermissionApi(name, projects){
    let path = `${getUrl()}/library/v2.0/tables/${name}`
    const [error,data] = await put(path, projects);
    return [error,data]
}

export async function exportTableData(tableName, projectName){
    // projectName = 'SuganyasWorkspace';
    let path = `${getUrl()}/ercdatabase/v1.0/table/${tableName}/export?project_name=${projectName}`;
    const [error,data] = await get(path);
    if(data){
       getAPIRequestData(data.requestId)
    }
    return [,data.status]
}

const getAPIRequestData = async (id)=>{
    let path = `${getUrl()}/ercdatabase/v1.0/apirequest/${id}`;
    const [error,res] = await get(path);
    if(!error && res.status==='Pending'){
        setTimeout(() => getAPIRequestData(id), 3000)
    }else{
        downloadFileFromUrl(res.url);
    }
}

export async function shareTableDataApi(tableName, projectName){
    if(!projectName)
        projectName="services_workspace"
    let path = `${getUrl()}/configurator/v2.0/project/${projectName}/component/${tableName}/shareToLib`;
    const [error,data] = await put(path);
    return [error,data];
}

export async function deleteTableDataApi(tableName, projectName){
    let path = `${getUrl()}/ercdatabase/v1.0/table/${tableName}?project_name=${projectName}`;
    const [error,data] = await deleteAPI(path);
    return [error,data];
}

export async function importFileApi(tableName, projectName,file){
    let path = `${getUrl()}/ercdatabase/v1.0/table/${tableName}/importFile?project_name=${projectName}`;
    const [error,data] = await postFile(path,file);
    return [error,data];
}

export async function importTableApi(tableName, projectName, requestId,payload, notify){
    let path = `${getUrl()}/ercdatabase/v1.0/table/${tableName}/import?project_name=${projectName}&request_id=${requestId}`;
    const [error,data] = await post(path,payload);
    if(data)
    {
        let path = `${getUrl()}/ercdatabase/v1.0/apirequest/${data.requestId}`;
        const [error,data1] = await get(path);

        if (data1.status === "Pending") {
            const intervals = [5, 10, 20, 40, 60];
            let currentIndex = 0;
            let pendingFlag = true
            
            const pollStatus = async (callback) => {
              const [error, newData] = await get(path);
              if (newData.status !== "Pending") {
                pendingFlag = false
                clearInterval(intervalId);
                if (!error && newData?.url) {
                  let text = await download(newData.url);
                  if(newData.status === "Success")
                    notify({ message: text.split("\n").slice(-2).join("\n"), type: "S" });
                  else if(newData.status === "Error")
                    notify({ message: text.split("\n").slice(-2).join("\n"), type: "E" });
                } else {
                  notify({ message: error, type: "E" });
                }
                callback()
              }
              else callback()
            };
      
            const startPolling = () => {
                pollStatus(() => {
                  currentIndex++;
                  if (pendingFlag && (currentIndex < intervals.length)) {
                    setTimeout(startPolling, intervals[currentIndex] * 1000);
                  } else if (pendingFlag) {
                    intervalId = setInterval(() => {
                      pollStatus(() => {});
                    }, intervals[intervals.length - 1] * 1000);
                  }
                });
              };
      
            let intervalId = null;
            setTimeout(startPolling, intervals[currentIndex] * 1000);
          }
        return [error,data1];
    }
    else 
        return [error, data];
}