import React from "react";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../components/activity/AppContext";
import Table from "../../../../components/Table/table";
import styles from "./revertService.module.css";
import { ServiceActions } from "../services.actions";

export default function RevertServiceForm(props) {
    const { handleClose, setRenderComponent, serviceData, setSelectedService } = props;
    const [formData, setFormData] = useState(null);
    const [list, setList] = useState([]);
    const [serviceList, setServiceList] = useState([])
    // const [selected, setSelected] = useState(null);
    const [filter, setFilter] = useState({});
    const app = useAppContext();
    let test;

    const headers = [
        { name: "radio", id: "radio", type: "radio", width: "50px" },
        { name: "Version", id: "version", type: "input" },
        {
            name: "Published On",
            id: "createdTimestamp",
            type: "input",
            disabled: true,
        },
        { name: "Published By", id: "userName", type: "input" }

    ]

    const onHeaderChange = (id, value) => {
        setFilter((prev) => ({ ...prev, [id]: value }));
    };

    const itemOnClick = (versionId) => {
        let data = test.filter((item) => item.id == versionId);
        if(data && data[0]){
            data = data[0];
            if (data.version === serviceData.version) return false;
            setFormData(data)
            mapServiceList(test, data.id);
        } data = data[0];
    };

    const getServiceData = async () => {
        const actions = ServiceActions(app());
        const uuid = serviceData.serviceId;
        const [error, data] = await actions.getSerivceData({ uuid, filter });

        if (!error) {
            const res = data.data;
            setList(res);
            test = res;
            mapServiceList(res);
        }
    }

    useEffect(() => {
        getServiceData();
    }, [filter])

    const mapServiceList = (services, clickedId = null) => {
        setList(services);
        let mapedService = [];
        for (let dt of services) {
            if (dt.version !== serviceData.version) {
                // if (clickedId === null)
                //     clickedId = dt.id;
                mapedService.push(
                    { ...dt, radio: <input type="radio" name={"version"} id={dt.id} value={dt.id} checked={dt.id === clickedId ? "checked" : ""} onChange={(e) => { console.log('onchange',itemOnClick(e.target.defaultValue))}} /> }
                )
            } else {
                mapedService.push(
                    { ...dt, disabled: true }
                )
            }
        }

        setServiceList(mapedService)
    }

    const handleRevert = () => {
        if (formData !== null) {
            setSelectedService(formData);
            setRenderComponent("RevertServiceStatus");
        }
    }

    return (
        <div className={styles.popupCont}>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{"Do you want to revert the changes?"}</h2>
                <h5 className={styles.subTitle}>{"Choose the right version to revert. By reverting the existing changes will be gone."}</h5>
            </div>
            <div className={styles.tableCont}>
                <Table
                    data={serviceList || []}
                    headerColumns={headers}
                    // contextOnChange={menuOnClick}
                    onRowClick={itemOnClick}
                    handleHeaderInputChange={onHeaderChange}
                />
            </div>
            <div className={styles.btnCont}>
                <input
                    type="button"
                    className="secondary"
                    value={"Cancel"}
                    onClick={handleClose}
                    data-clickable-testid={"Cancel"}
                />
                <input
                    type="button"
                    className={formData !== null ? "primary" : "tertiary"}
                    value={"Revert"}
                    onClick={handleRevert}
                    data-clickable-testid={"Revert"}
                />
            </div>
        </div>
    )
}
