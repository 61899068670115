import TableHeaderInput from "../../../../../components/tableHeaderInput/tableHeaderInput";
import React from "react";
import "./Parameters.module.table.css";

export default function ParametersTable(props) {
  const {
    data = [],
    width = "100%",
    headerColumns = [],
    name = "",
  } = props;

  return (
    <table
      data-table-testid={name}
      className="userlist-table"
      cellSpacing={0}
      cellPadding={0}
      style={{ width: width }}
    >
      <thead className="userlist-thead">
        <tr>
          {headerColumns &&
            headerColumns.map((dt, index) => {
              if (dt.type === "input")
                return (
                  <TableHeaderInput
                    id={dt.id}
                    width={dt.width}
                    placeholder={dt.name}
                    index={index}
                    disabled={dt.disabled}
                  />
                );
              else
                return (
                  <th data-tablehead-testid={dt.name} index={index}>
                    {" "}
                    {dt.name}
                  </th>
                );
            })}
        </tr>
      </thead>
      <tbody className="tableBody">
        {data.length > 0 ? (
          <>
            {data.map((item, index) => {
              return (
                <tr
                  data-row-testid={index}
                  key={index}
                  className="userlist-tr-td"
                >
                  {headerColumns.map((header, index) => {
                     if(header.dataReturnType && header.dataReturnType==='input'){
                      if(item.system)
                       return (
                        <td className="userlist-td">
                          <input
                            data-input-testid={header.name}
                            id={header.id}
                            placeholder={header.name}
                            index={item.index}
                            className={"input"}
                            name={header.id}
                            system={item.name}
                            onChange={item.onChange}
                            />
                        </td>
                      );
                      else
                      return (
                        <td className="userlist-td">
                          <input
                            data-input-testid={item.name}
                            id={item.id}
                            placeholder={item.name}
                            index={index}
                            className={"input"}
                            name={item.name}
                            onChange={item.onChange}
                            />
                        </td>
                      );
                    } else
                      return (
                        <td
                          className="userlist-td"
                          key={index}
                          data-col-testid={header.id}
                        >
                          {item[header.id]}
                        </td>
                      );
                  })}
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td
              data-col-testid={"No Record Exists"}
              colSpan={headerColumns.length}
              style={{ textAlign: "center", paddingTop: "20px" }}
            >
              No Record Exists
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}