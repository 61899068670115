import React ,{ useEffect, useState } from "react";
import PopUp from "../../../components/layouts/PopUp";
import "./index.css";
import styles from "./assets.module.css";
import { useAppContext } from "../../../components/activity/AppContext";
import { AssetActions } from "./asset.actions";

function EditAssetFile(props) {
  const { editProject, setEditproject } = props;
  const [fileName] = useState(editProject.asset.name);
  const [show, setShow] = useState(editProject.show);
  const [assetContent, setAssetContent] = useState("");
  const app = useAppContext();

  const getFile = async () => {
    const actions = AssetActions(app());
    const [error, data] = await actions.getAssetFileData(fileName);
    if (!error) {
      setAssetContent(data);
    }
  };

  useEffect(() => {
    getFile();
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setAssetContent(value);
  };

  const handleSubmit = async () => {
    const actions = AssetActions(app());
    const [error, data] = await actions.update(assetContent, fileName);
    if (!error) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setShow(false);
    setEditproject({
      show: false,
      asset: "",
    });
  };
  return (
    <div>
      <PopUp popUp={show} setPopUp={handleCancel} title={editProject.name}>
        <div className={styles.editContainer}>
          <textarea
            className={styles.editField}
            value={assetContent}
            onChange={handleChange}
            name={"assetContent"}
            data-input-testid={"assetContent"}
          />
        </div>
        <div className={styles.submitContainer}>
          <input
            className="secondary"
            type="button"
            onClick={handleCancel}
            data-clickable-testid="cancel"
            value="Cancel"
          />
          <input
            className="primary"
            type="submit"
            onClick={handleSubmit}
            data-clickable-testid="save"
            value="Save"
          />
        </div>
      </PopUp>
    </div>
  );
}
export default EditAssetFile;
