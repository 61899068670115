import React, { useEffect, useState } from "react";
import NewUserDialog from "../userManagement/create and update user/newUserDialog";
import RoleList from "./usersAndRoles/roleList";
import UserList from "./usersAndRoles/userList";
import "./userManagement.css";
import UserProperties from "./userProperties/userProperties";
import RolePopUp from "./createAndUpdateRole/rolePopUp.js";
import { useAppContext } from "../../../components/activity/AppContext";
import UserManagementActions from './userManagement.actions';
import SmallPopup from "../../../components/layouts/smallPopup";
import {useLocation} from "react-router-dom";

const UserManagement = ({perm}) => {

    const [userModal, setUserModal] = useState(false);
    const [userDataCount, setUserDataCount] = useState(0);
    const [createRoleColumn, setCreateRoleColumn] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [filterRoleData, setFilterRoleData] = useState("");
    const [roleName, setRoleName] = useState("");
    const [rolePopUp, setRolePopUp] = useState({ popUp: false, update: false, permissions: false });
    const [modifyRoleData, setModifyRoleData] = useState({});
    const [userProperties, setUserProperties] = useState(null);
    const [userData, setUserData] = useState([]);
    const [displayRows, setDisplayRows] = useState({ offset: 0, limit: 10, fullName: "", loginName: "" });
    const [userFilter, setUserFilter] = useState({});
    const [allRoles, setAllRoles] = useState({totalUsers:0, activeUsers:0});
    const [roleDelete, setRoleDelete] = useState({ name: "", delete: false });
    const [filter, setFilter] = useState({ name: "", value: "" });
    const app = useAppContext();
    const location = useLocation();
    location.state = {permission:perm}
    
    const getRoleData = async () => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getRoles();
        setRoleData(data?.data);
    }

    const getUserPropertyList = async (filter) => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getPropertiesList(filter);
        setUserProperties(data?.data);
    }

    const getUserDataCount = async () => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getUsersDataCount();
        setAllRoles(prev => ({...prev, totalUsers:data.noOfRecords}));
    }
    const getActiveUserDataCount = async () => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getUsersDataCount(true);
        setAllRoles(prev => ({...prev, activeUsers:data.noOfRecords}));
    }

    const getUserData = async (displayRows, roles, userFilter) => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getUsers(displayRows, roles, userFilter);
        setUserDataCount(data?.noOfRecords);
        const tempData = data?.data.map(element => {
            if (element.properties) element = { ...element, ...element.properties }
            return element;
        });
        setUserData(tempData);
    }

    const getUsersForRole = async () => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.getUsersForRole(displayRows, filterRoleData, userFilter,roleName);
        setUserDataCount(data?.noOfRecords);
        const tempData = data?.data.map(element => {
            if (element.properties) element = { ...element, ...element.properties }
            return element;
        });
        setUserData(tempData);
    }

    useEffect(() => {
        getRoleData();
        getUserDataCount();
        getActiveUserDataCount();
    }, [])

    useEffect(() => {
        getUserPropertyList(filter);
    }, [filter]);

    useEffect(() => {
        if(roleName){
            if(roleName!=='All-user')
                getUsersForRole()
            else
                getUserData(displayRows, filterRoleData, userFilter)

        }else
            getUserData(displayRows, filterRoleData, userFilter)
    }, [displayRows, userFilter, filterRoleData]);

    useEffect(() => {
        if(roleName){
            if(roleName!=='All-user')
                getUsersForRole()
            else
                getUserData(displayRows, filterRoleData, userFilter)

        }
    }, [roleName]);

    const handleCreateAndUpdateRole = async (value, type) => {
        setRolePopUp({ popUp: false, update: false });
        const actions = UserManagementActions(app());
        const [, data] = await actions.postCreateAndUpdateRole(value, type);
        setModifyRoleData({});
        getRoleData();
        if(roleName){
            if(roleName!=='All-user')
                getUsersForRole()
            else
                getUserData(displayRows, filterRoleData, userFilter)
        }
    }

    const handleUpdatePermissions = async (value) => {
        const actions = UserManagementActions(app());
        const [, data] = await actions.putPermissions(value);
    }

    const handleDeleteRole = async (uuid) => {
        setRoleDelete({ name: "", delete: false });
        const actions = UserManagementActions(app());
        const [, data] = await actions.deleteRoles(uuid);
        getRoleData();
        getUserData(displayRows, filterRoleData, userFilter);
    }

    const handleRoleFilter = (value) => {
        setRoleName("");
        if (value === "") setCreateRoleColumn(false);
        else setCreateRoleColumn(true);
        setRoleName(value);
    }

    const handleUserRoleFilter = (value) => {
        setFilterRoleData("");
        setFilterRoleData(value);
    }

    const handleEditRole = (data) => {
        let _expressions = {
            key: "",
            values: [{ select1: "", select2: "Equals", select3: "", data: [] }]
        };
        if (data.properties) {
            if (data.properties.includes("AND")) _expressions.key = "AND";
            else if (data.properties.includes("OR")) _expressions.key = "OR";
            let valuesArrayLength = 0;
            for (let i = 0; i < (userProperties?.length || 0); i++) {
                if (data.properties.includes(userProperties[i].propertyUuid)) {
                    _expressions.values[valuesArrayLength] = { select1: "", select2: "Equals", select3: "", data: [] };
                    _expressions.values[valuesArrayLength]["select1"] = userProperties[i].propertyUuid;
                    _expressions.values[valuesArrayLength]["data"] = JSON.parse(userProperties[i].value);
                    let tempVal = JSON.parse(userProperties[i].value);
                    for (let j = 0; j < tempVal.length; j++) {
                        if (data.properties.includes(tempVal[j])) {
                            _expressions.values[valuesArrayLength]["select3"] = tempVal[j];
                        }
                    }
                    valuesArrayLength++;
                }
            }
        }
        setModifyRoleData({
            defaultName: data.name,
            data: { name: data.name, roleAdmin: data.isPlatform, description: data.description },
            expressions: _expressions,
            permissions: data.permissions || []
        })
    }

    const handleRolePopUp = async (type, data) => {
        switch (type) {
            case "Create":
                setRolePopUp({ popUp: true, update: false, permissions: false });
                setModifyRoleData({
                    data: { name: "", roleAdmin: true, description: "" },
                    expressions: { key: "", values: [{ select1: "", select2: "Equals", select3: "", data: [] }] },
                    permissions: []
                });
                break;
            case "Edit":
                handleEditRole(data);
                setRolePopUp({ popUp: true, update: true, permissions: false });
                break;
            case "Delete":
                setRoleDelete({ name: data.name, delete: true })
                break;
            case "Permissions":
                handleEditRole(data);
                setRolePopUp({ popUp: true, update: false, permissions: true });
        }
    }

    const updateUserList = async () =>{
        getUserData(displayRows, filterRoleData, userFilter);
        getRoleData();
        getUserDataCount();
        getActiveUserDataCount();
    }

    return (
        <>
            {location.state.permission.childPermissions[1]?.canView
            ?<div className="userm-container">
                <div className="userm-top-container">
                    {createRoleColumn
                        ? <div className="userm-top-inner-container">
                            <div className="role-header-container">
                                <div className="userm">
                                    <h2 className="userm-header">Roles</h2>
                                    {
                                        location.state.permission.childPermissions[1]?.canCreate
                                        ?<button data-clickable-testid="new-role" onClick={() => handleRolePopUp("Create", "")}> + New Role </button>
                                        :<></>
                                    }
                                </div>
                                <p style={{ fontSize: "14.5px" }}>Manage how users access your functions.</p>
                            </div>
                            <div className="user-header-container">
                                <div className="userm">
                                    <h2 className="userm-header">Users</h2>
                                    {
                                        location.state.permission.childPermissions[1]?.canCreate
                                        ?<div className="userm-buttons">
                                            <button data-clickable-testid="bulk-import"> Bulk Import</button>
                                            <button data-clickable-testid="new-user" onClick={() => setUserModal(true)}> + New</button>
                                        </div>
                                        :<></>
                                    }
                                </div>
                                <p style={{ fontSize: "14.5px" }}>Manage the users who have access to the apps you create.</p>
                            </div>
                        </div>
                        : <>
                            <div className="userm">
                                <h2 className="userm-header">Users and Roles</h2>
                                {location.state.permission.childPermissions[1]?.canCreate?
                                  <div className="userm-buttons">
                                    <button data-clickable-testid="new-role" onClick={() => handleRolePopUp("Create", "")} > + New Role </button>
                                    <button data-clickable-testid="bulk-import"> Bulk Import Users</button>
                                    <button data-clickable-testid="new-user" onClick={() => setUserModal(true)}> + New User </button>
                                </div>:<></>
                                }
                            </div>
                            <p>Manage your roles and what users access your functions.</p>
                        </>
                    }
                </div>
                <div className="userm-data-container">
                    <RoleList
                        roleData={roleData}
                        handleRoleFilter={handleRoleFilter}
                        handleRolePopUp={handleRolePopUp}
                        allRoles={allRoles}
                        userProperties={userProperties || []} />
                    <UserList
                        updateUserList={updateUserList}
                        userDataCount={userDataCount}
                        roleData={roleData}
                        handleUserRoleFilter={handleUserRoleFilter}
                        filterRoleData={filterRoleData}
                        roleName={roleName}
                        setUserDataCount={setUserDataCount}
                        setAllRoles={setAllRoles}
                        userProperties={userProperties}
                        getUserData={getUserData}
                        userData={userData}
                        setUserData={setUserData}
                        displayRows={displayRows}
                        setDisplayRows={setDisplayRows}
                        filterData={userFilter}
                        setFilterData={setUserFilter}
                        getRoleData={getRoleData}
                        getUsersForRole={getUsersForRole} />
                </div>
            </div>
            :<></>}
            {location.state.permission.childPermissions[0]?.canView
                ?<UserProperties
                    userProperties={userProperties || []}
                    getUserPropertyList={getUserPropertyList}
                    filter={filter}
                    setFilter={setFilter} /> :""}
            {rolePopUp.popUp
                ? <RolePopUp
                    roleData={roleData}
                    rolePopUp={rolePopUp}
                    setRolePopUp={setRolePopUp}
                    userProperties={userProperties || []}
                    modifyRoleData={modifyRoleData}
                    handleCreateAndUpdateRole={handleCreateAndUpdateRole}
                    handleUpdatePermissions={handleUpdatePermissions}
                    setModifyRoleData={setModifyRoleData} /> : ""}
            {userModal
                ? <NewUserDialog
                    updateUserList={updateUserList}
                    setUserModal={setUserModal}
                    userModal={userModal}
                    testId="create-user-dialog"
                    createuser="createUser"
                    assignedRole={filterRoleData} /> : ""}
            {/* {assignRole.popup
                ? <AssignRole
                    assignRole={assignRole}
                    handleAssignRole={handleAssignRole}
                    roleData={roleData}
                    updateAssignRole={updateAssignRole} /> : ""} */}
            {roleDelete.delete
                ? <SmallPopup
                    popUp={roleDelete.delete}
                    title={"Delete Role"}
                    subtitle={`Do you want to delete ${roleDelete.name} role ?`}
                    handlePopUp={() => setRoleDelete({ name: "", delete: false })}
                    handleSubmit={() => handleDeleteRole(roleDelete.name)}
                />
                : ""
            }
            {/* {userDelete.delete
                ?<SmallPopup
                    popUp={userDelete.delete}
                    title={"Delete User"}
                    subtitle={`Do you want to delete ${userDelete.name} user ?`}
                    handlePopUp={() => setUserDelete({ name: "", delete: false })}
                    handleSubmit={() => handleDeleteUser(userDelete.name)}
                />
                : ""
            } */}
        </>
    );
}

export default UserManagement;