import { bosFunction, createNewBaas, fetchBaasApi, fetchServices, generateBaasApi, getMetaData, getServiceDetail, getTransportMetadata, publishService, publishStatus, putTransportService, revertBack, transportStatus, updateBaas, updateBaasEnable } from "../../../api/library/services/services";
import ActivityIndicator from "../../../components/activity/ActivityIndicator";
import messages from "./services.messages";
import React from "react";

export function ServiceActions({ show, close, notify }) {

    const getList = async (limit, offset, service) => {
        show(() => <ActivityIndicator text={messages.List} />)
        const [error, data] = await fetchServices(limit, offset, service);
        close()
        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const getBaasApiKeys = async () => {
        show(() => <ActivityIndicator text={messages.bassApi} />)
        const [error, data] = await fetchBaasApi();
        close()
        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const regenerateBaasApiKeys = async () => {
        show(() => <ActivityIndicator text={messages.regenerate} />)
        const [error, data] = await generateBaasApi();
        close()
        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const createNewServices = async (payload) => {
        show(() => <ActivityIndicator text={messages.checkNewService} />)
        const [error, data] = await createNewBaas(payload);
        close()
        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const updateServices = async (payload) => {
        show(() => <ActivityIndicator text={messages.updateService} />)
        const [error, data] = await updateBaas(payload);
        close()
        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];

        notify(data.status)
        return [null, data];
    }

    const updateBaasEnabled = async (baasData, payload) => {
        show(() => <ActivityIndicator text={messages.updateService} />)
        const [error, data] = await updateBaasEnable(baasData, payload);
        close()

        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        notify(data.status);
        return [null, data];
    }

    const getServiceMetadata = async (baasData) => {
        const [error, data] = await getMetaData(baasData);

        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const getSerivceData = async (sendData) => {
        const { uuid, limit = false, filter = null } = sendData;
        const [error, data] = await getServiceDetail(uuid, limit, filter);

        if (error)
            return [error, null];
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const postPublish = async (uuid, payload) => {
        const [error, data] = await publishService(uuid, payload);

        if (error)
            return [error, null]
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const getPublishStatus = async (reqId) => {
        const [error, data] = await publishStatus(reqId);

        if (error)
            return [error, data];
        if (data?.code >= 400)
            return [data.message, data];
        return [null, data];
    }

    const getTranasportMeta = async (sendData) => {
        const [error, data] = await getTransportMetadata(sendData);

        if (error)
            return [error, data];
        if (data?.code >= 400)
            return [data.message, data];
        return [null, data];
    }

    const transportServiceData = async (sendData) => {
        show(() => <ActivityIndicator text={"Loading"} />)
        const [error, data] = await putTransportService(sendData);
        close()

        if (error)
            return [error, null]
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const getTransportStatus = async (reqId, project) => {
        show(() => <ActivityIndicator text={"Loading"} />)
        const [error, data] = await transportStatus(reqId, project);
        close()

        if (error)
            return [error, data];
        if (data?.code >= 400)
            return [data.message, data];
        return [null, data];
    }

    const getBosFunction = async (uuid, project) => {
        show(() => <ActivityIndicator text={"Loading"} />)
        const [error, data] = await bosFunction(uuid, project);
        close()

        if (error)
            return [error, data];
        if (data?.code >= 400)
            return [data.message, data];
        return [null, data];
    }

    const putRevertService = async (uuid, project, version) => {
        show(() => <ActivityIndicator text={"Loading"} />)
        const [error, data] = await revertBack(uuid, project, version);
        close()

        if (error)
            return [error, null]
        if (data?.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    return {
        getList,
        getBaasApiKeys,
        regenerateBaasApiKeys,
        createNewServices,
        updateServices,
        updateBaasEnabled,
        getServiceMetadata,
        getSerivceData,
        postPublish,
        getPublishStatus,
        getTranasportMeta,
        transportServiceData,
        getTransportStatus,
        getBosFunction,
        putRevertService
    }

}