import { getUrl, get, post, put, deleteAPI, encodeFilter } from "../../url"

export async function getVariablesList(offset, limit, variables) {
    let uri = getUrl() + `/configurator/v2.0/company/globalvariable/listByCreate?$sort=desc name`
    if (variables)
        uri += getFilter(variables);
    if (limit)
        uri += "&limit=" + limit + "&offset=" + offset;
    const [error, data] = await get(uri);
    return [error, data];
}

const getFilter = (variable) => {
    let filter = "&$filter=";
    let obj = [];
    Object.keys(variable).map(function (key, index) {
        if (key === 'createdUser') {
            if (variable[key] !== "") obj.push("IN (createdUser,'" + variable[key] + "')");
        } else {
            obj.push(key + ' LIKE ' + encodeFilter(variable[key]));
        }
    });
    obj.length > 0 ? filter += obj.join(" AND ") : filter = "";
    return filter;
}

export async function deleteVariable(name) {
    let uri = getUrl() + `/configurator/v1.0/company/globalvariable/` + name;
    const [error, data] = await deleteAPI(uri);
    return [error, data];
}

export async function updateVariable(name, json) {
    //json should be same as create
    let uri = getUrl() + `/configurator/v1.0/company/globalvariable/` + name;
    const [error, data] = await put(uri, json);
    return [error, data];
}

export async function createVariable(json) {

    // let sampleStringData = {
    //     "name": "testVariable1",
    //     "type": "String",//Number or Decimal
    //     "field": {
    //         "value": "Privi",
    //         "fields": false
    //     },
    //     "value": "Privi"
    // }

    // let sampleTableData = {
    //     "name": "testTab1",
    //     "type": "Table",
    //     "value": [
    //         {
    //             "id": "1",
    //             "name": "privi",
    //             "mark": "78.5"
    //         }
    //     ],
    //     "fields": [
    //         {
    //             "key": "id",
    //             "dataType": "Number",
    //             "isPrimary": true,
    //             "isIndex": false
    //         },
    //         {
    //             "key": "name",
    //             "dataType": "String",
    //             "isPrimary": false,
    //             "isIndex": false
    //         },
    //         {
    //             "key": "mark",
    //             "dataType": "Decimal",
    //             "isPrimary": false,
    //             "isIndex": false
    //         }
    //     ]
    // }
    const uri = getUrl() + "/configurator/v1.0/company/globalvariable";
    const [error, data] = await post(uri, json);
    return [error, data];
}