import React, { useState } from "react";
import "./tree.css";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditPopUp from "../EditPopup/editPopUp";
import { ToolTip } from "../ToolTip/ToolTip";

const Tree = (props) => {
  const { name, data = [], options, handleMenuClick } = props;

  return (
    <div className="treeCon" data-tree-testid={name}>
      {data.map((node, index) => (
        <ul className="root">
          <TreeNode key={index} node={node} data={data} options={options} handleMenuClick={handleMenuClick} />
        </ul>
      ))}
    </div>
  );
};

const TreeNode = ({ node, key, data, options, handleMenuClick }) => {
  // console.log(node);
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  }

  const onLeave = () => {
    setHover(false);
  }

  const [open, setOpen] = useState("nested");

  const openTreeNode = (node) => {
    open == "nested" ? setOpen("tree-active") : setOpen("nested");
    const load = node.load;
    if (load)
      load(node, data);
  }

  const handleTreePopUp = (e, value) => {
    handleMenuClick(e, value);
  }

  return (
    <li data-row-testid={node.name} style={{ width: "100%" }} key={key}>
      {node.isLeaf
        ? node.name
        : (<>

          <div onMouseEnter={onHover} onMouseLeave={onLeave} className={`workspaceTable ${open === "tree-active" ? "root-active" : ""}`}>


            <span data-clickable-testid={`${node.name}`} className="span_dropdown" onClick={() => openTreeNode(node)}>

             
              <div title={node.name}>
                {node.name}
              </div>


            
            </span>

            <div style={{ height: "20px", width: "20px", marginLeft: '17px' }}><img src="/images/header/downArrowIcon.svg" onClick={() => openTreeNode(node)} /></div>
            {node.children
              ? <span className="rootIcon">
                <EditPopUp options={options} value={""} onChange={(e) => handleTreePopUp(e, node)} />
              </span>
              : ""}
          </div>

          {/* {hover?<div>{node.name} </div>:""} */}

          <ul className={open} key={key}>
            {node.children?.map((child) => <TreeNode node={child} data={data} />)}
          </ul>
        </>)}
    </li>
  );
};

export default Tree;