import React, { useEffect, useState } from "react";
import PopUp from "../../../components/layouts/PopUp";
import { useForm } from "../../../components/forms/Form";
import "./index.css";
import { useAppContext } from "../../../components/activity/AppContext";
import { AssetActions } from "./asset.actions";
import FileUpload from "../../../components/fileUpload";
import styles from "./assets.module.css";

function CreateFile(props) {
  const {
    handleCancel,
    item,
    isUpdate = false,
    getAssetsMasterData,
    createFile,
  } = props;
  const app = useAppContext();
  const [data, setData] = useState(item);
  const selectoption = [
    { id: "JS", value: "Javascript" },
    { id: "CSS", value: "Stylesheet" },
    { id: "SDK", value: "SDK" },
    { id: "Image", value: "Image" },
    { id: "AUD", value: "AUD" },
  ];
  const [accept, setAccept] = useState(props.accept || ".js");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleFileType = (value) => {
    switch (value) {
      case "JS":
        setAccept(".js");
        break;
      case "CSS":
        setAccept(".css");
        break;
      case "SDK":
        setAccept(".jar");
        break;
      case "Image":
        setAccept("image/*");
        break;
      case "AUD":
        setAccept("audio/*");
        break;
      default:
        break;
    }
  };

  const onWatch = (errors, val) => {
    setError("");
    try {
      if (val.name === "type") {
        setData({ ...data, type: val.value });
        handleFileType(val.value);
      } else {
        setFile(val);
      }
    } catch {
      setError(`Upload ${accept} only`);
    }
  };

  useEffect(() => {
    if (data?.file?.name !== undefined) {
      if (
        accept === "*" ||
        (accept !== "*" && data?.file?.name?.endsWith(accept))
      ) {
        setError("");
      } else if (
        file?.name?.endsWith("svg") ||
        file?.name?.endsWith("png") ||
        file?.name?.endsWith("jpg") ||
        file?.name?.endsWith("jpeg")
      ) {
        setError("");
      } else setError(`Upload ${accept} only`);
    }
  }, [accept, data.file.name]);

  useEffect(() => handleFileType(item?.type), [item.type]);

  const onSubmit = async (values, errors) => {
    if (error) return null;
    const actions = AssetActions(app());
    if (isUpdate) {
      if (file) {
        const [error, data] = await actions.update(file, item.file.name);
        if (!error) {
          handleCancel();
          getAssetsMasterData();
        }
      } else {
        if (!error) setError("File missing");
      }
    } else {
      if (file) {
        const [error, data] = await actions.upload(file);
        if (!error) {
          handleCancel();
          getAssetsMasterData();
        }
      } else {
        if (!error) setError("File missing");
      }
    }
  };

  const { Form, Select, Label } = useForm(data, onSubmit, onWatch);

  return (
    <div>
      <PopUp
        popUp={createFile}
        setPopUp={handleCancel}
        title={"Import file"}
        testId="import-file"
      >
        <Form>
          <div className={styles.createContainer}>
            <div className={styles.firstColumn}>
              <Label text="Type" />
              <Select name="type" id="type" defaultValue={data.type}>
                {selectoption.map((item, index) => {
                  return (
                    <option
                      data-option-testid={item.value}
                      key={index}
                      value={item.id}
                    >
                      {item.value}
                    </option>
                  );
                })}
              </Select>
            </div>
            <FileUpload
              onChange={(data) => onWatch({}, data)}
              accept={accept}
              file={file?.name}
              error={error}
              setError={setError}
              data={data}
              setData={setData}
            />
          </div>
          <div className={styles.submitContainer}>
            <input
              data-clickable-testid={"cancel"}
              className="secondary"
              type="button"
              value={"Cancel"}
              onClick={handleCancel}
            />
            <input
              data-clickable-testid={"upload"}
              className="primary"
              type="submit"
              value={"Upload"}
            />
          </div>
        </Form>
      </PopUp>
    </div>
  );
}
export default CreateFile;
