
import { messages } from "./messages";

export function validateColumn (key, value, app, colChanges)  {
    if (key === 'primary' && value === true) {
        let primaryList = colChanges?.filter(item =>  item.primary === true);
        if(primaryList && primaryList.length < 1)
        {
            return true;
        }
        else{
            app.notify({ message: messages.primaryKeyErr, type: "E" });
            return false;
        }
    } else if(key === "columnName") {
        let searchPattern = new RegExp('^[a-zA-Z_,0-9]*$', 'i');
        if (searchPattern.test(value) && value.length<=64){
            return true;
        }else{
            app.notify({ message: messages.validColNameErr, type: "E" });
            return false;
        }
    } else if(key === "dataLength" && value) {
        if (value.length >= 0){
            return true;
        }else{
            app.notify({ message: messages.validColLengthErr, type: "E" });
            return false;
        }
    }else
        return true;
}
export function validateIndex(key, value, app){  
   if(key === "name" && value != '') {
        let searchPattern = new RegExp('^[a-zA-Z_0-9]*$', 'i');
        if (searchPattern.test(value) && value.length<=64){
            return true;
        }else{
            app.notify({ message: messages.validIndexErr, type: "E" });
            return false;
        }
    } else
        return true;
}

export function validateChange(tableName,colChanges,indexChanges,rows,indicesRow, deletedRow, deletedIndices,existingTableName)
{
    // console.log(tableName,colChanges,indexChanges,rows,indicesRow, deletedRow, deletedIndices)
    let check = false;
    let error = messages.validDataErr;
    if(tableName)
    {
        let searchPattern = new RegExp('^[a-zA-Z_0-9]*$', 'i');
        if (searchPattern.test(tableName)){
            check = true;
            if(colChanges || indexChanges || deletedRow || deletedIndices)
            {
                if((colChanges.length > 1 || (colChanges.length === 1 )) || (indexChanges.length > 1 
                    || (indexChanges.length === 1 ) || (deletedRow.length>0 && deletedRow[0]!='') 
                    || (deletedIndices.length>0 && deletedIndices[0]!='') || tableName!==existingTableName)){
                    let checks = [true]
                    const options = ['float','decimal','double']
                    const omittedTypes=['date','timestamp', 'int', 'bigint']
                    const pattern = /^[0-9,]*$/g;
                    colChanges.map((obj,idx)=>{
                        let row = rows[obj.rowId];
                        // console.log(obj,row)
                        const name = obj.columnName || row.columnName
                        const dataType = obj.dataType || row.dataType
                        const dataLength = obj.dataLength || row.dataLength
                        if(!name){
                            checks.push(false);
                            error = messages.validColNameErr;
                        }else if(dataType && dataType!=='' ){
                            if(!omittedTypes.includes(dataType)){
                                if(dataLength && dataLength!=='' ){
                                    if(options.includes(dataType)){
                                        const flag = dataLength.match(pattern)?true:false;
                                        checks.push(flag)
                                    }else{
                                        checks.push(!isNaN(dataLength))
                                    }
                                    error = messages.validDataLengthErr;
                                }else{
                                    checks.push(false);
                                    error = messages.validDataLengthErr;
                                }
                            }
                        }else {
                            checks.push(false);
                            error = messages.validDataTypeErr;
                        }
                    })
                    indexChanges.map((obj,idx)=>{
                        let row = indicesRow[obj.rowId];
                        const name = obj.name || row.name
                        // console.log(obj,row,name)
                        if(!name){
                            checks.push(false);
                            error = messages.validIdxNameErr;
                        }
                    });

                    check = checks.length>0?!checks.includes(false):true//validate the datalength
                    // console.log(checks,check,error)
                }
                else
                    check = false;
            }
            else
                check = false;
        }
        else{
            check = false;
            error = messages.tableNameErr;
        }

    }
    else
    {
        check = false;
        error = messages.tableNameErr;
    }
    return {isValid:check,errorTxt:error};
}