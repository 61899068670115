import React, { useEffect, useState } from "react";
import Dialog from "../../../../views/dialog";
import {
  Box,
  Button,
  Typography,
  TablePagination,
  Grid,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import store from "../../../../stores";
import { previewAndPublishAction } from "../../../../actions/preview-and-publish";
import {
  PreviewOptions,
  PreviewAppType,
  GeneratingPreview,
  GeneratedPreviewFailed,
} from "../app-process-model/component";
import Table from "../../../../common/components/Table";
import { configMessage } from "../../../../common/messages/config";
import { businessFunctionAppPreviewConstants } from "../../../../constants";
import { fetchWorklist } from "../../../../helpers/all-items";
import { fetchStartPreview } from "../../../../helpers/preview-and-publish";

import {
  searchAppPreviewTable,
  getWorkflows,
  getWorkFlowPreview,
} from "../../../../helpers/preview-and-publish";
import useStyles from "./style";
import PromptDialog from "../../../../common/components/PromptDialog";
import { capitalize, setSessionVal } from "../../../../utils/common";
import GeneratedPreview from "../app-process-model/component/generated-preview";

function AppPreview(props) {
  const classes = useStyles();
  const { previewAndPublish, businessFunction, workflowlogs } = props;
  const [loading, setLoading] = useState(true);
  const [appType, setAppType] = useState("hybrid");
  const [appMode, setAppMode] = useState(configMessage.T4668);
  const [appName, setAppName] = useState("");
  const [dfilter, setDefaultFilter] = useState("");
  const [workFlowStatus, setWorkFlowStatus] = useState(false);
  const [searchValues, setSearchValues] = useState({});
  // const [flag, setflag] = useState(false);
  const [projectName, setProjName] = useState("");

  const filterKey = {
    businessFuntionName: "b.name",
    projectName: "p.name",
    businessKey: "b.name",
    workflowId: "workflow_id",
    itemCurrentStatus: "w.item_current_status",
    assignedRole: "assigned_role",
    subject: "subject",
    title: "w.title",
  };

  const versionListColumns = [
    {
      id: "workflowId",
      label: "Workflow Id",
      align: "left",
      minWidth: 100,
      maxWidth: 120,
      type: "text",
      style: {
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        textAlign: "left",
      },
    },
    {
      id: "title",
      label: "Title",
      minWidth: 80,
      maxWidth: 120,
      align: "left",
      style: { paddingLeft: 8 },
      type: "text",
      placeHolder: "Title",
    },
    {
      id: "subject",
      label: "Subject",
      minWidth: 80,
      maxWidth: 120,
      align: "left",
      style: { paddingLeft: 8 },
      type: "text",
      placeHolder: "Subject",
    },
    {
      id: "itemCurrentStatus",
      label: "State",
      align: "left",
      minWidth: 60,
      maxWidth: 80,
      type: "text",
      style: {
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
        textAlign: "left",
      },
    },
    {
      id: "action",
      label: "",
      align: "right",
      type: "button-w-header",
      color: "primary",
      minWidth: 120,
      maxWidth: 120,
      buttonText: "New request",
      style: {
        marginLeft: 24,
        overflowWrap: "break-word",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "8px",
      },
    },
  ];
  const [wIErrorMessage, setWIErrorMessage] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [propData, setPropData] = useState(tableData);
  const [workflowId, setWorkflowId] = useState("");
  const [details, setDetails] = useState({});

  const [generatedPreview, setGeneratedPreview] = useState();

  useEffect(() => {
    const status = businessFunction.type === "WFM";
    setWorkFlowStatus(status);
    const wfl =
      workflowlogs &&
      workflowlogs.map((i) => {
        return {
          ...i,
          action: "select",
        };
      });
    setTableData(wfl);
    iterateDataForButton(wfl);
  }, [businessFunction, workflowlogs]);

  useEffect(() => {
    if (props.lastDialog) {
      setPage(3);
    }
  }, [props.lastDialog]);

  useEffect(() => {
    const data = {
      ProjectName: props.projectDetail.name,
      BusinessFunction: props.businessFunName,
      Lane: props.lanes[0]?.name,
    };
    setDetails(data);
    props.urlObject["ProjectName"] = props.projectDetail.name || "";
    props.urlObject["BusinessFunction"] = props.businessFunName || "";
    if (props.appList?.name) {
      setAppName(props.appList.name);
    }
    if (props.urlObject.ProjectName) {
      setProjName(props.urlObject.ProjectName);
    }

    const defaultFilter = `p.name EQ '${props.projectDetail.name}' AND b.name EQ '${props.businessFunName}' `;
    fetchWorklist(defaultFilter);
    setDefaultFilter(defaultFilter);
  }, [props.appList]);

  const iterateDataForButton = (data) => {
    if (data?.length > 0) {
      let d = data.map((e) => {
        return {
          ...e,
          buttonText:
            e?.action.toLowerCase() === "new" ? "New request" : "Select",
        };
      });
      setTableData(d);
    }
  };

  const handleNextPage = () => {
    setPage((val) => val + 1);
  };

  const onModalClose = () => {
    store.dispatch(previewAndPublishAction.updateStatus(""));
    props.close();
    setSearchValues({});
  };

  const handleSearchInputChange = (e, c) => {
    if (e && e != "") {
      setSearchValues(searchAppPreviewTable(e, c.id, propData));
      iterateDataForButton(searchValues);
    } else {
      iterateDataForButton(propData);
    }
    let newFilter = { ...filter, [filterKey[c.id]]: e };
    setFilter(newFilter);
    handleFilter(newFilter);
  };

  const handleFilter = (filt = filter) => {
    fetchWorklist(dfilter, filt);
  };

  const handleNewRequest = (id = "") => {
    let _id = "";
    if (id) {
      _id = `&id=${id}`;
    }
    const previewPageUrl = `/Preview/${details.ProjectName}/${details.BusinessFunction}/${details.Lane}?isWorkflow=${workFlowStatus}${_id}`;
    setSessionVal("bfType","WF");
    props.history.push(previewPageUrl);
  };

  const handleCallToAction = (e, c) => {
    if (e.status === "error") {
      setWIErrorMessage(e.errorLog || "");
    } else {
      setWorkflowId(e.workflowId);
      handleNewRequest(e.workflowId);
    }
  };

  /* Modal header */
  const modalTitle = () => {
    if (page === 1) {
      return (
        <Box component="div">
          <Typography variant="h2" className={classes.modalTitle}>
            {configMessage.T4662}
          </Typography>
          <Typography variant="h5" className={classes.modalSubTitle}>
            {configMessage.T4663}
          </Typography>
        </Box>
      );
    }
    if (page === 2) {
      return (
        <Box component="div">
          <Typography variant="h2" className={classes.modalTitle}>
            {configMessage.T4672} {appName}
          </Typography>
          <Typography variant="h5" className={classes.modalSubTitle}>
            {configMessage.T4671}
          </Typography>
        </Box>
      );
    } else if (page === 3) {
      return (
        <>
          {appMode === "In Browser" ? (
            <Box id="scroll-dialog-title" className={classes.titles}>
              <Typography id="modal-title" className={classes.title}>
                {configMessage.T4770}
              </Typography>
              <Typography className={classes.subTitle}>
                {configMessage.T4771}
              </Typography>
            </Box>
          ) : (
            <Box component="div">
              <Typography variant="h2" className={classes.modalTitle}>
                {configMessage.T4686} {appName}
              </Typography>
            </Box>
          )}
        </>
      );
    } else if (page === 4) {
      return (
        <Box component="div">
          <Typography variant="h2" className={classes.modalTitle}>
            {configMessage.T4664}
          </Typography>
          <Typography variant="h5" className={`${classes.modalSubTitle} red`}>
            {configMessage.T4665}
          </Typography>
        </Box>
      );
    }
  };

  /* Modal content */
  const container = () => {
    if (page === 1) {
      return (
        <Grid xs={12} container>
          <Box component="div" className={classes.prevContainer}>
            <Grid xs={7} item>
              <PreviewAppType
                {...props}
                setAppType={setAppType}
                appType={appType}
              />
              <PreviewOptions
                {...props}
                appMode={appMode}
                setAppMode={setAppMode}
                workFlowStatus={workFlowStatus}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 2) {
      return (
        <Grid xs={12} container>
          <Box component="div" className={classes.prevContainer}>
            <Grid xs={7} item>
              <GeneratingPreview
                {...props}
                appType={appType}
                appMode={appMode}
                appName={appName}
                workflowId={workflowId}
                urlObject={props.urlObject != undefined ? props.urlObject : {}}
                previewAndPublish={props.previewAndPublish}
                handlePublishOption={props.handlePublishOption}
                setGeneratedPreview={(val, previewRedirect, page) => {
                  setGeneratedPreview(val);
                  if (!previewRedirect) {
                    setPage(page);
                  }
                }}
                handleNextPage={handleNextPage}
                isWorkflow={workFlowStatus}
              />
            </Grid>
          </Box>
        </Grid>
      );
    } else if (page === 3) {
      return (
        <>
          {appMode === "In Browser" ? (
            <Grid xs={12} container className={classes.itemCenter}>
              <Grid xs={10} item>
                <Table
                  classes={classes}
                  tableData={tableData}
                  tableColumns={versionListColumns}
                  tableHeight={420}
                  screenType={"Project"}
                  handleChange={(e, c) => handleSearchInputChange(e, c)}
                  handleButtonClick={() => handleNewRequest()}
                  handleCallToAction={(e, c) => handleCallToAction(e, c)}
                />
                {!!wIErrorMessage && (
                  <PromptDialog
                    primaryText={""}
                    secondaryText={"OK"}
                    open={!!wIErrorMessage}
                    yes={() => setWIErrorMessage("")}
                    close={() => setWIErrorMessage("")}
                    data={{
                      text: wIErrorMessage,
                      action: configMessage.E4501,
                      title: capitalize(configMessage.E4501),
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid xs={12} container>
              <Box component="div" className={classes.prevContainer}>
                <GeneratedPreview
                  {...props}
                  appType={appType}
                  appMode={appMode}
                  appName={appName}
                  projectName={projectName}
                />
              </Box>
            </Grid>
          )}
        </>
      );
    } else if (page === 4) {
      return (
        <GeneratedPreviewFailed
          {...props}
          previewAndPublish={previewAndPublish}
        />
      );
    }
  };

  const footer = () => {
    if (page === 1) {
      return (
        <Box className="drive-new-folder-footer">
          <Button variant="contained" color="secondary" onClick={onModalClose}>
            {"Cancel"}
          </Button>
          <Button variant="contained" color="primary" onClick={handleNextPage}>
            {"Next"}
          </Button>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={onModalClose}
      maxWidth={"md"}
      fullWidth={true}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
    />
  );
}

export default AppPreview;
