import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItem,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as mui from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useMemo, useState } from "react";
import ColorSwatch from "./color-swatch";
import useStyles from "./style";

const allIcons = Object.keys(mui).sort();

const getIcons = (theme) => {
  const icons = allIcons
    .map((importName) => {
      const name = importName.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, "");

      const icon = {
        importName,
        name,
        theme,
        Component: mui[importName],
      };
      if (importName.indexOf(theme) !== -1) {
        return icon;
      } else if (!theme) {
        return icon;
      }
    })
    .filter(Boolean);
  return icons;
};

export function IconViewer(props) {
  const {
    onSelectIcon,
    setActiveImage,
    setIconErr,
    iconErr,
    activeImage,
    onColorChange,
    theme,
    enableIconColorPicker,
    enableBgColorPicker,
    enableFileUpload,
    enableLibraryPicker,
    onLibraryFileClick,
    libraryList,
    iconColor,
    backgroundColor,
    selectedApp
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [iconList, setIconList] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);

  useEffect(() => {
    setIconList(getIcons(theme));
    return () => {
      setIconList([]);
    };
  }, [theme]);

  useEffect(() => {
    if(setIconErr)
      setIconErr('');
  },[])

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedApp) {
      setDefaultImage(selectedApp.icon);
    }
  }, [selectedApp]);

  function handleImageUpload(file) {

    const image = new Image();
    image.addEventListener('load', () => {
        if (image.width === 256 & image.height === 256) {
          // console.log("width & height", image.width, image.height);
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            if (reader.readyState === 2) {
              setActiveImage(reader.result);
            }
          });
          reader.readAsDataURL(file);
        }
        else
        {
          setIconErr("Image Size should be 256 x 256 px");
        }
    });
    image.src = URL.createObjectURL(file)
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileSelect(e, "drop");
  };

  const handleFileSelect = (e, type) => {
    e.preventDefault();
    setIconErr("");
    const file = type === "drop" ? e.dataTransfer.files[0] : e.target.files[0];
    const regex = new RegExp("(.*?).(jpe?g|png|JPE?G|PNG)$");
    if (file) {
      if (!regex.test(file.name.toLowerCase())) {
        setIconErr("Invalid file format");
      } else {
        if (file.size > 1000000) {
          setIconErr("Size should be less than 1MB");
        } else {
          const fileType = file.name.split(".").pop();
          handleImageUpload(file);
        }
      }
    }
  };

  const checkValidUrl = (url) => {
  
    var types = ['jpg','jpeg','png'];
    var parts = url.split('.');
    var extension = parts[parts.length-1];
   
    if(types.indexOf(extension) !== -1) {
        return true;   
    }
  }

  const handleSearch = (value) => {
    if (value === "") {
      setSearchKey(null);
    } else {
      setSearchKey(value);
    }
  };

  const icons = useMemo(
    () =>
      !searchKey
        ? iconList
        : iconList.filter((icon) => {
            let n = icon.name.toLowerCase();
            return !!n.includes(searchKey.toLowerCase());
          }),
    [iconList, searchKey]
  );

  return (
    <Paper>
      <Tabs
        value={value}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabContainer}
      >
        <Tab className={classes.tab} label="Icons" />
        {enableIconColorPicker && <Tab className={classes.tab} label="Color" />}
        {enableBgColorPicker && (
          <Tab className={classes.tab} label="Background" />
        )}
        {enableFileUpload && (
          <Tab className={classes.tab} label="Upload Icon" />
        )}
        {enableLibraryPicker && (
          <Tab className={classes.tab} label="Library" />
        )}
      </Tabs>
      {value == 0 && (
        <Box
          style={{
            maxWidth: "326px",
            height: "278px",
            margin: "auto",
          }}
        >
          <Paper
            className={classes.paper}
            style={{
              display: "flex",
              boxShadow: "none",
              width: "100%",
              alignItems: "center",
              position: "relative",
              padding: "8px",
            }}
          >
            <InputBase
              autoFocus
              onChange={(event) => {
                handleSearch(event.target.value);
              }}
              className={classes.input}
              placeholder="Search"
              inputProps={{ "aria-label": "search icons" }}
              style={{ width: "100%" }}
              endAdornment={
                <InputAdornment
                  position="end"
                  className={classes.searchIcon}
                >
                  <SearchIcon className={classes.searchSvgIcon} />
                </InputAdornment>
              }
            />
          </Paper>
          <Icons
            icons={icons}
            classes={classes}
            onSelectIcon={onSelectIcon}
            iconColor={iconColor}
            backgroundColor={backgroundColor}
          ></Icons>
        </Box>
      )}
      {!!enableIconColorPicker && value == 1 && (
        <Box
          style={{
            margin: "10px 30px",
            height: "278px",
          }}
        >
          <ColorSwatch
            classes={classes}
            onColorChange={(d) => {
              onColorChange(d, "ICON_COLOR");
            }}
          />
        </Box>
      )}
      {!!enableBgColorPicker && value == 2 && (
        <Box
          style={{
            margin: "10px 30px",
            height: "278px",
          }}
        >
          <ColorSwatch classes={classes} onColorChange={onColorChange} />
        </Box>
      )}
      {!!enableFileUpload && value == 3 && (
        <Box style={{ width: "320px", height: "278px", margin: "auto" }}>
          <Grid xs={12} container justify="center" style={{ marginTop: 8 }}>
            <Box
              component="div"
              className={classes.dropbox}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <Box
                component="div"
                className={classes.iconImgBox}
                style={{
                  background:
                    !!activeImage && !activeImage.includes("svg")
                      ? "none"
                      : "#9C63F9",
                }}
              >
                {activeImage && !activeImage.includes("svg") ? (
                  <img src={activeImage} className={classes.appIcon} />
                ) : (
                  <Box className={classes.imgIconSet}>
                    <Box component="div" className={classes.whiteBox}>
                      <SearchIcon className={classes.searchBar} />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box component="div" className={classes.dropContainer}>
                <Box component="div" className={classes.dropText}>
                  {" "}
                  Drop a file here or{" "}
                </Box>
                <Button variant="contained" component="label" color="primary">
                  <input
                    id="appIcon"
                    type="file"
                    alt="Profile Picture"
                    accept="image/*"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                  Select a file
                </Button>
                <Box component="div" className={classes.imgTypes}>
                  {" "}
                  .jpg or .png files only{" "}
                </Box>
                <Box component="div" className={classes.imgTypes}>
                  256 x 256 px only
                </Box>
                {iconErr && (
                  <Box component="div">
                    <small className="red">{iconErr}</small>
                  </Box>
                )}
              </Box>
            </Box>
            {/* <Grid xs={12} container justify="center" style={{margin:16}}>[OR]</Grid> */}
          </Grid>
        </Box>
      )}
      { enableLibraryPicker && value === 1 && (
        <Box style={{ width: "320px", height: "278px", margin: "auto" }}>
          {
            libraryList.map(e => (
              <ListItem  
                button
                selected={false}
                onClick={() => onLibraryFileClick(e)}
              >
                  {e.name || ""}
              </ListItem>
            ))
          }
        </Box>
      )}
    </Paper>
  );
}

export default React.forwardRef((props, ref) => (
  <IconViewer innerRef={ref} {...props} />
));

export function Icons(props) {
  const { icons, classes, onSelectIcon, iconColor, backgroundColor } = props;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        overflow: "scroll",
        height: "228px",
        minWidth: "310px"
      }}
    >
      {icons.map((icon) => {
        return (
          <span
            key={icon.importName}
            onClick={() => {
              onSelectIcon(icon);
            }}
            className={classes.icon}
          >
            <icon.Component
              tabIndex={-1}
              title={icon.importName}
              className={classes.iconSvg}
              style={{
                color: iconColor?.toLowerCase() === "#ffffff" ? "#000" : iconColor,
              }}
            />
          </span>
        );
      })}
    </div>
  );
}
