import React from "react";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../components/activity/AppContext";
import styles from "./revertService.module.css";
import { ServiceActions } from "../services.actions";

export default function RevertServiceStatus(props) {
    const { handleClose, selectedService, serviceData } = props;
    const app = useAppContext();
    const [statusMsg, setStatusMsg] = useState({ show: false, msg: "" })

    const updateRevertService = async () => {
        const actions = ServiceActions(app());
        const [error, data] = await actions.putRevertService(serviceData.serviceId, serviceData.projectName, selectedService.version);

        if (!error) {
            let reqId = JSON.parse(data.object).requestId;
            checkStatus(reqId)
        }
    }

    const checkStatus = async (reqId) => {
        const actions = ServiceActions(app());
        const [error, data] = await actions.getPublishStatus(reqId);
        if (data) {
            if (data.detail.status === "pending") {
                checkStatus(reqId);
            } else if (data.detail.status === "finish") {
                fetchStatus(data.detail.fileAddress)
            }
        }
    }

    const fetchStatus = async (url) => {
        const result = await fetch(url);
        const res = await result.text();

        setStatusMsg({ show: true, msg: res })
    }

    useEffect(() => {
        updateRevertService();

    }, [])

    const handleDone = () => {
        if (statusMsg.show) {
            handleClose();
        }
    }

    return (
        <div className={styles.popupCont}>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{statusMsg.show ? "Revert Success" : "Reverting..."}</h2>
                <h5 className={styles.subTitle}>{statusMsg.show ? "Check the logs here" : ""}</h5>
            </div>
            <div className={styles.tableCont}>
                {statusMsg.show ? <div className={styles.formCont}><span>{statusMsg.msg}</span></div> : ""}
            </div>
            <div className={styles.btnCont}>
                <input
                    type="button"
                    className={statusMsg.show ? "primary" : "tertiary"}
                    value={"Done"}
                    onClick={handleDone}
                    data-clickable-testid={"Done"}
                />
            </div>
        </div>
    )
}
