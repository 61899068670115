import React, { useEffect, useState } from 'react';
import "./pagination.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function Pagination(props) {
    const { userDataCount, displayRows, setDisplayRows, showRecords = true } = props;
    const rowsPerPageOptions = [10, 20, 40, 60, 80, 100];
    const [rowsPerPagePopUp, setRowsPerPagePopUp] = useState(false);
    const [disableRightArrow, setDisableRightArrow] = useState(false);
    const [disableLeftArrow, setDisableLeftArrow] = useState(true);
    const [rowsData, setRowsData] = useState({
        firstNum: 0,
        lastNum: 10,
        totalNum: userDataCount
    })

    useEffect(() => {
        if (userDataCount === 0) {
            setRowsData({ ...rowsData, firstNum: 0, lastNum: 0 });
            setDisableRightArrow(true);
        }
        else if (userDataCount <= 10 && userDataCount > 0) {
            setRowsData({ ...rowsData, firstNum: 1, lastNum: userDataCount });
            setDisableRightArrow(true);
        }
        else {
            setRowsData({ ...rowsData, firstNum: 1, lastNum: 10 });
            setDisableRightArrow(false);
        }
    }, [userDataCount])

    const handleRowsPerPagePopup = () =>{
        if(userDataCount>10) setRowsPerPagePopUp(true)
    }
    const handlePopUp = (event) => {
        if (userDataCount > event.target.value) {
            setDisplayRows({ offset: 0, limit: event.target.value });
            setRowsData({ firstNum: 1, lastNum: event.target.value });
            setRowsPerPagePopUp(false);
            setDisableRightArrow(false);
            setDisableLeftArrow(true);
        }
        else {
            setDisplayRows({ offset: 0, limit: event.target.value });
            setRowsData({ firstNum: 1, lastNum: userDataCount });
            setRowsPerPagePopUp(false);
            setDisableRightArrow(true);
            setDisableLeftArrow(true);
        }
    }

    const handleNextRow = () => {
        if (userDataCount > (+rowsData.lastNum + +displayRows.limit)) {
            setRowsData({
                "firstNum": +rowsData.firstNum + +displayRows.limit,
                "lastNum": +rowsData.lastNum + +displayRows.limit,
                "totalNum": userDataCount
            });
            setDisplayRows({ ...displayRows, offset: +displayRows.offset + +displayRows.limit })
            setDisableRightArrow(false);
            setDisableLeftArrow(false);
        }
        else if (userDataCount < (+rowsData.lastNum + +displayRows.limit)) {
            setRowsData({
                "firstNum": +rowsData.firstNum + +displayRows.limit,
                "lastNum": userDataCount,
                "totalNum": userDataCount
            });
            setDisplayRows({ ...displayRows, offset: +displayRows.offset + +displayRows.limit })
            setDisableRightArrow(true);
            setDisableLeftArrow(false);
        }
        else {
            setRowsData({
                "firstNum": +rowsData.firstNum + +displayRows.limit,
                "lastNum": +rowsData.lastNum + +displayRows.limit,
                "totalNum": userDataCount
            });
            setDisplayRows({ ...displayRows, offset: +displayRows.offset + +displayRows.limit })
            setDisableRightArrow(true);
            setDisableLeftArrow(false);
        }
    }

    const handlePrevRow = () => {
        if ((+rowsData.firstNum - +displayRows.limit) === 1) {
            setRowsData({
                "firstNum": +rowsData.firstNum - +displayRows.limit,
                "lastNum": +rowsData.firstNum - 1,
                "totalNum": userDataCount
            });
            setDisplayRows({ ...displayRows, offset: +displayRows.offset - +displayRows.limit })
            setDisableLeftArrow(true);
            setDisableRightArrow(false);
        }
        else if (+rowsData.lastNum % displayRows.limit !== 0) {
            setRowsData({
                "firstNum": +rowsData.firstNum - +displayRows.limit,
                "lastNum": +rowsData.firstNum - 1,
                "totalNum": userDataCount
            });
            setDisplayRows({ ...displayRows, offset: +displayRows.offset - +displayRows.limit })
            setDisableLeftArrow(false);
            setDisableRightArrow(false);
        }
        else {
            setRowsData({
                "firstNum": +rowsData.firstNum - +displayRows.limit,
                "lastNum": +rowsData.lastNum - +displayRows.limit,
                "totalNum": userDataCount
            });
            setDisplayRows({ ...displayRows, offset: +displayRows.offset - +displayRows.limit })
            setDisableLeftArrow(false);
            setDisableRightArrow(false);
        }
    }

    return (
        <div className="pagination-container">
            <div className="pagination-inner-container">
                <span data-testid={'Rows per page : '}>Rows per page : </span>
                <div className='button-value-container'>
                    {rowsPerPagePopUp
                        ? <div className='pagination-button-container'>
                            {rowsPerPageOptions.map((rw, index) => {
                                return (<button
                                    className='pagination-button-values'
                                    onClick={handlePopUp}
                                    value={rw}
                                    data-option-testid={rw}
                                    key={index}>
                                    {rw}
                                </button>)
                            })}
                        </div>
                        : <button data-clickable-testid="rows" className='pagination-button' onClick={() => handleRowsPerPagePopup()}>
                            {+displayRows.limit}
                            <KeyboardArrowDownIcon />
                        </button>}
                </div>
                {
                    showRecords &&
                    <span className="pagination-rows" data-text-testid="pagination-count">{`${+rowsData.firstNum}-${+rowsData.lastNum} of ${userDataCount}`}</span>
                }
                <KeyboardArrowLeftIcon
                    data-clickable-testid="left-arrow"
                    className={disableLeftArrow ? "disable" : ""}
                    onClick={handlePrevRow} />
                <KeyboardArrowRightIcon
                    data-clickable-testid="right-arrow"
                    className={disableRightArrow ? "disable" : ""}
                    onClick={handleNextRow} />
            </div>
        </div>
    );
}