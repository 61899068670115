import { get , getUrl} from "../../url";

export async function getRequestId(limit,log,nextKey){
    const filter=Object.keys(log).length>0?`&$search_key=${ encodeURIComponent(JSON.stringify(log))}`:"";
    const next=nextKey && Object.keys(nextKey).length>0?`&$key=${encodeURIComponent(JSON.stringify(nextKey))}`:"";
    const uri = getUrl() + `/configurator/v1.0/log/createRequest?limit=${limit}${filter}${next}`;
    const [error,data] = await get(uri);
    const request=JSON.parse(data?.object || "{}");
    return [error, request.requestId];
}

export async function getLogs(requestId){
    const uri = getUrl() + `/configurator/v1.0/log/${requestId}`
    const [error,data] = await get(uri);
    return [error,data];
}

export async function getTypes(){
    const uri = getUrl() + `/configurator/v1.0/log/listType`
    const [error,data] = await get(uri);
    return [error,data];
}