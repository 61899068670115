import adminMessages from "../../../common/validationMessages/administration";

const required = (errors, name, value, msg) => {
    if ([undefined, null, ''].includes(value)) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validateLength = (errors, value) => {
    let booleanValue = required(errors, 'length', value, adminMessages.E1003)
    if (booleanValue) {
        if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'length', "", adminMessages.E1010)
            booleanValue = false;
        }
        else if (value < 6 || value > 10) {
            required(errors, 'length', "", adminMessages.E1004)
            booleanValue = false;
        }
    }
    return booleanValue;
}

const validatevalidity = (errors, value) => {
    let booleanValue = required(errors, 'validity', value, adminMessages.E1003)
    if (booleanValue) {
        if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'validity', "", adminMessages.E1010)
            booleanValue = false;
        } else if (value < 30 || value > 365) {
            required(errors, 'validity', "", adminMessages.E1005)
            booleanValue = false;
        }
    }
    return booleanValue;
}

const validatereuse = (errors, value) => {
    let booleanValue = required(errors, 'reuse', value, adminMessages.E1003)
    if (booleanValue) {
        if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'reuse', "", adminMessages.E1010)
            booleanValue = false;
        }
        else if (value < 2 || value > 5) {
            required(errors, 'reuse', "", adminMessages.E1006)
            booleanValue = false;
        }
    }
    return booleanValue;
}

const validatelockoutThreshold = (errors, value) => {
    let booleanValue = required(errors, 'lockoutThreshold', value, adminMessages.E1003)
    if (booleanValue) {
        if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'lockoutThreshold', "", adminMessages.E1010)
            booleanValue = false;
        } else if (value < 3 || value > 10) {
            required(errors, 'lockoutThreshold', "", adminMessages.E1007)
            booleanValue = false;
        }
    }
    return booleanValue;
}

const validatelockOutTime = (errors, value) => {
    let booleanValue = required(errors, 'lockOutTime', value, adminMessages.E1003)
    if (booleanValue) {
        if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'lockOutTime', "", adminMessages.E1010)
            booleanValue = false;
        }
        else if (value < 1 || value > 24) {
            required(errors, 'lockOutTime', "", adminMessages.E1008)
            booleanValue = false;
        }
    }
    return booleanValue;
}

const validatelockOutWarning = (errors, value) => {
    let booleanValue = required(errors, 'lockOutWarning', value, adminMessages.E1003)
    if (booleanValue) {
        if ((value + "").match(/[^0-9]/g, "")) {
            required(errors, 'lockOutWarning', "", adminMessages.E1010)
            booleanValue = false;
        }
        else if (value > 100) {
            required(errors, 'lockOutWarning', "", adminMessages.E1009)
            booleanValue = false;
        }
    }
    return booleanValue;
}


const validateValue = (errors, name, value) => {
    switch (name) {
        case 'length':
            return validateLength(errors, value)
        case 'validity':
            return validatevalidity(errors, value)
        case 'reuse':
            return validatereuse(errors, value)
        case 'lockoutThreshold':
            return validatelockoutThreshold(errors, value)
        case 'lockOutTime':
            return validatelockOutTime(errors, value)
        case 'lockOutWarning':
            return validatelockOutWarning(errors, value)
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (["lockoutThreshold", "lockOutTime", "lockOutWarning"].includes(name) && values["lockoutThreshold"]) {
            if (!validateValue(errors, name, value))
                valid = false
        } else if ("validity" === name && values["validity"]) {
            if (!validateValue(errors, name, value))
                valid = false
        } else if ("reuse" === name && values["reusePolicy"]) {
            if (!validateValue(errors, name, value))
                valid = false
        } else {
            if (!validateValue(errors, name, value))
                valid = false;
        }
    })
    return valid
}

export { validateValue, validateValues, required, validateLength, validatevalidity, validatereuse, validatelockoutThreshold, validatelockOutTime, validatelockOutWarning }