import React from 'react';
import ReactDOM from 'react-dom';
import Editor from './editor/mxgraph-editor';
import bg from './images/pattern.png';
import { Graph, HoverIcons } from './graph';
import { Lane, Template, Screen, Title, Icon, ScreenSubtitle } from './shape/';
import { processObjects } from './processObjects';
import constants from './util/MX_Constants';
import previewImg from './images/screen-no-preview.svg';
import { ThemeProvider } from '@material-ui/core/styles';
import PillirTheme from '../../pillir-theme';
import GraphTitle from '../../containers/user-task/component/titlebox';
import { alertShow, createAppMenu, getMenuDetails } from '../../helpers/business-function';
import {createPage } from '../../helpers/app-designer';
import { apmMessage } from '../../common/messages/apm';
import NavItem from '../../common/components/NavItem';
import '../../pillir-theme.scss';
import {
  generateUid,
  getPermissions,
  constructProperty,
  decodeProperty,
  getUserPropertyId,
  safelyParseJSON
} from '../../utils/common';
import { SHAPE_TYPES } from './shape/types';
import {
  DOUBLE_SIDE_ARROW,
  SELECTED_EDGE_STYLE,
  OFFLINE_APP_ARROW,
  DEFAULT_INITIAL_STATE,
  DEFAULT_END_STATE,
} from '../../constants/index';
import showEyeIcon from './images/show-eye.svg';
import closeEyeIcon from './images/close-eye.svg';
import {
  runWorkflowValidator,
  searchExisitingWorkflowComponent,
  searchAppFlowBackwardDirection,
  searchWorkFlowOutWordDirection,
  searchAppFlowOutwardDirection,
  checkHasWorkflowComponent,
  checkTargetInBackwardDirection,
  validateFlow,
} from './workflowValidator';
import { configMessage } from '../../common/messages/config';
const WORKFLOW_COLOR = '#62D680';
const BORDER_WIDTH_WORKFLOW_COMP = '2';
const COLLAPSED_LANE_COLOR = '#edf4ff';
const UNSUPPORTED_COMPONENTS = [SHAPE_TYPES.SCREEN, SHAPE_TYPES.TASK];
const CONDITION_YES = 'Yes';
const CONDITION_NO = 'No';
const COMPONENTS = [
  SHAPE_TYPES.SCREEN,
  SHAPE_TYPES.XOR,
  SHAPE_TYPES.START,
  SHAPE_TYPES.END,
  SHAPE_TYPES.TASK,
  SHAPE_TYPES.BOS,
  SHAPE_TYPES.DMN,
];
const MINIMUM_LANE_HEIGHT = 550;
const MAXIMUM_LANE_WIDTH = 4000;

var mxgraph = require('mxgraph')({
  mxImageBasePath: '../images',
  mxBasePath: './',
});
let {
  mxConstants,
  mxClient,
  mxEvent,
  mxConnectionConstraint,
  mxCellState,
  mxPoint,
  mxClipboard,
  mxEventObject,
} = mxgraph;
mxgraph.mxWindow = function () {};

const _isStateArrow = (edge)=>{
  if (edge && edge.children && Array.isArray(edge.children) && edge.children.length > 0){
    return true;
  }
  return false;
}

const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

// Need to move these helper functions to seprate file
const _checkCrossLaneConnection = (graph, edge) => {
  let crossLane = false;
  const sourceLane = graph.getSwimlane(edge.source);
  const targetLane = graph.getSwimlane(edge.target);
  if (sourceLane && targetLane && sourceLane.id !== targetLane.id) {
    crossLane = true;
  }
  return crossLane;
};

const _getValueEdge = (cell) => {
  let stateEdge = cell.source.edges.find((ed) => {
    if (ed.source.id === cell.source.id && ed.target.id === cell.target.id) {
      return true;
    }
    return false;
  });
  return stateEdge;
};

const _getStateEdge = (cell) => {
  let stateEdge = cell.source.edges.find((ed) => {
    if (ed.source.id === cell.source.id && ed.target.id === cell.target.id) {
      return true;
    }
    return false;
  });
  return stateEdge;
};

const _isTransitionArrow = (graph, edge) => {
  let isTransitionArrow = false;
  if (
    edge.target?.type === SHAPE_TYPES.END ||
    edge.source?.type === SHAPE_TYPES.START ||
    (_checkCrossLaneConnection(graph, edge) && edge.source?.executeAsWorkflow)
  ) {
    isTransitionArrow = true;
  }
  return isTransitionArrow;
};

const _canHaveValue = (cell) => {
  if (cell.edge && [SHAPE_TYPES.DMN].indexOf(cell.source.type) > -1) {
    return true;
  }
  return false;
};

function PillirGraph(container, readOnly) {
  if (!mxgraph.mxClient.isBrowserSupported()) {
    // Displays an error message if the browser is not supported.
    mxgraph.mxUtils.error('Browser is not supported!', 200, false);
  } else {
    // Creates the graph inside the given container

    var graph = new Graph(container);
    // Updates the CSS of the background to draw the grid
    var canvas = graph.view.canvas;
    if (canvas && canvas.ownerSVGElement != null) {
      canvas = canvas.ownerSVGElement;
    }
    //this.Init(graph);
    this.createSideBarItem(graph);
    canvas.style.backgroundPosition = '';
    canvas.style.backgroundColor = '#F0F2F7';
    canvas.style.backgroundImage = 'url(' + bg + ')';
    var editor = new Editor(graph);
    editor.setGraphContainer(container);
    editor.swimlaneRequired = true;
    this.graph = graph;
    this.editor = editor;
    //
    this.graph.editor = editor;
    this.mxgraph = mxgraph;
    var h = new HoverIcons(graph);
    this.InitEdgeStyle(graph, container, readOnly);
    this.initEventHandler(graph);
    this.graph.self = this;
    this.groupingInProgress = false;
    this.highlightedCells = [];
    this.initMouseScrollEvent();
    this.isScrolling = '';
  }
}
PillirGraph.prototype.graph = null;
PillirGraph.prototype.mxgraph = null;
PillirGraph.prototype.editor = null;
PillirGraph.prototype.sideBarItems = {};
PillirGraph.prototype.sideBarEnabledTools = {};
PillirGraph.prototype.processObjects = processObjects;
PillirGraph.prototype.permissions = null;
PillirGraph.prototype.stateCell = null;
PillirGraph.prototype.createSnaphshot = function (graph) {
  graph.stopEditing(true);
  graph.selectionModel.clear();
  graph.graphHandler.reset();

  var fo = mxClient.NO_FO;
  var node = null;
  mxClient.NO_FO = fo;
  if (
    graph.dialect == mxConstants.DIALECT_SVG &&
    !mxClient.NO_FO &&
    graph.view.getCanvas().ownerSVGElement != null
  ) {
    node = graph.view.getCanvas().ownerSVGElement.cloneNode(true);
  }
  // LATER: Check if deep clone can be used for quirks if container in DOM
  else {
    node = graph.container.cloneNode(false);
    node.innerHTML = graph.container.innerHTML;

    // Workaround for clipping in older IE versions
    if (mxClient.IS_QUIRKS || document.documentMode == 8) {
      node.firstChild.style.overflow = 'visible';
    }
  }
  mxClient.NO_FO = fo;
  //node.style.transform = 'scale(0.4)';
  //node.setAttribute('width',290);
  //node.setAttribute('height',232);

  return node;
};

PillirGraph.prototype.initMouseScrollEvent = function() {
  let self = this;
  let graphElem = document.querySelector("#divGraph");
  if(mxClient.IS_WIN){
    graphElem.addEventListener('scroll', function ( event ) {
      // Clear our timeout throughout the scroll
      window.clearTimeout( this.isScrolling );
      // Set a timeout to run after scrolling ends
      this.isScrolling = setTimeout(function() {
        let graph = self.graph;
        self.skipAction = true;
        self.isLoadingGraph = true;
        graph.orderCells(null, [graph.getDefaultParent()]);
        self.isLoadingGraph = false;
        self.skipAction = false;
      }, 30);
    }, false);
  }
}

PillirGraph.prototype.focusDMNConnector = (
  i,
  graph,
  row,
  cell,
  self,
  table
) => {
  let connectors = cell?.edges; //children.filter(child => child.type === SHAPE_TYPES.CONNECTOR)
  let e = connectors?.find((c) => c.uid === row.id);
  if (e) {
    // e.style = SELECTED_EDGE_STYLE
    // graph.getModel().setVisible(e, false);
    // graph.getModel().setVisible(e, true);
   // setTimeout(() => {
      // e.style = SELECTED_EDGE_STYLE;
      graph.getModel().setVisible(e, false);
      graph.getModel().setVisible(e, true);
      graph.setSelectionCells([cell]);
   // }, 500);
  } else {
    if (cell) {
      self.editDMNConnector(graph, self, i, table, cell, row);
    }
  }
  graph.setSelectionCells([cell]);
};

PillirGraph.prototype.handleDMNManualArrow = (
  graph,
  edge,
  source,
  isLabel = false,
  canCreateArrow = false
) => {
  let dmnChild = source?.children?.[0]; //icon
  let newRow = {};
  if (dmnChild) {
    let model = graph.getModel();
    let dmnValues = dmnChild?.value || '{}';
    dmnValues = JSON.parse(dmnValues);
    if (!isLabel) {
      let initColumn = {};
      let a = dmnValues?.columns?.map((e) => {
        initColumn = { ...initColumn, [e]: { op: '=', value: '' } };
      });
      newRow = { ...initColumn, output: '', id: edge.uid || generateUid() };
      dmnValues = { ...dmnValues, rows: [...(dmnValues.rows || []), newRow] };
      if (canCreateArrow) {
        setTimeout(() => {
          edge.uid = newRow.id;
          graph.orderCells(null, [edge]);
        }, 100);
      }
    } else {
      let rows =
        dmnValues?.rows?.map((e) => {
          if (e.id === edge.uid) return { ...e, output: edge?.value || '' };
          else return e;
        }) || [];
      dmnValues = { ...dmnValues, rows: rows };
    }
    model.beginUpdate();
    try {
      model.setValue(dmnChild, JSON.stringify(dmnValues));
    } finally {
      model.endUpdate();
    }
  }
};

PillirGraph.prototype.editDMNConnector = (
  graph,
  self,
  rowIndex = '',
  table,
  dmnCell,
  row = {},
  isDelete = false
) => {
  let cell1 = dmnCell.children?.[0]; //icon
  let cell = dmnCell; //dmn;
  let connectors = cell.edges?.filter(
    (e) => e.source && e.source.id === dmnCell.id
  );
  let model = graph.getModel();
  model.beginUpdate();
  try {
    model.setValue(cell1, JSON.stringify(table));
    let existingCell = connectors?.find((e) => row.id === e.uid);
    if (isDelete && existingCell) {
      graph.removeCells([existingCell]);
    } else {
      if (!existingCell && row.id) {
        graph.addEdge(
          self.createConnection(
            rowIndex !== '' ? rowIndex : (connectors || []).length,
            row?.output || '',
            row,
            row.id, 
            {
              xPoint: 200,
            }
          ),
          cell,
          cell,
          null
        );
      } else if (existingCell) {
        model.setValue(existingCell, row.output || '');
      }
    }
  } finally {
    model.endUpdate();
  }
  graph.setSelectionCells([cell]);
  // self.openDMNTable(dmnCell, JSON.stringify(table));
};

PillirGraph.prototype.addDMNConnector = (
  graph,
  self,
  outputs,
  table,
  dmnCell,
  deleteValue = {}
) => {
  let cell1 = dmnCell.children[0]; //icon
  let cell = dmnCell; //dmn
  let connectors = cell.edges;
  let arr = cell.children.filter(
    (child) => child.type === SHAPE_TYPES.CONNECTOR
  );
  if (deleteValue?.output) {
    arr = connectors.filter((e) => e.value === deleteValue?.output);
  }
  // let arr = connectors.map(c => { return c.value })
  let model = graph.getModel();
  model.beginUpdate();
  try {
    model.setValue(cell1, JSON.stringify(table));
    arr.forEach((c) => {
      graph.removeCells([c]);
    });
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    let a = [...outputs];
    let result = a.filter(onlyUnique);
    result.forEach((op, i) => {
      if (op !== '') {
        let exp = table.rows.filter((r) => r.output === op);
        let isAlreadyExist = connectors?.find((e) => op === e.value);
        if (!isAlreadyExist) {
          graph.addEdge(
            self.createConnection(i, op, exp, self.generateUid()),
            cell,
            cell,
            null
          );
        }
      }
    });
  } finally {
    model.endUpdate();
  }
  graph.setSelectionCells([cell]);
  self.openDMNTable(dmnCell, JSON.stringify(table));
};

PillirGraph.prototype.redrawDeletedDMNEdges = function (self, graph, cell) {
  let edges = graph.getModel().getIncomingEdges(cell);
  let edgeWithDmnAsSource = edges.filter(
    (f) => f?.source?.type === SHAPE_TYPES.DMN
  );
  setTimeout(() => {
    if (edgeWithDmnAsSource.length) {
      let table = edgeWithDmnAsSource[0].source.children?.[0].value;
      table = JSON.parse(table || '{}');
      edgeWithDmnAsSource.forEach((edge) => {
        let findRowIndex = table?.rows?.findIndex((e) => e.id === edge.uid);
        let dmnExp = edge.dmnExp || { id: edge.uid, output: edge.value || '' };
        self.focusDMNConnector(
          findRowIndex !== -1 ? findRowIndex : '',
          graph,
          dmnExp,
          edge.source,
          self,
          table
        );
      });
    }
  }, 50);
};

PillirGraph.prototype.validateDMNConditionArrow = function (
  self,
  graph,
  edge,
  source,
  target
) {
  // var self = this;
  let isStateRequired = false;
  let crossLane = _checkCrossLaneConnection(graph, edge);
  // self.handleArrowNodes(graph, edge);
  if (crossLane) {
    //Connectivity in different lane.
    isStateRequired = true; // The arrows connecting between the lanes and coming from a workflow component then this arrow is state transition arrow.
  } else {
    if (!target.executeAsWorkflow && target.type !== SHAPE_TYPES.END) {
      // Target is  app-flow
      let found = searchAppFlowBackwardDirection(graph, source);
      if (found) {
        // Don't allow to add
        if (this.arrowHandled !== edge.uid) {
          this.arrowHandled = edge.uid;
          setTimeout(() => {
            graph.getModel().beginUpdate();
            graph.removeCells([edge]);
            let table = source.children[0].value;
            table = JSON.parse(table || '{}');
            let findRowIndex = table?.rows?.findIndex((e) => e.id === edge.uid);
            self.editDMNConnector(
              graph,
              self,
              findRowIndex,
              table,
              source,
              edge.dmnExp
            );
            this.arrowHandled = '';
            graph.getModel().endUpdate();
            if (self.editor.undoManager.history.length > 0) {
              self.editor.undoManager.history.pop();
              self.editor.undoManager.indexOfNextAdd =
                self.editor.undoManager.indexOfNextAdd - 1;
            }
          }, 100);
        }
        self.showAlert(
          `A non-workflow ${found.value}(${found.type} Component) found in backward direction!`
        );
      } else {
        // Allow to add
        if (UNSUPPORTED_COMPONENTS.indexOf(target.type) === -1) {
          target.executeAsWorkflow = 'yes';
          graph
            .getModel()
            .setStyle(
              target,
              target.getStyle() +
                ';strokeColor=' +
                WORKFLOW_COLOR +
                ';strokeWidth=' +
                BORDER_WIDTH_WORKFLOW_COMP +
                ';'
            );
          graph.getModel().setVisible(target, false);
          graph.getModel().setVisible(target, true);
          setTimeout(() => {
            graph.orderCells(false, [edge.source, target]);
          }, 10);
        }
      }
    }
  }
};

PillirGraph.prototype.createSideBarItem = function (graph) {
  var self = this;
  var getSvgNode = function (cells, width, height) {
    var fo = mxClient.NO_FO;
    mxClient.NO_FO = fo;
    graph.view.scaleAndTranslate(1, 0, 0);
    graph.addCells(cells);
    var bounds = graph.getGraphBounds();
    var s =
      Math.floor(
        Math.min(
          (width - 2 * 1) / bounds.width,
          (height - 2 * 1) / bounds.height
        ) * 100
      ) / 100;
    graph.view.scaleAndTranslate(
      s,
      Math.floor((width - bounds.width * s) / 2 / s - bounds.x),
      Math.floor((height - bounds.height * s) / 2 / s - bounds.y)
    );
    var node = null;

    // For supporting HTML labels in IE9 standards mode the container is cloned instead
    if (
      graph.dialect == mxConstants.DIALECT_SVG &&
      !mxClient.NO_FO &&
      graph.view.getCanvas() &&
      graph.view.getCanvas().ownerSVGElement != null
    ) {
      node = graph.view.getCanvas().ownerSVGElement.cloneNode(true);
    }
    // LATER: Check if deep clone can be used for quirks if container in DOM
    else {
      node = graph.container.cloneNode(false);
      node.innerHTML = graph.container.innerHTML;

      // Workaround for clipping in older IE versions
      if (mxClient.IS_QUIRKS || document.documentMode == 8) {
        node.firstChild.style.overflow = 'visible';
      }
    }

    graph.getModel().clear();
    mxClient.NO_FO = fo;
    node.style.position = 'relative';
    node.style.marginBottom = '8px';
    node.style.overflow = 'hidden';
    node.style.width = width + 'px';
    node.style.height = height + 'px';
    node.style.visibility = '';
    node.style.minWidth = '';
    node.style.minHeight = '';
    return node;
  };

  var initSVG = function (id) {
    var Items = {};
    for (var key in processObjects) {
      // skip loop if the property is from prototype
      if (key !== SHAPE_TYPES.LANE) {
        if (!processObjects.hasOwnProperty(key)) continue;
        var obj = processObjects[key]();

        var getObject = (obj, id, geo) => {
          var Temp = new Template();

          var width = Temp.geometry.width;
          var height = Temp.geometry.height;
          obj.geometry.x = 0;
          obj.geometry.y = 0;
          var style = obj.style;
          if (Temp.geometry.width !== obj.geometry.width) {
            obj.geometry.x = Temp.geometry.width / 2 - obj.geometry.width / 2;
            obj.geometry.y = Temp.geometry.height / 2 - obj.geometry.height / 2;
          } else {
            obj.style = style + 'strokeOpacity=0;';
          }
          Temp.style = Temp.style + 'strokeOpacity=0;';
          Temp.insert(obj);
          var cells = [Temp];

          var node = getSvgNode(cells, width, height);
          node.id = obj.type + '_' + id;
          node.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.11)';
          node.style.background = 'white';
          node.style.borderRadius = '2px';
          obj.style = style;
          if (obj.type === SHAPE_TYPES.SCREEN) {
            // obj = processObjects[key]({deployment:'web'});
            obj.geometry = geo;
          }

          var svg = getSvgNode([obj], obj.geometry.width, obj.geometry.height);
          svg.id = 'svg_' + obj.type + '_' + id;
          //svg.style.display='none';
          obj.node = node;
          obj.svg = svg;
          return obj;
        };

        if (obj.type === SHAPE_TYPES.SCREEN) {
          obj = processObjects[key]({ deployment: 'web' });
          var obj1 = processObjects[key]({ deployment: 'mobile' });

          const geo1 = { ...obj1.geometry };
          const geo = { ...obj.geometry };

          obj1.geometry.height = 44;
          obj1.geometry.width = 24;
          obj1.children = null;

          obj.geometry.height = 70;
          obj.geometry.width = 50;
          obj.children = null;
          Items[key] = [
            getObject(obj, id + '1', geo),
            getObject(obj1, id + '2', geo1),
          ];
        } else {
          Items[key] = getObject(obj, id);
        }
      }
    }
    return Items;
  };

  this.sideBarItems = initSVG('sidebar');
  this.menuItems = initSVG('popoup');
};

PillirGraph.prototype.InitEdgeStyle = function (graph, container, readOnly) {
  var self = this;
  graph.gridSize = 24;
  graph.setPanning(true);
  graph.setTooltips(true);
  graph.setConnectable(true);
  graph.setCellsEditable(true);
  graph.setEnabled(!readOnly);
  graph.setAllowDanglingEdges(false);
  // Enables HTML labels
  graph.setHtmlLabels(true);
  graph.centerZoom = false;
  graph.autoSizeCellsOnAdd = true;

  /*mxClipboard.copy = function (graph, cells) {
    cells = cells || graph.getSelectionCells();
    var result = graph.getExportableCells(cells);

    mxClipboard.parents = new Object();

    for (var i = 0; i < result.length; i++) {
      mxClipboard.parents[i] = graph.model.getParent(cells[i]);
    }

    mxClipboard.insertCount = 1;
    mxClipboard.setCells(graph.cloneCells(result));

    return result;
  };*/

  graph.getStylesheet().putDefaultEdgeStyle(constants.STYLE);
  var style = graph.getStylesheet().getDefaultVertexStyle();
  //fix for custom css into the vertex
  style[mxConstants.STYLE_FONTFAMILY] = 'Saira';
  style[mxConstants.STYLE_FONTSIZE] = '15';
  style[mxConstants.STYLE_FONTCOLOR] = '#000';
  //style[mxConstants.STYLE_SHADOW]="1";
  style[mxConstants.STYLE_PERIMETER_SPACING] = '2';
  graph.getStylesheet().putDefaultVertexStyle(style);
  graph.getAllConnectionConstraints = function (terminal) {
    //Shorthand
    const cConstraints = function (x, y) {
      return new mxConnectionConstraint(new mxPoint(x, y), true);
    };
    if (terminal != null && this.model.isVertex(terminal.cell)) {
      if (terminal.cell.type === SHAPE_TYPES.SCREEN) {
        return [
          cConstraints(0.5, 0),
          cConstraints(0, 0.5),
          cConstraints(1, 0.5),
          cConstraints(0.25, 1),
        ];
        //end bottom
      }

      if (
        terminal.cell.type === SHAPE_TYPES.STARTDEFAULT ||
        terminal.cell.type === SHAPE_TYPES.START
      ) {
        return [cConstraints(1, 0.5)];
      }
      if (terminal.cell.type === SHAPE_TYPES.END || terminal.cell.type === SHAPE_TYPES.ENDDEFAULT) {
        return [];
      }
      if (terminal.cell.type === SHAPE_TYPES.MENU) {
        return [];
      }
      return [
        cConstraints(0.5, 0),
        cConstraints(0, 0.5),
        cConstraints(1, 0.5),
        cConstraints(0.5, 1),
      ];
    }
    return null;
  };

  graph.connectionHandler.addListener(mxEvent.CONNECT, function (sender, evt) {
    var edge = evt.getProperty('cell');
    var source = graph.getModel().getTerminal(edge, true);
    var target = graph.getModel().getTerminal(edge, false);
    var style = graph.getCellStyle(edge);
    edge.type = SHAPE_TYPES.CONNECTOR;
    edge.uid = self.generateUid();

    setTimeout(() => {
      self.skipAction = true;
      self.handleArrowNodes(graph, edge);
      self.skipAction = false;
    }, 0);

    console.log("edge.target", edge.target);

    if (edge && (!edge.target || [SHAPE_TYPES.MENU].indexOf(edge.target?.type) > -1 || (edge.target.type === SHAPE_TYPES.NOTE || edge.target.parent?.type === SHAPE_TYPES.NOTE))) {
      graph.removeCells([edge]);
      validEdge = false;
    }
    let startEgdes = source.edges.filter((e) => e.source.id === source.id);
    let validEdge = true;

    if((source?.id === target?.id || target?.edge) && validEdge){
      self.skipAction = true;
      self.isLoadingGraph = true;
      graph.removeCells([edge]);
      validEdge = false;
      self.isLoadingGraph = false;
      self.skipAction = false;
    }

    if([SHAPE_TYPES.SCREEN, SHAPE_TYPES.TASK].indexOf(source?.type) !== -1 && target?.type){
      let alreadyConnectedEdge = source?.edges?.filter((e) => e.target?.id == edge?.target?.id) || [];
      if (alreadyConnectedEdge.length > 1) {
        self.skipAction = true;
        graph.removeCells([edge]);
        validEdge = false;
        self.skipAction = false;
        self.showAlert(`An arrow is already connected  from ${source.value} to ${target.value}(${target.type})`);
      }
    }

    

    if (self._checkIsWorkflowApp() && edge.target) {
      const errorMsg = validateFlow(graph, edge);
      if (errorMsg) {
        validEdge = false;
        graph.removeCells([edge]);
        self.showAlert(errorMsg);
      }

      /*
        //App component should not have 2 entry points. See eg: Start --> Page_1 ---> BOS_1, BOS_4 --> BOS_1. Single App component has 2 entry points, which wrong
        if (edge.source.executeAsWorkflow && !edge.target.executeAsWorkflow) {
          let parent = edge.target.parent;
          parent.children &&
            parent.children.map((node) => {
              if (!node.executeAsWorkflow) {
                let incomingEdges = graph.getModel().getIncomingEdges(node);
                incomingEdges.map((e) => {
                  if (e.source.executeAsWorkflow && edge.target.id !== node.id) {
                    validEdge = false;
                    graph.removeCells([edge]);
                    self.showAlert(
                      `App component can not have 2 entry points!. You can connect from the Workflow only to the component ${node.value}`
                    );
                  }
                });
              }
            });
        }
        //App component should not have 2 exit points. See eg:
        if (!edge.source.executeAsWorkflow && edge.target.executeAsWorkflow) {
          let parent = edge.source.parent;
          parent.children &&
            parent.children.map((node) => {
              if (!node.executeAsWorkflow) {
                let outgoingEdges = graph.getModel().getOutgoingEdges(node);
                outgoingEdges.map((e) => {
                  if (e.target.executeAsWorkflow && e.id !== edge.id) {
                    validEdge = false;
                    graph.removeCells([edge]);
                    self.showAlert(
                      `App component can not have 2 exit points!. You can connect to Workflow only from the component ${node.value}`
                    );
                  }
                });
              }
            });
        }
      */
      
      self.isLoadingGraph = true;
      if (validEdge) {
        let isStateRequired = false;
        let crossLane = _checkCrossLaneConnection(graph, edge);
        if (crossLane) {
          //Connectivity in different lane.
          // The arrows connecting between the lanes and coming from a workflow component then this arrow is state transition arrow.
          if (edge.source.executeAsWorkflow) {
            isStateRequired = true;
            if (source.type === SHAPE_TYPES.DMN) {
              self.handleDMNManualArrow(graph, edge, source);
            }
          } else if (
            [SHAPE_TYPES.START, SHAPE_TYPES.END].indexOf(edge.target.type) ===
            -1
          ) {
            graph.removeCells([edge]);
            validEdge = false;
            self.showAlert(
              `Step transition can only be done from a workflow component!`
            );
          }
        } else {
          // Connectivity with-in same lane

          if (source && target) {
            if (!source.executeAsWorkflow) {
              // Source is  app-flow
              if (source.type !== SHAPE_TYPES.START) {
                // If source is not start

                if (!target.executeAsWorkflow) {
                  // Target is  app-flow
                  // Simply allow
                } else {
                  // Target is  work-flow
                  let found = checkHasWorkflowComponent(
                    graph,
                    source,
                    target.id
                  );
                  if (found) {
                    // Don't allow to add
                    graph.removeCells([edge]);
                    validEdge = false;
                    self.showAlert(
                      `A non-workflow ${found.value}(${found.type} Component) found in outward direction!`
                    );
                  }
                }
              }
            } else {
              // Source is  work-flow
              if (!target.executeAsWorkflow) {
                // Target is  app-flow
                if (target.type !== SHAPE_TYPES.END) {
                  let found = searchAppFlowBackwardDirection(graph, source);
                  const targetInReverse = checkTargetInBackwardDirection(
                    graph,
                    source,
                    target
                  );
                  if (found && !targetInReverse) {
                    // Don't allow to add
                    graph.removeCells([edge]);
                    validEdge = false;
                    self.showAlert(
                      `A non-workflow ${found.value}(${found.type} Component) found in backward direction!`
                    );
                  } else {
                    // Allow to add
                    if (UNSUPPORTED_COMPONENTS.indexOf(target.type) === -1) {
                      const outgoingEdges = graph.getModel().getOutgoingEdges(target);
                      const appFlows = outgoingEdges.filter(e => (e.target && !e.target.hasOwnProperty("executeAsWorkflow")));
                      if (appFlows.length < 2){
                        target.executeAsWorkflow = 'yes';
                        graph
                          .getModel()
                          .setStyle(
                            target,
                            target.getStyle() +
                            ';strokeColor=' +
                            WORKFLOW_COLOR +
                            ';strokeWidth=' +
                            BORDER_WIDTH_WORKFLOW_COMP +
                            ';'
                          );
                          
                        // Workflow edge color: 
                        graph.getModel().getIncomingEdges(target).forEach((ie) => {
                          graph.getModel().setStyle(ie, mxgraph.mxUtils.setStyle(ie.getStyle(), 'strokeColor', WORKFLOW_COLOR));
                        })
                      }
                      
                    }
                    if (source.type === SHAPE_TYPES.DMN) {
                      self.handleDMNManualArrow(graph, edge, source);
                    }
                  }
                } else {
                  // simply allow....
                  if (source.type === SHAPE_TYPES.DMN) {
                    self.handleDMNManualArrow(graph, edge, source);
                  }
                }
              } else {
                // Target is  work-flow
                // simply allow....

                // Workflow edge color: 
                graph.getModel().getIncomingEdges(target).forEach((ie) => {
                  let strokeColor = (ie.source?.executeAsWorkflow || _isStateArrow(ie)) ? WORKFLOW_COLOR : null;
                  graph.getModel().setStyle(ie, mxgraph.mxUtils.setStyle(ie.getStyle(), 'strokeColor', strokeColor));
                })

                if (source.type === SHAPE_TYPES.DMN) {
                  self.handleDMNManualArrow(graph, edge, source);
                }
              }
            }
          }
        }

        if (
          target.type === SHAPE_TYPES.END ||
          source.type === SHAPE_TYPES.START
        ) {
          // The arrows connecting to end/start component should have the state transition
          isStateRequired = true;
        }

        if (isStateRequired && validEdge) {
          let valueEdge = null;
          graph
            .getModel()
            .setStyle(
              edge,
              edge.getStyle() +
                ';strokeColor=' +
                WORKFLOW_COLOR +
                ';strokeWidth=' +
                BORDER_WIDTH_WORKFLOW_COMP +
                ';'
            );

          //Set state transition default value
          if (source.type === SHAPE_TYPES.START) {
            valueEdge = DEFAULT_INITIAL_STATE;
          } else if (target.type === SHAPE_TYPES.END) {
            valueEdge = DEFAULT_END_STATE;
          }
          if (valueEdge) {
            self.addStateLabel(edge, valueEdge);
          }
          //Open state-sidebar
          self.toggleStateSideBar(edge);
        }
        // if(validEdge){
        //   self.paintEdge(edge);
        // }
      }
      self.isLoadingGraph = false;
    }

    if (
      source != null &&
      target != null &&
      source.type == SHAPE_TYPES.SCREEN &&
      validEdge
    ) {
      let style = edge.getStyle();
      let isBottomConnection = getScreenBottomPoint(style);
      if (target.type && target.type == SHAPE_TYPES.TASK) {
        if (isBottomConnection) {
          graph.removeCells([edge]);
        }
      }

      if (
        !!isBottomConnection &&
        target.type &&
        target.type === SHAPE_TYPES.BOS &&
        target.edges.length > 1
      ) {
        graph.removeCells([edge]);
        return;
      }
      if(!edge.isHierarchy ){
        if(!edge.style.includes("dashed=1;")){
          edge.style += ((edge.style.endsWith(";") ? '' : ';') + "dashed=1;");
          edge.transitions = [];
        }
      }
      if (target.type && target.type == SHAPE_TYPES.BOS) {
        if (!isCellEdgeHeirarchical(target)) {
          let edge = target.edges[0];
          if (isBottomConnection) {
            edge.isCellEdgeHeirarchical = true;
            edge.isHierarchy = true;
            target.connectable = false;
            source.isLockedAddHierarchy = false;
            try {
              self.skipAction = true;
              graph.getModel().setVisible(source, true);
              self.isLoadingGraph = true;
              self.toggleEyeIcon(
                source,
                source.children.find((c) => c.type === SHAPE_TYPES.EYE)
              );
              self.updateEdgesinUI(edge);
              self.arrangeScreen(source, null, null, true);
              self.isLoadingGraph = false;
              self.skipAction = false;
            } catch (err) {}

            // self.arrangeScreen(source, edge, true);
          }
        }
      }
    }
    return null;
  });

  let isCellEdgeHeirarchical = function (cell) {
    if (
      cell != undefined &&
      cell.edges != undefined &&
      cell.edges != null &&
      cell.edges[0] != undefined &&
      cell.edges[0] != null &&
      !cell.edges[0].isHierarchy
    ) {
      return false;
    }
    return true;
  };

  var getScreenBottomPoint = function (style) {
    try {
      var split = style.split(';');
      let count = 0;
      for (var a = 0; a < split.length; a++) {
        if (split[a] == 'exitX=0.25') {
          count++;
        }
        if (split[a] == 'exitX=0.50') {
          count++;
        } else if (split[a] == 'exitY=1') {
          count++;
        } else if (split[a] == 'exitDx=0') {
          count++;
        } else if (split[a] == 'exitDy=0') {
          count++;
        }
      }
      if (count == 4) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
  graph.connectionHandler.validateConnection = function (source, target) {
    var self = this;
    if (
      source != null &&
      target != null &&
      source.id !== target.id &&
      source.type &&
      target.type
    ) {
      if(target.type === SHAPE_TYPES.END && graph?.self?.isLoginMicroApp?.()){
        this.reset();
      }
      if (
        source.type &&
        ([SHAPE_TYPES.BOS,SHAPE_TYPES.TASK, SHAPE_TYPES.EMAIL, SHAPE_TYPES.ASSIGNMENT].includes(source.type)) &&
        !!source.edges
      ) {
        let outputEdges = source.edges.filter((e) => e.source.id == source.id);

        if (outputEdges.length >= 1) {
          this.reset();
        }
      }
      if (source.type && source.type === SHAPE_TYPES.XOR && !!source.edges) {
        let outputEdges = this.graph.getOutgoingEdges(source);
        if (outputEdges.length >= 2) {
          this.reset();
        }
      }
      if (target && target.type && target.type === SHAPE_TYPES.NOTE) {
        // this.reset();
      } else if (
        target &&
        target.type &&
        target.type.startsWith(SHAPE_TYPES.START)
      ) {
        this.reset();
      } else if (
        target &&
        target.type &&
        target.type === SHAPE_TYPES.BOS &&
        source.type === SHAPE_TYPES.SCREEN
      ) {
        if (
          target.edges &&
          target.edges.length &&
          target.edges[0] &&
          target.edges[0].isHierarchy
        ) {
          this.reset();
        }
      } else if (source && source.type && source.type === SHAPE_TYPES.BOS) {
        if (source.edges && source.edges.length) {
          setTimeout(() => {
            var count = 0;
            source.edges.map((e) => {
              if (e.source.id === source.id) {
                count++;
              }
            });
            // console.log(count);
            if (count >= 1) self.reset();
          }, 200);
        }
      }

      //Commenting the validation for DMN as it can't be executed in some scenario's like WF -> AF -> WF

      // if(source && target && target.type && source.type && source.type === SHAPE_TYPES.DMN) {
      //   if(target.type && [SHAPE_TYPES.SCREEN, SHAPE_TYPES.TASK].includes(target.type)){
      //     this.reset();
      //   } else if(target.type && target.type !== SHAPE_TYPES.END && target.executeAsWorkflow && target.executeAsWorkflow !== "yes"){
      //     this.reset();
      //   }
      // }
    }
  };

  graph.connectionHandler.createEdgeState = function (me) {
    if (
      me &&
      me.sourceState &&
      me.sourceState.cell &&
      me.sourceState.cell.type &&
      me.sourceState.cell.type.startsWith(SHAPE_TYPES.START)
    ) {
      if (me.sourceState.cell.type.indexOf(SHAPE_TYPES.ICON) !== -1) {
        if (me.sourceState.cell.getParent().getEdgeCount() >= 1) {
          this.reset();
        }
      } else {
        let count = me.sourceState.cell.getEdgeCount();
        let hierarchicalCount = me.sourceState.cell.edges?.filter(
          (e) => e.isHierarchy
        );
        count = count - (hierarchicalCount?.length || 0);
        if (count >= 1) {
          this.reset();
        }
      }
    }
    if (
      me &&
      me.sourceState &&
      me.sourceState.cell &&
      me.sourceState.cell.type &&
      me.sourceState.cell.type.startsWith(SHAPE_TYPES.END)
    ) {
      this.reset();
    }
    if (me && me.sourceState) {
      var edge = graph.createEdge(
        null,
        null,
        '  ',
        null,
        null,
        'edgeStyle=orthogonalEdgeStyle;orthogonalLoop=1;jettySize=auto;html=1;movable=0;rounded=0;'
      );
      return new mxCellState(
        this.graph.view,
        edge,
        this.graph.getCellStyle(edge)
      );
    } else {
      this.reset();
    }
  };

  const validateEdgesAndRemoveEye = (graph, screenCell) => {
    if (!screenCell.getHierarchyEdgeCount()) {
      let eyeIcon = screenCell.children.find((c) => c.type == SHAPE_TYPES.EYE);
      graph.removeCells([eyeIcon]);
    }
  };

  var createPopupMenu = (graph, menu, cell, evt) => {
    var connectVertex = function (
      menuItemCell,
      menu,
      item,
      edgeStyle,
      success
    ) {
      if (
        menuItemCell.connectable &&
        !menuItemCell.type.startsWith(SHAPE_TYPES.START)
      ) {
        menu.addItem(item.label, menuItemCell.node, function () {
          var cells = processObjects[item.type]();
          cells.value = self.getDefaultName(cells);
          if (item.type === SHAPE_TYPES.SCREEN) {
            let screen = new Screen({
              content: previewImg,
              name: ' ',
              deployment: self.getDeploymentPlatform(),
            });
            screen.value = self.getDefaultName(screen);
            cells = screen;
          }
          if (edgeStyle) {
            cells.isScreenHierarchycell = true;
            cells.connectable = false;
          }
          var result = graph.selectCellsForConnectVertex(
            graph.connectVertexPillir(
              cell,
              evt.dir,
              graph.defaultEdgeLength,
              evt,
              cells,
              null,
              null,
              edgeStyle
            ),
            evt,
            this
          );
          result[0].uid = self.generateUid();
          if(cell.type === SHAPE_TYPES.SCREEN){
            self.skipAction = true;
            graph.getModel().setStyle(result[0], result[0].style + "dashed=1;");
            graph.getModel().setVisible(result[0], false);
            graph.getModel().setVisible(result[0], true);
            self.skipAction = false;
          }
          if (self._checkIsWorkflowApp()) {
            if (cell.executeAsWorkflow && cell.executeAsWorkflow === 'yes') {
              if (UNSUPPORTED_COMPONENTS.indexOf(result[1].type) > -1) {
                let found = searchAppFlowBackwardDirection(graph, cell);
                if (found) {
                  // Don't allow to add
                  graph.removeCells([result[1]]);
                  self.showAlert(
                    `A non-workflow ${found.value}(${found.type} Component) found in backward direction!`
                  );
                } else if (cell.type === SHAPE_TYPES.DMN) {
                  self.handleDMNManualArrow(
                    graph,
                    result?.[0] || {},
                    cell,
                    false,
                    true
                  );
                }
              } else {
                result[1].executeAsWorkflow = 'yes';
                graph
                  .getModel()
                  .setStyle(
                    result[1],
                    result[1].getStyle() +
                      ';strokeColor=' +
                      WORKFLOW_COLOR +
                      ';strokeWidth=' +
                      BORDER_WIDTH_WORKFLOW_COMP +
                      ';'
                  );
                if (cell.type === SHAPE_TYPES.DMN) {
                  self.handleDMNManualArrow(
                    graph,
                    result?.[0] || {},
                    cell,
                    false,
                    true
                  );
                }
              }
            } else if (result[1].executeAsWorkflow) {
              let found = checkHasWorkflowComponent(graph, cell, result[1].id);
              if (found) {
                graph.removeCells([result[1]]);
                self.showAlert(
                  `An existing Workflow component found in outward direction!`
                );
              }
            }

            if (cell.type === SHAPE_TYPES.START) {
              // Edges going out from start should have dtaft state
              cell.edges.map((ed) => {
                if (ed.source.id === cell.id) {
                  /*let valueEdge = null;
                  if (_canHaveValue(ed)) {
                    //Add addition background arrow (valueEdge) to have additional "value" other than "state"
                    ed.uid = generateUid();
                    valueEdge = ed.clone();
                    valueEdge.uid = `${ed.uid}_${self.generateUid()}`;
                    graph.addEdge(valueEdge, ed.parent, ed.source, ed.target);
                  }*/

                  //Set state transition color
                  //let fontColorStyle = `${mxConstants.STYLE_FONTCOLOR}=${WORKFLOW_COLOR}`;
                  graph
                    .getModel()
                    .setStyle(
                      ed,
                      ed.getStyle() +
                        ';strokeColor=' +
                        WORKFLOW_COLOR +
                        ';strokeWidth=' +
                        BORDER_WIDTH_WORKFLOW_COMP +
                        ';'
                    );
                  /*if(valueEdge){
                    graph.getModel().setStyle(valueEdge, valueEdge.getStyle() + ';strokeColor=' + WORKFLOW_COLOR + ';strokeWidth=' + BORDER_WIDTH_WORKFLOW_COMP + ';verticalLabelPosition=bottom;verticalAlign=top;labelPosition=left;align=left');
                  }*/
                  self.addStateLabel(ed, DEFAULT_INITIAL_STATE);
                  //Set state transition default value
                  //ed.setValue(DEFAULT_INITIAL_STATE);
                }
              });
            }
          }

          if (result[1] && result[1].type !== SHAPE_TYPES.XOR) {
            graph.startEditingAtCell(result[1], evt);
          }
          if (success) success(result);
        });
      }
    };
    /**
     * FIXME:
     * have to check the cell length as we are passing array in case of right click
     * with mulitple cell selections.
     */
    if (
      cell &&
      !!Array.isArray(cell) &&
      cell.length > 1 &&
      self.type === 'businessFunction' &&
      evt.evt.shiftKey
    ) {
      // menu.addItem('Create Group', null, function () {
      //   self.groupTask(evt.evt);
      // });
    }
    let cells = graph.getSelectionCells();
    let me = evt.evt || evt;
    if (
      cells &&
      !!Array.isArray(cells) &&
      cells.length &&
      self.type === 'businessFunction' &&
      me.shiftKey
    ) {
      let isStartEndConsiderd = cells.find(
        (c) =>
          [
            SHAPE_TYPES.START,
            SHAPE_TYPES.STARTDEFAULT,
            SHAPE_TYPES.NOTE,
            SHAPE_TYPES.DMN,
            SHAPE_TYPES.TASK,
            SHAPE_TYPES.ENDDEFAULT,
          ].includes(c.type) || c.executeAsWorkflow || c.isOfflineBOS
      );
      if (!isStartEndConsiderd) {
        menu.addItem('Create Group', null, function () {
          self.groupTask(me);
        });
      }
    }
    if (
      cell &&
      self._checkIsWorkflowApp() &&
      (cell.type === SHAPE_TYPES.LANE || cell.id == graph.getDefaultParent().id)
    ) {
      //let isMainLane=(self.lanes[0].name==cell.Lname);
      let model = self.graph.getModel();
      if (!model.isCollapsed(cell)) {
        let parent = self.graph.getDefaultParent();
        let allLanes = parent?.children?.filter((f) => f.isLane) || [];
        let expandedLanes = 0,
          collapsedLanes = 0;
        allLanes.forEach((f) => {
          if (model.isCollapsed(f)) collapsedLanes++;
          if (!model.isCollapsed(f)) expandedLanes++;
        });
        if (expandedLanes > 1) {
          menu.addItem('Collapse All', null, async function () {
            self.collapseLanes(cell, evt, true);
          });
        }
        if (collapsedLanes) {
          menu.addItem('Expand All', null, async function () {
            self.expandLanes(cell, evt, true);
          });
        }
      }
    } else if (cell) {
      let isBottomConnection = false;
      if (cell.source && cell.source.type == SHAPE_TYPES.SCREEN) {
        isBottomConnection = getScreenBottomPoint(cell.getStyle());
      }

      if (cell.source && cell.source.type == SHAPE_TYPES.START) {
        isBottomConnection = getScreenBottomPoint(cell.getStyle());
      }
      if (cell.edge === true && (!cell.isHierarchy || 
        (cell.isHierarchy && cell.target?.type === SHAPE_TYPES.BOS))) {
        if (
          (!isBottomConnection || _checkCrossLaneConnection(graph, cell)) &&
          [SHAPE_TYPES.XOR, SHAPE_TYPES.CASE, SHAPE_TYPES.MENU].indexOf(cell.source.type) === -1
        ) {
          menu.addItem('Delete connection', null, function () {
            let cells = [cell];

            if (cell?.source?.type === SHAPE_TYPES.DMN) {
              self.handleDMNRowDelete(cell);
            }
            graph.removeCells(cells);
            
            if(cell.isHierarchy && cell.target?.type === SHAPE_TYPES.BOS){
              cell.target.connectable = true; // On deleting connection for an Invocational BOS, the BOS should be allowed to connect
            }
            mxEvent.consume(evt);
          });
        }
        if (cell.source && cell.target && !cell.isHierarchy) {
          if (cell.geometry?.points && cell.geometry.points.length > 0) {
            menu.addItem('Reset line', null, function () {
              self.handleArrowBends(self.graph, [cell]);
              mxEvent.consume(evt);
            });
          }
        }
        if (self._checkIsWorkflowApp()) {
          if (_isTransitionArrow(graph, cell)) {
            // Check if the arrow can have state
            menu.addItem('Add State', null, function () {
              //const stateEdge = _getStateEdge(cell);
              self.toggleStateSideBar(cell);
              mxEvent.consume(evt);
            });
          }
        }
      } else if (cell.type === 'moreOptionsIcon') {
        if (cell.parent.type === 'ServiceTask') {
          let ServiceTaskActions = [
            {
              label: 'Open',
              onClick: (params) => {
                self.navToBuilder(params);
              },
            },
            {
              label: configMessage.T4722,
              onClick: (params) => {
                // Open builder in new tab
                self.openIntoNewTab(params);
              },
            },
          ];
          ServiceTaskActions.map((item) => {
            return menu.addItem(item.label, null, function () {
              var c = cell.getParent();
              var a = cell.parent.value;
              var b = a.split('>');
              if (b[1] != undefined) {
                var val = b[1].split('<');
                a = val[0];
              }
              if (cell.parent.type === 'ServiceTask' && a.trim().length !== 0) {
                item.onClick({
                  uid: cell.parent.isReference
                    ? cell.parent.refuid
                    : cell.parent.uid,
                  label: a,
                  isOfflineBOS: cell.parent?.isOfflineBOS || false
                });
              } else {
                self.showAlert('Invalid Name');
              }
              mxEvent.consume(evt);
            });
          });            
        }
        if (cell.parent.type === SHAPE_TYPES.ACTIVITY) {
          let msg = 'Link To Function';
          if (cell.parent.link) msg = 'Update Link';
          menu.addItem(msg, null, function () {
            if (cell.parent.type === SHAPE_TYPES.ACTIVITY) {
              self.updateLinkToBusinessFunction(cell.parent);
            }
            mxEvent.consume(evt);
          });
        }
        if (cell.parent.type === SHAPE_TYPES.TASK) {
          if (cell.parent.childTask && cell.parent.childTask.length > 2) {
            menu.addItem('Ungroup', null, function () {
              mxEvent.consume(evt);
              self.unGroupTask(cell.parent, cell.parent.childTask);
            });
          }
        }
        if (
          cell.parent.type !== SHAPE_TYPES.SCRIPT &&
          !cell.parent.isReference
        ) {
          menu.addItem('Copy', null, function () {
            if (cell.type === 'moreOptionsIcon') {
              graph.setSelectionCell(cell.parent);
            }
            if(cell.parent.type===SHAPE_TYPES.SCREEN){
              let screenCell=cell.parent;
              self.hideScreenHierachy(screenCell);
            }
            if (cell.parent.type !== 'Script') {
              self.editor.execute('copy');
              self.editor.execute('paste');
            }
            mxEvent.consume(evt);
          });
          /*
          //Reverting create Reference
          if (
            cell.parent.type === SHAPE_TYPES.BOS ||
            cell.parent.type === SHAPE_TYPES.SCREEN
          ) {
            menu.addItem('Create Reference', null, function () {
              if (cell.type === 'moreOptionsIcon') {
                graph.setSelectionCell(cell.parent);
              }
              self.editor.execute('copy', { isReference: true });
              self.editor.execute('paste');
              mxEvent.consume(evt);
            });
          }*/
        }
        if (!cell.parent.isOfflineBOS) {
          menu.addItem('Delete', null, async function () {
            if (
              [
                SHAPE_TYPES.DMN,
                SHAPE_TYPES.XOR,
                SHAPE_TYPES.SCREEN,
                SHAPE_TYPES.BOS,
                SHAPE_TYPES.TASK,
              ].includes(cell.parent.type)
            ) {
              self.redrawDeletedDMNEdges(self, graph, cell.parent);
            }
            if (cell.type === 'moreOptionsIcon') {
              graph.setSelectionCell(cell.parent);
            }
            if (cell.parent.type == SHAPE_TYPES.SCRIPT && cell.parent.pageId) {
              let result = await self.deleteScript(
                cell.parent.value,
                cell.parent.pageId
              );
              if (result) {
                self.editor.execute('delete');
                mxEvent.consume(evt);
                self.afterObjectDeleted(cell.parent);
              }
            } else if (cell.parent.type == SHAPE_TYPES.SCREEN) {
              /*let getPage = await self.screens.find(
                (e) => e.id === cell.parent.value && e.uid === cell.parent.uid
              );

              if (getPage) {
                let c = cell.parent;
                let result = await self.deleteScreen(c.value, c.uid);
                if (result) {
                  graph.getModel().beginUpdate();
                  cell.parent.edges &&
                    cell.parent.edges.map((e) => {
                      if (e.isHierarchy && e.target) {
                        graph.removeCells([e.target]);
                      }
                    });
                  self.hideScreenHierachy(cell.parent);
                  self.editor.execute('delete');
                  mxEvent.consume(evt);
                  graph.getModel().endUpdate();
                  self.afterObjectDeleted(cell.parent);
                }
                
              } else {
                self.editor.execute('delete');
                mxEvent.consume(evt);
                self.afterObjectDeleted(cell.parent);
              }*/

                self.hideScreenHierachy(cell.parent);
                let node=[];
                graph.getModel().beginUpdate();
                cell.parent.edges && cell.parent.edges.map((e) => {
                      if (e.isHierarchy && e.target) {
                        node.push(e.target);
                      }
                });
                if(node.length>0){  
                  graph.removeCells(node);
                }
                self.editor.execute('delete');
                mxEvent.consume(evt);
                graph.getModel().endUpdate();
                self.afterObjectDeleted(cell.parent);
            } else {
              self.editor.execute('delete');
              mxEvent.consume(evt);
              self.afterObjectDeleted(cell.parent);
            }
            if (
              !![SHAPE_TYPES.SCRIPT, SHAPE_TYPES.BOS].includes(
                cell.parent.type
              ) &&
              !!cell.parent.edges &&
              !!cell.parent.edges.length
            ) {
              let screenCell = cell.parent.edges[0].source;
              validateEdgesAndRemoveEye(graph, screenCell);
            }
          });
        }
        if (cell.parent.type === "ServiceTask") {
          if((cell.parent?.executeAsWorkflow==="yes") || self._checkIsOffline()) {
            menu.addItem("Unmark LRT", null, function () {}, null, null, false, false)
          }else{
            let serviceData = {}
            if(typeof cell.parent.data === "string") {
              serviceData = JSON.parse(cell.parent.data || JSON.stringify({}))
            } else {
              serviceData = cell.parent?.data || {};
            }
            menu.addItem(serviceData?.isLongRunningTask?"Unmark LRT":"Mark as LRT", null, function () {
              cell.parent.data =  JSON.stringify({isLongRunningTask: !serviceData?.isLongRunningTask});
              graph.getModel().setValue(cell.parent, cell.parent.value)
            })
          }
        }

        self.graph.setSelectionCells([cell.parent]);
      } else if (
        cell.type === SHAPE_TYPES.ICON &&
        cell.parent.type === SHAPE_TYPES.XOR
      ) {
      } else {
        if (evt.dir) {
          for (let i = 0; i < this.sideBarEnabledTools.length; i++) {
            let item = this.sideBarEnabledTools[i];
            var menuItemCell = this.menuItems[item.type];

            if (item.type === SHAPE_TYPES.SCREEN) {
              var deploy = this.getDeploymentPlatform() === 'web' ? 0 : 1;
              menuItemCell = this.menuItems[item.type][deploy];
            } else {
              menuItemCell = this.menuItems[item.type];
            }
            var x = self.toJSON();
            let flag = true;
            if (item.type === SHAPE_TYPES.END) {
              x.graph.lanes.forEach((lane) => {
                lane.children.forEach((child) => {
                  if (child.type === item.type) flag = false;
                });
              });
            }
            if (flag) {
              switch (cell.type) {
                case SHAPE_TYPES.SCREEN:
                  if (
                    evt.dir == 'south' &&
                    menuItemCell.type === SHAPE_TYPES.BOS
                  ) {
                    connectVertex(
                      menuItemCell,
                      menu,
                      item,
                      DOUBLE_SIDE_ARROW,
                      function (r) {
                        let edge = r[0];
                        let bos = r[1];
                        bos.connectable = false;
                        self.graph.getModel().setVisible(bos, true);
                        // Add show icon if not available on attaching BOS from bottom of screen
                        if (
                          !cell.children.find((c) => c.type == SHAPE_TYPES.EYE)
                        ) {
                          let showeye = new Icon(
                            new mxgraph.mxGeometry(
                              10,
                              cell.geometry.height + 12,
                              16,
                              10
                            ),
                            null,
                            showEyeIcon
                          );
                          showeye.type = SHAPE_TYPES.EYE;
                          showeye.parent = cell;
                          cell.isLockedAddHierarchy = true;
                          cell.children.push(showeye);
                        }
                        self.skipAction = true;
                        self.arrangeScreen(cell, edge);
                        self.skipAction = false;
                      }
                    );
                  } else if (evt.dir !== 'south') {
                    connectVertex(menuItemCell, menu, item);
                  }
                  break;
                default:
                  connectVertex(menuItemCell, menu, item);
                  break;
              }
            }
          }
        }
      }

      /*
        Any component in the canvas except Screen can be added to the Workflow Execution. On right-clicking on the component, the option of Execute in Workflow will be shown. So after selecting the option, the subsequent components becomes part of workflow. (eg. From the Dia 1.1 user has enabled the first condition component to execute in workflow and if you see Dia 1.2 the subsequent component turned to workflow)
      */
      if (self._checkIsWorkflowApp() && !evt.dir) {
        if (
          cell.type === SHAPE_TYPES.MORE_OPTIONS_ICON &&
          [
            SHAPE_TYPES.SCREEN,
            SHAPE_TYPES.TASK,
            SHAPE_TYPES.END,
            SHAPE_TYPES.CONNECTOR,
            SHAPE_TYPES.ICON,
            SHAPE_TYPES.TABLE_ICON,
            SHAPE_TYPES.DMN,
            SHAPE_TYPES.STATE_LABEL,
            SHAPE_TYPES.ASSIGNMENT,
            SHAPE_TYPES.EMAIL
          ].indexOf(cell.parent.type) === -1 &&
          !cell.parent.isEdge() &&
          !cell.parent.isHierarchy
        ) {
          let parentCell = cell.parent;
          if (
            parentCell.executeAsWorkflow &&
            parentCell.executeAsWorkflow === 'yes'
          ) {
            menu.addItem('Remove from Workflow', null, function () {
              // alert("Remove from Workflow");
              let errorMsg = `Can't remove from workflow as it's connected with some workflow component!"`;
              const _checkCanRemoveFromWFC = () => {
                let canDelete = true;
                let a = parentCell.edges?.map((edge) => {
                  if (canDelete && edge.target) {
                    if (
                      [SHAPE_TYPES.START, SHAPE_TYPES.END].indexOf(
                        edge.target.type
                      ) === -1 &&
                      _checkCrossLaneConnection(graph, edge) &&
                      edge.uid
                    ) {
                      canDelete = false;
                      errorMsg = `Can't remove from workflow as it's one of the outgoing edge is step transition arrow!"`;
                    } else {
                      // Check if there is any pervious step is workflow then down allow
                      if (edge.target.id === parentCell.id) {
                        // Incoming edge
                        let prevStep = null;
                        prevStep = edge.source;
                        if (
                          prevStep &&
                          prevStep.executeAsWorkflow &&
                          prevStep.executeAsWorkflow === 'yes' &&
                          prevStep.type !== SHAPE_TYPES.START
                        ) {
                          canDelete = false;
                        }
                      } else {
                        // Outgoing edge
                        // Reverse checking of "Connect an app flow to the workflow having app flow in outgoing connection"
                        if (edge.target.executeAsWorkflow) {
                          let found = searchAppFlowOutwardDirection(
                            graph,
                            edge.target
                          );
                          if (found) {
                            // Don't allow to add
                            canDelete = false;
                            errorMsg = `Can't remove, a non-workflow ${found.value}(${found.type} Component found in outward direction for a connected work flow component!`;
                          }
                        }
                      }
                    }
                  }
                });
                return canDelete;
              };

              if (_checkCanRemoveFromWFC()) { 
                graph.getModel().beginUpdate();
                // Workflow edge color: Reset color of all arrows as the cell is no longer workflow
                graph.getModel().getEdges(parentCell).forEach((ie)=>{
                  if (!_isStateArrow(ie)){
                    graph.getModel().setStyle(ie, mxgraph.mxUtils.setStyle(ie.getStyle(), 'strokeColor', null)); 
                  }
                })

                delete parentCell.executeAsWorkflow; 
                let defaultColor = '';
                parentCell
                  .getStyle()
                  .split(';')
                  .map((style) => {
                    if (!defaultColor) {
                      let [k, v] = style.split('=');
                      if (k === 'strokeColor') {
                        defaultColor = v.trim();
                      }
                    }
                  });
                graph
                  .getModel()
                  .setStyle(
                    parentCell,
                    parentCell.getStyle() + ';strokeColor=' + defaultColor + ';'
                  );
                graph.getModel().endUpdate();

                let json = self.toJSON();
                self.saveSnapshot(json, false, true);
                
              } else {
                self.showAlert(errorMsg);
              }
              mxEvent.consume(evt);
            });
          } else if (
            parentCell.type &&
            !['Lane', 'LaneName'].includes(parentCell.type)
          ) {
            menu.addItem('Execute in Workflow', null, function () {
              if(parentCell.type === "ServiceTask") {
                parentCell.data = JSON.stringify({})
              }
              let checkFlag = true;

              let hasInvalidComponnets = false;
              let subsequentComponents = [];
              // const laneId = cell.parent.id;
              const laneInfo = graph.getSwimlane(
                graph.getModel().getCell(parentCell.id)
              );

              let parentLane = null;
              if (laneInfo) {
                parentLane = graph
                  .getChildVertices()
                  .find((l) => l.id === laneInfo.id);
                let errorMsg = `Invalid component found (${UNSUPPORTED_COMPONENTS.join(
                  ', '
                )}) in subsequent route.`;
                let findConnectors = (cellId) => {
                  let cellObj = graph.getModel().getCell(cellId);
                  if (
                    [
                      SHAPE_TYPES.END,
                      SHAPE_TYPES.CONNECTOR,
                      ...UNSUPPORTED_COMPONENTS,
                    ].indexOf(cellObj.type) === -1
                  ) {
                    subsequentComponents.push(cellObj);
                  }
                  if (checkFlag) {
                    if (UNSUPPORTED_COMPONENTS.indexOf(cellObj.type) > -1) {
                      let found = searchWorkFlowOutWordDirection(
                        graph,
                        cellObj
                      );
                      if (!found) {
                        hasInvalidComponnets = true;
                      }
                    } else {
                      /*
                      let outgoingEdges =  graph.getOutgoingEdges(cellObj)
                      outgoingEdges.map((oE)=>{
                        if ([SHAPE_TYPES.START, SHAPE_TYPES.END].indexOf(oE.target.type) === -1 && (_checkCrossLaneConnection(graph, oE))){
                          hasInvalidComponnets = true;
                          errorMsg = `One of the outgoig edge from this component is a non - transition arrow!`;
                        }
                      })
                      */
                    }
                  }

                  if (parentLane) {
                    return parentLane.children.filter((ch) => {
                      return ch.edge && ch.source?.id == cellId;
                    });
                  }
                  return [];
                };

                let filterComponents = (connectors) => {
                  if (connectors) {
                    connectors.map((con) => {
                      if (!hasInvalidComponnets) {
                        const connectors = findConnectors(con.target.id);
                        filterComponents(connectors);
                      }
                    });
                  }
                };

                // Need to check this requiremet with Manoj
                let found = searchExisitingWorkflowComponent(graph, parentCell);
                if (found) {
                  hasInvalidComponnets = true;
                  errorMsg = `One of the incoming edge to this cell is connected to a workflow component ${found.value}!`;
                } else {
                  // Round trip every subsequent childreen item to find non-suported component like Screen, Task, etc...
                  const connectors = findConnectors(parentCell.id);
                  filterComponents(connectors);
                  checkFlag = false;
                }

                let menuEdges = parentCell?.edges?.filter(e => e?.source?.type === SHAPE_TYPES.MENU);
                if(menuEdges?.length > 0) {
                  hasInvalidComponnets = true;
                  errorMsg = "Component connected to Menu cannot be changed to workflow !";
                }

                if (!hasInvalidComponnets) {
                  graph.getModel().beginUpdate();

                  subsequentComponents.map((c) => {
                    c.executeAsWorkflow = 'yes';

                    //Workflow edge color: Set Edge color as green because its taget is a worklow component
                    graph.getModel().getIncomingEdges(c).forEach((ie)=>{
                      let strokeColor = (ie.source?.executeAsWorkflow || _isStateArrow(ie)) ? WORKFLOW_COLOR : null;
                      graph.getModel().setStyle(ie, mxgraph.mxUtils.setStyle(ie.getStyle(), 'strokeColor', strokeColor));
                    })

                    
                    graph
                      .getModel()
                      .setStyle(
                        c,
                        c.getStyle() +
                          ';strokeColor=' +
                          WORKFLOW_COLOR +
                          ';strokeWidth=' +
                          BORDER_WIDTH_WORKFLOW_COMP +
                          ';'
                      );
                  });

                  // After marking the required components as workflow, mark the edges colors
                  // subsequentComponents.map((c) => {
                  //   c.edges.forEach(e=>{
                  //     self.paintEdge(e)
                  //   })
                  // });

                  graph.getModel().endUpdate();
                } else {
                  self.showAlert(errorMsg);
                }

                // self.isLoadingGraph = true;
                // let json = self.toJSON()
                // self.saveSnapshot(json, false, true)
                // self.isLoadingGraph = false;
              } else {
                alert('Error while getting lane info');
              }
              mxEvent.consume(evt);
            });
          }
        }
      }
    }
  };
  graph.popupMenuHandler.factoryMethod = function (menu, cell, evt) {
    return createPopupMenu(graph, menu, cell, evt);
  };

  mxgraph.mxEvent.disableContextMenu(container);
};
PillirGraph.prototype.updateEdgesinUI = function (edge) {
  this.graph.getModel().setVisible(edge, false);
  this.graph.getModel().setVisible(edge, true);
};
PillirGraph.prototype.arrangeScreen = function (
  screenCell,
  edge,
  out,
  moved = false,
  isReload = false
) {
  var self = this;
  let graph = self.graph;
  let moveEdges = [];
  const screenHeirachicalEdgePoints = function (edge) {
    return [
      new mxgraph.mxPoint(
        edge.target.geometry.x - 58,
        edge.target.geometry.y + 18
      ),
    ];
  };

  const moveLablesInEedge = function (edge){
    try {
      var h = graph.createEdgeHandler(
        graph.getView().getState(edge),
        edge.style
      );
      let laneY = edge.target?.parent?.geometry?.y ?? 0;
      h.moveLabel(
        graph.getView().getState(edge),
        edge.target.geometry.x - 58,
        edge.target.geometry.y + laneY + 1
      );
      h.destroy();
    } catch (e) {
      console.log('Error moving Lable', e);
    }      
  }

  const updateHierarchyEdge = function (edge) {
    edge.isHierarchy = true;
    edge.geometry.points = screenHeirachicalEdgePoints(edge);
    self.updateEdgesinUI(edge);
    if (edge.value && edge.value.trim()) {
      moveEdges.push(edge);
    
    }
  };
  const orderScreen = function (screen, edge, index) {
    let target = edge.target;
    // target.parent=screen.parent;
    let dashed='';
    if(!edge.children && target.type===SHAPE_TYPES.BOS){
      dashed="dashed=1;";
    }
    var r = graph.connectVertexPillir(
      screen,
      'south',
      graph.defaultEdgeLength,
      null,
      target,
      null,
      null,
      DOUBLE_SIDE_ARROW+dashed,
      edge,
      index,
      false
    );
    if (r) {
      let edge1 = r[0];
      updateHierarchyEdge(edge1);
      if (!!isReload || !!screenCell.isLockedAddHierarchy) {
        graph.getModel().setVisible(edge1, false);
        if (r[1]) {
          r[1].visible = false;
        }
      }
      if (r[1]) {
        r[1].isHierarchy = true;
        graph.getModel().setVisible(r[1], r[1].visible);
        //graph.orderCells(null, [r[1]]);
      }
    }
  };

  if (edge && !out) {
    //senario for click to connect
    updateHierarchyEdge(edge);
    return;
  }

  if (edge && out) {
    orderScreen(screenCell, edge);
    return;
  }
  //Re arrange when screen get moved or load
  if (moved) {
    let count = 0;
    const edges = screenCell.edges ? [...screenCell.edges] : [];
    graph.getModel().beginUpdate();
    try{
    for (let i = 0; i < edges.length; i++) {
      let edge = edges[i];
      if (edge.isHierarchy && edge.target && !edge.order) {
        edge.order = true;
        orderScreen(screenCell, edge, count);
        count++;
      }
    }
  }finally{
    graph.getModel().endUpdate();
    moveEdges.forEach(e=>{
      moveLablesInEedge(e);
    })
  }
  }
};
const jsonCodec = class JsonCodec extends mxgraph.mxObjectCodec {
  constructor() {
    super((value) => {});
  }
  encode(value) {
    return value;
  }
  decode(model) {
    return Object.keys(model.cells)
      .map((iCell) => {
        const currentCell = model.getCell(iCell);
        return currentCell.value !== undefined ? currentCell : null;
      })
      .filter((item) => item !== null);
  }
};

PillirGraph.prototype.startEndDefault = function (
  graph,
  appType = '',
  obj = {}
) {
  let self = this;
  if (graph == undefined || graph == null) {
    graph = this.graph;
  }
  let parent = graph.getDefaultParent().children[0];
  let start_type = SHAPE_TYPES.START;
  let end_type = SHAPE_TYPES.END;
  let intial_download = SHAPE_TYPES.BOS;
  if (self.type === 'userTaskDetail') {
    start_type = SHAPE_TYPES.STARTDEFAULT;
    end_type = SHAPE_TYPES.ENDDEFAULT;
    parent = graph.getDefaultParent();
  }
  graph.getModel().beginUpdate();

  let oflProperty = obj?.offlineProperty || '';

  var cell1 = processObjects[start_type](appType);
  cell1.geometry.x = 120;
  cell1.geometry.y = 182;
  cell1.geometry.width = 28;
  cell1.geometry.height = 28;
  cell1.id = 'start';
  cell1.value = oflProperty;

  var cell2 = processObjects[end_type]();
  cell2.geometry.x = 440;
  cell2.geometry.y = 182;
  cell2.geometry.width = 28;
  cell2.geometry.height = 28;
  cell2.connectable = false;
  //cell2.value = ' ';
  cell2.id = 'end';

  var cell3 = processObjects[intial_download]();
  if (oflProperty) {
    oflProperty = JSON.parse(oflProperty || '');
  }
  if (appType === 'Ofl') {
    cell3.geometry.x = 192;
    cell3.geometry.y = 264;
    cell3.uid = oflProperty?.initialDownloadUid || '';
    cell3.value = 'Initial Download';
    cell3.name = 'Initial Download';
  }

  cell1 = graph.addCell(cell1, parent);
  cell2 = graph.addCell(cell2, parent);
  if (appType === 'Ofl') {
    cell3 = graph.addCell(cell3, parent);
  }
  graph.getModel().endUpdate();
  if (appType === 'Ofl' && cell3) {
    var r = graph.connectVertexPillir(
      cell1,
      'south',
      graph.defaultEdgeLength,
      null,
      cell3,
      null,
      null,
      OFFLINE_APP_ARROW
    );
    if (r) {
      var edge = r[0];
      cell3 = r[1];
      cell3.geometry.x = 192;
      cell3.geometry.y = 264;
      cell3.connectable = false;
      cell3.isOfflineBOS = true;
      graph.orderCells(null, [cell3]);
      edge.isHierarchy = true;
      edge.geometry.points = [new mxgraph.mxPoint(192 - 58, 264 + 18)];
      graph.getModel().setVisible(edge, false);
      graph.getModel().setVisible(edge, true);
    }
  }
};

PillirGraph.prototype.drawMenuComponent = function (graph, laneName, creatingLane=true) {
  const laneObj = graph.getDefaultParent().children.find(lane => lane.Lname === laneName);
  if (laneObj){

    graph.getModel().beginUpdate();
    
    let screenCell, menuCell;
    let menuLinks = [];
    //Removing any Menu component if there
    let existingMenuCell = laneObj.children.find(cell=>cell.type === SHAPE_TYPES.MENU);
    if (!existingMenuCell){
      // Creating new menu cell instance
      menuCell = processObjects[SHAPE_TYPES.MENU]();
      menuCell.geometry.x = 432;
      menuCell.geometry.y = 220;
      menuCell.geometry.width = 28;
      menuCell.geometry.height = 28;

      if (!creatingLane) { // If generating menu component for existing app, then the menu position should be at the bottom of the screen. So it wont overlap on any existing component
        let geo = laneObj.getGeometry(); 
        if (this._checkIsWorkflowApp()){ // Worklow lane
          menuCell.geometry.y = geo.height - (menuCell.geometry.height + 10);
        }else{ // Non-workflow lane
          let maxHeight = 0;
          laneObj.children.forEach((ch)=>{
            if (!ch.isEdge()){
              let h = ch.getGeometry().height + ch.getGeometry().y
              if (h > maxHeight) {
                maxHeight = h
              }
            }
          })
          menuCell.geometry.y = maxHeight + 10;
        }
      }
      menuCell.id = 'menu';
      menuCell.value = this.getDefaultName(menuCell);
      // Attaching new menu cell to the lane
      graph.addCell(menuCell, laneObj);

    }
    
    if(creatingLane){
      let newPageName = `Untitled`;
      let existingPageLength = 0;
      graph.getDefaultParent().children.forEach((_l) => {
        existingPageLength += _l.children.filter(function (element) {
          return element?.value.startsWith(newPageName);
        }).length
      })
      
      if(existingPageLength > 0){
        newPageName = `${newPageName}_${existingPageLength}`
      }

      // Creating new SCREEN cell instance
      screenCell = new Screen({
        content: previewImg,
        name: newPageName,
        deployment: this.getDeploymentPlatform(),
      });
      // screenCell.value = this.getDefaultName(screenCell);
      screenCell.value = newPageName;
      screenCell.name = screenCell.value;
      screenCell.geometry.x = 232;
      screenCell.geometry.y = 91;
      // Attach new screen cell to the lane
      graph.addCell(screenCell, laneObj);

      //Create connections b/w the screen and menu
      let newEdge = this.createConnection(0, "", "", this.generateUid());
      graph.addEdge(newEdge, laneObj, menuCell, screenCell);
      graph.getModel().setStyle(newEdge, mxgraph.mxUtils.setStyle(newEdge.getStyle(), 'endArrow', "none")); // remove endArrow style
      this.handleArrowBends(graph, [newEdge]) // Reset the line, remove all bends
      // set menuCell state for new screen link
      menuLinks = [
        {
          linkTo: screenCell.uid,
          label: "Home",
          iconName: "AccountBalance",
          id: generateUid(),
        },
        {
          linkTo: "",
          label: "Setting",
          iconName: "Settings",
          id: generateUid(),
        }
      ]
      menuCell.data = JSON.stringify(menuLinks)
    }

    if(menuCell || screenCell){
      let menuUid = menuCell?.uid || ""
      let menuName = menuCell?.value || ""
      let screenUid = screenCell?.uid || ""
      let screenName = screenCell?.value || ""
      let cb = (err) => {
        if (err) {
          graph.self.showAlert(err);
          graph.self.editor.undo()
        }else{
          if (screenCell) {
            setTimeout(() => {
              graph.self.renderPageScreen([screenCell]);
            });
          }
        }
        
      };

      
      createAppMenu(this.projectName, this.businessFunction, menuUid, menuName, menuLinks, screenUid, screenName, cb);
    }
    graph.getModel().endUpdate();
    
  }
}

PillirGraph.prototype.removeMenuComponent = function (graph, laneName) {
  const laneObj = graph.getDefaultParent().children.find(lane => lane.Lname === laneName);
  if (laneObj){
    graph.getModel().beginUpdate();
    let menuCell = laneObj.children.find(cell=>cell.type === SHAPE_TYPES.MENU);
    if(menuCell){
      graph.removeCells([menuCell])
    }
    graph.getModel().endUpdate();
  }
}

PillirGraph.prototype.clickEvent = function (graph) {
  if (this.graph.firstClickState == undefined) {
    graph.selectionModel.clear();
  }
};

PillirGraph.prototype.fromJSON = function (
  dataModel,
  graph,
  appType,
  callback = null,
  parents
) {
  let self = this;
  if (graph == undefined || graph == null) {
    graph = this.graph;
  }

  try {
    if (!callback) graph.getModel().clear();
  } catch (e) {}

  let vertices = {};
  let screens = [];
  let laneCount = undefined;
  const parent = parents || graph.getDefaultParent();

  const _getEndCell = (json) => {
    return json.find((c) => {
      return c.type === 'End';
    });
  };
  const _getStartCell = (json) => {
    return json.find((c) => {
      return c.type === 'Start';
    });
  };
  try {
    let LaneIndex = 0;
    dataModel.graph != undefined &&
      dataModel.graph.lanes.map((node) => {
        graph.getModel().beginUpdate(); // Adds cells to the model in a single step
        // if(!node.isAbap){
        //   abapFromJSON(dataModel,laneCount,vertices,screens,parent,node,self,graph);
        // }
        // else
        if (node.type === SHAPE_TYPES.LANE) {
          var geometry = new mxgraph.mxGeometry(
            3,
            node.geometry?.y ?? 0,
            4000,
            node.geometry?.height ?? 4000
          );
          geometry.alternateBounds = new mxgraph.mxRectangle(0, 0, 4000, 50);
          vertices[node.id] = self.createLaneProcess(
            'role',
            node.name,
            dataModel,
            laneCount,
            geometry,
            LaneIndex,
            self._isGraphReadOnly()
          );
          laneCount =
            vertices[node.id].children[vertices[node.id].children.length - 1];
          LaneIndex++;
          let boundStartY = vertices[node.id].geometry.y;
          let boundEndY =
            vertices[node.id].geometry.y + vertices[node.id].geometry.height;

          if (node.children) {
            node.children.map((c) => {
              if (c.type.toLowerCase() !== SHAPE_TYPES.CONNECTOR && processObjects[c.type]) {
                var v = processObjects[c.type]();
                var cell = v;

                if (UNSUPPORTED_COMPONENTS.indexOf(c.type) === -1) {
                  if (c.executeAsWorkflow && c.executeAsWorkflow === 'yes') {
                    if ([SHAPE_TYPES.END].indexOf(c.type) === -1) {
                      cell.setStyle(
                        cell.getStyle() +
                          ';strokeColor=' +
                          WORKFLOW_COLOR +
                          ';strokeWidth=' +
                          BORDER_WIDTH_WORKFLOW_COMP +
                          ';'
                      ); // mxConstants.STYLE_FONTCOLOR + '=white;
                    }
                    cell.executeAsWorkflow = c.executeAsWorkflow;
                  }
                }

                if (c.type === SHAPE_TYPES.DMN) {
                  let dmnTable = decodeProperty(c.dmnExp);
                  cell.children[0].value = JSON.stringify(dmnTable);
                  // console.log('dmn: dmnTable', dmnTable);
                } else if (c.type === SHAPE_TYPES.SCREEN) {
                  screens.push({ id: c.id, name: c.name, uid: c.uid });
                  v = [
                    new Screen({
                      content: previewImg,
                      name: c.name,
                      deployment: self.getDeploymentPlatform(),
                    }),
                  ];
                  cell = v[0];
                } else if (c.type === SHAPE_TYPES.TASK) {
                  cell.childTask = c.childTask;
                } else if (c.type === SHAPE_TYPES.ACTIVITY) {
                  cell.link = c.link;
                } else if (c.type === SHAPE_TYPES.START) {
                  v = processObjects[c.type](appType);
                  cell = v;
                } else if (c.type === SHAPE_TYPES.BOS) {
                  cell.isOfflineBOS = c.isOfflineBOS;
                }
                let Y = c.geometry.y;
                if (Y < boundStartY) {
                  let yy = Y + boundStartY;
                  if (yy >= boundEndY) {
                    c.geometry.y = yy - boundEndY / 2;
                  }
                } else if (Y > boundEndY) {
                  Y = boundEndY - (c.geometry.height + 100);
                  c.geometry.y = Y;
                }
                cell.parentCellVal = c.parentCellVal;
                cell.isReference = c.isReference;
                cell.refuid = c.refuid;
                cell.geometry.x = c.geometry.x;
                cell.geometry.y = c.geometry.y;

                if (c.type === SHAPE_TYPES.NOTE) {
                  if (c.geometry.width) cell.geometry.width = c.geometry.width;
                  if (c.geometry.height)
                    cell.geometry.height = c.geometry.height;
                }

                cell.value = c.name;
                cell.uid = c.uid;
                cell.data = c.data;
                cell.isHierarchy = c.isHierarchy;
                cell.setAttribute('data', c.data);
                cell.modified = c.modified;
                cell.connectable = c.connectable;

                try {
                  cell = graph.getImportableCells([cell]);
                  vertices[c.id] = graph.addCells(cell, vertices[node.id])[0];
                } catch (e) {
                  cell[0].parent = 1;
                  vertices[c.id] = cell[0];
                }
              } else {
                // graph.addEdge(self.makeEdge(c), c.parent === 1 ? parent : vertices[c.parent], c.start != null ? vertices[c.start] : undefined, c.end != null ? vertices[c.end] : undefined);
              }
              // DMN connections
              if (c.type === SHAPE_TYPES.DMN) {
                // Re-generate edges required for DMN, as they are not persistent
                setTimeout(() => {
                  self.adjustDMNArrows(
                    vertices[c.id],
                    decodeProperty(c.dmnExp)
                  );
                }, 0);
              } else if (c.type === SHAPE_TYPES.XOR) {
                // Re-generate edges required for XR, as they are not persistent
                setTimeout(() => {
                  self.adjustConditionArrows(vertices[c.id]);
                }, 0);
              } else if (c.type === SHAPE_TYPES.CASE) {
                // Re-generate edges required for CASE, as they are not persistent
                setTimeout(() => {
                  self.skipAction = true;
                  self.adjustCaseArrows(vertices[c.id]);
                  self.skipAction = false;
                }, 0);
              } else if (c.type == SHAPE_TYPES.MENU) {
                // Re-generate edges required for CASE, as they are not persistent
                setTimeout(() => {
                  self.skipAction =  true;
                  self.isLoadingGraph =  true;
                  graph.getModel().beginUpdate();
                  self.adjustMenuArrows(vertices[c.id]);
                  graph.getModel().endUpdate();
                  self.skipAction = false;
                  self.isLoadingGraph = false;
                }, 0);
                getMenuDetails(self.projectName, self.businessFunction, c.uid)
              }
              return c;
            });
            let endCell = _getEndCell(node.children);
            let startCell = _getStartCell(node.children);
            node.children.map((c) => {
              if (c.type === SHAPE_TYPES.CONNECTOR) {
                let name = self.handleInteractedArrows(c);
                let edgeCell = self.makeEdge(c);
                edgeCell.transitions = c.transitions || [];
                // In workflow apps, arrows connecting to end/start component should have the state transition
                if (
                  self._checkIsWorkflowApp() &&
                  ((endCell && endCell.id === c.end) ||
                    (startCell && startCell.id === c.start))
                ) {
                  if (
                    !edgeCell
                      .getStyle()
                      .indexOf('strokeColor=' + WORKFLOW_COLOR) > 0
                  ) {
                    edgeCell.setStyle(
                      edgeCell.getStyle() +
                        ';strokeColor=' +
                        WORKFLOW_COLOR +
                        ';strokeWidth=' +
                        BORDER_WIDTH_WORKFLOW_COMP +
                        ';'
                    );
                    // if(c.uid.indexOf("_")!=-1){
                    //   edgeCell.setStyle(edgeCell.getStyle() + ';labelColor=' + WORKFLOW_COLOR+';')
                    // }
                  }
                }
                // if (c.stateValue) { // Showing stateValue as cell value on runtime
                //   edgeCell.setValue(c.stateValue)
                // }
                edgeCell = graph.addEdge(
                  edgeCell,
                  c.parent === 1 ? parent : vertices[c.parent],
                  c.start != null ? vertices[c.start] : undefined,
                  c.end != null ? vertices[c.end] : undefined
                );
                if (c.stateValue && c.stateValue != ''){
                  self.addStateLabel(edgeCell, c.stateValue, false);
                }
                if(name){
                  self.addIntractionLabel(edgeCell,name,false);
                }
              }
              return c;
            });
          }
        }

        graph.getModel().endUpdate(); // Updates the display
        return node;
      });

    // Loading Graph for the Tasks
    graph.getModel().beginUpdate(); // Adds cells to the model in a single step
    if (dataModel.task != undefined) {
      dataModel.task.map((c) => {
        if (c.type !== SHAPE_TYPES.CONNECTOR) {
          if (c.type != 'Title') {
            var v = processObjects[c.type]();
            var cell;
            if (c.type === SHAPE_TYPES.SCREEN) {
              screens.push({ id: c.id, name: c.name, uid: c.uid });
              v = [
                new Screen({
                  content: previewImg,
                  name: c.name,
                  deployment: self.getDeploymentPlatform(),
                }),
              ];
              cell = v[0];
            } else {
              cell = v;
            }
            cell.geometry.x = c.geometry.x;
            cell.geometry.y = c.geometry.y;
            cell.geometry.width = c.geometry.width;
            cell.geometry.height = c.geometry.height;
            cell.value = c.name;
            cell.parentCellVal = c.parentCellVal;
            cell.isReference = c.isReference;
            cell.refuid = c.refuid;
            cell.uid = c.uid;
            if (c.data) {
              cell.data = c.data;
            }
            cell.connectable = c.connectable;
            try {
              cell = graph.getImportableCells([cell]);
              vertices[c.id] = graph.addCells(cell, parent)[0];
            } catch (e) {
              cell[0].parent = parent;
              vertices[c.id] = cell[0];
            }

            if (c.type === SHAPE_TYPES.XOR) {
              // Re-generate edges required for XR, as they are not persistent
              setTimeout(() => {
                self.adjustConditionArrows(vertices[c.id]);
              }, 0);
            } else if (c.type === SHAPE_TYPES.CASE) {
              // Re-generate edges required for CASE, as they are not persistent
              setTimeout(() => {
                self.skipAction = true;
                self.adjustCaseArrows(vertices[c.id]);
                self.skipAction = false;
              }, 0);
            }
          }
        }
      });
      dataModel.task.map((c) => {
        if (c.type === SHAPE_TYPES.CONNECTOR) {
          let name = self.handleInteractedArrows(c);
          let ec = self.makeEdge(c);
          ec.transitions = c.transitions || [];
          ec = graph.addEdge(
            ec,
            c.parent === 1 ? parent : vertices[c.parent],
            c.start != null ? vertices[c.start] : undefined,
            c.end != null ? vertices[c.end] : undefined
          );

          /*
          Issue fix: T2937903 - Verify UI Alignment in the Group
          Setting bend points for edges after adding
          */
          if (c.geometry?.points && c.geometry?.points.length > 0){
            let geo = ec.getGeometry().clone();
            geo.points = [];
            c.geometry.points.forEach((p) => {
              geo.points.push(new mxPoint(p.x, p.y));
            });
            graph.getModel().setGeometry(ec, geo);
          }
          if(name){
              self.addIntractionLabel(ec,name,false);
          }
        }
        return c;
      });
    }

    //End of Loading the Graph for UserTasks

    dataModel.graph != undefined &&
      dataModel.graph.connectors.map((node) => {
        if (
          node.start != null &&
          node.end != null &&
          node.type === SHAPE_TYPES.CONNECTOR &&
          vertices[node.start] &&
          vertices[node.end] &&
          vertices[node.start].parent !== vertices[node.end].parent
        ) {
          try {
            let name = self.handleInteractedArrows(node);
            let e =  self.makeEdge(node);
            e.transitions = node.transitions || [];
            let edge = graph.addEdge(
              e,
              parent,
              vertices[node.start],
              vertices[node.end]
            );
            if (node.stateValue && node.stateValue != '') {
              self.addStateLabel(edge, node.stateValue, false);
            }
            if(name){
              self.addIntractionLabel(edge,name,false);
            }
          } catch (e) {}
        } else if (node.end == null || node.start == null) {
          try {
            graph.addEdge(self.makeEdge(node), parent, vertices[node.start]);
          } catch (e) {}
        }
        return node;
      });
  } finally {
    if (callback) {
      callback(vertices);
    }
    graph.getModel().endUpdate(); // Updates the display
    //graph.refresh();
    if (callback) {
      self.skipAction = true;
    }
    self.renderPageScreen(screens, vertices, callback);
    if (callback) {
      self.skipAction = false;
    }
    if (!callback) {
      self.editor.undoManager.clear();
      setTimeout(() => {
        if(self._checkIsWorkflowApp()){
          let parent=graph.getDefaultParent();
          let lanes = parent.children.filter(l=> l.isLane);
          let inactiveLanes = lanes.filter(l=> self.activeLanes.indexOf(l.Lname) === -1 );
          if(inactiveLanes.length === lanes.length){
            inactiveLanes = inactiveLanes.filter((e,i) => i !== 0 );
          }
          inactiveLanes.forEach((lane) => {
            self.collapseLanes(lane, new mxEventObject('foldCells','cell'), false, false);
          })
        } 
      }, 100);
    }
  }
};

PillirGraph.prototype.toJSON = function (graph) {
  var self = this;
  if (!graph || graph == null) {
    graph = this.graph;
  }

  const encoder = new jsonCodec();
  const jsonModel = encoder.decode(graph.getModel());
  var jsonObject = { lanes: [], connectors: [], task: [] };
  //processrequiresLane has false has been moved to the birds-eye-view.js . We can create a base component and inherit the businessfunction and bev later.
  if (self.processRequireLane == false && jsonModel != '') {
    graph.getDefaultParent().children.map((node) => {
      if (node.edge == false && node.type != 'Title' && node.type != 'Script') {
        var obj = {};
        obj.id = node.id;
        obj.type = node.type;
        obj.name = node.value;
        obj.geometry = {};
        obj.uid = node.uid;
        obj.geometry.x = node.geometry.x;
        obj.geometry.y = node.geometry.y;
        obj.geometry.width = node.geometry.width;
        obj.geometry.height = node.geometry.height;
        obj.parent = node.parent.id;
        obj.data = node.data;
        obj.connectable = node.connectable;
        obj.parentCellVal = node.parentCellVal;
        obj.isReference = node.isReference;
        obj.refuid = node.refuid;
        if (node.type == 'Screen') {
          obj.isLockedAddHierarchy = node.isLockedAddHierarchy;
        }

        var getChildrens = function (node) {
          var ch = [];
          if (node.children) {
            node.children.map((c) => {
              var obj = {};
              if (c.type && !c.isIcon) {
                obj.id = c.id;
                obj.type = c.type;
                obj.name = c.value;
                obj.geometry = {};
                obj.uid = c.uid;
                obj.geometry.x = c.geometry.x;
                obj.geometry.y = c.geometry.y;
                obj.geometry.width = c.geometry.width;
                obj.geometry.height = c.geometry.height;
                obj.data = c.data;
                obj.parent = c.parent.id;
                if (c.children) {
                  obj.children = getChildrens(c);
                } else {
                  obj.children = [];
                }
                ch.push(obj);
              } else if (c.edge) {
                obj.id = c.id;
                obj.type = SHAPE_TYPES.CONNECTOR;
                obj.name = c.value;
                obj.uid = c.uid;
                obj.isHierarchy = c.isHierarchy;
                obj.style = c.style;
                obj.geometry = c.geometry;
                obj.parent = c.parent.id;
                obj.start = c.source ? c.source.id : null;
                obj.end = c.target ? c.target.id : null;
                obj.transitions = c.source.type === SHAPE_TYPES.SCREEN ? (c.transitions || []) : [];
                if (!obj.children) {
                  obj.children = [];
                }
                obj.children.push(obj);
              }
              return c;
            });
            return ch;
          }
          return ch;
        };
        obj.children = getChildrens(node);
        jsonObject.task.push(obj);
        // if(node.type === "Screen" || node.type === "ServiceTask"){
        //   jsonObject.task.push(obj);
        // }
      }

      //usertask Connectors
      else if (node.edge) {
        var obj = {};
        obj.id = node.id;
        obj.type = SHAPE_TYPES.CONNECTOR;
        obj.name = node.value; //event line value
        obj.isHierarchy = node.isHierarchy;
        obj.style = node.style;
        obj.uid = node.uid;
        obj.geometry = node.geometry;
        obj.parent = node.parent.id;
        // obj.start=node.source ? (node.source.type==='Screen' && node.source.type ? '15': node.source.id):null;
        obj.start = node.source ? node.source.id : null;
        obj.end = node.target ? node.target.id : null;
        obj.transitions = node.source.type === SHAPE_TYPES.SCREEN ? (node.transitions || []) : [];
        if (!node.isHierarchy || node.target.type == 'ServiceTask') {
          jsonObject.task.push(obj);
        }
      }
    });
    return {
      graph: jsonObject,
    };
  }

  if (graph.getDefaultParent().children !== null)
    graph.getDefaultParent().children.map((node) => {
      var obj = {};
      if ((node.type && node.type === SHAPE_TYPES.LANE) || !node.edge) {
        obj.id = node.id;
        obj.type = node.type;
        obj.name = node.Lname;
        obj.geometry = {};
        obj.uid = node.uid;
        obj.geometry = new mxgraph.mxGeometry(
          node.geometry.x,
          node.geometry.y,
          node.geometry.width,
          node.geometry.height == 50 ? 475 : node.geometry.height
        );
        obj.parent = node.parent.id;
        obj.data = node.data;

        let boundStartY = obj.geometry.y;
        let boundEndY = obj.geometry.y + obj.geometry.height;

        var getChildrens = function (node) {
          var ch = [];
          if (node.children) {
            node.children.map((c) => {
              let Y = c.geometry.y;
              if (Y < boundStartY) {
                let yy = Y + boundStartY;
                if (yy >= boundEndY) {
                  c.geometry.y = yy - boundEndY / 2;
                }
              } else if (Y > boundEndY) {
                Y = boundEndY - (c.geometry.height + 100);
                c.geometry.y = Y;
              }
              var obj = {};
              if (
                c.type &&
                !c.edge &&
                !c.isIcon &&
                c.type != SHAPE_TYPES.SCRIPT
              ) {
                if (c.type === SHAPE_TYPES.DMN) {
                  let table =
                    c.children[0].value === ' '
                      ? '{"rows":[], "columns":[]}'
                      : c.children[0].value;
                  let dmnTable = JSON.parse(table || '{}');
                  let col = [];
                  col = dmnTable.columns.map((item) => {
                    if (item.startsWith('GETUSERPROPERTY')) {
                      return getUserPropertyId(item, 'name', 'propertyUuid');
                    } else {
                      return item;
                    }
                  });
                  obj.dmnExp = {
                    columnNames: col,
                    dmnTable: constructProperty(dmnTable),
                  };
                }
                if (c.type == SHAPE_TYPES.TASK) {
                  obj.childTask = c.childTask;
                } else if (c.type === SHAPE_TYPES.ACTIVITY) {
                  obj.link = c.link;
                }
                obj.id = c.id;
                obj.uid = c.uid;
                obj.type = c.type;
                obj.name = c.value;
                obj.geometry = {};
                obj.geometry = c.geometry;
                obj.data = c.data;
                if (c.executeAsWorkflow) {
                  obj.executeAsWorkflow = c.executeAsWorkflow;
                }
                obj.parentCellVal = c.parentCellVal;
                obj.isReference = c.isReference;
                obj.refuid = c.refuid;
                //bf screen child mapping
                if (c.type === SHAPE_TYPES.BOS) {
                  obj.isOfflineBOS = c.isOfflineBOS;
                }
                if (
                  c.type == SHAPE_TYPES.BOS &&
                  c.edges &&
                  c.edges[0] &&
                  c.edges[0].uid &&
                  c.edges[0].source &&
                  c.edges[0].source.type == SHAPE_TYPES.SCREEN
                ) {
                  var style = c.edges[0].getStyle();

                  var validateStyle = function () {
                    try {
                      var split = style.split(';');
                      let count = 0;
                      for (var a = 0; a < split.length; a++) {
                        if (split[a] == 'exitX=0.25') {
                          count++;
                        } else if (split[a] == 'exitY=1') {
                          count++;
                        } else if (split[a] == 'exitDx=0') {
                          count++;
                        } else if (split[a] == 'exitDy=0') {
                          count++;
                        }
                      }
                      if (count == 4) {
                        return true;
                      } else {
                        return false;
                      }
                    } catch (e) {
                      return false;
                    }
                  };

                  if (validateStyle() == true) {
                    obj.parentUID = c.edges[0].source.uid;
                  }
                } else {
                  obj.parent = c.parent.id;
                }
                obj.modified = c.modified;
                obj.connectable = c.connectable;

                if (c.children) {
                  obj.children = getChildrens(c);
                } else {
                  obj.children = [];
                }
                ch.push(obj);
              } else if (c.edge) {
                obj.id = c.id;
                obj.uid = c.uid;
                obj.type = SHAPE_TYPES.CONNECTOR;
                obj.dmnExp = c.dmnExp;
                obj.name = c.value;
                obj.style = c.style;
                obj.isHierarchy = c.isHierarchy;
                obj.geometry = c.geometry;
                obj.parent = c.parent.id;
                obj.start = c.source ? c.source.id : null;
                obj.end = c.target ? c.target.id : null;
                obj.transitions = c.source?.type === SHAPE_TYPES.SCREEN ? (c.transitions || []) : [];
                if (c.stateLabel) {
                  obj.stateValue = c.stateLabel.value;
                  obj.state = c.stateLabel.value;
                }
                if (
                  c.target &&
                  c.target.type &&
                  c.target.type != SHAPE_TYPES.SCRIPT
                ){
                  ch.push(obj);
                }
              }
              return c;
            });
            return ch;
          }
          return ch;
        };
        obj.children = getChildrens(node);

        if (obj.type == SHAPE_TYPES.BOS) {
          // some times bos parent is default it is not inclded in lanes
          obj.id = node.id;
          obj.uid = node.uid;
          obj.type = node.type;
          obj.name = node.value;
          obj.geometry = {};
          obj.geometry = node.geometry;
          obj.data = node.data;
          obj.connectable = node.connectable;

          if(jsonObject?.lanes?.[0]){
            jsonObject.lanes[0].children.push(obj);
          }
        } else {
          jsonObject.lanes.push(obj);
        }
      } else if (node.edge) {
        obj.id = node.id;
        obj.uid = node.uid;
        obj.type = SHAPE_TYPES.CONNECTOR;
        // obj.value=node.value;
        obj.name = node.value;
        obj.style = node.style;
        obj.isHierarchy = node.isHierarchy;
        obj.geometry = node.geometry;
        if (
          !(obj.geometry?.points ?? false) &&
          node.alternateBounds &&
          node.alternateBounds.points
        ) {
          obj.geometry.points = node.alternateBounds.points;
        }
        obj.parent = node.parent.id;
        obj.start = node.source ? node.source.id : null;
        obj.end = node.target ? node.target.id : null;

        /*if (node.hasOwnProperty('stateValue')){
          obj.stateValue = node.stateValue
        }*/
        if (node.children && !node.isHierarchy) {
          if (node.children[0] && node.children[0].value)
            obj.stateValue = node.children[0].value;
        }
        obj.transitions = node.source?.type === SHAPE_TYPES.SCREEN ? (node.transitions || []) : [];

        if (
          node.target &&
          node.target.type &&
          node.target.type != SHAPE_TYPES.SCRIPT
        ){
          jsonObject.connectors.push(obj);
        }
      }

      return node;
    });
  //Sort lanes based on the position. When a role name changed, the graph changes the sequence in array.
  jsonObject.lanes.sort(function(x,y){return x.geometry.y - y.geometry.y});
  
  return {
    graph: jsonObject,
  };
};
PillirGraph.prototype.makeEdge = function (node) {
  var geometry = new mxgraph.mxGeometry(
    node.geometry.x,
    node.geometry.y,
    node.geometry.width,
    node.geometry.height
  );
  if (node.geometry.relative) geometry.relative = true;
  if (node.geometry.offset)
    geometry.offset = new mxgraph.mxPoint(
      node.geometry.offset.x,
      node.geometry.offset.y
    );
  if (node.end == null && node.geometry.targetPoint)
    geometry.targetPoint = new mxgraph.mxPoint(
      node.geometry.targetPoint.x,
      node.geometry.targetPoint.y
    );
  if (node.start == null && node.geometry.sourcePoint)
    geometry.sourcePoint = new mxgraph.mxPoint(
      node.geometry.sourcePoint.x,
      node.geometry.sourcePoint.y
    );
  if (node.geometry.points && node.geometry.points.length > 0) {
    geometry.points = [];
    node.geometry.points.map((e) => {
      geometry.points.push(new mxgraph.mxPoint(e.x, e.y));
      return e;
    });
  }

  var edge = new mxgraph.mxCell(node.name, geometry, node.style);

  edge.edge = true;
  edge.uid = node.uid;
  edge.isHierarchy = node.isHierarchy;
  return edge;
};

//Create connector from dmn table
PillirGraph.prototype.createConnection = function (count, val, exp, uid, conf) {
  // let yPoint = count === null ? -20 : (count + 60)
  let yPoint = -80 + 60 * count;
  let xPoint = 300;
  if (conf && conf.xPoint){
    xPoint = conf.xPoint;
  }
  var geometry = new mxgraph.mxGeometry(0, 0, 0, 0);
  geometry.relative = true;
  geometry.targetPoint = new mxgraph.mxPoint(xPoint, yPoint);
  geometry.points = [];
  [{ x: 200, y: yPoint }].map((e) => {
    geometry.points.push(new mxgraph.mxPoint(e.x, e.y));
    return e;
  });
  const exitX = conf?.exitX || 1;
  const exitY = conf?.exitY || 0.5;

  var edge = new mxgraph.mxCell(
    val ? val : '',
    geometry,
    `edgeStyle=orthogonalEdgeStyle;points:[{x:300,y:40}];html=1;verticalAlign=top;verticalLabelPosition=bottom;movable=0;rounded=0;`
  ); //exitX=${exitX};exitY=${exitY};exitDx=0;exitDy=0;
  edge.edge = true;
  // edge.isHierarchy = true;
  //edge.type = SHAPE_TYPES.CONNECTOR
  if (exp !== undefined) {
    edge.dmnExp = exp;
  }
  edge.uid = uid;
  return edge;
};

PillirGraph.prototype.createConnectionForXOR = function (
  count,
  val,
  uid,
  conf
) {
  if (val === CONDITION_YES) {
    count = 0;
  } else {
    count = 4;
  }

  let yPoint = -80 + 60 * count;
  var geometry = new mxgraph.mxGeometry(0, 0, 0, 0);
  geometry.relative = true;

  let targetX = val === CONDITION_YES ? 200 : 30;
  let targetY = val === CONDITION_YES ? 30 : yPoint;

  geometry.targetPoint = new mxgraph.mxPoint(targetX, targetY);

  const exitX = conf?.exitX || 1;
  const exitY = conf?.exitY || 0.5;

  var edge = new mxgraph.mxCell(
    val ? val : '',
    geometry,
    `edgeStyle=orthogonalEdgeStyle;points:[{x:300,y:40}];html=1;exitX=${exitX};exitY=${exitY};exitDx=0;exitDy=0;verticalAlign=top;verticalLabelPosition=bottom;movable=0;rounded=0;`
  );
  edge.edge = true;
  //edge.type = SHAPE_TYPES.CONNECTOR
  edge.uid = uid;
  return edge;
};

PillirGraph.prototype.toggleEyeIcon = function (c, cell) {
  let iconGeo = new mxgraph.mxGeometry(10, c.geometry.height + 12, 16, 10);
  if (!c.isLockedAddHierarchy) {
    let showeye = new Icon(iconGeo, null, showEyeIcon);
    showeye.type = 'eye';
    showeye.parent = c;
    let subtitleCell = c.children.find((i) => i.type == 'subtitle');
    this.graph.removeCells([cell, subtitleCell]);
    c.children.push(showeye);
    this.graph.removeCells([cell]);
  } else {
    let closeeye = new Icon(iconGeo, null, closeEyeIcon);
    closeeye.type = 'eye';
    closeeye.parent = c;
    let subtitleCell = c.children.find((i) => i.type == 'subtitle');
    let count = c.getHierarchyEdgeCount();
    if (!subtitleCell && !!count) {
      let geo = new mxgraph.mxGeometry(40, c.geometry.height - 4, 138, 12);
      let title = new ScreenSubtitle(count, geo);
      title.parent = c;
      c.children.push(title);
    }
    this.graph.removeCells([cell]);
    c.children.push(closeeye);
  }
  let model = this.graph.getModel();
  c.edges.forEach((e) => {
    if (e.isHierarchy) {
      e.order = false;
      if (
        e.target &&
        e.target.children &&
        e.target.type != SHAPE_TYPES.SCREEN
      ) {
        model.setVisible(e.target, !c.isLockedAddHierarchy);
      } else if (
        e.source &&
        e.source.children &&
        e.source.type != SHAPE_TYPES.SCREEN
      ) {
        model.setVisible(e.source, !c.isLockedAddHierarchy);
      }
      model.setVisible(e, !c.isLockedAddHierarchy);
      if(e.children){
        model.setVisible(e.children[0], !c.isLockedAddHierarchy);
      }
    }
  });
};

PillirGraph.prototype.laneCount = 0;
PillirGraph.prototype.initEventHandler = function (graph) {
  var self = this;
  graph.addMouseListener({
    mouseDown: function (sender, me) {
      let cell = me.getCell();
      let evt = me.getEvent();
      if (
        ((cell && !evt.shiftKey) || !cell || cell.executeAsWorkflow || cell.isOfflineBOS) &&
        self.highlightedCells.length
      ) {
        self.highlightedCells.map((e) => {
          e.highlightObj.hide();
        });
        self.highlightedCells = [];
        graph.selectionModel.clear();
        me.consume();
      }
      // me.consume();
      // var evt = me.getEvent();
      // console.log(evt);
    },
    mouseMove: function (sender, me) {
      //me.consume();
    },
    mouseUp: function (sender, me) {
      var cell = me.getCell();
      var evt = me.getEvent();
      if (cell === null) {
        graph.selectionModel.clear();
        cell = graph.getCellAt(me.graphX, me.graphY);
        if (!cell) {
          cell = graph.getDefaultParent();
        }
      } else if (cell && cell.isLane) {
        graph.selectionModel.clear();
        me.consume();
        return;
      }
      let cells = graph.getSelectionCells();
      if ((evt.which === 3 || evt.which === 2) && cells.length > 1) {
        // track the right click event and show the popup menu.
        // 3 for chrome / mozilla / safari
        // 2 For IE, Opera.
        // can not group moreOptionsIcon, Icon, eye, tableIcon, Start, End together into the group

        // Check wether user has selected start and end along with defaults so block him from creating group.

        let isStartEndConsiderd = cells.find((c) =>
          [
            SHAPE_TYPES.START,
            SHAPE_TYPES.STARTDEFAULT,
            SHAPE_TYPES.ENDDEFAULT,
          ].includes(c.type)
        );
        if (!isStartEndConsiderd) {
          graph.popupMenuHandler.popup(me.evt.pageX, me.evt.pageY, cells, me);
        } else {
          self.showAlert('Can not create group with start and end!');
        }
      } else if (
        (evt.which === 3 || evt.which === 2) &&
        cell &&
        (cell.type === SHAPE_TYPES.LANE ||
          cell.id === graph.getDefaultParent().id)
      ) {
        graph.popupMenuHandler.popup(me.evt.pageX, me.evt.pageY, cell, me);
      } else if (
        !sender?.enabled &&
        cell &&
        cell.type == SHAPE_TYPES.ACTIVITY
      ) {
        self.linkToBusinessFunction(cell);
      } else if (!sender?.enabled && cell && cell.type == SHAPE_TYPES.TASK) {
        self.navToUserTask(cell);
      } else if (!sender?.enabled && cell && cell.type == SHAPE_TYPES.BOS) {
        self.navToBuilder({
          uid: cell.isReference ? cell.refuid : cell.uid,
          label: cell.value,
          isOfflineBOS: cell?.isOfflineBOS || false
        });
      } else if (!sender?.enabled && cell && cell.type == SHAPE_TYPES.SCREEN) {
        // self.navToDesigner(cell.value);
        self.navToDesigner(
          cell.value,
          false,
          '',
          cell.isReference ? cell.refuid : cell.uid
        );
      } else if (!sender?.enabled && cell && cell.type == SHAPE_TYPES.SCRIPT) {
        if (cell.edges != null && cell.edges[0] != null) {
          let pageSource = cell.edges[0].source;
          if (pageSource.value.trim().length !== 0) {
            self.navToDesigner(
              pageSource.value,
              true,
              cell.value,
              pageSource.uid,
              cell.pageId,
              pageSource.value === cell.value
            );
          } else {
            self.showAlert('Invalid Page Name');
          }
        }
      } else if (cell && cell.type === SHAPE_TYPES.EYE) {
        if (cell?.parent?.edges) {
          let model = graph.getModel();
          var c = model.getParent(cell);
          const prevlockstate = c.isLockedAddHierarchy;
          c.isLockedAddHierarchy = !c.isLockedAddHierarchy;
          model.setVisible(c, true);
          self.isLoadingGraph = true;
          self.skipAction = true;
          self.toggleEyeIcon(c, cell);
          if (prevlockstate) {
            self.arrangeScreen(c, null, null, true);
          }
          self.skipAction = false;
          self.isLoadingGraph = false;
        }
      } else if (!sender?.enabled) {
        //Implemented to prevent running below condition in QA enviroment
        return;
      } else if (cell && !cell.edge) {
        /**
         *  Handling the grouping of cells and task together
         * */
        if (
          cell &&
          cell.type !== SHAPE_TYPES.MORE_OPTIONS_ICON &&
          cell.type !== SHAPE_TYPES.TABLE_ICON &&
          cell.type !== SHAPE_TYPES.EYE &&
          cell.type !== SHAPE_TYPES.TASK &&
          cell.type !== SHAPE_TYPES.BOS &&
          cell.type !== SHAPE_TYPES.DMN &&
          cell.type !== SHAPE_TYPES.SCRIPT &&
          cell.type !== SHAPE_TYPES.ACTIVITY
        ) {
          //On screen selection it triggers change event, bcoz on selection it hides the more options/
          //"skipAction" is added to skip this selection action from undoableEdit.
          self.skipAction = true;
          self.isLoadingGraph = true;
          let childs = cell.children ? cell.children : cell.parent.children;
          childs.forEach((child) => {
            if (child.type === SHAPE_TYPES.MORE_OPTIONS_ICON) {
              if (cell.type === SHAPE_TYPES.NOTE)
                child.geometry.offset = new mxgraph.mxPoint(
                  cell.geometry.width - 14,
                  7
                );
              child.visible = true;
              self.currentBlock = child;
              self.graph.orderCells(null, [cell]);
              self.graph.getModel().setVisible(cell, true);
            }
          });
          self.isLoadingGraph = false;
          self.skipAction = false;
        } else if (cell === null && self.currentBlock) {
          self.currentBlock.visible = false;
          self.graph.orderCells(null, [self.currentBlock]);
          self.currentBlock = null;
        }

        if (cell && cell.addLanebutton) {
          self.stateCell = { addLanebtnCell: cell };
          self.graph.selectionModel.clear();
          let parent = self.graph.getDefaultParent();
          let firstLane = parent.children.find(
            (e) =>
              e.type === SHAPE_TYPES.LANE && e.Lname === self.lanes[0]?.name
          );
          let LastLane = parent.children.find(
            (e) =>
              e.type === SHAPE_TYPES.LANE &&
              e.Lname === self.lanes[self.lanes.length - 1]?.name
          );
          if (
            self.graph.getModel().isCollapsed(firstLane) ||
            self.graph.getModel().isCollapsed(LastLane)
          ) {
            // self.expandLanes(firstLane, evt, true);
          }
          self.addLaneEventListener();
        } else if (cell && cell.type === SHAPE_TYPES.TABLE_ICON) {
          self.graph.setSelectionCells([cell.parent]);
          if (cell.parent.value.trim() !== '') {
            self.openDMNTable(cell.parent, cell.value);
            self.currentDMN = cell.parent.value;
          } else {
            self.showAlert('Enter name for DMN');
          }
          self.graph.setSelectionCells([cell.parent]);
        } else if (cell && cell.type === SHAPE_TYPES.MORE_OPTIONS_ICON) {
          graph.popupMenuHandler.popup(me.evt.pageX, me.evt.pageY, cell, me);
        } else if (cell && cell.type === SHAPE_TYPES.ICON) {
          graph.popupMenuHandler.popup(me.evt.pageX, me.evt.pageY, cell, me);
        } else if (cell && cell.type === 'NavBack') {
          self.handleGraphBackbtnClick(cell);
        } else if (cell && cell.type === 'NavLink') {
          self.handleNavLinkClick(cell);
        } else if (cell && cell.type) {
          var isGroupingCells = () => {
            let f = false;
            if (!cell.executeAsWorkflow || !cell.isOfflineBOS) {
              cells.push(cell);
            } else {
              return false;
            }
            for (let i = 0; i < cells.length; i++) {
              if (cells[i].type && !cells[i].executeAsWorkflow) {
                switch (cells[i].type) {
                  case SHAPE_TYPES.BOS:
                    f = !cells[i].isHierarchy;
                    break;
                  case SHAPE_TYPES.SCREEN:
                    f = true;
                    break;
                  case SHAPE_TYPES.XOR:
                    f = true;
                    break;
                  default:
                    f = false;
                    break;
                }
              } else {
                f = false;
              }
              if (!f) {
                return f;
              }
            }
            return f;
          };
          let isValidParent = false; //TO descope the mullti selection
          if (cells.length > 0) {
            if (
              cells[0].parent &&
              cell.parent &&
              cells[0].parent.id == cell.parent.id
            ) {
              isValidParent = true;
            } else {
              isValidParent = false;
            }
          }
          if (
            !cell.executeAsWorkflow &&
            !cell.isHierarchy &&
            !cell.isOfflineBOS &&
            self.type === 'businessFunction' &&
            isGroupingCells() &&
            isValidParent &&
            mxEvent.isShiftDown(me.getEvent())
          ) {
            self.graph.setSelectionCells(cells);
          } else {
            graph.setSelectionCells([cell]);
            if (
              cell.type !== SHAPE_TYPES.START &&
              (evt.target.tagName === 'DIV' || evt.target.tagName === 'SPAN') &&
              (cell.type === SHAPE_TYPES.BOS ||
                cell.type === SHAPE_TYPES.TASK ||
                cell.type === SHAPE_TYPES.EYE ||
                cell.type === SHAPE_TYPES.SCREEN ||
                cell.type === SHAPE_TYPES.NOTE ||
                cell.type === SHAPE_TYPES.PROCESS_CONDITION ||
                cell.type === SHAPE_TYPES.ACTIVITY ||
                cell.type === SHAPE_TYPES.DMN ||
                cell.type === SHAPE_TYPES.ASSIGNMENT ||
                cell.type === SHAPE_TYPES.EMAIL)
            ) {
              graph.startEditingAtCell(cell, evt);
            } else if (
              cell.type !== SHAPE_TYPES.START &&
              (evt.target.tagName === 'DIV' || evt.target.tagName === 'SPAN') &&
              cell.type === SHAPE_TYPES.LANE_NAME
            ) {
              self.graph.selectionModel.clear();
              self.renameLane(cell);
            } else {
              graph.stopEditing(true);
            }
          }
        }
      } else {
        if (cells.length > 1) self.graph.selectionModel.clear();
      }
      me.consume();
    },
  });

  // save state on change
  graph.getModel().addListener(mxEvent.CHANGE, function (sender, evt) {
    
    let editProp = evt.getProperty('edit');
    let canProcess = true;
    if (self._checkIsWorkflowApp() && editProp) {
      editProp.changes.map((ch) => {
        if (ch.constructor.name === 'mxStyleChange') {
          // Handling undo action back/fourth, maintaing "executeAsWorkflow" property"
          let cell = ch.cell;
          var targetStyle = graph.getCurrentCellStyle(cell);
          var strokeColor = mxgraph.mxUtils.getValue(
            targetStyle,
            'strokeColor'
          );

          // If dont have workflow style and have "executeAsWorkflow" custom property then we need to remove it
          if (
            strokeColor !== WORKFLOW_COLOR &&
            cell.executeAsWorkflow
          ) {
            delete cell.executeAsWorkflow;
          }

          // If have workflow style and dont have "executeAsWorkflow" custom property then we need to add it
          if (
            strokeColor === WORKFLOW_COLOR &&
            !cell.executeAsWorkflow
          ) {
            cell.executeAsWorkflow = 'yes';
          }
        } else if (ch.constructor.name === 'mxTerminalChange') {
          if (!self.isLoadingGraph) {
            if (ch.source) {
              // source is changed
              if (
                ch.previous &&
                ch.previous.type === SHAPE_TYPES.XOR &&
                ch.terminal &&
                ch.previous.id !== ch.terminal.id
              ) {
                canProcess = self.undoableError(self, `Source of an edge connected to XOR can't be changed!`);
              } else if (ch.previous && ch.terminal && [SHAPE_TYPES.DMN, SHAPE_TYPES.CASE].indexOf(ch.previous.type) !== -1) {
                canProcess = self.undoableError(self, `Source of an edge connected to ${ch.previous.type} can't be changed!`);
              } else if (ch.previous && ch.terminal && ch.terminal.type === SHAPE_TYPES.END) {
                canProcess = self.undoableError(self, `Source of an edge can't be changed to END!`);
              } else if (ch.previous && ch.terminal && ch.terminal.type === SHAPE_TYPES.XOR) {
                canProcess = self.undoableError(self, `Source of an edge can't be changed to XOR!`);
              } else if (ch.previous && ch.terminal && ch.terminal.type === SHAPE_TYPES.DMN) {
                self.handleDMNManualArrow(self.graph, ch.cell, ch.terminal);
              } else if (ch.previous && ch.terminal && ch.terminal.type === SHAPE_TYPES.CASE) {
                let data = JSON.parse(ch.terminal?.data || "{}");
                let newCaseOption = { id: ch.cell?.uid || "", value: "" };
                data = JSON.stringify({ ...data, caseOptions: [...(data?.caseOptions || []), newCaseOption] });
                self.changeComponentValue(ch.terminal, { name: ch.cell?.value || "", key: data });
              } else if (ch.previous && ch.terminal && ch.terminal.type === SHAPE_TYPES.START) {
                if (self.checkIfAlreadyEdgeConnected(ch.terminal, ch.cell)) {
                  canProcess = self.undoableError(self, `'START' component already have outgoing edge`);
                }
              } else if (ch.previous && ch.terminal && 
                [SHAPE_TYPES.BOS, SHAPE_TYPES.EMAIL, SHAPE_TYPES.ASSIGNMENT].includes(ch.terminal.type)
              ) {
                if (self.checkIfAlreadyEdgeConnected(ch.terminal, ch.cell)) {
                  canProcess = self.undoableError(self, `${ch.terminal.type} component already have outgoing edge`);
                }
              } else {
                canProcess = self.handleTerminalChange(graph, ch);
              }
            }

            if (canProcess) {
              canProcess = self.isInvalidExitAndEntryPoints(self, graph, ch.cell);
              if (canProcess) {
                canProcess = self.handleTerminalChange(graph, ch);
              }
            }
          }
        } else if (ch.constructor.name === 'mxChildChange') {
          // Any connected component should not be allowed lane change
          let previous = ch.previous;
          let parent = ch.parent;
          let child = ch.child;
          if ( child && previous?.isLane && parent?.isLane && previous.id !== parent.id && child.edges && child.edges.length > 0) {
            let allow = true;
            child.edges.forEach((ed) => {
              if (ed.target && !ed.isHierarchy) {
                allow = false;
              }
            });

            if (!allow) {
              // Preventing repeated undo...
              if (this.chidHandled !== child.uid) {
                this.chidHandled = child.uid;
                setTimeout(() => {
                  self.editor.undo();
                  self.editor.undo();
                }, 100);
              } else this.chidHandled = '';
              canProcess = false;
              self.showAlert(
                `Lane change is not allowed for connected component`
              );
            }
          }
          /*
            if (
            child &&
            child.edge &&
            child.source &&
            // child.source.type === SHAPE_TYPES.XOR &&
            [SHAPE_TYPES.XOR, SHAPE_TYPES.CASE].indexOf(child.source.type) !==
              -1 &&
            !self.isLoadingGraph
            ) {
              setTimeout(() => {
                let fun = undefined;
                if (child.source.type === SHAPE_TYPES.XOR) {
                  fun = 'adjustConditionArrows';
                } else if (child.source.type === SHAPE_TYPES.CASE) {
                  fun = 'adjustCaseArrows';
                }
                if (fun) {
                  self[fun](child.source);
                }
              }, 0);
            }
          */

          // Undo delete lane handler...
          if (
            ch.child.type === SHAPE_TYPES.LANE &&
            !ch.previous &&
            ch.child.deletedLane
          ) {
            self.handleUndoDeleteLane(ch);
          }
        }else if (ch.constructor.name === 'mxValueChange'){
          let cell = ch.cell;
          if(cell.type==='filterCell'){
              if(cell.cb){
                cell.cb(ch.value);//callback to show the filter icon
              }
            let Lname=cell.parent.Lname;
            self.lanes=self.lanes.map(e=>{
              if(e.name === Lname){
                if(e.inbox && e.inbox.filter!=ch.value){
                  if(ch.value!=""){
                    e.inbox={...e.inbox,filter:ch.value}
                  }else{
                    delete e.inbox.filter;
                  }
                }
              }
              return e;
            })
          }
        }
      });
    }


    // General (WF/AF) scenrio, we need to check
    if (canProcess){
      editProp.changes.map((ch) => {
        if (ch.constructor.name === 'mxChildChange') {
          let child = ch.child;
          if (child && child.edge && child.source && [SHAPE_TYPES.XOR, SHAPE_TYPES.CASE].indexOf(child.source.type) !==
            -1 && !self.isLoadingGraph) {  // If a component is deleted and it was connected from XR/CASE block, then we need to regenerate the connected arrows as by default they will also get deleted on deleting the component
            setTimeout(() => {
              let fun = undefined;
              if (child.source.type === SHAPE_TYPES.XOR) {
                fun = 'adjustConditionArrows';
              } else if (child.source.type === SHAPE_TYPES.CASE) {
                fun = 'adjustCaseArrows';
              }
              if (fun) {
                self[fun](child.source);
              }
            }, 0);
          }
        }
      });
    }
    

    var cell = evt.getProperty('cell'); // cell may be null
    if (cell != null) {
    }

    if (
      !self.isLoadingGraph &&
      sender &&
      sender.updateLevel <= 0 &&
      canProcess
    ) {
      let json = self.toJSON();
      self.saveSnapshot(json);
      self.isLoadingGraph = false;
    }
  });
  PillirGraph.prototype.changeComponentValue = function (
    selectedCell,
    variable,
    evt
  ) {
    let graph = this.graph;
    var cell = graph.getModel().getCell(selectedCell.id);
    selectedCell.data = variable.key;
    cell.data = variable.key;

    if(variable.hasOwnProperty("name")){
      labelValueChanged(cell, variable.name, null);
    }
    if (cell.type == SHAPE_TYPES.CASE) {
      this.adjustCaseArrows(cell);
    }else if (cell.type == SHAPE_TYPES.MENU) { // Save Menu Configuration
      // Need to autogenerate arrows based on the config instead of callig toJSon function
      // let json = self.toJSON();
      // self.saveSnapshot(json, false, true);
      this.adjustMenuArrows(cell);
    }
  };

  PillirGraph.prototype.adjustDMNArrows = function (
    cell,
    acidTransaction = true
  ) {
    let graph = this.graph;
    let self = this;
    let outgoingEdges = graph.getModel().getOutgoingEdges(cell);
    let values = outgoingEdges.map((e) => e.value);

    // Getting dmn table configuration...
    const dmnTblCel = cell.children.find((c) => c.type === 'tableIcon');
    const dmnTable = JSON.parse(dmnTblCel.value);
    const { rows } = dmnTable;

    if (Array.isArray(rows)) {
      let requiredEdges = [];
      rows.forEach((row, i) => {
        if (!values.includes(row.output)) {
          requiredEdges.push({
            index: i,
            row,
          });
        }
      });

      // Create new arrow if its not there...

      if (requiredEdges.length > 0 && acidTransaction) {
        self.graph.getModel().beginUpdate();
      }
      
      // let xPoint = undefined;
      // let startIndex = (rows.length - requiredEdges.length)+1;

      requiredEdges.forEach((e, i) => {
        graph.addEdge(
          self.createConnection(e.index, e.row.output, e.row.expr, e.row.id, {
            xPoint: 200,
          }),
          cell,
          cell,
          null
        );
      });
      if (requiredEdges.length > 0 && acidTransaction) {
        self.graph.getModel().endUpdate();
        self.forgetLastTransaction();
      }
    }
  };

  PillirGraph.prototype.adjustConditionArrows = function (
    xorCell,
    acidTransaction = true
  ) {
    let graph = this.graph;
    let self = this;
    let outgoingEdges = graph.getModel().getOutgoingEdges(xorCell);

    if (outgoingEdges.length < 2) {
      self.isLoadingGraph = true;
      self.skipAction = true;
      let requiredEdges = [];
      const yesEdge = outgoingEdges.find((oE) => {
        return oE.value === CONDITION_YES;
      });
      const noEdge = outgoingEdges.find((oE) => {
        return oE.value === CONDITION_NO;
      });

      if (!yesEdge) {
        requiredEdges.push(CONDITION_YES);
      }
      if (!noEdge) {
        requiredEdges.push(CONDITION_NO);
      }

      if (acidTransaction && requiredEdges.length > 0) {
        self.graph.getModel().beginUpdate();
      }

      requiredEdges.forEach((edgeValue, i) => {
        let conf = {
          exitX: 1,
          exitY: 0.5,
        };
        if (edgeValue === 'No') {
          conf = {
            exitX: 0.5,
            exitY: 1,
          };
        }
        let edgeCell = self.createConnectionForXOR(
          i * 4,
          edgeValue,
          self.generateUid(),
          conf
        );
        graph.addEdge(edgeCell, xorCell, xorCell, null);
      });

      if (acidTransaction && requiredEdges.length > 0) {
        self.graph.getModel().endUpdate();
      }
      self.skipAction = false;
      self.isLoadingGraph = false;
    }
  };

  PillirGraph.prototype.adjustCaseArrows = function (
    caseCell,
    acidTransaction = true
  ) {
    let graph = this.graph;
    let self = this;
    let outgoingEdges = graph.getModel().getOutgoingEdges(caseCell);
    
    const { data } = caseCell;
    const dataObj = safelyParseJSON(data);
    const { caseOptions } = dataObj;

    self.isLoadingGraph = true;
    if (acidTransaction) {
      self.graph.getModel().beginUpdate();
    }

    let requiredEdges = [];
    caseOptions.forEach((o)=>{
      let existingEdge = outgoingEdges.find(e => e.uid === o.id);
      if(!existingEdge){
        requiredEdges.push(o);
      } else if (existingEdge.value !== o.value){
        self.graph.getModel().setValue(existingEdge, o.value);
      }
    })

    const caseValues = caseOptions.map((o) => o.value);
    const extraEdges = outgoingEdges.filter(
      (n) => !caseValues.includes(n.value) && !n.target?.isHierarchy
    );

    let newStartCounter = outgoingEdges.filter((n) =>
      caseValues.includes(n.value)
    ).length; // Count existing edges, for next arrow position

    // Add required edges
    requiredEdges.forEach((e, i) => {
      if (e) {
        let edgeCell = self.createConnection(newStartCounter, e.value,undefined, e.id,{
          xPoint:200
        });
        graph.addEdge(edgeCell, caseCell, caseCell, null);
        newStartCounter++;
      }
    });

    // Remove extra edges
    graph.removeCells(extraEdges);
    if (acidTransaction) {
      self.graph.getModel().endUpdate();
    }
    self.isLoadingGraph = false;
  };


  PillirGraph.prototype.adjustMenuArrows = function ( cell) {
    let graph = this.graph;
    let self = this;
    let outgoingEdges = graph.getModel().getOutgoingEdges(cell);
    self.skipAction = true;
    graph.getModel().beginUpdate();

    // Remove extra edges
    if (outgoingEdges.length > 0) {
      graph.removeCells(outgoingEdges);
    }
    const { data } = cell;
    const cellOptions = safelyParseJSON(data);

    // Add required edges
    if(cellOptions){
      cellOptions.forEach((e, i) => {
        if (e) {
          let targetCell = cell.parent.children.find(ch => ch.uid === e.linkTo);
          if (targetCell) {
            let newEdge = this.createConnection(i, "", "", e.id);
            graph.addEdge(newEdge, cell.parent, cell, targetCell);
            graph.getModel().setStyle(newEdge, mxgraph.mxUtils.setStyle(newEdge.getStyle(), 'endArrow', "none")); // remove endArrow style
            this.handleArrowBends(graph, [newEdge]) // Reset the line, remove all bends
          }
        }
      });
    }
    

    graph.getModel().endUpdate();
    self.skipAction = false;
    
  };

  const labelValueChanged = function (cell, value, evt) {
    let nvalue = '';
    if(cell.type && cell.type === SHAPE_TYPES.NOTE) {
      nvalue = value ? value.trim() : '';
      if(nvalue && !nvalue?.startsWith("<div>")){
        nvalue = nvalue?.replace("<div>", "</div><div>");
        nvalue = "<div>" + nvalue;
        nvalue = nvalue.replaceAll("<br>", '');
      }
    }else {
      nvalue = value ? value.replace(/&nbsp;/g, '').replace(/(<([^>]+)>)/gi, '').trim() : '';
    }
    if (cell.type & (cell.type !== SHAPE_TYPES.XOR && cell.type !== SHAPE_TYPES.NOTE)) {
      nvalue = value.replace(/(<([^>]+)>)/gi, ''); // to remove the html tag's from text auto generated by mxgraph
      nvalue = nvalue.replace(/&amp;/g, '');
      nvalue = nvalue.replace(/[^\w\s]+/g, ''); //replace special characters
      // nvalue=nvalue.replace(/[ ]+/g, ""); //remove spaces in text
    }
    let method;
    if (cell.type === SHAPE_TYPES.TASK) {
      method = !cell.modified ? 'create' : 'update';
      if (!cell.modified) cell.modified = true;
    }
    var model = graph.getModel();
    model.beginUpdate();
    try {
      var old = cell.value;
      graph.cellLabelChanged(cell, nvalue, false);
      graph.fireEvent(
        new mxgraph.mxEventObject(
          mxEvent.LABEL_CHANGED,
          'cell',
          cell,
          'value',
          nvalue,
          'old',
          old,
          'event',
          evt
        )
      );

      if (
        cell.type !== SHAPE_TYPES.SCREEN &&
        cell.type !== SHAPE_TYPES.XOR &&
        cell.type !== SHAPE_TYPES.CASE &&
        cell.type !== SHAPE_TYPES.PROCESS_CONDITION &&
        cell.type !== SHAPE_TYPES.NOTE
      ) {
        /**
         * @LabelHeightChange
         *  - increase the cell height base on the client height of the label.
         * As the cell height is getting calculated internaly by mx graph
         * creating label with width of cell and performing height update based on the size of label + cell.
         */
        var geo = graph.getCellGeometry(cell);
        geo = geo.clone();
        let para = document.createElement('p');
        para.innerText = cell.value;
        para.style['wordBreak'] = 'break-all';
        document.body.append(para);
        /**
         *  @Here using cellSize defined as per the shapes.js in mxgraph-wraper
         *  : so to have the height to calculated w.r.to base height / width.
         * */
        para.style.width = '136px';
        let labelHeight = para.offsetHeight;
        // if (cell.type === "Note") {
        //   let labelWidth = para.offsetWidth;
        //   geo.width = labelWidth > 20 ? 60 + labelHeight : 80;
        // }
        geo.height = labelHeight > 20 ? 60 + labelHeight : 80;
        graph.getModel().setGeometry(cell, geo);
        para.remove();
      }
      if (cell.type === SHAPE_TYPES.TASK) {
        self.updateUserTask(old, nvalue, method);
      } else if (cell.type === SHAPE_TYPES.SCREEN) {
        if (old) {
          self.updatePageName(old, nvalue, cell);
          var a =
            cell.edges &&
            cell.edges.filter(
              (e) => e.isHierarchy && e.target && e.target.value === old
            );
          if (a && a.length > 0) {
            var scell = a[0].target;
            scell.value = nvalue;
            graph.cellLabelChanged(scell, nvalue, false);
            graph.fireEvent(
              new mxgraph.mxEventObject(
                mxEvent.LABEL_CHANGED,
                'cell',
                scell,
                'value',
                nvalue,
                'old',
                old,
                'event',
                evt
              )
            );
          }
        }
      } else if (cell.type === SHAPE_TYPES.BOS) {
        self.updateBOSName(old, nvalue, cell);
      } else if (cell.source?.type === SHAPE_TYPES.DMN) {
        self.handleDMNManualArrow(graph, cell, cell.source, true);
      }
    } finally {
      model.endUpdate();
    }
    return cell;
  };

  graph.labelChanged = function (cell, value, evt) {
    let newVal = value
      ? value
          .replace(/&nbsp;/g, '')
          .replace(/(<([^>]+)>)/gi, '')
          .trim()
      : '';
    //Validation gose here based on validation call this function
    var x = self.toJSON();
    var flag = '';
    const dublicateCheck = function (c) {
      if (
        c &&
        c.type !== SHAPE_TYPES.CONNECTOR &&
        c.type !== SHAPE_TYPES.NOTE &&
        c.name.toLowerCase() === newVal.toLowerCase() &&
        c.id != cell.id
      ) {
        flag = 'duplicate';
        return false;
      } else {
        return true;
      }
    };
    if (cell.edge && cell.source.type === SHAPE_TYPES.DMN && newVal !== '') {
      //  self.addConnectorToDMN(cell,newVal)
      // let dmn = JSON.parse(cell.source.children[0].value)
      // let r = {'output':newVal}
      // dmn.columns.forEach((c,i)=> { r = {...r, [c]:'*'} })
      // dmn.rows.push(r)
      // let model = graph.getModel()
      // model.beginUpdate();
      // try { model.setValue(cell.source.children[0],JSON.stringify(dmn)) }
      // finally {
      //   model.endUpdate();
      // }
    }
    if (!!cell.edge && newVal.length > 20) {
      flag = 'invalidEdgeName';
    } else if (newVal.length > 30 && cell.type !== SHAPE_TYPES.NOTE) {
      flag = 'invalidCellName';
    } else if (newVal.length > 1024 && cell.type === SHAPE_TYPES.NOTE) {
      flag = 'invalidNoteName';
    } else if (newVal === '' && cell.type !== SHAPE_TYPES.NOTE) {
      flag = 'empty';
    } else if (
      x &&
      x.graph &&
      x.graph.lanes.length != 0 &&
      cell.source === null &&
      cell.type !== SHAPE_TYPES.NOTE
    ) {
      x.graph.lanes.map((l) => {
        if (l.children) {
          let utask = l.children.filter((e) => e.type === SHAPE_TYPES.TASK);
          var lchildren = [...l.children];
          if (utask && utask.length) {
            utask.map((ut) => {
              if (ut.childTask && ut.childTask.length) {
                lchildren = [...lchildren, ...ut.childTask];
              }
            });
          }
          // NOTE: Commented as backlog-may 3 need to check name duplication on all cases.
          // let typechildren = lchildren.filter(e=>e.type === cell.type)
          if (lchildren && lchildren.length)
            lchildren.map((c) => {
              if (!dublicateCheck(c)) {
                return false;
              }
            });
        }
      });
    } else if (
      x &&
      x.graph &&
      x.graph.task &&
      cell.source === null &&
      !self.processRequireLane &&
      cell.type !== SHAPE_TYPES.NOTE
    ) {
      const utask = [...x.graph.task, ...self.businessFunctionData];
      let typeTask = utask.filter((e) => e.type === cell.type);
      if (typeTask && typeTask.length)
        typeTask.map((c) => {
          if (!dublicateCheck(c)) {
            return false;
          }
        });
    }
    if (flag !== '') {
      if (flag == 'invalidEdgeName' || flag == 'invalidCellName') {
        self.showAlert(
          `Name should be of max length ${flag == 'invalidCellName' ? 30 : 20}`
        );
      } else if (flag == 'invalidNoteName') {
        self.showAlert(`Note should be of max length 1024`);
      } else {
        self.showAlert(
          flag === 'empty'
            ? 'Provide valid name'
            : flag == 'Special'
            ? 'Special Characters Not allowed'
            : 'Name already exist'
        );
      }
      labelValueChanged(cell, cell.value, evt);
      setTimeout(() => {
        graph.selectionModel.setCell(cell);
        graph.startEditingAtCell(cell);
      }, 10);
    } else {
      labelValueChanged(cell, value, evt);
    }
  };

  graph.getSelectionModel().addListener(mxEvent.CHANGE, function (sender, evt) {
    if (sender.cells[0]) {
      if (sender.cells[0].type !== SHAPE_TYPES.DMN) {
        self.closeDMNTable();
      } else if (sender.cells[0].value !== self.currentDMN) {
        self.closeDMNTable();
      }
    }
  });

  graph.addListener(mxEvent.CELLS_ADDED, function (sender, evt) {
    let cell = evt.properties.cells[0];
    if (self._checkIsWorkflowApp()) {
      // self.adjustLaneHeightFlexibly(self, cell);
    }
  });

  graph.addListener(mxEvent.CELLS_REMOVED, function (sender, evt) {
    let cell = evt.properties.cells[0];
    if (self._checkIsWorkflowApp()) {
      //&& cell && cell.type !== SHAPE_TYPES.CONNECTOR
      // self.adjustLaneHeightFlexibly(self, cell, true);
    }
  });

  graph.addListener(mxEvent.CELLS_MOVED, function (sender, evt) {
    let cell = evt.properties.cells[0];
    if (cell && cell.type !== SHAPE_TYPES.CONNECTOR) {
      // self.handleArrowBends(self.graph, cell?.edges || []);
    }
    if (
      cell &&
      cell.type === SHAPE_TYPES.CONNECTOR &&
      cell.source === null &&
      cell.target === null
    ) {
      return false;
    }
    if (cell && [
        SHAPE_TYPES.DMN, 
        SHAPE_TYPES.EMAIL, 
        SHAPE_TYPES.ASSIGNMENT
      ].includes(cell.type)
    ) {
      // By Defauls DMN cell are workflow components
      cell.executeAsWorkflow = 'yes';
      graph
        .getModel()
        .setStyle(
          cell,
          cell.getStyle() +
            ';strokeColor=' +
            WORKFLOW_COLOR +
            ';strokeWidth=' +
            BORDER_WIDTH_WORKFLOW_COMP +
            ';'
        );
      if(cell.type === SHAPE_TYPES.DMN){
        self.closeDMNTable();
      }
    } else if (cell && cell.type === SHAPE_TYPES.XOR) {
      // Create default edges
      self.adjustConditionArrows(cell);
    } else if (cell && cell.type === SHAPE_TYPES.CASE) {
      // Create default edges
      self.adjustCaseArrows(cell);
    } else if (cell && cell.type === SHAPE_TYPES.SCREEN) {
      self.hideScreenHierachy(cell);
    }
    if (self._checkIsWorkflowApp()) {
      // if (!self.isLaneChange(cell)) {
      // self.adjustLaneHeightFlexibly(self, cell);
      // }
    }
  });

  //Resize Not Allowed for note less then 48 x 48
  graph.resizeCells = function (cells, bounds, recurse) {
    recurse = recurse != null ? recurse : this.isRecursiveResize();
    /*
    NOTE: as in the TDD to enable resize from center
    if(cells.length && cells[0].type===SHAPE_TYPES.NOTE ){
      let isOutOfBound=false;
      if(bounds[0].width<50){
        isOutOfBound=true;
      }
      if(bounds[0].height<50){
        isOutOfBound=true;
      }
      cells[0].children.forEach(c=>{
        if(c.visible && c.type===SHAPE_TYPES.MORE_OPTIONS_ICON){
          c.visible=false;
        }
      })
      if(isOutOfBound){
        return cells;
      }
    } */
    this.model.beginUpdate();
    try {
      var prev = this.cellsResized(cells, bounds, recurse);
      this.fireEvent(
        new mxgraph.mxEventObject(
          mxEvent.RESIZE_CELLS,
          'cells',
          cells,
          'bounds',
          bounds,
          'previous',
          prev
        )
      );
    } finally {
      this.model.endUpdate();
    }

    return cells;
  };

  //Handle dblClick
  graph.addListener(mxEvent.DOUBLE_CLICK, function (sender, evt) {
    var cell = evt.getProperty('cell');
    if (cell?.edge) cell.type = SHAPE_TYPES.CONNECTOR;
    try {
      if (cell && (cell.type == SHAPE_TYPES.MENU || (cell.type == 'MenuIcon' && cell.parent.type == SHAPE_TYPES.MENU))) {
        self.openMenuPropertyPanel(cell.type == 'MenuIcon' ? cell.parent : cell);
        self.graph.stopEditing();
      }else if (
        cell &&
        (cell.type == SHAPE_TYPES.CASE ||
          (cell.type == 'Icon' && cell.parent.type == SHAPE_TYPES.CASE))
      ) {
        self.openCasePropertyPanel(cell.type == 'Icon' ? cell.parent : cell);
        self.graph.stopEditing();
      } else if (
        cell &&
        // (cell.type == SHAPE_TYPES.XOR || cell.parent.type == SHAPE_TYPES.XOR)
        cell.type == SHAPE_TYPES.XOR
      ) {
        self.openVariablePanel(cell);
        self.graph.stopEditing();
      } else if(cell && cell.type === SHAPE_TYPES.ASSIGNMENT){
        self.toggleAssignmentPanel(true, cell);
        self.graph.stopEditing();
      } else if(cell && cell.type === SHAPE_TYPES.EMAIL){
        self.toggleEmailPanel(true, cell);
        self.graph.stopEditing();
      }else if (
        cell &&
        (cell.type == SHAPE_TYPES.START ||
          cell.parent.type == SHAPE_TYPES.START)
      ) {
        if (self.appType !== 'Ofl') {
          // self.navToBuilder({ uid: cell.uid, label: "StartService" });
        } else {
          self.toggleOfflinePropSidebar({
            offlineProperty: JSON.parse(cell.value),
            cell: cell,
          });
        }
      } else if (
        cell &&
        (cell.value.trim().length > 0 || cell.type == SHAPE_TYPES.ICON)
      ) {
        if (cell.type == SHAPE_TYPES.ICON) cell = cell.parent;
        switch (cell.type) {
          case SHAPE_TYPES.SCREEN:
            // self.navToDesigner(cell.value);
            self.navToDesigner(
              cell.value,
              false,
              '',
              cell.isReference ? cell.refuid : cell.uid
            );
            break;
          case SHAPE_TYPES.SCRIPT:
            if (cell.edges != null && cell.edges[0] != null) {
              let pageSource = cell.edges[0].source;
              if (pageSource.value.trim().length !== 0) {
                self.navToDesigner(
                  pageSource.value,
                  true,
                  cell.value,
                  pageSource.uid,
                  cell.pageId,
                  pageSource.value === cell.value
                );
              } else {
                self.showAlert('Invalid Page Name');
              }
            }
            break;
          case SHAPE_TYPES.BOS:
            self.navToBuilder({
              uid: cell.isReference ? cell.refuid : cell.uid,
              label: cell.value,
              isOfflineBOS: cell?.isOfflineBOS || false
            });
            break;
          case SHAPE_TYPES.TASK:
            self.navToUserTask(cell);
            break;
          case SHAPE_TYPES.CONNECTOR:
            if (_canHaveValue(cell)) {
              self.graph.startEditingAtCell(cell, evt);
            } else {
              self.graph.stopEditing();
            } /*else{
              if (!_isTransitionArrow(graph, cell)) {
                self.graph.startEditingAtCell(cell, evt)
              } else {
                const valueEdge = _getValueEdge(cell);
                if (valueEdge) {
                  self.graph.startEditingAtCell(valueEdge, evt)
                } else {
                  self.graph.stopEditing();
                }
              }
            }*/

            break;
          case SHAPE_TYPES.PROCESS_CONDITION:
            self.graph.startEditingAtCell(cell, evt);
            break;
          case SHAPE_TYPES.ACTIVITY:
            self.linkToBusinessFunction(cell);
            break;
          case SHAPE_TYPES.DMN:
            self.graph.setSelectionCells([cell]);
            if (cell.value.trim() !== '') {
              let dmnExpCell = cell.children.find(
                (e) => e.type === SHAPE_TYPES.TABLE_ICON
              );
              self.openDMNTable(cell, dmnExpCell.value);
              self.currentDMN = cell.value;
            } else {
              self.showAlert('Enter name for DMN');
            }
            self.graph.setSelectionCells([cell]);
            break;
          default:
            self.graph.stopEditing();
        }
      } else if (!cell) {
        self.graph.stopEditing();
      } else if (
        cell &&
        cell.type &&
        cell.type !== SHAPE_TYPES.START &&
        cell.type !== SHAPE_TYPES.END &&
        !cell.isIcon
      ) {
        self.showAlert('Enter name');
      }
    } catch (e) {}
    // if(cell?.type == SHAPE_TYPES.LANE && self.graph.getModel().isCollapsed(cell)) {
    //   self.expandLanes(cell, new mxEventObject('foldCells','cell'), false);
    // }
  });
};

PillirGraph.prototype.isLaneChange = function (cell) {
  let cellGeo = cell?.geometry;
  let parentGeo = cell?.parent?.geometry;
  return cellGeo?.y > parentGeo?.height || cellGeo?.y < 0;
};

PillirGraph.prototype.isLoadingGraph = false;
PillirGraph.prototype.skipAction = false;
PillirGraph.prototype.createDragPreview = function (width, height, svg) {
  var elt = document.createElement('div');
  //elt.style.border = '1px dashed black';
  elt.style.width = width + 'px';
  elt.style.height = height + 'px';
  elt.appendChild(svg);
  return elt;
};

PillirGraph.prototype.createDragableElt = function (
  tasksDrag,
  processTools,
  isDrag
) {
  var self = this;
  this.sideBarEnabledTools = [...processTools];
  Array.prototype.slice.call(tasksDrag).forEach((ele) => {
    const index = ele.getAttribute('data-value');
    var value;
    if (index === SHAPE_TYPES.SCREEN) {
      var deploy = self.getDeploymentPlatform() === 'web' ? 0 : 1;
      value = self.sideBarItems[index][deploy];
    } else {
      value = self.sideBarItems[index];
    }

    if (undefined == value) return;

    value.dragEnabled = true;

    ele.appendChild(value.node);
    //ele.appendChild(value.svg);
    if (isDrag) {
      let ds = mxgraph.mxUtils.makeDraggable(
        ele.childNodes[0],
        self.graph,
        (graph, evt, target, x, y) =>
          self.dragSourceListener(graph, evt, target, x, y, value),
        self.createDragPreview(
          value.geometry.width,
          value.geometry.height,
          value.svg
        ),
        0,
        0,
        self.graph.autoscroll,
        true,
        true
      );
      ds.isGuidesEnabled = function () {
        return true;
      };
    }
    //ds.createDragElement = mxgraph.mxDragSource.prototype.createDragElement;
  });
};

PillirGraph.prototype.processRequireLane = true;
PillirGraph.prototype.dragSourceListener = function (
  graph,
  evt,
  target,
  x,
  y,
  value
) {
  var self = this;
  let cell = this.processObjects[value.type]();
  cell.value = self.getDefaultName(cell);
  var cells = [cell];
  //cells.uid = this.generateUid();
  if (value.type === SHAPE_TYPES.SCREEN) {

    let newScreeNname = self.getDefaultName({
      type:SHAPE_TYPES.SCREEN
    })


    let screen = new Screen({
      content: previewImg,
      name: newScreeNname,
      deployment: self.getDeploymentPlatform(),
    });
    // screen.value = self.getDefaultName(screen); 
    screen.value = newScreeNname;
    cells = [screen];
  }
  if (this.beforeObjectAdd({ src: cells, target: target })) {
    //Adding only if the container allows the add object
    var model = graph.getModel();
    cells = graph.getImportableCells(cells);
    var flag = false;
    if (this.processRequireLane) {
      if (target && target.isLane) {
        flag = true;
      }
    } else {
      if (target == null) {
        flag = true;
        target = graph.getDefaultParent();
      }
    }
    //if ((cells[0].type == SHAPE_TYPES.SCRIPT || cells[0].type == SHAPE_TYPES.BOS) && !this.processRequireLane) {
    // cells[0].connectable = false;
    //}

    if (cells.length > 0 && flag) {
      graph.stopEditing();
      model.beginUpdate();

      cells = graph.importCells(cells, x, y, target);

      model.endUpdate();
      if (cells[0].clickToEditable) {
        if (cells[0].type !== SHAPE_TYPES.NOTE) {
          graph.startEditingAtCell(cells[0], evt);
        } else {
          graph.setSelectionCell(cells[0]);
        }
      }

      // In case, when page is created in a lane containing "menu", we need to call create page so that default screenshot can be shown in APM
      let menuCell = cells[0].parent.children.find(ch => ch.type === SHAPE_TYPES.MENU);
      if (value.type === SHAPE_TYPES.SCREEN && menuCell) {
        self.createPageWithMenu(cells[0], menuCell)
      }
      
      this.afterObjectAdded({ src: cells, target: target });
    }
  }
};
PillirGraph.prototype.getDefaultName = function (
  cell,
  cloneFlag = false,
  creatingref = false
) {
  var self = this;
  var getName = function (type) {
    switch (type) {
      case SHAPE_TYPES.BOS:
        return 'BOS';
        break;
      case SHAPE_TYPES.TASK:
        return 'Group';
        break;
      case SHAPE_TYPES.SCREEN:
        return 'Page';
        break;
      case SHAPE_TYPES.ACTIVITY:
        return 'BF';
        break;
      case SHAPE_TYPES.DMN:
        return 'DMN';
        break;
      case SHAPE_TYPES.MENU:
        return 'MENU';
        break;
      case SHAPE_TYPES.XOR:
        return cloneFlag;
        break;
      case SHAPE_TYPES.EMAIL:
        return "Send_Email";
        break;
      case SHAPE_TYPES.ASSIGNMENT:
        return "Assignment";
        break;
      default:
        return null;
        break;
    }
  };
  let name = getName(cell.type);
  if (name) {
    let data = self.toJSON();
    let laneChildres = [];
    if (data.graph.lanes && data.graph.lanes.length) {
      data.graph.lanes.map((e) => {
        if (e.children) laneChildres = [...laneChildres, ...e.children];
      });
    }
    let children = self.processRequireLane
      ? laneChildres
      : [...data.graph.task, ...self.businessFunctionData];
    let utask = children.filter((e) => e.type === SHAPE_TYPES.TASK);
    var lchildren = [...children];
    if (self.processRequireLane && utask && utask.length) {
      utask.map((ut) => {
        if (ut.childTask && ut.childTask.length) {
          lchildren = [...lchildren, ...ut.childTask];
        }
      });
    }
    let nooftypes = lchildren.filter((e) => e.type === cell.type);
    if (cloneFlag) {
      nooftypes = lchildren.filter(
        (e) => e.parentCellVal === cell.parentCellVal
      );
    }
    if (nooftypes && nooftypes.length) {
      if (!cloneFlag) name = `${name}_${nooftypes.length + 1}`;
      else if (creatingref) name = `${cloneFlag}_ref_${nooftypes.length + 1}`;
      else name = `${cloneFlag}_copy_${nooftypes.length + 1}`;
    } else {
      if (!cloneFlag) name = `${name}_1`;
      else if (creatingref) name = `${cloneFlag}_ref_1`;
      else name = `${cloneFlag}_copy_1`;
    }
    let copyCount = lchildren.filter((e) => e.name === name);
    if (copyCount && copyCount.length) {
      if (!cloneFlag) name = `${name}_of_${copyCount.length}`;
      else if (creatingref)
        name = name = `${cloneFlag}_ref_of_${copyCount.length}`;
      else name = `${cloneFlag}_copy_of_${copyCount.length}`;
    }
    return name;
  } else {
    return '';
  }
};

PillirGraph.prototype.getDeploymentPlatform = function (val) {};

PillirGraph.prototype.createLaneProcess = function (
  obj,
  LaneName,
  dataModel,
  addLanebtnCell,
  geometry
) {
  var self = this;
  var laneCell = [];
  let isMultipleLane = false;
  try {
    var parent = self.graph.getDefaultParent();
    var model = this.graph.getModel();
    model.beginUpdate();
    if (obj !== 'role' && obj.workflow === 'no') {
      isMultipleLane = false;
    } else if (
      obj === 'role' &&
      dataModel.lanes &&
      dataModel.lanes.length > 0 &&
      dataModel.lanes[0].workflow === 'no'
    ) {
      isMultipleLane = false;
    }
    var lane = new Lane(LaneName, isMultipleLane, self._checkIsWorkflowApp());
    /*Process Designer only requires this
    if (geometry) {
      lane.geometry = geometry;
    }*/
    var cells = [lane];
    var g = self.toJSON();
    var index = 0;
    if (g && g.graph && g.graph.lanes) {
      index = g.graph.lanes.length;
    }
    cells = self.graph.getImportableCells(cells);
    if (index === 0 && !geometry) {
      laneCell = self.graph.importCells(cells, 0, index, parent);
    }

    if (self.stateCell && self.stateCell.addLanebtnCell && !geometry) {
      laneCell = self.graph.importCells(
        cells,
        0,
        self.stateCell.addLanebtnCell.getParent().geometry.height * index,
        parent
      );
      model.setVisible(self.stateCell.addLanebtnCell, false);
      self.stateCell = undefined;
    }
    if (geometry) {
      laneCell[0] = self.graph.addCell(lane, parent);
      model.setVisible(addLanebtnCell, false);
    }
  } finally {
    model.endUpdate();
  }
  if (index === 0 && typeof obj === 'object') {
    self.startEndDefault(self.graph, obj?.appType ?? obj.type, obj);
  }
  if (index === 0) {
    self.editor.undoManager.clear();
  }
  setTimeout(function () {
    let laneNameSelect = document.getElementsByClassName('laneNameSelect');
    var target = laneNameSelect[laneNameSelect.length - 1];
    if (self.laneRoleData && target) {
      self.renderLaneRoles(LaneName, laneCell, target, obj);
    }
  });
  return laneCell[0];
};
PillirGraph.prototype.triggerChangeEvent = function (changeCellValue) {
  var self = this;
  self.graph.getModel().beginUpdate();
  var model = self.graph.getModel();
  try {
    var enc = new mxgraph.mxCodec();
    var node = enc.encode(self.graph.getModel());

    var codec = new mxgraph.mxCodec();
    codec.lookup = function (id) {
      return model.getCell(id);
    };

    var changes = [];
    var change = codec.decode(node);
    change.model = model;
    //change.execute();
    changes.push(change);

    var edit = new mxgraph.mxUndoableEdit(model, false);
    edit.changes = changes;
    self.graph.getModel().setVisible(changeCellValue, true);
    model.fireEvent(
      new mxgraph.mxEventObject(
        mxEvent.CHANGE,
        'edit',
        edit,
        'changes',
        changes
      )
    );
  } finally {
    self.graph.getModel().endUpdate();
  }
};

/*
handleUndoDeleteLane: We need to handle undo after deleting lane
*/

PillirGraph.prototype.handleUndoDeleteLane = function (ch) {
  var self = this;
  delete ch.child.deletedLane;
  let deletedLane = ch.child;
  let deletedLaneIndex = ch.index;
  setTimeout(function () {
    self.graph.getModel().beginUpdate();
    // Page preview rendering...
    if (deletedLane.children.length > 0) {
      let screenCells = [];
      deletedLane.children.forEach((s) => {
        if (s.type === SHAPE_TYPES.SCREEN) {
          screenCells.push(s);
        }
      });
      if (screenCells.length > 0) {
        self.renderPageScreen(screenCells);
      }
    }

    // Need to adjust height (+550px) of bottom lanes..

    /*
      let parent = self.graph.getDefaultParent();
      self.graph.orderCells(null, parent);
      let allLanes = parent.children.filter((e) => e.type === SHAPE_TYPES.LANE);
      for (let i = deletedLaneIndex + 1; i < allLanes.length; i++) {
        allLanes[i].geometry.y = allLanes[i].geometry.y + 550;
        self.graph.orderCells(null, [allLanes[i], parent]);
      }
    */

    let laneNameSelect = document.getElementsByClassName('laneNameSelect');
    var target = laneNameSelect[deletedLaneIndex];
    if (deletedLane.appDetail) {
      self.lanes.push(deletedLane.appDetail);
    }
    if (target) {
      self.renderLaneRoles(
        deletedLane.Lname,
        [deletedLane],
        target,
        'role',
        deletedLaneIndex
      );
    }

    self.graph.getModel().endUpdate();
  }, 100);
};

PillirGraph.prototype.handleDeleteRoles = async function (
  self,
  laneCell,
  islastLane = false,
  appDetail
) {
  self.graph.getModel().beginUpdate();
  let parent = self.graph.getDefaultParent();
  self.graph.orderCells(null, parent);

  //Marking lane as deleted, so while undoing we will be able to identify this was deleted lane undo.
  laneCell[0].deletedLane = true;

  let index = parent.children.findIndex((e) => e.id === laneCell[0].id);
  let childvalue = parent.children.filter((e) => e.type === SHAPE_TYPES.LANE);
  if (index !== -1) {
    // Adjusting height of below swimlanes to fill up the gap of this lane.
    for (let i = index + 1; i < childvalue.length; i++) {
      let geo = childvalue[i].geometry.clone();
      geo.y = geo.y - 550;
      self.graph.getModel().setGeometry(childvalue[i], geo);
    }

    let xrs = [];
    let dmns = [];
    let cases = [];

    laneCell[0].children.forEach((c) => {
      let incomingEdges = self.graph.getModel().getIncomingEdges(c);
      incomingEdges.forEach((ied) => {
        if (ied.source.parent.id !== laneCell[0].id) {
          if (ied?.source?.type === SHAPE_TYPES.XOR) {
            // Filter XR components
            xrs.push(ied.source);
          } else if (ied?.source?.type === SHAPE_TYPES.DMN) {
            // Filter DMN components
            dmns.push(ied.source);
          } else if (ied?.source?.type === SHAPE_TYPES.DMN) {
            // Filter CASE components
            cases.push(ied.source);
          }
        }
      });
    });
    laneCell[0].appDetail = appDetail;

    laneCell[0].index = index;
    self.graph.getModel().setVisible(laneCell[0], false);

    // Deleting mane lane...
    self.graph.removeCells(laneCell);

    // Re-generating the XR default edages if deleted due to cell delete
    xrs.forEach((c) => {
      self.adjustConditionArrows(c, false);
    });

    // Re-generating the DMN default edages if deleted due to cell delete
    dmns.forEach((dmn) => {
      self.adjustDMNArrows(dmn, false);
    });

    // Re-generating the CASE default edages if deleted due to cell delete
    cases.forEach((dmn) => {
      self.adjustCaseArrows(dmn, false);
    });
  }

  // Make the bottom "+" (Add more lane) icon visible
  let lastLane = self.lanes[self.lanes.length - 1].name;
  let lastLCell = parent?.children.find((e) => e.Lname === lastLane);
  // if (islastLane) { // Need to keep visible the "+" icon
  let a = lastLCell?.children.find((e) => e.type === SHAPE_TYPES.ICON);
  self.graph.getModel().setVisible(a, true);
  // }

  self.graph.getModel().endUpdate();
  alertShow({
    message: apmMessage.S3502,
    type: 'success',
  });
  setTimeout(() => {
    self.checkAndMakeLaneActive(self);
  }, 0);
};

PillirGraph.prototype.renderLaneRoles = async function (
  LaneName,
  laneCell,
  target,
  obj,
  index = 0
) {

  var self = this;
  const hasWorkItemFilter=(lane)=>{
    let l=self.graph.getModel().getCell(lane.id);
    if(l.children){
     if(l.children.find(c=>c.type===SHAPE_TYPES.START)){
        return false;
     }
     return true;
    }
    return true;
  }

  const hasFilter = (cb) => {
    let flag = false;
    let l = self.lanes.find(e => e.name === LaneName);
    if (l) {
      if (l.inbox?.filter) {
        flag = true;
      }
      let c = self.getFilterCell(l?.inbox?.filter ?? '', laneCell[0].geometry);
      c.cb=cb;
      self.isLoadingGraph = true;
      self.skipAction = true;
      self.graph.getModel().beginUpdate();
      self.graph.addCell(c, laneCell[0]);
      self.graph.getModel().endUpdate();
      self.skipAction = false;
      self.isLoadingGraph = false;
    }
    cb(flag);
    return flag;
  }
  var roledropdownCell = laneCell[0]?.children?.[2];
  let isCollapsed = self.graph.getModel().isCollapsed(laneCell[0]);
  
  target.innerHTML = '';
  target.innerText = LaneName;
  let lInfo = self.lanes.find(l => l.name === laneCell[0].Lname)
  const _getNavItems = () =>{
    let items = [];
    items=
      getPermissions()?.projects?.business_function?.canUpdate &&
          !self.graph.getModel().isCollapsed(laneCell[0])
          ? self._checkIsWorkflowApp()
            ? hasWorkItemFilter(laneCell[0]) ? [
              { name: 'Change Role' },
              { name: 'Edit App Details' },
              { name: apmMessage.T3518 },
              { name: 'Inbox Preview' },
              { name: 'WorkItem Filter' }
            ] : [
              { name: 'Change Role' },
              { name: 'Edit App Details' },
              { name: apmMessage.T3518 },
              { name: 'Inbox Preview' },
            ]
            : [{ name: 'Change Role' }, { name: 'Edit App Details' }]
          : []
        // };
        if (lInfo?.app){
          items.push({ name: lInfo?.app?.menu === "yes" ? "Disable Menu" : "Enable Menu" })
        }
    /* if(getPermissions()?.projects?.business_function?.canUpdate && !self.graph.getModel().isCollapsed(laneCell[0])){
      items = [
        { name: 'Change Role' }, 
        { name: 'Edit App Details' }
      ];
      if (self._checkIsWorkflowApp()){
        items = items.concat([
          { name: apmMessage.T3518 },
          { name: 'Inbox Preview' },
        ])
      }
      if (lInfo?.app){
        items.push({ name: lInfo?.app?.menu === "yes" ? "Disable Menu" : "Enable Menu" })
      }
    } */
    if(!self.graph.enabled){//QA and Prd onlly have access to multilingual
      items=[{ name: "Enable language" }];
    }
    return items;
  }
  const handleToogleMenu = () => {
    if (lInfo?.app?.menu === "yes") {
      self.removeMenuComponent(
        self.graph,
        lInfo?.app.role
      );
    } else {
      self.drawMenuComponent(
        self.graph,
        lInfo?.app.role,
        false
      );
    }
  }

  setTimeout(() => {
    ReactDOM.render(
      <ThemeProvider theme={PillirTheme}>
        <NavItem
          key='role'
          id='graph-title'
          identifier='role'
          title={LaneName}
          changeRole={(cb) => {
            // if(!index) {
            self.changeRole(laneCell[0]?.Lname, cb);
            // }else {
            //   self.changeLaneRole(laneCell[0]?.Lname, cb);
            // }
          }}
          openEditAppDetails={() => self.openEditAppDetails(laneCell[0]?.Lname)}
          openDeleteRole={() =>
            self.openDeleteRole(laneCell[0]?.Lname, laneCell, isCollapsed)
          }
          handleToogleMenu={handleToogleMenu}
          openInboxPreview={() => self.openInboxPreview(laneCell[0]?.Lname)}
          openWorkItemFilter={(cb)=>{
            self.openWorkItemFilter(laneCell[0], cb,)
          }}
          openMultiLingual={()=>{
            self.openMultiLingual();
          }}
          valueChanged={(value) => {
            laneCell[0].Lname = value;
            self.graph.getModel().beginUpdate();
            var model = self.graph.getModel();
            try {
              if (!index) {
                var enc = new mxgraph.mxCodec();
                var node = enc.encode(self.graph.getModel());

                var codec = new mxgraph.mxCodec();
                codec.lookup = function (id) {
                  return model.getCell(id);
                };

                var changes = [];
                var change = codec.decode(node);
                change.model = model;
                //change.execute();
                changes.push(change);
                // var deletelane = new mxgraph.mxUndoableEdit(model, false);
                // deletelane.changes = changes;
                var edit = new mxgraph.mxUndoableEdit(model, false);
                edit.changes = changes;
                self.graph.getModel().setVisible(laneCell[0], true);
                model.fireEvent(
                  new mxgraph.mxEventObject(
                    mxEvent.CHANGE,
                    'edit',
                    edit,
                    'changes',
                    changes
                    // "deletelane",
                    // deletelane
                  )
                );
              } else {
                // self.graph.getModel().setVisible(laneCell[0], true);
                self.graph.orderCells(null, [laneCell[0]]);
              }
            } finally {
              self.graph.getModel().endUpdate();
            }
          }}
          // items={
          //   getPermissions()?.projects?.business_function?.canUpdate &&
          //   !self.graph.getModel().isCollapsed(laneCell[0])
          //     ? self._checkIsWorkflowApp()
          //       ? [
          //           { name: 'Change Role' },
          //           { name: 'Edit App Details' },
          //           { name: apmMessage.T3518 },
          //           { name: 'Inbox Preview' },
          //         ]
          //       : [{ name: 'Change Role' }, { name: 'Edit App Details' }]
          //     : []
          // }
          showWorkItemFilterOption={()=>{
            return hasWorkItemFilter(laneCell[0]);
          }}
          items={_getNavItems()}
          orderCells={() => {
            self.isLoadingGraph = true;
            self.graph.orderCells(null, [roledropdownCell]);
            self.isLoadingGraph = false;
          }}
          isWorkflow={self._checkIsWorkflowApp()}
          isCollapsed={isCollapsed}
          onCollapseExpand={(toCollapse) => {
            if (!toCollapse)
              self.expandLanes(
                laneCell[0],
                new mxEventObject('foldCells', 'cell'),
                false
              );
            else
              self.collapseLanes(
                laneCell[0],
                new mxEventObject('foldCells', 'cell'),
                false
              );
          }}
          hasFilter={(cb)=>{hasFilter(cb)}}
          processLinked={self.processLinked}
          navigateToProcess={() => {
            self.navToProcess(true);
          }}
          getNavItems={() => {
            lInfo = self.lanes.find(l => l.name === laneCell[0].Lname);
            return _getNavItems();
          }}
        />
      </ThemeProvider>,
      target
    );
  }, 100);
};
PillirGraph.prototype.graphTitle = null;
PillirGraph.prototype.setGraphTitle = function (Path, process = '') {
  var self = this;
  if (this.graphTitle) {
    this.graph.removeCells(this.graphTitle);
  }
  var parent = this.graph.getDefaultParent();
  var title = new Title(Path);
  var model = self.graph.getModel();
  var cells = self.graph.getImportableCells([title]);
  model.beginUpdate();
  cells = self.graph.importCells(cells, 0, 0, parent);
  self.graphTitle = cells;
  model.endUpdate();

  var link = Path.split('/');

  ReactDOM.render(
    <ThemeProvider theme={PillirTheme}>
      <GraphTitle
        value={link}
        process={process}
        navigate={() => self.navToBusinessFunction(true)}
        navigateToProcess={() => self.navToProcess(true)}
      />
    </ThemeProvider>,
    document.getElementById('mx_graphTitle')
  );
};

PillirGraph.prototype.setScreenImages = function (data) {
  var self = this;
  var platform = self.getDeploymentPlatform();
  data.forEach(async (d) => {
    var e = {};
    
    this.screens.map((s) => {
      if(s.id === d.value || s.id === d.name)
      {
        e = s;
      }
    })
    // let e = this.screens?.find((s) => s.id === d.value || s.id === d.name);
    if (e) {
      if (
        document.getElementById(e.id) &&
        document.getElementById(e.id).parentElement != null
      ) {
        document.getElementById(e.id).parentElement.style.pointerEvents =
          'none';
        let base64Image;
        try {
          base64Image = await fetch(e.image).then((resp) => resp.text());
        } catch (error) {}
        
        if(e.hasOwnProperty("imageInBase64")){
          base64Image = e.image
        }
        let target = document.getElementById(e.id);
        if (e.image && e.image !== '' && target) {
          ReactDOM.render(
            <ThemeProvider theme={PillirTheme}>
              <img
                src={base64Image}
                style={{
                  borderRadius: platform === 'web' ? '5px' : '8px',
                  width: '120px',
                  height: platform === 'web' ? '73px' : '245px',
                  pointerEvents: 'none',
                  marginTop: platform === 'web' ? '7px' : 0,
                }}
              />
            </ThemeProvider>,
            target
          );
        }
      }
    }
  });
  /*this.screens && this.screens.map(async e => {

    
  })*/
};

PillirGraph.prototype.updateOflComponents = function (oflProperty) {
  let self = this;
  let graph = this.graph;
  let parent = graph.getDefaultParent().children[0];
  var startCell = parent.children.find((e) => e.type === SHAPE_TYPES.START);
  let nvalue = JSON.stringify(oflProperty);
  let old = startCell.value;
  graph.getModel().beginUpdate();
  try {
    if (oflProperty.isSameDelta === 'no') {
      let deltadownloadCell = parent.children.find(
        (e) => e.uid === oflProperty.deltaDownloadUid
      );
      if (!deltadownloadCell) {
        let service = SHAPE_TYPES.BOS;
        var cell4 = processObjects[service]();
        cell4.geometry.x = 192;
        cell4.geometry.y = 400;
        cell4.uid = oflProperty?.deltaDownloadUid || '';
        cell4.value = 'Delta Download';
        cell4.name = 'Delta Download';
        cell4 = graph.addCell(cell4, parent);
        var r = graph.connectVertexPillir(
          startCell,
          'south',
          graph.defaultEdgeLength,
          null,
          cell4,
          null,
          null,
          OFFLINE_APP_ARROW
        );
        if (r) {
          var edge = r[0];
          cell4 = r[1];
          cell4.geometry.x = 192;
          cell4.geometry.y = 400;
          cell4.connectable = false;
          cell4.isOfflineBOS = true;
          graph.orderCells(null, [cell4]);
          edge.isHierarchy = true;
          edge.geometry.points = [new mxgraph.mxPoint(192 - 58, 400 + 18)];
          graph.getModel().setVisible(edge, false);
          graph.getModel().setVisible(edge, true);
        }
      }
    } else {
      let deltadownloadCell = parent.children.find(
        (e) => e.uid === oflProperty.deltaDownloadUid
      );
      if (deltadownloadCell) {
        graph.removeCells([deltadownloadCell]);
      }
    }
    graph.cellLabelChanged(startCell, nvalue, false);
    graph.fireEvent(
      new mxgraph.mxEventObject(
        mxEvent.LABEL_CHANGED,
        'cell',
        startCell,
        'value',
        nvalue,
        'old',
        old,
        'event',
        {}
      )
    );
  } finally {
    graph.getModel().endUpdate();
  }
};
PillirGraph.prototype.getStartTaskGroup = function (geometry) {
  return {
    id: '2',
    type: 'StartDefault',
    name: '',
    geometry: {
      x: geometry.x > 90 ? geometry.x - 90 : 90,
      y: 182,
      width: 28,
      height: 28,
    },
    uid: this.generateUid(),
    parent: '1',
    connectable: true,
    children: [],
  };
};
PillirGraph.prototype.getEdgeObject = function (
  uid,
  id,
  start,
  end,
  style,
  value,
  stateValue,
  transitions
) {
  return {
    uid,
    id: id,
    type: 'connector',
    name: value ? value : '',
    style: style
      ? style
      : 'edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;',
    stateValue: stateValue ? stateValue : '',
    geometry: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      relative: true,
    },
    parent: '1',
    start: start,
    end: end,
    transitions
  };
};
PillirGraph.prototype.getEndTaskGroup = function (id, geometry) {
  return {
    id: id,
    type: 'EndDefault',
    name: ' ',
    geometry: {
      x: geometry.x + 200,
      y: 182,
      width: 28,
      height: 28,
    },
    uid: this.generateUid(),
    parent: '1',
    connectable: false,
    children: [],
  };
};
PillirGraph.prototype.toPillirObjectFromCell = function (node) {
  var obj = {};
  if (node.edge == false) {
    obj.id = node.id;
    obj.type = node.type;
    obj.name = node.value;
    obj.geometry = {};
    obj.uid = node.uid;
    obj.geometry.x = node.geometry.x;
    obj.geometry.y = node.geometry.y;
    obj.geometry.width = node.geometry.width;
    obj.geometry.height = node.geometry.height;
    obj.parent = '1';
    obj.data = node.data;
    obj.connectable = node.connectable;
    obj.isHierarchy = node.isHierarchy;

    if (node.type == 'Screen') {
      obj.isLockedAddHierarchy = node.isLockedAddHierarchy;
    }
  }
  //usertask Connectors
  else if (node.edge) {
    obj.id = node.id;
    obj.uid = node.uid;
    obj.type = SHAPE_TYPES.CONNECTOR;
    obj.name = node.value; //event line value
    obj.isHierarchy = node.isHierarchy;
    obj.style = node.style;
    obj.uid = node.uid;
    obj.geometry = node.geometry;
    obj.parent = node.parent.id;
    // obj.start=node.source ? (node.source.type==='Screen' && node.source.type ? '15': node.source.id):null;
    obj.start = node.source ? node.source.id : null;
    obj.end = node.target ? node.target.id : null;
    obj.transitions = node.transitions;
    if (node.stateLabel) {
      obj.stateValue = node.stateLabel.value;
      obj.state = node.stateLabel.value;
    }
  }
  return obj;
};
PillirGraph.prototype.groupTask = function (evt) {
  let self = this,
    graph;
  self.groupingInProgress = true;
  if (graph == undefined || graph == null) {
    graph = this.graph;
  }
  let childTask = [];
  let cells = graph.getSelectionCells();
  cells.sort(function (a, b) {
    return a.geometry.x - b.geometry.x;
  });
  let nodes = cells.filter((c) => !c.edge);
  let edges = [];
  let bound = 300;
  let maxid = 4;
  let isinvalidGroup = false,
    requireReverse = false;
  const isNodeSelected = (cell) => {
    return nodes.find((f) => f?.id === cell?.id);
  };
  const ifAlreadyEdgeAdded = (edg, edgs = []) => {
    return (
      edgs.filter(
        (j) => j.source.id === edg.source.id && j.target.id === edg.target.id
      )?.length || 0
    );
  };
  let outgoingNodes = 0;
  let incomingNodes = 0;
  let incomingTarget = null;
  let outgoingSource = null;
  let startEdge = [];
  let endEdge = [];
  nodes.forEach((c, index) => {
    if (maxid < c.id) {
      maxid = c.id;
    }
    if (c.edges) {
      let incomingEdges = graph.getModel().getIncomingEdges(c);
      incomingEdges = incomingEdges.filter((e) => !e.isHierarchy);
      let outgoingEdges = graph.getModel().getOutgoingEdges(c);
      outgoingEdges = outgoingEdges.filter((e) => !e.isHierarchy);
      let unSelectedIECount = 0;
      let a = incomingEdges.forEach((e) => {
        if (!isNodeSelected(e.source)) {
          incomingTarget = index;
          startEdge.push(e);
          unSelectedIECount++;
        } else if (!ifAlreadyEdgeAdded(e, edges)) {
          edges.push(e);
        }
      });
      if (unSelectedIECount > 0) {
        incomingNodes++;
      }
      let unSelectedOECount = 0;
      let b = outgoingEdges.forEach((e) => {
        if (!isNodeSelected(e.target)) {
          outgoingSource = index;
          endEdge.push(e);
          unSelectedOECount++;
        } else if (!ifAlreadyEdgeAdded(e, edges)) {
          edges.push(e);
        }
      });
      if (unSelectedOECount > 0) {
        outgoingNodes++;
        if (unSelectedOECount > 2) outgoingNodes++;
      }
      if (c.type === SHAPE_TYPES.SCREEN) {
        let hierachy = c.edges.filter((e) => e.isHierarchy);
        if (hierachy) {
          hierachy.forEach((h) => {
            if (h.target.type === SHAPE_TYPES.BOS) {
              if (maxid < h.target.id) {
                maxid = h.target.id;
              }
              childTask.push(self.toPillirObjectFromCell(h.target));
              edges.push(h);
            }
            cells.push(h.target);
          });
        }
      }
    }

    childTask.push(self.toPillirObjectFromCell(c));
  });

  let isLastComponentIsXor = endEdge.filter(
    (e) => e.source?.type === SHAPE_TYPES.XOR
  );
  if (incomingNodes > 1 || outgoingNodes > 1 || isLastComponentIsXor.length) {
    isinvalidGroup = true;
  }
  if (isinvalidGroup) {
    let message = apmMessage.E3503;
    if (incomingNodes > 1) message += apmMessage.E3505;
    else if (outgoingNodes > 1) message += apmMessage.E3506;
    else if (isLastComponentIsXor.length) message += apmMessage.E3507;
    if(self.highlightedCells.length){
      self.highlightedCells.map((e) => {
        e.highlightObj.hide();
      });
      self.highlightedCells = [];
    }
    self.graph.selectionModel.clear();
    self.showAlert(message);
  } else {
    graph.stopEditing();
    graph.getModel().beginUpdate();

    let x = nodes[0].geometry.x;
    if (x > bound) {
      nodes = nodes.map((e) => {
        let geo = e.geometry.clone();
        geo.x = geo.x - (x - bound);
        graph.getModel().setGeometry(e, geo);
        return e;
      });
    }

    // self.handleArrowBends(self.graph, edges || []);

    edges.forEach((c) => {
      if (maxid < c.id) {
        maxid = c.id;
      }
      childTask.push(self.toPillirObjectFromCell(c));
    });
    let start = 0,
      end = nodes.length - 1;
    if (incomingTarget !== null) {
      start = incomingTarget;
    }
    if (outgoingSource !== null) {
      end = outgoingSource;
    }
    if (requireReverse) {
      let temp = start;
      start = end;
      end = temp;
    }
    let endEdgeStyle = 'edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;';
    if(nodes[end]?.type === SHAPE_TYPES.SCREEN){
      if(!(endEdge?.[0]?.transitions?.length)){
        endEdgeStyle += "dashed=1;";
      }
    }
    childTask.push(self.getStartTaskGroup(nodes[start].geometry));
    childTask.push(self.getEndTaskGroup(maxid + 1, nodes[end].geometry));
    childTask.push(self.getEdgeObject(generateUid(), maxid + 2, 2, nodes[start].id)); // adding the arrow for start to firsst node
    childTask.push(self.getEdgeObject(endEdge[0]?.uid, maxid + 3, nodes[end].id, maxid + 1,  endEdgeStyle, '', '', endEdge[0]?.transitions)); // adding the arrow for end from last node
    let groupCell = processObjects[SHAPE_TYPES.TASK]();
    groupCell.uid = generateUid();
    groupCell.geometry.x = x; // cells[start].geometry.x + bound;
    groupCell.geometry.y =
      cells[start].geometry.y +
      (cells[start].geometry.height - groupCell.geometry.height) / 2;
    groupCell.value = self.getDefaultName(groupCell);
    groupCell.name = groupCell.value;
    groupCell.childTask = childTask;
    groupCell = graph.addCell(groupCell, cells[0].parent);
    let incommingEdge = startEdge,
      outgoingEdge = endEdge;
    // if (nodes[start].edges) {
    //   //TO-DO if is need to connect the arrow
    //   incommingEdge = nodes[start].edges.filter(e => e.target.id === nodes[start].id);

    // }
    // if (nodes[end].edges) {
    //   //TO-DO if is need to connect the arrow
    //   outgoingEdge = nodes[end].edges.filter(e => e.source.id === nodes[end].id);

    // }
    if (incommingEdge) {
      incommingEdge.map((e, i) => {
        let c = self.toPillirObjectFromCell(e);
        self.makeInOutEdgesForTask(c, e.source, groupCell, groupCell.parent);
      });
    }
    if (outgoingEdge) {
      outgoingEdge.map((e, i) => {
        let c = self.toPillirObjectFromCell(e);
        c.transitions = [];
        c.uid = generateUid();
        c.style = c.style.replaceAll("dashed=1;", '');
        self.makeInOutEdgesForTask(c, groupCell, e.target, groupCell.parent);
      });
    }
    graph.removeCells(cells);
    this.unshiftRightSideCells(groupCell);
    graph.getModel().endUpdate();
  }
  self.groupingInProgress = false;
};

PillirGraph.prototype.makeInOutEdgesForTask = function (c, startCell, endCell, parent) {
    let self = this;
    let graph = self.graph;
    let name = self.handleInteractedArrows(c);
    let edgeCell = self.makeEdge(c);
    edgeCell.transitions = c.transitions || [];
    // In workflow apps, arrows connecting to end/start component should have the state transition
    if (
      self._checkIsWorkflowApp() &&
      ((endCell && endCell.id === c.end) ||
        (startCell && startCell.id === c.start))
    ) {
      if (
        !edgeCell
          .getStyle()
          .indexOf('strokeColor=' + WORKFLOW_COLOR) > 0
      ) {
        edgeCell.setStyle(
          edgeCell.getStyle() +
            ';strokeColor=' +
            WORKFLOW_COLOR +
            ';strokeWidth=' +
            BORDER_WIDTH_WORKFLOW_COMP +
            ';'
        );
      }
    }
    edgeCell = graph.addEdge(edgeCell, parent, startCell, endCell);
    if (c.stateValue && c.stateValue != ''){
      self.addStateLabel(edgeCell, c.stateValue, false);
    }
    if(name){
      self.addIntractionLabel(edgeCell, name, true);
    }
}

PillirGraph.prototype.unshiftRightSideCells = function (cell) {
  let self = this;
  let nodes = cell.childTask.filter(
    (e) =>
      e.type != SHAPE_TYPES.STARTDEFAULT &&
      e.type != SHAPE_TYPES.ENDDEFAULT &&
      e.type != SHAPE_TYPES.CONNECTOR
  );
  let hghtWdthObj = this.getMaxHeightWidthOfGrpCells(cell, nodes);
  let lane = cell.parent || {};
  let startofAdjucentItemsX = cell.geometry.x + cell.geometry.width;
  lane.children.map((child) => {
    if (
      (child.type === SHAPE_TYPES.DMN ||
        child.type === SHAPE_TYPES.SCREEN ||
        child.type === SHAPE_TYPES.XOR ||
        child.type === SHAPE_TYPES.CASE ||
        child.type === SHAPE_TYPES.BOS ||
        child.type === SHAPE_TYPES.END) &&
      child.id !== cell.id
    ) {
      if (child.geometry.x > startofAdjucentItemsX) {
        let x = child.geometry.x - hghtWdthObj.totalWidth + cell.geometry.width;
        let geometry = child.geometry.clone();
        geometry.x = x;
        self.graph.getModel().setGeometry(child, geometry);
        self.handleArrowBends(self.graph, child?.edges || []);
      } /* else if ((child.geometry.y > startofAdjucentItemsY) 
      && !(child.geometry.x > startofAdjucentItemsX) 
      && !(child.geometry.x < cell.geometry.x)) { 
        child.setGeometry({
          ...child.geometry,
          y: (child.geometry.y - hghtWdthObj.totalHeight) + cell.geometry.height
        });
      }*/
    }
  });
};
const MIN_MARGIN_BETWEEN_CELLS = 80;
PillirGraph.prototype.getMaxHeightWidthOfGrpCells = function (
  cell,
  nodes,
  excludeArrows = false
) {
  let totalGrpWidthAfterExp = 0;
  let totalGrpHeightAfterExp = 0;
  //Start X and Start Y is to find actual space needed for the components inside the task. discarding the start and end default
  let startX = nodes[nodes.length - 1].geometry.x; //since already sorted by x.
  let startY = nodes[nodes.length - 1].geometry.y;
  nodes.map((e) => {
    if (
      (excludeArrows && !e.isHierarchy && !e.source && !e.target) ||
      !excludeArrows
    ) {
      if (e.geometry.x + e.geometry.width > totalGrpWidthAfterExp) {
        // e.geometry.x = totalGrpWidthAfterExp;
        totalGrpWidthAfterExp = e.geometry.x + e.geometry.width;
      }

      if (e.geometry.y + e.geometry.height > totalGrpHeightAfterExp) {
        totalGrpHeightAfterExp = e.geometry.y + e.geometry.height;
      }
      if (startY > e.geometry.y) {
        startY = e.geometry.y;
      }
      if (startX > e.geometry.x) {
        startX = e.geometry.x;
      }
    }
  });
  return {
    totalHeight: totalGrpHeightAfterExp - startY,
    totalWidth: totalGrpWidthAfterExp - startX,
  };
};

PillirGraph.prototype.unGroupTask = function (cell, jsonData) {
  let self = this,
    graph;
  let model = self.graph.getModel();
  let incommingEdges, outgoingEdges;
  if (cell.edges) {
    incommingEdges = cell.edges.filter((e) => e.target?.id === cell.id);
    outgoingEdges = cell.edges.filter((e) => e.source.id === cell.id);
  }

  let start = jsonData.find((e) => e.type == SHAPE_TYPES.STARTDEFAULT);
  let end = jsonData.find((e) => e.type == SHAPE_TYPES.ENDDEFAULT);
  let nodes = jsonData.filter(
    (e) =>
      e.type != SHAPE_TYPES.STARTDEFAULT &&
      e.type != SHAPE_TYPES.ENDDEFAULT &&
      e.type != SHAPE_TYPES.CONNECTOR
  );
  let edges = jsonData.filter((e) => e.type == SHAPE_TYPES.CONNECTOR);
  let seEdges = edges.filter((e) => e.start == start.id || e.end == end.id);
  edges = edges.filter((e) => e.start != start.id && e.end != end.id);

  start = seEdges.find((e) => e.start == start.id);
  end = seEdges.find((e) => e.end == end.id);

  nodes.sort(function (a, b) {
    return a.geometry.x - b.geometry.x;
  });

  /* 
    * Algo to pervent overlapping...
    * Get total width as groupExpandWidth of group after expanding
    * Get total height as groupExpandHeight of group after expanding
    * Shift all the right elements to right after groupExpandWidth
      -Figure out the starting point for shifting
    * Shift all the below elements to bottom after groupExpandHeight
      Figure out the starting point for shifting
      -Increase height of this and subsequent lanes if required
  */

  model.beginUpdate();
  let hghtWdthObj = this.getMaxHeightWidthOfGrpCells(cell, nodes);

  let lane = cell.parent || {};
  let laneHeight = lane.geometry.height;
  let startofAdjucentItemsX = cell.geometry.x + cell.geometry.width;
  let startofAdjucentItemsY = cell.geometry.y + cell.geometry.height;
  let requiredArea = new mxgraph.mxGeometry(
    cell.geometry.x,
    cell.geometry.y,
    hghtWdthObj.totalWidth + 40,
    hghtWdthObj.totalHeight + 40
  );
  let otherTypes = [
    SHAPE_TYPES.DMN,
    SHAPE_TYPES.SCREEN,
    SHAPE_TYPES.XOR,
    SHAPE_TYPES.CASE,
    SHAPE_TYPES.BOS,
    SHAPE_TYPES.END,
  ];
  let findRequiredRegionAvailable = function (area) {
    let cells = self.graph.selectRegion(area, null);
    let filteredCells = cells.filter(
      (c) => cell.id !== c.id && COMPONENTS.indexOf(c.type) !== -1
    );
    return filteredCells.length === 0;
  };
  // if(!findRequiredRegionAvailable(requiredArea)){
  lane.children.map((child) => {
    if (otherTypes.indexOf(child.type) !== -1) {
      if (child.geometry.x > startofAdjucentItemsX) {
        let geometry = child.geometry.clone();
        let x = child.geometry.x + hghtWdthObj.totalWidth - cell.geometry.width;
        geometry.x = x;
        // child.setGeometry(geometry);
        model.setGeometry(child, geometry);
        self.handleArrowBends(self.graph, child?.edges || []);
      } else if (
        child.geometry.y > startofAdjucentItemsY &&
        !(child.geometry.x > startofAdjucentItemsX)
        // && !(child.geometry.x < cell.geometry.x)
      ) {
        let y =
          child.geometry.y + hghtWdthObj.totalHeight - cell.geometry.height;
        let geometry = child.geometry.clone();
        geometry.y = y;
        // child.setGeometry(geometry);
        model.setGeometry(child, geometry);
        self.handleArrowBends(self.graph, child?.edges || []);
        if (child.geometry.y + child.geometry.height > laneHeight) {
          laneHeight = child.geometry.y + child.geometry.height;
        }
      }
    }
  });
  // }
  let startBoundDiff = nodes[0].geometry.x - cell.geometry.x;
  nodes = nodes.map((e) => {
    e.geometry.x = e.geometry.x - startBoundDiff;
    if (e.geometry.y + e.geometry.height > laneHeight) {
      laneHeight = e.geometry.y + e.geometry.height;
    }
    return e;
  });
  lane.children.forEach((child) => {
    if (otherTypes.indexOf(child.type) !== -1) {
      if (child.geometry.y + child.geometry.height > laneHeight) {
        laneHeight = child.geometry.y + child.geometry.height;
      }
    }
  });
  self.graph.selectionModel.clear();
  self.graph.stopEditing();
  if (lane.geometry.height < laneHeight) {
    // lane.setGeometry({ ...lane.geometry, height: laneHeight + 60 });
    let allLanes = cell.parent?.parent?.children || [];
    let laneIndex;
    let lastLaneY = 0;
    allLanes.map((otherLane, index) => {
      let geo = otherLane.geometry.clone();
      if (cell.parent.id === otherLane.id) {
        laneIndex = index;
        geo.height = laneHeight + 60;
        // otherLane.geometry = geo;
        model.setGeometry(otherLane, geo);
      }
      if (index > laneIndex) {
        geo.y = lastLaneY;
        // otherLane.geometry = geo;
        model.setGeometry(otherLane, geo);
      }
      lastLaneY = lastLaneY + otherLane.geometry.height;
      return otherLane;
    });
  }
  model.endUpdate();
  self.fromJSON(
    { task: [...nodes, ...edges] },
    self.graph,
    'Onl',
    (vertices) => {
      let vertex = vertices;
      let inout = [];
      if (incommingEdges) {
        incommingEdges.forEach((e, i) => {
          vertex['start' + i] = e.source;
          let stateValue = null;
          if (e.children) {
            let stateData = e.children.filter(f => f.type !== SHAPE_TYPES.INTRACTION_LABEL) || [];
            if(stateData && stateData.length > 0){
              stateValue = e.children[0].value;
            }
          }
          inout.push(
            self.getEdgeObject(
              e.uid,
              e.id,
              'start' + i,
              start.end,
              e.style,
              e.value,
              stateValue,
              e.transitions
            )
          );
        });
      }
      if (outgoingEdges && end) {
        outgoingEdges.forEach((e, i) => {
          vertex['end' + i] = e.target;
          let stateValue = null;
          if (e.children) {
            let stateData = e.children.filter(f => f.type !== SHAPE_TYPES.INTRACTION_LABEL) || [];
            if(stateData && stateData.length > 0){
              stateValue = e.children[0].value;
            }
          }
          let sourceComponent = vertex?.[end?.start || ""] || null;
          if( sourceComponent && sourceComponent.type === SHAPE_TYPES.SCREEN && !end?.transitions?.length ){
            e.style += "dashed=1;";
          }
          inout.push(
            self.getEdgeObject(
              end.uid,
              e.id,
              end.start,
              'end' + i,
              e.style,
              e.value,
              stateValue,
              end.transitions
            )
          );
        });
      }
      if (inout) {
        inout.forEach((c) => {
          self.makeInOutEdgesForTask(
            c, 
            c.start != null ? vertex[c.start] : undefined, 
            c.end != null ? vertex[c.end] : undefined ,
            cell.parent
          )
        });
      }
      self.graph.removeCells([cell]);
      self.graph.refresh();
    },
    cell.parent
  );
  self.skipAction = true;
  let parent = self.graph.getDefaultParent();
  let cells = [];
  let screenCells = [];
  let laneCount = 0,
    LaneInfo = [];
  parent.children.forEach((e) => {
    if (e.type == SHAPE_TYPES.LANE) {
      cells.push(e);
      let screens = e.children.filter((f) => f.type === SHAPE_TYPES.SCREEN);
      screenCells = [...screenCells, ...screens];
      LaneInfo.push({ cell: e, index: laneCount });
      laneCount++;
    }
  });
  if (screenCells.length) {
    self.renderPageScreen(screenCells, null, () => null);
  }
  LaneInfo.forEach((l) => {
    let t = (ll) => {
      setTimeout(function () {
        let laneNameSelect = document.getElementsByClassName('laneNameSelect');
        var target = laneNameSelect[ll.index];
        if (self.laneRoleData && target) {
          self.renderLaneRoles(
            ll.cell.Lname,
            [ll.cell],
            target,
            'role',
            ll.index
          );
        }
      }, 0);
    };
    t(l);
  });
  self.skipAction = false;
};

PillirGraph.prototype.handleDMNRowDelete = function (cell) {
  if (cell.source) {
    let childCell = cell.source.children?.[0];
    let value = childCell?.value || '';
    if (value) {
      let parsed = JSON.parse(value);
      let rows = parsed?.rows || [];
      if (rows) {
        rows = rows?.filter((e) => e?.id !== cell.uid);
        let finalData = { ...parsed, rows };
        var model = this.graph.getModel();
        model.beginUpdate();
        try {
          model.setValue(childCell, JSON.stringify(finalData));
        } finally {
          model.endUpdate();
        }
      }
    }
  }
};
PillirGraph.prototype.renderPageScreen = function (
  screens,
  vertices,
  callback
) {
  let self = this;
  let graph = this.graph;
  const updateEye = (screenCell) => {
    screenCell.isLockedAddHierarchy = true;
    graph.orderCells(null, [screenCell]);
    let count = screenCell.getHierarchyEdgeCount();
    if (!!count) {
      let geo = new mxgraph.mxGeometry(
        40,
        screenCell.geometry.height - 4,
        138,
        12
      );
      let title = new ScreenSubtitle(count, geo);
      title.parent = screenCell;
      if (vertices) {
        screenCell.children.push(title);
      }
      let closeeye = new Icon(
        new mxgraph.mxGeometry(8, screenCell.geometry.height + 12, 16, 10),
        null,
        closeEyeIcon
      );
      closeeye.type = SHAPE_TYPES.EYE;
      closeeye.parent = screenCell;
      if (vertices) {
        screenCell.children.push(closeeye);
      }
      self.arrangeScreen(screenCell, null, null, true, true);
      graph.orderCells(null, [screenCell]);
      graph.getModel().setVisible(screenCell, true);
    }
  };
  screens.map(async (e) => {
    let screenCell = graph.getModel().getCell(vertices ? vertices[e.id].id : e.id);
    if(screenCell){
      const screenScript = self.getScreenImage(e.name, e.uid);
      self.isLoadingGraph = true;
      screenCell.isLockedAddHierarchy = true;
      //graph.orderCells(null, [screenCell]);
      graph.getModel().setVisible(screenCell, true);
      self.arrangeScreen(screenCell, null, null, true, true);
      screenCell = graph.getModel().getCell(vertices ? vertices[e.id].id : e.id);
      if (screenScript && Object.keys(screenScript).length != 0) {
        screenScript[0].childTask && screenScript[0].childTask.map((f, index) => {
          var data = f;
          var cells = processObjects[SHAPE_TYPES.SCRIPT]();
          cells.value = data.name;
          cells.name = SHAPE_TYPES.SCRIPT;
          cells.pageId = screenScript[0].pageId;
          cells.uid = data.uid;
          cells.isScreenHierarchycell = true;
          cells.connectable = false;
          cells.parent = screenCell.parent;
          if (e.name == screenScript[0].pageName) {
            setTimeout(() => {
              self.isLoadingGraph = true;
              var r = graph.connectVertexPillir(screenCell, 'south', graph.defaultEdgeLength, null, cells, null, null, DOUBLE_SIDE_ARROW);
              if (r) {
                var edge = r[0];
                if (screenScript[0].pageName !== data.name) {
                  edge.value = data.events && data.events !== 'onPageShow' ? data.events : 'onClick';
                } else if (screenScript[0].pageName == data.name) {
                  edge.value = 'onPageShow';
                }
                edge.isHierarchy = true;
                edge.uid = data.uid;
                edge.geometry.points = [
                  new mxgraph.mxPoint(
                    edge.target.geometry.x - 58,
                    edge.target.geometry.y + 18
                  ),
                ];
                graph.getModel().setVisible(edge, false);
                graph.getModel().setVisible(edge, true);
                try {
                  var h = graph.createEdgeHandler(
                    graph.getView().getState(edge),
                    edge.style
                  );
                  h.moveLabel(
                    graph.getView().getState(edge),
                    edge.target.geometry.x - 58,
                    edge.target.geometry.y + 1
                  );
                  h.destroy();
                } catch (e) { }
              }
              //graph.orderCells(null, [screenCell])
              graph.getModel().setVisible(screenCell, true);
              graph.getModel().setVisible(r[1], false);
              graph.getModel().setVisible(r[0], false);
              // self.isLoadingGraph = false;
              if (screenScript[0].childTask.length === index + 1) {
                updateEye(screenCell);
              }
            }, 200);
          }
        });
      } else {
        updateEye(screenCell);
      }

      self.setScreenImages(screens);
      if (!callback) self.editor.undoManager.clear();
    // self.isLoadingGraph = false;
    }
  });

  setTimeout(()=>{ // At the end of loading of all the scripts, mark isLoadingGraph flag as done. For now it is okay to use settimout function but we need to restructure the function with promisify instead of the setTimout.
    self.isLoadingGraph = false;
  },250);
};

PillirGraph.prototype.checkBetweenLanesCollapsed = (
  self,
  allLanes = [],
  source = {},
  target = {}
) => {
  let sourceIndex = allLanes.findIndex((e) => e.id === source?.id);
  let targetIndex = allLanes.findIndex((e) => e.id === target?.id);
  let result = true;
  if (sourceIndex !== -1 && targetIndex !== -1) {
    if (sourceIndex > targetIndex) {
      for (let i = targetIndex; i <= sourceIndex; i++) {
        if (!self.isCollapsed(allLanes[i])) result = false;
      }
    } else if (targetIndex > sourceIndex) {
      for (let i = sourceIndex; i <= targetIndex; i++) {
        if (!self.isCollapsed(allLanes[i])) result = false;
      }
    }
  }
  return result;
};

PillirGraph.prototype.handleCollapsedLaneEdge = (
  self,
  edges = [],
  allLanes = [],
  visible = false
) => {
  let changeableEdges = [];
  let unChangeableEdges = [];
  if (edges.length > 0) {
    let model = self.graph.getModel();
    edges.map((f) => {
      if (f.source && f.target) {
        let styles = f.getStyle();
        styles = styles.split(';');
        let isSourceCollapsed = model.isCollapsed(f.source?.parent);
        let isTargetCollapsed = model.isCollapsed(f.target?.parent);
        if (f.geometry.points?.length > 0 && visible) f.geometry.points = null;
        styles = styles.filter(
          (e) => !e.includes('exit') && !e.includes('entry') && e
        );
        let exitX =
          (f.target.geometry.x + f.target.geometry.width / 2) /
          MAXIMUM_LANE_WIDTH;
        let entryX =
          (f.source.geometry.x + f.source.geometry.width / 2) /
          MAXIMUM_LANE_WIDTH;
        if (
          (!isSourceCollapsed && isTargetCollapsed) ||
          (isSourceCollapsed && !isTargetCollapsed)
        ) {
          let sourceIndex = allLanes.findIndex(
            (e) => e.id === f.source.parent.id
          );
          let targetIndex = allLanes.findIndex(
            (e) => e.id === f.target.parent.id
          );
          if (isSourceCollapsed) {
            styles = [
              ...styles,
              `exitX=${exitX};exitDx=0`,
              `exitY=${sourceIndex < targetIndex ? 1 : 0};exitDy=0`,
            ];
          } else if (isTargetCollapsed) {
            styles = [
              ...styles,
              `entryX=${entryX};entryDx=0`,
              `entryY=${sourceIndex < targetIndex ? 0 : 1};entryDy=0;`,
            ];
          }
        }
        styles = styles.join(';');
        model.setStyle(f, styles);
        // let isBetweenCollapsed = self.checkBetweenLanesCollapsed(model, allLanes, f.source?.parent, f.target?.parent);
        // if((!visible && isBetweenCollapsed) || (visible && !isBetweenCollapsed)){
        if (
          (!visible && isSourceCollapsed && isTargetCollapsed) ||
          (visible && (!isSourceCollapsed || !isTargetCollapsed))
        ) {
          model.setVisible(f, !visible);
          model.setVisible(f, visible);
          changeableEdges.push(f);
        } else {
          let v = f.visible;
          model.setVisible(f, !v);
          model.setVisible(f, v);
          unChangeableEdges.push(f);
        }
      }
    });
    if (changeableEdges.length > 0) {
      changeableEdges.map((e) => model.setVisible(e, visible));
      self.graph.orderCells(true, [...unChangeableEdges, ...changeableEdges]);
    }
  }
};

PillirGraph.prototype.collapseLanes = function (Lane, evt, isAllLanes = false, updateJson = true) {
  let self = this;
  let parent = self.graph.getDefaultParent();
  let model = self.graph.getModel();
  let cells = [];
  let laneCount = 0,
    LaneInfo = [];
  let laneTolaneArrows = [];
  let allLanes = [];
  let edges = [];
  if(!updateJson){
    self.isLoadingGraph = true;
  }
  model.beginUpdate();
  parent.children.forEach((e) => {
    if (e.type == SHAPE_TYPES.LANE) {
      if (
        (isAllLanes && e.id != Lane.id) ||
        (!isAllLanes && e.id === Lane.id)
      ) {
        if (!self.graph.getModel().isCollapsed(e)) {
          e.value =
            "<div><div class='laneNameSelect" +
            laneCount +
            "' style='left: 20px;position: relative;margin-top: 15px;'>" +
            e.Lname +
            '</div></div>';
          cells.push(e);
          LaneInfo.push({ cell: e, index: laneCount });
        }
      }
      laneCount++;
      allLanes.push(e);
    } else if (e.edge) {
      edges.push(e);
      if (e.geometry.points) {
        if (!e.gpoints) {
          let geo = e.geometry;
          e.gpoints = e.geometry.points;
          geo.points = null;
          self.graph.getModel().setGeometry(e, geo);
          e.geometry = geo;
          laneTolaneArrows.push(e);
        } else if (e.gpoints.length != e.geometry.points.length) {
          let geo = e.geometry;
          e.gpoints = e.geometry.points;
          geo.points = null;
          self.graph.getModel().setGeometry(e, geo);
          e.geometry = geo;
          laneTolaneArrows.push(e);
        }
      }
    }
  });
  if (laneTolaneArrows) {
    self.graph.orderCells(null, laneTolaneArrows);
  }
  if (cells.length > 0) {
    self.graph.removeCells(self.borderCells);
    self.borderCells = [];
    self.graph.foldCells(true, false, cells, null, evt);
    mxEvent.consume(evt);
    parent = self.graph.getDefaultParent();
    parent.children.forEach((e) => {
      if (e.type == SHAPE_TYPES.LANE) {
        let changeColor = false;
        if (self.graph.getModel().isCollapsed(e)) {
          // || (isAllLanes && e.id != Lane.id)
          let b = self.getBorderLine(e.geometry);
          self.borderCells.push(self.graph.addCell(b, parent));
          changeColor = true;
          // } else if ((!isAllLanes && e.id === Lane.id) || self.graph.getModel().isCollapsed(e)) {
          //   let b = self.getBorderLine(e.geometry);
          //   self.borderCells.push(self.graph.addCell(b, parent));
          //   changeColor = true;
        }

        if (changeColor) {
          let stl = e
            .getStyle()
            .replaceAll('fillColor=#fff;fillOpacity=0;', '');
          self.graph
            .getModel()
            .setStyle(
              e,
              stl +
                `swimlaneFillColor=${COLLAPSED_LANE_COLOR};fillColor=${COLLAPSED_LANE_COLOR};`
            );
        }
      }
    });
  }
  let lastLane = self.lanes[self.lanes.length - 1].name;
  let lastLCell = parent.children.find((e) => e.Lname == lastLane);
  if (lastLCell && self.graph.getModel().isCollapsed(lastLCell)) {
    let l = self.getAddLaneCell(lastLCell.geometry);
    l = self.graph.getImportableCells([l]);
    l[0].lastLCell = lastLCell;
    self.borderCells.push(self.graph.addCell(l[0], parent));
  }
  self.handleCollapsedLaneEdge(self, edges, allLanes, false);
  model.endUpdate();
  self.graph.orderCells(true, allLanes);
  if(!updateJson){
    self.isLoadingGraph = false;
  }
  setTimeout(() => {
    self.graph.selectionModel.clear();
  }, 520);
  LaneInfo.forEach((l) => {
    let t = (ll) => {
      setTimeout(function () {
        let laneNameSelect = document.getElementsByClassName(
          'laneNameSelect' + ll.index
        );
        var target = laneNameSelect[0];
        if (self.laneRoleData && target) {
          self.renderLaneRoles(
            ll.cell.Lname,
            [ll.cell],
            target,
            'role',
            ll.index
          );
        }
      }, 0);
    };
    t(l);
  });
  // self.handleArrowBends(self.graph, edges || []);
};
PillirGraph.prototype.expandLanes = function (Lane, evt, isAllLanes = false) {
  let self = this;
  let parent = self.graph.getDefaultParent();
  let model = self.graph.getModel();
  let cells = [];
  let laneCount = 0,
    LaneInfo = [];
  let allLanes = [];
  let edges = [];
  let edgeChanges = [];
  let collapsedLanes = [];
  // self.isLoadingGraph = true;
  parent.children.forEach((e) => {
    if (e.type == SHAPE_TYPES.LANE) {
      let nodes = e.children.filter((f) => f.type !== SHAPE_TYPES.CONNECTOR);
      let maxWidthHeight = this.getMaxHeightWidthOfGrpCells(e, nodes, true);
      let maxHeight =
        maxWidthHeight.totalHeight <= 550
          ? 550
          : maxWidthHeight.totalHeight + 60;
      if (
        (isAllLanes && model.isCollapsed(e)) ||
        (!isAllLanes && e.id === Lane.id && model.isCollapsed(e))
      ) {
        LaneInfo.push({ cell: e, index: laneCount });
        e.value =
          "<div><div class='laneNameSelect' style='left: 20px;position: relative;margin-top: 15px;'>" +
          e.Lname +
          '</div></div>';
        e.geometry.alternateBounds = new mxgraph.mxRectangle(
          0,
          0,
          4000,
          maxHeight
        );
        cells.push(e);
      } else if (model.isCollapsed(e)) {
        collapsedLanes.push(e);
      }
      laneCount++;
      allLanes.push(e);
    } else if (e.edge) {
      edges.push(e);
      /* if(!(e.geometry?.points ?? false ) && e.alternateBounds && e.alternateBounds.points){
           let geo = e.geometry;
           geo.points=e.alternateBounds.points;
           self.graph.getModel().setGeometry(e, geo);          
       }*/
      if (e.gpoints && !(e.geometry?.points ?? false)) {
        let geo1 = e.geometry.clone();
        geo1.points = e.gpoints;
        e.geometry.points = e.gpoints;
        edgeChanges.push({ edge: e, geo: geo1 });
      }
    }
  });
  if (cells.length > 0) {
    let screenCells = [];
    self.graph.foldCells(false, false, cells, null, evt);
    mxEvent.consume(evt);
    self.graph.orderCells(null, allLanes);
    cells.forEach((l) => {
      l.children.forEach((s) => {
        if (s.type === SHAPE_TYPES.SCREEN) {
          screenCells.push(s);
        }
      });
    });
    if (screenCells.length > 0) {
      self.renderPageScreen(screenCells);
    }
    model.beginUpdate();
    self.graph.removeCells(self.borderCells);
    self.borderCells = [];
    if (collapsedLanes.length > 0) {
      collapsedLanes.forEach((e) => {
        let b = self.getBorderLine(e.geometry);
        self.borderCells.push(self.graph.addCell(b, parent));
      });
      let lastLane = self.lanes[self.lanes.length - 1]?.name;
      let lastLCell = parent.children.find((e) => e.Lname == lastLane);
      if (lastLCell && model.isCollapsed(lastLCell)) {
        let l = self.getAddLaneCell(lastLCell.geometry);
        l = self.graph.getImportableCells([l]);
        l[0].lastLCell = lastLCell;
        self.borderCells.push(self.graph.addCell(l[0], parent));
      }
    }
    if (cells.length > 0) {
      cells.forEach((f) => {
        let stl = f
          .getStyle()
          .replaceAll(
            `swimlaneFillColor=${COLLAPSED_LANE_COLOR};fillColor=${COLLAPSED_LANE_COLOR};`,
            ''
          );
        self.graph
          .getModel()
          .setStyle(f, stl + 'fillColor=#fff;fillOpacity=0;');
      });
    }
    self.handleCollapsedLaneEdge(self, edges, allLanes, true);
    model.endUpdate();
    self.graph.orderCells(true, allLanes);
    setTimeout(() => {
      self.graph.selectionModel.clear();
    }, 520);
    edgeChanges.forEach((edge) => {
      edge.edge.gpoints = null;
      model.setGeometry(edge.edge, edge.geo);
      model.setVisible(edge.edge, false);
      model.setVisible(edge.edge, true);
    });
    self.graph.orderCells(null, edges);
    LaneInfo.forEach((l) => {
      let t = (ll) => {
        setTimeout(function () {
          let laneNameSelect =
            document.getElementsByClassName('laneNameSelect');
          let d = new Array(...laneNameSelect);
          let target = d.find((e) => e.innerText === ll.cell.Lname);
          if (self.laneRoleData && target) {
            self.renderLaneRoles(
              ll.cell.Lname,
              [ll.cell],
              target,
              'role',
              ll.index
            );
          }
        }, 100);
      };
      t(l);
    });
  }
  // self.handleArrowBends(self.graph, edges || []);
  // self.isLoadingGraph = false;
};
PillirGraph.prototype.borderCells = [];
PillirGraph.prototype.getAddLaneCell = function (geo) {
  let l = new Lane('', true, false);
  l = l.children.find((e) => e.addLanebutton);
  l.geometry = new mxgraph.mxGeometry(
    14,
    geo.y + geo.height + 14,
    l.geometry.width,
    l.geometry.height
  );
  return l;
};
PillirGraph.prototype.getBorderLine = function (geometry) {
  let borderLine = new mxgraph.mxCell(
    '',
    new mxgraph.mxGeometry(0, geometry.y + geometry.height - 5, 4000, 1),
    'shape=line;strokeWidth=2;rotatable=0;html=1;strokeColor=#8B9BC0;resizable=0;movable=0;'
  );
  borderLine.vertex = true;
  borderLine.isLane = false;
  borderLine.isIcon = true;
  borderLine.connectable = false;
  return borderLine;
};
PillirGraph.prototype.getFilterCell = function (text='',geometry) {
  let filterCell = new mxgraph.mxCell(
    text,
    new mxgraph.mxGeometry(0, geometry.y, 0, 0),
    'strokeWidth=2;rotatable=0;html=1;resizable=0;movable=0;'
  );
  filterCell.vertex = true;
  filterCell.isLane = false;
  filterCell.visible =false;
  filterCell.type ='filterCell';
  filterCell.isIcon = true;
  filterCell.connectable = false;
  return filterCell;
};
PillirGraph.prototype.updateComponentState = function (
  selectedCell,
  selectedVal
) {
  let self = this;
  let graph = this.graph;

  var cell = graph.getModel().getCell(selectedCell.id);

  if (cell) {
    cell.stateValue = selectedVal;
    if (cell.stateLabel) {
      graph.getModel().setValue(cell.stateLabel, selectedVal);
    } else {
      self.addStateLabel(cell, selectedVal);
    }
    //graph.getModel().setValue(cell, selectedVal);
  }
};
PillirGraph.prototype.addStateLabel = function (
  edge,
  valueEdge,
  require = true
) {
  let graph = this.graph;
  if (require) graph.getModel().beginUpdate();
  try {
    let fontColorStyle = `${mxConstants.STYLE_FONTCOLOR}=${WORKFLOW_COLOR}`;
    var stateLabel = graph.insertVertex(
      edge,
      null,
      valueEdge,
      0,
      0,
      1,
      1,
      'text;html=1;fontSize=13;fontWeight=bold;' +
        fontColorStyle +
        ';fillColor=none;strokeColor=none;arcSize=15;align=left;editable=0;resizable=0;rotatable=0;',
      true
    );
    // Adds padding (labelPadding not working...)
    //stateLabel.geometry.width += 16;
    //stateLabel.geometry.height += 12;

    // Centers the label
    //stateLabel.geometry.offset = new mxPoint(-stateLabel.geometry.width / 2, -stateLabel.geometry.height / 2);
    stateLabel.connectable = false;
    stateLabel.isIcon = true;
    stateLabel.geometry.relative = true;
    stateLabel.geometry.offset = new mxgraph.mxPoint(-24, -24);
    stateLabel.type = SHAPE_TYPES.STATE_LABEL;
    graph.updateCellSize(stateLabel);
    edge.stateLabel = stateLabel;
  } finally {
    if (require) graph.getModel().endUpdate();
  }
};
PillirGraph.prototype.addIntractionLabel = function (
  edge,
  valueEdge,
  require = true
) {
  setTimeout(() => {
  let graph = this.graph;
  this.isLoadingGraph = true;
  this.skipAction = true;
  if (require) graph.getModel().beginUpdate();
  const  truncateString=(str, n)=> {
    if (str.length > n) {
      return str.substring(0, n) + "...";
    } else {
      return str;
    }
  }
  try {
    
    let fontColorStyle = `${mxConstants.STYLE_FONTCOLOR}=#CDD4E4`;
    var stateLabel = graph.insertVertex(
      edge,
      null,
      `<span data-tooltip="${valueEdge}" data-tooltip-position="bottom">${truncateString(valueEdge,18)}</span>`,
      0,
      0,
      1,
      1,
      'edgeLabel;resizable=0;html=1;fontSize=13;fontWeight=bold;align=left;editable=0;rotatable=0;movable=0;fillColor=none;strokeColor=none;' +
        fontColorStyle +';',
      true
    );
    let xx=-50,yy=14;
    if(edge.isHierarchy){
        xx=-58;
        yy=-16;
    }
    stateLabel.connectable = false;
    stateLabel.isIcon = true;
    stateLabel.geometry.relative = true;
    stateLabel.geometry.offset = new mxgraph.mxPoint(xx,yy);
    stateLabel.type = SHAPE_TYPES.INTRACTION_LABEL;
    graph.updateCellSize(stateLabel);
  } finally {
    if (require) graph.getModel().endUpdate();

    this.isLoadingGraph = false;
    this.skipAction = false;
  }
  }, 100);
};
PillirGraph.prototype.handleArrowNodes = function (graph, edge) {
  let styles = edge.getStyle();
  let model = graph.getModel();
  if (!edge.isHierarchy) {
    if (styles.includes('exit') || styles.includes('entry')) {
      styles = styles.split(';');
      if(edge.source?.type !== SHAPE_TYPES.SCREEN){
        styles = styles.filter((e) => !e.includes('exit'));
      }
      if(edge.target?.type !== SHAPE_TYPES.SCREEN){
        styles = styles.filter((e) => !e.includes('entry'));
      }
      styles = styles.join(';');
      model.setStyle(edge, styles);
      graph.orderCells(null, [edge, edge.source, edge.target]);
    }
  }
};
PillirGraph.prototype.handleArrowBends = (graph, edges = []) => {
  let model = graph.getModel();
  model.beginUpdate();
  edges = edges.forEach((e) => {
    if (e.geometry.points?.length > 0 && !e.isHierarchy && e.target) {
      let geo2 = e.geometry.clone();
      geo2.points = null;
      model.setGeometry(e, geo2);
    }
    return e;
  });
  model.endUpdate();
};
PillirGraph.prototype.replaceAllStateLabel = function (old_val, new_val) {
  let self = this;
  let graph = self.graph;
  graph.getModel().beginUpdate();
  try {
    let parent = graph.getDefaultParent();
    let edgehasStatesValues = [];
    let visittedFistLane = false;
    parent.children.forEach((e) => {
      if (e.type == SHAPE_TYPES.LANE && !visittedFistLane) {
        visittedFistLane = true;
        if (e.children) {
          let start = e.children.find((n) => n.type === SHAPE_TYPES.START);
          let end = e.children.find((n) => n.type === SHAPE_TYPES.END);
          if (start && start.edges) {
            let starState = start.edges[0];
            if (starState.children) {
              if (starState.children[0].value === old_val) {
                edgehasStatesValues.push(starState);
              }
            }
          }
          if (end && end.edges) {
            let sameLaneStateArrows = end.edges.filter(
              (e) => e.parent.id === end.parent.id
            );
            if (sameLaneStateArrows) {
              sameLaneStateArrows.forEach((endState) => {
                if (endState.children) {
                  if (endState.children[0].value === old_val) {
                    edgehasStatesValues.push(endState);
                  }
                }
              });
            }
          }
        }
      } else if (e.edge) {
        if (e.children) {
          if (old_val === e.children[0].value) {
            edgehasStatesValues.push(e);
          }
        }
      }
    });

    if (edgehasStatesValues.length > 0) {
      edgehasStatesValues.forEach((e) => {
        self.updateComponentState(e, new_val);
      });
    }
  } finally {
    graph.getModel().endUpdate();
    if (self.editor.undoManager.history.length > 0) {
      self.editor.undoManager.history.pop();
      self.editor.undoManager.indexOfNextAdd =
        self.editor.undoManager.indexOfNextAdd - 1;
    }
  }
};

PillirGraph.prototype.forgetLastTransaction = function () {
  if (this.editor.undoManager.history.length > 0) {
    this.editor.undoManager.history.pop();
    this.editor.undoManager.indexOfNextAdd =
      this.editor.undoManager.indexOfNextAdd - 1;
  }
};

PillirGraph.prototype.isUsedSateLable = function (stateValue) {
  let self = this;
  let graph = self.graph;
  let parent = graph.getDefaultParent();
  let visittedFistLane = false;
  let children = parent.children;
  for (let i = 0; i < children.length; i++) {
    let e = children[i];
    if (e.type == SHAPE_TYPES.LANE && !visittedFistLane) {
      visittedFistLane = true;
      if (e.children) {
        let start = e.children.find((n) => n.type === SHAPE_TYPES.START);
        let end = e.children.find((n) => n.type === SHAPE_TYPES.END);
        if (start && start.edges) {
          let starState = start.edges[0];
          if (starState.children) {
            if (starState.children[0].value === stateValue) {
              return true;
            }
          }
        }
        if (end && end.edges) {
          let sameLaneStateArrows = end.edges.filter(
            (e) => e.parent.id === end.parent.id
          );
          if (sameLaneStateArrows) {
            let flag = false;
            sameLaneStateArrows.forEach((endState) => {
              if (endState.children) {
                if (endState.children[0].value === stateValue) {
                  flag = true;
                }
              }
            });
            if (flag) {
              return true;
            }
          }
        }
      }
    } else if (e.edge) {
      if (e.children) {
        if (e.children[0].value == stateValue) {
          return true;
        }
      }
    }
  }
  return false;
};
PillirGraph.prototype.handleTerminalChange = function (graph, change) {
  let canProcess = true;
  const {
    cell: { source, target },
    previous,
  } = change;
  let self = this;

  let makeunUndoEdit = () => {
    
    canProcess = false;
    if (
      !previous &&
      (edge.source.type === SHAPE_TYPES.XOR ||
        edge.source.type === SHAPE_TYPES.CASE)
    ) {
      // Handling of undo specifically if source is XOR
      setTimeout(() => {
        graph.getModel().beginUpdate();
        graph.removeCells([edge]);
        if (edge.source.type === SHAPE_TYPES.XOR) {
          self.adjustConditionArrows(edge.source);
        } else if (edge.source.type === SHAPE_TYPES.CASE) {
          self.adjustCaseArrows(edge.source);
        }
        graph.getModel().endUpdate();
        if (self.editor.undoManager.history.length > 0) {
          self.editor.undoManager.history.pop();
          self.editor.undoManager.indexOfNextAdd =
            self.editor.undoManager.indexOfNextAdd - 1;
        }
      }, 0);
    } else if (!previous && edge.source.type === SHAPE_TYPES.DMN) {
      setTimeout(() => {
        graph.getModel().beginUpdate();
        graph.removeCells([edge]);
        let table = source.children[0].value;
        table = JSON.parse(table || '{}');
        let findRowIndex = table?.rows?.findIndex((e) => e.id === edge.uid);
        self.editDMNConnector(
          graph,
          self,
          findRowIndex,
          table,
          source,
          edge.dmnExp
        );
        graph.getModel().endUpdate();
        if (self.editor.undoManager.history.length > 0) {
          self.editor.undoManager.history.pop();
          self.editor.undoManager.indexOfNextAdd =
            self.editor.undoManager.indexOfNextAdd - 1;
        }
      }, 100);
    } else {
      if (self.edgeHandled !== edge.uid) { // Preventing repeated undo...
        self.edgeHandled = edge.uid;
        setTimeout(() => {
          self.edgeHandled = '';
          self.editor.undo();
          self.editor.undo();
        }, 100);
      }
    }
  };

  const edge = change.cell;
  
  // let changedTerminal = change.source ? source : target;
  // const previousLane = graph.getSwimlane(previous);
  // const newLane = graph.getSwimlane(changedTerminal);

  if (source && target) {
    /*
      if (previousLane && newLane && newLane.id !== previousLane.id) {   // Chaning of lane is not allowed
        makeunUndoEdit();
        self.showAlert(`Lane change is not allowed!`);
      } else {}
    */


    const errorMsg = validateFlow(graph, edge);
    if (errorMsg){
      makeunUndoEdit();
      self.showAlert(errorMsg);
      return canProcess;
    }

    let isStateRequired = false;

    let crossLane = _checkCrossLaneConnection(graph, edge);
    if (crossLane) {
      //Connectivity in different lane.
      // The arrows connecting between the lanes and coming from a workflow component then this arrow is state transition arrow.

      if (source.executeAsWorkflow) {
        // Source is workflow
        isStateRequired = true;

        if (source.type === SHAPE_TYPES.DMN) {
          self.handleDMNManualArrow(graph, edge, source, true);
        }
      } else if (
        [SHAPE_TYPES.START, SHAPE_TYPES.END].indexOf(edge.target.type) === -1
      ) {
        // Source is app flow and target is not start or end

        makeunUndoEdit();
        self.showAlert(
          `Step transition can only be done from a workflow component!`
        );
      }
    } else {
      // Connectivity with-in same lane
      if (!source.executeAsWorkflow) {
        // Source is  app-flow
        if (source.type !== SHAPE_TYPES.START) {
          // If source is not start

          if (!target.executeAsWorkflow) {
            // Target is  app-flow
            // transtion arrow need to remove, it will be done below
          } else {
            // Target is  work-flow
            let found = checkHasWorkflowComponent(graph, source, target.id);
            if (found) {
              // Don't allow to add
              makeunUndoEdit();
              self.showAlert(
                `A non-workflow ${found.value}(${found.type} Component) found in outward direction!`
              );
            }
          }
        }
      } else {
        // Source is  work-flow
        if (!target.executeAsWorkflow) {
          // Target is  app-flow
          let found = null;
          if (target.type !== SHAPE_TYPES.END) {
            found = searchAppFlowBackwardDirection(graph, source);
            const targetInReverse = checkTargetInBackwardDirection(
              graph,
              source,
              target
            );
            if (found && !targetInReverse) {
              // Don't allow to add
              makeunUndoEdit();
              self.showAlert(
                `A non-workflow ${found.value}(${found.type} Component) found in backward direction!`
              );
            } else {
              // Allow to add
              if (UNSUPPORTED_COMPONENTS.indexOf(target.type) === -1) {
                target.executeAsWorkflow = 'yes';
                graph
                  .getModel()
                  .setStyle(
                    target,
                    target.getStyle() +
                      ';strokeColor=' +
                      WORKFLOW_COLOR +
                      ';strokeWidth=' +
                      BORDER_WIDTH_WORKFLOW_COMP +
                      ';'
                  );
                graph.getModel().setVisible(target, false);
                graph.getModel().setVisible(target, true);
                setTimeout(() => {
                  graph.orderCells(false, [target]);
                }, 10);
              }
              // transtion arrow need to remove, it will be done below
            }
          }
          if (canProcess && source.type === SHAPE_TYPES.DMN) {
            self.handleDMNManualArrow(graph, edge, source, true);
          }
        } else {
          // Target is  work-flow
          // simply allow....
          if (source.type === SHAPE_TYPES.DMN) {
            self.handleDMNManualArrow(graph, edge, source, true);
          }
        }
      }
    }

    if (canProcess) {
      if (
        target.type === SHAPE_TYPES.END ||
        source.type === SHAPE_TYPES.START
      ) {
        isStateRequired = true;
      }

      setTimeout(() => {
        self.skipAction = true;
        graph.getModel().beginUpdate();
        // Workflow edge color: 
        graph.getModel().getIncomingEdges(target).forEach((ie) => {
          let strokeColor = ((ie.source?.executeAsWorkflow && ie.target?.executeAsWorkflow) || _isStateArrow(ie)) ? WORKFLOW_COLOR : null;
          graph.getModel().setStyle(ie, mxgraph.mxUtils.setStyle(ie.getStyle(), 'strokeColor', strokeColor))
        })

        
        if (isStateRequired && (!edge.children || edge.children.length === 0)) {
          // State is required and edge has no state style
          let stateValue = null;
          graph
            .getModel()
            .setStyle(
              edge,
              edge.getStyle() +
                ';strokeColor=' +
                WORKFLOW_COLOR +
                ';strokeWidth=' +
                BORDER_WIDTH_WORKFLOW_COMP +
                ';'
            );

          //Set state transition default value
          if (source.type === SHAPE_TYPES.START) {
            stateValue = DEFAULT_INITIAL_STATE;
          } else if (target.type === SHAPE_TYPES.END) {
            stateValue = DEFAULT_END_STATE;
          }
          self.addStateLabel(edge, stateValue);
          self.toggleStateSideBar(edge);
        } else if ( !isStateRequired && edge.children && edge.children.length > 0 ) {
          // State is not required but edge has state
          graph.removeCells(edge.children);
          delete edge.stateLabel;
          graph
            .getModel()
            .setStyle(
              edge,
              edge.getStyle() + ';strokeColor=#cdd4e4;strokeWidth=2;'
            );
        }

        graph.getModel().endUpdate();
        self.skipAction = false;
        // self.forgetLastTransaction();    
        //Commenting (forgetLastTransaction) as it Removes all arrow action from "Undo", so replaced with 'skipAction' - T3053096
              
      }, 0);
      
    }

    if (
      [SHAPE_TYPES.DMN, SHAPE_TYPES.XOR].indexOf(edge.source.type) &&
      canProcess
    ) {
      // self.handleArrowNodes(graph, edge);
    }
  }

  return canProcess;
};
PillirGraph.prototype.adjustLaneHeightFlexibly = function (
  self,
  cell,
  isRemoved = false
) {
  if (
    [
      SHAPE_TYPES.XOR,
      SHAPE_TYPES.CASE,
      SHAPE_TYPES.SCREEN,
      SHAPE_TYPES.DMN,
      SHAPE_TYPES.BOS,
      SHAPE_TYPES.TASK,
      SHAPE_TYPES.NOTE,
    ].includes(cell?.type)
  ) {
    let model = self.graph.getModel();
    let defaultParent = self.graph.getDefaultParent();
    let allLanes =
      defaultParent?.children?.filter((e) => e?.type === SHAPE_TYPES.LANE) ||
      [];
    let currentY = 0;
    let laneIndex = -1;
    let laneCount = 0;
    let LaneInfo = [];
    let collapsedLanes = [];
    let i = '';
    if (!isRemoved)
      i = allLanes?.findIndex((f) => f.Lname === cell.parent?.Lname);
    allLanes = allLanes.map((otherLane, index) => {
      let nodes =
        i === index ? [...otherLane.children, cell] : otherLane.children;
      let totalCalculations = this.getMaxHeightWidthOfGrpCells(
        cell,
        nodes,
        true
      );
      let geometry = otherLane.geometry.clone();
      geometry.y = currentY;
      laneIndex = index;
      if (!model.isCollapsed(otherLane)) {
        if (totalCalculations.totalHeight > MINIMUM_LANE_HEIGHT - 8) {
          geometry.height = totalCalculations.totalHeight + 60;
        } else {
          geometry.height = MINIMUM_LANE_HEIGHT;
        }
      }
      model.setGeometry(otherLane, geometry);
      currentY += geometry.height;
      if (model.isCollapsed(otherLane)) collapsedLanes.push(otherLane);
      else LaneInfo.push({ cell: otherLane, index: laneCount });
      laneCount++;
      return otherLane;
    });
    self.graph.getModel().beginUpdate();
    if (collapsedLanes.length > 0) {
      self.graph.removeCells(self.borderCells);
      self.borderCells = [];
      collapsedLanes.forEach((e) => {
        let b = self.getBorderLine(e.geometry);
        self.borderCells.push(self.graph.addCell(b, defaultParent));
      });
      let lastLane = self.lanes[self.lanes.length - 1]?.name;
      let lastLCell = defaultParent.children.find((e) => e.Lname == lastLane);
      if (lastLCell && self.graph.getModel().isCollapsed(lastLCell)) {
        let l = self.getAddLaneCell(lastLCell.geometry);
        l = self.graph.getImportableCells([l]);
        l[0].lastLCell = lastLCell;
        self.borderCells.push(self.graph.addCell(l[0], defaultParent));
      }
    }
    LaneInfo.forEach((l) => {
      let t = (ll) => {
        setTimeout(function () {
          let laneNameSelect = document.getElementsByClassName(
            'laneNameSelect' + ll.index
          );
          var target = laneNameSelect[0];
          if (self.laneRoleData && target) {
            self.renderLaneRoles(
              ll.cell.Lname,
              [ll.cell],
              target,
              'role',
              ll.index
            );
          }
        }, 100);
      };
      t(l);
    });
    self.graph.orderCells(true, allLanes);
    self.graph.getModel().endUpdate();
  }
};
PillirGraph.prototype.handleDragToGroup = function (
  self,
  graph,
  cells = [],
  evt
) {
  let selectedCells = cells.filter(
    (e) =>
      [
        SHAPE_TYPES.DMN,
        SHAPE_TYPES.XOR,
        SHAPE_TYPES.CASE,
        SHAPE_TYPES.BOS,
        SHAPE_TYPES.SCREEN,
      ].indexOf(e.type) !== -1
  );
  let nonSupportedCells = selectedCells.filter((f) => !f.executeAsWorkflow && !f.isOfflineBOS);
  if (selectedCells.length === nonSupportedCells.length) {
    setTimeout(() => {
      self.highlightedCells = [];
      graph.setSelectionCells(selectedCells);
      selectedCells.forEach((e) => {
        let h = new mxgraph.mxCellHighlight(graph, '#00a8ff', 2);
        h.highlight(graph.view.getState(e));
        self.highlightedCells.push({ cell: e, highlightObj: h });
      });
      graph.getSelectionModel().setCells(selectedCells);
    }, 100);
  } else {
    self.showAlert(apmMessage.E3503 + apmMessage.E3504);
  }
};

PillirGraph.prototype.checkAndMakeLaneActive = function (self) {
  let model = self.graph.getModel();
  let parent = self.graph.getDefaultParent();
  let allLanes = parent?.children?.filter((f) => f.isLane) || [];
  let flag = false;
  allLanes.forEach((f) => {
    if (!model.isCollapsed(f)) flag = true;
  });
  if (!flag) {
    self.expandLanes(
      allLanes[0],
      new mxEventObject('foldCells', 'cell'),
      false
    );
  }
};

PillirGraph.prototype.checkIfAlreadyEdgeConnected = function(cell, currentEdge) {
  let flag = false;
  if(currentEdge && cell) {
    let edges = cell?.edges?.filter(e =>  e.id !== currentEdge.id && e.source?.id === cell.id);
    if(edges.length) flag = true;
  }else {
    flag = cell?.edges?.length > 0;
  }
  return flag;
}

PillirGraph.prototype.undoableError = function(self, errorMessage) {
  setTimeout(() => {
    self.editor.undo();
    self.editor.undo();
  }, 100);
  self.showAlert(errorMessage);
  return false;
}

PillirGraph.prototype.isInvalidExitAndEntryPoints = function(self, graph, edge){
  let validEdge = true;
  if (edge.source?.executeAsWorkflow && !edge.target?.executeAsWorkflow) {
    let parent = edge.target?.parent;
    let a = parent?.children &&
      parent?.children?.map((node) => {
        if (!node.executeAsWorkflow) {
          let incomingEdges = graph.getModel().getIncomingEdges(node);
          incomingEdges.map((e) => {
            if (e.source.executeAsWorkflow && edge.target.id !== node.id) {
              validEdge = false;
              // graph.removeCells([edge]);
              self.undoableError(self,
                `App component can not have 2 entry points!. You can connect from the Workflow only to the component ${node.value}`
              );
            }
          });
        }
      });
  }
  //App component should not have 2 exit points. See eg:
  if (!edge.source?.executeAsWorkflow && edge.target?.executeAsWorkflow) {
    let parent = edge.source?.parent;
    let a = parent?.children &&
      parent?.children?.map((node) => {
        if (!node?.executeAsWorkflow) {
          let outgoingEdges = graph.getModel().getOutgoingEdges(node);
          outgoingEdges.map((e) => {
            if (e.target?.executeAsWorkflow && e.id !== edge.id) {
              validEdge = false;
              // graph.removeCells([edge]);
              self.undoableError(self,
                `App component can not have 2 exit points!. You can connect to Workflow only from the component ${node.value}`
              );
            }
          });
        }
      });
  }
  return validEdge;
}
PillirGraph.prototype.hideScreenHierachy=function(screenCell){
  let self=this;
  if (screenCell?.edges) {
    let model = self.graph.getModel();
    var c = screenCell.children.find((c) => c.type === SHAPE_TYPES.EYE);
    let subtitleCell = screenCell.children.find((i) => i.type == 'subtitle');
    self.isLoadingGraph = true;
    self.skipAction = true;
    if (!subtitleCell) {
      screenCell.isLockedAddHierarchy = !screenCell.isLockedAddHierarchy;
      model.setVisible(screenCell, true);
      self.toggleEyeIcon(screenCell, c);
      self.arrangeScreen(screenCell, null, null, true);
    }
    self.skipAction = false;
    self.isLoadingGraph = false;
  }
}

PillirGraph.prototype.handleInteractedArrows = (arrow = {}) => {
  let name=null;
  if(arrow.transitions?.length > 0){
    name = '';
    arrow.transitions.map((e, index) => {
      let type = e.type;
      if(type && !type?.startsWith("on")){
        type = "on" + type.charAt(0).toUpperCase() + type.slice(1);
      }
      name += `${index ? ',': ''} ${e.name}/${type || ""}`;
    })
  }
  return name;
}

// PillirGraph.prototype.paintEdge = function (edge) {

//   console.log("this", this);
//   const {graph} = this
//   graph.getModel().beginUpdate();
//   let strokeColor = null;
//   if (edge.source && edge.target && ((edge.source.executeAsWorkflow && edge.target.executeAsWorkflow) || _isTransitionArrow(graph, edge))) {
//     strokeColor = WORKFLOW_COLOR;
//   }
//   graph.getModel().setStyle(edge, mxgraph.mxUtils.setStyle(edge.getStyle(), 'strokeColor', strokeColor));
//   graph.getModel().endUpdate();
//   this.forgetLastTransaction();
// }

PillirGraph.prototype.count = -1;
PillirGraph.prototype.laneRoleData = undefined;
PillirGraph.prototype.variables = undefined;
PillirGraph.prototype.lanes = [];
PillirGraph.prototype.screens = [];
PillirGraph.prototype.type = undefined;
PillirGraph.prototype.currentBlock = null;
PillirGraph.prototype.currentDMN = undefined;
PillirGraph.prototype.processLinked = undefined;
PillirGraph.prototype.deleteUserTask = function () {};
PillirGraph.prototype.generateUid = function () {
  return generateUid();
};
PillirGraph.prototype.closeDMNTable = function () {};
PillirGraph.prototype.updateUserTask = function () {};
PillirGraph.prototype.updatePageName = function () {};
PillirGraph.prototype.openDMNTable = function (val) {};
PillirGraph.prototype.addConnectorToDMN = function () {};
PillirGraph.prototype.showAlert = function () {};
PillirGraph.prototype.changeRole = function (cb, name) {};
PillirGraph.prototype.newAppModel = function (flag) {};
PillirGraph.prototype.openEditAppDetails = function (oldName) {};
PillirGraph.prototype.openDeleteRole = function (name) {};
PillirGraph.prototype.beforeObjectAdd = function (obj) {};
PillirGraph.prototype.afterObjectAdded = function (obj) {};
PillirGraph.prototype.afterObjectDeleted = function () {};
PillirGraph.prototype.addLaneEventListener = function () {};
PillirGraph.prototype.createTaskLink = function (obj) {};
PillirGraph.prototype.updateTaskLink = function (cell) {
  if (this.stateCell) {
    this.graph.getModel().setVisible(this.stateCell.cell, true);
    this.stateCell = undefined;
  }
};
PillirGraph.prototype.linkToTask = function (obj) {};
PillirGraph.prototype.navToBusinessFunction = function (obj) {};
PillirGraph.prototype.navToProcess = function (obj) {};
PillirGraph.prototype.navToBuilder = function (obj) {};
PillirGraph.prototype.openIntoNewTab = function (obj) {};
PillirGraph.prototype.navToBack = function (obj) {};
PillirGraph.prototype.navToDesigner = function (obj) {};
PillirGraph.prototype.navToUserTask = function (obj) {};
PillirGraph.prototype.openVariablePanel = function (obj) {};
PillirGraph.prototype.openCasePropertyPanel = function (obj) {};
PillirGraph.prototype.getScreenImage = function (obj) {};
PillirGraph.prototype.handleGraphBackbtnClick = function (obj) {};
PillirGraph.prototype.handleNavLinkClick = function (obj) {};
PillirGraph.prototype.saveSnapshot = function () {};
PillirGraph.prototype.deleteScript = function (obj1, obj2) {};
PillirGraph.prototype.deleteScreen = function (obj) {};
PillirGraph.prototype.linkToBusinessFunction = function (obj) {};
PillirGraph.prototype.updateLinkToBusinessFunction = function (obj) {};
PillirGraph.prototype.renameLane = function (obj) {};
PillirGraph.prototype.updateBOSName = function (obj) {};
PillirGraph.prototype.updateCloneBos = function (obj) {};
PillirGraph.prototype.createPageWithMenu = function (obj) {};
PillirGraph.prototype._checkIsWorkflowApp = function () {
  return false;
};
PillirGraph.prototype._checkIsOffline = function () {
  return false;
};
PillirGraph.prototype._isGraphReadOnly = function () {
  return false;
};
PillirGraph.prototype._checkCrossLaneConnection = _checkCrossLaneConnection;
PillirGraph.prototype.toggleEmailPanel = function(obj) {};
PillirGraph.prototype.toggleAssignmentPanel = function(obj) {};
PillirGraph.prototype.isLoginMicroApp = function(){ return false; };
PillirGraph.prototype.openMultiLingual = function(){};
export default PillirGraph;
