import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "../../../../components/forms/Form";
import Table from "../../../../components/Table/table";
import { useAppContext } from "../../../../components/activity/AppContext";
import { ServiceActions } from "../services.actions";
import { validateValue, validateValues } from "../serviceValidations";
import styles from "./transport.module.css";

export default function TransportForm(props) {
    const { setRenderComponent, serviceData, handleClose, setTransportService, transportService } = props;
    const [formData, setFormData] = useState({ notes: "", service: transportService.item })
    const [serviceList, setServiceList] = useState()
    // const [selected, setSelected] = useState(null);
    const [filter, setFilter] = useState({});
    const app = useAppContext();

    const headers = [
        { name: "radio", id: "radio", type: "radio", width: "50px" },
        { name: "Version", id: "version", type: "input" },
        {
            name: "Published On",
            id: "createdTimestamp",
            type: "input",
            disabled: true,
        },
        { name: "Notes", id: "comment", type: "input" }

    ]

    const onHeaderChange = (id, value) => {
        setFilter((prev) => ({ ...prev, [id]: value }));
    };

    const itemOnClick = (item, event) => {
        setFormData((val) => ({ ...val, service: item }))
        mapServiceList(serviceList, item.id);
    };

    const getServiceData = async () => {
        const actions = ServiceActions(app());
        const uuid = serviceData.serviceId;
        const [error, data] = await actions.getSerivceData({ uuid, filter });

        if (!error) {
            const serviceData = data.data;
            mapServiceList(serviceData);
        }
    }

    useEffect(() => {
        getServiceData();
    }, [filter])

    const mapServiceList = (serviceData, clickedId = null) => {
        let mapedService = [];
        for (let dt of serviceData) {
            if (dt.status !== "Approved") {
                if (clickedId == null) {
                    if (formData.service == null) {
                        clickedId = dt.id;
                        setFormData(val => ({ ...val, service: dt }))
                    }
                    else clickedId = formData.service.id;
                }
                mapedService.push(
                    { ...dt, radio: <input type="radio" name={"version"} id={dt.id} value={dt.id} checked={dt.id === clickedId ? "checked" : ""} onChange={() => { }} /> }
                )
            } else {
                mapedService.push(
                    { ...dt, disabled: true }
                )
            }
        }

        setServiceList(mapedService)
    }

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = (values, errors) => {
        if (!formData.service) return false;
        if (!validateValues(values, errors))
            return false;
        setTransportService(val => ({ ...val, comment: values.notes, item: formData.service }))
        setRenderComponent("Metadata")
    }

    const { Form, Label, Input, Error } = useForm(formData, onSubmit, onWatch);

    return (
        <div className={styles.popupCont}>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{`Transporting ${serviceData.name} to Quality`}</h2>
                <h5 className={styles.subTitle}>{"Transporting a version will package all the required objects for a service and move it to Quality environment"}</h5>
            </div>
            <div className={styles.tableCont}>
                <Table
                    data={serviceList || []}
                    headerColumns={headers}
                    // contextOnChange={menuOnClick}
                    onRowClick={itemOnClick}
                    handleHeaderInputChange={onHeaderChange}
                />
            </div>
            <Form>
                <div className={styles.formCont}>
                    <Label text={"Notes"} htmlFor="notes" />
                    <Input type="text" id="notes" name="notes" onFocus={e => { e.target.placeholder = "Add any additional transport comments here" }} onBlur={e => { e.target.placeholder = "" }} style={{ width: "100%" }} />
                    <Error name="notes" />
                </div>
                <div className={styles.btnCont}>
                    <Input
                        type="button"
                        className="secondary"
                        value={"Cancel"}
                        onClick={handleClose}
                        data-clickable-testid={"Cancel"}
                    />
                    <Input
                        type="submit"
                        className={formData.service !== null ? "primary" : "tertiary"}
                        value={"Next"}
                        data-clickable-testid={"Next"}
                    />
                </div>
            </Form>
        </div>
    )
}
