import { getUrl, get, put, post } from "../../url"


// export async function getSessionSetting() {
//     const uri = getUrl() + "/configurator/v1.0/company/personalization";
//     let data = new Promise(function (resolver, reject) {
//         get(uri).then((res) => {
//             const [error, data] = res;
//             if (error) {
//                 reject({});
//             } else {
//                 resolver(data.detail);
//             }
//         });
//     });
//     return data;
// }

export async function getSessionSetting() {
    let path = `${getUrl()}/configurator/v1.0/company/personalization`;
    const [error, data] = await get(path);
    return [error, data];
}



export async function updateSessionSetting(input) {
    let path = `${getUrl()}/configurator/v1.0/company/personalization`;
    const [error, data] = await put(path, input);
    return [error, data];
}


// export async function updateSessionSetting(input) {

//     // Sample Data
//     // {
//     //     "companyId": 3,
//     //     "isActive": true,
//     //     "dataValidity": 30,
//     //     "hasS3Support": false,
//     //     "tokenValidity": 241,
//     //     "autoApprove": 0
//     // }

//     const uri = getUrl() + "/configurator/v1.0/company/personalization";
//     let data = new Promise(function (resolver, reject) {
//         put(uri, input).then((res) => {
//             const [error, data] = res;
//             if (error) {
//                 reject({});
//             } else {
//                 resolver(data);
//             }
//         });
//     });
//     return data;
// }

