import React, { useEffect, useState } from 'react';
import { LOGINMICROAPP } from '../../constants';
import SubContentHeader from '../../common/components/SubContentHeader/index.js';
import Reports from '../modernizer/components/logs-and-reports/reports';
import { Container, Button, Box, Grid, Tab } from '@material-ui/core';
import { fetchBusinessFunctions } from '../../helpers/project-detail';
import Logs from '../modernizer/components/logs-and-reports/logs';
import PromptDialog from '../../common/components/PromptDialog';
import roleIcon2 from '../../assets/images/role-box-image.svg';
import { configMessage } from '../../common/messages/config';
import ManagePermission from './manage-project-permissions';
import { s3Url, getPermissions } from '../../utils/common';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { resetStateData } from '../../helpers/modernizer';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import SubHeader from '../../common/components/SubHeader';
import ProcessCard from '../../views/process-card/index';
import NavItem from '../../common/components/NavItem';
import BusinessRoleCard from './business-role-card';
import AbapModernizer from './abap-modernizer';
import NewFunction from './new-function/index';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';

export default function (props) {
  const classes = useStyles();
  const [emptyCards, setEmptyCards] = useState([]);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const {
    showLogs = false,
    abapFlow = false,
    setShowLogs = () => null,
    setAbapFlow = () => null,
  } = props;

  const [
    showNewBusinessFunctionModal,
    setShowNewBusinessFunctionModal,
  ] = useState(false);

  useEffect(() => {
    let total = props.businessFunctions.length;
    let cards = total <= 6 ? total : total % 6;
    let arr = [];
    setEmptyCards(arr);
    if (cards > 0) {
      for (let i = 0; i < 6 - cards; i++) {
        arr.push({ id: i });
        setEmptyCards(arr);
      }
    }
  }, [props.businessFunctions]);

  const subHeaderMenus = () => {
    let arr = [];
  
    if(getPermissions()?.projects?.business_function?.canView){
      arr.push({menu: 'Home',url: '/',selected: true});
    }
    if(getPermissions()?.projects?.database?.canView){
      arr.push({menu: 'Database',url: '/Database',selected: false});
    }
    if(getPermissions()?.projects?.drive?.canView){
      arr.push({menu: 'Drive',url: '/Drive',selected: false});
    }
    if(getPermissions()?.projects?.import_logs?.canView){
      arr.push({menu: 'Import Logs', url: '/import_logs', selected: false});
    }
    if(getPermissions()?.projects?.project_settings?.canView){
      arr.push({menu: 'Settings',url: '/Settings',selected: false});
    }
  
    return arr;
  
  };


  const permissionContainer = {
    borderRadius: 8,
    backgroundColor: '#F5F6FA',
    overflow: 'hidden',
    width: "624px",
    border: "1px solid #F5F6FA"
  };


  const permissionHeaderData = [
    {
      placeholder: 'First Name',
      type: 'text',
      xs: 3.23,
      rowxs: 3.23,
      rowType: 'text',
      key: "firstName"
    },
    {
      placeholder: 'Last Name',
      type: 'text',
      xs: 3.23,
      rowxs: 3.23,
      rowType: 'text',
      key: "lastName"
    },
    {
      placeholder: 'Loginid',
      type: 'text',
      xs: 3.23,
      rowxs: 3.23,
      rowType: 'text',
      key: "loginName"
    },
    {
      placeholder: 'Access',
      type: 'dropdown',
      xs: 2.31,
      rowxs: 2.0,
      rowType: 'checkboxOnly',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      key: "access"
    },
  ];

  const permissionColumnStyle = [
    {
      column: {
        backgroundColor: 'white',
        width: '160px',
        marginLeft: '8px',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '152px',
        marginLeft: '16px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        fontSize: "13px",
        height: 'auto',
        fontWeight: "500"
      },
    },
    {
      column: {
        backgroundColor: 'white',
        width: '160px',
        marginLeft: '8px',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '152px',
        marginLeft: '16px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        fontSize: "13px",
        height: 'auto',
        fontWeight: "500"
      },
    },
    {
      column: {
        backgroundColor: 'white',
        width: '160px',
        marginLeft: '8px',
        fontSize: "13px"
      },
      row: {
        backgroundColor: 'white',
        width: '152px',
        marginLeft: '16px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        fontSize: "13px",
        height: 'auto',
        fontWeight: "500"
      },
    },
    {
      column: {
        backgroundColor: 'white',
        width: '96px',
        fontSize: "13px",
        marginLeft: '8px',
      },
      row: {
        backgroundColor: 'white',
        width: '96px',
        marginLeft: '4px',
        fontWeight: "400",
        fontSize: "13px",
        textAlign: "center",
      },
    },
  ];


  const businessProcess = {
    image: '../../json/project.png',
    screenshot:
      props.project && props.project.screenshot
        ? s3Url(props.project.screenshot)
        : undefined,
    name: 'Process details',
    id: '1',
    last_edited: '',
    business_functions: [
      {
        name: 'Purchase Logs',
        id: '1',
      },
      {
        name: 'Sell logs',
        id: '1',
      },
    ],
  };

  const permissionFooterDetail = {
    secondaryBtn: 'Back',
    primaryBtn: 'Share',
  };


  const deleteAction = () => {
    setIsShowDelete(false)
    props.projectDelete()
  }

  const tabMenu = (
    // classes.projectRightTab
    <Grid xs={5} className={`databaseRightGrid`}>  
      {
        ( getPermissions()?.projects?.project_share?.canView ) &&
        <Tab
          style={{
            fontSize: '13px',
            color: 'black', width: "100px"
          }}
          onClick={() => props.handlePermissionModal("open")}
          className='rightTab divider'
          icon={<ShareIcon style={{fontSize:"20", width: "30px"}}/>}
          label='Share'
        />
      }
      {
        ( getPermissions()?.projects?.canDelete ) &&
        <Tab
          style={{
            fontSize: '13px',
            color: '#da1e28', width: "160px"
          }}
          onClick={() => setIsShowDelete(true)}
          className='rightTab divider'
          icon={<DeleteIcon style={{fontSize:"20", width: "30px"}}/>}
          label='Delete Project'
        />
      }
    </Grid>
  );

  const closeAbapFlow = () => {
    fetchBusinessFunctions(props.match.params.id);
    setShowNewBusinessFunctionModal(false);
    setAbapFlow(false);
  }

  const handleAppCardClick = (obj = {}) => {
    props.navigateToBusiness('businessfunction', obj?.businessFunctionName, props.project);
  }


  return (
    <Box>
      <SubHeader
        menus={props.projectId === LOGINMICROAPP ? [] : subHeaderMenus()}
        navigateTab={props.navigateTab}
        downloadAvail={false}
        rightTab={tabMenu}
      ></SubHeader>
      {props.process && <Box component='div' className={classes.projectDetails}>
        {getPermissions()?.projects?.canView && <Grid container spacing={0}>
          <Grid item xs={12} className='project-details-bg'>
            <SubContentHeader
              left={12}
              right={0}
              isToolTipDesc={(props.process?.description != undefined) && (props.process.description.length > 50) ? true : false }
              toolTipDesc={props.process?.description}
              title={props.process?.name != undefined ? props.process.name : ''}
              subtitle={props.process?.description || '' }
            />
            <Grid item xs={12}>
              <Container fixed className='project-detail-container'>
                <Grid item xs={3} className='project-detail-grid'>
                  {
                    getPermissions()?.projects?.business_process?.canView &&
                    <>
                    <h4 className='sub-title'>{configMessage.T4548}</h4>
                    <Container className='project-process-list-container'>
                      {
                        <ProcessCard
                          projectType='businessProccess'
                          navigateToBusiness={props.navigateToBusiness}
                          project={props.process}
                          projectId={props.process.id}
                          navigate='/ProcessDesigner'
                        />
                      }
                    </Container>
                    </>
                  }
                  {
                    getPermissions()?.projects?.canView &&
                    <Container className='business-roles'>
                      <Box className='business-role-head'>
                        <h4 className='detail-head-name'> {configMessage.T4547}</h4>
                      </Box>
                      <Box className='role-grid' d-flex>
                        {props.roles && props.roles.length > 0 ? props.roles.map((role, i) => (
                              <BusinessRoleCard
                                onClick = {() => handleAppCardClick(role)}
                                role = {role}
                              />
                        )) :
                          <Box className='role-card-empty' d-flex>
                            <Box className='role-card-text'>
                              {configMessage.T4540}
                            </Box>
                          </Box>
                        }
                      </Box>
                    </Container>
                  }
                </Grid>
                {getPermissions()?.projects?.business_function?.canView &&
                <Grid item xs={9} className='project-detail-grid'>
                  <Box style={{ display: 'flex' }}>
                    <h4 className='sub-title'>{configMessage.T4549}</h4>
                    {getPermissions()?.projects?.business_function?.canCreate && <Button
                      variant='contained'
                      id='newproject-detail-btn'
                      color='secondary'
                      onClick={() => {
                        props.resetIsExistingName();
                        setShowNewBusinessFunctionModal(true);
                      }}
                      startIcon={<AddIcon />}
                    >
                      {configMessage.T4550}
                    </Button>
                    }
                    <NewFunction
                      isExistingName={props.isExistingName}
                      checkExistingName={props.checkExistingName}
                      reloadFunctionList={props.reloadFunctionList}
                      creatingFunction={props.creatingFunction}
                      createBusinessFunction={props.createBusinessFunction}
                      businessFunctions={props.businessFunctions}
                      show={showNewBusinessFunctionModal}
                      templateCardData={props.templateCardList}
                      navigate={props.navigateToBuisnessFunction}
                      clicked={() => setShowNewBusinessFunctionModal(false)}
                      setAbapFlow={setAbapFlow}
                    />
                  </Box>
                  <Container className='project-function-list-container'>
                    {props.businessFunctions &&
                    props.businessFunctions.length > 0 ? (
                      props.businessFunctions.map((data, i) => (
                        <ProcessCard
                          projectType='businessFunction'
                          navigateToBusiness={props.navigateToBusiness}
                          project={data}
                          projectId={props.projectId}
                        />
                      ))
                    ) : (
                      <Container className='projectdetail-canvas'>
                        <Box component='div' className='inner-div'>
                          <Box>
                            <h4>{configMessage.T4552}</h4>
                          </Box>
                          <Box>
                           {configMessage.T4551}
                          </Box>
                          {
                            getPermissions()?.projects?.business_function?.canCreate &&
                            <Box>
                              <Button
                                variant='contained'
                                color='primary'
                                className='add_function_btn'
                                onClick={() =>
                                  setShowNewBusinessFunctionModal(true)
                                }
                                startIcon={<AddIcon />}
                              >
                                {configMessage.T4550}
                              </Button>
                              <NewFunction
                                checkExistingName={props.checkExistingName}
                                reloadFunctionList={props.reloadFunctionList}
                                creatingFunction={props.creatingFunction}
                                setAbapFlow={setAbapFlow}
                                createBusinessFunction={
                                  props.createBusinessFunction
                                }
                                isExistingName={props.isExistingName}
                                businessFunctions={props.businessFunctions}
                                show={showNewBusinessFunctionModal}
                                templateCardData={props.templateCardList}
                                navigate={props.navigateToBuisnessFunction}
                                clicked={() =>
                                  setShowNewBusinessFunctionModal(false)
                                }
                              />
                            </Box>
                          } 
                          </Box>
                        </Container>
                      )}
                    {props.businessFunctions &&
                      props.businessFunctions.length > 0
                      ? emptyCards.map((data, i) => (
                        <Box component='div' className='emptyCard' />
                      ))
                      : null}
                  </Container>
                </Grid>
                }
                {isShowDelete ?
                  <PromptDialog
                    open={isShowDelete}
                    yes={() => deleteAction()}
                    close={() => { setIsShowDelete(false) }}
                    data={{
                      text: `${configMessage.T4545} ${props.process.name != undefined ? props.process.name : ''}? ${configMessage.T4546}`,
                      title: configMessage.T4544, action: 'warning'
                    }}
                  />
                  : ''
                }
              </Container>
            </Grid>
          </Grid>
        </Grid>
        }
      </Box>
      }
      { 
        abapFlow &&
        <AbapModernizer
            {...props}
            project = {props.project}
            closeAbapFlow = {closeAbapFlow}
            show = {abapFlow}
          />
      }
      {
        showLogs &&
        <Logs
            show = {props.showLogs}
            selectedSap = {{ 
              id: props.project?.programeId, 
              obj_name: props.project?.program 
            }}
            setShowLogs = {(flag) => {
              setShowLogs(false);
              setShowReports(flag);
            }}
            modernize = {props.modernize}
        />
      }
      {
        showReports &&
        <Reports
            show = {showReports}
            selectedSap = {{ 
              id: props.project?.programeId, 
              obj_name: props.project?.program 
            }}
            setShowReports = {() => {
              setShowReports(false);
              resetStateData();
            }}
            modernize = {props.modernize}
        />
      }
      
      <ManagePermission
        permissionContainerStyle = {permissionContainer}
        showDialog={(props.shareOpen) ? true : false}
        psnHeaderData={permissionHeaderData}
        psnListData={props.projectShareList}
        // psnDetailData={props.permissionDetail}
        permissionColumnStyle={permissionColumnStyle}
        permissionFooterDetail={permissionFooterDetail}
        // handleChange={props.handleChange}
        handlePermissionModal={ props.handlePermissionModal}
        modalTitletext={configMessage.T4543}
        getPermissionResp={props.projectShareSubmit}
        canUpdate = {getPermissions()?.projects?.project_share?.canUpdate}
      />
      
    </Box>
  );
}
