import { validateValue, validateValues } from './variableValidations';
import DeleteIcon from "../../images/delete.png";
import { useForm } from "../forms/Form";
import styles from "./index.module.css";
import React from "react";


function Column(props) {

    const { index, data, dataType, onChange, deleteColumn, columnError, setColumnError, fields, keyValidation, focus } = props;

    const onWatch = (errors, val) => {
        validateValue(errors, val.name, val.value)
        onChange(index, val.name, val.value, errors);
        setColumnError({ key: "" });
    }

    const onSubmit = async (values, errors) => {
        console.log(values, errors);
        if (validateValues(values, errors)) {
        }
    };

    const deleteField = () => {
        deleteColumn(index);
        setColumnError({ key: "" });
    }

    
    const { Input, Error, Select } = useForm(data, onSubmit, onWatch);

    return (
        <div className={styles.columnContainer} key={index}>
            <div style={{ width: "25%" }} className={styles.columnInnerContainer}>
                <Input
                    type='text'
                    id="key"
                    name="key" autoComplete="off"
                    value={data.key}
                    // onChange={keyValidation} 
                    // autoFocus={focus}
                    style={{ width: "100%" }} />
                {columnError.key && fields.length - 1 === index ? <Error message={columnError.key} /> : <Error name={'key'} />}
            </div>
            <div style={{ width: "25%" }} className={styles.columnInnerContainer}>
                <Select
                    name="dataType"
                    id="dataType"
                    defaultValue={data.dataType}
                    style={{ width: "100%" }}>
                    {dataType.map((title, index) => <option key={`title-${index}`} value={title}>{title}</option>)}
                </Select>
            </div>
            <div style={{ width: "15%" }} className={styles.columnInnerContainerchkradio}>
                <Input type="checkbox" name="isPrimary" defaultValue={data.isPrimary} />
            </div>
            <div style={{ width: "15%" }} className={styles.columnInnerContainerchkradio}>
                <Input type="checkbox" name="isIndex" defaultValue={data.isIndex} />
            </div>
            <div style={{ width: "10%" }} className={styles.columnInnerContainerchkradio}>
                {index > 0 ? <img data-clickable-testid='delete'
                    style={{ cursor: "pointer" }}
                    src={DeleteIcon}
                    alt='Delete system'
                    onClick={deleteField} /> : ""}
            </div>
        </div>
    )
}

export default Column