import { get, getUrl, upload, download, deleteAPI, put } from "../../url";

const getFilter = (asset) => {
  let filter = "&$filter=";
  let obj = [];
  Object.keys(asset).map(function (key, index) {
    if (key === "projects") {
      //   if (asset[key] !== "") obj.push("IN (projectName,'" + asset[key] + "')");
      if (asset[key] !== "")
        obj.push("p.name" + " LIKE" + encodeFilter(asset[key]));
      else return;
    } else if (key === "lastModifiedBy") {
      if (asset[key] !== "")
        obj.push("u.login_name" + " LIKE" + encodeFilter(asset[key]));
      else return;
    } else {
      obj.push("pc." + key + " LIKE " + encodeFilter(asset[key]));
    }
  });
  obj.length > 0 ? (filter += obj.join(" AND ")) : (filter = "");
  return filter;
};

const encodeFilter = (input) => {
  return encodeURIComponent("'%" + input + "%'");
};

export async function getAssets(limit, offset, asset) {
  const filter = getFilter(asset);
  const uri =
    getUrl() +
    `/library/v1.0/assets/list?$select=name,projects,size,type,modifiedDate,lastModifiedBy&$sort=asc name` +
    `&limit=${limit}&offset=${offset}${filter}`;
  const [error, data] = await get(uri);
  return [error, data];
}

export async function uploadAsset(fileName, bytes) {
  const url = getUrl() + `/library/v1.0/assets/${fileName}`;
  const [error, data] = await upload(url, bytes);
  return [error, data];
}

export async function downloadAsset(fileName) {
  const url = getUrl() + `/library/v1.0/assets/${fileName}`;
  const [error, data] = await get(url);
  return [error, data];
}

export async function deleteAsset(fileName) {
  const url = getUrl() + `/library/v1.0/assets/${fileName}`;
  const [error, data] = await deleteAPI(url, null);
  return [error, data];
}

export async function updateAsset(fileName, bytes) {
  const url = getUrl() + `/library/v1.0/assets/${fileName}`;
  const [error, data] = await put(url, bytes);
  return [error, data];
}

export async function shareAsset(fileName, projects) {
  const url = getUrl() + `/library/v1.0/assets/${fileName}/shareAsset`;
  const [error, data] = await put(url, projects);
  return [error, data];
}

export async function assetContent(url) {
  const res = await fetch(url);
  return res;
}
