import React from "react";
import "../../App.css";
import NotFound from "../not-found";
import { Route, Switch } from "react-router-dom";
import SubHeader from '../../components/header/subheader';
import Integrations from "../administration/integrations/integration";
import Organization from "../administration/organisation/organisation";
import Authentication from "../administration/authentication/authentication";
import UserManagement from "../administration/userManagement/userManagement.js";
import PasswordPolicy from "../administration/password-policy/passwordPolicy";
import SessionSettings from "../administration/session-settings/sessionSettings";
import TenantAdmin from "../administration/tenantAdministration/tenantAdministration";
import UserLogs from "../administration/userLogs/userLogs";
import { getPermissions } from "../../common/utils/utils";
import UserRole from "../../../containers/settings/roles" //old userRole
import LicenseRenewal from "../administration/licenseRenewal/licenseRenewal";


const Administration = () => {
  const links = getPermissions("Administration")?.childPermissions;

  return (
    <div>
      <SubHeader links={links} />
      <Switch>
        <Route path={"/Administration/organization"} render={()=><Organization perm={links.find((e)=>e.name==="Organization")}/>} />
        <Route path={"/Administration/passwordpolicy"} render={()=><PasswordPolicy perm={links.find((e)=>e.name==="Password Policy")}/>} />
        <Route path={"/Administration/sessionsettings"} render={()=><SessionSettings perm={links.find((e)=>e.name==="Session Settings")}/>} />
        <Route path={"/Administration/authentication"} render={()=><Authentication perm={links.find((e)=>e.name==="Authentication")}/>} />
        <Route path={"/Administration/usermanagement"} render={()=><UserManagement perm={links.find((e)=>e.name==="User Management")}/>} />
        <Route path={"/Administration/userlogs"} render={()=><UserLogs perm={links.find((e)=>e.name==="User Logs")}/>} />
        <Route path={"/Administration/integrations"} render={()=><Integrations perm={links.find((e)=>e.name==="Integrations")}/>} />
        <Route path={"/Administration/tenantadministration"} render={()=><TenantAdmin perm={links.find((e)=>e.name==="Tenant Administration")}/>} />
        <Route path={"/Administration/licenserenewal"} render={()=><LicenseRenewal perm={links.find((e)=>e.name==="License Renewal")}/>} />
        <Route path={"/Administration"} render={()=><Organization perm={links.find((e)=>e.name==="Organization")}/>} />
        <Route path={"*"} component={NotFound} />
      </Switch>
    </div>
  );
}

export default Administration;