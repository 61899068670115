import React, { useState } from "react";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import styles from "../LogReport/LogReport.module.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useScheduler } from "../../schedulerContext";

export const LogReport = ({ show, handleClose, logReportData }) => {
    let style = { height: "26rem", outline: "none" };

    const [data, setData] = useState({})

    const { setReport } = useScheduler();

    const onWatch = (errors, data) => {
        // validateValue(errors, data.name, data.value);
    }

    const onSubmit = async (values, errors) => {

        // if (!validateValues(values, errors))
        //     return false;

        // let payload = { ...values };
        // const project = ProjectsActions(app())
        // const [error, data] = await project.createNewProject(payload);

        // if (error || !data.status?.cod {
        //     setApiError(error || data.status?.message);
        //     return false;
        // } else {
        //     handleClose();
        //     navigate(`/project/${values.name}`);
        //     return true;
        // }
    }

    const { Form, Label, Input, Error, TextArea, Select } = useForm(data, onSubmit, onWatch);

    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"scheduler-log-report"} >
            <ArrowBackIcon onClick={() => setReport(false)} />
            <h1 className={styles.timeBasedScheduleHeader} data-text-testid="title">
                Scheduler Log Report
            </h1>
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>
                        <TextArea style={style} value={logReportData}
                            type="text" id="description" name="description" />
                    </div>
                </div>
            </Form>
        </PopUp>
    </>
}