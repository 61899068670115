import React from "react";
import { useState } from "react";
import PopUp from "../../../../components/layouts/PopUp";
import TransportForm from "./transportForm";
import TransportMetadata from "./transportMetadata";
import TransportProject from "./transportProject";

export default function TransportService(props) {
    const { getList, transport, setTransport } = props;
    const [transportService, setTransportService] = useState({ comment: "", item: null });

    const handleClose = () => {
        setTransport((val) => ({ ...val, show: false }));
        getList();
    }

    const [renderComponent, setRenderComponent] = useState("TransportForm")

    return (
        <PopUp popUp={transport.show} setPopUp={handleClose}>
            {renderComponent === "TransportForm" ? <TransportForm setRenderComponent={setRenderComponent} serviceData={transport.item} handleClose={handleClose} transportService={transportService} setTransportService={setTransportService} /> : ""}
            {renderComponent === "Metadata" ? <TransportMetadata setRenderComponent={setRenderComponent} serviceData={transport.item} transportService={transportService} /> : ""}
            {renderComponent === "TransportProject" ? <TransportProject handleClose={handleClose} serviceData={transport.item} transportService={transportService} /> : ""}
        </PopUp>
    )
}
