import {Rectangle} from './shapes'
import task_icon from "../images/task.svg";
import activity_icon from "../images/activity.svg";


function Task()
{
    Rectangle.call(this,
        {
            type:"Task",
            icon:activity_icon,
            options:[]
        });

}
Task.prototype = Object.create(Rectangle.prototype);
Task.prototype.fromJSON = function(json)
{
    
}
Task.prototype.toJSON = function()
{
    return null;
}
Task.prototype.getPreview = function()
{
    return null;
}
Task.prototype.getStep = function(){
    let step = {
      name: this.type,
      uid: this.uid,
      type: this.type,
      parent:this.parentUID,
      data:this.data
    };
    return step;
}
export default Task;
