import { checkDuplicateKey, validateValue, validateValues } from './variableValidations';
import React, { useEffect, useState } from 'react';
import styles from "./index.module.css";
import { useForm } from '../forms/Form';
import PopUp from '../layouts/PopUp';
import Column from './column';


function VariablePopup(props) {
    let formStyle = { margin: "1rem" }
    const {
        isUpdate = false,
        variableData = {},
        variablePopup = false,
        saveOrUpdate = () => null,
        setVariablePopup = () => null,
        type = "bfVar"
    } = props;

    const [table, setTable] = useState(false);
    const dataType = ["String", "Number", "Decimal"];
    const [columnError, setColumnError] = useState({ key: "" });
    const [variable, setVariable] = useState({ name: "", type: "Value", dataType: "String", persist: "" });
    const [errorValue, setErrorValue] = useState("");
    const [checked, setChecked] = useState(true);
    const [valueType, setValueType] = useState("text");
    const [focusName,setFocusName] = useState(false)
    const [focusVal,setFocusVal] = useState(false)
    const [focusKey,setFocusKey] = useState(false)
    const [colIndex,setColIndex] = useState(0);

    useEffect(() => {
        if (isUpdate === true) {
            if (variableData.type === "Table") {
                setVariable({ ...variableData, type: "Table", Table: "table", fields: variableData.field.fields, tableValue: JSON.stringify(variableData.field.value) })
                setTable(true)
                setChecked(false)
            }
            else setVariable({
                ...variableData, dataType: variableData.type, type: "Value",
                fields: [{ key: "", dataType: "String", isPrimary: false, isIndex: false }]
            });
        }
        else setVariable({
            name: "", type: "Value", dataType: "String", value: "", tableValue: "",
            fields: [{ key: "", dataType: "String", isPrimary: false, isIndex: false }]
        })
    }, [isUpdate, variableData]);

    const onChange = (index, key, value, errors) => {
        setColumnError(errors);
        setErrorValue("");
        variable.fields[index][key] = value;
        if (key === "isPrimary") {
            variable.fields = variable.fields.map((obj) => {
                if (variable.fields.indexOf(obj) === index) {
                    return ({ ...obj, [key]: true })
                } else {
                    return ({ ...obj, [key]: false })
                }
            })
        }
    }

    const onWatch = (errors, data) => {
        setErrorValue("");
        validateValue("", "dataType", data.dataType)
        validateValue(errors, data.name, data.value)
        variable[data.name] = data.value;
        setVariable(variable);
        if (data.value === "Value") {
            setTable(false)
            setChecked(true)
        } else if (data.value === "Table") {
            setTable(true)
            setChecked(false)
        }
        if (data.name === "dataType") {
            (data.value === "String" ? setValueType("text") : setValueType("number"))
            setVariable({ ...variable, value: "" });
        }
    }
    const handleValue = (tableData, feilds, errors) => {
        try {
            if (!tableData.startsWith('[') && !tableData.endsWith(']')) {
                throw ""
            }

            let tempValue = JSON.parse(tableData);
            let tableField = Object.keys(tempValue[0]);
            let fieldValue = Object.values(tempValue[0]);

            for (let field of feilds) {
                if (!tableField.includes(field.key)) {
                    throw "";
                } else {
                    let index = tableField.indexOf(field.key);
                    if (typeof fieldValue[index] !== (field.dataType === "Decimal" ? "number" : field.dataType.toLowerCase())) {
                        throw "";
                    }
                    if (typeof fieldValue[index] === "number") {
                        if (field.dataType === "Number" && !fieldValue[index].toString().match(/^([+-]?[0-9]*)?$/)) {
                            throw "";
                        } else if (field.dataType === "Decimal" && !fieldValue[index].toString().match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)) {
                            throw "";
                        }
                    }
                }
            }
            return true;
        }
        catch (e) {
            // setErrorValue("Please provide valid table values");
            errors.set("tableValue", "Please provide valid table values")
            return false;
        }
    }

    const onSubmit = async (values, errors) => {
        let currentValue = {};

        if (values.type === "Value") {
            validateValue("", "dataType", values.dataType)
            const valueData = { name: values.name, dataType: values.dataType, value: values.value }

            if (validateValues(valueData, errors)) {
                currentValue = {
                    id:values.id,
                    name: values.name,
                    type: values.dataType,
                    dataType: values.dataType,
                    value: values.value,
                    field: { fields: false, value: values.value }
                }
                saveOrUpdate(isUpdate, currentValue);
                setVariablePopup(false)
            } else {
                return false;
            }
        } else if (values.type === "Table") {
            const valueData = { name: values.name }
            if (!handleValue(values.tableValue, values.fields, errors) || !validateValues(valueData, errors) || !checkDuplicateKey(errors, values.fields)) {
                return false;
            } else {
                currentValue = {
                    name: values.name,
                    type: values.type,
                    value: false,
                    field: {
                        fields: values.fields,
                        value: JSON.parse(values.tableValue)
                    }
                }
                saveOrUpdate(isUpdate, currentValue);
                setVariablePopup(false)
            }
        }
    };

    const deleteColumn = (index) => {
        let _variable = variable;
        _variable.fields.splice(index, 1);
        setVariable({ ...variable, ..._variable });
    }

    const addColumn = () => {
        let valueArr = variable.fields.map(function (item) { return item["key"] });
        let duplicatekey = "";
        let isDuplicate = valueArr.some(function (item, idx) {
            duplicatekey = item;
            return valueArr.indexOf(item) != idx
        });
        let haveValue = true;
        variable.fields.map((fl) => {
            for (let val in fl) {
                if (val === "key" && !fl[val]) {
                    haveValue = false;
                    setColumnError({ ...columnError, key: "Please enter column name" });
                    break;
                }
                if (isDuplicate) {
                    haveValue = false;
                    setColumnError({ ...columnError, key: duplicatekey + " - column already exists." });
                    break;
                }
            }
        })
        if (haveValue) {
            let _variable = variable;
            _variable.fields.push({ key: "", dataType: "String", isPrimary: false, isIndex: false });
            setVariable({ ...variable, ..._variable });
        }
    }

    const alphabetOnly = (e) => {
        const { value } = e.target;

        const re = /^[0-9A-Za-z_ ]+$/;
        if ((value === "" || re.test(value)) && value.length <= 45) {
            setVariable(val => ({ ...val, name: value }));
            console.log("value : ",value," : ",variable);
        }
        setFocusName(true)
        setFocusVal(false)
    }

    const basedOnTypeValidation = (e) => {
        const { value } = e.target;
        const dataType = variable.dataType;
        if (dataType === "Decimal") {
            if (value.match(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/)) 
                setVariable(val => ({ ...val, value: value }));
        } else if (dataType === "Number") {
            if (value.match(/^([+-]?[0-9]*)?$/))    
                setVariable(val => ({ ...val, value: value }));
        }
        else
            setVariable(val => ({ ...val, value: value }));
        setFocusVal(true)
        setFocusName(false)
    }

    const alphabetOnlyKey = (e) => {
        const { value } = e.target;

        const re = /^[0-9A-Za-z_ ]+$/;
        if (value === "" || re.test(value)) {
            let data = variable;
            let fields = variable['fields'];
            fields[colIndex].key = value;
            data['fields'] = fields;
            setVariable(data);
            console.log("value : ",value," : ",variable);
        }
        setFocusKey(true)
    }

    const { Form, Label, Input, Error, Select, Radio, TextArea } = useForm(variable, onSubmit, onWatch);

    return (
        <PopUp setPopUp={setVariablePopup} popUp={variablePopup} title={`${isUpdate ? "Edit" : "Add"} Variable`} testId={isUpdate === true ? "edit-variable" : "add-variable"}>
            <Form>
                <div className={styles.popupContainer} style={{ margin: "1rem" }}>
                    <div className={styles.formContainer}>
                        <div style={{ width: "50%" }}>
                            <Label text="Variable Name" htmlFor="Variable Name" />
                            <Input type='text' style={{ width: "100%" }} id="name" name={"name"} autoComplete="off" placeholder="Name" onChange={alphabetOnly} value={variable.name} autoFocus={focusName}/>
                            <Error name={'name'} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Label text="Type" htmlFor="Type" />
                            <div className={styles.radioVariable}>
                                <Radio type="radio" defaultValue="Value" checked={variable.type === "Value"} name="type" id="type" style={{ marginRight: "10px" }} /><small style={{ marginRight: "1rem" }}>Value </small>
                                <Radio type="radio" defaultValue="Table" checked={variable.type === "Table"} name="type" id="type" style={{ marginRight: "10px" }}/><small>Table</small>
                            </div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Label text="Persist" htmlFor="persist" />
                            <div className={styles.radioVariable}>
                                <Radio type="radio" defaultValue="persist" name="persist" id="persist" style={{ marginRight: "10px" }} /><small style={{ marginRight: "1rem" }}>Persist</small>
                                <Radio type="radio" defaultValue="Don't persist" name="persist" id="persist" style={{ marginRight: "10px" }} /><small>Don't Persist</small>
                            </div>
                        </div>
                        {!table
                            ? <div style={{ width: "50%" }}>
                                <Label text="Data Type" htmlFor="Data Type" />
                                <Select
                                    style={{ width: "100%" }}
                                    name="dataType"
                                    id="dataType"
                                >
                                    {dataType.map((title, index) => {
                                        return <option key={index} value={title.name}>{title}</option>
                                    })}
                                </Select>
                                <Error name={'dataType'} />
                                <div style={{ width: "100%", marginTop: "12px" }}>
                                    <Label text="Value" htmlFor="value" />
                                    <Input style={{ width: "100%" }} className={styles.variableValue} type="text" id="value" name="value" value={variable.value} onChange={basedOnTypeValidation} autoFocus={focusVal}/>
                                    <Error name={'value'} />
                                </div>
                            </div>
                            : <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
                                <div className={styles.columnContainer}>
                                    <Label text="Column Name" htmlFor="Column Name" style={{ width: "25%" }} className={styles.columnInnerContainer} />
                                    <Label text="Column Type" htmlFor="Data Type" style={{ width: "25%" }} className={styles.columnInnerContainer} />
                                    <Label text="Primary" htmlFor="Primary" style={{ width: "15%" }} className={styles.columnInnerContainer} />
                                    <Label text="Apply Index" htmlFor="Apply Index" style={{ width: "15%" }} className={styles.columnInnerContainer} />
                                    <div style={{ width: "10%" }} className={styles.columnInnerContainer}></div>
                                </div>
                                {variable?.fields?.map((item, index) => {
                                    // let spltkey = item.key.split('$');
                                    // item.key = spltkey[spltkey.length - 1];
                                    // setColIndex(index);
                                    return (<Column data={item} Label={Label} Error={Error} onChange={onChange} columnError={columnError}
                                        index={index} dataType={dataType} deleteColumn={deleteColumn} addColumn={addColumn}
                                        setColumnError={setColumnError} fields={variable.fields} keyValidation={alphabetOnlyKey} focus={focusKey}/>
                                    )
                                })}
                                 <Error name={"key"} />
                                <Input type="button" className={`secondary ${styles.addField}`} value={"+ Add field"} onClick={addColumn} />
                                <div style={{ width: "50%", marginTop: "14px" }}>
                                    <Label text="Value" htmlFor="tableValue" />
                                    <TextArea style={{ width: "100%" }} className={styles.tableValue} type="text" id="tableValue" name="tableValue" defaultValue={variable.tableValue} />
                                    <Error name={'tableValue'} />
                                    <div className="errorMessage" data-error-testid="tableValue">{errorValue}</div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="afFooter">
                    <Input className='secondary' type="button" size="small" value={"Cancel"} onClick={() => setVariablePopup(false)} />
                    <Input type="submit" value={"Save"} />
                </div>
            </Form>
        </PopUp >
    )
}

export default VariablePopup;
