const messages = {
    loadTenants:"Loading all the tenants",
    saveCompany:"Creating new tenant",
    nameErrMsg:"Please enter valid company name",
    nameLength:45,
    phoneNumberErrMsg:"Please enter valid phone number",
    phoneNumberLength:15,
    primaryContactErrMsg:"Please enter valid primary contact",
    primaryContactLength:45,
    languageCodeErrMsg:"Please select the language",
    timZoneErrMsg:"Please select the timezone",
    dateFormatErrMsg:"Please select the dateformat",
    salutationErrMsg:"Please select the salutation",
    firstNameErrMsg:"Please enter valid first name",
    firstNameLength:45,
    lastNameErrMsg:"Please enter valid last name",
    lastNameLength:45,
    loginNameErrMsg:"Please enter valid login name",
    loginNameLength:45,
    titleErrMsg:"Please enter valid title",
    titleLength:45,
    emailErrMsg:"Please enter valid email",
    emailLength:255,
    packageMasterNameErrMsg:"Please select the package name",
    termErrMsg:"Please select the term",
    autoRenewalErrMsg:"Please select the autorenewal",
    generateLicense: "Generating License Key...",
    getLicenseDetails: "Retrieving License Details..."
};
export default messages;