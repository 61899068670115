import React, { Component } from 'react';
import PreviewLayout from './layout.js';

class AppPreviewComponent extends Component {
  constructor(props) {
    super(props);
    this.navigateBack = this.navigateBack.bind(this);
    this.state = {
      deviceLayout: 'iPhone', // values - iPhone / iPad / Tablet / Android,
      deviceOrientation: 'Portrait', // values - Portrait / Landscape
    }
  }

  isWorkflow = () => {
    return window.location.href.includes('isWorkflow=true');
  };

  navigateBack = () => {
    const { Project, BusinessFunction,toConsole } = this.props.match.params;
    if(toConsole)
      this.props.history.push('/Console');
    else
      this.props.history.push(
        `/project/${Project}/BusinessFunction/${BusinessFunction}`,
        { showModal: this.isWorkflow() }
      );
  };

  render() {
    const { Project, BusinessFunction, Lane } = this.props.match.params;
    return (
      <PreviewLayout
        Project={Project}
        BusinessFunction={BusinessFunction}
        Lane={Lane}
        navigateBack={this.navigateBack}
        setState={(data) => this.setState(data)}
        {...this.state}
      />
    );
  }
}

export default AppPreviewComponent;
