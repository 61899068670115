import { useAppContext } from "../../components/activity/AppContext";
import { validateValue, validateValues } from "./validations";
import { useForm } from '../../components/forms/Form';
import PopUp from "../../components/layouts/PopUp";
import React, { useEffect, useState } from "react";
import { DriveActions } from "./drive.actions";
import styles from "./index.module.css";
import FolderTree from "./folderTree";

function DriveCopyOrMove(props) {
    const [data, setData] = useState({ name: "", isShared: true });
    const [apiError, setApiError] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(true);
    const app = useAppContext();
    const [selectedNode, setSelectedNode] = useState(null);
    const {
        show = false,
        copyOrMove =null,
        file = null,
        list = [],
        projectName = "",
        setShow = () => null,
        getList = () => null
    } = props;
    const rootFolder = {id:-1,name:'/',path:'',parent:'',parentId:0}
    let folderList = list.filter(item => {
        item.name = item.displayname;
        item.isLeaf = true;
        item.open = "nestedNode";
        return item;
    });
    folderList.unshift(rootFolder);

    let treeList = (items, id = 0, link = 'parentId') =>
        items
            .filter(item => item[link] === id)
            .map(item => ({ ...item, children: treeList(items, item.id) }));


    const handleClose = () => {
        setShow("");
    }

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const validatePath = (destination) => {
        let index = (file.path).indexOf(file.displayname);
        let sourcePath = (file.path).substr(0,index);
        if(sourcePath == destination)
            setDisableSubmit(true);
        else
            setDisableSubmit(false);
    }
    const onSubmit = async (values, errors) => {
        setApiError('');

        const drive = DriveActions(app());
        console.log('submit : ',selectedNode);
        const [error, data] = await drive.DriveCopyOrMove(projectName, `${selectedNode.path}/`, file.path, copyOrMove.toLowerCase());

        if (error || data.status.type === 'E') {
            setApiError(data.status.message || error);
            return false;
        } else {
            handleClose();
            getList();
            return true;
        }
    }

    const { Form, Label, Input, Error } = useForm(data, onSubmit, onWatch);

    return (
        <PopUp
            popUp={show}
            setPopUp={handleClose}
            titleClassName={styles.treePopupTitle}
            testId={"copyOrMove"}
            title={`${copyOrMove}(${file?.displayname}) to`}
            bodyClassName = {styles.treePopUp}
        >
            <Form>
                <div className={styles.treeFormArea}>
                {<FolderTree 
                    data={treeList(folderList)}  
                    setSelectedNodes={setSelectedNode} 
                    validateDestination={validatePath} 
                    lastSelectedNode={selectedNode}
                    setDisableSubmit={setDisableSubmit}
                    />}
                </div>

                <div className={styles.treeFooter}>
                    <Input type="button" className="secondary" value={"Cancel"} onClick={handleClose} data-clickable-testid={"Cancel"} />
                    <Input type="submit" className="primary" value={`${copyOrMove} Here`} data-clickable-testid={`${copyOrMove} Here`} disabled = {disableSubmit} />
                </div>
            </Form>
        </PopUp>
    )
}

export default DriveCopyOrMove;