import styles from './Activity.module.css';
import { generateUuid } from '../../common';
import NotificationCard from './NotificationCard';
import React ,{ forwardRef, useImperativeHandle, useState } from "react";

const ActivityHost = (props, ref) => {
  const [ state, setState ] = useState({ open:false, render:null })
  const [ notifyState, setNotifyState ] = useState([]);
  const NOTIFY_UPTO = 5 * 1000;  // s * ms;

  const updateNotification = (obj = {}) => {
    let id = generateUuid();
    setNotifyState((prev) => ([...prev, { ...obj, id }]));
    if(obj?.type === "S"){
      setNotifyState((prev) => prev.filter((i) => (i.type !== "E" && i.type !== "W")))
      setTimeout(()=> handleDismiss(id), NOTIFY_UPTO);
    } else if(obj?.type === "E") {
      setNotifyState((prev) => prev.filter((i) => (i.type !== "S" && i.type !== "W") && i.id===id))
    } else if(obj?.type === "W") {
      setNotifyState((prev) => prev.filter((i) => (i.type !== "S" && i.type !== "E") && i.id===id))
    }
  }

  const handleDismiss = (id) => {
    setNotifyState((prev) => prev.filter((i) => i.id !== id));
  }

  useImperativeHandle(ref,()=>({
    show: (render) => { setState({ open:true, render:render })},
    close: () => { setState({ open:false, render:null })},
    notify: updateNotification
  }))

  const getNotificationRenderer = () => {
    let l = notifyState.length;
    // let top = `calc(90vh - ((var(--spacer) * 9px)*${l}))`;
    if(!l) {
      return null;
    } else {
      return (
        <div className={styles.notifications} style = {{ bottom: "calc(var(--spacer) * 2px)" ,right: "calc(var(--spacer) * 2px)"}}>
          { notifyState.map((item) => <NotificationCard item={item} onClose={handleDismiss}/>) }
        </div>
      );
    }
  }

  return (
    <div>
      {
        state.open &&
        <div className={styles.backdrop}>
          {state.render()}
        </div>
      }
      {getNotificationRenderer()}
    </div>
  )
}
 
export default forwardRef(ActivityHost);