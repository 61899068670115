export const messages = {
    name: "Name",
    files: "Files",
    drive: "Drive",
    storage: "Storage",
    newFolder: "New Folder",
    recents: "Recent Files",
    fileSize: "File Size",
    createdBy: "Created By",
    modified: "Last Modified",
    uploadFile: "Upload File",
    sharedDrive: "Drive",
    invalidName: "Invalid Name",
    invalidFile: "Invalid File",
    renameFolder: "Rename Folder",
    uploadingFile: "Uploading file",
    sharingFile: "Sending to Library",
    deleteTitle: "Delete Folder/File",
    loading: "Loading Folders and Files",
    creatingFolder: "Creating new folder",
    loadingRecents: "Loading recent files",
    note: "Note: Name is case in-sensitive",
    loadingStorage: "Loading Storage details",
    loadingFileUrl : "Fetching the file URL",
    deleteFolderFile: "Deleting Folder / File",
    renameFolderFile: "Renaming Folder / File",
    updatePermission: "updating drive permission",
    initiatedDownload: "Download initiated successfully",
    deleteSubTitle: "Do you want to delete this folder / file "
}