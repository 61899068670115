import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/pagination";
import { ModuleTitle } from "../../../components/module-title";
import Table from "../../../components/Table/table";
// import VariablePopup from "./variablePopUp";
import { useAppContext } from "../../../components/activity/AppContext";
import { VariableActions } from "./Variable.Actions";
import styles from "./variables.module.css";
import SmallPopup from "../../../components/layouts/smallPopup";
import VariablePopup from "../../../components/variable";
import { useLocation } from "react-router-dom";

function Variables({perm}) {

    const app = useAppContext();
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [variableListCount, setVariableListCount] = useState(0);
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState({});
    const menus = ["Edit", "Delete"];
    const [variablePopup, setVariablePopup] = useState(false);
    const [variableData, setVariableData] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [variableDelete, setVariableDelete] = useState({ name: "", delete: false });
    const location = useLocation();
    location.state = {permission:perm}

    const getVariablesMasterData = async () => {
        const actions = VariableActions(app());
        const [error, data] = await actions.loadVariableLists(pagination.offset, pagination.limit, filter)
        if (!error) {
            let tempCreatedby = [];
            for (let i = 0; i < data.data.length; i++) {
                if (tempCreatedby.length === 0) {
                    tempCreatedby.push(data.data[i].createdUser)
                }
                else {
                    for (let j = 0; j < tempCreatedby.length; j++) {
                        if (tempCreatedby[j] === data.data[i].createdUser) break;
                        else tempCreatedby.push(data.data[i].createdUser)
                    }
                }
            }
            setHeaders([{ name: "Name", id: "name", type: "input", width: "150px" },
            { name: "Value", id: "value", type: "input", width: "150px" },
            { name: "Type", id: "type", type: "select", value: ["String", "Number", "Decimal", "Table"], width: "150px" },
            { name: "Created by", id: "createdUser", type: "select", value: tempCreatedby, width: "150px" },
            { name: "moreVertIcon", id: "moreVertIcon", type: "moreVertIcon", width: "64px" },
            { name: "Checkbox", id: "checkBox", type: "checkBox", width: "64px" }])
        }
    }

    useEffect(() => {
        getVariablesMasterData();
    }, [])

    const getList = async () => {
        const actions = VariableActions(app());
        const [error, data] = await actions.loadVariableLists(pagination.offset, pagination.limit, filter)
        if (!error) {
            setList(data?.data);
            setVariableListCount(data.noOfRecords);
        }
    }

    useEffect(() => {
        getList();
    }, [filter, pagination])

    const onHeaderChange = (id, value) => {
        setFilter({ ...filter, [id]: value });
    }

    const menuOnClick = (option, value) => {
        switch (option) {
            case "Edit":
                setIsUpdate(true);
                setVariableData(value);
                setVariablePopup(true);
                break;
            case "Delete":
                setVariableDelete({ name: value.name, delete: true });
                // deleteVariable(value.name);
                break;
            default:
                break;
        }
    }

    const deleteVariable = async (name) => {
        setVariableDelete({ name: "", delete: false })
        const actions = VariableActions(app());
        await actions.removeVariable(name);
        const [error, data] = await actions.loadVariableLists(pagination.offset, pagination.limit, filter)
        if (!error) {
            setList(data?.data);
        }
    }

    const saveOrUpdate = async (isUpdate, variable) => {

        const actions = VariableActions(app());

        if (isUpdate) await actions.modifyVariable(variable.name, variable);
        else {
            await actions.saveVariable(variable);
        }
        getList();
    }
    const getContextMenu = () => {
        let cont = menus
        if(!location.state.permission?.canUpdate) cont = cont.filter((e)=>e!=="Edit")
        if(!location.state.permission?.canDelete) cont = cont.filter((e)=>e!=="Delete")
        return cont
    }

    const onNewVariableButton = () => {
        setIsUpdate(false)
        setVariablePopup(true)
    }
    const openVariableDialog = (open, currentVariable, isUpdate) => {
        const _variablePopup = { show: open, edit: currentVariable, isUpdate: isUpdate };
        setVariablePopup({ ...variablePopup, ..._variablePopup });
    }

    const close = () => {
        setVariablePopup({ show: false, edit: "", isUpdate: false })
    }

    return (
        <>
            <ModuleTitle title="Variables" subTitle="Variables defined here are globally available in all projects. Changes affect apps immediately." >
            {
                location.state.permission?.canCreate
                ?<button
                    data-clickable-testid="new-variable"
                    className={`${styles.buttonWidth} primary `}
                    onClick={onNewVariableButton} >
                    + New Variable
                </button>
                :<></>
            }
            </ModuleTitle>
            <div className={styles.varOuterContainer}>
                <div className={styles.varContainer}>
                    <div className={styles.varInnerContainer}>
                        <div className={styles.varTableContainer}>
                            <Table
                                modifyPopUp={getContextMenu}
                                data={list}
                                headerColumns={headers}
                                contextOnChange={menuOnClick}
                                handleHeaderInputChange={onHeaderChange}
                                handleHeaderSelectChange={onHeaderChange}
                                name={"VariablesList"} />
                        </div>
                        <Pagination
                            displayRows={pagination}
                            setDisplayRows={setPagination}
                            userDataCount={variableListCount} />
                    </div>
                </div>
                {variablePopup && <VariablePopup saveOrUpdate={saveOrUpdate} setVariablePopup={setVariablePopup}
                    variableData={variableData} isUpdate={isUpdate} variablePopup={variablePopup} />}
                {variableDelete
                    ? <SmallPopup
                        popUp={variableDelete.delete}
                        title={"Delete Variable"}
                        subtitle={"Are you sure you want to delete?"}
                        handlePopUp={() => setVariableDelete({ name: "", delete: false })}
                        handleSubmit={() => deleteVariable(variableDelete.name)}
                    /> : ""}
            </div>
        </>
    )
}

export default Variables