export const messages = {
    uploadFile: "Upload File",
    invalidFile: "Invalid File",
    uploadPageTwo: "Match columns to database",
    uploadPageThree: "Result",
    success: "Success",
    primaryKeyErr: "Primary key already exists!",
    validColNameErr: "Please provide valid column name",
    validIdxNameErr: "Please provide valid index name",
    validColLengthErr: "Please provide valid column Length",
    validIndexErr: "Please provide valid index name",
    maxTabErr: "Maximum number of tabs execeeded. Kindly close the unused tabs and try again!",
    tabOpenErr: "Table tab is open already. Please close that tab and try again",
    tableNameErr: "Please provide valid table name",
    saveTableErr: "Unsaved data will be lost. Are you sure you want to close?",
    closeTable: "Close Table",
    deleteTable: "Delete Table",
    deleteConfirmationText:"Are you sure you want to delete the table?",
    deleteCol: "Delete Column",
    deleteColumnConfirmationText:"Are you sure you want to delete the column?",
    deleteIndice: "Delete Index",
    deleteIndiceConfirmationText:"Are you sure you want to delete the index?",
    query: "Query",
    cancel: "Cancel",
    save: "Save",
    execute: "Execute",
    queryPlaceholder: "Write your query here..",
    library: "Library",
    addTable: "Add Table",
    validDataTypeErr: "Please provide valid datatype",
    validDataErr: "Please provide valid data",
    validDataLengthErr: "Please provide valid datalength",
    loadingTables: "Loading tables",
    exportTable: "Exporting table data",
    loadingColumns: "Loading columns",
    executingQuery: "Executing query",
    fetchingDetails: "Fetching table details",
    updatingPermissions: "Updating permissions",
    loading: "Loading",
    sharingTable: "Sharing table",
    deletingTable: "Deleting table",
    importingTables: "Importing tables",
    importingFiles: "importingFiles",
    pendingStatus: "Your request is in queue to be processed.\nYou will receive notification once the table is imported.",
    displayRecordLimit: `The output record set limit is set to 200`,
    displayRecordCount: (count) => `Total count of records retrieved - ${count}`
}