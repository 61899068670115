import React, { useEffect, useState, useRef } from 'react'
import styles from './licenseRenewal.module.css'
import { useForm } from "../../../components/forms/Form";
import { validateValue, validateValues } from './licenseRenewalValidation';
import LicenseActions from './licenseRenewal.actions';
import { useAppContext } from "../../../components/activity/AppContext";
import { useHistory, useLocation } from 'react-router-dom';
import { forceLogout } from '../../../common';


function LicenseRenewal(props) {
    const [licenseData, setLicenseData] = useState({
        licenseKey: ""
    })
    const location = useLocation();
    if(props?.perm) {
        location.state = {permission:props.perm}
    }
    const history = useHistory()
    let {isExpired=false} = props
    if(history?.location?.state?.isExpired !== undefined) isExpired = history.location.state.isExpired
    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value)
        // setSessionData(data.value);
    }

    const onSubmit = async (values, errors) => {
        if (!validateValues(values, errors))
            return false;
        const licenseValue = LicenseActions(app());
        const [error, data] = await licenseValue.UpdateLicenseKeyValue(values)
        // getDependencies()
        if(!error){
            if(isExpired) {
                // sessionStorage.setItem('licenseStatus', "Valid")
                // history.push("/home")
                isExpired=false
                forceLogout()
            }
        }   
        // return true;
    };

    const app = useAppContext();

    const getDependencies = () => {
        const licenseValue = LicenseActions(app());

        const getLicenseKeyValue = async () => {
            let [err, data] = await licenseValue.GetLicenseKeyValue();
            if (!err && !data.error) {
                setLicenseData({
                    licenseKey: data.detail.licenseKey
                })
            }
        }
        getLicenseKeyValue()
    }


    // useEffect(getDependencies, []);

    const handleCancel = () => {
        setLicenseData({
            licenseKey: "",
        })
    }

    useEffect(()=>{
        const {notify} = app()
        if(isExpired){notify({message:sessionStorage.getItem("licenseStatus"), type:"E"})}
    },[])

    const { Form, Input, Error } = useForm(licenseData, onSubmit, onWatch);


    return (
        <Form>
            <div className={styles.main}>
                <div className={styles.heading}>
                    <h1 data-text-testid="title" className={styles.title}>License Renewal</h1>
                    {/* <p data-text-testid="subTitle" className={styles.subtitle}>License Renewal subtitle</p> */}
                </div>
                <div className={styles.rolesLayoutMainContainer}>
                    <div className={styles.sessionLayout}>
                        <h2 data-text-testid="subTitle" className={styles.rolesLyoutTitle}>
                            License Renewal
                        </h2>
                        <div className={styles.container}>
                            {/* <div className={styles.textWrap}> */}
                                {/* <p className={styles.passwordpolicyDesc}>License Key</p> */}
                                <Input data-input-testid="License-Key" type="text" pattern=".*\S+.*" name="licenseKey" id="licenseKey" placeholder="Enter License Key" className={styles.input} /*maxLength={9}*//>
                                {/* <p className={styles.passwordpolicyDesc}>mins</p> */}
                            {/* </div> */}
                            <Error name={'licenseKey'} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop: "16px" }}>
                            <Input data-clickable-testid="Cancel" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Cancel"} onClick={handleCancel} />
                            { props?.perm?(location.state.permission?.canUpdate
                                ?<Input data-clickable-testid="Save" type="submit" value={"Update"} />
                                :<></>)
                                :<Input data-clickable-testid="Save" type="submit" value={"Update"} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}


export default LicenseRenewal
