import { bottomBarDefaultSchema, collapsibleDefaultSchema, dataGridDefultSchema, gridDefaultSchema, listViewDefaultSchema, navBarDefaultSchema, panelDefaultSchema, stepperDefaultSchema, tableDefaultSchema, tabsDefaultSchema, dialogDefaultSchema,contextMenuDefaultSchema } from "./schema";

const collapsible = {
  componentSection: ['ID','ExposeAsVariable','Classes', 'Source'],
  interactionSection: collapsibleDefaultSchema({}).events,
  dataSource: [{name:'Data',isArray:true}],
  gridSection: [],
  addDropZoneSection: ['Section'],
  textSection: [],
  styleSection: [],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const grid = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'Source'],
  gridSection: ['Rows', 'Columns', 'Width', 'Gutter'],
  interactionSection: gridDefaultSchema({}).events,
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  dataSource: [{name:'Data',isArray:true}],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const listView = {
  componentSection: ['ID', 'ExposeAsVariable','Classes','Source', 'Style'],
  interactionSection: listViewDefaultSchema({}).events,
  dataSource: [{name:'Data',isArray:true}],
  gridSection: [],
  textSection: [],
  addDropZoneSection: ['Item'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const navBar = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: navBarDefaultSchema({}).events,
  dataSource: [{name:'Data',isArray:true}],
  gridSection: [],
  addDropZoneSection: ['Item'],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const panel = {
  componentSection: ['ID', 'ExposeAsVariable','Classes', 'IconPosition', 'Enabled'],
  interactionSection: panelDefaultSchema({}).events,
  dataSource: [],
  gridSection: [],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const tab = {
  componentSection: ['ID','ExposeAsVariable','Classes', 'Source', 'Tabs'],
  interactionSection: tabsDefaultSchema({}).events,
  dataSource: [{name:'Data',isArray:true}],
  gridSection: [],
  addDropZoneSection: ['Item'],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const tabBar = {
  componentSection: ['ID','ExposeAsVariable','Classes'],
  interactionSection: bottomBarDefaultSchema({}).events,
  dataSource: [{name:'Data',isArray:true}],
  gridSection: [],
  addDropZoneSection: ['Item'],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const table = {
  componentSection: ['ID','ExposeAsVariable','Classes', 'Source'],
  interactionSection: tableDefaultSchema({}).events,
  dataSource: [{name:'Data',isArray:true}],
  gridSection: [],
  addDropZoneSection: ['Column'],
  textSection: [],
  styleSection: [],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const datagrid = {
  componentSection: ['ID', 'ExposeAsVariable', 'Classes', 'Selection', 'Columns', 'Source'], 
  dataSource: [{ name: 'Data', isArray: true }, { name: 'Selected' }],
  interactionSection: dataGridDefultSchema({}).events,
  gridSection: [],
  textSection: [],
  styleSection: [],
  layoutSection: ['Layout', 'Width', 'Height'],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  pagenationSection: ['RowsPerPage'], // ,'CurrentIndex'
  tooltipSection: ["event", "position", "title"],
};

const stepper = {
  componentSection: ['ID', 'Classes', 'labelPosition', 'stepperType'],
  interactionSection: stepperDefaultSchema({}).events,
  dataSource: [ { name: 'Selected' }],
  gridSection: [],
  addDropZoneSection: ['Item'],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]

};
const dialig = {
  componentSection: ['ID', 'showHide'],
  interactionSection: dialogDefaultSchema({}).events,
  dataSource: [],
  gridSection: [],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]
};

const contextMenu = {
  componentSection: ['ID', 'showHide'],
  interactionSection: contextMenuDefaultSchema({}).events,
  dataSource: [],
  gridSection: [],
  addDropZoneSection: ['Item'],
  textSection: [],
  styleSection: ['Background', 'Opacity', 'Custom CSS'],
  layoutSection: ['Layout', 'Width', 'Height'],
  tooltipSection: ["event", "position", "title"]

};

export { table, tabBar, tab, panel, navBar, listView, grid, collapsible, stepper,datagrid,dialig,contextMenu };
