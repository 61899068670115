import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Box, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.color.white,
    paddingBottom: theme.spacing(6) + 'px',
    paddingTop: theme.spacing(6) + 'px',
  },
  styledRoot: {
    backgroundColor: 'transparent',
    paddingBottom: theme.spacing(2) + 'px',
    paddingTop: (theme.spacer * 10) + 'px',
  },
  bosHead: {
    paddingBottom: theme.spacing(6) + 'px',
    paddingTop: theme.spacing(6) + 'px',
  },
  subtitle: {
    color: theme.color.gray60,
    whiteSpace: 'pre-wrap',
  },
  container: {
    display: 'flex',
    padding: '0 !important',
  },
  bosTitle: {
    marginLeft: '60px',
  },
}));
export default function (props) {
  const { bgTransparent = false } = props;
  let classes = useStyles();
  return (
    <Box variant='div' className={bgTransparent ? classes.styledRoot : classes.root}>
      <Container>
        <Grid item xs={12} className={classes.container}>
          <Grid item xs={props.left}>
            <Typography variant={ !bgTransparent ? 'h1' : 'h3'}>
              {props.title}
            </Typography>
            <Typography variant='subtitle2' className={classes.subtitle}>
              {props.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={props.right}>
            <Grid
              container
              direction='row'
              justify='flex-end'
              alignItems='center'
            >
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
