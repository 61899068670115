import { getUrl, get, post,getLikeValue } from "../../url";

export async function loginsList(name){
    let path = `${getUrl()}/library/v2.0/logins/list`;
    let select = `$select=name,projectName,snapshot`;
    if(name){
        select +=`&$filter=name like '${getLikeValue(name)}'`
    }

    const [error, data] = await get(`${path}?${select}`);
    return [error,data];
}

export async function createLogin(payload = {}) {
    let path = `${getUrl()}/library/v1.0/logins`;

    const [error, data] = await post(path, payload);
    return [error, data];
}
