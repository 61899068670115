import PopUp from "../../../components/layouts/PopUp";
import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/table";
import "./index.css";
import { useAppContext } from "../../../components/activity/AppContext";
import { AssetActions } from "./asset.actions";
import styles from "./assets.module.css";

const CheckBox = (props) => {
  const { value, name, setProjects, projects } = props;

  const handleChange = (e) => {
    let selectedProjects = projects;
    const isSelected = e.target.checked;
    const value = e.target.value;
    if (isSelected) {
      if (!selectedProjects.includes(value)) selectedProjects.push(value)
    } else if (selectedProjects.includes(value)) {
      selectedProjects.splice(selectedProjects.indexOf(value), 1)
    }
    // selectedProjects.pop(value);
    setProjects(selectedProjects);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        data-option-testid={'isAdded'}
        type="checkbox"
        style={{ cursor: "pointer"  , width: "auto"}}
        value={name}
        key={name}
        name={name}
        id={name}
        defaultChecked={value}
        onChange={handleChange}
      />
      <label data-text-testid={"use"} htmlFor={name} style={{ paddingLeft: "3px" }}>
        {" Use"}
      </label>
    </div>
  );
};

function ApplytoProject(props) {
  const { applyPopUp, handleCancel } = props;
  const [projectData, setProjectData] = useState([]);
  const [filteredProject, setFilteredProject] = useState([]);
  const [filter, setFilter] = useState({ name: "", isAdded: "" });
  const app = useAppContext();
  const [projects, setProjects] = useState(
    applyPopUp.asset.projects?.split(",") || []
  );

  const getProjectList = async () => {
    const actions = AssetActions(app());
    const [error, data] = await actions.getProjects();
    if (!error) {
      setProjectData(data.data.map(ele => ({ name: ele.name })))
      mapProjects(data.data);
    }
  };

  useEffect(() => {
    getProjectList();
  }, []);

  const addCheckbox = (projectName) => {
    return ({
      name: projectName,
      isAdded: (
        <CheckBox
          value={projects.includes(projectName)}
          projects={projects}
          setProjects={setProjects}
          name={projectName}
        />
      )
    })
  }

  const mapProjects = (data = []) => {
    let projectListData = [];
    for (let project of projectData) {
      if (filter.isAdded === "Yes") {
        if (projects.includes(project.name) && project.name.toLowerCase().includes(filter.name.toLowerCase())) {
          projectListData.push(
            addCheckbox(project.name)
          )
        } else if (projects.includes(project.name) && filter.name === "") {
          projectListData.push(
            addCheckbox(project.name)
          )
        }
      } else if (filter.isAdded === "No") {
        if (!projects.includes(project.name) && project.name.toLowerCase().includes(filter.name.toLowerCase())) {
          projectListData.push(
            addCheckbox(project.name)
          )
        } else if (!projects.includes(project.name) && filter.name === "") {
          projectListData.push(
            addCheckbox(project.name)
          )
        }

      } else {
        if (project.name.toLowerCase().includes(filter.name.toLowerCase())) {
          projectListData.push(
            addCheckbox(project.name)
          )
        } else if(filter.name === "") {
          projectListData.push(
            addCheckbox(project.name)
          )
        }
      }
    }
    setFilteredProject(projectListData);
  }

  useEffect(() => {
    mapProjects();
  }, [projectData, projects, filter])

  const onHeaderChange = (id, value) => {
    let filteredData = [];
    let filterObj = filter;

    filterObj = { ...filterObj, [id]: value };
    setFilter(filterObj);
  };

  const headerColumns = [
    { id: "name", name: "Project", type: "input", width: "210px" },
    {
      id: "isAdded",
      name: "use",
      type: "select",
      value: ["Yes", "No"],
      width: "100px",
    },
  ];

  const handleSubmit = async () => {
    const actions = AssetActions(app());
    const [error, data] = await actions.share(applyPopUp.asset.name, {
      projects: projects,
    });
    if (!error) {
      handleCancel();
    }
  };

  return (
    <div>
      <PopUp
        popUp={applyPopUp.show}
        setPopUp={() => handleCancel()}
        title="Apply to Project"
        testId="apply-to-project"
      >
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <Table
              data={filteredProject}
              headerColumns={headerColumns}
              width="100%"
              name={"project-list"}
              handleHeaderInputChange={onHeaderChange}
              handleHeaderSelectChange={onHeaderChange}
            />
          </div>
        </div>
        <div className={styles.submitContainer}>
          <input
            className="secondary"
            type="button"
            onClick={handleCancel}
            data-clickable-testid="cancel"
            value="Cancel"
          />
          <input
            className="primary"
            type="submit"
            onClick={handleSubmit}
            data-clickable-testid="save"
            value="Save"
          />
        </div>
      </PopUp>
    </div>
  );
}
export default ApplytoProject;
