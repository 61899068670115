const newBaasData={
    "uuid": "",
    "name": "",
    "baasEnable": true,
    "type": 5001,
    "ui": {
        "parameters": {
            "Input": {},
            "Output": {},
            "Local": {},
            "Environment": {
                "HOSTUSER": {
                    "Xpath": "BOS$Environment$HOSTUSER",
                    "Expression": "",
                    "dataType": "String",
                    "Description": "HOSTUSER",
                    "name": "HOSTUSER",
                    "type": "String"
                },
                "APPNAME": {
                    "Xpath": "BOS$Environment$APPNAME",
                    "Expression": "",
                    "dataType": "String",
                    "Description": "APPNAME",
                    "name": "APPNAME",
                    "type": "String"
                },
                "LOGINID": {
                    "Xpath": "BOS$Environment$LOGINID",
                    "Expression": "",
                    "dataType": "String",
                    "Description": "LOGINID",
                    "name": "LOGINID",
                    "type": "String"
                },
                "SYNCDATETIME": {
                    "Xpath": "BOS$Environment$SYNCDATETIME",
                    "Expression": "",
                    "dataType": "String",
                    "Description": "SYNCDATETIME",
                    "name": "SYNCDATETIME",
                    "type": "String"
                },
                "RESPONSE": {
                    "Xpath": "BOS$Environment$RESPONSE",
                    "Expression": "",
                    "dataType": "Table",
                    "Description": "RESPONSE",
                    "name": "RESPONSE",
                    "type": "Table",
                    "field": [
                        {
                            "name": "Success",
                            "default": "Success",
                            "Xpath": "BOS$Environment.$RESPONSE$Success",
                            "key": "BOS$Environment.$RESPONSE$Success",
                            "dataType": "String"
                        },
                        {
                            "name": "MsgType",
                            "default": "MsgType",
                            "Xpath": "BOS$Environment.$RESPONSE$MsgType",
                            "key": "BOS$Environment.$RESPONSE$MsgType",
                            "dataType": "String"
                        },
                        {
                            "name": "Message",
                            "default": "Message",
                            "Xpath": "BOS$Environment.$RESPONSE$Message",
                            "key": "BOS$Environment.$RESPONSE$Message",
                            "dataType": "String"
                        }
                    ]
                }
            },
            "metadata": "",
            "system": [],
            "plugin": [],
            "tables": []
        },
        "ui": []
    },
    "object": {
        "input": {
            "field": [],
            "table": []
        },
        "output": {
            "field": [],
            "table": []
        },
        "local": {
            "field": [],
            "table": []
        },
        "env": {
            "field": [
                {
                    "name": "HOSTUSER",
                    "key": "BOS$Environment$HOSTUSER",
                    "Expression": "",
                    "Description": "HOSTUSER",
                    "dataType": "String"
                },
                {
                    "name": "APPNAME",
                    "key": "BOS$Environment$APPNAME",
                    "Expression": "",
                    "Description": "APPNAME",
                    "dataType": "String"
                },
                {
                    "name": "LOGINID",
                    "key": "BOS$Environment$LOGINID",
                    "Expression": "",
                    "Description": "LOGINID",
                    "dataType": "String"
                },
                {
                    "name": "SYNCDATETIME",
                    "key": "BOS$Environment$SYNCDATETIME",
                    "Expression": "",
                    "Description": "SYNCDATETIME",
                    "dataType": "String"
                }
            ],
            "table": [
                {
                    "type": "Table",
                    "name": "RESPONSE",
                    "Xpath": "BOS$Environment$RESPONSE",
                    "Expression": "",
                    "Description": "RESPONSE",
                    "field": [
                        {
                            "name": "Success",
                            "default": "Success",
                            "Xpath": "BOS$Environment.$RESPONSE$Success",
                            "key": "BOS$Environment.$RESPONSE$Success",
                            "dataType": "String"
                        },
                        {
                            "name": "MsgType",
                            "default": "MsgType",
                            "Xpath": "BOS$Environment.$RESPONSE$MsgType",
                            "key": "BOS$Environment.$RESPONSE$MsgType",
                            "dataType": "String"
                        },
                        {
                            "name": "Message",
                            "default": "Message",
                            "Xpath": "BOS$Environment.$RESPONSE$Message",
                            "key": "BOS$Environment.$RESPONSE$Message",
                            "dataType": "String"
                        }
                    ]
                }
            ]
        },
        "sp": []
    }
}

export default newBaasData;