import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getUrl } from '../../../../api/url';
import { useAppContext } from '../../../../components/activity/AppContext';
import PopUp from "../../../../components/layouts/PopUp";
import MetadataOption from './metadataOption';
import { ServiceActions } from '../services.actions';
import styles from "../services.module.css";

export default function MetadataPopup(props) {
    const { setMetadata, metadata } = props;
    const [metaDataList, setMetaDataList] = useState({ uri: "", input: "", output: "", system: "" });
    const [openOption, setOpenOption] = useState({ uri: false, input: false, output: false, system: false })
    const [outputData, setOutputData] = useState([])
    const [inputData, setInputData] = useState([])
    const [systemData, setSystemData] = useState([])
    const app = useAppContext();

    const getMetadataVariables = () => {
        let outPut = [];
        let input = [];
        let system = [];
        for (let data of Object.values(metaDataList.output)) {
            const name = data.name;
            const type = data.dataType ? data.dataType : data.type;
            outPut.push(<MetadataOption key={name} data={{ name, type, showContent: false }} />)
        }
        setOutputData(outPut);

        for (let data of Object.values(metaDataList.input)) {
            const name = data.name;
            const type = data.dataType ? data.dataType : data.type;
            input.push(<MetadataOption key={name} data={{ name, type, showContent: false }} />)
        }
        setInputData(input);

        if (metaDataList.system !== "") {
            system = metaDataList.system.map((ele) => (
                <div className={styles.systemOption}>
                    <span>{ele}</span>
                </div>
            ))
            setSystemData(system);
        }
    }

    useEffect(getMetadataVariables, [metaDataList])

    useEffect(() => {
        const getMetadata = async () => {
            const actions = ServiceActions(app());
            const [error, data] = await actions.getServiceMetadata(metadata.item);
            if (!error) {
                const metaDataObj = JSON.parse(data.object);
                setMetaDataList(
                    {
                        uri: `${getUrl()}/baas/v1.0/baasexecute/`,
                        input: metaDataObj.Input,
                        output: metaDataObj.Output,
                        system: metaDataObj.system
                    }
                );
            }
        }
        getMetadata();
    }, [])

    const expandHandler = (option) => {
        switch (option) {
            case "uri":
                setOpenOption((val) => ({ ...val, uri: !val.uri }))
                break;
            case "input":
                setOpenOption((val) => ({ ...val, input: !val.input }))
                break;
            case "output":
                setOpenOption((val) => ({ ...val, output: !val.output }))
                break;
            case "system":
                setOpenOption((val) => ({ ...val, system: !val.system }))
                break;
            default:
        }
    }

    const handleClose = () => {
        setMetadata({ show: false, item: "" })
    }

    return (
        <PopUp popUp={metadata.show} setPopUp={handleClose} title="Metadata">
            <div className={styles.metaCont}>
                <div className={styles.formCont}>
                    <div className={styles.metaOptionCont}>
                        <div className={styles.metaOption}>
                            <div className={styles.option} onClick={() => expandHandler("uri")}>
                                <span>Execute Uri</span>
                                {!openOption.uri ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                            </div>
                            {openOption.uri ? <div className={styles.metaContent}>
                                <span>{`${metaDataList.uri}${metadata.item.serviceId}`}</span>
                            </div> : ""}
                        </div>
                        <div className={styles.metaOption}>
                            <div className={styles.option} onClick={() => expandHandler("input")}>
                                <span>Input Variable</span>
                                {!openOption.input ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                            </div>
                            {openOption.input ? <div className={styles.metaContent}>
                                {inputData}
                            </div> : ""}
                        </div>
                        <div className={styles.metaOption}>
                            <div className={styles.option} onClick={() => expandHandler("output")}>
                                <span>Output Variable</span>
                                {!openOption.output ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                            </div>
                            {openOption.output ? <div className={styles.metaContent}>
                                {outputData}
                            </div> : ""}
                        </div>
                        <div className={styles.metaOption}>
                            <div className={styles.option} onClick={() => expandHandler("system")}>
                                <span>System</span>
                                {!openOption.system ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                            </div>
                            {openOption.system ? <div className={styles.metaContent}>
                                {systemData}
                            </div> : ""}
                        </div>
                    </div>
                </div>
                <div className={styles.buttonCont}>
                    <input type="button" className="secondary" value="Close" data-clickable-testid="Close" onClick={handleClose} />
                </div>
            </div>
        </PopUp>
    );
}
