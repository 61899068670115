import React ,{ useState } from "react";
import { useForm } from "../../../components/forms/Form";
import styles from "./generateTest.module.css";

function Test({parameter,onTest, closePopup}){
    const close = '/images/variables/close.png';
    const play = '/images/header/nav_play.svg';

    const [input, setInput] = useState({});

    const onWatch = (errors, data) => {
        // variable[data.name] = data.value;
        // console.log('------------ The Input Data : ', data);
        parameter.Input[data.name] = data.value;
    }

    const onSubmit = async (values, errors) => {
        // console.log('------------ The Input Data : ', values);
        let inputObj = {};
        let systemObj = {};
        let isDebug = false;
        Object.keys(parameter.Input).map((key) => {
            inputObj[key] = values[key] ? values[key] : '';
        });
        parameter.system.map((key) => {
            systemObj[key] = values[`_sys.${key}`] ? values[`_sys.${key}`] : '';
        });
        isDebug = values['_isDebug'] ? values['_isDebug'] : false;
        onTest(inputObj, isDebug, systemObj);
    };

    const { Form, Label, Input} = useForm(input, onSubmit, onWatch);

    return(
        <div className={styles.testPanel} >
            <div>
                <Form>
                    <div className={styles.testPanelBar} >
                        <span className={styles.testPanelTitle} >Properties</span>
                        <div onClick={()=>closePopup()}>
                            <img src={close} className={styles.testPanelCloseIcon}/>
                        </div>
                    </div>
                    <div>
                        <ul className={styles.testul}>
                            {Object.keys(parameter.Input).length > 0 && 
                                Object.keys(parameter.Input).map((key) => {
                                    return (
                                        <li className={styles.testli}>
                                            <div className={styles.testInputdiv}>
                                                <span className={styles.testInputSpan} title={parameter.Input[key].name} >{parameter.Input[key].name}</span>
                                                {(parameter.Input[key].dataType == "String" || parameter.Input[key].type == "Table") && 
                                                    <Input type='text' style={{ width: "100%" }} id="variable" name={parameter.Input[key].name}  />
                                                }
                                                {(parameter.Input[key].dataType == "Number" || parameter.Input[key].dataType == "Decimal") && 
                                                    <Input type='number' style={{ width: "100%" }} id="variable" name={parameter.Input[key].name} autoComplete="off" />
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    {parameter.system.length > 0 &&
                        <div className={styles.testPanelBar} >
                            <span className={styles.testPanelTitle}>System</span>
                        </div>
                    }
                    <div>
                        <ul className={styles.testul}>
                            {parameter.system.length > 0 &&
                                parameter.system.map((key) => {
                                    return (
                                        <li className={styles.testli}>
                                            <div className={styles.testInputdiv}>
                                                <span className={styles.testInputSpan} title={key} >{key}</span>
                                                <Input type='text' style={{ width: "100%" }} id="system" name={`_sys.${key}`}  />
                                            </div>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                    </div>
                    <div className={styles.testPanelFlex}>
                        <div className={styles.testPanelFlex}>
                            <span className={styles.testInputSpan}>Debug?</span>
                            <Input type="checkbox" name="_isDebug" defaultValue={parameter.isDebug} />
                        </div>
                        <div className={styles.testbuttondiv}>
                            <button className={`primary ${styles.testPanelFlex}`} > 
                                <img src={play} className={styles.testPanelPlayIcon}/>
                                <span className={styles.testInputSpan}>Test</span>
                            </button>
                        </div>
                    </div>
                </Form>
                <div className={styles.testPanelBar} >
                    <span className={styles.testPanelTitle}>Output</span>
                </div>
                <div >
                    <textarea disabled={true} className={styles.testPanelTextArea} value={parameter.debugTest}/>
                </div>
            </div>
            {/* <div>

            </div> */}
        </div>
    )

}


export default Test