import { forceLogout } from "../../../common";
import { getUrl, get, put, post } from "../../url";

// const keys = {name:"b.name",version:"b.version",bassEnable:"baasEnable"};


export async function fetchBaasApi() {
    const uri = getUrl() + `/baas/v1.0/baas/getApiKey`
    const [error, data] = await get(uri);
    return [error, data];
}

export async function generateBaasApi() {
    const uri = getUrl() + `/baas/v1.0/baas/generateApiKey`
    const [error, data] = await put(uri);
    return [error, data];
}

export async function fetchServices(limit, offset, service) {
    const filter = getFilter(service);
    const uri = getUrl() + `/baas/v2.0/baas/list?$select=id,name,baasEnable,companyId,serviceId,projectId,projectName,version,lastPublishedDateTime`
        + `&$sort=asc name&limit=${limit}&offset=${offset}${filter}`;
    const [error, data] = await get(uri);
    return [error, data];
}

const getFilter = (service) => {
    let filter = "&$filter=";

    let obj = [];
    Object.keys(service).map(function (key, index) {
        if (service[key]) {
            if (key === "baasEnable") {
                let isEnabled = service[key] === 'Enabled' ? 1 : 0;
                obj.push(key + ' EQ ' + isEnabled);
            } else
                obj.push(key + ' LIKE ' + encodeFilter(service[key]));
        }

    });
    obj.length > 0 ? filter += obj.join(" AND ") : filter = "";
    return filter;
}

const encodeFilter = (input) => {
    return encodeURIComponent("'%" + input + "%'");
}

export async function createNewBaas(payload = {}) {
    let path = `${getUrl()}/configurator/v1.0/file`;

    const [error, data] = await post(path, payload);
    return [error, data];
}

export async function updateBaas(payload = {}) {
    let path = `${getUrl()}/configurator/v1.0/file/bosUpdate?project_name=services_workspace&$bos_name=${payload.name}&$bos_uuid=${payload.uuid}`;

    const [error, data] = await put(path);
    return [error, data];
}

export async function updateBaasEnable(baasData, payload) {
    let path = `${getUrl()}/baas/v1.0/baas/${baasData.serviceId}/enable?project_name=${baasData.projectName}`;

    const [error, data] = await put(path, payload);
    return [error, data];
}

export async function getMetaData(baasData) {
    let path = `${getUrl()}/configurator/v1.0/bos/list?project_name=${baasData.projectName}&$bos_name=${baasData.name}&$bos_uuid=${baasData.serviceId}`

    const [error, data] = await get(path);
    return [error, data];
}

export async function getServiceDetail(uuid, limit, service) {
    let path = `${getUrl()}/baas/v1.0/baas/listVersions?$bos_uuid=${uuid}&$select=id,createdTimestamp,version,status,userName,comment&$sort=desc%20id`
    if (limit) {
        path += `&$filter=&limit=1&offset=0`;
    }
    if (service) {
        path += `${getFilter(service)}`
    }

    const [error, data] = await get(path);
    return [error, data];
}

export async function publishService(uuid, payLoad) {
    let path = `${getUrl()}/baas/v1.0/baas/${uuid}/publish?project_name=${payLoad.project_name}&version=${payLoad.version}&comment=${payLoad.comment}`;

    const [error, data] = await put(path);
    return [error, data];
}

export async function publishStatus(reqId) {
    let path = `${getUrl()}/baas/v1.0/baas/${reqId}/publishStatus`;

    const [error, data] = await get(path);
    return [error, data];
}

export async function getTransportMetadata(sendData) {
    let path = `${getUrl()}/baas/v1.0/baas/metadata?project_name=${sendData.projectName}&version=${sendData.version}&$bos_uuid=${sendData.uuid}`;

    const [error, data] = await get(path);
    return [error, data];
}

export async function putTransportService(sendData) {
    let path = `${getUrl()}/baas/v1.0/baas/${sendData.uuid}/transport?project_name=${sendData.projectName}&version=${sendData.version}&comment=${sendData.comment}`;

    const [error, data] = await put(path);
    return [error, data];
}

export async function transportStatus(reqId, project) {
    let path = `${getUrl()}/businessfunction/v1.0/function/${reqId}/publishStatus?project_name=${project}`

    const [error, data] = await get(path);
    return [error, data];
}

export async function bosFunction(uuid, project) {
    let path = `${getUrl()}/businessfunction/v1.0/function/${uuid}?project_name=${project}`

    const [error, data] = await get(path);
    return [error, data];
}

export async function revertBack(uuid, project, version) {
    let path = `${getUrl()}/baas/v1.0/baas/${uuid}/revertBack?project_name=${project}&version=${version}`;

    const [error, data] = await put(path);
    return [error, data];
}