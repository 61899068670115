import React, { useEffect, useState, useRef } from 'react'
import styles from './sessionSettings.module.css'
import { useForm } from "../../../components/forms/Form";
import { validateValue, validateValues } from './sessionSettingsValidation';
import SessionActions from './sessionSettings.actions';
import { useAppContext } from "../../../components/activity/AppContext";
import { useLocation } from 'react-router-dom';


function SessionSettings({perm}) {
    const location = useLocation();
    location.state = {permission:perm}
    const [sessionData, setSessionData] = useState({
        tokenValidity: "",
        autoApprove: "",
        companyId: "",
        dataValidity: "",
        hasS3Support: "",
        isActive: "",
    })
    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value)
        // setSessionData(data.value);
    }

    const onSubmit = async (values, errors) => {
        if (!validateValues(values, errors))
            return false;
        const sessionValue = SessionActions(app());
        const [error] = await sessionValue.UpdateSessionSettingValue(values)
        getDependencies()
        // updateSessionSetting(values).then(resp => {
        //     if (resp.status.type === "S") {
        //         alert("Session validity updated successfully")
        //     } else {
        //         alert("An error occured, please try again")
        //     }
        // })
        // sessionSettingsUpdate()
        return true;
    };

    const app = useAppContext();

    const getDependencies = () => {
        const sessionValue = SessionActions(app());

        const getSessionSettingValue = async () => {
            let [err, data] = await sessionValue.GetSessionSettingValue();
            if (!err && !data?.error) {
                setSessionData({
                    tokenValidity: data?.detail.tokenValidity,
                    autoApprove: data?.detail.autoApprove,
                    companyId: data?.detail.companyId,
                    dataValidity: data?.detail.dataValidity,
                    hasS3Support: data?.detail.hasS3Support,
                    isActive: data?.detail.isActive,
                })
            }
        }
        getSessionSettingValue()
    }


    useEffect(getDependencies, []);



    const { Form, Input, Error } = useForm(sessionData, onSubmit, onWatch);


    return (
        <Form>
            <div className={styles.main}>
                <div className={styles.heading}>
                    <h1 data-text-testid="title" className={styles.title}>Session Settings</h1>
                </div>
                <div className={styles.rolesLayoutMainContainer}>
                    <div className={styles.sessionLayout}>
                        <h2 data-text-testid="subTitle" className={styles.rolesLyoutTitle}>
                            Session Settings
                        </h2>
                        <div className={styles.container}>
                            <div className={styles.textWrap}>
                                <p className={styles.passwordpolicyDesc}>Session validity
                                </p>
                                <Input data-input-testid="Session-validity" type="text" pattern="\d*" name="tokenValidity" id="tokenValidity" placeholder="240" className={styles.input} maxLength={9}/>
                                <p className={styles.passwordpolicyDesc}>mins</p>
                            </div>
                            <Error name={'sessionvalidity'} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px", marginTop: "16px" }}>
                            <Input data-clickable-testid="Cancel" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Cancel"} onClick={getDependencies} />
                            {
                                location.state.permission?.canUpdate
                                ?<Input data-clickable-testid="Save" type="submit" value={"Save"} />
                                :<></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}


export default SessionSettings
