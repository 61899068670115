import React from "react";
import CustomScrollbar from "../../components/customScrollbar/customScrollbar";
import styles from "./database.module.css";

export function ColumnMapping(props) {
    const { 
        tableColumns = [],
        selectedColumns = {},
        importedColumns = [],
        onChange = () => null
    } = props;

    const [ headers, ...columnValues ] = importedColumns;

    const dBColumns = (header, index) => {
        return (
            <div 
                className={styles.mappingData}
                style = {{ opacity: selectedColumns[header] === false ? 0.6 : 1 }}
            >
                <div className={`${styles.tableColumns} form`}>
                    <select data-option-testid={"column_"+index}
                        style={{width: "calc((var(--spacer) * 28px) / 1.49)"}}
                        value={selectedColumns[header] || ""}
                        onChange={(e) => onChange(header, e.target.value)}
                    >
                        <option data-option-testid={"Select"} value={""}>Select</option>
                        {tableColumns.map((item) => <option data-option-testid={item} value={item}>{item}</option>)}
                    </select>
                    <button
                        className={"secondary"}
                        onClick={() => onChange(header, false)}
                        disabled={selectedColumns[header] === false}
                        data-clickable-testid={'skip_'+index}
                    >
                        SKIP
                    </button>
                </div>
                <div className={styles.dbColumnValues}>
                    <div className={`${styles.dbColumnHeader} ${selectedColumns[header]? styles.dbColumnHeaderShade : ""}`} data-text-testid={header}>
                        {header}
                    </div>
                    {
                        columnValues &&
                        columnValues.map((item) => {
                            return (
                                <div className={styles.dbColumnValue} data-text-testid={item?.[index]}>
                                    {item?.[index] || ""}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <CustomScrollbar>
        <div className={styles.mappingColumnsData}>
            {
                headers.map((e, i) => {
                    return dBColumns(e,i);
                })
            }
        </div>
        </CustomScrollbar>
    );
}