import React from "react";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../components/activity/AppContext";
import ProgressBar from "./progressBar";
import { ServiceActions } from "../services.actions";
import styles from "../services.module.css";

export default function PublishServiceStatus(props) {
    const { handleClose, serviceData, requestId } = props;
    const popupTitle = { title: "Publishing..." }
    const app = useAppContext();
    const [progress, setProgress] = useState(0);
    const [checkColor] = useState({ success: { color: "#4f8a10" }, fail: { color: "#ff0000" } })
    const [status, setStatus] = useState("");
    const [failData, setFailData] = useState("");

    const checkPublishStatus = async (requestId) => {
        const actions = ServiceActions(app());
        const [error, data] = await actions.getPublishStatus(requestId.requestId);

        if (data) {
            if (data.detail.status === "finish") {
                setProgress(50);
                getServiceInfo(data);
                setStatus("finish")
            } else if (data.detail.status === "failed") {
                setStatus("failed");
                getServiceInfo(data);
            } else {
                setProgress(25);
                checkPublishStatus(requestId);
            }
        }
    }

    const getServiceInfo = async (data) => {
        const file = data.detail.fileAddress;
        const res = await fetch(file);
        const resTxt = await res.text();

        if (res) {
            if (res.status == 200) {
                if (data.detail.status === "failed") {
                    setFailData(resTxt);
                    setProgress(100);
                } else {
                    setProgress(75);
                    genrateCode();
                }
            }
        }
    }

    const genrateCode = async () => {
        const actions = ServiceActions(app());
        const uuid = serviceData.serviceId;
        const [error, data] = await actions.getSerivceData(uuid);

        if (!error) {
            setProgress(100);
        }
    }

    useEffect(() => {
        checkPublishStatus(requestId)
    }, [])

    const handleDone = () => {
        handleClose();
    }

    return (
        <div className={styles.statusCont}>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{popupTitle.title}</h2>
            </div>
            <div className={styles.progressCont}>
                <ProgressBar progress={progress} statusColor={status === "failed" ? checkColor.fail : { color: "#24A148" }} />
                <h5 className={styles.statusMsg}>{(progress === 100 && status !== "failed") ? "Published Successfully" : ""}</h5>
                <h5 className={`${styles.statusMsg} ${styles.errorMsg}`}>{(progress === 100 && status === "failed") ? "Publishing Failed" : ""}</h5>
            </div>
            <div className={styles.ststusTextCont}>
                {status !== "failed" ?
                    <div className={styles.column}>
                        <div className={styles.statusText}>
                            {progress >= 25 ? <CheckCircleOutlineRounded style={checkColor.success} /> : <CheckCircleOutlineRounded />}
                            <span>Initializing the publish</span>
                        </div>
                        <div className={styles.statusText}>
                            {progress >= 50 ? <CheckCircleOutlineRounded style={checkColor.success} /> : <CheckCircleOutlineRounded />}
                            <span>Gathering service informations</span>
                        </div>
                        <div className={styles.statusText}>
                            {progress >= 75 ? <CheckCircleOutlineRounded style={checkColor.success} /> : <CheckCircleOutlineRounded />}
                            <span>Generating the Code</span>
                        </div>
                        <div className={styles.statusText}>
                            {progress >= 100 ? <CheckCircleOutlineRounded style={checkColor.success} /> : <CheckCircleOutlineRounded />}
                            <span>Done</span>
                        </div>
                    </div> :
                    <div className={styles.feedback}>
                        <span>{failData}</span>
                    </div>
                }
            </div>

            <div className={styles.buttonCont}>
                {progress === 100 ?
                    <input
                        type="button"
                        className="primary"
                        value={"Done"}
                        data-clickable-testid={"Done"}
                        onClick={handleDone}
                    /> :
                    <input
                        type="button"
                        className="tertiary"
                        value={"Done"}
                        data-clickable-testid={"Done"}
                    />}
            </div>
        </div>
    );
}
