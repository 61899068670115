import React from "react";
import styles from "../services.module.css";
import { validateValue, validateValues } from '../serviceValidations';
import { useForm } from '../../../../components/forms/Form';
import { useEffect, useState } from "react";
import { useAppContext } from '../../../../components/activity/AppContext';
import { ServiceActions } from "../services.actions";

export default function PublishServiceForm(props) {
    const { setRenderComponent, handleClose, serviceData, setRequestId } = props;
    const popupTitle = { title: "Additional information for your service", subTitle: "Publishing an service builds a release version of your service" }
    const [formData, setFormData] = useState({ version: "", comment: "" });
    const app = useAppContext();

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = (values, errors) => {
        if (!validateValues(values, errors))
            return false;

        initPublish(values);
    }

    const initPublish = async (values) => {
        const actions = ServiceActions(app());
        const uuid = serviceData.serviceId;
        const payload = { project_name: serviceData.projectName, version: values.version, comment: values.comment }

        const [error, data] = await actions.postPublish(uuid, payload);

        if (!error) {
            const requestId = JSON.parse(data.object);
            setRequestId(requestId);
            setRenderComponent("PublishStatus");
        }
    }

    const getServiceDetail = async () => {
        const actions = ServiceActions(app());
        const uuid = serviceData.serviceId;
        const limit = true;
        const [error, data] = await actions.getSerivceData({ uuid, limit });

        if (!error) {
            const version = data.data[0]?.version ? (`${parseInt(data.data[0].version) + 1}.0`) : (`1.0`);
            setFormData((val) => ({ ...val, version: version }))
        }
    }

    useEffect(() => {
        getServiceDetail();
    }, [])

    const { Form, Label, Input, Error, TextArea } = useForm(formData, onSubmit, onWatch);

    return (
        <div>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{popupTitle.title}</h2>
                <h5 className={styles.subTitle}>{popupTitle.subTitle}</h5>
            </div>
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>
                        <Label text={"Version Number"} htmlFor="version" />
                        <Input type="text" id="version" name="version" disabled={true} style={{width : "auto"}} />
                    </div>
                    <div className={styles.column}>
                        <Label text={"Comments"} htmlFor="comment" />
                        <TextArea type="text" id="comment" name="comment" maxlength={200}/>
                        <Error name={"comment"} />
                    </div>
                </div>

                <div className={styles.buttonCont}>
                    <Input
                        type="button"
                        className="secondary"
                        value={"Cancel"}
                        onClick={handleClose}
                        data-clickable-testid={"Cancel"}
                    />
                    <Input
                        type="submit"
                        className="primary"
                        value={"Publish"}
                        data-clickable-testid={"Publish"}
                    />
                </div>
            </Form>
        </div>
    )
}
