import { ReactComponent as KeyboardRightArrow } from "../../images/keyboardRightArrow.svg";
import { ReactComponent as KeyboardCrossArrow } from "../../images/keyboardCrossArrow.svg";
import React ,{ useEffect, useState } from "react";
import { download,downloadPng } from "../../api/url";
import styles from "./card.module.css";

export function Card(props) {
    const [imageData, setImageData] = useState('');
    const [type,setType] = useState('svg');

    const {
        title = "",
        subTitle = "",
        imageUrl = "",
        hoverList = [],
        isDeleted = false,
        isLoading = false,
        onClick = () => null,
        onListClick = () => null,
    } = props;

    const getAndSetImageData = async () => {
        let result=""
        if(imageUrl.split("?")[0]?.includes(".png")){
            setType("png")
            result=await downloadPng(imageUrl)
        }else{
            result=await download(imageUrl)
        }
        setImageData(result);
    }

    const getHoverList = () => {
        return (
            <div className={styles.afHoverList}>
                <div data-clickable-testid={"open"} className={styles.afHoverOpenRow} onClick={onClick}>
                    {isDeleted ? "Restore" : "Open"}
                    <KeyboardRightArrow />
                </div>
                {
                    !isDeleted &&
                    hoverList.slice(0, 3).map((item) => {
                        return (
                            <div
                                data-clickable-testid={title}
                                className={styles.afHoverRow}
                                onClick={() => onListClick(title, item)}
                            >
                                <KeyboardCrossArrow />
                                <p className={styles.afRowText}>{item}</p>
                                <KeyboardRightArrow />
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    useEffect(() => {
        if (imageUrl) {
            getAndSetImageData();
        }
    }, [])

    return (
        <div className={`${styles.afCard} ${isLoading ? styles.loading : ''}`} key={title} data-clickable-testid={title}>

            <div className={styles.afCardImagePreviewArea}>
                {type==="svg"?
                <span
                    className={styles.afCardImagePreviewArea}
                    dangerouslySetInnerHTML={{ __html: imageData || "" }}>
                </span>
                :<span
                className={styles.afCardImagePreviewArea}>
                    <img src={imageData} />
                </span>
                }
                {getHoverList()}
            </div>
            <div
                data-clickable-testid={title}
                onClick={onClick}
                className={styles.afContentArea}
            >
                <div className={`${styles.afCardTitle} ${isDeleted ? 'afError' : ''}`}>
                    {title}
                </div>
                <div className={styles.afCardSubTitle}>
                    {subTitle}
                </div>
            </div>

        </div>
    )
}