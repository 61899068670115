import { PanoramaSharp } from "@mui/icons-material";
import React, { useState } from "react";
import { useAppContext } from "../../../../../components/activity/AppContext";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import { validateValue, validateValues } from "../../validations";
import { SchedulerActions } from "../../scheduler.action";
import { useScheduler } from "../../schedulerContext";
import styles from "./Select.module.css"
import { getAuth } from "../../../../../auth/user";


export const SelectBos = ({ show, handleClose }) => {

    let style = { marginTop: "8px" };
    let style1 = { height: "18rem", overflow: "auto", width: "12rem" }

    const app = useAppContext();


    const { setTimeBased, setParameter, bospopupEdit, setBospopupEdit, setFinalStep, setParams, params, draft, setDraft, setBospopup,
        timeBasedSchedulerForm, edit, paramObject, setParamObject,
        createScheduler, setCreateScheduler, allProjects, setAllProjects, allBos, setAllBos, save, setSave } = useScheduler();

    const [data, setData] = useState({
        project: params.projectName, bos: params.bosName,
        projectId: params.projectComponentId, serviceId: params.serviceId, runAsUser: params.runAsUser
    })

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value)
    }

    const onSubmit = async (values, errors) => {

        if (!validateValues(values, errors)) {
            return false;
        }
        else {
            if (draft) {
                const payload = {
                    bosname: "",
                    description: timeBasedSchedulerForm.description,
                    eventId: null,
                    freq: null,
                    isEnabled: 2,
                    isTimeBased: true,
                    name: timeBasedSchedulerForm.name,
                    parameters: JSON.stringify(paramObject),
                    projectComponentId: values.projectComponentId,
                    runAsUser: values.runAsUser,
                    serviceName: values.bosName,
                    serviceType: timeBasedSchedulerForm.serviceType
                }

                CreateScheduler(payload)
                handleClose()
                setTimeout(() => setCreateScheduler(!createScheduler), 1000)
                setDraft(false)
               

            }
            else if (edit) {
                handleCancel()
            }
            else if (save) {
                handleClose()
                setBospopup(false)
                setBospopupEdit(false)
                setSave(false)
                setFinalStep(true)
            }
            else {
                const errMsg = await verifyUser(values.runAsUser)
                if (errMsg) {
                    errors.clear('runAsUser')
                    errors.set('runAsUser', errMsg)
                    return false;
                } else
                    handleNext(values)
            }
        }

    }

    const { Label, Input, Error, Form } = useForm(data, onSubmit, onWatch);

    const handleBack = () => {
        handleClose();
        setTimeBased(true)

    }
    const verifyUser = async (name) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.getUser(name);
        if (error)
            return error;
    }
    const handleNext = async () => {
        handleClose();
        setParameter(true)
    }

    const handleCancel = () => {
        handleClose();
        setBospopupEdit(false);
        setFinalStep(true)

    }

    const loadBos = async (name) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.GetBos(name);
        if (!error) {
            setAllBos(data.data)
        }
    }

    const handleProject = (name, id, projectId) => {
        setParams({ ...params, projectName: name, projectComponentId: projectId })
        setData({ ...data, project: name, projectId: projectId })
        // data.project= name;data.projectId= projectId
        console.log(data)
        setAllProjects(
            allProjects.map((proj, idx) => {
                if (id === idx) {
                    proj.select = true
                    return proj

                }
                else {
                    proj.select = false
                    return proj;
                }
            })

        )
        loadBos(name);

    }

    const handleBos = (id, name, uuid) => {
        setParams({ ...params, bosName: name, serviceId: uuid })
        setData({ ...data, bos: name, serviceId: uuid })
        // data.bos= name;data.serviceId= uuid

        setAllBos(
            allBos.map((bos, idx) => {
                if (id === idx) {
                    bos.select = true
                    return bos
                }
                else {
                    bos.select = false
                    return bos;
                }
            })

        )
        loadParameter(name, uuid)

    }

    const loadParameter = async (name, uuid) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.GetParameter(params.projectName, name, uuid);
        if (!error) {
            setParamObject(data.object)
        }
    }

    const CreateScheduler = async (payload) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.CreateSchedulerBasedonTime(payload);
        if (!error) {
            console.log(data)
        }
    }



    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"select-bos"} title={'Select a BOS to invoke'} titleClassName={styles.bosHeader} >
            {/* <h1 className={styles.bosHeader}>
                Select a BOS to invoke
            </h1> */}
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>
                        <div className={styles.bosBox}>
                            <div data-label-testid={"project"}>
                                <Label text={"Select Project"} htmlFor="project" style={style} />
                                <div name={"project"} id="project" style={style1}>

                                    {
                                        allProjects.length ? allProjects.map((project, id) => {
                                            return <li data-option-testid={project.name} className={project.select ? (styles.boslist, styles.select) : styles.boslist} key={project.id} onClick={() => handleProject(project.name, id, project.id)}>{project.name}</li>
                                        }) : ""
                                    }
                                </div>
                                <Error name="project" />
                            </div>
                            <div data-label-testid={"Select BOS"} >
                                <Label text={"Select BOS"} htmlFor="bos" style={style} />
                                <div id="bos" style={style1}>

                                    {
                                        allBos.length ? allBos.map((service, id) => {
                                            return <li data-option-testid={service.name} className={service.select ? (styles.boslist, styles.select) : styles.boslist} key={service.id} onClick={() => handleBos(id, service.name, service.serviceId)}>{service.name}</li>
                                        }) : ""
                                    }
                                </div>
                                <Error name="bos" />

                            </div>
                        </div>


                        <Label text={"Do you want to run as a specific user?"} htmlFor="runAsUser" style={style} />
                        <Input type="text" id="runAsUser" name="runAsUser" maxLength={45} />
                        <Error name={'runAsUser'} />
                        {/* className={styles.inputStyle} */}


                    </div>
                </div>

                <div className={styles.afFooter}>
                    {
                        !bospopupEdit ? <>
                            <Input
                                type="button"
                                value={"Back"}
                                className="secondary"
                                onClick={handleBack}

                            />
                            <Input
                                type="submit"
                                value={"Save Draft"}
                                className="secondary"
                                onClick={() => setDraft(true)}

                            />
                            <Input type="submit" className="primary" value={"Next"} /></> : <>

                            <Input
                                type="button"
                                value={"Cancel"}
                                className="secondary"
                                onClick={handleCancel}

                            />
                            <Input type="submit" className="primary" value={"Save"} /></>
                    }

                </div>
            </Form>
        </PopUp>
    </>
}
