import React, { useEffect, useState, useRef } from "react";
import TableList from "./table"
import styles from "./database.module.css";
import { DatabaseActions } from "./database.actions";
import { useAppContext } from "../../components/activity/AppContext";
function Query(props) {
    const app = useAppContext();
    const {
        item = [],
        index = "",
        handleQueryTab = () => null,
        projectName = "",
    } = props;
    const [records, setRecords] = useState(item.data || []);
    const visibleRowCount = 5; //count should be such that it overflows the table
  
    const containerRef = useRef(null);
    const [endIndex, setEndIndex] = useState(visibleRowCount - 1);
    const [downloadDisabled, setDownloadDisabled] = useState(false)
    const [initialQuery, setInitialQuery] = useState(item?.query||"")
    useEffect(() => {
        const execute = async () => handleExecuteQuery();
        if (item.canExecute)
            execute();
            item.canExecute=false
    }, []);

    const handleExecuteQuery = async () => {
        if (item.query.length > 5) {
            const database = DatabaseActions(app());
            let [err, data] = await database.executeQuery(item.query, projectName, item.isLib);
            item.data = data
            setRecords(data);
            item.canExecute=false
            setDownloadDisabled(false)
            setInitialQuery(item.query)
        }
    };

    const handleQueryDataDownload = () => {
        let csvContent = '';
        csvContent += records.columns.join(',') + '\n';
        for (let i = 1; i < records.list.length; i++) {
          csvContent += records.list[i].join(',') + '\n';
        }
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'table.csv');
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);      
    }

    const updateQuery = (val) => {
        item.query = val;
        handleQueryTab(index, val);
        
        if(initialQuery !== val.trim()) {setDownloadDisabled(true);item.canExecute=true}
        else {setDownloadDisabled(false);item.canExecute=false}
    };

    const moveFocus = (e) => {
        var temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    }

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    
        if (scrollTop + clientHeight >= scrollHeight) {
          setEndIndex((prevEndIndex) => prevEndIndex + visibleRowCount); //adding rows, if we reached bottom of row
        }
      };
    const newVisibleRecords = {...records}
    const visibleRecords = records?.list? records.list.slice(0, endIndex + 1): [];
    if(visibleRecords.length) newVisibleRecords.list = visibleRecords
    return (
        <div label={`${item.name}`} tId={`${item.id}`} canClose={`${item.canClose}`}>
            <div>
                <textarea autoFocus onFocus={moveFocus} data-input-testid={"queryText"} className={styles.textarea} placeholder="Write your query here.." onChange={(e) => updateQuery(e.target.value)}>{item.query}</textarea>
            </div>
            <div className={styles.columnButtonContainer}>
                {records?.list?.length!==undefined?<div className={styles.queryDownloadButton}><input data-clickable-testid={"downloadQueryData"} className={`primary`} type="submit" value="Download" onClick={handleQueryDataDownload} disabled={downloadDisabled || records.length===0}/></div>:""}
                <input data-clickable-testid={"executeQuery"} className={`primary`} type="submit" value="Execute" onClick={handleExecuteQuery} />
            </div>
            <div className={styles.databaseQueryTable} onScroll={handleScroll} ref={containerRef}>
                <TableList
                    list={newVisibleRecords}
                    name={"table-records"}
                />
            </div>
        </div>
    )
}
export default Query;