import React from "react";
export default function ProgressBar(props) {

    const { progress, statusColor } = props;

    const Parentdiv = {
        height: '8px',
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: '4px',
        margin: "50px 0 0",
        overflow: "hidden"
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: `${statusColor.color}`,
        borderRadius: "4px",
        textAlign: 'right',
        transition: "width 1s"
    }

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
                <span></span>
            </div>
        </div>
    )
}
