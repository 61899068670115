import { getUrl, get, post } from "../url";

export async function projectsHistory(loginName){
    let path = `${getUrl()}/configurator/v2.0/projecthistory/list`;
    let select = `$select=id,loginName,name,modifiedDate,screenshot,versionStatus,BFList`;
    let sortQuery = `$sort=desc createdTimestamp`;
    let filter = `$filter=loginName eq '${loginName}'`;
    let limit = 4;

    const [error, data] = await get(`${path}?${select}&${sortQuery}&${filter}&limit=${limit}`);
    return [error,data];
}

export async function projectsList(){
    let path = `${getUrl()}/configurator/v2.0/project/listWithModified`;
    let select = `$select=name,description,status,versionStatus,modifiedDate,screenshot,id,editedTime,BFList`;
    let sortQuery = `$sort=desc id`;
    let filter = `$filter=status GT 0`;

    const [error, data] = await get(`${path}?${select}&${sortQuery}&${filter}`);
    return [error,data];
}

export async function projectsTitle() {
    let path = `${getUrl()}/configurator/v1.0/project/titleMsg`;

    const [error, data] = await get(path);
    return [error,data];
}

export async function createProject(payload = {}) {
    let path = `${getUrl()}/configurator/v1.0/project`;

    const [error, data] = await post(path, payload);
    return [error, data];
}