import styles from "./index.module.css";
import { messages } from "./messages";
import React from "react";

function StorageCard (props) {
    
    const { space = {} } = props;

    const convertToKB = (str = "", value = 0) => {
        let fromMB = 1024;
        let fromGB = 1024 * 1024;

        if(str.includes("MB")){
            value *= fromMB;
        } else if(str.includes("GB")) {
            value *= fromGB;
        }

        return value;
    }

    const getUsedSpace = (obj = {}) => {
        let total = parseFloat(obj.totalSpace || "1");
        let occupies = parseFloat(obj.occupiedSpace || "0");

        total = convertToKB(obj.totalSpace, total);
        occupies = convertToKB(obj.occupiedSpace, occupies);

        return `${(occupies / total) * 100}%` ;
    }

    return (
        <div className={styles.storageCard}>
            <div className={styles.storageCardText}>
                <p>{messages.storage}</p>
                <p>{space.occupiedSpace || "0"} of {space.totalSpace || "0"} used</p>
            </div>
            <div className={styles.storageIndicator}>
                <div 
                    style={{ width: getUsedSpace(space) }}
                    className={styles.storageIndicatorActive} 
                />
            </div>
        </div>
    )
}

export default StorageCard;