import { useAppContext } from "../../../components/activity/AppContext";
import Pagination from '../../../components/Pagination/pagination';
import { ModuleTitle } from "../../../components/module-title";
import { UserLogActions } from './userlogs.actions';
import Table from '../../../components/Table/table';
import React, { useEffect, useState } from 'react';
import UserLogsDetail from './userLogsDetail';
import './userLogs.css';

function UserLogs({perm}) {

    const app = useAppContext();
    const header = [
        { name: "User Name", id: "loginName", type: "input", value: "", width: "200px", disabled: true },
        { name: "Api Type", id: "apitype", type: "select", value: ["drive", "configurator", "library", "Login", "database", "businessfunction"], width: "160px" },
        { name: "Log Type", id: "logtype", type: "select", value: ["info", "debug", "error", "warning", "success"], width: "160px" },
        { id: "timestamp", name: "Time Stamp", "value": "", type: "input", width: "190px", disabled: true },
        { id: "userAgent", name: "User Agent", "value": "", type: "input", width: "190px", disabled: true }
    ];
    const [displayRows, setDisplayRows] = useState({ offset: 0, limit: 10 });
    const [userDataCount, setUserDataCount] = useState(10);
    const [userpopup, setUserpopup] = useState(null);
    const [nextKey, setNextKey] = useState(null);
    const [userLogs, setUserLogs] = useState([]);
    const [log, setLog] = useState({});
    let isLoading = false;



    const handleHeaderInputChange = (id, value, d) => {
        let keys = {
            logtype: "logtype",
            apitype: "apitype",
            loginName: "login_name"
        }
        setLog({ ...log, [keys[id]]: value });
    }

    const handleUpdate = (userLog) => {
        setUserpopup(userLog);
    }

    function getData(paginationChange = false) {
        async function getLogs() {
            isLoading = true;
            const actions = UserLogActions(app());
            if (log.logtype === "") {
                setLog({})
            }
            const [error, data] = await actions.getUserLogs(displayRows.limit, log, paginationChange ? nextKey : null);
            isLoading = false;
            if (!error) {
                try {
                    let logs = JSON.parse(data?.detail?.fileAddress || "{}") || {};
                    if (Array.isArray(logs?.data)) {
                        logs.data = logs.data.map((item) => {
                            if (item.data) {
                                item.data = JSON.parse(item.data || "{}") || {};
                                item.userAgent = item.data?.userAgent || "";
                            }
                            return item;
                        })
                    }
                    setUserLogs(logs?.data || []);
                    setNextKey(logs?.nextKey || null);
                    setUserDataCount((logs?.noOfrecords || 0) + 11);
                } catch (e) {
                    //error
                }
            }
        }

        if (!isLoading) {
            getLogs();
        }
    }

    useEffect(() => getData(true), [displayRows]);
    useEffect(() => { getData(false) }, [log]);


    return (
        <div className='userLogsPage'>
            <ModuleTitle
                title="User Logs"
                subTitle="Admin Tools"
            />
            <div className='afModule'>
                <Table
                    width={"100%"}
                    data={userLogs}
                    headerColumns={header}
                    name={"userlogs-table"}
                    onRowClick={handleUpdate}
                    handleHeaderInputChange={handleHeaderInputChange}
                    handleHeaderSelectChange={handleHeaderInputChange}
                />
                <div className='paginationUserlog'>
                    <Pagination
                        showRecords={false}
                        displayRows={displayRows}
                        userDataCount={userDataCount}
                        setDisplayRows={setDisplayRows}
                    />
                </div>
            </div>
            {
                userpopup &&
                <UserLogsDetail
                    userModal={userpopup}
                    setUserModal={setUserpopup}
                />
            }
        </div>
    )
}

export default UserLogs;