import React, { useState, useRef, useEffect } from 'react';
import { Box, Tooltip, Grid } from '@material-ui/core';
import { Draggable, state, Dropzone } from '../../../../libraries/react-page-maker';
import MoreActionButton from '../../components/more-actions/more-action-tool';
import { getComputedStyle, getStyle, replaceTemplateValues } from '../../utils/style';
import { datagrid as property } from './property';
import DatagridPreview from './preview/DataGrid';
import { textTemplate } from '../../template';
import { GROUP_COMPONENT_NAMES, COMPONENT_NAMES } from '../../utils/configuration/componentName.contants';
import { getMaxIDByType } from '../../../../helpers/app-designer';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { dataGridCellDefultSchema } from './schema';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  spacing: 1,
});


LicenseInfo.setLicenseKey(
  '474272ca5c79da16f5ce14be16ba4cc4Tz03MTI5OCxFPTE3MjE2ODYyNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);
let clone = React.cloneElement;
React.cloneElement = (e, params) => {
  //console.log("Clone",e,params); hook method for react clone fix for two times showing lable
  if (!params['aria-labelledby']) {
    return clone(e, params);
  } else {
    return e;
  }
}
const DHeaderCell = React.forwardRef((props, ref) => {
  React.useEffect(() => {
    if (ref && ref.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current, null),
        componentName: props.j,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.TABLE_CELL,
      });
      var val = value;
      val.text = {
        color: "#000",
        fontFamily: "Saira",
        fontSize: "14px",
        fontStyle: "normal",
        fontType: "",
        lineHeight: "24px",
        textAlign: "left"
      }
      val.component.placeholder = `Header ${props.i}`; // Setting default property value
      val.component.resizable = "enable"; // Setting default property value
      val.component.sortable = "enable"; // Setting default property value
      val.component.editable = "disable"; // Setting default property value

      val.component.placeholder = `Header ${props.i}`;
      props.updateCellPropertyValue(props.id, val, true)
    }
  }, [ref]);

  const _getStyleProps = () => {
    let style = { ...getStyle(props.propertyValue, ['text']) }
    //border: '0px solid transparent'
    //if (props.triggerAction.highlightGrid){
    //  style.border = '2px solid #97C1FF'
    //}
    return style;
  }

  return (
    <Box
      component={"span"}
      container
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        props.onCellClick(props, ref);
      }}
      style={_getStyleProps()}
      onMouseOver={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}

    >
      <div id={props.propertyValue?.component?.id} dataId={props.id} style={{ display: 'none' }} />
      {props.propertyValue?.component?.placeholder ?? `Header ${props.i}`}
      {props.triggerAction.highlightGrid && (
        <MoreActionButton
          type={'Header Cell'}
          showParentSelection={true}
          selectParent={(e) => {
            e.stopPropagation();
            props.triggerAction.selectParent();
          }}
          showActionButton={false}
          anchorElement={ref}
        />
      )}
    </Box>
  )
});

const DTableCell = React.forwardRef((props, ref) => {
  React.useEffect(() => {
    if (ref && ref.current && !props.propertyValue.style) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(ref.current, null),
        componentName: props.j,
        componentId: props.i,
        gComponentName: GROUP_COMPONENT_NAMES.TABLE_CELL,
      });
      var val = value;
      val.marginTop = 0;
      val.marginBottom = 0;
      props.updateCellPropertyValue(props.id, val, true)
    }
  }, [ref]);
  return (
    <Tooltip arrow
      title={props.propertyValue?.tooltip?.title || ""}
      placement={props.propertyValue?.tooltip?.position || ""}
      PopperProps={{ disablePortal: true }}
    >
      <Grid xs={12}
        container
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          props.onCellClick(props, ref);
        }}
        style={{ margin: 0, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: props.triggerAction.getCellAlignment(props.i - 1) }}
        {...props}
        className={`${props.className ? props.className : ''}`}
      >
        <div id={props.propertyValue?.component?.id} dataId={props.id} style={{ display: 'none' }} />
        {props.children}
        {props.triggerAction.highlightGrid && (
          <MoreActionButton
            type={'DataGrid-Cell'}
            showParentSelection={true}
            selectParent={(e) => {
              e.stopPropagation();
              props.triggerAction.selectParent();
            }}
            showActionButton={false}
            anchorElement={ref}
          />
        )}
      </Grid>
    </Tooltip>
  )
});

const DraggableDataGrid = (props) => {
  const {
    id,
    uniquekey,
    showBasicContent,
    showPreview,
    dropzoneID,
    onDropZone,
    dropzoneProps,
    initialElements,
    parentID,
    onSelect,
    type,
    iconComponent,
    fields,
    setState,
    options = [
      { label: '', value: '', checked: false },
      { label: '', value: '', checked: false },
    ],
    propertyValue,
    idocument,
    selectedComponent,
    ...rest
  } = props;
  const [label, setLabel] = useState(props.label);
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [highlightGrid, setHighlightGrid] = useState(null);
  const [isHover, setHover] = useState(false);
  const [row, setRow] = useState([]);
  const [col, setCol] = useState([]);
  const colum = propertyValue?.component?.columns ?? 4;

  const filterInitialElements = (dID) => {
    let items = [];
    var el = state.traverseAndReturnElement(id, dropzoneID, parentID);
    if (el && el.fields && el.fields > 0) {
      items = el.fields.filter((e) => e.dropzoneID === dID) || [];
    }
    if (initialElements && initialElements.length > 0) {
      // if(initialElements && fields && initialElements.length > 0 && initialElements.length >= options.length*2) {
      items = initialElements.filter((e) => e.dropzoneID === dID) || [];
    }
    if (items.length > 0) {
      return items;
    }

    var d = [];
    if (d && d.length === 0) {
      // var uniquekey = idocument.getElementsByClassName('TEXT').length + 1;
      var uniquekey = getMaxIDByType(idocument, 'TEXT');
      for (let i = 0; i < colum; i++) {
        d = [
          ...d,
          {
            ...replaceTemplateValues(textTemplate, { textColor: '#000', TextId: uniquekey + i + 1 }),
            uniquekey: uniquekey + i + 1,
            id: 'table-lable-' + i + '-' + id,
            key: 'table-lable-' + i + '-' + id,
            dropzoneID: 'table-heading-col-' + i + '-' + id,
            label: 'Heading',
            parentID: id,
          },
          {
            ...replaceTemplateValues(textTemplate, { textColor: '#000', TextId: uniquekey + i + 2 }),
            uniquekey: uniquekey + i + 2,
            id: 'lable-0-' + i + '-' + id,
            key: 'lable-0-' + i + '-' + id,
            dropzoneID: 'table-body-col-0-' + i + '-' + id,
            label: 'Label',
            parentID: id,
          },
        ];
        uniquekey++;
      }
    }
    return d.filter((e) => e.dropzoneID === dID) || [];
  };
  const onClick = (e) => {
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        options,
        property,
        propertyValue,
      },
      false,
      e
    );
  };

  const handleActions = (e) => {
    e.stopPropagation();
    let position = { x: e.clientX, y: e.clientY };
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        uniquekey,
        type,
        label,
        property,
        propertyValue,
        isActions: true,
        position,
      },
      false,
      e
    );
  };

  const updateCellPropertyValue = async (cellId, newValue, initflag) => {
    if (!initflag) {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => {
        onSelect(
          {
            id,
            dropzoneID,
            parentID,
            cellId,
            type,
            label,
            property,
            parentPropertyValue: newPvalue,
            propertyValue: newPvalue[cellId],
          },
          false
        );
      });
    } else {
      var newPvalue = propertyValue;
      newPvalue[cellId] = newValue;
      await setState({ addAction: true });
      state.updateElement(id, dropzoneID, parentID, { propertyValue: newPvalue }, () => { });
    }

  }
  const onHeadingDataCellClick = (target, ref) => {
    const property = {
      componentSection: ['ID', 'Classes', 'Label', 'Sorting', 'Resizable', 'Editable'],
      dataSource: [{ name: 'Data', isArray: false }],
      gridSection: [],
      textSection: ['Size', 'Alignment', 'Decoration'],
      styleSection: ['Background', 'Opacity', 'Custom CSS'],
      layoutSection: ['Layout','Width', 'Height'],
      tooltipSection: ["event", "position", "title"],
      interactionSection: dataGridCellDefultSchema({}).events
    };
    onCellClick(target, ref, property, COMPONENT_NAMES.DATAGRID_HEADER_CELL);
  }
  const onRowDataCellClick = (target, ref) => {
    const property = {
      componentSection: ['ID', 'Classes'],
      gridSection: [],
      textSection: true,
      styleSection: ['Background', 'Opacity', 'Custom CSS'],
      layoutSection: ['Layout', 'Width', 'Height'],
      tooltipSection: ["event", "position", "title"],
      disableMargin: true,
      interactionSection: dataGridCellDefultSchema({}).events
    };
    onCellClick(target, ref, property, COMPONENT_NAMES.DATAGRID_CELL);
  }
  const onCellClick = (target, ref, property, _type) => {
    let cellId = target.id;
    let styles = { ...(propertyValue ? propertyValue : defaultPropertyValue) };
    setHighlightGrid(cellId);
    onSelect(
      {
        id,
        dropzoneID,
        parentID,
        cellId,
        type: _type ?? type,
        label,
        property,
        parentPropertyValue: styles,
        propertyValue: styles[cellId],
      },
      false
    );
  };


  const _getHeaderCellId = (itemIndex) => {
    return `table-heading-col-${itemIndex}-${id}`
  }
  const _getColumnCellId = (itemIndex) => {
    return `table-body-col-0-${itemIndex}-${id}`
  }
  const _getHeaderCellPropValue = (itemIndex) => {
    return propertyValue[_getHeaderCellId(itemIndex)];
  }
  const _getColumnCellPropValue = (itemIndex) => {
    return propertyValue[_getColumnCellId(itemIndex)];
  }

  const _getHeaderSortable = (itemIndex) => {
    let pv = _getHeaderCellPropValue(itemIndex);
    if (pv && pv?.component?.sortable === "disable") {
      return false;
    }
    return true;
  }
  const _getColumnEditable = (itemIndex) => {
    let pv = _getHeaderCellPropValue(itemIndex);
    if (pv && pv?.component?.editable === "disable") {
      return false;
    }
    return true;
  }
  const _getHeaderResizable = (itemIndex) => {
    let pv = _getHeaderCellPropValue(itemIndex);
    if (pv && pv?.component?.resizable === "disable") {
      return false;
    }
    return true;
  }

  const _getHeaderAlignment = (itemIndex) => {
    let pv = _getHeaderCellPropValue(itemIndex);
    if (pv && pv?.text?.textAlign !== undefined) {
      return pv?.text?.textAlign;
    }
    return "left";
  }
  const _getCellAlignment = (itemIndex) => {
    return _getHeaderAlignment(itemIndex);
  }
  const _getHeaderClasees = (itemIndex) => {
    let pv = _getHeaderCellPropValue(itemIndex);
    if (pv && pv?.component?.classes !== undefined) {
      return pv?.component?.classes;
    }
    return '';
  }
  const _getCellClasees = (itemIndex) => {
    let pv = _getColumnCellPropValue(itemIndex);
    if (pv && pv?.component?.classes !== undefined) {
      return pv?.component?.classes;
    }
    return '';
  }
  const RenderHeader = React.forwardRef((props, ref) => {
    const { j } = props;
    return (
      <>
        <Dropzone
          {...dropzoneProps}
          // initialElements={filterInitialElements('table-heading-col-' + j + '-' + id)}
          initialElements={[]}
          id={'table-heading-col-' + j + '-' + id}
          onDrop={onDropZone}
          onSelect={(value) => {
            setHighlightGrid(null);
            // setShowMoreActions(null);
            onSelect(value);
          }}
          onCellClick={onHeadingDataCellClick}
          updateCellPropertyValue={updateCellPropertyValue}
          setState={setState}
          placeholder=""
          key={'table-heading-col-' + j + '-' + id}
          col={options}
          i={j + 1}
          j={`datagrid${uniquekey}_th_cell`}
          selectedComponent={selectedComponent}
          component={DHeaderCell}
          className={'table-heading-col-' + j + '-' + id}
          type={`DataGrid Header Cell`}
          dropzoneCellID={id}
          properties={JSON.stringify({ id: 'table-heading-col-' + j + '-' + id, dropzoneID: id, parentID: id, label: `DataGrid Header Cell ${j + 1}`, type: 'DataGrid Header Cell' })}
          triggerAction={{
            highlightGrid: 'table-heading-col-' + j + '-' + id === selectedComponent?.cellId,
            selectParent: () => { onClick(); },
            dataGridProps: propertyValue
          }}
          propertyValue={propertyValue && propertyValue['table-heading-col-' + j + '-' + id] ? propertyValue['table-heading-col-' + j + '-' + id] : { component: { id: `datagrid${uniquekey}_th_cell` } }}
        />
      </>
    )
  });
  const RenderCell = (props, ref) => {
    const { value: j } = props;
    return (
      <>
        <Dropzone
          {...dropzoneProps}
          initialElements={filterInitialElements('table-body-col-0-' + j + '-' + id)}
          id={'table-body-col-0-' + j + '-' + id}
          onDrop={(data, flag, t) => {
            console.log("ondrop", j);
            onDropZone(data, flag, t);
          }}
          onSelect={(value) => {
            setHighlightGrid(null);
            // setShowMoreActions(null);
            onSelect(value);
          }}
          onCellClick={onRowDataCellClick}
          updateCellPropertyValue={updateCellPropertyValue}
          selectedComponent={selectedComponent}
          setState={setState}
          placeholder="Drop Here"
          key={'table-body-col-0-' + j + '-' + id}
          col={options}
          i={j + 1}
          j={`datagrid${uniquekey}_td_cell`}
          component={DTableCell}
          className={'table-body-col-0-' + j + '-' + id}
          type={`DataGrid Row Cell`}
          dropzoneCellID={id}
          properties={JSON.stringify({ id: 'table-body-col-0-' + j + '-' + id, dropzoneID: id, parentID: id, label: `DataGrid Row Cell ${j + 1}`, type: 'DataGrid Row Cell' })}
          triggerAction={{
            highlightGrid: 'table-body-col-0-' + j + '-' + id === selectedComponent?.cellId,
            selectParent: () => {
              onClick();
            },
            getCellAlignment: _getCellAlignment
          }}
          propertyValue={propertyValue && propertyValue['table-body-col-0-' + j + '-' + id] ? propertyValue['table-body-col-0-' + j + '-' + id] : { component: { id: `datagrid${uniquekey}_td_cell` } }}
        />
      </>
    );
  };
  const setRowCol = () => {
    if (colum > 0 && !showBasicContent && !showPreview) {
      let c = [], r = { id: 1 };
      for (let i = 0; i < colum; i++) {
        let headerCellId = `table-heading-col-${i}-${id}`;
        let key = headerCellId;
        let pvh = _getHeaderCellPropValue(i);
        let column = {
          field: key,
          headerName: <RenderHeader j={i} />,
          //renderHeader:(p)=>(<RenderHeader j={i}/>),
          renderCell: RenderCell,
          sortable: false,// design time it shold be disabled
          resizable: false,//design time ti shud be false
          editable: false,// design time it shuld be disabled
          cellClassName: `data-grid-cell-0-${i} ${_getCellClasees(i)}`,
          headerClassName: `data-grid-header-0-${i} ${_getHeaderClasees(i)}`,
          headerAlign: _getHeaderAlignment(i),
          description: pvh?.tooltip?.title || '',
        }
        if (!column.description) {
          delete column.description;
        }
        if (pvh?.layout.hasOwnProperty("width") && pvh?.layout?.width !== "auto") {
          column.width = parseInt(pvh?.layout?.width);
        } else {
          column.flex = 1;
          column.minWidth = 100;
        }
        c.push(column);
        r[key] = i;
      }
      setCol(c);
      setRow([r])
    } else if (colum > 0 && !showBasicContent && showPreview) {
      let c = [], r = { id: 1 };

      for (let i = 0; i < colum; i++) {
        let headerCellId = `table-heading-col-${i}-${id}`;
        let pv = propertyValue[headerCellId];
        let key = `column ${i + 1}`;
        let column = {
          field: key,
          headerName: pv?.component.placeholder || key,
          renderCell: () => {
            let pv = propertyValue[`table-body-col-0-${i}-${id}`];
            return (
              <Grid xs={12}
                container
                style={{ margin: 'auto', display: 'flex', position: 'relative', alignItems: 'center', justifyContent: _getCellAlignment(i) }}
                id={pv?.component?.id}
              >
                {rest.childNode[`table-body-col-0-${i}-${id}`] || null}
              </Grid>
            )
          },
          sortable: _getHeaderSortable(i),
          resizable: _getHeaderResizable(i),
          editable: _getColumnEditable(i),
          cellClassName: `data-grid-cell-0-${i} ${_getCellClasees(i)}`,
          headerClassName: `data-grid-header-0-${i} ${_getHeaderClasees(i)}`,
          headerAlign: _getHeaderAlignment(i),
        }
        if (pv?.layout.hasOwnProperty("width") && pv?.layout?.width !== "auto") {
          column.width = parseInt(pv?.layout?.width);
        }

        c.push(column);
        r[key] = i;
      }
      setCol(c);
      setRow([r])
    } else {
      setCol([]);
      setRow([]);
    }
  }
  useEffect(() => {
    setRowCol();
  }, [propertyValue, selectedComponent]);

  if (showBasicContent) {
    let data = { ...props };
    data.propertyValue = defaultPropertyValue;
    data.options = [
      { label: '', value: '', checked: false },
      { label: '', value: '', checked: false },
    ];
    return (
      <Draggable {...data}>
        {iconComponent}
        <DatagridPreview setDefaultPropertyValue={setDefaultPropertyValue} />
      </Draggable>
    );
  }
  const _getBorderHighlight = (id) => {
    if (selectedComponent?.cellId === id) {
      return '2px solid #97C1FF';
    }
    return '0px solid transparent';
  }

  const getSx = () => {
    let sx = {};
    for (let i = 0; i < colum; i++) {
      let pvc = _getColumnCellPropValue(i);
      let pvh = _getHeaderCellPropValue(i);
      if (pvc) {
        sx[`& .data-grid-cell-0-${i}`] = { border: _getBorderHighlight(`table-body-col-0-${i}-${id}`), ...getStyle(pvc, ['style', 'layout'],true) };
      }
      if (pvh) {
        sx[`& .data-grid-header-0-${i}`] = { border: _getBorderHighlight(`table-heading-col-${i}-${id}`), ...getStyle(pvh, ['style', 'layout'],true) };
      }
    }
    return sx;
  }
  const _getDataGridComponentsProps = () => {
    let _props = {};
    if (propertyValue.component?.toolbar === "show") {
      _props.Toolbar = GridToolbar
    }
    return _props;
  }

  if (showPreview) {
    return (
      <>
        <Box className={propertyValue.component?.classes} style={{ display: 'grid', height: 200, width: '100%', ...getStyle(propertyValue, ['style', 'layout']) }}>
          <ThemeProvider theme={theme}>

            <DataGridPro
              rows={row}
              columns={col}
              checkboxSelection={propertyValue.component?.selection === 'checkbox'}
              disableSelectionOnClick={propertyValue.component?.selection === 'none' ?? true}
              pagination={propertyValue.pagination}
              hideFooterPagination={!propertyValue.pagination}
              page={0}//current Index
              pageSize={5}//number of records per page
              rowsPerPageOptions={[5]}//number of records per page
              hideFooterRowCount={propertyValue.component?.selection === 'checkbox' ?? true}
              hideFooterSelectedRowCount={propertyValue.component?.selection === 'none' ?? true}
              sx={
                getSx()
              }
              components={_getDataGridComponentsProps()}
            />
          </ThemeProvider >

        </Box>
      </>
    );
  }

  const _getPageSizePropValue = () => {
    if (propertyValue?.pagination?.rowsPerPage && typeof propertyValue?.pagination?.rowsPerPage !== "string") {
      return propertyValue?.pagination?.rowsPerPage;
    }
    return 5
  }

  const _getDataGridStyle = () => {
    const _style = { ...getStyle(propertyValue, ['style', 'layout']) };
    if (_style?.height === "auto") {
      delete _style.height;
    }
    return _style;
  }

  return (
    <>
      <Draggable {...props} dataid={`${id}_parent`} style={{ boxShadow: 'none' }} onClick={onClick}>
        {(data) => (
          <Tooltip arrow
            title={propertyValue?.tooltip?.title || ""}
            placement={propertyValue?.tooltip?.position || ""}
            PopperProps={{ disablePortal: true }}
          >

            <Box
              onMouseOut={(e) => {
                e.stopPropagation();
                setHover(false);
              }}
              onMouseOver={(e) => {
                e.stopPropagation();
                setHover(true);
              }}
              id={propertyValue.component.id}
              dropzoneID={dropzoneID}
              compid={propertyValue.component.id}
              className={`draggable-component ${type} ${isHover && 'draggable-hover'}  ${data.className} ${selectedComponent?.id === id && 'highlight-component'} ${propertyValue.component.classes ?? ''}`}
              dataid={`${id}_parent`}
              properties={JSON.stringify({ id, dropzoneID, parentID, label, type })}
              style={{ display: 'grid', height: 200, width: '100%', ..._getDataGridStyle() }}

            >
              <ThemeProvider theme={theme}>
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                      rows={row}
                      columns={col}
                      loading={row.length === 0}
                      checkboxSelection={propertyValue.component?.selection === 'checkbox'}
                      disableSelectionOnClick={propertyValue.component?.selection === 'none' ?? true}
                      pagination={propertyValue.pagination}
                      hideFooterPagination={!propertyValue.pagination}
                      // page={propertyValue?.pagination?.currentIndex ?? 0}//current Index
                      page={0}//In design time, we will show only, how the pagination will look like, actual pagination values will be calculated on runtime
                      pageSize={_getPageSizePropValue()}//number of records per page
                      rowsPerPageOptions={[_getPageSizePropValue()]}//number of records per page
                      sx={(theme) => {
                        return getSx();
                      }}
                      //components={_getDataGridComponentsProps()}
                      disableColumnFilter
                      onColumnHeaderClick={(params, cls, v) => {
                        cls.stopPropagation();
                        let idoc = document.getElementById('iframe').contentDocument;
                        let elem = idoc.querySelector(`[dataid*="${params.field}"]`);
                        console.log(elem);
                        if (elem && !elem.length) {
                          elem.click();
                        }

                      }}
                      disableVirtualization// This props shold be there for design time to prevent lazy render or on demant dom createion
                      disableColumnMenu
                      onColumnHeaderOver={(params, event, details) => {
                        event.preventDefault();
                        event.defaultMuiPrevented = true;
                        event.stopPropagation();
                      }}

                    />

                    {!selectedComponent?.cellId && selectedComponent?.id === id && <MoreActionButton handleActions={handleActions} type={'DataGrid'} anchorElement={data.anchorelement} />}
                  </div></div>
              </ThemeProvider >
            </Box>
          </Tooltip>

        )}

      </Draggable>

    </>
  );
};

export default DraggableDataGrid;
