import React, { useEffect, useState } from "react";
import PopUp from "../../../components/layouts/PopUp";
import { useForm } from "../../../components/forms/Form";
import {TenantActions} from './Tenant.Actions'
import { useAppContext } from "../../../components/activity/AppContext";
import styles from "./licensePopUp.module.css";
import { validateValue, validateValues } from "./validations";
import LicenseActions from "../licenseRenewal/licenseRenewal.actions";
import SmallPopup from "../../../components/layouts/smallPopup";
import { useLocation } from "react-router-dom";

export default function LicensePopUp(props) {

    const app = useAppContext()
    const { popUp, setPopUp, selectedTenant, refresh, perm } = props;
    const [data, setData] = useState({
        tenantId: selectedTenant.tenantId || "",
        generationDate: "",
        expirationDate: "",
        appsCount: "",
        usersCount: "",
        // licenseKey: ""
    })

    const [minimumExpirationDate, setMinimumExpirationDate] = useState(data.generationDate)
    const [applyPopup, setApplyPopup] = useState({show:false, msg:""})
    const location = useLocation();
    location.state = {permission:perm}

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value)
        if(data.name==="generationDate") {
            setMinimumExpirationDate(data.value)
        }
    }

    const onSubmit = async (values, errors) => {
        if(validateValues(values, errors,/*Object.keys(data)*/["generationDate", "expirationDate"])) {
            return false
        }
        const actions = TenantActions(app());
        let [err, data] = await actions.generateLicense(values);
        if(!err) {

            setApplyPopup({show:true, msg:data.object})
            // setPopUp(false)
            // getDependencies()
            // setData((prev)=>({...prev, licenseKey:data?.object}))
        }
    }

    const handleCopy = () => {
        const { notify } = app();
        navigator.clipboard.writeText(applyPopup.msg);
        notify({ code: 1, message: "License Key copied to clipboard !", type: "S" })
    }

    const handleApply = async () => {
        const licenseValue = LicenseActions(app());
        const [error,] = await licenseValue.UpdateLicenseKeyValue({licenseKey:applyPopup.msg, tenantId:selectedTenant.tenantId})
        if(!error) {
            setApplyPopup({ show: false, msg:"" })
            setPopUp(false)
        }
    }

    const getDependencies = () => {
        const actions = TenantActions(app());
        const getDetails = async () => {
            let [err, data] = await actions.GetLicenseDetails(selectedTenant.tenantId);
            if (!err && !data.error) {
                setData({
                    tenantId: data?.detail?.tenantId||selectedTenant.tenantId,
                    generationDate: data.detail.serviceStartDate,
                    expirationDate: data.detail.serviceRenewalDate,
                    usersCount: data.detail.noOfUsers,
                    appsCount: data.detail.noOfApps,
                    licenseKey: data.detail?.licenseKey||"",
                })
                setMinimumExpirationDate(data.detail.serviceStartDate)
            }
        }
        getDetails()
    }

    const handleClose = () => {
        setPopUp(false);
      };

    useEffect( () => {

    },[])

    useEffect(()=>{
        if(selectedTenant?.tenantId) getDependencies()
    }, [selectedTenant.tenantId, refresh]);

    const { Form, Label, Input, Error, TextArea } = useForm(data, onSubmit, onWatch);


    return (
        <PopUp 
            popUp={popUp} 
            name={'Tenant License'} 
            setPopUp={setPopUp} 
            testId={"tenant-license-popup"}
            title={'Generate License'}
        >
        <Form>
            <div className={styles.formArea}>
                <div className={styles.column}>
                    <Label text={"Tenant ID"} htmlFor="tenantId" />
                    <Input type="text" id="tenantId" name="tenantId" disabled={true} style={{width:"100%"}}/>
                    <Error name={"tenantId"} />
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Label text={"License Generation Date"} htmlFor="generationDate" />
                        <Input type="date" id="generationDate" name="generationDate" style={{width:"100%"}}/>
                        <Error name={"generationDate"} />
                    </div>
                    <div className={styles.column}>
                        <Label text={"License Expiration Date"} htmlFor="expirationDate" />
                        <Input type="date" id="expirationDate" name="expirationDate" style={{width:"100%"}} min={minimumExpirationDate}/>
                        <Error name={"expirationDate"} />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <Label text={"No. of Apps"} htmlFor="appsCount" />
                        <Input type="number" id="appsCount" name="appsCount" pattern="\d*" disabled={true} style={{width:"100%"}} />
                        <Error name={"appsCount"} />
                    </div>
                    <div className={styles.column}>
                        <Label text={"No. of Users"} htmlFor="usersCount" />
                        <Input type="number" id="usersCount" name="usersCount" pattern="\d*" disabled={true} style={{width:"100%"}} />
                        <Error name={"usersCount"} />
                    </div>
                </div>
                {/* <div className={styles.column}>
                    <Label text={"License Key"} htmlFor="licenseKey" />
                    <div style={{position: "relative"}} >
                        <TextArea type="text" id="licenseKey" name="licenseKey" disabled={true} style={{width:"100%", whiteSpace:"pre-wrap", height:"auto"}}/>
                        <span style={{position: "absolute", right:"-170px", top:"4px"}}>
                            <Input className={"secondary"} type="button" value={"Copy"} disabled={data.licenseKey.length===0} onClick={handleCopy} />
                            <Input className={"secondary"} type="button" value={"Apply"} disabled={data.licenseKey.length===0} onClick={handleApply} />
                        </span>
                    </div>
                    <Error name={"licenseKey"} />
                </div> */}
            </div>

            <div className={styles.afFooter}>
            <Input
                type="button"
                className="secondary"
                value={"Cancel"}
                onClick={handleClose}
                data-clickable-testid={"Cancel"}
            />
            {location.state.permission?.canUpdate
            ?<Input
                type="submit"
                className="primary"
                value={"Generate"}
                data-clickable-testid={"Generate"}
            />
            :<></>
            }
            </div>
        </Form>
        {applyPopup.show?
            <SmallPopup 
                popUp={applyPopup.show}
                title={"Apply License"}
                subtitle={applyPopup.msg}
                handlePopUp={() => setApplyPopup({ show: false, msg:"" })}
                handleSubmit={() => handleApply()}
                handleButton1={() => handleCopy()}
                buttonName1={"Copy"}
                buttonName2={"Apply"}
                icon={"warning"}
                contentStyle={{overflowWrap:"break-word", marginTop:"0px"}}
            />:<></>}
        </PopUp>
    );

}