import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../../../components/activity/AppContext";
import { useForm } from "../../../../../components/forms/Form";
import PopUp from "../../../../../components/layouts/PopUp";
import ParametersTable from "./ParametersTable";
import { validateValue, validateValues } from "../../../../businessFunctions/validations";
import { SchedulerActions } from "../../scheduler.action";
import { useScheduler } from "../../schedulerContext";
import styles from "./Parameters.module.css"

export const Parameters = ({ show, handleClose }) => {
    let style = { marginTop: "8px" };

    const app = useAppContext()

    const [data, setData] = useState([])
    const [systemData, setSystemData] = useState([])

    // const [parameters,setParameters] = useState({})

    const { setBospopup, setTrigger, parameterEdit, setParameterEdit, setFinalStep,
        paramObject, draft, setDraft, timeBasedSchedulerForm, setParameter,
        params, createScheduler, setCreateScheduler, edit, parameters, setParameters, updateParams, updateSystemParams, save, setSave } = useScheduler();

    const updateInputFlds = (e) => {
        // setParameters({...parameters,[e.target.name]:e.target.value})
        updateParams(e.target.name, e.target.value)
    }

    const updateSystemFlds = (e) => {
        console.log(e.target.attributes.index, e.target.attributes.system, e.target.name, e.target.value)
        // setParameters({...parameters,[e.target.name]:e.target.value})
        updateSystemParams(Number.parseInt(e.target.attributes.index.value), e.target.attributes.system.value, e.target.name, e.target.value)
    }

    const fetchBosParams = async () => {
        console.log(params)
        const actions = new SchedulerActions(app())
        const [err, data] = await actions.GetParameter(params.projectName, params.bosName, params.serviceId)
        console.log(err, data)
        const obj = JSON.parse(data.object)
        let tableData = [];
        let sysData = [];
        Object.keys(obj.Input).forEach((key) => {
            console.log(obj.Input[key].type, obj.Input[key].dataType)
            let dataObj = { Variable: key, DataType: obj.Input[key].dataType, value: "", name: key, onChange: updateInputFlds }
            tableData.push(dataObj)
        })
        obj.system.forEach((key, idx) => {
            let sysObj = { System: key, "userId": "", password: "", name: key, index: idx, onChange: updateSystemFlds, system: true }
            sysData.push(sysObj)
        })
        console.log(tableData, sysData)
        setSystemData(sysData)
        setData(tableData)
    }

    useEffect(() => {
        fetchBosParams()
    }, [])

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = async (values, errors) => {
        console.log(parameters)
        if (!validateValues(values, errors)) {
            return false;
        }
        else {
            if (draft) {
                const payload = {
                    bosname: "",
                    description: timeBasedSchedulerForm.description,
                    eventId: null,
                    freq: null,
                    isEnabled: 2,
                    isTimeBased: true,
                    name: timeBasedSchedulerForm.name,
                    parameters: JSON.stringify(paramObject),
                    projectComponentId: params.projectComponentId,
                    runAsUser: params.runAsUser,
                    serviceName: params.bosName,
                    serviceType: timeBasedSchedulerForm.serviceType
                    // parameter as string {"input":{"input":"55","a":"12","b":"56"},"system":[{"system":"webservice","userId":"privi","password":"palani"}]}
                }

                console.log(payload)

                // CreateScheduler(payload)
                // handleClose()
                // setTimeout(() => setCreateScheduler(!createScheduler), 1000)
                // setDraft(false)
            }
            else if (edit) {
                handleCancel()
            }
            else if (save) {
                handleClose()
                setParameter(false)
                setParameterEdit(false)
                setSave(false)
                setFinalStep(true)
            }
            else {
                console.log(parameters)
                handleNext()
            }
        }

    }

    const { Form, Label, Input, Error, TextArea, Select } = useForm(data, onSubmit, onWatch);


    const parameterTableHeader1 = [
        { id: "Variable", name: "Variable", type: "input", width: "50px", disabled: true },
        { id: "DataType", name: "Data Type", type: "input", width: "50px", disabled: true },
        { id: "Value", name: "Value", type: "input", width: "50px", disabled: true, dataReturnType: "input" },
    ]
    const parameterTableHeader2 = [
        { id: "System", name: "System", type: "input", disabled: true },
        { id: "userId", name: "User ID", type: "input", disabled: true, dataReturnType: "input" },
        { id: "password", name: "Password", type: "input", disabled: true, dataReturnType: "input" },
    ]

    const handleBack = () => {
        handleClose();
        setBospopup(true)
    }

    const handleNext = () => {
        handleClose();
        setTrigger(true)
    }

    const handleCancel = () => {
        handleClose()
        setParameterEdit(false)
        setFinalStep(true)
    }
    const CreateScheduler = async (payload) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.CreateSchedulerBasedonTime(payload);
        if (!error) {
            console.log(data)
        }
    }


    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"select-parameters"} titleClassName={styles.parameterHeader} title={"Do you want to define any parameters?"}>

            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>
                        <div className={styles.parameterTables}>
                            <ParametersTable headerColumns={parameterTableHeader1} data={data} />
                            <ParametersTable headerColumns={parameterTableHeader2} data={systemData} />
                        </div>
                    </div>
                </div>

                <div className={styles.afFooter}>
                    {
                        !parameterEdit ? <>
                            <Input
                                type="button"
                                value={"Back"}
                                className="secondary"
                                onClick={handleBack}

                            />
                            <Input
                                type="button"
                                value={"Save Draft"}
                                className="secondary"

                            />
                            <Input type="submit" className="primary" value={"Next"} /></> : <>
                            <Input
                                type="button"
                                value={"Cancel"}
                                className="secondary"
                                onClick={handleCancel}
                            />
                            <Input type="submit" className="primary" value={"Save"} /></>
                    }

                </div>
            </Form>
        </PopUp>
    </>
}