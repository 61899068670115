import {
  SapFunctionNode,
  SapTransactionNode,
  SapStopTransactionNode,
  SapCommitNode,
  SapRollbackNode,
  AssignmentNode,
  MathNode,
  ConditionalNode,
  IfElseNode,
  IfNode,
  LoopNode,
  RepeaterNode,
  TerminateNode,
  BreakNode,
  WebServiceNode,
  RestServiceNode,
  LookupNode,
  SortNode,
  BossNode,
  CustomNode,
  MailNode,
  PushNode,
  DeviceSyncNode,
  MSDListNode,
  MSDCreateNode,
  MSDUpdateNode,
  MSDDeleteNode,
  GenerateFileNode,
  DriveUploadNode,
  DriveDownloadNode,
  DriveDeleteNode,
  DriveMoveNode,
  DriveCopyNode,
  DriveListNode,
  FTPDownloadNode,
  FileParserNode,
  CreateUserNode,
  UpdateUserNode,
  AssignGroupNode,
  BluetoothListNode,
  BluetoothListenerNode,
  BarcodeScannerNode,
  BluetoothConnectNode,
  BluetoothDisconnectNode,
  BluetoothPairingNode,
  BluetoothUnpairingNode,
  BluetoothCommandNode,
  DownloadFile,
  FreedomDBSelectNode,
  LaneNode,
  FreedomDBCreateNode,
  FreedomDBDeleteNode,
  FreedomDBUpdateNode,
  DBAdapterSelectNode,
  DBAdapterCreateNode,
  DBAdapterDeleteNode,
  DBAdapterUpdateNode,
  AddRowNode,
  FunctionNode,
  SFListNode,
  SFCreateNode,
  SFUpdateNode,
  SFDeleteNode,
  FtpNode,
  FDBColumn,
  InputOutObj,
  FunctionVariable,
  FunctionSort,
  FDBWhere,
  Workspace,
  FreedomDBQueryNode,
  DBAdapterQueryNode,
  CommentBlockNode,
  SNFCreateNode,
  SNFDeleteNode,
  SNFUpdateNode,
  SNFSelectNode,
  FreedomDBQuerywithoutParamNode,
	DBAdapterQueryWithoutParamNode,
  PdfGenerateNode,
  RemoveGroupNode,
  FMAuthNode,
  OAuthAuthenticationeNode,
  SamlAuthenticationNode,
  SapAuthenticationNode,
  ODataListNode,
  ODataCreateNode,
  ODataUpdateNode,
  ODataDeleteNode,
  ConflictQueueNode,
  WorkflowRestartNode,
  WorkflowRetryNode,
  WorkflowRevertNode,
  WorkflowContinueNode,
  WorkflowEndNode,
  WorkflowArchiveNode,
  WorkflowUpdateNode,
  UserListNode,
  UserDetailNode
} from './model';
import { ExprParser } from './ExprParser';
import DataService from '../../../../../utils/data-service';

// var BOS_NAMES = ["BOS_0", "BOS_1", "BOS_2", "BOS_3", "BOS_4", "BOS_5"];
// function Service() {
// 	this.token='eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJkYXZ1bHVyaTIxOSIsImlhdCI6MTYwODI4NjczMiwiaXNzIjoiUGlsbGlyIiwibG9naW5OYW1lIjoiZGF2dWx1cmkyMTkiLCJmaXJzdE5hbWUiOiJTcmVlbmFkaCIsImxhc3ROYW1lIjoiRCIsImVtYWlsIjoiZGF2dWx1cmkuc0BwaWxsaXIuaW8iLCJ0ZW5hbnRJZCI6IjEyMyIsInVzZXJJZCI6MTEzMzMsImNvbXBhbnlJZCI6MSwiZGV2aWNlVHlwZSI6IlVua25vd24iLCJyb2xlTmFtZSI6InRlY2ggZGV2ZWxvcGVyIiwic2FsdXRhdGlvbiI6Ik1yIiwidHlwZSI6InR5cGUiLCJleHAiOjE2MDg2MTA3MzJ9.wu44zYbQIjZclml9UxZDb3OcA154dpeOjTFYXvX-650';
// 	this.xmlhttp = new XMLHttpRequest();
// 	this.urls = {}
// 	this.urls["builder"] = ".././builder"
// 	this.urls["drive"] = ".././fmdrivefile"
// 	this.urls["uploaddoc"] = ".././importdoc"
// 	this.urls["uploadjar"] = ".././importJar"
// 	this.urls["dependencies"] = ".././ui/index.php/webservice/service"
// 	this.urls["fdbtables"] = "https://dm4.bifreedom.com/configurator/v1.0/project/cricket/listTables"
// 	this.urls["fdbcolumns"] = "https://dm4.bifreedom.com/ercdatabase/v1.0/table"

// }
// Service.prototype = {
// 	get:function(urlKey,queryString,func,event){
// 		let self = this;
// 		this.xmlhttp.onreadystatechange = ()=>{
// 			if(self.xmlhttp.readyState == 4){
// 				if(self.xmlhttp.status == 200){
// 					func(self.xmlhttp.responseText,event);
// 				}else{self.handleError();}
// 			}
// 		};
// 		let _url = this.urls[urlKey] + ((queryString && queryString.length > 0) ? queryString :'');
// 		this.xmlhttp.open("GET", _url, true);
// 		this.xmlhttp.setRequestHeader("Authorization", "Bearer " + this.token);
// 		this.xmlhttp.send();
// 	},
// 	post:function(urlKey,queryString,input,func){
// 		let self = this;
// 		this.xmlhttp.onreadystatechange = ()=>{
// 			if(self.xmlhttp.readyState == 4){
// 				if(self.xmlhttp.status == 200){
// 					func(self.xmlhttp.responseText);
// 				}else{self.handleError();}
// 			}
// 		};
// 		let _url = this.urls[urlKey] + ((queryString && queryString.length > 0) ? queryString :'');
// 		this.xmlhttp.open("POST", _url, true);
// 		this.xmlhttp.setRequestHeader("Content-type","application/x-www-form-urlencoded");
// 		this.xmlhttp.setRequestHeader("Authorization", "Bearer " + this.token);
// 		this.xmlhttp.send(input);
// 	},
// 	upload:function(urlKey,queryString,file,func){
// 		var formData = new FormData();
// 		formData.append("file_upload", file);
// 		let _url = this.urls[urlKey] + (queryString && queryString.length > 0) ? queryString :''
// 		let self = this;
// 		this.xmlhttp.onreadystatechange = ()=>{
// 			if(self.xmlhttp.readyState == 4 && self.xmlhttp.status == 200){
// 				func(self.xmlhttp.responseText);
// 			}else{self.handleError();}
// 		};
// 		this.xmlhttp.open("POST", _url, true);
// 		this.xmlhttp.send(formData);
// 	},
// 	handleError:function() {
// 		let _type = this.xmlhttp.status;
// 		let _status = this.xmlhttp.responseText;
// 		if (_type == 401) {

// 		} else if (_type == 521 || _type == 511) {

// 		} else {

// 		}
// 	}
// }

function Utils() {
  this.load = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.setAttribute('id', jsonObj[i].id);
      selectOptions.text = jsonObj[i].name;
      selectOptions.title = jsonObj[i].description;
      selectOptions.value = jsonObj[i].id;
      // selectOptions.setAttribute("data-prj",jsonObj[i]);
      select.options.add(selectOptions);
    }
  };
  this.loadWebServiceSystems = function (select, jsonObj) {
    var selectOptions1 = document.createElement('option');
    selectOptions1.text = '---Select---';
    selectOptions1.value = 'select';
    select.options.add(selectOptions1);
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].value;
      selectOptions.value = jsonObj[i].key;
      select.options.add(selectOptions);
    }
  };
  this.loadRSSystems = function (select, jsonObj) {
    var selectOptions1 = document.createElement('option');
    selectOptions1.text = '---Select---';
    selectOptions1.value = 'select';
    select.options.add(selectOptions1);
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].value;
      selectOptions.value = jsonObj[i].value;
      select.options.add(selectOptions);
    }
  };
  this.loadSystems = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].value;
      selectOptions.value = jsonObj[i].key;
      select.options.add(selectOptions);
    }
  };
  this.loadApps = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].appsKey;
      select.options.add(selectOptions);
    }
  };
  this.loadSalutation = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].value;
      select.options.add(selectOptions);
    }
  };
  this.loadBUSalutation = function (select, jsonObj) {
    var selectOptions1 = document.createElement('option');
    selectOptions1.text = '---Select---';
    selectOptions1.value = 'select';
    select.options.add(selectOptions1);
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].value;
      select.options.add(selectOptions);
    }
  };
  this.loadBGroups = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadDateformat = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadBUDateformat = function (select, jsonObj) {
    var selectOptions1 = document.createElement('option');
    selectOptions1.text = '---Select---';
    selectOptions1.value = 'select';
    select.options.add(selectOptions1);
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadLanguage = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadBULanguage = function (select, jsonObj) {
    var selectOptions1 = document.createElement('option');
    selectOptions1.text = '---Select---';
    selectOptions1.value = 'select';
    select.options.add(selectOptions1);
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadTZ = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadBUTZ = function (select, jsonObj) {
    var selectOptions1 = document.createElement('option');
    selectOptions1.text = '---Select---';
    selectOptions1.value = 'select';
    select.options.add(selectOptions1);
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadSAPList = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name + '$' + jsonObj[i].isEnabled;
      if (!jsonObj[i].isEnabled) selectOptions.className = 'optionb';
      select.options.add(selectOptions);
    }
  };
  this.loadList = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name + '$' + jsonObj[i].isEnabled;
      select.options.add(selectOptions);
    }
  };
  this.loadBIList = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var res = jsonObj[i].name.split(':');
      var selectOptions = document.createElement('option');
      selectOptions.text = res[0];
      selectOptions.value = res[1];
      selectOptions.title = res[0];
      select.options.add(selectOptions);
    }
  };
  this.loadWsTables = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].key;
      selectOptions.value = jsonObj[i].value;
      select.options.add(selectOptions);
    }
  };
  this.loadFreedomTables = function (select, jsonObj) {
    for (var i = 0; i < jsonObj.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[i].name;
      selectOptions.value = jsonObj[i].name;
      select.options.add(selectOptions);
    }
  };
  this.loadFreedomColumns = function (select, jsonObj) {
    for (var key in jsonObj) {
      var selectOptions = document.createElement('option');
      selectOptions.text = key;
      selectOptions.value = key;
      select.options.add(selectOptions);
    }
  };
  this.loadJBList = function (select, jsonObj) {
    for (var key in jsonObj) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObj[key];
      selectOptions.value = key;
      select.options.add(selectOptions);
    }
  };
  this.loadBosList = function (select, jsonObjlist) {
    for (var i = 0; i < jsonObjlist.length; i++) {
      var selectOptions = document.createElement('option');
      var jobj = jsonObjlist[i];
      selectOptions.setAttribute('id', jobj.id);
      selectOptions.text = jobj.name;
      selectOptions.title = jobj.projectName;
      selectOptions.value = jobj.type;
      if (jobj.type == '5003') selectOptions.className = 'optionb';
      select.options.add(selectOptions);
    }
  };
  this.loadRSOperations = function (select, jsonObjlist) {
    for (var i = 0; i < jsonObjlist.length; i++) {
      var selectOptions = document.createElement('option');
      selectOptions.text = jsonObjlist[i];
      selectOptions.title = jsonObjlist[i];
      selectOptions.value = jsonObjlist[i];
      select.options.add(selectOptions);
    }
  };
  this.setSelectedSystem = function (select, sysname) {
    for (var i = 0; i < select.options.length; i++) {
      if (select.options[i].text === sysname) {
        select.selectedIndex = i;
        break;
      }
    }
  };
  this.isEmpty = function (str) {
    return !str || 0 === str.length;
  };
  this.contentLength = function (str) {
    if (str) {
      let lowerCaseLength = str.replace(/[A-Z0-9]/g, '').length;
      let spaceLength = str.replace(/([^\s])+/g, '').length;
      let remainingLength = str.length - lowerCaseLength - spaceLength;
      return lowerCaseLength * 7.25 + remainingLength * 9 + spaceLength * 5;
    } else {
      return 0;
    }
  };
  this.cloneObjs = function (className, input) {
    let work = new Workspace();
    let objects = null;
    if (Array.isArray(input)) {
      objects = [];

      for (let val of input) {
        if (work[className]) {
          let obj = new work[className]();
          objects.push(Object.assign(obj, JSON.parse(JSON.stringify(val))));
        } else {
          objects.push(JSON.parse(JSON.stringify(val)));
        }
      }
    } else if (work[className]) {
      let obj = new work[className]();
      objects = Object.assign(obj, JSON.parse(JSON.stringify(input)));
    } else {
      objects = JSON.parse(JSON.stringify(input));
    }
    return objects;
  };
  this.variableLength = function (str, flag = false, varType, lengthChk = false) {
    if (typeof str == 'string') {
      if (str != '') {
        if (!lengthChk) {
          let val = str.split('$');
          let valLength = 0;
          if (val.length > 1) {
            let pos = 2;
            let lengthCheck = 3;
            if (varType == 'sap') {
              pos = val.length > 4 ? val.length - 2 : val.length - 1;
              lengthCheck = 4;
            }
            valLength = window.utils.contentLength(val[pos]);
            if (flag) valLength = valLength;
            else valLength = valLength + 30 > 50 ? valLength + 30 : 50;
            if (val.length > lengthCheck - 1) {
              valLength += 10;
              if (
                window.application.ui.parameter.checkTable(val[2], val[1]) &&
                val.length <= 3
              )
                valLength += 35;
              if (varType == 'sap') valLength += 35;
            }
            if (val.length > lengthCheck) {
              valLength += 20;
              let text = val[pos + 1] != '_' ? val[pos + 1] : 'select';
              valLength += window.utils.contentLength(text);
              if (val[pos + 1] == '_') valLength += 30;
            }
          } else {
            valLength = window.utils.contentLength(str);
            if (flag) valLength = valLength;
            else valLength = valLength + 30 > 50 ? valLength + 30 : 50;
          }
          return valLength;
        } else {
          let valLength = window.utils.contentLength(str);
          if (flag) valLength = valLength;
          else valLength = valLength + 30 > 50 ? valLength + 30 : 50;
          return valLength;
        }
      } else if (str == '') {
        return 50;
      }
    } else if (str && typeof str == 'object') {
      str.shape.calculateHeight(str);
      return str.shape.Width;
    } else {
      return 0;
    }
  };
  this.variableSlice = function (str) {
    if (typeof str == 'string') {
      if (str.endsWith('$_')) str = str.substring(0, str.length - 2);
    } else {
      str = str.constructParams();
    }
    return str;
  };
  this.chunkString = function (str, length) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
  };
  this.getVariableName = function (str) {
    if (typeof str == 'string') {
      let _temp = str.split('$');
      let _val = str;
      if (_temp.length > 0) _val = _temp[_temp.length - 1];
      return _val;
    }
    return str;
  };
  this.getQueryColumns = function (str, chk) {
    let arr = new Array();
    if (str && str.trim() != '') {
      let searchPattern = null;
      if (chk){
        searchPattern = new RegExp('^(\'select|"select) ', 'i');
      }else
        searchPattern = new RegExp('^select ', 'i');
      if (searchPattern.test(str)) {
        if (chk)
          str = str.substring(1, str.length);
        let expr = new ExprParser();
        arr = expr.parseSelectQry(str);
      }
      // else {
      // 	window.application.handleErrorMessage("Error",
      // 			"Only Select query can be allowed");
      // }
    }
    return arr;
  };
  this.repeatStringNumTimes = function (string, times) {
    let repeatedString = '';
    while (times > 0) {
      repeatedString += string;
      times--;
    }
    return repeatedString;
  };
  this.updateSystem = function (node, old, nw) {
    if (node.childs) {
      for (var i = 0; i < node.childs.length; i++) {
        node.childs[i].updateSystem(old, nw);
        this.updateSystem(node.childs[i], old, nw);
      }
    }
    if (node.nodes) {
      for (var i = 0; i < node.nodes.length; i++) {
        node.nodes[i].updateSystem(old, nw);
        this.updateSystem(node.nodes[i], old, nw);
      }
    }
  };
  this.toJSON = function (object, isGlobal) {
    var obj = new Object();
    obj.field = new Array();
    obj.table = new Array();
    for (var key in object) {
      var o = object[key];
      //console.log(o["Field"]);
      if (o['field']) {
        var table = new Object();
        table.type = 'Table';
        if (isGlobal) {
          table.name = key;
          table.Xpath = o.Xpath;
        } else {
          table.name = o.Xpath;
          table.Xpath = key;
        }
        table.Expression = o.Expression;
        table.Description = '';
        table.mapping = o.mapping;
        table.filter = o.filter;
        if (o.Description) table.Description = o.Description;
        var tab = o['field'];
        var flds = this.toTableJSON(tab, isGlobal);
        table.field = flds;
        obj.table.push(table);
      } else {
        var f = new Object();
        if (isGlobal) {
          f.name = key;
          f.key = o.Xpath;
          if (o.isSortedCol) {
            f.isSortedCol = o.isSortedCol;
            f.isAsc = o.isAsc;
          }
        } else {
          f.name = o.Xpath;
          f.key = key;
        }
        f.Expression = o.Expression;
        f.Description = '';
        f.mapping = o.mapping;
        if (o.Description) f.Description = o.Description;
        f.value = o.value;
        f.dataType = o.dataType;
        obj.field.push(f);
      }
    }
    return obj;
  };
  this.fromJSON = function (object, isGlobal) {
    var obj = new Object();
    if (object instanceof Object) {
      if (object.field) {
        if (object.field instanceof Array) {
          for (var i = 0; i < object.field.length; i++) {
            var f = new Object();
            var o = object.field[i];
            var k;
            if (isGlobal) {
              f.Xpath = o.key;
              k = o.name;
            } else {
              f.Xpath = o.name;
              k = o.key;
            }
            f.Xpath = o.name;
            if (o.value) f.value = this.encode(o.value);
            else f.value = '';
            f.dataType = o.dataType;
            f.Expression = o.Expression;
            f.Description = '';
            f.mapping = o.mapping;
            f.filter = o.filter;
            if (o.Description) f.Description = o.Description;
            if (o.expr) f.Expression = o.expr;
            if (!o.key) {
              k = o.name;
              f.name = o.name;
            }
            obj[k] = f;
          }
        } else {
          var f = new Object();
          var o = object.field;
          var k;
          if (isGlobal) {
            f.Xpath = o.key;
            k = o.name;
          } else {
            f.Xpath = o.name;
            k = o.key;
          }
          f.Xpath = o.name;
          if (o.value) f.value = this.encode(o.value);
          else f.value = '';
          f.dataType = o.dataType;
          f.Expression = o.Expression;
          f.Description = '';
          f.mapping = o.mapping;
          if (o.Description) f.Description = o.Description;
          if (o.expr) f.Expression = o.expr;
          if (!o.key) {
            k = o.name;
            f.name = o.name;
          }
          obj[k] = f;
        }
      }
      if (object.table)
        if (object.table instanceof Array) {
          for (var i = 0; i < object.table.length; i++) {
            var o = object.table[i];
            var table = new Object();
            var n = '';
            if (isGlobal) {
              table.Xpath = o.Xpath;
              n = o.name;
            } else {
              table.Xpath = o.name;
              n = o.Xpath;
            }
            table.Expression = o.Expression;
            table.Description = '';
            table.mapping = o.mapping;
            if (o.Description) table.Description = o.Description;
            if (o.expr) table.Expression = o.expr;
            table['Field'] = this.fromTableJSON(o.field, isGlobal);
            obj[n] = table;
          }
        } else {
          var o = object.table;
          var table = new Object();
          var n = '';
          if (isGlobal) {
            table.Xpath = o.Xpath;
            n = o.name;
          } else {
            table.Xpath = o.name;
            n = o.Xpath;
          }
          table.Expression = o.Expression;
          table.Description = '';
          table.mapping = o.mapping;
          if (o.Description) table.Description = o.Description;
          if (o.expr) table.Expression = o.expr;
          table['Field'] = this.fromTableJSON(o.field, isGlobal);
          obj[n] = table;
        }
    }
    return obj;
  };
  this.toTableJSON = function (obj, isGlobal) {
    var flds = new Array();
    for (var key in obj) {
      var o = obj[key];
      if (isGlobal) {
        //const _key  = o.key;
        //o.name = _key;
        o.key = o.Xpath;
      } else {
        o.name = o.Xpath;
        o.Expression = o.value;
      }
      if (!o.isSortedCol) {
        o.isAsc = false;
        o.isSortedCol = false;
      }
      flds.push(o);
    }
    return flds;
  };
  this.fromTableJSON = function (obj, isGlobal) {
    var fld = new Object();
    if (obj instanceof Array) {
      for (var i = 0; i < obj.length; i++) {
        var o = obj[i];
        var f = new Object();
        var k;
        if (isGlobal) {
          f.Xpath = o.key;
          k = o.name;
        } else {
          f.Xpath = o.name;
          k = o.key;
        }
        f.name = o.name;
        f.value = o.value;
        f.dataType = o.dataType;
        f.Expression = o.Expression;
        f.Description = '';
        f.mapping = o.mapping;
        if (o.Description) f.Description = o.Description;
        if (o.expr) f.Expression = o.expr;
        if (!o.key) {
          k = o.name;
          f.name = o.name;
        }
        fld[k] = f;
      }
    } else {
      var o = obj;
      var f = new Object();
      var k;
      if (isGlobal) {
        f.Xpath = o.key;
        k = o.name;
      } else {
        f.Xpath = o.name;
        k = o.key;
      }
      f.name = o.name;
      f.value = o.value;
      f.dataType = o.dataType;
      f.Expression = o.Expression;
      f.Description = '';
      f.mapping = o.mapping;
      if (o.Description) f.Description = o.Description;
      if (o.expr) f.Expression = o.expr;
      if (!o.key) {
        k = o.name;
        f.name = o.name;
      }
      fld[k] = f;
    }
    return fld;
  };
  this.createNode = function (caption, x, y, type, clazz = 0, meta = 0) {
    var node;
    switch (type) {
      case 'func':
        node = new SapFunctionNode(caption, x, y, type);
        break;
      case 'trans':
        node = new SapTransactionNode(caption, x, y, type);
        break;
      case 'strans':
        node = new SapStopTransactionNode(caption, x, y, type);
        break;
      case 'cmt':
        node = new SapCommitNode(caption, x, y, type);
        break;
      case 'rbk':
        node = new SapRollbackNode(caption, x, y, type);
        break;
      case 'a':
        node = new AssignmentNode(caption, x, y, type);
        break;
      case 'm':
        node = new MathNode(caption, x, y, type);
        break;
      case 'c':
        node = new ConditionalNode(caption, x, y, type);
        break;
      case 'la':
        node = new ConditionalNode(caption, x, y, type);
        break;
      case 'ie':
        node = new IfElseNode(caption, x, y, type);
        break;
      case 'if':
        node = new IfNode(caption, x, y, type);
        break;
      case 'lp':
        node = new LoopNode(caption, x, y, type);
        break;
      case 'rp':
        node = new RepeaterNode(caption, x, y, type);
        break;
      case 't':
        node = new TerminateNode(caption, x, y, type);
        break;
      case 'b':
        node = new BreakNode(caption, x, y, type);
        break;
      case 'ar':
        node = new AddRowNode(caption, x, y, type);
        break;
      case 'ws':
        node = new WebServiceNode(caption, x, y, type);
        break;
      case 'rs':
        node = new RestServiceNode(caption, x, y, type);
        break;
      case 'arb':
          node = new RestServiceNode(caption, x, y, type);
          break;  
      case 'kr':
          node = new RestServiceNode(caption, x, y, type);
          break;
      case 'shp':
          node = new RestServiceNode(caption, x, y, type);
          break;     
      case 'sfl':
        node = new SFListNode(caption, x, y, type);
        break;
      case 'sfc':
        node = new SFCreateNode(caption, x, y, type);
        break;
      case 'sfu':
        node = new SFUpdateNode(caption, x, y, type);
        break;
      case 'sfd':
        node = new SFDeleteNode(caption, x, y, type);
        break;
      case 'lk':
        node = new LookupNode(caption, x, y, type);
        break;
      case 'srt':
        node = new SortNode(caption, x, y, type);
        break;
      case 'boss':
        node = new BossNode(caption, x, y, type);
        break;
      case 'custom':
        node = new CustomNode(caption, x, y, type, clazz, meta);
        break;
      case 'mail':
        node = new MailNode(caption, x, y, type);
        break;
      case 'push':
        node = new PushNode(caption, x, y, type);
        break;
      case 'devsync':
        node = new DeviceSyncNode(caption, x, y, type);
        break;
      case 'msdl':
        node = new MSDListNode(caption, x, y, type);
        break;
      case 'msdc':
        node = new MSDCreateNode(caption, x, y, type);
        break;
      case 'msdu':
        node = new MSDUpdateNode(caption, x, y, type);
        break;
      case 'msdd':
        node = new MSDDeleteNode(caption, x, y, type);
        break;
      case 'gf':
        node = new GenerateFileNode(caption, x, y, type);
        break;
      case 'dupl':
        node = new DriveUploadNode(caption, x, y, type);
        break;
      case 'ddnl':
        node = new DriveDownloadNode(caption, x, y, type);
        break;
      case 'ddel':
        node = new DriveDeleteNode(caption, x, y, type);
        break;
      case 'dmov':
        node = new DriveMoveNode(caption, x, y, type);
        break;
      case 'dcpy':
        node = new DriveCopyNode(caption, x, y, type);
        break;
      case 'dlst':
        node = new DriveListNode(caption, x, y, type);
        break;
      case 'ftpdl':
        node = new FTPDownloadNode(caption, x, y, type);
        break;
      case 'fp':
        node = new FileParserNode(caption, x, y, type);
        break;
      case 'ftp':
        node = new FtpNode(caption, x, y, type);
        break;
      case 'cbu':
        node = new CreateUserNode(caption, x, y, type);
        break;
      case 'ubu':
        node = new UpdateUserNode(caption, x, y, type);
        break;
      case 'abug':
        node = new AssignGroupNode(caption, x, y, type);
        break;
      case 'rgrp':
        node = new RemoveGroupNode(caption, x, y, type);
        break;
      case 'dl':
        node = new BluetoothListNode(caption, x, y, type);
        break;
      case 'listener':
        node = new BluetoothListenerNode(caption, x, y, type);
        break;
      case 'bcs':
        node = new BarcodeScannerNode(caption, x, y, type);
        break;
      case 'con':
        node = new BluetoothConnectNode(caption, x, y, type);
        break;
      case 'dc':
        node = new BluetoothDisconnectNode(caption, x, y, type);
        break;
      case 'pair':
        node = new BluetoothPairingNode(caption, x, y, type);
        break;
      case 'unpair':
        node = new BluetoothUnpairingNode(caption, x, y, type);
        break;
      case 'com':
        node = new BluetoothCommandNode(caption, x, y, type);
        break;
      case 'filed':
        node = new DownloadFile(caption, x, y, type);
        break;
      case 'fdbselect':
        node = new FreedomDBSelectNode(caption, x, y, type);
        break;
      case 'fdbcreate':
        node = new FreedomDBCreateNode(caption, x, y, type);
        break;
      case 'fdbdelete':
        node = new FreedomDBDeleteNode(caption, x, y, type);
        break;
      case 'fdbupdate':
        node = new FreedomDBUpdateNode(caption, x, y, type);
        break;
      case 'dbaselect':
        node = new DBAdapterSelectNode(caption, x, y, type);
        break;
      case 'dbacreate':
        node = new DBAdapterCreateNode(caption, x, y, type);
        break;
      case 'dbadelete':
        node = new DBAdapterDeleteNode(caption, x, y, type);
        break;
      case 'dbaupdate':
        node = new DBAdapterUpdateNode(caption, x, y, type);
        break;
      case 'snfselect':
        node = new SNFSelectNode(caption, x, y, type);
        break;
      case 'snfcreate':
        node = new SNFCreateNode(caption, x, y, type);
        break;
      case 'snfdelete':
        node = new SNFDeleteNode(caption, x, y, type);
        break;
      case 'snfupdate':
        node = new SNFUpdateNode(caption, x, y, type);
        break;
      case 'fun':
        node = new FunctionNode(caption, x, y, type);
        break;
      case 'fdbQry':
        node = new FreedomDBQueryNode(caption, x, y, type);
        break;
      case 'fdbQryNP':
        node = new FreedomDBQuerywithoutParamNode(caption, x, y, type);
        break;
      case 'dbQry':
        node = new DBAdapterQueryNode(caption, x, y, type);
        break;
      case 'dbQryNP':
        node = new DBAdapterQueryNode(caption, x, y, type);
        break;
      case 'commt':
        node = new CommentBlockNode(caption, x, y, type);
        break;
      case 'pdf':
        node = new PdfGenerateNode(caption, x, y, type);
        break;
      case 'fma':
        node = new FMAuthNode(caption, x, y, type);
        break;
      case 'oath':
        node = new OAuthAuthenticationeNode(caption, x, y, type);
        break;
      case 'samlAth':
        node = new SamlAuthenticationNode(caption, x, y, type);
        break;
      case 'sapAth':
        node = new SapAuthenticationNode(caption, x, y, type);
        break;
      case 'odl':
        node = new ODataListNode(caption, x, y, type);
        break;
      case 'odc':
        node = new ODataCreateNode(caption, x, y, type);
        break;
      case 'odu':
        node = new ODataUpdateNode(caption, x, y, type);
        break;
      case 'odd':
        node = new ODataDeleteNode(caption, x, y, type);
        break;
      case 'cfq':
        node = new ConflictQueueNode(caption, x, y, type);
        break;
      case 'rst':
        node = new WorkflowRestartNode(caption, x, y, type);
        break;
      case 'rty':
        node = new WorkflowRetryNode(caption, x, y, type);
        break;
      case 'end':
        node = new WorkflowEndNode(caption, x, y, type);
        break;
      case 'contd':
        node = new WorkflowContinueNode(caption, x, y, type);
        break;
      case 'revrt':
        node = new WorkflowRevertNode(caption, x, y, type);
        break;  
      case 'arch':
        node = new WorkflowArchiveNode(caption,x,y,type);
        break;
      case 'updt':
          node = new WorkflowUpdateNode(caption, x, y, type);
          break;  
      case 'ulist':
        node = new UserListNode(caption,x,y,type);
        break;
      case 'udet':
        node = new UserDetailNode(caption,x,y,type);
        break;
      // case "fct":
      // 	node = new FunctionNode1(caption, x, y, type);
      // 	break;
      default:
        node = new LaneNode(caption, x, y, type);
        break;
    }
    return node;
  };
  this.createBOSNode = function (caption, x, y, type, img) {
    var node;
    // console.log('type :' + type);
    switch (type) {
      case 'sapFunction':
        node = new SapFunctionNode(caption, x, y, type);
        break;
      case 'startTransaction':
        node = new SapTransactionNode(caption, x, y, type);
        break;
      case 'assignment':
        node = new AssignmentNode(caption, x, y, type);
        break;
      case 'condition':
        node = new IfElseNode(caption, x, y, type);
        break;
      case 'loop':
        node = new LoopNode(caption, x, y, type);
        break;
      case 'repeater':
        node = new RepeaterNode(caption, x, y, type);
        break;
      case 'terminate':
        node = new TerminateNode(caption, x, y, type);
        break;
      case 'Break':
        node = new BreakNode(caption, x, y, type);
        break;
      case 'wsList':
        node = new WebServiceNode(caption, x, y, type);
        break;
      case 'rsList':
        node = new RestServiceNode(caption, x, y, type);
        break;
      case 'sapCommit':
        node = new SapCommitNode(caption, x, y, type);
        break;
      case 'sapRollback':
        node = new SapRollbackNode(caption, x, y, type);
        break;
      case 'lookup':
        node = new LookupNode(caption, x, y, type);
        break;
      case 'sort':
        node = new SortNode(caption, x, y, type);
        break;
      case 'bosService':
        node = new BossNode(caption, x, y, type);
        break;
      case 'mscrmList':
        node = new MSDListNode(caption, x, y, type);
        break;
      case 'mscrmCreate':
        node = new MSDCreateNode(caption, x, y, type);
        break;
      case 'mscrmUpdate':
        node = new MSDUpdateNode(caption, x, y, type);
        break;
      case 'mscrmDelete':
        node = new MSDDeleteNode(caption, x, y, type);
        break;
      case 'genFile':
        node = new GenerateFileNode(caption, x, y, type);
        break;
      case 'driveUpload':
        node = new DriveUploadNode(caption, x, y, type);
        break;
      case 'driveDownload':
        node = new DriveDownloadNode(caption, x, y, type);
        break;
      case 'driveDelete':
        node = new DriveDeleteNode(caption, x, y, type);
        break;
      case 'driveMove':
        node = new DriveMoveNode(caption, x, y, type);
        break;
      case 'driveCopy':
        node = new DriveCopyNode(caption, x, y, type);
        break;
      case 'ftpDownload':
        node = new FTPDownloadNode(caption, x, y, type);
        break;
      case 'fparser':
        node = new FileParserNode(caption, x, y, type);
        break;
      case 'createUser':
        node = new CreateUserNode(caption, x, y, type);
        break;
      case 'updateUser':
        node = new UpdateUserNode(caption, x, y, type);
        break;
      case 'assignGroup':
        node = new AssignGroupNode(caption, x, y, type);
        break;
      case 'btlist':
        node = new BluetoothListNode(caption, x, y, type);
        break;
      case 'btlistener':
        node = new BluetoothListenerNode(caption, x, y, type);
        break;
      case 'sbcs':
        node = new BarcodeScannerNode(caption, x, y, type);
        break;
      case 'btconnect':
        node = new BluetoothConnectNode(caption, x, y, type);
        break;
      case 'btdisconnect':
        node = new BluetoothDisconnectNode(caption, x, y, type);
        break;
      case 'btpair':
        node = new BluetoothPairingNode(caption, x, y, type);
        break;
      case 'btunpair':
        node = new BluetoothUnpairingNode(caption, x, y, type);
        break;
      case 'btcom':
        node = new BluetoothCommandNode(caption, x, y, type);
        break;
      case 'downloadFile':
        node = new DownloadFile(caption, x, y, type);
        break;
      case 'workflowRestart':
        node = new WorkflowRestartNode(caption, x, y, type);
        break;
      case 'workflowRetry':
        node = new WorkflowRetryNode(caption, x, y, type);
        break;
      case 'workflowRevert':
          node = new WorkflowRevertNode(caption, x, y, type);
          break;
      case 'workflowEnd':
          node = new WorkflowEndNode(caption, x, y, type);
          break;
      case 'workflowContinue':
          node = new WorkflowContinueNode(caption, x, y, type);
          break;  
      case 'workflowArchive':
        node = new WorkflowArchiveNode(caption,x,y,type);
        break;  
      case 'workflowUpdate':
        node = new WorkflowUpdateNode(caption, x, y, type);
        break;  
      case 'usrList':
        node = new UserListNode(caption,x,y,type);
        break;  
      case 'usrdtl':
        node= new UserDetailNode(caption,x,y,type);
        break;
      default:
        // node = new SequenceNode(caption, x, y, type);
        break;
    }
    node.shape.setImage(img);
    return node;
  };
  this.createDoc = function (xml) {
    var parser = new DOMParser();
    return parser.parseFromString(xml, 'text/xml');
  };
  this.constructObject = function (name, value) {
    var obj = new Object();
    let objName =
      name.split('$').length > 0
        ? name.split('$')[name.split('$').length - 1]
        : name;
    obj.name = objName;
    //obj.Xpath = "$" + name;
    //obj.Expression = "\"" + value + "\"";
    obj.Xpath = name;
    obj.Expression = value;
    obj.value = window.application.parse(value);
    return obj;
  };
  this.constructObj = function (name, value) {
    var obj = new Object();
    let objName =
      name.split('$').length > 0
        ? name.split('$')[name.split('$').length - 1]
        : name;
    obj.name = objName;
    //obj.Xpath = "$" + name;
    //obj.Expression = "\"" + value + "\"";
    obj.Xpath = name;
    obj.Expression = value;
    obj.value = 'LIT(' + value + ')';
    return obj;
  };
  this.constructObjectWithoutParse = function (name, value) {
    var obj = new Object();
    let objName =
      name.split('$').length > 0
        ? name.split('$')[name.split('$').length - 1]
        : name;
    obj.name = objName;
    //obj.Xpath = "$" + name;
    //obj.Expression = "\"" + value + "\"";
    obj.dataType = "String";
    obj.Xpath = name;
    obj.Expression = value;
    obj.value = value;
    return obj;
  };
  this.encode = function (strVal) {
    /*
     * if (strVal.indexOf('&') > -1) { var searchStr = "&"; var replaceStr =
     * "%26"; var re = new RegExp(searchStr, "g"); var result =
     * strVal.replace(re, replaceStr); } else { var result = strVal; }
     */

    return strVal;
  };
  this.removeSpaces = function (strObj) {
    strObj = strObj.replace(/\s/g, '');
    return strObj.toLowerCase();
  };
  this.loadActions = function () {
    // Service("data={}&type=FileService&action=getActions", function () {
    // 	window.application.loader.show();
    // 	if (this.readyState == this.DONE) {
    // 		if (this.status == 200) {
    // 			var obj = JSON.parse(this.responseText);
    // 			var objlen = 0;
    // 			for (var key in obj) {
    // 				++objlen;
    // 			}
    // 			if (objlen > 0) {
    // 				window.application.addActions(obj);
    // 			}
    // 		} else {
    // 			this.handleError(this.status, this.responseText);
    // 		}
    // 		window.application.loader.hide();
    // 	}
    // });
  };
  this.find = function (obj) {
    for (var key in obj)
      if (
        key == 'condition' ||
        key == 'repeater' ||
        key == 'terminate' ||
        key == 'row' ||
        key == 'assignment' ||
        key == 'loop' ||
        key == 'sort' ||
        key == 'lookup' ||
        key == 'Break' ||
        key == 'sapBI' ||
        key == 'sfList' ||
        key == 'sfCreate' ||
        key == 'sfDelete' ||
        key == 'sfUpdate' ||
        key == 'reference' ||
        key == 'sapFunction' ||
        key == 'startTransaction' ||
        key == 'endTransaction' ||
        key == 'sapCommit' ||
        key == 'sapRollback' ||
        key == 'sapProgram' ||
        key == 'wsList' ||
        key == 'rsList' ||
        key == 'jbList' ||
        key == 'sequence' ||
        key == 'bosService' ||
        key == 'fdbList' ||
        key == 'fdbCreate' ||
        key == 'fdbUpdate' ||
        key == 'fdbDelete' ||
        key == 'fdbOfflineList' ||
        key == 'fdbBulkData' ||
        key == 'dbList' ||
        key == 'dbCreate' ||
        key == 'dbUpdate' ||
        key == 'dbDelete' ||
        key == 'catalog' ||
        key == 'spr' ||
        key == 'mail' ||
        key == 'push' ||
        key == 'mscrmList' ||
        key == 'mscrmCreate' ||
        key == 'mscrmDelete' ||
        key == 'mscrmUpdate' ||
        key == 'genFile' ||
        key == 'driveUpload' ||
        key == 'driveDownload' ||
        key == 'driveDelete' ||
        key == 'driveMove' ||
        key == 'driveCopy' ||
        key == 'ftpDownload' ||
        key == 'fparser' ||
        key == 'devsync' ||
        key == 'createUser' ||
        key == 'assignGroup' ||
        key == 'btlist' ||
        key == 'btlistener' ||
        key == 'btconnect' ||
        key == 'btdisconnect' ||
        key == 'btpair' ||
        key == 'btunpair' ||
        key == 'btcom' ||
        key == 'updateUser' ||
        key == 'downloadFile' ||
        key == 'sbcs' ||
        key == 'workflowRestart' ||
        key == 'workflowRetry' ||
        key == 'workflowRevert' ||
        key == 'workflowContinue' ||
        key == 'workflowEnd'||
        key == 'workflowArchive'||
        key == 'workflowUpdate'||
        key == 'usrList' ||
        key == 'usrdtl'
      )
        return obj[key];
    return null;
  };
}
function doLogOut() {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '.././logout', false);
  xhr.onreadystatechange = function () {
    if (this.readyState == this.DONE) {
      if (this.status == 200) {
        window.opener.location.replace(sessionStorage.prdUrl);
        sessionStorage.clear();
        localStorage.clear();
        window.close();
      } else {
        //handleError(this);
        return;
      }
    }
  };
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.send(null);
}

function util() {
  this.fromJSON = function (variables, global) {
    return { variables, global };
  };
  this.toJSON = function (variables, global) {
    return { variables, global };
  };
}

function utils() {
  this.createNode = function (a, b, c, d) {
    return a, b, c, d;
  };
}

function listLanguage (){
  const data = { service: 'getLanguages' };
  const params = {
    uri: '/configurator/v1.0/masters/values/LAN/list',
    data: data,
  };
  let list = {};
  DataService.read(params).then((result) => {
    for (let data of result.data.data){
      list[data.name] = "'" + data.code + "'";
    }
  });
  return list;
}

export {
  //Service,
  utils,
  util,
  doLogOut,
  Utils,
  listLanguage
};
