import React from "react";
import { useEffect, useState } from "react";
import MetadataBox from "./metadataBox";
import { ServiceActions } from "../services.actions";
import styles from "./transport.module.css";
import { useAppContext } from "../../../../components/activity/AppContext";

export default function TransportMetadata(props) {
    const { setRenderComponent, serviceData, transportService } = props;
    const [showData, setShowData] = useState([]);
    const app = useAppContext();

    const getTransportMetadata = async () => {
        const actions = ServiceActions(app());
        const sendData = { projectName: serviceData.projectName, version: transportService.item.version, uuid: serviceData.serviceId }
        const [error, data] = await actions.getTranasportMeta(sendData)

        if (!error) {
            getMetadataUrl(data.url)
        }
    }

    const getMetadataUrl = async (url) => {
        const result = await fetch(url);
        const res = await result.json();
        const saveRes = [];
        for (let dt in res) {
            if (typeof res[dt] === "object") {
                saveRes.push({ [dt]: res[dt] })
            }
        }
        for (let dt of saveRes) {
            mapingMetadata(dt, Object.keys(dt))
        }
    }

    const mapingMetadata = (data, name) => {
        let tempData = [];
        const title = name[0];
        const dataObj = data[title]
        if (title !== "tables") {
            let content = dataObj.map(item => { return <div key={item} style={{ padding: "5px" }}><span>{item}</span></div> });
            tempData.push(
                <div className={styles.optionCont} key={title}>
                    <MetadataBox
                        data={
                            { name: `${capitalize(title)} ${dataObj.length}`, showContent: false, content: content }
                        }
                    />
                </div>)
        } else if (title === "tables") {
            let content = dataObj.map((item) => {
                return (<MetadataBox key={item.tableName} data={{
                    name: item.tableName,
                    showContent: false,
                    content: item.queries.map(dt => { return (<div key={dt}><span>{dt}</span></div>) })
                }} />)
            })
            tempData.push(
                <div className={styles.optionCont} key={title}>
                    <MetadataBox data={{
                        name: `${capitalize(title)} ${dataObj.length}`,
                        showContent: false,
                        content: content
                    }} />
                </div>
            )
        }

        setShowData((val) => ([...val, tempData]));
    }

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        getTransportMetadata();
    }, [])

    const handleBack = () => {
        setRenderComponent("TransportForm");
    }

    const handleTransport = () => {
        setRenderComponent("TransportProject");
    }

    return (
        <div>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{`Transporting ${serviceData.name} to Quality`}</h2>
            </div>
            <div className={styles.metadataCont}>
                {showData}
            </div>
            <div className={styles.btnCont}>
                <input
                    type="button"
                    className="secondary"
                    value={"Back"}
                    onClick={handleBack}
                    data-clickable-testid={"Back"}
                />
                <input
                    type="button"
                    className="primary"
                    value={"Transport"}
                    onClick={handleTransport}
                    data-clickable-testid={"Transport"}
                />
            </div>
        </div>
    )
}
