import { useAppContext } from "../../components/activity/AppContext";
import { ModuleTitle } from "../../components/module-title";
import React, { useEffect, useState } from "react";
import { DriveActions } from "./drive.actions";
import DriveRecentFiles from "./recentFiles";
import { useLocation, useParams } from "react-router-dom";
import { messages } from "./messages";
import UploadFile from "./uploadFile";
import NewFolder from "./newFolder";
import DrivePaths from "./paths";
import DriveList from "./list";
import Tabs from "../../components/tab/tabs";
import styles from "./index.module.css";

function Drive({perm}) {

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [libPagination, setLibPagination] = useState({ offset: 0, limit: 10 });
    const [list, setList] = useState({ total: 0, data: [] });
    const [libraryList, setLibraryList] = useState({ total: 0, data: [] });
    const [selectedPath, setSelectedPath] = useState([]);
    const [libSelectedPath, setLibSelectedPath] = useState([]);
    const [showModal, setShowModal] = useState("");
    const [filter, setFilter] = useState({});
    const [libFilter, setLibFilter] = useState({});
    const { id: projectName } = useParams();
    const app = useAppContext();
    const [folderList, setFolderList] = useState([]);
    const location = useLocation()
    location.state = {permission:perm}

    const getList = () => {
        const getAll = async () => {
            let path = "";
            let finalFilter = { ...filter };
            // if (projectName) {
                finalFilter["version"] = { value: 1, op: "LE" };
                finalFilter["parentId"] = { value: 0, op: "EQ" };
            // }
            if (selectedPath.length > 0) {
                let current = selectedPath.at(-1);
                path = current?.path;
                finalFilter["parentId"] = { value: current.id, op: "EQ" };
                //setPagination({ offset: 0, limit: 10 }) re-rendering recursively  
            }
            const drive = DriveActions(app());
            let [err, data] = await drive.GetFolders(projectName, path, pagination, finalFilter);
            if (!err && !data.error) {
                let updatedData=updateSize(data.data)
                setList({
                    data: updatedData,
                    total: data.noOfRecords || 0
                });
            }

            let [err1, data1] = await drive.GetAllFolders(projectName);
            if (!err1 && !data1.error) {
                setFolderList(data1.data);
            }
        }
        getAll();
    }

    const getLibList = ()=>{
        const getAll = async () => {
            let libPath="";
            let finalLibFilter = { ...libFilter };
            if (libSelectedPath.length > 0) {
                let current = libSelectedPath.at(-1);
                libPath = current?.path;
                finalLibFilter["parentId"] = { value: current.id, op: "EQ" };
            }
            const drive = DriveActions(app());
            if(projectName){
                let [err1, data1] = await drive.GetProjectsLibraryFolders(projectName, libPath, libPagination, finalLibFilter);
                if (!err1 && !data1.error) {
                    let updatedData=updateSize(data1.data)
                    setLibraryList({
                        data: updatedData,
                        total: data1.noOfRecords || 0
                    });
                }
            }else{
                let [err1, data1] = await drive.GetLibraryFolders(libPagination, finalLibFilter);
                if (!err1 && !data1.error) {
                    let updatedData=updateSize(data1.data)
                    setLibraryList({
                        data: updatedData,
                        total: data1.noOfRecords || 0
                    });
                }
            }
        }
        getAll();
    }

    const updateSize = (data)=>{
        let tempFiles = []
        for (let file of data) {
            let tempFileSize = file.size;
            if(tempFileSize==0) tempFileSize = "0";
            else if (tempFileSize < 1023) tempFileSize = file.size + " B";
            else tempFileSize = Math.round(file.size / 1024) + " KB";
            tempFiles.push({ ...file, size: tempFileSize });
        }
        return tempFiles
    }
    useEffect(()=>{
        setPagination({ offset: 0, limit: 10 })
    },[selectedPath]);
    useEffect(getList, [ pagination, filter, DriveList, DriveRecentFiles]);
    useEffect(()=>{
        setLibPagination({ offset: 0, limit: 10 })
    },[libSelectedPath]);
    useEffect(getLibList, [ libPagination, libFilter, DriveList, DriveRecentFiles]);

    return (
        <>
                <div>
                <Tabs headerStyle={""} canOpen={false} >
                        <div label="Files" tId="Files" name={"Files"} sid={{marginLeft:'45%'}} fileIcon={true} className={styles.tabsInnerCon}>
                        <ModuleTitle title={messages[projectName ? "drive" : "sharedDrive"]}>
                        {
                            location.state.permission?.canCreate
                            ?<>
                                <button data-clickable-testid="new" className='secondary' onClick={() => setShowModal("new")}>
                                    {messages.newFolder}
                                </button>
                                <button data-clickable-testid="upload" className='primary' onClick={() => setShowModal("upload")}>
                                    {messages.uploadFile}
                                </button>
                            </>
                            :<></>
                        }
                        </ModuleTitle>
                        <div className="afModule">
                            {
                                !selectedPath.length &&
                                <DriveRecentFiles getList={getList} projectName={projectName} />
                            }
                            {
                                !!selectedPath.length &&
                                <DrivePaths
                                    selectedPath={selectedPath}
                                    setSelectedPath={setSelectedPath}
                                />
                            }
                            <DriveList
                                list={list}
                                getList={getList}
                                setFilter={setFilter}
                                pagination={pagination}
                                isLibrary={projectName?false:true}
                                projectName={projectName}
                                setPagination={setPagination}
                                setSelectedPath={setSelectedPath}
                                name={"file-folder-list"}
                                folderList={folderList}
                            />
                            </div>
                        </div>
                        <div label={'Library'} tId="Library" name={"Library"} libraryIcon={true} className={styles.tabsInnerCon}>
                            <DriveList
                                list={libraryList}
                                getList={getLibList}
                                setFilter={setLibFilter}
                                pagination={libPagination}
                                isLibrary={projectName?false:true}
                                projectName={projectName}
                                setPagination={setLibPagination}
                                setSelectedPath={setLibSelectedPath}
                                name={"file-folder-list-library"}
                            />
                        </div>
                </Tabs>
                </div>
            {
                showModal === "new" &&
                <NewFolder
                    getList={getList}
                    setShow={setShowModal}
                    projectName={projectName}
                    show={showModal === "new"}
                    selectedPath={selectedPath}
                />
            }
            <UploadFile
                getList={getList}
                setShow={setShowModal}
                projectName={projectName}
                selectedPath={selectedPath}
                show={showModal === "upload"}
            />
        </>
    );
}

export default Drive;