import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Container, Box, Grid } from '@material-ui/core';
import Table from '../../../../../common/components/Table';
import { TablePagination, TableContainer } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getPermissions } from '../../../../../utils/common';

export default function (props) {
  const { changePagination = () => null, permissions = {} } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [queryValues, setQueryValues] = useState({});
  const [targetValue, setTargetValue] = useState(false);
  const [clearInputFields, setClearInputFields] = useState(false);
  const { variableData } = props;
  const classes = useStyles();

  const headCells = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      type: 'text',
    },
    {
      id: 'value',
      label: 'Value',
      align: 'left',
      type: 'text',
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
      type: 'text',
    },
    {
      id: 'createdUser',
      label: 'Created by',
      align: 'left',
      type: 'dropdown',
      isMultiSelect: false,
      placeHolder: 'Created by',
      dropdownItems: props.variableDropDownList,
    },
    {
      id: 'checkbox',
      label: 'checkbox',
      align: 'left',
      type: 'checkbox_with_menu',
      menuStyle: { paddingRight: '16px', marginLeft: 'auto' },
      style: { marginLeft: 'auto', width: '106px', paddingRight: '8px' },
    },
  ];

  const deleteData = {
    title: `Confirmation`,
    text: `Are you sure you want to delete?`,
    action: 'delete',
  };

  const dropdownData = () => {
    let array = [];
    if (getPermissions()?.library?.variables?.canUpdate=== true) {
    array.push({ id: 1, title: 'Edit', selected: false, key: 'edit' });
    }
    if (getPermissions()?.library?.variables?.canDelete === true) {
    array.push({ id: 2, title: 'Delete', selected: false, key: 'delete' });
    }
    return array;
  };

  const headerDropDownData = () => {
    let array = [];
    if (getPermissions()?.library?.variables?.canDelete === true) {
    array.push({ id: 1, title: 'Delete', selected: false, key: 'delete' });
    }
    return array;
  };
  const paginationDropDownList = [10, 20, 40, 60, 80, 100];

  const handleChangePage = (event, newPage) => {
    props.handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(recordsPerPage);
    props.handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
    setPage(0);
  };

  useEffect(() => {
    if (!props.showNewVariableDialog) {
      setClearInputFields(true);
      setRowsPerPage(10);
      setQueryValues('');
      setPage(0);
    }
  }, [props.showNewVariableDialog]);

  useEffect(() => {

    if(props.clearSearchValue === true)
    {
      setPage(0);
      setClearInputFields(true);
    }
    
  }, [props.clearSearchValue]);

  

  useEffect(() => {
    // props.handlePagination({ rowsPerPage: rowsPerPage, newPageNumber: page });
  }, []);

  const handleChange = (value, columnName) => {
    const searchValues = { ...queryValues, [columnName.id]: value };
    setQueryValues((prevState) => {
      return { ...prevState, [columnName.id]: value };
    });
    props.handleChange(searchValues, rowsPerPage);
    setClearInputFields(false);
    setTargetValue(true);
    setPage(0);
  };

  return (
    <>
      <Grid item xs={12}>
        <Container className={classes.p0}>
          <Box className={classes.innerSchedulerTableLayout}>
            <Box className={classes.schedulerTableBox}>
              <Box>
                <TableContainer className={classes.tableContainer}>
                  <Table
                    clearInputFields={clearInputFields}
                    classes={classes.rightSubBox}
                    canUpdate={getPermissions()?.library?.variables?.canUpdate || false}
                    canDelete ={getPermissions()?.library?.variables?.canDelete || false}
                    handleChange={handleChange}
                    rowKeySuffix={'variables'}
                    tableColumns={headCells}
                    rowMenuOptions={dropdownData()}
                    headerMenuOptions={headerDropDownData()}
                    handleMenuDropdown={props.handleVarMenuDropdown}
                    checkDisable={props.checkDisable}
                    handleDeleteConfirm={props.handleDeleteConfirm}
                    tableData={variableData}
                    rowKey={'id'}
                  />
                </TableContainer>
                <TablePagination
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    classes: { icon: classes.selectIcon },
                  }}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={paginationDropDownList}
                  count={props.totalVariableRecords}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  component='div'
                  showFirstButton
                  showLastButton
                  page={page}
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
    </>
  );
}
