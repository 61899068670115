import MX_Constants from "../util/MX_Constants";
import {Ellipse} from "./shapes"

function StartTimer()
{
    Ellipse.call(this,
        {
            type:"StartTimer",
            icon:MX_Constants.IMAGE_START_TIMER,
            options:[],
            stroke:"light",
            moreIcon:"black"
        });

}
StartTimer.prototype = Object.create(Ellipse.prototype);
StartTimer.prototype.fromJSON = function(json)
{
    
}
StartTimer.prototype.toJSON = function()
{
    return null;
}

export default StartTimer;