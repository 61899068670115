import * as Blockly from 'blockly';

// Temporary holding object.
Blockly.Themes.NewDark = {};

export const BlockDarkTheme = Blockly.Themes.NewDark.defaultBlockStyles = {
  "colour_blocks": {
    "colourPrimary": "#a5745b",
    "colourSecondary": "#dbc7bd",
    "colourTertiary": "#845d49"
  },
  "loop_blocks": {
    "colourPrimary": "#7DACFF",
    "colourSecondary": "#000000",
    "colourTertiary": "#0062ff"
  },
  "logic_blocks": {
    "colourPrimary": "#7DACFF",
    "colourSecondary": "#64C7FF",
    "colourTertiary": "#FFFFFF"
  },
  "variable_blocks": {
    "colourPrimary": "#E2D0FF",
    // "colourSecondary": "yellow",
    // "colourTertiary": "green"
  },
  "list_blocks": {
    "colourPrimary": "#7DACFF",
    "colourSecondary": "#7DACFF",
    "colourTertiary": "#0062FF"
  },
  "math_blocks": {
    "colourPrimary": "#E2D0FF",
    "colourSecondary": "#bdc2db",
    "colourTertiary": "#a083ce"
  },
  "text_blocks": {
    "colourPrimary": "#E2D0FF",
    "colourSecondary": "#e2d0ff",
    "colourTertiary": "#a083ce"
  },
  "function_blocks": {
    "colourPrimary": "#c5289b",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#711358"
  },
  "procedure_blocks": {
    "colourPrimary": "#c5289b",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#711358"
  },
   "object_blocks": {
    "colourPrimary": "#888a05",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#51520b"
  },
   "element_blocks": {
    "colourPrimary": "#54bfeb",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#3c99bf"
  },
   "events_blocks": {
    "colourPrimary": "#ce3c64",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#82263f"
  },
   "mappings_blocks": {
    "colourPrimary": "#2e55e2",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#1a3492"
  },
   "storage_blocks": {
    "colourPrimary": "#767879",
    "colourSecondary": "#d6bddb",
    "colourTertiary": "#4c4f50"
  },
  "variable_dynamic_blocks": {
    "colourPrimary": "#3D339A",
    "colourSecondary": "#dbbdd6",
    "colourTertiary": "#1e1569"
  },
  "hat_blocks": {
    "colourPrimary": "#3D339A",
    "colourSecondary": "#dbbdd6",
    "colourTertiary": "#1e1569",
    "hat": "cap"
  },
  
  "scope_block": {
    "colourPrimary": "#7DACFF",
    "colourSecondary": "#5f88d2",
    "colourTertiary": "#fff"
  },
  "scope_no_connect_block": {
    "colourPrimary": "#AAE2FF",
    "colourSecondary": "#7fafc7",
    "colourTertiary": "#fff"
  },
  "statment_connect_block": {
    "colourPrimary": "#8c8c8c",
    "colourSecondary": "#BEBEBE",
    "colourTertiary": "#fff"
  },
  "statement_top_no_connect_block": {
    "colourPrimary": "#81b1b1",
    "colourSecondary": "#5c8888",
    "colourTertiary": "#fff"
  },
  "statement_bottom_no_connect_block": {
    "colourPrimary": "#81b1b1",
    "colourSecondary": "#5c8888",
    "colourTertiary": "#fff"
  },
  "return_int_block": {
    "colourPrimary": "#0062FF",
    "colourSecondary": "#5e9af9",
    "colourTertiary": "#fff"
  },
  "return_bool_block": {
    "colourPrimary": "#0062FF",
    "colourSecondary": "#5e9af9",
    "colourTertiary": "#fff"
  },
  "mutator_block": {
    "colourPrimary": "#24A148",
    "colourSecondary": "#6bd489",
    "colourTertiary": "#fff"
  },
  "variable_block": {
    "colourPrimary": "#E2D0FF",
    "colourSecondary": "#b6a7ce",
    "colourTertiary": "#fff"
  },
  "event_connect_block": {
    "colourPrimary": "#AAE2FF",
    "colourSecondary": "#BEBEBE",
    "colourTertiary": "#fff"
  },
  "element_block": {
    "colourPrimary": "#E2D0FF",
    "colourSecondary": "#BEBEBE",
    "colourTertiary": "#a083ce"
  },
  "event_start_block": {
    "colourPrimary": "#F9699C",
    "colourSecondary": "#BEBEBE",
    "colourTertiary": "#FFFFFF"
  },
};

export const CategoryDarkTheme = Blockly.Themes.NewDark.categoryStyles = {
 "colour_category": {
    "colour": "#a5745b"
  },
  "loops_category": {
    "colour": "#5ecd88"
  },
  "logic_category": {
    "colour": "#e9c84b"
  },
  "variables_category": {
    "colour": "#3d339a"
  },
  "lists_category": {
    "colour": "#a20f3e"
  },
  "math_category": {
    "colour": "#ee8945"
  },
  "text_category": {
    "colour": "#2b7880"
  },
  "function_category": {
    "colour": "#c5299b"
  },
  "object_category": {
    "colour": "#888a05"
  },
  "element_category": {
    "colour": "#54bfeb"
  },
  "events_category": {
    "colour": "#ce3c64"
  },
  "mappings_category": {
    "colour": "#2e55e2"
  },
  "storage_category": {
    "colour": "#767879"
  },
  "variable_dynamic_category": {
    "colour": "#3D339A"
  },
  "advance_category":{
    "colour": "#9A6324"
  },
  "utility_category": {
    "colour": "#FFA9A9"
  },
  "date_category": {
    "colour": "#911EB4"
  }
};

// This style is still being fleshed out and may change.
Blockly.Themes.Dark =
    new Blockly.Theme('newdark', Blockly.Themes.NewDark.defaultBlockStyles,
        Blockly.Themes.NewDark.categoryStyles);

Blockly.Themes.Dark.setComponentStyle('workspaceBackgroundColour', '#1e1e1e');
Blockly.Themes.Dark.setComponentStyle('toolboxBackgroundColour', '#333');
Blockly.Themes.Dark.setComponentStyle('toolboxForegroundColour', '#fff');
Blockly.Themes.Dark.setComponentStyle('flyoutBackgroundColour', '#252526');
Blockly.Themes.Dark.setComponentStyle('flyoutForegroundColour', '#ccc');
Blockly.Themes.Dark.setComponentStyle('flyoutOpacity', 1);
Blockly.Themes.Dark.setComponentStyle('scrollbarColour', '#797979');
Blockly.Themes.Dark.setComponentStyle('scrollbarOpacity', 0.4);

/**
 * CSS for the dark theme.
 * This registers CSS that is specific to this theme. It does so by prepending a
 * ``.dark-theme`` selector before every CSS rule that we wish to override by
 * this theme.
 */
(function() {
  var selector = '.dark-theme';
  Blockly.Css.register([
    /* eslint-disable indent */
    // Toolbox hover
    selector + ' .blocklyTreeRow:not(.blocklyTreeSelected):hover {',
      'background-color: #2a2d2e;',
    '}',
    // Dropdown and Widget div.
    selector + '.blocklyWidgetDiv .goog-menu, ',
    selector + '.blocklyDropDownDiv {',
      'background-color: #3c3c3c;',
    '}',
    selector + '.blocklyDropDownDiv {',
      'border-color: #565656;',
    '}',
    selector + '.blocklyWidgetDiv .goog-menuitem-content, ',
    selector + '.blocklyDropDownDiv .goog-menuitem-content {',
      'color: #f0f0f0;',
    '}',
    selector + '.blocklyWidgetDiv .goog-menuitem-disabled',
    ' .goog-menuitem-content,',
    selector + '.blocklyDropDownDiv .goog-menuitem-disabled',
    ' .goog-menuitem-content {',
      'color: #8a8a8a !important;',
    '}',
    /* eslint-enable indent */
  ]);
})();
