import React, { useEffect, useState } from "react";
import { useForm } from "../../../components/forms/Form";
import styles from "./authentication.module.css";
import { useAppContext } from "../../../components/activity/AppContext";
import AuthenticationActions from './authentication.actions';
import { validateValue, validateValues } from './oAuthValidations';
import adminMessages from "../../../common/validationMessages/administration";
import { useLocation } from "react-router-dom";

export default function OAuth(props) {

    const { authData, fetchAuthenticationData, currentAuthData } = props;
    const [data, setData] = useState({});
    const [oAuthData, setOAuthData] = useState({});
    const [connectionStatus, setConnectionStatus] = useState(false);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState("");
    const [whiteSpaceError, setWhiteSpaceError] = useState("")
    const [showwhiteSpaceError, setShowwhiteSpaceError] = useState(false)
    const [clientError, setClientError] = useState("");
    const app = useAppContext();
    const location = useLocation();

    useEffect(() => {
        if (authData.config !== "") {
            try {
                let parsedData = JSON.parse(authData.config);
                if (parsedData.providerUrl) setConnectionStatus(true);
                setData({
                    providerUrl: parsedData.providerUrl, clientid: parsedData.clientid, client_secret: parsedData.client_secret,
                    claim: parsedData.claim, response_type: parsedData.response_type, scope: parsedData.scope, authMethod: parsedData.authMethod
                });
                setOAuthData(parsedData);
            }
            catch {
                setData({ providerUrl: "", clientid: "", client_secret: "", claim: "", response_type: "", scope: "", authMethod: "" });
            }
        }
        else {
            setData({ providerUrl: "", clientid: "", client_secret: "", claim: "", response_type: "", scope: "", authMethod: "" });
        }
    }, [authData.config]);

    useEffect(() => {
        if (data.providerUrl && data.claim && data.response_type && data.scope && data.authMethod) setUpdate(true);
        else setUpdate(false);
        if (data.providerUrl) setError("");
        else setError(adminMessages.E1012);
    }, [data.providerUrl, data.claim, data.response_type, data.scope, data.authMethod]);

    function stringValPatternValidation(stringVal) {
        return /\s/g.test(stringVal);
    }

    const onWatch = (errors, value) => {
        data[value.name] = value.value;
        if (value.name === "providerUrl") {
            if (value.value === "") {
                setError(adminMessages.E1012)
            }
            else {
                setError("")
            }

            if (stringValPatternValidation(value.value) === true) {
                setShowwhiteSpaceError(true)
                setWhiteSpaceError(adminMessages.E1012)
            } else {
                setShowwhiteSpaceError(false)
                setWhiteSpaceError("")
            }
            // else if (value.value.includes(" ")) {
            //     setError(adminMessages.E1012)
            // } 
            // if (value.value !== "") setError("");
            // else setError(adminMessages.E1012);
        }

        // if (value.name === "clientid") {
        //     if (value.value === "") {
        //         setClientError("Please Enter Client Id")
        //     } else if (value.value.length > 25) {
        //         setClientError("Please Enter a valid Client ID")
        //     } else {
        //         setClientError("")
        //     }
        // }

        validateValue(errors, value.name, value.value)
    };

    const onSubmit = async (values, errors) => {
        if (!validateValues(values, errors))
            return false;
        let updateData = { authName: authData.name, config: JSON.stringify({ ...oAuthData, ...data }) };
        const actions = AuthenticationActions(app());
        const update = actions.validateAuthChanges(authData, currentAuthData);
        if (update) {
            const [, value] = await actions.updateAuthentication(updateData);
            if (value) fetchAuthenticationData();
        }
    };

    const handleConfigurations = async (link) => {

        const actions = AuthenticationActions(app());
        const [, value] = await actions.getConfigurationsData(link);
        if (value) {
            let parsedData = JSON.parse(value.status.message);
            setOAuthData(parsedData);
            setData({ ...data, claim: "email", response_type: "token id_token", scope: "openID", authMethod: "none" })
            setConnectionStatus(true);
        }
    }

    const { Form, Select, Input, Label, Error } = useForm(data, onSubmit, onWatch);

    return (
        <Form>
            <div className={styles.oauthCon}>
                <h2 data-header-testid="OAuth Authentication" className={styles.rolesLyoutTitle}>OAuth Authentication</h2>
                <div className={styles.oauthOuterCon}>
                    <div className={styles.inOutInnerCon} style={{ width: "45%" }}>
                        <Label text="Provider URL" htmlFor="providerUrl" />
                        <Input type="url" id="providerUrl" name="providerUrl" defaultValue={data.providerUrl} className={styles.inputField}/>
                        <Error message={error} />
                        {showwhiteSpaceError === true ? <Error message={whiteSpaceError} /> : <></>}
                        <div className={styles.submitCon}>
                            <input type="button" data-clickable-testid="load-configurations" className={`primary ${styles.submit}`} value="Load Configurations" onClick={() => handleConfigurations(data.providerUrl)} />
                        </div>
                        <Label text="Client ID" htmlFor="clientid" />
                        <Input type="text" id="clientid" name="clientid" defaultValue={data.clientid} className={styles.inputField}/>
                        <Error name={"clientid"} />
                        <Label text="Client Secret" htmlFor="client_secret" />
                        <Input type="text" id="client_secret" name="client_secret" defaultValue={data.client_secret} className={styles.inputField}/>
                        <Error name={"client_secret"} />
                    </div>
                    {connectionStatus ? <div className={styles.inOutInnerCon} style={{ width: "45%" }}>
                        <Label text="Claims" htmlFor="claim" />
                        <Select id="claim" name="claim" defaultValue={data.claim}>
                            {oAuthData.claims_supported.map((dt, index) => <option key={index}>{dt}</option>)}
                        </Select>
                        <Label text="Response Type" htmlFor="response_type" />
                        <Select id="response_type" name="response_type" defaultValue={data.response_type}>
                            {oAuthData.response_types_supported.map((dt, index) => <option key={index}>{dt}</option>)}
                        </Select>
                        <Label text="Scope" htmlFor="scope" />
                        <Select id="scope" name="scope" defaultValue={data.scope}>
                            {oAuthData.scopes_supported.map((dt, index) => <option key={index}>{dt}</option>)}
                        </Select>
                        <Label text="Auth Method" htmlFor="authMethod" />
                        <Select id="authMethod" name="authMethod" defaultValue={data.authMethod}>
                            <option>none</option>
                        </Select>
                    </div> : ""}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                    <input data-clickable-testid="cancel" className='tertiary' type="submit" value="Cancel" disabled={true} />
                    {
                        location.state.permission?.canUpdate
                        ?<input data-clickable-testid="update" className={update ? "primary" : "tertiary"} type="submit" value="Update" disabled={update ? false : true} />
                        :<></>
                    }
                </div>
            </div>
        </Form>
    );
}