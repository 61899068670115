import React from "react";
import { useParams } from "react-router-dom";
import Database from "./database";
import SubHeader from "../../components/header/subheader";
import { getPermissions } from "../../common/utils/utils"; 

function Database_v6() {

    const { id: pName } = useParams();
    const perms = getPermissions("Projects")?.childPermissions;

    //added for integration purpose
    const links = [
        { path: `/Project/${pName}`, name: "Home", canView: true },
        { path: "database", name: "Database", canView:perms.find((e)=>e.name==="Database")?.canView  },
        { path: "drive", name: "Drive", canView:perms.find((e)=>e.name==="Drive")?.canView },
        // { path: "import_logs", name: "Import Logs" },
        { path: "settings", name: "Settings",canView:perms.find((e)=>e.name==="Project Settings")?.canView },

    ];
        
    return (
        <>
        <SubHeader links={links} />
        <Database perm={perms.find((e)=>e.name==="Database")}/>
        </>
    )

}
export default Database_v6;