import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { previewAndPublishAction } from '../../../../../../actions/preview-and-publish';
import {
  publishStatus,
  fetchStartPreview,
} from '../../../../../../helpers/preview-and-publish';
import { configMessage } from '../../../../../../common/messages/config';
import { getCurrentDomain, setSessionVal } from '../../../../../../utils/common';
import store from '../../../../../../stores';
import useStyles from '../../style';

export default function GeneratingPreview(props) {
  const classes = useStyles();
  const {
    appMode,
    previewAndPublish,
    urlObject = {},
    isWorkflow,
    workflowId,
  } = props;
  let statusDetail =
    previewAndPublish != undefined ? previewAndPublish.statusDetail : {};
  let status = previewAndPublish != undefined ? previewAndPublish.status : {};
  const [progress, setProgress] = useState({ percent: 50, status: -1 });
  const [deviceBtnShow, setDeviceBtnShow] = useState(false);
  const [workFlow, setWorkFlow] = useState('');
  const [loading, setLoading] = useState(false);

  const [previewGenerated, setGeneratedPreview] = useState(false);

  useEffect(() => {
    if (appMode === configMessage.T4668) {
      props.handlePublishOption(0);
    } else if (appMode === configMessage.T4669) {
      props.handlePublishOption(1);
    }
  }, [appMode]);

  useEffect(() => {
    const wf = workflowId ? `&id=${workflowId}` : '';
    setWorkFlow(wf);
  }, [workflowId]);

  useEffect(() => {
    if (status === 'PREVIEW_INIT_SUCCESS') {
      setLoading(false);

      publishStatus(
        urlObject.ProjectName || props.projectDetail.name,
        previewAndPublish.previewId,
        urlObject.BusinessFunction || props.businessFunName
      );
    } else if (
      ['PUBLISH_STATUS_ERROR', 'PREVIEW_INIT_ERROR'].includes(status)
    ) {
      setLoading(false);
      setProgress((p) => ({ percent: 100, status: 0 }));
    }
  }, [status]);

  useEffect(() => {
    let timer = null;
    let data = urlObject;
    if (isWorkflow) {
      data = {
        ProjectName: props.projectDetail.name,
        BusinessFunction: props.businessFunName,
        Lane: props.lanes[0]?.name,
      };
    }
    if (statusDetail.status === 'pending') {
      setProgress((p) => ({
        percent: p.percent < 90 ? p.percent + 5 : p.percent,
        status: -1,
      }));
      timer = setTimeout(() => {
        publishStatus(
          urlObject.ProjectName || data.ProjectName,
          previewAndPublish.previewId,
          urlObject.BusinessFunction || data.BusinessFunction
        );
      }, 10000);
    }
    if (statusDetail.status !== undefined && statusDetail.status === 'finish') {
      setProgress((p) => ({ percent: 100, status: 1 }));
      store.dispatch(previewAndPublishAction.updateStatus(''));

      // fetchStartPreview(data.ProjectName, data.BusinessFunction);
      if (appMode === configMessage.T4668) {
        props.setGeneratedPreview(data, true, 3);
        if (isWorkflow) props.handleNextPage();
        else {
          const previewPageUrl = `/Preview/${data.ProjectName}/${data.BusinessFunction}/${data.Lane}?isWorkflow=${isWorkflow}${workFlow}`;
          setSessionVal("bfType","NWF");
          props.history.push(previewPageUrl);
        }
      } else {
        props.setGeneratedPreview(data, false, 3);
        // setDeviceBtnShow(true);
      }
    } else if (
      statusDetail.status !== undefined &&
      statusDetail.status === 'failed'
    ) {
      setProgress((p) => ({ percent: 100, status: 0 }));
      setTimeout(() => {
        props.setGeneratedPreview(data, false, 4);
      }, 2000);
    }

    return () => {
      if (timer !== null) clearTimeout(timer);
      else return null;
    };
  }, [statusDetail]);

  let ipaOtaUrl = `${getCurrentDomain()}/${sessionStorage.getItem(
    'env'
  )}/previewapp/index.html`;
  let apkOtaUrl = `${getCurrentDomain()}/${sessionStorage.getItem(
    'env'
  )}/previewapp/index.html`;

  const handleCopyClipboard = (type) => {
    const otaUrl = type === 'ios' ? ipaOtaUrl : apkOtaUrl;
    navigator.clipboard.writeText(otaUrl);
    props.handleCopyAlert();
  };

  const onModalClose = () => {
    store.dispatch(previewAndPublishAction.updateStatus(''));
    setProgress({ percent: 50, status: -1 });
    props.onClose();
  };

  return (
    <Grid xs={12} className={classes.publishContainer}>
      {/* {loading && ( */}
      <CircularProgress
        classes={{ root: classes.loader }}
        style={{ color: '#CDD4E4' }}
        size={64}
      />
      {/* )} */}
      {/* {!loading && (
      <Container className={classes.publishContainer}>
        <Box> */}
      {/* {appMode === configMessage.T4669 ? (
            <ProgressBar value={100} status={1} statusfailed={false} />
          ) : ( */}
      {/* <Box component='div'>
              <Box component='div'>
                <ProgressBar
                  value={progress.percent}
                  status={progress.status}
                />
              </Box>
              <Box component='div'>
                {progress.status === 0 && (
                  <Box>
                    <Typography variant='h5' className={classes.errorMessage}>
                      {configMessage.T4701}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box> */}
      {/* )} */}
      {/* </Box>
      </Container>
    )} */}
      {/* <Container className={classes.publishContainer}>
      <Grid xs={12} className={classes.generateNote}>
        <Typography variant='body2' className={classes.generateNoteText}>
          {configMessage.T4673}
        </Typography>
      </Grid>

      {appMode === configMessage.T4669 && deviceBtnShow && (
        <Box component='div' className={classes.otaBtns}>
          <Grid xs={12} container className={classes.publishContainer}>
            <Grid xs={6} className={classes.linkImages}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  handleCopyClipboard('ios');
                }}
              >
                {configMessage.T4674}
              </Button>
            </Grid>
            <Grid xs={6} className={classes.linkImages}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  handleCopyClipboard('android');
                }}
              >
                {configMessage.T4675}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container> */}
    </Grid>
  );
}
