import React, { useEffect, useState } from "react";
import { useForm } from "../../../../components/forms/Form";
import PopUp from "../../../../components/layouts/PopUp";
import styles from "./newService.module.css";
import messages from "../services.messages";
import { validateValue } from "../serviceValidations";

function NewService(props) {
  const [data, setData] = useState({
    name: "",
    isShared: true,
    serviceId: "",
    isUpdate: false,
  });
  const [apiError, setApiError] = useState("");
  const [focus,setFocus] = useState(false)
  const { show = false, closepopup, apiService, update } = props;

  useEffect(() => {
    if (update.isUpdate) {
      setData({
        ...update,
        name: update?.name || "",
      });
    }
  }, []);
  const handleClose = () => {
    closepopup(false);
  };

  const onWatch = (errors, data) => {
    validateValue(errors, data.name, data.value);
  };

  const alphabetOnly = (e) => {
    const { value } = e.target;

    const re = /^[0-9A-Za-z_ ]+$/;
    if ((value === "" || re.test(value)) && value.length <= 45) {
      setData(val => ({ ...val, name: value }));
    }
    setFocus(true)
  }

  const onSubmit = async (values, errors) => {
    if (values.name && values.name != "") {
      setData({ name: values.name });
      if (update) apiService(values.name, update, setApiError);
      else apiService(values.name, setApiError);
    } else {
      setApiError(messages.createServiceError);
    }
  };

  const { Form, Label, Input, Error } = useForm(data, onSubmit, onWatch);

  return (
    <PopUp
      popUp={show}
      setPopUp={handleClose}
      titleClassName={styles.popupTitle}
      testId={update ? "edit-service" : "new-service"}
      title={update ? "Edit Service" : "New Service"}
    >
      <Form>
        <div className={styles.formArea}>
          <div className={styles.column}>
            <Label text={"Name"} htmlFor="name" />
            {/* <Input type="text" id="name" name="name" autoFocus={focus}/> */}
            <input type={"text"} data-input-testid={"name"} value={data.name} onChange={alphabetOnly} autoFocus={focus} />
            <Error name={"name"} />
            <Error message={apiError} />
          </div>
        </div>

        <div className={styles.afFooter}>
          <Input
            type="button"
            className="secondary"
            value={"Cancel"}
            onClick={handleClose}
          // data-clickable-testid={"Cancel"}
          />
          <Input
            type="submit"
            className="primary"
            value={update.isUpdate ? "Update" : "Create"}
          // data-clickable-testid={update.isUpdate ? "Update" : "Create"}
          />
        </div>
      </Form>
    </PopUp>
  );
}

export default NewService;
