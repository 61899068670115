import React from "react";
import { useState } from "react";
import PopUp from "../../../../components/layouts/PopUp";
import RevertServiceStatus from "./reverServiceStatus";
import RevertServiceForm from "./revertServiceForm";

export default function RevertService(props) {
    const { getList, revert, setRevert } = props;
    const [selectedService, setSelectedService] = useState();

    const handleClose = () => {
        setRevert((val) => ({ ...val, show: false }));
        getList();
    }

    const [renderComponent, setRenderComponent] = useState("RevertServiceForm");

    return (
        <PopUp popUp={revert.show} setPopUp={handleClose}>
            {renderComponent === "RevertServiceForm" ? <RevertServiceForm handleClose={handleClose} setRenderComponent={setRenderComponent} serviceData={revert.item} setSelectedService={setSelectedService} /> : ""}
            {renderComponent === "RevertServiceStatus" ? <RevertServiceStatus handleClose={handleClose} serviceData={revert.item} selectedService={selectedService} /> : ""}
        </PopUp>
    )
}
