import { getPasswordPolicy, updatePasswordPolicy } from '../../../api/administration/passwordPolicy/passworkPolicy'
import ActivityIndicator from '../../../components/activity/ActivityIndicator'
import React from "react";

function PasswordActions({ show, close, notify }) {

    const GetPasswordPolicValues = async () => {
        show(() => <ActivityIndicator text={"Loading Password Policy"} />)
        const [error, data] = await getPasswordPolicy()
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const UpdatePasswordPolicyValues = async (input) => {
        show(() => <ActivityIndicator text={"Loading Password Policy"} />)
        const [error, data] = await updatePasswordPolicy(input)
        close()
        if (error) {
            notify({ message: data?.status?.message, type: "E" });
            return [data?.status.message, data];
        }
        if (data.status.code === 1) {
            notify({ message: data.status.message, type: "S" });
            return [null, data];
        } else {
            notify({ message: data.status?.message || "", type: "E" });
            return [data.status?.message || "", data];
        }
        return [null, data];
    }




    return { GetPasswordPolicValues, UpdatePasswordPolicyValues }
}

export default PasswordActions