import React from 'react';
import messages from './loginsMessages';
import styles from './logins.module.css';
import { Card } from '../../../components/card';

function LoginsList(props) {
    const { 
        list = [], 
        isLoading = null, 
        navigate = () => null 
    } = props;

    return (
        <>

            <div className={styles.afLoginsList}>
                {   
                    isLoading && 
                    [1,2,3,4,5,6,7,8].map((item) => <Card isLoading={isLoading}/>)
                }
                {
                    list.map((item) => {
                        return (
                            <Card
                                title={item.name}
                                imageUrl={item.snapshot}
                                onClick = {() => navigate(item)}
                            />
                        )
                    })
                }
                {
                    !isLoading &&
                    list.length === 0 &&
                    <div className="emptyArena">
                        {messages.emptyLogins}
                    </div>
                }
            </div></>
    )
}

export default LoginsList