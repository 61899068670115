import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

import state from '../../core/state';
import core from '../../core/core';

import { getComputedStyle } from '../../../../../containers/app-designer/utils/style';
import style from '../../../../../common/components/TableHeadSelectInput/style';

class Dropzone extends Component {
  constructor(props) {
    super(props);

    // create refrence for canvas
    // to check whether is dragging on current canvas or not
    this.canvasRef = React.createRef();
    this.containerRef = React.createRef();
    this.dangerouslySetElements = this.dangerouslySetElements.bind(this);
    // component state
    // initialElements - to keep track of initial elements
    // droppedElements - to keep track of newly added elements
    this.state = {
      initialElements: [],
      droppedElements: [],
      initDone: false,
      _addElement: this._addElement.bind(this),
      _updateDropzoneElements: this.dangerouslySetElements.bind(this),
      _containerOnClick: this.containerOnClick.bind(this),
      isPropertyValueUpdated: false,
    };
    this._renderDragItem= this._renderDragItem.bind(this);
  }

  componentDidMount() {
    if (this.containerRef && this.containerRef.current && this.props.isPage) {
      const { value } = getComputedStyle({
        computedStyle: window.getComputedStyle(this.containerRef.current, ''),
        componentName: this.props.pageName,
        componentId: '',
        isPage: true,
      });
      value.style.backgroundColor = '#fafafa';
      this.props.setState({ propertyValue: value });
      this.props.onSelect({
        uuid:this.props.pageId,
        id: null,
        dropzoneID: null,
        property: {
          componentSection: [],
          gridSection: [],
          textSection: [],
          styleSection: [
            'Background',
            'Opacity',
            'Custom CSS',
            'BackgroundImage',
          ],
          layoutSection: ['Layout', 'Width', 'Height'],
        },
        propertyValue: value,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.isPage) {
      //console.warn('PageUnMount', this);
      this.props.setState({ propertyValue: {} });
    }
  }

  containerOnClick = (propertyValue = null, pageName) => {
    //console.warn('page Props', this.props.propertyValue, propertyValue);
    if (propertyValue) {
      if (propertyValue.component.id && pageName) {
        if (propertyValue.component.id != pageName) {
          propertyValue.component.id = pageName;
        }
      }
    }

    this.props.onSelect({
      ...this.props._getPageProperties(),
      propertyValue: propertyValue ? propertyValue : this.props.propertyValue,
    });
  };

  static getDerivedStateFromProps(props, state) {
    // if (props.addElement && Object.keys(props.addElement).length > 0) {
    //   state._addElement(props.addElement, false);
    //   props.setState({ addElement: null });
    // }
    if (props.initialElements && !state.initDone) {
      state._updateDropzoneElements(props.initialElements, (tree) => {
        // console.log('new tree id',tree,props.id);
      });
      props.setState({ shouldCanvasRefreshed: false });
      //this.dangerouslySetElements(props.initialElements);
    }
    if (props.id === 'root' && !state.initDone) {
      props.setState({ addElementToRoot: state._addElement });
    }
    if (
      props.id === 'root' &&
      props.shouldCanvasRefreshed &&
      props.initialElements
    ) {
      state._updateDropzoneElements(props.initialElements, (tree) => {
        // console.log('new tree id shouldCanvasRefreshed',tree,props.id);
        if (props.propertyValue.component.imageName) {
          props.setBackgroundImage(props.propertyValue?.component?.imageName);
        }
        if (props.propertyValue)
          state._containerOnClick(props.propertyValue, props.pageName);
      });
      // if (props.propertyValue && !state.isPropertyValueUpdated) {
      // console.log('new tree id shouldCanvasRefreshed propertyValue',props.propertyValue);

      // }
      props.setState({ shouldCanvasRefreshed: false });
    }
    return { initDone: true };
  }

  // componentWillMount() {
  //   //this._setInitialElements(this.props.initialElements);
  //   //this._setInitialElements(this.props.initialElements);
  //   // console.log("id,initelements",this.props.id,this.props.initialElements);
  //   //this._updateDropzoneElements(this.props.initialElements);
  //   /* this.dangerouslySetElements(this.props.initialElements,(tree)=>{
  //     console.log("new tree id",tree,this.props.dropzoneID)
  //   });*/
  // }

  // componentWillReceiveProps(nextProps) {
  //   // This will erase any local state updates!
  //   // Do not do this.
  //   //this.setState({ email: nextProps.email });
  // }
  // UNSAFE_componentWillReceiveProps(props, nextProps) {
  //   console.warn('componentWillReceiveProps componentWillReceiveProps', props, nextProps);
  //   this._setInitialElements(nextProps.initialElements);
  // }

  /**
   * function to allow manual element update
   * Note - function is only accessible through ref and make sure
   * valid data is pass through else application state/hierarchy can break
   * @param data {Array/Function} - if its function then make sure you are
   * returning valida array
   * @param done {function} - success call back function
   */
  dangerouslySetElements = (data, done) => {
    let elements = [];
    if (typeof data === 'function') {
      elements = data(this.state.droppedElements) || [];
    } else {
      elements = data;
    }
    const { id: dropzoneID, parentID } = this.props;
    elements = elements.map((e) => ({
      ...e,
      fields:
        !e.fields && e.initialElements && e.initialElements.length === 0
          ? []
          : e.initialElements,   
      dropzoneID,
      parentID,
      showBasicContent: false,
      updateState: this._updateState,
      removeElement: this._removeElement,
      addElement: this._addElement,
      updateElement: this._updateElement,
      flushDroppedElements: this._flushDroppedElements,
      checkAndRemoveElement: this._checkAndRemoveElement,
      selectedComponent: this.props.isPage ? this.props._getPageProperties() : this.props.selectedComponent,
      menuPagePropertyValue: e.menuPagePropertyValue ? e.menuPagePropertyValue : (this.props.isPage && this.props._getPageProperties()?.propertyValue)
    }));

    this.setState(
      {
        initialElements: elements,
        droppedElements: elements,
        initDone: true,
      },
      () => {
        this._updateState(done, false, false);
      }
    );
  };

  /**
   * function to remove before/after class form all canvas elements
   */
  _unmarkDragElements = () => {
    [].forEach.call(
      this.canvasRef.current.querySelectorAll('.drag-item'),
      (e) => e.classList.remove('before', 'after')
    );
  };

  /**
   * function to set initial elements
   * @param initialElements {Array} - It holds all initial elements to be shown in canvas
   */
  _setInitialElements = (initialElements) => {
    // current component state(droppedElements) doesn't hold any elements
    // but initialElements has some element to be set
    const gotInitialItems =
      Array.isArray(initialElements) &&
      initialElements.length &&
      !this.state.initDone &&
      !this.state.initialElements.length;

    // for first time add initialElements to droppedElements
    if (gotInitialItems) {
      const { id: dropzoneID, parentID } = this.props;
      const updatedInitialItems = initialElements.map((e) => ({
        ...e,
        key: e.id,
        dropzoneID,
        parentID,
        showBasicContent: false,
        updateState: this._updateState,
        removeElement: this._removeElement,
        updateElement: this._updateElement,
        addElement: this._addElement,
        flushDroppedElements: this._flushDroppedElements,
        checkAndRemoveElement: this._checkAndRemoveElement,
      }));

      this.setState(
        {
          initialElements: updatedInitialItems,
          droppedElements: updatedInitialItems,
        },
        () =>
          this._updateState(() => {
            this.setState({
              initDone: true,
            });
          })
      );
    }
  };

  _updateDropzoneElements = (initialElements) => {
    const gotInitialItems = Array.isArray(initialElements);

    // for first time add initialElements to droppedElements
    if (gotInitialItems) {
      const { id: dropzoneID, parentID } = this.props;
      const updatedInitialItems = initialElements.map((e) => ({
        ...e,
        key: e.id,
        dropzoneID,
        parentID,
        showBasicContent: false,
        updateState: this._updateState,
        removeElement: this._removeElement,
        updateElement: this._updateElement,
        addElement: this._addElement,
        flushDroppedElements: this._flushDroppedElements,
        checkAndRemoveElement: this._checkAndRemoveElement,
      }));

      this.setState(
        {
          initialElements: updatedInitialItems,
          droppedElements: updatedInitialItems,
        },
        () =>
          this._updateState((d) => {
            //console.log('State tree', d);
            this.setState({
              initDone: true,
            });
          })
      );
    }
  };

  /**
   * function to toggle/manage class(inside)
   * class gets appended to canvas
   * @param target {DOM} - current canvas upon which use is hovering
   * @param action {String} - add/remove - label of action
   */
  _manageInsideClass = ({ target }, action) => {
    if (this.canvasRef.current === target) {
      target.classList[action]('inside');
    }
  };

  /**
   * function to remove element from droppedElements
   * @param elementID - {String} - ID of element
   * @param cb - {function}
   * @param dispatchElementRemove {Boolean} - trigger `removeElement` event if its true
   */
  _removeElement = (
    elementID,
    cb = () => {},
    dispatchElementRemove,
    flag = true
  ) => {
    const index = this.state.droppedElements.findIndex(
      (e) => e.id === elementID
    );

    this.setState(
      {
        droppedElements: this.state.droppedElements.filter(
          (d, i) => i !== index
        ),
      },
      () =>
        this._updateState(
          (d, id = this.props.id) => {
            //console.log('new id', d, id);
            cb(d);
          },
          dispatchElementRemove ? elementID : null,
          flag
        )
    );
  };

  /**
   * function to update element from droppedElements
   * @param newData - {element} - { id, label, type, payload }
   * @param cb - {function}
   */
  _updateElement = (newData, cb = () => {}) => {
    const elementIndex = this.state.droppedElements.findIndex(
      (e) => e.id === newData.id
    );

    if (elementIndex === -1) {
      return cb(null);
    }

    // support is limited to below keys only, to avoid possible breaks
    const supportedKeys = [
      'label',
      'type',
      'payload',
      'style',
      'property',
      'options',
      'propertyValue',
      'uniquekey',
      'imageUrl',
      'disableDraggable',
    ];
    const newElementData = {};
    Object.keys(newData).forEach((key) => {
      if (supportedKeys.indexOf(key) !== -1) {
        newElementData[key] = newData[key];
      }
    });

    this.setState(
      {
        droppedElements: this.state.droppedElements.map((e) => {
          if (e.id === newData.id) {
            return { ...e, ...newElementData };
          }

          return e;
        }),
      },
      () => this._updateState(cb)
    );

    return true;
  };

  /**
   * function to update the application state (not component state)
   * function will further call `updateState` from state API, which updates the application state
   * @param cb {function} - callback function - optional
   */
  _updateState = (
    cb = (d) => {
     // console.log('tree', d);
    },
    dispatchElementRemove,
    flag = true,
    onDrop=false
  ) => {
    const { id: dropzoneID, parentID } = this.props;
    // console.log('dropzoneID,parentID',dropzoneID,parentID);
    state.updateState(
      dropzoneID,
      parentID,
      this.state.droppedElements,
      cb,
      dispatchElementRemove,
      flag,
      onDrop
    );
  };

  /**
   * function to flush the component state
   * once component state ths flushed, this will call `_updateState` to update the
   * application state
   * @param cb {function} - callback function - optional
   */
  _flushDroppedElements = (cb) => {
    this.setState(
      {
        initialElements: [],
        droppedElements: [],
      },
      () => {
        if (!cb) {
          cb = () => {};
        }
        this._updateState(cb);
      }
    );
    if (cb) {
      this._updateState(cb);
    }
  };

  /**
   * function check below parameters
   * 1 - user's intend is to move element from one canvas to other
   * 2 - Get confirmation before remove
   * once both condition matched remove the element from current canvas
   */
  _checkAndRemoveElement = () => {
    const elementMoved = core.getDraggedElement();
    const { removeElement, elementID } = elementMoved;
    const status = this.props.onElementMove(elementMoved);
    const attemptToMove = !core.getAttemptToRemove();

    if (status && attemptToMove) {
      removeElement(elementID);
    }
  };

  // user is dragging over the canvas
  // `preventDefault` - Required. Allows us to drop.
  // @param e {event}
  _onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    core.setDropPostion(undefined);
  };

  /**
   * user is leaving the canvas
   * @param e {event}
   */
  _onDragLeave = (e) => {
    e.stopPropagation();
    this._manageInsideClass(e, 'remove');
    core.setAttemptToRemove(true);
  };

  /**
   * user is dragging over canvas
   * @param e {event}
   */
  _onDragEnter = (e) => {
    e.stopPropagation();
    this._manageInsideClass(e, 'add');
    core.setAttemptToRemove(false);
  };

  /**
   * use has dropped the element into the canvas
   * perform all required checks
   * function also triggers `onDrop` prop so that uset can provide additional info
   * once all done `_addElement` get called with all required info
   * @param e {event}
   */
  _onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { droppedElements } = this.state;

    core.setAttemptToRemove(false);
    this._manageInsideClass(e, 'remove');

    const { onDrop } = this.props;
    let data = JSON.parse(e.dataTransfer.getData('data'));
    data = { ...data };
    if (!this.props.dropzoneID) {
      this._unmarkDragElements();

      return onDrop
        ? onDrop(data, this._addElement, {
            dropIndex: !droppedElements.length ? core.getDropPostion() + 1 : 0,
            currentElements: droppedElements,
          })
        : this._addElement(data);
    } else {
      return false;
    }
  };

  /**
   * function to add element into droppedElements
   * prior to add it perfom below check
   * 1 - element has unique key
   * 2 - element has invalid data
   * 3 - check against max-capacity
   * once element added remove the element from previous canvas
   * @param updatedData {Object} - element information
   * @returns Object - new list of canvas elements
   */
  _addElement = (updatedData, onDrop = true, index = undefined) => {
    let dropzoneID;
    let capacity;
    let parentID;
    let allowHorizontal;
    //console.log('new drop', onDrop);
    if (onDrop) {
      dropzoneID = this.props.id;
      parentID = this.props.parentID;
      allowHorizontal = this.props.allowHorizontal;
    } else {
      dropzoneID = updatedData.dropzoneID;
      parentID = updatedData.parentID;
      allowHorizontal = updatedData.allowHorizontal;
    }
    const dropPosition = core.getDropPostion();
    const draggedElement = core.getDraggedElement();
   /* console.warn(
      'inside dropzone',
      dropzoneID,
      parentID,
      dropPosition,
      draggedElement
    );*/
    const invalidUpdatedData = !updatedData || !updatedData.id;
    let indexOfPresentElement = -1;
    const keyAlreadyPresent =
      updatedData.id &&
      this.state.droppedElements.filter((e, i) => {
        if (e.id === updatedData.id) {
          indexOfPresentElement = i;

          return true;
        }

        return false;
      }).length;
    const isSameIndex = indexOfPresentElement === dropPosition;
    let newElements = [].concat(this.state.droppedElements);
    let elementAlreadyRemoved = false;

    // check fo unique key
    if (invalidUpdatedData || keyAlreadyPresent) {
      if (isSameIndex) {
        return core.error('Duplicate or invalid ID');
      }

      elementAlreadyRemoved = true;
      newElements = newElements.map((e) => {
        if (e.id === updatedData.id) {
          return { ...e, remove: true };
        }
        return e;
      });
    }

    let initialElementsToBind = null;
    if (updatedData.dropzoneID && updatedData.parentID) {
      const oldCopy = state.traverseAndReturnElement(
        updatedData.id,
        updatedData.dropzoneID,
        updatedData.parentID
      );
      initialElementsToBind = (oldCopy && oldCopy.fields) || [];
      const assignInitialElements = (list) => {
        list.forEach((e) => {
          if (e.fields) {
            e.initialElements = e.fields;
            assignInitialElements(e.fields);
          }
        });
      };

      assignInitialElements(initialElementsToBind);
    }

    /*console.log(
      'new initialElementsToBind || updatedData.initialElements || this.state.initialElements',
      initialElementsToBind,
      updatedData.initialElements,
      this.state.initialElements
    );*/
    if (updatedData.initialElements) {
      updatedData.initialElements = updatedData.initialElements.map((e) => {
        return {
          ...e,
          updateState: this._updateState,
          removeElement: this._removeElement,
          updateElement: this._updateElement,
          addElement: this._addElement,
          flushDroppedElements: this._flushDroppedElements,
          checkAndRemoveElement: this._checkAndRemoveElement,
        };
      });
    }
    //console.warn('inside dropzone add elements', updatedData);
    if(!updatedData.fields && initialElementsToBind){
      updatedData.fields=initialElementsToBind;
      updatedData.initialElements = updatedData.fields;
    }
    const elementToDrop = {
      ...updatedData,
      key: updatedData.id,
      uniquekey: updatedData.uniquekey,
      disableDraggable: updatedData.disableDraggable || false,
      dropzoneID,
      parentID,
      allowHorizontal,
      showBasicContent: false,
      updateState: this._updateState,
      removeElement: this._removeElement,
      updateElement: this._updateElement,
      addElement: this._addElement,
      // initialElements helps figuring out initDone
      initialElements: updatedData.initialElements && updatedData.initialElements.length  ? updatedData.initialElements :[],
      flushDroppedElements: this._flushDroppedElements,
      checkAndRemoveElement: this._checkAndRemoveElement,
    };
    //console.log('dd dropPosition', dropPosition);
    if (index != undefined) {
      newElements = [
        ...newElements.slice(0, index),
        elementToDrop,
        ...newElements.slice(index),
      ];
    } else if (dropPosition === undefined) {
      newElements = [...newElements, elementToDrop];
    } else if (dropPosition > 0) {
      newElements = [
        ...newElements.slice(0, dropPosition),
        elementToDrop,
        ...newElements.slice(dropPosition),
      ];
    } else {
      newElements = [elementToDrop, ...newElements];
    }

    if (!isSameIndex) {
      newElements = newElements.filter((e) => !e.remove);
    }

    // check new list against max-capacity
    if (capacity && newElements.length > capacity) {
      return core.error(
        `Maximum capacity of canvas(${dropzoneID}) is ${capacity}`
      );
    }

    // update the current list
    this.setState(
      {
        droppedElements: newElements,
      },
      () => {
        // remove element from previous canvas
        var flag = true;
        if (
          !elementAlreadyRemoved &&
          draggedElement &&
          typeof draggedElement.checkAndRemoveElement === 'function'
        ) {
          //console.log('new', draggedElement);
          flag = false;
          draggedElement.checkAndRemoveElement();
        }

        // update the application state
        this._updateState(
          (d) => {
            // console.log('new tree id',d,this.props.id);
          },
          false,
          flag,
          true
        );
      }
    );

    // adding dropping class to dropzone, it can be used for animation purpose
    this.canvasRef.current.classList.add('dropping');
    setTimeout(() => {
      this.canvasRef.current &&
        this.canvasRef.current.classList.remove('dropping');
    }, 500);

    return {
      dropzoneID,
      elements: newElements,
    };
  };

  /**
   * function to render the dragged element
   * @param props {Object} - An object which hold the info of dragged element/component
   * @returns JSX
   */
  _renderDragItem = (props, isDropzone) => {
    if (!isDropzone) {
      let additionalProps = {};
      if (this.props.isMenuPage) {
        additionalProps = {
          showPreview: true,
          isMenuPage: true,
          initialElements: [],
          menuPagePropertyValue: this.props.isPage && this.props._getPageProperties()?.propertyValue
        };
        let menuProps=additionalProps.menuPagePropertyValue.component.links;
        if(menuProps){
          menuProps.map(mp=>{
            this.dispatchTokens(mp);
          })
        }
      }
      if (!props.showPreview || !props.showBasicContent) {
        this.dispatchTokens(props);
      }
      const element = core
        .getRegisteredPaletteElements()
        .find((e) => e.type === props.type);

      return element ? (
        <element.component {...props} projectName={this.props.projectName} {...additionalProps} />
      ) : null;
    }
    const { droppedElements } = this.state;
    const { capacity, id, placeholder, onSelect, onDrop, setState } =
      this.props;
    const spaceAvailable = capacity ? capacity > droppedElements.length : true;
    let idocument = document.getElementById('iframe').contentDocument;
    if (!props.showPreview || !props.showBasicContent) {
      this.dispatchTokens({id:id,label:this.props.propertyValue?.component?.placeholder});
    } 
    const Comp=this.props.component ;
    if(Comp){
    return (<Comp
      ref={this.canvasRef}
      className={`${!spaceAvailable ? 'no-space' : ''} ${id === 'root' ? 'canvas' : ''
        } ${!droppedElements.length ? 'empty' : ''} dropzone ${this.props.className && this.props.className
        }`}
      onDragOver={this._onDragOver}
      onDragLeave={this._onDragLeave}
      onDragEnter={this._onDragEnter}
      onDrop={this._onDrop}
      onDragEnd={this._onDragEnd}
      i={this.props.i}
      j={this.props.j}
      col={this.props.col}
      onCellClick={this.props.onCellClick}
      updateCellPropertyValue={this.props.updateCellPropertyValue}
      id={this.props.id}
      dataId={this.props.id}
      selectedComponent={this.props.selectedComponent}
      type={this.props.type}
      dropzoneID={this.props.dropzoneCellID}
      properties={this.props.properties}
      key={this.props.key}
      propertyValue={this.props.propertyValue}
      triggerAction={this.props.triggerAction}
      children={
        <>
          {droppedElements.map((e, i) =>
            this._renderDragItem({
              ...e,
              index: i,
              spaceAvailable,
              initDone: this.state.initDone,
              dropzoneProps: {
                initDone: this.state.initDone,
                parentID: e.id,
                projectName: this.props.projectName,
              },
              onSelect: onSelect,
              onDropZone: onDrop,
              setState: setState,
              idocument: idocument,
              selectedComponent: this.props.selectedComponent,
            })
          )}

          {!droppedElements.length ? (
            <p className='dropzone-placeholder'>{placeholder}</p>
          ) : null}
        </>
      }
    />)
    }else{
      return (<></>)
    }
  };

   dispatchTokens= async (props)=>{
    return new Promise((resolve, reject) => {
        if (props) {
          const event = new CustomEvent("tokenGen", {
            detail: {
              id : props.id,
              label:props.label,
              placeholder:props?.propertyValue?.component?.placeholder,
              options:props?.options,
              type:props?.type
            }
          });
          document.dispatchEvent(event);
        } 
        resolve();
    })
  }

  getHeight = (Header, Footer) => {
    if (Header.length && Footer.length === 0) {
      return '92vh';
    } else if (Footer.length && Header.length === 0) {
      return '92vh';
    } else if (Header.length && Footer.length) {
      return '84vh';
    }
  };

  _getPageContainerStyle = () => {
    let _style = {
      flex: 1,
      overflow: 'auto',
      height: '100vh',
      backgroundSize: 'contain',
      width: '100%',
    }
    if(!this.props.propertyValue?.component?.menuStyle){
      _style = {
        ..._style,
        ...this.props.propertyValue?.layout,
        backgroundColor:
          this.props.propertyValue?.style?.backgroundColor,
        opacity: this.props.propertyValue?.style?.opacity,
        ...this.props.propertyValue?.style?.custom,
      }
    }
    return _style;
  }

  _getPageRootStyle = () =>{
    let _style = {
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
      maxHeight: '100%',
    }
    if(!this.props.propertyValue?.component?.menuStyle){
      _style = {
        ..._style,
        backgroundColor: this.props.propertyValue?.style?.backgroundColor,
      }
    }
    _style = {
      ..._style,
      ...(this.props.style ? this.props.style : {}),
    }

    return _style
  }

  _getPageContainerCssClasses = () =>{
    if (this.props.propertyValue?.component?.classes && !this.props.propertyValue?.component?.menuStyle){
      return this.props.propertyValue?.component?.classes
    }
    return ""
  }

  render() {
    const { droppedElements } = this.state;
    const { capacity, id, placeholder, onSelect, onDrop, setState } =
      this.props;
    const spaceAvailable = capacity ? capacity > droppedElements.length : true;
    let idocument = document.getElementById('iframe').contentDocument;
    var content = [],
      Header = [],
      Footer = [],
      NavBar = [];
    droppedElements.filter((e, index) => {
      if (e.type === 'TOPBAR' || e.type === 'PANEL') Header.push(e);
      else if (e.type === 'FOOTER' || e.type === 'BOTTOMBAR') {
        Footer.push(e);
      } else if (e.type === 'NAVBAR') {
        NavBar.push(e);
      } else content.push({ ...e, index: index });
      return true;
    });
    return (
      <>
        {this.props.isPage ? (
          <div
            ref={this.canvasRef}
            className={`${!spaceAvailable ? 'no-space' : ''} ${
              id === 'root' ? 'canvas' : ''
            } ${!droppedElements.length ? 'empty' : ''} dropzone`}
            onDragOver={this._onDragOver}
            onDragLeave={this._onDragLeave}
            onDragEnter={this._onDragEnter}
            onDrop={this._onDrop}
            onDragEnd={this._onDragEnd}
            // style={{
            //   display: 'flex',
            //   flex: 'auto',
            //   flexDirection: 'column',
            //   maxHeight: '100%',
            //   backgroundColor: this.props.propertyValue?.style?.backgroundColor,
            //   ...(this.props.style ? this.props.style : {}),
            // }}
            style={this._getPageRootStyle()}
          >
            <div
              style={{
                flex: 'none',
              }}
            >
              {Header
                ? Header.map((e, i) =>
                    this._renderDragItem({
                      ...e,
                      index: i,
                      spaceAvailable,
                      initDone: this.state.initDone,
                      dropzoneProps: {
                        initDone: this.state.initDone,
                        parentID: e.id,
                        projectName: this.props.projectName,
                      },
                      onSelect: onSelect,
                      onDropZone: onDrop,
                      setState: setState,
                      idocument: idocument,
                      selectedComponent: this.props.selectedComponent,
                    })
                  )
                : null}
            </div>
            <div
              style={{
                flex: 'none',
              }}
            >
              {NavBar
                ? NavBar.map((e, i) =>
                    this._renderDragItem({
                      ...e,
                      index: i,
                      spaceAvailable,
                      initDone: this.state.initDone,
                      dropzoneProps: {
                        initDone: this.state.initDone,
                        parentID: e.id,
                        projectName: this.props.projectName,
                      },
                      onSelect: onSelect,
                      onDropZone: onDrop,
                      setState: setState,
                      idocument: idocument,
                      selectedComponent: this.props.selectedComponent,
                    })
                  )
                : null}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 'auto',
                overflowY: 'auto',
              }}
            >
              <div
                style={{
                  display: 'block',
                  height: this.getHeight(Header, Footer),
                  width: '100%',
                }}
              >
                <Container
                  maxWidth={false}
                  // style={{
                  //   flex: 1,
                  //   overflow: 'auto',
                  //   height: '100vh',
                  //   backgroundSize: 'contain',
                  //   width: '100%',
                  //   ...this.props.propertyValue?.layout,
                  //   backgroundColor:
                  //     this.props.propertyValue?.style?.backgroundColor,
                  //   opacity: this.props.propertyValue?.style?.opacity,
                  //   ...this.props.propertyValue?.style?.custom,
                  // }}
                  style={this._getPageContainerStyle()}
                  ref={this.containerRef}
                  onClick={() => {
                    this.containerOnClick(
                      this.props.propertyValue,
                      this.props.pageName
                    );
                  }}
                  id='__pillirPageContent'
                  className={this._getPageContainerCssClasses()}
                >
                  {content.map((e, i) =>
                    this._renderDragItem({
                      ...e,
                      index: e.index,
                      spaceAvailable,
                      initDone: this.state.initDone,
                      dropzoneProps: {
                        initDone: this.state.initDone,
                        parentID: e.id,
                        projectName: this.props.projectName,
                      },
                      onSelect: onSelect,
                      onDropZone: onDrop,
                      setState: setState,
                      idocument: idocument,
                      selectedComponent: this.props.selectedComponent,
                    })
                  )}

                  {!droppedElements.length ? (
                    <p className='dropzone-placeholder'>{placeholder}</p>
                  ) : null}
                </Container>
              </div>
            </div>
            <div
              style={{
                flex: 'none',
                // alignSelf: 'flex-end',
              }}
            >
              {Footer
                ? Footer.map((e, i) =>
                    this._renderDragItem({
                      ...e,
                      index: i,
                      spaceAvailable,
                      initDone: this.state.initDone,
                      dropzoneProps: {
                        initDone: this.state.initDone,
                        parentID: e.id,
                        projectName: this.props.projectName,
                      },
                      onSelect: onSelect,
                      onDropZone: onDrop,
                      setState: setState,
                      idocument: idocument,
                      selectedComponent: this.props.selectedComponent,
                    })
                  )
                : null}
            </div>
          </div>
        ) : (
          this.props?.component && this._renderDragItem(this.props,true)
        )}
      </>
    );
  }
}

Dropzone.propTypes = {
  id: PropTypes.string.isRequired,
  capacity: PropTypes.number,
  onDrop: PropTypes.func,
  onElementMove: PropTypes.func,
  allowHorizontal: PropTypes.bool,
  initialElements: PropTypes.arrayOf(Object),
  parentID: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Dropzone.defaultProps = {
  initialElements: [],
  placeholder: 'Drop Here',
  onElementMove: () => true,
};

export default Dropzone;
