import { useAppContext } from "../../../components/activity/AppContext";
import { validateValue, validateValues } from './validations';
import { OrganisationActions } from "./organization.actions";
import { useForm } from "../../../components/forms/Form";
import React, { useEffect, useState } from "react";
import styles from "./organisation.module.css";
import messages from "./messages";
import {useLocation} from "react-router-dom";

const inputStyle = { width: "60%" };

function Organisation({perm}) {
  const [data, setData] = useState({
    name: "", tenantId: "", timeZone: "",
    languageCode: "", dateFormat: "", currency: ""
  });
  const [dateFormats, setDF] = useState([]);
  const [languages, setLan] = useState([]);
  const [timezones, setTz] = useState([]);
  const [currencies, setCur] = useState([]);

  const app = useAppContext();
  const location =  useLocation();
  location.state = {permission:perm}

  const onWatch = (errors, data) => {
    validateValue(errors, data.name, data.value)
  }

  const onSubmit = async (values, errors) => {
    if (!validateValues(values, errors))
      return false;
    const actions = OrganisationActions(app());
    const [error, res] = await actions.updateOrganisation(values);
    if (!error) {
      handleCancel();
    }
    return !error;
  };

  useEffect(() => {
    getDependencies();
    getOrganisationDetail();
  }, []);

  const { Form, Label, Input, Select, Error } = useForm(data, onSubmit, onWatch);

  const getDependencies = () => {
    const actions = OrganisationActions(app());

    const getCurrencyList = async () => {
      let [err, data] = await actions.GetCurrencyList();
      if (!err && !data?.error) {
        setCur(data.data)
      }
    }

    const getDateFormatsList = async () => {
      let [err, data] = await actions.GetDateFormatsList();
      if (!err && !data.error) {
        setDF(data.data)
      }
    }

    const getTimezoneList = async () => {
      let [err, data] = await actions.GetTimezoneList();
      if (!err && !data.error) {
        setTz(data.data)
      }
    }

    const GetLanguageList = async () => {
      let [err, data] = await actions.GetLanguageList();
      if (!err && !data.error) {
        setLan(data.data)
      }
    }

    getCurrencyList()
    getDateFormatsList()
    getTimezoneList()
    GetLanguageList()
  }


  const getOrganisationDetail = async () => {
    const actions = OrganisationActions(app());
    let [error, values] = await actions.detail();
    if (!error) {
      setData({
        name: values?.detail?.name,
        tenantId: values?.detail?.tenantId,
        timeZone: values?.detail?.timeZone,
        currency: values?.detail?.currency,
        dateFormat: values?.detail?.dateFormat,
        languageCode: values?.detail?.languageCode,
      });
    };
  };


  const handleCancel = () => {
    getOrganisationDetail();
  };

  return (
    <>
      <Form>
        <div className={styles.main}>
          <div className={styles.heading}>
            <h2 className={styles.title} data-text-testid={"title"}>Organization</h2>
            <p className={styles.subtitle}></p>
          </div>
          <div className={styles.rolesLayoutMainContainer}>
            <div className={styles.oranizationLayout}>
              <h2 className={styles.rolesLyoutTitle} data-text-testid={"subTitle"}>
                Basic Information
              </h2>
              <div className={styles.container}>
                <div className={styles.subcontainer}>
                  <div className={styles.textWrapOrganisation}>
                    <Label htmlFor="name" text={messages.name} />
                    <Input type="text" id="name" name="name" data-input-testid="name" style={inputStyle} maxLength={100} />
                    <Error name={'name'} />
                  </div>
                  <div className={styles.textWrapOrganisation}>
                    <Label htmlFor="tenantId" text={messages.tenantId} />
                    <Input type="text" id="tenantId" name="tenantId" data-input-testid="tenantId" style={inputStyle} disabled={true} />
                    <Error name={'tenantId'} />
                  </div>
                  <div className={styles.textWrapOrganisation}>
                    <Label htmlFor="dateFormat" text={messages.date} />
                    <Select type="text" id="dateFormat" name="dateFormat" data-option-testid="dateFormat" style={inputStyle} >
                      {dateFormats.map((item) => {
                        return (
                          <option value={item.code}>{item.name}</option>
                        )
                      })}
                    </Select>
                    <Error name={'dateFormat'} />
                  </div>

                </div>
                <div className={styles.subcontainer}>
                  <div className={styles.textWrapOrganisation}>
                    <Label htmlFor="languageCode" text={messages.language} />
                    <Select type="text" id="languageCode" name="languageCode" data-option-testid="languageCode" style={inputStyle} >
                      {languages?.map((item) => {
                        return (
                          <option value={item.code}>{item.name}</option>
                        )
                      })}
                    </Select>
                    <Error name={'languageCode'} />
                  </div>
                  <div className={styles.textWrapOrganisation}>
                    <Label htmlFor="timeZone" text={messages.timezone} />
                    <Select type="text" id="timeZone" name="timeZone" data-option-testid="timeZone" style={inputStyle} >
                      {timezones.map((item) => {
                        return (
                          <option value={item.code}>{item.name}</option>
                        )
                      })}
                    </Select>
                    <Error name={'timeZone'} />
                  </div>
                  <div className={styles.textWrapOrganisation}>
                    <Label htmlFor="currency" text={messages.currency} />
                    <Select type="text" id="currency" name="currency" data-option-testid="currency" style={inputStyle} >
                      {currencies?.map((item) => {
                        return (
                          <option value={item.code}>{item.name}</option>
                        )
                      })}
                    </Select>
                    <Error name={'currency'} />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px", alignItems: 'center' }}>
                <Input type="button" value="Cancel" className="secondary" onClick={getOrganisationDetail} data-clickable-testid={"cancel"} />
               {
                location.state.permission?.canUpdate?
                <Input type="submit" value="Save" />:<></>
               }
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
export default Organisation;



