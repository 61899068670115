import { useAppContext } from "../../components/activity/AppContext";
import { validateValue, validateValues } from "./validations";
import { useForm } from '../../components/forms/Form';
import PopUp from "../../components/layouts/PopUp";
import React, { useEffect, useState } from "react";
import { DriveActions } from "./drive.actions";
import styles from "./index.module.css";
import { messages } from "./messages";

function NewFolder(props) {
    const [data, setData] = useState({ name: "", isShared: true });
    const [apiError, setApiError] = useState('');
    const app = useAppContext();
    const {
        show = false,
        update = null,
        projectName = "",
        selectedPath = [],
        setShow = () => null,
        getList = () => null
    } = props;

    useEffect(() => {
        if (update) {
            setData({
                ...update,
                name: update?.displayname || ""
            });
        }
    }, []);

    const handleClose = () => {
        setShow("");
    }

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = async (values, errors) => {
        let parent = "";
        setApiError('');
        let isShared = projectName ? false : true;
        if (!validateValues(values, errors))
            return false;

        if (!update && selectedPath.length) {
            parent = selectedPath.at(-1)?.path || "";
        } else if (update) {
            parent = update?.path || "";
        }

        const drive = DriveActions(app())
        const [error, data] = await drive[!update ? "NewFolder" : "RenameFolder"](projectName, parent, values.name, isShared);

        if (error || data.status.type === 'E') {
            setApiError(data.status.message || error);
            return false;
        } else {
            handleClose();
            getList();
            return true;
        }
    }

    const { Form, Label, Input, Error } = useForm(data, onSubmit, onWatch);

    return (
        <PopUp
            popUp={show}
            setPopUp={handleClose}
            titleClassName={styles.popupTitle}
            testId={`${update ? "rename" : "new"}-folder`}
            title={!update ? messages.newFolder : messages.renameFolder}
        >
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>

                        <Label text={messages.name} htmlFor="name" />
                        <Input type="text" id="name" name="name" />
                        <p className={styles.note}>{messages.note}</p>
                        <Error name={'name'} />

                        <Error message={apiError} />
                    </div>
                </div>

                <div className={styles.afFooter}>
                    <Input type="button" className="secondary" value={"Cancel"} onClick={handleClose} data-clickable-testid={"Cancel"} />
                    <Input type="submit" className="primary" value={"Create"} data-clickable-testid="Create" />
                </div>
            </Form>
        </PopUp>
    )
}

export default NewFolder;