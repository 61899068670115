import React from 'react'
import { getCurrencyList, getDateFormatsList, getLanguageList, getSalutaionList, getTimezoneList } from '../../../../api/administration/organisation/organisation'
import { createUser, resetUserPassword, unlockUser, updateUser, deleteUser } from '../../../../api/administration/usermanagement/userlist'
import ActivityIndicator from '../../../../components/activity/ActivityIndicator'

function UserActions({ show, close, notify }) {

    const GetCurrencyList = async () => {
        const [error, data] = await getCurrencyList()
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];

    }

    const GetDateFormatsList = async () => {
        const [error, data] = await getDateFormatsList()
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const GetLanguageList = async () => {
        const [error, data] = await getLanguageList()
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const GetTimezoneList = async () => {
        const [error, data] = await getTimezoneList()
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const GetSalutaionList = async () => {
        const [error, data] = await getSalutaionList()
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }

    const CreateUserValues = async (input,roleName) => {
        show(() => <ActivityIndicator text={"Creating User"} />)
        const [error, data] = await createUser(input,roleName)
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, data];
        }
        if (data.status.code === 1) {
            notify({ message: data.status.message, type: "S" });
            return [data.status.message, data];
        } else if (data.status.code === 0) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, data];
        }
        return [null, data];
    }
    const UpdateUserValues = async (userName, input) => {
        show(() => <ActivityIndicator text={"Updating User"} />)
        const [error, data] = await updateUser(userName, input)
        // console.log(data)
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, data];
        }
        if (data.status.code === 1) {
            notify({ message: data.status.message, type: "S" });
            return [data.status.message, data];
        }
        return [null, data];
    }

    const UnlockUser = async (loginId) => {
        show(() => <ActivityIndicator text={"Unlocking User"} />)
        const [error, data] = await unlockUser(loginId)
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, data];
        }
        if (data.status.code === 1) {
            notify({ message: data.status.message, type: "S" });
            return [data.status.message, data];
        }
        return [null, data];
    }

    const DeleteUser = async (loginId) => {
        show(() => <ActivityIndicator text={"Deleting User"} />)
        const [error, data] = await deleteUser(loginId)
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, data];
        }
        if (data.status.code === 1) {
            notify({ message: "User Deleted Successfully" || data.status.message, type: "S" });
            return [data.status.message, data];
        }
        return [null, data];
    }

    const ResetPassword = async (userDetails) => {
        show(() => <ActivityIndicator text={"Resetting User password"} />)
        const [error, data] = await resetUserPassword(userDetails)
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.message, data];
        }
        if (data.code===200 && data.status ==='S') {
            notify({ message: data.message, type: "S" });
            return [data.status.message, data];
        }
        return [null, data];
    }




    return { GetCurrencyList, GetSalutaionList, GetDateFormatsList, GetLanguageList, GetTimezoneList, createUser, CreateUserValues, UpdateUserValues, UnlockUser, ResetPassword, DeleteUser }
}

export default UserActions