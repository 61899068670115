import { ReactComponent as TrashIcon } from '../../../../assets/designer-icons/trash.svg';
import { configMessage } from '../../../../common/messages/config';
import AddInput from '../inbox-preview/components/add-input';
import ExprParser from '../../../../utils/expr/ExprParser';
import { KeyboardArrowDown } from '@material-ui/icons';
import Fill from '../../../../assets/images/Fill.svg';
import React, { useState, useEffect } from 'react';
import VariableDropdown from './variable-dropdown';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';
import {
  Box,
  Paper,
  FormControl,
  List,
  ListItem,
  Grid,
  TextField,
  Button,
  InputLabel,
  FilledInput,
  Drawer,
} from '@material-ui/core';


const EmailProperties = function (props) {
  const [currentInput, setCurrentInput] = useState(null);
  const [subjectError, setSubjectError] = useState('');
  const [systemsList, setSystemsList] = useState([]);
  const [showRoles, setShowRoles] = useState(null);
  const [titleError, setTitleError] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [property, setProperty] = useState({});
  const [type, setType] = useState('');
  const classes = useStyles();

  const textStringVal = "'Text goes here'";
  const errorMsg = 'Please enter a valid string.';
  const workItemList =  [
    {name: "createdBy", varType: "$WORKITEM."},
    {name: "updatedBy", varType: "$WORKITEM."} 
  ];

  const getNonTableVariables = (arr = []) => {
    return arr?.filter(e => e.type !== "Table") || [];
  }

  const functions = [
    { name: "Web Url", varType: "$FUNCTION.", isMenu: true, item: getNonTableVariables(props.globalVariable)},
    { name: "Device Url", varType: "$FUNCTION.", isMenu: false },
  ]

  useEffect(() => {
    setProperty(JSON.parse(props.currentCell?.data || "{}"));
    setRoleList(props.roles || []);
    props.getSystemList(["EMAIL"], setSystemsList);
    props.getContainerAppsList();
  }, []);

  const close = () => {
    props.updateBlockProperties(property);
    props.onClose();
  };

  const handleChange = (value, key = "") => {
    let propertyVal = { ...property };
    propertyVal[key] = value;
    setProperty(propertyVal);
  }

  const handleRemoveFilter = (name, propertyType = '') => {
    let propertyTo = property?.[propertyType] || [];
    propertyTo = propertyTo.filter(e => e !== name);
    setProperty({ ...property, [propertyType]: propertyTo });
  }

  const handleVariableFormat = (name = '') => {
    if(name.startsWith("$CUSTOM.")){ 
      name = name.replace('$CUSTOM.', '');
    }else if(name.startsWith('$BF.')){
      name = "GETASARRAY('" + name + "')";
    }
    return name;
  }

  const handleListItemClick = (name = '') => {
    name = handleVariableFormat(name);
    let propertyTo = property?.[type] || [];
    if(propertyTo?.indexOf(name) === -1){
      propertyTo.push(name);
    }
    setProperty({ ...property, [type]: propertyTo });
  }

  const setThefocusField = (event) => {
    setCurrentInput(event.currentTarget);
  };

  const validateString = (value) => {
    let expr = new ExprParser();
    return expr.parse(value);
  };

  const updateVariableOnTextField = (value, field) => {
    handleTextClick(field, value);
  };

  const checkAndUpdateSubject = (updatedString) => {
    setProperty({ ...property, subject: updatedString});

    // if (updatedString.length <= 200) {
    //   setProperty({ ...property, subject: updatedString});
    //   if (validateString(updatedString)) {
    //     setSubjectError('');
    //   } else {
    //     setSubjectError(errorMsg);
    //   }
    // } else {
    //   props.alertShow(configMessage.W4514, configMessage.E4501);
    // }
  }

  const checkAndUpdateTitle = (updatedString) => {
    setProperty({ ...property, title: updatedString});

    // if (updatedString.length <= 100) {
    //   setProperty({ ...property, title: updatedString});
    //   if (validateString(updatedString)) {
    //     setTitleError('');
    //   } else {
    //     setTitleError(errorMsg);
    //   }
    // } else {
    //   props.alertShow(configMessage.W4513, configMessage.E4501);
    // }
  }

  const onChange = (event) => {
    let inputField = event.currentTarget;
    let updatedString = event.target.value || '';
    if (inputField.id === 'subjectField') {
      checkAndUpdateSubject(updatedString);
    } else if (inputField.id === 'titleField') {
      checkAndUpdateTitle(updatedString);
    }
  };


  const handleTextClick = (event, value) => {
    let textToAdd = event?.currentTarget?.value || value;
    let currentTextField;
    if (
      (event && typeof event === 'object' && event.currentTarget.id === 'titleBtn') ||
      (event && typeof event === 'string' && event === 'titleBtn')
    ) {
      currentTextField = document.getElementById('titleField') || currentInput;
    } else if (
      (event && typeof event === 'object' && event.currentTarget.id === 'subjectBtn') ||
      (event && typeof event === 'string' && event === 'subjectBtn')
    ) {
      currentTextField = document.getElementById('subjectField') || currentInput;
    }
    let cursorPos = currentTextField?.selectionStart;
    let v = currentTextField?.value || "";
    if(!currentInput || currentInput != currentTextField){
      cursorPos = v.length || 0;
    }
    let textBefore = v?.substring(0, cursorPos);
    let textAfter = v?.substring(cursorPos, v.length);
    if (textBefore && !textBefore.trim()?.endsWith('+')) {
      textToAdd = '+ ' + textToAdd;
    }
    if (textAfter && !textAfter.trim()?.startsWith('+')) {
      textToAdd = textToAdd + ' +';
    }
    cursorPos += textToAdd?.length;
    currentTextField.focus();
    currentTextField.setSelectionRange(cursorPos, cursorPos);
    let updatedString = textBefore + textToAdd + textAfter;
    if (currentTextField.id === 'subjectField') {
      checkAndUpdateSubject(updatedString);
    } else if (currentTextField.id === 'titleField') {
      checkAndUpdateTitle(updatedString);
    }
  };

  const getTableVariables = (arr = []) => {
    let tableVariables =  arr?.filter(e => e.type === "Table") || [];
    return tableVariables.map(e => {
      e.isMenu = true; 
      return e;
    })
  }


  return (
    <Drawer
      anchor='right'
      open={props.show}
      onClose={close}
    >
    <Box component='div' className={classes.root}>
      <Paper className={classes.secionTitle}>
        <span>Properties</span>
        <CloseIcon onClick={() => close()} />
      </Paper>
      <Box component='div'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ paddingTop: '22px' }}>
                <span>Name</span>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  disabled
                  variant='outlined'
                  style={{ width: '100%' }}
                  inputProps={{ maxLength: 45 }}
                  value={props.currentCell?.value}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid xs={12} className={classes.formControlFieldWithErr}>
                <Box component='div' className={classes.fieldControls}>
                  <select 
                    className={classes.systems} 
                    value = {property?.system || ""}
                    onChange={(e) => handleChange(e.target.value, "system")}
                  >
                    <option value="">Select System</option>
                    {
                      systemsList.map(e => (
                          <option value={e.value}>{e.value}</option>
                      ))
                    }
                  </select>
                </Box>
            </Grid>
          </ListItem>
          { 
            property.system &&
            <>
                <ListItem>
                  <FormControl
                    component='fieldset'
                    className={classes.conditionFieldSet}
                  >
                    <Grid container className={classes.conditionTitle}>
                      <Grid item xs={4} className={classes.ListAlt}>
                        <Box component='div' className={classes.pt2}>
                          <img src={Fill} alt='' />
                        </Box>
                        <Box component='div' className={classes.pl8}>
                          To
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.operandRightInput3}>
                      {
                        (property?.to || []).map((e, index) => {
                        return (
                          <Box item className={classes.sectionRoles}>
                            {e}
                            <TrashIcon 
                              className='trash-icon'
                              onClick={() => handleRemoveFilter(e, 'to')}
                            />
                          </Box>
                        )})
                      }
                      </Grid>
                    </Grid>
                  </FormControl>
                </ListItem>
              <ListItem>
                <Grid container spacing={2} className={classes.containerAddMore}>
                    <Button
                      color='secondary'
                      variant='contained'
                      className={classes.addButton}
                      endIcon={<KeyboardArrowDown />}
                      onClick={(e) => {
                        setType('to');
                        setShowRoles(e.currentTarget);
                      }}
                    >
                      ADD
                    </Button>
                </Grid>
              </ListItem>
              <ListItem>
                  <FormControl
                    component='fieldset'
                    className={classes.conditionFieldSet}
                  >
                    <Grid container className={classes.conditionTitle}>
                      <Grid item xs={4} className={classes.ListAlt}>
                        <Box component='div' className={classes.pt2}>
                          <img src={Fill} alt='' />
                        </Box>
                        <Box component='div' className={classes.pl8}>
                          CC
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.operandRightInput3}>
                      {
                        (property?.cc || []).map((e, index) => {
                        return (
                          <Box item className={classes.sectionRoles}>
                            {e}
                            <TrashIcon 
                              className='trash-icon'
                              onClick={() => handleRemoveFilter(e, 'cc')}
                            />
                          </Box>
                        )})
                      }
                      </Grid>
                    </Grid>
                  </FormControl>
                </ListItem>
              <ListItem>
                <Grid container spacing={2} className={classes.containerAddMore}>
                    <Button
                      color='secondary'
                      variant='contained'
                      className={classes.addButton}
                      endIcon={<KeyboardArrowDown />}
                      onClick={(e) => {
                        setType('cc');
                        setShowRoles(e.currentTarget);
                      }}
                    >
                      ADD
                    </Button>
                </Grid>
              </ListItem>
            <ListItem>
              <Grid xs={12} className={classes.formControlFieldWithErr}>
                <InputLabel htmlFor='descName' className='titleLayout'>
                  Subject
                </InputLabel>
                <Box component='div' className={classes.fieldControls}>
                  <FilledInput
                    rows={5}
                    multiline
                    minRows={5}
                    rowsMax={10}
                    type={'text'}
                    name={"title"}
                    disableUnderline
                    id={'titleField'}
                    onChange={onChange}
                    errorText={titleError}
                    onFocus={setThefocusField}
                    value={property?.title || ""}
                    // inputProps={{ maxLength: 100 }}
                    className={classes.noBackground}
                    helperText={titleError !== '' ? 'Please enter a valid input' : ''}
                  />
                  <Box component='div' className={classes.btnControls}>
                    <Button
                      disableRipple
                      id='titleBtn'
                      variant='contained'
                      value = {textStringVal}
                      onClick={handleTextClick}
                      className={classes.textButton}
                    >
                      "Text"
                    </Button>
                    <Box className={classes.textButton}>
                      <AddInput
                        value='$'
                        disabled={true}
                        id={'titleBtn'}
                        usedColumns={[]}
                        callType={'condition'}
                        workItems={props.workItemList}
                        userPropertyList={props.userPropertyList}
                        selectedVariable={property?.title || ""}
                        setVariableValueToTextField={() => null}
                        updateVariableOnTextField={updateVariableOnTextField}
                        bfVariable={getNonTableVariables(props.variableList)}
                        projVariable={getNonTableVariables(props.projVariable)}
                        globalVariable={getNonTableVariables(props.globalVariable)}
                        handleAddVariable={(value) => handleChange(value, "title")}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box component='span' className={classes.error}>
                  {titleError ? ' *' + titleError : ''}
                </Box>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid xs={12} className={classes.formControlFieldWithErr}>
                <InputLabel htmlFor='state' className='titleLayout'>
                  Message
                </InputLabel>
                <Box component='div' className={classes.fieldControls}>
                  <FilledInput
                    rows={5}
                    multiline
                    minRows={5}
                    rowsMax={10}
                    type={'text'}
                    name={"subject"}
                    disableUnderline
                    onChange={onChange}
                    id={'subjectField'}
                    errorText={subjectError}
                    onFocus={setThefocusField}
                    value={property?.subject || ""}
                    // inputProps={{ maxLength: 100 }}
                    className={classes.noBackground}
                    helperText={subjectError !== '' ? 'Please enter a valid input' : ''}
                  />
                  <Box component='div' className={classes.btnControls}>
                    <Button
                      disableRipple
                      id='subjectBtn'
                      variant='contained'
                      value = {textStringVal}
                      onClick={handleTextClick}
                      className={classes.textButton}
                    >
                      "Text"
                    </Button>
                    <Box className={classes.textButton}>
                      <AddInput
                        value='$'
                        disabled={true}
                        usedColumns={[]}
                        id={'subjectBtn'}
                        functions={functions}
                        callType={'condition'}
                        className={classes.textButton}
                        workItems={props.workItemList}
                        containerApps={props.containerApps}
                        userPropertyList={props.userPropertyList}
                        selectedVariable={property?.subject || ""}
                        setVariableValueToTextField={() => null}
                        updateVariableOnTextField={updateVariableOnTextField}
                        bfVariable={getNonTableVariables(props.variableList)}
                        projVariable={getNonTableVariables(props.projVariable)}
                        globalVariable={getNonTableVariables(props.globalVariable)}
                        handleAddVariable={(value) => handleChange(value, "subject")}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box component='span' className={classes.error}>
                  {subjectError ? ' *' + subjectError : ''}
                </Box>
              </Grid>
            </ListItem>
            </>
          }
        </List>
      </Box>
      <VariableDropdown 
        usedColumns = {[]}
        selectedVariable={''}
        anchorEl = {showRoles}
        showCustomList = {true}
        customList = {roleList}
        workItems={workItemList}
        onClose = {setShowRoles}
        customType = {{ key: '$CUSTOM.', label: 'Roles' }}
        bfVariable={getTableVariables(props.variableList)}
        handleAddVariable={(value) => handleListItemClick(value)}
      />
    </Box>
    </Drawer>
  );
};

export default EmailProperties;