import messages from "./loginsMessages";

const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validateName = (errors, value) => {
    return required(errors, 'name', value, messages.invalidName)
}

const validateDescription = (errors, value) => {
    return required(errors, 'description', value, messages.invalidDesc)
}

const validateValue = (errors, name, value) => {
    switch (name) {
        case 'name':
            return validateName(errors, value)
        case 'description':
            return validateDescription(errors, value);
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value))
            valid = false
    })
    return valid
}

export { validateValue, validateValues, required }
