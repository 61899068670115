import store from "../stores";
import DataService from "../utils/data-service";
import { showNotification } from "../actions/app-root";
import {
  loadWorkflowList,
  loadWorkLogsList,
  loadWorkflowListAll,
  loadWorkitemList,
} from "../actions/all-items";
import { configMessage } from "../common/messages/config";
import { FUNCTION_API, WORKFLOW_LIST_API, pillirConstants,USER_API } from "../constants";

const alertShow = (data) => {
  var param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: configMessage.E4501,
    message: res.message,
  };

  alertShow(errorData);
};

const responseStatus = (status) => {
  if (status?.toLowerCase() === "success") {
    return status?.toLowerCase() === pillirConstants.success;
  } else if (status?.toLowerCase() === "error") {
    return status?.toLowerCase() === pillirConstants.error;
  }
};

export function fetchWorklist(val, filter = {}) {
  const preview = `&is_preview=true`;
  let params = {
    uri:
      `${WORKFLOW_LIST_API}/workFlowList?` +
      `$select=workflowId,` +
      `status,businessKey,` +
      `subject,title,assignedRole,` +
      `projectName,itemCurrentStatus` + //userName
      `&$sort=desc w.id` +
      `${preview}`,
  };

  let filterStr = "";

  if (filter && Object.keys(filter).length !== 0) {
    Object.keys(filter).map((item, index) => {
      if (filter[item]) {
        filterStr = `${filterStr}${filterStr ? " AND " : " "}${item} LIKE '%25${
          filter[item]
        }%25'`;
      }
    });
    filterStr = val && filterStr ? `AND${filterStr}` : `${filterStr}`;
  }
  params.uri = `${params.uri}&$filter=${val}${filterStr}`;

  DataService.read(params).then(
    (result) => {
      store.dispatch(loadWorkitemList(result.data));
    },
    (error) => {
      alertShow(error);
    }
  );
}

export function fetchWorkflowList(filter, pagination) {
  let limit = pagination.rowsPerPage;
  let offset = pagination.newPageNumber * limit || 0;
  const paging =
    limit || offset ? `&limit=${limit || 10}&offset=${offset}` : "";
  let params = {
    uri:
      `${WORKFLOW_LIST_API}/workListAll?` +
      `$select=functionId,workflowId,` +
      `status,businessKey,createdDate,` +
      `firstName,lastName,timeSpent,laneName,` +
      `subject,title,assignedRole,` +
      `modifiedDate,projectName,version,itemCurrentStatus,assignedTo` + //userName
      `&$sort=desc w.modified_date` +
      `${paging}`,
  };

  let filterStr = "";
  if (filter && Object.keys(filter).length !== 0) {
    Object.keys(filter).map((item, index) => {
      if (filter[item] && item !== "user") {
        filterStr = `${filterStr}${filterStr ? " AND " : " "}${item} LIKE '%25${
          filter[item]
        }%25'`;
      } else if (filter[item] && item === "user") {
        filterStr = `${filterStr}${
          filterStr ? " AND " : " "
        }u.first_name LIKE %27%25${
          filter[item]
        }%25%27 OR u.last_name LIKE %27%25${filter[item]}%25%27`;
      }
    });
    params.uri = `${params.uri}&$filter=${filterStr}`;
  }
  DataService.read(params).then(
    (result) => {
      store.dispatch(loadWorkflowList(result.data));
    },
    (error) => {
      alertShow(error);
    }
  );
}

export function fetchWorkflowListAll() {
  let params = {
    uri: `${WORKFLOW_LIST_API}/workListAll?` + `$select=status,functionId`,
  };

  DataService.read(params).then(
    (result) => {
      store.dispatch(loadWorkflowListAll(result.data));
    },
    (error) => {
      alertShow(error);
    }
  );
}

export function fetchWorkflowById(id) {
  let params = {
    uri:
      `${WORKFLOW_LIST_API}/logs?` +
      `$select=actor,userName,createdDate,` +
      `&$filter=workflow_id EQ '${id}'`,
  };

  DataService.read(params).then(
    (result) => {
      store.dispatch(loadWorkLogsList(result.data));
    },
    (error) => {
      alertShow(error);
    }
  );
}

export function fetchUsers(param) {
  let params = {
    uri:`${USER_API}/listUserAndTheirRoles?$select=id,loginName,fullName&limit=10&$filter=isActive EQ true`,
  };

  DataService.read(params).then(
    (result) => {
      param(result.data);
    },
    (error) => {
      alertShow(error);
    }
  );

}
export function searchUsers(searchText,param) {
  const lowercasedFilter = searchText.toLowerCase();
  let filter = ` user.login_name LIKE '%25${lowercasedFilter}%25' AND isActive EQ true`;
  let params = {
    uri:`${USER_API}/listUserAndTheirRoles?$select=id,loginName,fullName&limit=10&$filter=${filter}`,
  };

  DataService.read(params).then(
    (result) => {
      if(result?.data)
        param(result.data);
    },
    (error) => {}
  );
}

/* Workitem list delete */
export function fetchWorkitemDelete(id, filter, pagination) {
  let params = {
    uri: `${WORKFLOW_LIST_API}`,
    data: id,
  };
  DataService.delete(params).then(
    (result) => {
      if (!result.data?.error) {
        const { data, status } = result;
        if (status === 200) {
          const success = {
            status:
              result.data?.status?.type === "S"
                ? configMessage.S4501
                : configMessage.E4501,
            message: result.data?.status?.message,
          };
          alertShow(success);
          fetchWorkflowList(filter, pagination);
          fetchWorkflowListAll();
        }
      } else if (result.data?.error && responseStatus(result.data?.status)) {
        errorData(result);
      }
    },
    (error) => {
      // console.log('User Property List Error');
    }
  );
}

export function revertWorkflow({ projName, bfName, wId }, filter, pagination) {
  let function_id = `${projName}.${bfName}`;
  let params = {
    uri: `${FUNCTION_API}/${function_id}/revert?project_name=${projName}&function_id=${function_id}&workflow_id=${wId}`,
  };

  DataService.create(params).then(
    (result) => {
      let status = result.data?.status;
      status.type = status?.code ? configMessage.S4501 : configMessage.E4501;
      alertShow(status);
      if (status?.code) {
        fetchWorkflowList(filter, pagination);
        fetchWorkflowListAll();
      }
    },
    (error) => {
      errorData(error);
    }
  );
}

export function retryWorkflow({ projName, bfName, wId }, filter, pagination) {
  let function_id = `${projName}.${bfName}`;
  let params = {
    uri: `${FUNCTION_API}/${function_id}/retry?project_name=${projName}&function_id=${function_id}&workflow_id=${wId}`,
  };

  DataService.create(params).then(
    (result) => {
      let status = result.data?.status;
      status.type = status?.code ? configMessage.S4501 : configMessage.E4501;
      alertShow(status);
      if (status?.code) {
        fetchWorkflowList(filter, pagination);
        fetchWorkflowListAll();
      }
    },
    (error) => {
      errorData(error);
    }
  );
}

export function restartWorkflow({ projName, bfName, wId }, filter, pagination) {
  let function_id = `${projName}.${bfName}`;
  let params = {
    uri: `${FUNCTION_API}/${function_id}/restart?project_name=${projName}&function_id=${function_id}&workflow_id=${wId}`,
  };

  DataService.create(params).then(
    (result) => {
      let status = result.data?.status;
      status.type = status?.code ? configMessage.S4501 : configMessage.E4501;
      alertShow(status);
      if (status?.code) {
        fetchWorkflowList(filter, pagination);
        fetchWorkflowListAll();
      }
    },
    (error) => {
      errorData(error);
    }
  );
}

export function reAssign(postdata,workflowid,setUserDialog) {
  let params = {
    uri: `${WORKFLOW_LIST_API}/${workflowid}`,
    data: postdata
  };

  DataService.update(params).then(
    (result) => {
      let status = result.data?.status;
      status.type = status?.code ? configMessage.S4501 : configMessage.E4501;
      alertShow(status);
      if (status?.code) {
        setUserDialog(false);
        //fetchWorkflowList(filter, pagination);
        //fetchWorkflowListAll();
      }
    },
    (error) => {
      errorData(error);
    }
  );
}
export function fetchWorkflowDetailsById(workflowid,param) {
  let params = {
    uri: `${WORKFLOW_LIST_API}/${workflowid}`,
  };

  DataService.read(params).then(
    (result) => {
      //store.dispatch(loadUserList(result.data));
      param(result.data);
    },
    (error) => {
      alertShow(error);
    }
  );

}
