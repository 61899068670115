import React from "react";

class Command {
    constructor(object){
        this.object = object;
    }
    undo() {}
    redo() {}
    deleteNode(nodeList, node) {
        const index = nodeList.indexOf(node);
        if (index > -1) {
            nodeList.splice(index, 1);
        }
    }
}

class CreateNodeCommand extends Command{
    constructor(object, index){
        super(object);
        this.action = "createNode";
        this.actionType = "create";
        this.index = index;
    }
    undo(){
        if (this.object.parent)
            this.deleteNode(this.object.parent.childs, this.object);
        else
            this.deleteNode(window.application.ui.nodeList, this.object);
    }
    redo(){
        if (this.object.parent)
            this.object.parent.childs.splice(this.index, 0, this.object);
        else {
            window.application.ui.add(this.object);
         }  
    }
}

class DeleteNodeCommand extends Command{
    constructor(object,index){
        super(object);
        this.action = "deleteNode";
        this.actionType = "node";
        this.index = index;        
    }
    undo(){
        if ((this.object.type.startsWith('db') || this.object.type.startsWith('sf') || this.object.type == 'func' || this.object.type == 'rpr')
            &&  this.object.system && this.object.system.system){
            window.application.ui.addSystem(this.object.system.system);
        }
        if(this.object.type == 'boss'){
            window.application.ui.addServices(this.object.uuid);
        }
        if (this.object.parent){
            if (this.object.parent.type == 'ie'){
                if (this.object.ifChildType == 'else')
                    this.object.parent.elseChilds.splice(this.index, 0, this.object);
                else
                    this.object.parent.ifChilds.splice(this.index, 0, this.object);
            }
            else
                this.object.parent.childs.splice(this.index, 0, this.object);
        }else {
            window.application.ui.add(this.object);
        }
    }
    redo(){
        if ((this.object.type.startsWith('db') || this.object.type.startsWith('sf') || this.object.type == 'func' || this.object.type == 'rpr')
            &&  this.object.system && this.object.system.system){
            window.application.ui.removeSystem(this.object.system.system);
        }
        if(this.object.type == 'boss'){
            window.application.ui.removeServices(this.object.uuid);
        }
        if (this.object.parent){
            if (this.object.parent.type == 'ie'){
                if (this.object.ifChildType == 'else')
                    this.object.parent.elseChilds.splice(this.index, 1);
                else
                    this.object.parent.ifChilds.splice(this.index, 1);
            }
            else
                this.object.parent.childs.splice(this.index, 1);
        }else
            window.application.ui.nodeList.splice(this.index, 1);
    }
}

class CommentCommand extends Command{
    constructor(object){
        super(object);
        this.action = "comment";
    }
    undo(){
        this.object.isCommented = 0;
    }
    redo(){
        this.object.isCommented = 1;
    }
}

class UnCommentCommand extends Command{
    constructor(object){
        super(object);
        this.action = "comment";
    }
    undo(){
        this.object.isCommented = 1;
    }
    redo(){
        this.object.isCommented = 0;
    }
}

class MinimizeCommand extends Command{
    constructor(object){
        super(object);
        this.action = "minimize";
        this.actionType = "node";
    }
    undo(){
        this.object.minimize = 0;
    }
    redo(){
        this.object.minimize = 1;
    }
}

class ExpandCommand extends Command{
    constructor(object){
        super(object);
        this.action = "expand";
        this.actionType = "node";
    }
    undo(){
        this.object.minimize = 1;
    }
    redo(){
        this.object.minimize = 0;
    }
}
class CopyCommand extends Command{
    constructor(object){
        super(object);
        this.action = "copy";
        this.actionType = "node";
    }
    undo(){
        window.application.copyNode = null;
    }
    redo(){
        window.application.copyNode = this.object;
    }
}

class CutCommand extends Command{
    constructor(object){
        super(object);
        this.action = "copy";
        this.actionType = "node";
    }
    unbo(){
        if (this.object.parent)
            this.object.parent.addChild(this.object);
        else
            window.application.ui.add(this.node);
        window.application.copyNode = null;
    }
    redo(){
        if (this.object.parent)
            this.deleteNode(this.object.parent.childs, this.object);
        else
            this.deleteNode(window.application.ui.nodeList, this.object);
        window.application.copyNode = this.object;
    }
}

class ChangeCommand extends Command{
    constructor(object,tag,previousVal,currentValue,i){
        super(object);
        this.previous = previousVal;
        this.current = currentValue;
        this.tag = tag;
        this.i = i;
    }
    undo(){
        if (typeof (this.object) == 'string' && !this.tag) {
            this.object = this.previous;
        }
        else if (this.tag instanceof Array) {
            for (let i = 0; i < this.tag.length; i++) {
                if (this.i){
                    if (this.object[this.i].hasOwnProperty(this.tag[i])){
                        this.object[this.i][this.tag[i]] = this.previous[i];
                    }
                }
                else{
                    if (this.object.hasOwnProperty(this.tag[i])){
                        this.object[this.tag[i]] = this.previous[i];
                    }
                }
            }
        } else {
            if (this.i){
                if (this.object[this.i].hasOwnProperty(this.tag)){
                    this.object[this.i][this.tag] = this.previous;
                }
            }
            else{
                if (this.object.hasOwnProperty(this.tag)){
                    this.object[this.tag] = this.previous;
                }
            }
        }
    }
    redo(){
        if (typeof (this.object) == 'string' && !this.tag) {
            this.object = this.current;
        }
        else if (this.tag instanceof Array) {
            for (let i = 0; i < this.tag.length; i++) {
                if (this.i){
                    if (this.object[this.i].hasOwnProperty(this.tag[i])){
                        this.object[this.i][this.tag[i]] = this.current[i];
                    }
                }
                else{
                    if (this.object.hasOwnProperty(this.tag[i])){
                        this.object[this.tag[i]] = this.current[i];
                    }
                }
            }
        } else {
            if (this.i){
                if (this.object[this.i].hasOwnProperty(this.tag)){
                    this.object[this.i][this.tag] = this.current;
                }
            }
            else{
                if (this.object.hasOwnProperty(this.tag)){
                    this.object[this.tag] = this.current;
                }
            }
        }
    }
}

class ChangeGroupCommand extends Command{
    constructor(objects, tags, previousVal, currentValue, i, iTag){
        super(objects);
        this.objects = objects;
        this.previous = previousVal;
        this.current = currentValue;
        this.tags = tags;
        this.i = i;
        this.iTag = iTag;
    }
    undo(){
        if (this.tags instanceof Array && this.objects instanceof Array) {
            for (let i = 0; i < this.tags.length; i++) {
                if (this.i){
                    if (this.object[i][this.i].hasOwnProperty(this.tags[i]))
                        this.object[i][this.i][this.tags[i]] = this.previous[i];
                }
                else{
                    if (this.object[i].hasOwnProperty(this.tags[i]))
                        this.object[i][this.tags[i]] = this.previous[i];
                }
            }
        } else {
            if (this.object.hasOwnProperty(this.tags))
                this.object[this.tags] = this.previous;
        }
    }
    redo(){
        if (this.tags instanceof Array && this.objects instanceof Array) {
            for (let i = 0; i < this.tags.length; i++) {
                if (this.i){
                    if (this.object[i][this.i].hasOwnProperty(this.tags[i]))
                        this.object[i][this.i][this.tags[i]] = this.current[i];
                }
                else{
                    if (this.object[i].hasOwnProperty(this.tags[i]))
                        this.object[i][this.tags[i]] = this.current[i];
                }
            }
        } else {
            if (this.object.hasOwnProperty(this.tags))
                this.object[this.tags] = this.current;
        }
    }
}
class SpliceCommand extends Command{
    constructor(object, index, obj){
        super(object);
        this.obj = obj;
        this.index = index;
    }
    undo(){
        this.object.splice(this.index, 0, this.obj);
    }
    redo(){
        this.object.splice(this.index, 1);
    }
}

class PopCommand extends Command{
    constructor(object,obj){
        super(object);
        this.obj = obj;
    }
    undo(){
        this.object.pop();
    }
    redo(){
        this.object.push(this.obj);
    }
}

class CreateVariableCommand extends Command{
    constructor(object,variable){
        super(object);
        this.type = variable;
    }
    undo(){
        window.application.ui.remove(this.object.name, this.type);
    }
    redo(){
        window.application.ui.setParam(this.object, this.object.name, this.type);
    }
}
class MoveCommand extends Command{
    constructor(object,prevNode,prevIndex,currentNode,currentIndex,moveType){
        super(object);
        this.prevNode=prevNode;
        this.prevIndex=prevIndex;
        this.currentNode=currentNode;
        this.currentIndex=currentIndex;
        this.moveType=moveType;
    }
    undo(){
        this.currentNode.childs.splice(this.currentIndex,1);
        if(this.prevNode.type=='ie'){
            if(this.moveType=='if')
                this.prevNode.ifChilds.splice(this.prevIndex,0,this.object);
            else
                this.prevNode.elseChilds.splice(this.prevIndex,0,this.object);
        }else
            this.prevNode.childs.splice(this.prevIndex,0,this.object);
        this.object.parent = this.prevNode;
    }
    redo(){
        if(this.prevNode.type=='ie'){
            if(this.moveType=='if')
                this.prevNode.ifChilds.splice(this.prevIndex,1);
            else
                this.prevNode.elseChilds.splice(this.prevIndex,1);
        }else
            this.prevNode.childs.splice(this.prevIndex,1);
        this.currentNode.childs.splice(this.currentIndex,0,this.object);
        this.object.parent = this.currentNode;
    }
}
class CommandStack{
    constructor(){
        this.undoStack = new Array();
        this.redoStack = new Array();
    }
    add(command){
        if (this.undoStack.length >= 10)
            this.undoStack.splice(0, 1);
        this.undoStack.push(command);
        this.redoStack = new Array();
        //window.application.enableHeaderButtons({redo:false,undo:true});
    }
    undo(){
        if (this.undoStack.length > 0){
            var command = this.undoStack.pop();
            command.undo();
           // this.undoStack.length == 0 ? window.application.enableHeaderButtons({redo:true,undo:false}) : window.application.enableHeaderButtons({redo:true,undo:true});
            this.redoStack.push(command);
        }
    }
    redo(){
        if (this.redoStack.length > 0){
            var command = this.redoStack.pop();
            command.redo();
           // this.redoStack.length == 0 ? window.application.enableHeaderButtons({redo:false,undo:true}) : window.application.enableHeaderButtons({redo:true,undo:true});
            this.undoStack.push(command);
        }
    }
}

export {
    Command,
    CreateNodeCommand,
    DeleteNodeCommand,
    MinimizeCommand,
    ExpandCommand,
    CopyCommand,
    CutCommand,
    ChangeCommand,
    SpliceCommand,
    PopCommand,
    CreateVariableCommand,
    CommentCommand,
    UnCommentCommand,
    CommandStack,
    ChangeGroupCommand,
    MoveCommand
}