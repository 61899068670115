import React,{useState, useEffect} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useStyles from './style';
import {s3Url,getPermissions} from "../../utils/common";
import DataService from "../../utils/data-service"
import { Grid, Box,  } from '@material-ui/core';
import { getEnvironment } from '../../utils/common';
import { projectFileConstants } from '../../constants';
import { configMessage } from '../../common/messages/config';

function ProcessCard(props){
    const classes = useStyles();
    const[projectSVG,setProjectSVG]=useState(undefined);
    const[isNewCommit,setIsNewCommit]=useState(false);
    const onClick = ()=> {
      props.navigateToBusiness(props.projectType, props.project.name,props.project)
    }

    useEffect(()=>{
        var environment = getEnvironment(sessionStorage.getItem('env'))
        if(props.project != undefined &&  props.project.object != undefined && environment !== "Development") {
            props.project.object.split(',').map((value, index) => {
                if (value == "New")
                {
                    setIsNewCommit(true);
                }
            })
        }
    
    if(props.project&&(props.project.screenshot)&&!projectSVG){
        var param={uri:s3Url(props.project.screenshot)};
        DataService.download(param).then(d=>{
            setProjectSVG(d.data);
        }).catch(e=>{

        })
    }

    },[])

    const clickHandler=()=>{
        
        if(props.projectType=='businessProccess' && getPermissions()?.projects?.business_process?.canView){
            onClick();
        }
        else if(props.projectType=='businessFunction' && getPermissions()?.projects?.business_function?.canView){
            onClick();
        }

    }
    return (
        <Box component="div" className={classes.process} onClick={clickHandler}>
            <Box className={"process-li"}>
                <Box className="grid-1 empty-process">
                    {/* {projectSVG?<span dangerouslySetInnerHTML={{__html: projectSVG}}/>:
                      props.project.image ? <CardMedia component="img" src={`${props.project.image}`} alt=""/>
                      :null
                    }  */}
                    { projectSVG ? <span dangerouslySetInnerHTML={{__html: projectSVG}}/> : <></>}
                </Box>

                <Box className="grid-2" key={props.project.id}>
                    <Grid className="name-bar">
                        <h5 className="prj_name black">{props.project.name}</h5>
                        {
                            props.project?.action?.toLowerCase() === "modernizing" ? 
                                <Box className="modernize-status">
                                    <div className="modernizing-mark"></div>
                                    <label className='modernize-label'>{`${props.project?.action}...`}</label> 
                                </Box>
                                 : <label>{props.project.last_edited}</label>
                        }
                        {props.project.object !== undefined && isNewCommit ?
                            <Box style={{display: "flex"}}>
                                <Box className="new_commit_dot"></Box>
                                <h5 className="new_commit"> {configMessage.T4567}</h5>
                            </Box> : <></>
                        } 
                    </Grid>
                    <Grid className="menu-bar">
                        {/*<MoreVertIcon /> */}
                    </Grid>
                </Box>
            </Box>
        </Box>
     )
}
export default ProcessCard;