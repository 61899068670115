import React from "react";
import { useState } from 'react';
import PopUp from "../../../../components/layouts/PopUp";
import PublishServiceStatus from './publishStatus';
import PublishServiceForm from './publishServiceForm';

export default function PublishService(props) {
    const { getList, publish, setPublish } = props;
    const [requestId, setRequestId] = useState('');

    const handleClose = () => {
        setPublish({ show: false, item: "" })
        getList();
    }

    const [renderComponent, setRenderComponent] = useState("PublishForm")

    return (
        <PopUp popUp={publish.show} setPopUp={handleClose}>
            {renderComponent === "PublishForm" ? <PublishServiceForm setRenderComponent={setRenderComponent} handleClose={handleClose} serviceData={publish.item} setRequestId={setRequestId} /> : ""}
            {renderComponent === "PublishStatus" ? <PublishServiceStatus handleClose={handleClose} requestId={requestId} serviceData={publish.item} /> : ""}
        </PopUp>
    );
}
