import styles from "./NewScheduler.module.css"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PopUp from "../../../../../components/layouts/PopUp";
import React from "react";
import { useScheduler } from "../../schedulerContext";
import timescheduler from "../../../../../images/timescheduler.svg";
import eventScheduler from "../../../../../images/eventScheduler.svg";

export const NewSchedulerStart = ({ show, handleClose }) => {

    const { setFreq, setParamObject, setIsTimeBased, setId, setPrevName, setIsEnabled, setParams,
        params, setTimeBasedSchedulerForm, timeBasedSchedulerForm, setEdit, setTimeBased
    } = useScheduler();



    const handleClick = () => {
        setIsEnabled("")
        setPrevName("")
        setId("")
        setTimeBasedSchedulerForm({
            ...timeBasedSchedulerForm, name: "",
            description: "",
            serviceType: "BOS"
        })
        setParams({
            ...params,
            serviceId: "",
            projectComponentId: "",
            bosName: ""
        })
        setParamObject("")
        setEdit(false)
        setFreq([{ times: "", day: "", week: "", hr: "", min: "", isOpended: false, drp: "hours" }])
        handleClose()
        setTimeBased(true)
        setIsTimeBased(true)
    }

    return <>
        <PopUp popUp={show} setPopUp={handleClose} testId={"new-scheduler"} title={"How do you want to trigger your action?"}>
            {/* <h1 data-text-testid={"title"} className={styles.newSchedulerHeader}>
                How do you want to trigger your action?
            </h1> */}
            <div className={styles.typeContainer}>
                <div className={styles.typeInnerCon}>
                    <img className={styles.image} src={timescheduler} alt='' />
                    <p style={{ fontWeight: "500" }}>Based on time</p>
                    <p>Want to invoke something periodically?</p>
                    <input data-clickable-testid={"Start here"} className="primary" type="submit" value={"Start here"} onClick={handleClick} ></input>
                </div>
                <div className={styles.typeInnerCon}>
                    <img className={styles.image} src={eventScheduler} alt='' />
                    <p style={{ fontWeight: "500" }}>Based on an event</p>
                    <p>Want to invoke something if an event happens?</p>
                    <input data-clickable-testid={"Start here"} className="primary" type="submit" disabled={true} value={"Start here"}></input>
                </div>
            </div>
        </PopUp>


    </>
}