import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import { getTenantList, getPackageList,createCompany, generateLicenseKey, getLicenseDetails } from '../../../api/administration/tenantAdmin/tenantAdmin.js';
import { getSalutaionList,getDateFormatsList,getLanguageList,getTimezoneList } from '../../../api/administration/organisation/organisation.js';
import messages from '../tenantAdministration/tenantMessages';
import React from "react";


export function TenantActions({show,close,notify}){

    const loadTenantLists = async (offset,limit,tenantFIlter) => {
        show(()=><ActivityIndicator text={messages.loadTenants}/>)
        const [error, data] = await getTenantList(offset,limit,tenantFIlter);
        close()
        if(error){
            notify({message:error,type:'E'});
            return [error, null];
        }
        if(data.code >= 400){
            notify({message:data.message,type:'E'})
            return [data.message, null];
        }
        return [null,data];
    }

    const saveCompany = async (json) => {
        show(()=><ActivityIndicator text={messages.saveCompany}/>)
        const [error, data] = await createCompany(json);
        close()
        if(error){
            notify({message:error,type:'E'})
            return [error, null];
        }
        if(data.code >= 400){
            notify({message:data.message,type:'E'})
            return [data.message, null];
        }
        if(data)
            notify(data.status);
        return [null,data];
    }

    const getSAL = async ()=>{
        const [err,tz] = await getSalutaionList()
        return tz;
    }
    const getDF = async ()=>{
        const [err,tz] = await getDateFormatsList()
        return tz;
    }
    const getTZ = async ()=>{
        const [err,tz] = await getTimezoneList()
        return tz;
    }
    const getLAN = async ()=>{
        const [err,tz] = await getLanguageList()
        return tz;
    }
    
   const getPackage = async ()=>{
        const [error,data] = await getPackageList()
        if(error)
            return [error, null];
        if(data.code >= 400)
            return [data.message, null];
        return [null,data];
    }

    const generateLicense = async (value) => {
        show(()=><ActivityIndicator text={messages.generateLicense}/>)
        const [error, data] = await generateLicenseKey(value);
        close()
        if(error){
            notify({message:error,type:'E'});
            return [error, null];
        }
        if(data.code >= 400){
            notify({message:data.message,type:'E'})
            return [data.message, null];
        }
        if(data) {
            notify(data.status)
        }
        return [null,data];
    
    }

    const GetLicenseDetails = async (tenantId) => {
        show(()=><ActivityIndicator text={messages.getLicenseDetails}/>)
        const [error, data] = await getLicenseDetails(tenantId);
        close()
        if(error){
            notify({message:error,type:'E'});
            return [error, null];
        }
        if(data.code >= 400){
            notify({message:data.message,type:'E'})
            return [data.message, null];
        }
        return [null,data];
    }

 
    return {loadTenantLists, getSAL,getLAN,getDF,getTZ,getPackage,saveCompany, generateLicense, GetLicenseDetails};
}