import React, { useState, useReducer, useEffect } from 'react';
import { Container, Box, Grid, TablePagination } from '@material-ui/core';
import useStyles from './style';
import ContentHeader from '../../../common/components/ContentHeader';
import SubHeader from '../../../common/components/SubHeader';
import Table from '../../../common/components/Table';
import EditItemModal from './components/edit-item';
import { allItemsConstants } from '../../../constants';
import Tile from '../../../common/components/Tile';
import PromptDialog from '../../../common/components/PromptDialog';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { configMessage } from '../../../common/messages/config';
import ManagePermission from '../../library/database/Components/manage-permissions';
import {
  fetchWorkflowList,
  fetchWorkflowById,
  fetchWorkflowListAll,
  fetchWorkitemDelete,
  fetchUsers,
  reAssign,
  fetchWorkflowDetailsById,
  searchUsers,
} from '../../../helpers/all-items';

let pagination = {
  newPageNumber: 0,
  rowsPerPage: 10,
};

const subHeaderMenus = () => {
  let arr = [];
  arr.push({
    menu: 'Universal Work list',
    url: 'Console',
    selected: true,
  });
  arr.push({
    menu: 'Variables',
    url: 'Console/Variables',
    selected: false,
  });
  arr.push({
    menu: 'Conflicts',
    url: 'Console/Conflicts',
    selected: false,
  });

  return arr;
};

const allItemsTableColumns = [
  {
    id: 'businessKey',
    label: 'Business Function',
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Business Function',
  },
  {
    id: 'laneName',
    label: 'Role',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Role',
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 80,
    maxWidth: 120,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Title',
  },
  {
    id: 'subject',
    label: 'Subject',
    minWidth: 80,
    maxWidth: 120,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Subject',
  },
  {
    id: 'workflowId',
    label: 'Workflow Id',
    minWidth: 100,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Workflow Id',
  },
  {
    id: 'user',
    label: 'User',
    // disabled: true,
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'User',
  },
  {
    id: 'createdDate',
    label: 'Created On',
    minWidth: 120,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Created On',
  },
  {
    id: 'modifiedDate',
    label: 'Updated On',
    minWidth: 120,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Updated On',
  },
  {
    id: 'itemCurrentStatus',
    label: 'State',
    minWidth: 80,
    maxWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'State',
  },
  {
    id: 'status',
    label: '',
    minWidth: 40,
    align: 'center',
    type: 'action',
    style: { paddingLeft: 8 },
  },
  {
    id: 'checkbox',
    label: 'checkbox',
    minWidth: 40,
    align: 'left',
    type: 'checkbox-2',
    style: { paddingLeft: 8 },
  },
  {
    id: 'more',
    label: 'moreIcons',
    minWidth: 40,
    align: 'left',
    type: 'more',
    menuStyle: {
      paddingRight: '8px',
      marginLeft: 'auto',
    },
  },
];

const editModalTableColumns = [
  {
    id: 'actor',
    label: 'State',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    disabled: true,
    placeHolder: 'Last Task',
  },
  {
    id: 'userName',
    label: 'User',
    minWidth: 80,
    align: 'left',
    style: { paddingLeft: 8 },
    type: 'text',
    disabled: true,
    placeHolder: 'User',
  },
  {
    id: 'createdDate',
    label: 'Time',
    minWidth: 80,
    align: 'left',
    disabled: true,
    style: { paddingLeft: 8 },
    type: 'text',
    placeHolder: 'Time',
  },
];

let filterKey = {
  functionId: 'w.function_id',
  laneName: 'l.name',
  workflowId: 'w.workflow_id',
  status: 'w.status',
  businessKey: 'business_key',
  assignedTo: 'w.assigned_to',
  stepName: 'w.step_name',
  itemCurrentStatus: 'w.item_current_status',
  created: 'w.created_by',
  data: 'data',
  modified: 'w.modified_by',
  createdDate: 'w.created_date',
  modifiedDate: 'w.modified_date',
  businessFuntionName: 'p.name',
  projectName: 'p.name',
  version: 'b.version',
  assignedRole: 'w.assigned_role',
  subject: 'w.subject',
  title: 'w.title',
  user: 'user',
  fullName: 'fullName',
};

const paginationDropDownList = [10, 20, 40, 60, 80, 100];

const initialState = {
  dialogType: '',
  showDialog: false,
  showAlert: false,
  alertMsg: {},
  data: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'dialogType':
      return { ...state, dialogType: action.value };
    case 'showDialog':
      return { ...state, showDialog: action.value, data: action.data || [] };
    case 'showAlert':
      return {
        ...state,
        showAlert: action.showAlert,
        alertMsg: action.alertMsg,
      };
    default:
      return state;
  }
}

const permissionContainer = {
  borderRadius: 8,
  backgroundColor: '#F5F6FA',
  overflow: 'hidden',
  width: "416px",
};

const permissionColumnStyle = [
  {
      column: {
          backgroundColor: 'white',
          width: "296px",
          marginLeft: "8px",
          fontSize : "13px"
      },
      row: {
          backgroundColor: 'white',
          width: "280px",
          marginLeft: "16px",
          overflow: "hidden",
          overflowWrap: "break-word",
          height: "auto",
          fontWeight: "500",
          fontSize : "13px"
      }
  },
  {
      column: {
          backgroundColor: 'white',
          width: "96px",
          fontSize : "13px"
      },
      row: {
          backgroundColor: 'white',
          width: "96px",
          marginLeft: "-8px",
          fontWeight: "400",
          fontSize : "13px"
      }
  }]

const permissionHeaderData = [
  {
      "placeholder": "User",
      "type": "text",
      "xs": 9,
      "rowxs": 9,
      "rowType": "text",
      "key": "name"
  },
  {
      "placeholder": "Add",
      "type": "dropdown",
      "xs": 3,
      "rowxs": 3,
      "rowType": "checkbox_with_text",
      "options": [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]
  }
];

const permissionFooterDetail = { "secondaryBtn" : "Cancel",  "primaryBtn" : "Save"};


const AllItemsLayout = (props) => {
  const { workLogs, workflowlogs, logsCount, workflowListAll,openWorkflowRequest } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [workItemId, setWorkItemId] = useState([]);
  const [page, setPage] = useState(0);
  const [tiles, setTiles] = useState([]);
  const [showUserDialog,setUserDialog] = useState(false);
  const [userList,setUserList] = useState([]);
  const [assignWorkflowId,setAssignWorkflowId] = useState("");
  const [assignedUsers,setAssignedUsers] = useState([]);

  useEffect(() => {
    fetchWorkflowList(filter, pagination);
    fetchWorkflowListAll();
  }, []);

  useEffect(() => {
    getTilesCount();
  }, [workflowListAll]);

  const getTilesCount = () => {
    var all = workflowListAll.length;
    // var open = workflowListAll.filter(obj => obj.status === "open").length;

    var businessFunctionCount = workflowListAll.reduce((unique, o) => {
      if (!unique.some((obj) => obj.functionId === o.functionId)) {
        unique.push(o);
      }
      return unique;
    }, []).length;

    var completed = workflowListAll.filter(
      (obj) => obj.status === 'completed'
    ).length;
    var open = all - completed;

    setTiles([
      {
        title: 'Workflows',
        count: all,
      },
      {
        title: 'Business Function',
        count: businessFunctionCount,
      },
      {
        title: 'In Progress',
        count: open,
      },
      {
        title: 'Completed',
        count: completed,
      },
    ]);
  };

  const handleChange = (value, column) => {
    let newFilter = { ...filter, [filterKey[column.id]]: value };
    setFilter(newFilter);
    setPage(0);
    handlePagination({ newPageNumber: 0, rowsPerPage }, newFilter);
  };

  const renderModal = () => {
    switch (state.dialogType) {
      case 'editItem':
        return (
          <EditItemModal
            classes={classes}
            dispatch={dispatch}
            tableData={workLogs}
            onClose={() => dispatch({ type: 'showDialog', value: false })}
            columns={editModalTableColumns}
            {...state}
            {...props}
          />
        );
      default:
        break;
    }
  };

  const dropdownData = (row, column, checkedList = [], index) => {
    let arr = [];
    arr.push({
      id: 1,
      title: 'Archive',
      selected: false,
      key: 'delete',
    });
    if(row.status!="completed"){
      arr.push({
        id: 6,
        title: 'Open',
        selected: false,
        key: 'openWorkItem',
      });

      arr.push({
        id: 7,
        title: 'AssignTo',
        selected: false,
        key: 'assignTo',
      });
    } 
    

    if((!checkedList.length || (checkedList.length === 1 && checkedList?.[0] === index)) && index > -1){
      arr.push({
        id: 2,
        title: 'Show History',
        selected: false,
        key: 'history',
      });
    }
    if(
      row.status === configMessage.E4501 && (!checkedList.length || 
      (checkedList.length === 1 && checkedList?.[0] === index))
    ){
      arr.push({
        id: 3,
        title: 'Retry',
        selected: false,
        key: 'retry',
      },{
        id: 4,
        title: 'Revert',
        selected: false,
        key: 'prevTask',
      },{
        id: 5,
        title: 'Restart',
        selected: false,
        key: 'restart',
      });
    }
    return arr;
  };

  const handleSearchChange =(searchText)=>{
    setUserList([]);
    searchUsers(searchText,(data)=>{
      let ul = [];
      const users=data.data?.map(e=>{
        ul.push(e.loginName);
      })
      setUserList(ul);
    });

  }

  const handlePermissionModal = (action, data, userlist) => {
    if (action == 'close') {
      setUserDialog(false);
    } else if (action == 'submit') {
      console.log("workItemId selected",assignWorkflowId);
      console.log('users selected', data, userlist);
      if (userlist.length > 0) {
        var postArray = [];
        userlist.map((object, index) => {
          if (object.status == 'true' && object.checkDisable == false)
            postArray.push(object.name);
        });

        var postData = {
            assignedTo: JSON.stringify(postArray),
        };

        console.log("postData",postData);
        reAssign(postData,assignWorkflowId,setUserDialog);
        /*shareAsset(postData);
        pagination = { limit: 10, offset: 0 };
        this.setState({ shareConfirm: false, assetOpIndex: [] });*/
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePagination({
      rowsPerPage: rowsPerPage,
      newPageNumber: newPage,
    });
  };

  const handleMenuDropdown = (event, key, data, type) => {
    let payl = { 
      projName: type.projectName, 
      bfName: type.businessKey, 
      wId: type.workflowId
    };
    let confirmPayl = {
      type: payl,
      flag: true, 
      key: key.key,
      varName: type?.workflowId, 
    };
    if (key.key === 'delete') {
      let deleteArray = [];
      let confirmationProps = { title: configMessage.L4670, text: configMessage.W4515, action: configMessage.E4501 };
      if (type === 'column') {
        data.map((obj, index) => { deleteArray.push(workflowlogs[obj].workflowId); });
      } else {
        deleteArray = [workflowlogs[data].workflowId];
      }
      setWorkItemId(deleteArray);
      setOpenConfirm({ flag: true, varName: type?.workflowId, confirmationProps, key: key.key });
    } else if (key.key === 'retry') {
      let confirmationProps = { title: configMessage.L4673, text: configMessage.W4518, action: configMessage.W4501 }
      setOpenConfirm({ ...confirmPayl, confirmationProps });
    } else if (key.key === 'prevTask') {
      let confirmationProps = { title: configMessage.L4671, text: configMessage.W4516, action: configMessage.W4501 }
      setOpenConfirm({ ...confirmPayl, confirmationProps });
    } else if (key.key === 'restart') {
      let confirmationProps = { title: configMessage.L4672, text: configMessage.W4517, action: configMessage.W4501 }
      setOpenConfirm({ ...confirmPayl, confirmationProps });
    } else if(key.key === "history"){
      fetchWorkflowById(workflowlogs[data]?.workflowId);
      dispatch({ type: 'showDialog', value: true, data: workflowlogs[data] });
      dispatch({ type: 'dialogType', value: 'editItem' });
    } else if(key.key === "assignTo"){
      let worflowid = workflowlogs[data]?.workflowId;
      let assignedusers = [];
      fetchWorkflowDetailsById(worflowid,(data)=>{
        console.log("newdata",data);
        if(data.detail?.assignedTo){
          assignedusers = JSON.parse(data.detail?.assignedTo);
        }
        let selectedUser=[];
        if (assignedusers != undefined && assignedusers.length > 0) {
          assignedusers.map((user) => {
            let o = {"projectName":user};
            selectedUser.push(o);
          });
        } 
        setAssignedUsers(selectedUser);

        fetchUsers((data)=>{
          let ul = [];
          const users=data.data.map(e=>{
            ul.push(e.loginName);
          })
          setUserList(ul);
          setUserDialog(worflowid);
          setAssignWorkflowId(worflowid);
        });
      })
      console.log("assingedTo users are",assignedusers);
      //selectedUser = workflowlogs[data]?.assignedTo;
      
      
    }else if(key.key === "openWorkItem"){
      console.log(workflowlogs[data]);
      openWorkflowRequest(workflowlogs[data]);
      
    }
  };

  const handleChangeRowsPerPage = (event) => {
    let recordsPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(recordsPerPage);
    handlePagination({ rowsPerPage: recordsPerPage, newPageNumber: 0 });
  };

  const handlePagination = (value, filt = filter) => {
    pagination = {
      rowsPerPage: value.rowsPerPage,
      newPageNumber: value.newPageNumber,
    };
    fetchWorkflowList(filt, pagination);
  };

  const handleWorkItemActionConfirmation = (key = "", type = {}) => {
    if(key === "delete"){
      fetchWorkitemDelete(workItemId, filter, pagination);
    }else if(key === "prevTask") {
      props.handleWorkItemRevert(type, filter, pagination);
    } else if (key === 'restart') {
      props.handleWorkItemReStart(type, filter, pagination);
    } else if(key === "retry"){
      props.handleWorkItemRetry(type, filter, pagination);
    }
    popupClose();
  };

  const popupClose = () => {
    setOpenConfirm({ flag: false, varName: '', confirmationProps: {}, key: '', type: {} });
  };

  const handleActionButton = (event, row, index) => {
    let payl = { 
      projName: row.projectName, 
      bfName: row.businessKey, 
      wId: row.workflowId
    };
    let confirmPayl = {
      type: payl,
      flag: true, 
      key: "history",
      varName: row?.workflowId
    };
    if(row.status === configMessage.E4501){
      let confirmationProps = { 
        text: row.errorLog || "", 
        title: configMessage.L4674,
        action: configMessage.E4501,
        buttons: {primary : "", secondary: "Close"}
      }
      setOpenConfirm({ ...confirmPayl, confirmationProps });
    }
  };

  
  return (
    <Box component='div' className={classes.allItemsPage}>
      <Grid container>
        <Grid item xs={12} className='layout'>
          <SubHeader
            type='function'
            menus={subHeaderMenus()}
            middleTab={[]}
            rightTab={[]}
            navigateTab={props.navigateTab}
          />
          {/* <ContentHeader
            left={9}
            right={3}
            title={allItemsConstants.pageTitle}
            subtitle={allItemsConstants.pageSubTitle}
          /> */}
          <Container
            className={classes.containerBox}
            style={{ marginTop: '48px' }}
          >
            <Grid component='div' xs={12} className='tile-wrapper'>
              {tiles.map((tile, index) => (
                <Tile
                  key={`${Date.now()}_tile_${index}`}
                  title={tile.title}
                  count={tile.count}
                />
              ))}
            </Grid>
          </Container>
          <Container className={classes.containerBox}>
            <Grid component='div' xs={12} className='wrapper'>
              <Grid component='div' className='wrapper-table'>
                <Table
                  tableColumns={allItemsTableColumns}
                  tableData={workflowlogs}
                  classes={classes}
                  rowMenuOptions={dropdownData}
                  handleMenuDropdown={handleMenuDropdown}
                  handleActionButton={handleActionButton}
                  handleChange={(value, column) => handleChange(value, column)}
                  emptyText='No records available'
                  screenType='workflowconsole'
                />
                <TablePagination
                  rowsPerPageOptions={paginationDropDownList}
                  component='div'
                  count={logsCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(event, newPage) =>
                    handleChangePage(event, newPage)
                  }
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  SelectProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    classes: { icon: classes.selectIcon },
                  }}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
            {openConfirm.flag && (
              <PromptDialog
                open={openConfirm.flag}
                close={() => popupClose()}
                primaryText = {openConfirm.confirmationProps?.buttons?.primary} 
                secondaryText = { openConfirm.confirmationProps?.buttons?.secondary}
                yes={() => handleWorkItemActionConfirmation(openConfirm.key, openConfirm.type)}
                data={{
                  title: openConfirm?.confirmationProps?.title || "",
                  text: openConfirm?.confirmationProps?.text || "",
                  action: openConfirm?.confirmationProps?.action || "",
                }}
              />
            )}
          </Container>
        </Grid>
      </Grid>
      {state.showDialog && renderModal()}
      <ManagePermission
                        showDialog={showUserDialog}
                        permissionContainerStyle={permissionContainer}
                        psnHeaderData={permissionHeaderData}
                        psnListData={userList}
                        psnDetailData={assignedUsers} // selectedUser
                        permissionColumnStyle={permissionColumnStyle}
                        permissionFooterDetail={permissionFooterDetail}
                        handleChange={()=>{}}
                        handleSearchChange={handleSearchChange}
                        handlePermissionModal={handlePermissionModal}
                        modalTitletext={"User List"}
                        getPermissionResp={[{}]}
                        canUpdate = {true}
                    /> 
    </Box>
  );
};

export default AllItemsLayout;
