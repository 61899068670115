const messages = {
    title: "How do you want to trigger your action?",
    nameErrMsg: "Please enter schedule name",
    bosErrMsg: "Please select service type",
    bosErrMsg: "Please select service type",
    projectErrMsg: "Please select a project",
    bosNameErrMsg: "Please select a bos",
    userNameErrMsg: "Please enter login ID",
    schedulerErrorMsg: "Please enter scheduler value"
};

export default messages;