import store from '../stores';
import {
  checkExistingName,
  loadProjectDetails,
  loadBusinessFunctions,
  loadRoles,
  loadTemplateCardList,
  saveProjectPath,
  createBusinessFunctions,
  loadProjectDeleteResponse,
  loadProjectShareList,
  loadProjectShareSubmit
} from '../actions/project-detail';
import DataService from '../utils/data-service';
import { showNotification, openProject } from '../actions/app-root';
import { BUSINESS_FUNCTION_API, PROJECT_API } from '../constants';
import { configMessage } from '../common/messages/config';

/**
 * Get Recent Projects
 */

// const alertShow = (data) => {
//   var param = {
//     message: data.message,
//     show: true,
//     type: data.type.toLowerCase()
//   }
//   store.dispatch(showNotification(param));
// }

function clearResponse(){
  store.dispatch(loadProjectDetails({},{}));
}

export function fetchProjectDetails(projectName) {
  store.dispatch(loadProjectDetails({},{}));

  let params = {
    uri: `${PROJECT_API}/` + projectName,
  };

  DataService.read(params).then(
    (result) => {
      if (result.data.status.code == 1) {
        store.dispatch(openProject(result.data.detail));
        store.dispatch(loadProjectDetails({}, result.data));
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function fetchBusinessFunctions(projectName,cb) {
  let params = {
    uri: `/${PROJECT_API}/${projectName}/listBusinessFunction?$select=name,type,modifiedDate,snapshot,id`,
  };

  DataService.read(params).then(
    (result) => {
      let functionlist=result.data.data;
      if(cb){
        cb(functionlist)
      }
      store.dispatch(loadBusinessFunctions(functionlist));
    },
    (error) => { }
  );
}

export function checkExistingFunctionName(projectName, businessName) {
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/${businessName}/checkname?project_name=${projectName}`,
  };
  DataService.read(params).then(
    (res) => {
      if (res.status === 200) {
        let isSuccess = {
          status: res.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
          message: res.data.status.message,
        };
        store.dispatch(checkExistingName(isSuccess));
      }
    },
    (error) => { }
  );
}

export function createFunction(projectName, input, type,src) {
  let data = {
    name: input.name,
    description: input.desc,
    source: src,
    type: type,
  };

  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function?project_name=${projectName}`,
    data: data,
  };
  store.dispatch(createBusinessFunctions({ isCreating: true }));
  DataService.create(params).then(
    (result) => {
      if (result.status === 200) {
        let isSuccess = {
          status: result.data.status.type === 'S' ? configMessage.S4501 : configMessage.E4501,
          message: result.data.status.message,
          isCreating: false,
          uuid:result.data.uuid
        };
        store.dispatch(createBusinessFunctions(isSuccess));
        //  alertShow(result.data.status)
      }
    },
    (error) => { }
  );
}

export function fetchRoles(projectName) {
  let params = {
    uri: `/${BUSINESS_FUNCTION_API}/function/bfRoles?project_name=${projectName}`,
  };
  DataService.read(params).then(
    (result) => {
      if (result.data.status.code == 1) {
        store.dispatch(loadRoles(result.data.data));
      }else {
        store.dispatch(loadRoles([]));
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
  // return fetch('../json/project-detail.json')
  //   .then((res) => res.json())
}
export function fetchTemplateCardList() {
  fetch('../json/templatecard.json')
    .then((res) => res.json())
    .then(
      (result) => {
        store.dispatch(loadTemplateCardList(result));
      },

      (error) => { }
    );
}

export function saveProjectPathUrl(id, url,openBehavior,behaviourData) {
  clearPreviousProjectData();
  fetchProjectDetails(id);
  let project = { id: id, url: url,openBehavior:openBehavior,behaviourData:behaviourData };
  return store.dispatch(saveProjectPath(project));
}

export function resetIsExistingName() {
  store.dispatch(checkExistingName({}));
}


export function handleProjectDelete(props) {

  const data = {

    companyId: props.id,
    name: props.name,
    description: props.description,
    owner: props.owner,
    status: props.status,
    version: props.version,
  };

  let params = {
    uri: `${PROJECT_API}/` + props.name,
    // data: data,
  };

  DataService.delete(params).then(
    (result) => {
      // store.dispatch(loadProjectUpdate(result.data));
      alertShow(result.data);
      if (result.data.status.code == 1) {
        store.dispatch(loadProjectDeleteResponse(result.data.status));
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function handleProjectShareList(props, searchValue) {

  
  var url = `${PROJECT_API}/${props.name}/listAccess?$select=id,firstName,lastName,loginName,access`;

  if (searchValue != undefined) {

    var firstName = (searchValue["firstName"] == undefined) ? "" : searchValue["firstName"];
    var lastName = (searchValue["lastName"] == undefined) ? "" : searchValue["lastName"];
    var loginName = (searchValue["loginName"] == undefined) ? "" : searchValue["loginName"];

    if(firstName.length > 0 || lastName.length > 0 || loginName.length > 0) {
      url += `&$filter=`;
      var value = "";
      if(firstName.length > 0)
      {
        value += `u.first_name like '%25` + searchValue.firstName + `%25'`
      }
      if(lastName.length > 0)
      {
        value += (value.length > 0) ? ` AND ` : ``
        value +=  `u.last_name like '%25` + searchValue.lastName + `%25'`;
      }
      if(loginName.length > 0)
      {
        value += (value.length > 0) ? ` AND ` : ``
        value +=  `u.login_name like '%25` + searchValue.loginName + `%25'`
      }

      url += value;
    }
  }


  let params = {
    uri: url
  };

  DataService.read(params).then(
    (result) => {
      if (result.data.status.code == 1) {
        if(result.data.data.length > 0)
          store.dispatch(loadProjectShareList({}, result.data.data));
        else
          alertShow({
            status: {code: 0, message: configMessage.E4505}
          });
      }
      else
      {
        alertShow(result.data);
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export function handleProjectShareSubmit(props, selectedList) {
  var data = {};

  if(selectedList.length > 0)
  {
    data = { "userAccess" : selectedList }
  }else {
    data = { "userAccess" : [] }
  }

  let params = {
    uri: `${PROJECT_API}/${props.name}/projectAccess/update`,
    data: data,
  };

  DataService.update(params).then(
    (result) => {
      if(result.data.status.code == 1)
      {
        store.dispatch(loadProjectShareSubmit(result.data));
      }
      alertShow(result.data);
    },
    (error) => {
      // console.log('variable error: ', error);
    }
  );
}

const alertShow = (data) => {
  var param = {
    message: data.status.message,
    show: true,
    type: data.status.code == 1 ? configMessage.S4501 : configMessage.E4501,
  };
  store.dispatch(showNotification(param));
};

export function clearDeleteResponse(type) {
  if(type == "delete")
  {
    store.dispatch(loadProjectDeleteResponse({}));
  }
  else if(type == "share")
  {
    store.dispatch(loadProjectShareSubmit({}));
  }
  else if(type == "list")
  {
    store.dispatch(loadProjectShareList({}, {}));
  }
}


export function clearPreviousProjectData(){
  store.dispatch(loadBusinessFunctions([]));
  store.dispatch(loadProjectDetails({}, {data: {}}));
  store.dispatch(loadRoles([]));
}

