import { useAppContext } from "../../../components/activity/AppContext";
import { validateValue, validateValues } from "./validations";
import { useForm } from '../../../components/forms/Form';
import PopUp from "../../../components/layouts/PopUp";
import { LoginsActions } from './logins.actions';
import { ServiceActions } from '../services/services.actions';
import styles from "./logins.module.css";
import messages from "./loginsMessages";
import React ,{ useState } from "react";
import  createImg from "../../../images/creating-app.svg";

let style = { marginTop: "8px" };

function NewLogin(props) {
    const [data] = useState({ description: "", name: "" });
    const [apiError, setApiError] = useState('');
    const app = useAppContext();
    const {
        show = false,
        setShow = () => null,
        navigate = () => null,
        getList,
        appPage,
        setAppPage
    } = props;

    const handleClose = () => {
        setShow(false);
        setAppPage(false);
    }

    const onWatch = (errors, data) => {
        validateValue(errors, data.name, data.value);
    }

    const onSubmit = async (values, errors) => {
        setApiError('');

        if (!validateValues(values, errors))
            return false;

        const actions = LoginsActions(app());
        const reqAction = ServiceActions(app());
        const [error,data] = await actions.createNewLogin(values);

        if(error || !data.status?.code){
            setApiError(error || data.status?.message);
            return false;
        } else {
            const id = JSON.parse(data.object).requestId
            setShow(false);
            setAppPage(true);
            getAPIStatus(reqAction,id)
            // navigate(values);
            return true;
        }

    }
// 
    const getAPIStatus = async (reqAction,id)=>{
        const [error,res] = await reqAction.getPublishStatus(id);
        if(!error && res.detail.status==='pending'){
            setTimeout(() => getAPIStatus(reqAction,id), 2000)
        }else{
            setAppPage(false);
            getList()
        }
    }

    const { Form, Label, Input, Error, TextArea } = useForm(data, onSubmit, onWatch);

    return (
        <>
        <PopUp
            popUp={show}
            setPopUp={handleClose}
            title={messages.newLoginTitle}
            titleClassName={styles.popupTitle}
        >
            <Form>
                <div className={styles.formArea}>
                    <div className={styles.column}>

                        <Label text={messages.name} htmlFor="name" style={style} />
                        <Input type="text" id="name" name="name" />
                        <Error name={'name'} />

                        <Label text={messages.desc} htmlFor="description" style={style} />
                        <TextArea type="text" id="description" name="description" />
                        <Error name={'description'} />

                        <Error message={apiError} />
                    </div>
                </div>

                <div className={styles.afFooter}>
                    <Input
                        type="button"
                        value={"Cancel"}
                        className="secondary"
                        onClick={handleClose}
                        data-clickable-testid={"cancel"}
                    />
                    <Input type="submit" className="primary" />
                </div>
            </Form>
        </PopUp>
        <PopUp
            popUp={appPage}
            setPopUp={setAppPage}
            title={messages.createAppTitle}
            titleClassName={styles.popupTitle}
        >
            <img className={styles.createApp} src={createImg} alt="createAppImage" />
        </PopUp>
        </>
    )
}

export default NewLogin;