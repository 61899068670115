import { useAppContext } from "../../components/activity/AppContext";
import { DriveActions } from "./drive.actions";
import img from "../../images/imageFile.png";
import txt from "../../images/textFile.png";
import folder from "../../images/folder.svg";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import StorageCard from "./storageCard";
import { messages } from "./messages";

function DriveRecentFiles(props) {
    const [storage, setStorage] = useState({});
    const [list, setRecents] = useState([]);
    const { projectName = "" } = props;
    const app = useAppContext();

    const getDependencies = () => {
        const drive = DriveActions(app());

        const getStorage = async () => {
            let [err, data] = await drive.GetStorage(projectName);
            if (!err && !data.error) {
                setStorage(data.data || {});
            }
        }

        const getRecents = async () => {
            let [err, data] = await drive.GetRecentFiles(projectName);
            if (!err && !data.error) {
                setRecents(data.data || []);
            }
            if (projectName) {
                getStorage();
            }
        }
        getRecents();
    }
    useEffect(getDependencies, []);
    // useEffect(getDependencies, [props.getList]);

    const renderFileDetails = (item = {}) => {
        const { extension, modifiedon, displayname, size } = item;
        let actualSize = Math.round(size / 1024);
        let imageData = folder;

        if (extension === ".txt")
            imageData = txt;
        else if (extension)
            imageData = img;

        return (
            <div
                onClick={() => null}
                key={displayname + modifiedon}
                className={styles.folderCard}
                data-clickable-testid={displayname}
            >
                <img
                    src={imageData}
                    alt={extension}
                    className={styles.typeImage}
                />
                <p className={styles.files}>
                    {displayname}
                </p>
                <p className={styles.files}>
                    {actualSize ? `${actualSize}KB` : ''}
                    {actualSize ? ', ' : ''}
                    {new Date(modifiedon).toDateString()}
                </p>
            </div>
        );
    }

    return (
        <>
            <h3 className={styles.sectionTitle} data-text-testid={messages.recents}>
                {messages.recents}
            </h3>
            <div className={styles.cards}>
                <div className={styles.recents}>
                    {
                        list.map((item) => {
                            return renderFileDetails(item);
                        })
                    }
                </div>
                {projectName && <StorageCard space={storage} />}
            </div>
        </>
    )
}

export default DriveRecentFiles;