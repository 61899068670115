import React, { useState, useEffect } from 'react';
import useStyles from './style';
import Project from '../../views/project/';
import SubHeader from '../../common/components/SubHeader';
import { getPermissions, getEnvironment } from '../../utils/common';
// import SubContentHeader from '../../common/components/SubContentHeader/index.js';
import { Box, Grid, Tab, Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomizedAccordions from '../../common/components/Accordion';
import { Restore } from '@material-ui/icons';
import Modal from '../../views/dialog';
import NavItem from '../../common/components/NavItem';
import {
  clearLogFile
} from '../../helpers/preview-and-publish';
import {
  AcceptTest,
  AcceptTestForQuality,
  ApproveTestForQuality,
  RejectForQuality,
  RejectTest,
  NewTest,
  Results,
  Footer,
  ModalTitle,
} from './components/AcceptTest';
import { clearDeleteResponse,getUsedLanguage } from '../../helpers/create-project-details';
// import Accordion from '../../views/Accordion';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LensIcon from '@material-ui/icons/Lens';
import whiteTick from '../../assets/images/white_tick.png';
import {
  TransportResult,
  TransportStatus,
  TransportData,
} from '../business-function/components/app-transport/component';
import Transport from '../business-function/components/app-transport';
import RevertVersion from '../business-function/components/revert-version';
import ManagePermission from '../library/database/Components/manage-permissions';
import EditLanguage from './components/edit-language';
import AlertDialog from '../../common/components/AlertDialog';
import {modifyLanguage} from '../../helpers/create-project-details';



let enums = {
  active: <CheckCircleOutlineIcon />,
  warning: <ReportProblemOutlinedIcon />,
  delete: <ErrorOutlineIcon />,
};

const subHeaderMenus = () => {
  let arr = [];

  if (getPermissions()?.projects?.business_function?.canView) {
    arr.push({ menu: 'Home', url: '', selected: false });
  }
  if (getPermissions()?.projects?.database?.canView) {
    arr.push({ menu: 'Database', url: 'Database', selected: false });
  }
  if (getPermissions()?.projects?.drive?.canView) {
    arr.push({ menu: 'Drive', url: 'Drive', selected: false });
  }
  if (getPermissions()?.projects?.project_settings?.canView) {
    arr.push({ menu: 'Settings', url: 'Settings', selected: false });
  }

  return arr;
};

export const CreateProejctDetails = React.forwardRef((props, ref) => {
  const { graphContainerRef, userTask } = ref;
  const {
    handleModal,
    projects,
    accordions,
    progressData,
    loadingProjects,
    loadingAccordionData,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState('');
  const [testType, setTestType] = useState(null);
  const [version, setVersion] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [versionDetail, setVersionDetail] = useState({});
  const [showTransportModal, setShowTransportModal] = useState(false);
  const [showRevertModal, setshowRevertModal] = useState(false);
  const [errorDict, setErrorDict] = useState({});
  const [revertDisVersion, setRevertDisVersion] = useState({});
  const [selectedProject,setSelectedProjects]=useState([{projectName:'English',checkDisable:true}]);
  const [showAlert,setShowAlert]=useState(false);
  const [alertMsg,setAlertMsg] =useState({});
  useEffect(()=>{
      if(props.shareConfirm){
          //let assert=props.assetData[props.selectedIndex[0]];
          updatSelectedLanguage();
      }
  },[props.shareConfirm])

  const updatSelectedLanguage=()=>{
    let project = props.match.params.id;
    let businessFunction = props.match.params.ProcessId;
    let version = props.bfDetails.version;
    getUsedLanguage(project,businessFunction,version,(data)=>{
      let ulang=[];
      if(data.languages){
        props.masterListLang.forEach(e=>{
          let x=data.languages.find(i=>i===e.code);
          if(x){
            ulang.push({projectName:e.name});
          }
        });
      }
    
      setSelectedProjects([{projectName:'English',checkDisable:true},...ulang]);
    })
  }

  React.useEffect(() => {
    if (props.isRejected.status === 'success') {
      toggleModal();
      props.reloadVersionList();
      props.showAlert(props.isRejected.message, props.isRejected.status);
      setRefresh(!refresh);
    }
    if (props.isRejected.status === 'error') {
      props.showAlert(props.isRejected.message, props.isRejected.status);
    }
  }, [props.isRejected]);

  React.useEffect(() => {}, [props.updateObjVersion]);

 

  useEffect(() => {
    accordions &&
      accordions.map(({ projects, ...rest }, i) => {
        if (props.bfDetails.version === rest.version) {
          setRevertDisVersion(rest.version);
        }
      });
  }, [accordions, props.bfDetails]);

  React.useEffect(() => {
    if (
      props.updateObjVersion != undefined &&
      Object.keys(props.updateObjVersion).length > 0
    ) {
      if (testType === 'reject') {
        if (props.updateObjVersion.status.code == 1) {
          setShowTransportModal(true);
        }
      }
      setOpen(false);
    }

    if (Object.keys(props.updateObjVersion).length > 0 && testType === 'reject') {
      if (props.updateObjVersion.status != undefined) {
        var time = 1000;

        setTimeout(() => {
          props.reloadVersionList();
          setRefresh(!refresh);
        }, time);
      }
    } else {
      setRefresh(!refresh);
    }

    // if(testType === "accept" &&  props.updateObjVersion != undefined && Object.keys(props.updateObjVersion).length > 0)
    // {
    //   clearDeleteResponse("accept");
    // }
  }, [props.updateObjVersion]);

  React.useEffect(() => {
    if (testType === 'accept' && showTransportModal) {
      clearDeleteResponse('accept');
    }
  }, [showTransportModal]);

  // React.useEffect(() => {
  //   if (
  //     ['failed', 'finish'].includes(props.previewAndPublish.statusDetail.status)
  //   ) {
  //     setTimeout(() => {
  //       props.reloadVersionList();
  //       setRefresh(!refresh);
  //     }, 1000);
  //   }
  // }, [props.previewAndPublish.statusDetail]);

  // React.useEffect(() => {
  //   if (props.isAccepted.status === 'success') {
  //     toggleModal();
  //     props.reloadVersionList();
  //     props.showAlert(props.isAccepted.message, props.isAccepted.status);
  //     setRefresh(!refresh);
  //   }
  //   if (props.isAccepted.status === 'error') {
  //     props.showAlert(props.isAccepted.message, props.isAccepted.status);
  //   }
  // }, [props.isAccepted]);


  React.useEffect(() => {
    if (props.previewAndPublish.logStatus.length > 0) {
      setTimeout(() => {
        props.reloadVersionList();
        clearLogFile();
        setRefresh(!refresh);
      }, 1000);
    }
  }, [props.previewAndPublish.logStatus]);
  


  React.useEffect(() => {
    if (props.previewAndPublish.logStatus.length > 0) {
      setTimeout(() => {
        props.reloadVersionList();
        clearLogFile();
        setRefresh(!refresh);
      }, 1000);
    }
  }, [props.previewAndPublish.logStatus]);
  

  const toggleModal = (type) => {
    if (type === 'rejectConfirm') {
      setShowTransportModal(false);
    }
    setErrorDict({});
    props.reset();
    setOpen(!open);
    !open && setTestType(type);
  };

  const selectTest = (e, item) => {
    // console.log(item)
    setSelectedTest(item);
  };

  const handleRejectConfirm = (data) => {
    if (testType === 'reject') {
      if (data.reason === undefined || data.reason.length === 0) {
        setErrorDict({ reason: 'Show' });
      } else {
        setErrorDict({});
        props.acceptTest(data);
        setTimeout(
          function () {
            props.versionApi();
          }.bind(this),
          200
        );
      }
    }
  };

  const handleAcceptTest = (data) => {
    var environment = getEnvironment(sessionStorage.getItem('env'));
    if (testType === 'approve' && environment === 'Production') {
      props.acceptTest(data);
      setTimeout(
        function () {
          props.versionApi();
        }.bind(this),
        200
      );
    } else {
      setShowTransportModal(true);

      setTimeout(
        function () {
          //Start the timer
          setOpen(!open);
        }.bind(this),
        100
      );
      props.acceptTest(data);
    }
  };

  const handleBackTransport = () => {
    setOpen(!open);
    setTimeout(
      function () {
        //Start the timer
        setShowTransportModal(false);
      }.bind(this),
      100
    );
  };

  const body = {
    accept: (
      <AcceptTestForQuality
        type={testType}
        versionDetail={versionDetail}
        onChangeData={props.modalChangeText}
        changeAction={props.changeModalText}
        errorDict={errorDict}
      />
    ),
    reject: (
      <AcceptTestForQuality
        type={testType}
        versionDetail={versionDetail}
        onChangeData={props.modalChangeText}
        changeAction={props.changeModalText}
        errorDict={errorDict}
      />
    ),
    new: <NewTest handleModal={handleModal} onClose={toggleModal} />,
    approve: (
      <AcceptTestForQuality
        type={testType}
        versionDetail={versionDetail}
        onChangeData={props.modalChangeText}
        changeAction={props.changeModalText}
        errorDict={errorDict}
      />
    ),
    cardModal: <Results handleModal={handleModal} onClose={toggleModal} />,
    rejectConfirm: (
      <RejectForQuality
        type={testType}
        updateObjVersion={props.updateObjVersion}
      />
    ),
  };

  const footer = {
    accept: (
      <Footer
        type={testType}
        versionDetail={versionDetail}
        acceptTest={(e) => handleAcceptTest(e)}
        onClose={toggleModal}
        onChangeData={props.modalChangeText}
      />
    ),
    reject: (
      <Footer
        type={testType}
        versionDetail={versionDetail}
        acceptTest={(e) => handleRejectConfirm(e)}
        onClose={toggleModal}
        onChangeData={props.modalChangeText}
      />
    ),
    approve: (
      <Footer
        type={testType}
        versionDetail={versionDetail}
        acceptTest={(e) => handleAcceptTest(e)}
        onClose={toggleModal}
        onChangeData={props.modalChangeText}
      />
    ),
    rejectConfirm: (
      <Footer
        type={testType}
        versionDetail={versionDetail}
        acceptTest={(e) => handleAcceptTest(e)}
        onClose={toggleModal}
        onChangeData={props.modalChangeText}
      />
    ),
  };

  /* fuction to calculate width of status in progress bar */
  const calWidth = (value, total) => {
    return `${(value * 100) / total}%`;
  };

  const handleReject = (data) => {
    toggleModal('reject');
    setVersion(data.version);
    var postData = data;
    postData['reason'] = props.modalChangeText.reason;
    setVersionDetail(postData);
  };

  const handleAcceptVesrion = (data) => {
    toggleModal(data.status === 'New' ? 'accept' : 'approve');
    setVersion(data.version);
    setVersionDetail(data);
  };

  const formatDate = (d) => {
    let dateStr = d.substring(0, 12);
    var date = new Date(dateStr);
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear()
    );
  };

  const summary = (data) => (
    <>
      {props.bfDetails.version === data.version ? (
        <Box className={classes.green_circle}>
          <img
            style={{
              height: '6px',
              width: '8px',
              display: 'center',
              marginLeft: '4px',
              marginBottom: '4px',
            }}
            src={whiteTick}
            alt=''
          />
        </Box>
      ) : (
        <></>
      )}

      <div className={'acc-heading'}>
        <Typography variant='caption'>{`Version ${data.version}`}</Typography>
        <Box>
          <Typography variant='caption' display='block'>
            {`By ${data.userName} on ${formatDate(data.createdTimestamp)}`}
          </Typography>
          <div className='testStatus'>
            {data.status === 'New' && (
              <LensIcon style={{ color: '#0062FF', width: 10 }} />
            )}
            <Typography
              variant='caption'
              display='block'
              className={data.status === 'New' ? 'new' : ''}
            >
              {data.status}
            </Typography>
          </div>
        </Box>
      </div>
    </>
  );

  const details = (data, test) => (
    // console.log('render',test),
    <React.Fragment>
      <ul>
        {data.map((item, i) => (
          <>
            {item.status === 'Testing' ? (
              <li
                onClick={(e) => selectTest(e, item)}
                className={
                  selectedTest.title === item.title ? 'selected' : 'unselected'
                }
              >
                <Typography
                  variant='caption'
                  display='block'
                >{`${item.title} ${item.createdOn}`}</Typography>
                <Typography variant='caption'>{item.status}</Typography>
              </li>
            ) : (
              <li
                onClick={(e) => selectTest(e, item)}
                className={
                  selectedTest.title === item.title ? 'selected' : 'unselected'
                }
              >
                <Grid xs={7}>
                  <Typography
                    variant='caption'
                    display='block'
                  >{`${item.title} ${item.createdOn}`}</Typography>
                </Grid>
                <Grid xs={5} className={'progress-bar-sm'}>
                  <span
                    style={{
                      width: calWidth(item.report.delete, item.report.total),
                    }}
                  ></span>
                  <span
                    style={{
                      width: calWidth(item.report.warning, item.report.total),
                    }}
                  ></span>
                  <span
                    style={{
                      width: calWidth(item.report.active, item.report.total),
                    }}
                  ></span>
                </Grid>
              </li>
            )}
          </>
        ))}
        {/* <div className={classes.newTest} onClick={() => toggleModal('new')}>Run New Test</div> */}
      </ul>
      <div className='btn-container'>
        <Button
          variant='contained'
          style={{ visibility: test.status == 'New' ? 'hidden' : 'visible' }}
          onClick={() => handleReject(test)}
        >
          Reject
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => handleAcceptVesrion(test)}
        >
          {test.status == 'New' ? 'Accept' : 'Approve'}
        </Button>
      </div>
    </React.Fragment>
  );

  const rightTabMenu = (
    <Grid xs={5} className={`databaseRightGrid`}>
      <Tooltip title='Revert'>
        <Tab
          className={classes.rightRevertIcon}
          onClick={() => setshowRevertModal(true)}
          icon={<Restore />}
          label=''
        />
      </Tooltip>
    </Grid>
  );

  const middleTab = (
    <Grid xs={2} className={classes.middleTab}>
      <Box>{props.bfDetails.name}</Box>
    </Grid>
  );

  const permissionContainer = {
    borderRadius: 8,
    backgroundColor: '#F5F6FA',
    overflow: 'hidden',
    width: "416px",
  };

const permissionColumnStyle = [
    {
        column: {
            backgroundColor: 'white',
            width: "296px",
            marginLeft: "8px",
            fontSize : "13px"
        },
        row: {
            backgroundColor: 'white',
            width: "280px",
            marginLeft: "16px",
            overflow: "hidden",
            overflowWrap: "break-word",
            height: "auto",
            fontWeight: "500",
            fontSize : "13px"
        }
    },
    {
        column: {
            backgroundColor: 'white',
            width: "96px",
            fontSize : "13px"
        },
        row: {
            backgroundColor: 'white',
            width: "58px",
            marginLeft: "-8px",
            fontWeight: "400",
            fontSize : "13px"
        }
    }]

const permissionHeaderData = [
    {
        "placeholder": "Languages",
        "type": "text",
        "xs": 9,
        "rowxs": 9,
        "rowType": "text",
        "key": "name"
    },
    {
        "placeholder": "Use",
        "type": "dropdown",
        "xs": 3,
        "rowxs": 3,
        "rowType": "checkbox_with_text",
        "options": [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]
    }
];

const permissionFooterDetail = { "secondaryBtn" : "Close",  "primaryBtn" : "Ok"};
let projectList=[];
const languages=props.masterListLang.map(e=>{
    projectList.push(e.name);
})

  return (
    <Box>
      <SubHeader
        menus={subHeaderMenus()}
        navigateTab={props.navigateTab}
        middleTab={middleTab}
        rightTab={rightTabMenu}
        downloadAvail={false}
      ></SubHeader>

      {/* Modal container */}
      <Modal
        className={classes.modalContainer}
        // dialogTitle={(testType == "approve" || testType == "reject") ? modalTitle[testType] : undefined}
        open={open}
        onClose={toggleModal}
        maxWidth='md'
        dialogoContent={body[testType]}
        dialogFooter={
          testType == 'approve' ||
          testType == 'reject' ||
          testType == 'accept' ||
          testType == 'rejectConfirm'
            ? footer[testType]
            : undefined
        }
        width={['new'].indexOf(testType) !== -1 ? true : false}
      />

      {showTransportModal ? (
        <Transport
          versionDetail={versionDetail}
          displayType={testType}
          show={showTransportModal}
          versionApi={props.versionApi}
          isLoading={testType == 'accept' ? false : true}
          transportObject={props.appTransport}
          isTransported={false}
          tVersionResp={props.updateObjVersion}
          transport={{}}
          showAlert={false}
          urlObj={props.match.params}
          previewAndPublish={props.previewAndPublish || {}}
          handleBackTransport={handleBackTransport}
          currentPage={testType === 'reject' ? 3 : 2}
          toggleModal={toggleModal}
          onClose={() => {
            setShowTransportModal(false);
          }}
          resetIsTransported={{}}
          screenType={'project_detail'}
        />
      ) : (
        <></>
      )}
      {/* Modal container ends*/}

      {/* Revert Version Modal*/}
      {showRevertModal && (
        <RevertVersion
          show={showRevertModal}
          close={() => setshowRevertModal(false)}
          urlObject={props.match.params}
          previewAndPublish={props.previewAndPublish || {}}
          revertDisVersion={revertDisVersion}
          screenType={'project_detail'}
        />
      )}

      <Box component='div' className={classes.projectDetailsContainer}>
        <Grid container spacing={1}>
          <Grid className={classes.sideBar}>
            <Box className='left-container'>
              <div id='title'>All commits</div>
              {accordions &&
                accordions.map(({ projects, ...rest }, i) => (
                  <CustomizedAccordions
                    key={i}
                    layout='versionlist'
                    isExpand={i === 0 && rest.status === 'New'}
                    data={rest}
                    refresh={refresh}
                    isDisabled={
                      rest.status !== 'New' && rest.status !== 'Active'
                    }
                    summary={summary(rest)}
                    details={details([], rest)}
                    handleAccept={toggleModal}
                    handleReject={toggleModal}
                  />
                ))}
            </Box>
          </Grid>
          <Grid xs={9}>
            {selectedTest === '' ? (
              <>
                <div>
                  {' '}
                  <Box
                    component='div'
                    className={classes.graphContainer}
                    ref={graphContainerRef}
                    style={
                      !props.openUserTask
                        ? {}
                        : {
                            visibility: 'hidden',
                            position: 'absolute',
                            top: -99999999,
                          }
                    }
                    id='divGraph'
                  ></Box>
                </div>
                {props.openUserTask && (
                  <Box
                    component='div'
                    className={classes.graphContainer}
                    ref={userTask}
                    id='divGraph'
                  ></Box>
                )}
              </>
            ) : (
              <div className='right-container'>
                <Grid xs={12} className='head-bar'>
                  <Grid xs={3}>
                    <div className='version'>Version 2.2</div>
                    <div className='date'>
                      {`${selectedTest.title} ${selectedTest.createdOn}`}
                    </div>
                  </Grid>
                  <Grid xs={9}>
                    {
                      <div className='progress-bar'>
                        <div
                          className='span'
                          style={{
                            width: calWidth(
                              progressData.delete,
                              progressData.total
                            ),
                          }}
                        >
                          {enums.delete}
                          <span>{progressData.delete}</span>
                        </div>
                        <div
                          className='span'
                          style={{
                            width: calWidth(
                              progressData.warning,
                              progressData.total
                            ),
                          }}
                        >
                          {enums.warning}
                          <span>{progressData.warning}</span>
                        </div>
                        <div
                          className='span'
                          style={{
                            width: calWidth(
                              progressData.active,
                              progressData.total
                            ),
                          }}
                        >
                          {enums.active}
                          <span>{progressData.active}</span>
                        </div>
                      </div>
                    }
                  </Grid>
                </Grid>
                <Grid container xs={12} className='projectContainer'>
                  {projects &&
                    projects.map((item, i) => (
                      // <Grid item lg={3} md={4} sm={6} xs={9} key={i} onClick={()=> toggleModal("cardModal")}>
                      <div key={i} onClick={() => toggleModal('cardModal')}>
                        <Project
                          project={{
                            name: item.title,
                            status: item.status,
                            img: item.image,
                          }}
                          noHover={true}
                        />
                      </div>
                      // </Grid>
                    ))}
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
      <AlertDialog
        open={showAlert}
        status={alertMsg?.status}
        response={alertMsg?.message}
      />
      {props.editConfirm &&(
      <EditLanguage
        showDialog={props.editConfirm}
        onClose={props.handleEditClose}
        modifyLanguage={(project,fName,version,lcode,payload)=>{
          setShowAlert(false);
          modifyLanguage(project,fName,version,lcode,payload,(msg)=>{
            setShowAlert(true);
            setAlertMsg(msg);
          })
        }}
      />)}
          <ManagePermission
          showDialog={props.shareConfirm}
          permissionContainerStyle={permissionContainer}
          psnHeaderData={permissionHeaderData}
          psnListData={projectList}
          psnDetailData={selectedProject}
          permissionColumnStyle={permissionColumnStyle}
          permissionFooterDetail={permissionFooterDetail}
          handleChange={props.handleChange}
          handlePermissionModal={props.handlePermissionModal}
          modalTitletext={"Enable Language"}
          getPermissionResp={[{}]}
          onChangesCheckbox={(d)=>{
            
              let np=[...selectedProject];
              if(d.status=="true"){
                np.push({projectName:d.name});
              }else{
                np = np.filter(e=>e.projectName!==d.name);
              }
              setSelectedProjects(np);
             
            props.onChangesCheckbox(d,(msg)=>{
              setShowAlert(false);
              //updatSelectedLanguage();
              setShowAlert(true);
              setAlertMsg(msg);
            })
          }}
          canUpdate={true}
          editOnCheck={props.onEditClick}
        /> 
    </Box>
  );
});
