import { getUrl, get, put, post, deleteAPI,encodeFilter } from "../url";

export async function getAllFolders(projectName = "", folderPath, { offset, limit }, filter = {}) {
    let filt = "";
    let sortQuery = "asc%20id";
    let path = `${getUrl()}/drive/v2.0/file${folderPath}/list`;
    let selectQuery = "id,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId,createdon,version";

    if (Object.keys(filter).length > 0) {
        Object.keys(filter).forEach(e => {
            if (filter[e]?.value !== undefined) {
                if (filt)
                    filt += " AND "
                if (filter[e].op === "LIKE") {
                    filt += `${e} LIKE '%25${filter[e].value}%25'`;
                } else {
                    filt += `${e} ${filter[e].op} ${filter[e].value}`;
                }
            }
        })
    }

    path += `?$select=${selectQuery}&sort=${sortQuery}&offset=${offset}&limit=${limit}${filt ? "&$filter=" + filt : ''}`;
    const [error, data] = await get(path + (projectName ? `&project_name=${projectName}` : ""));
    return [error, data];
}
export async function getProjectLibraryFolders(projectName = "", folderPath, { offset, limit }, filter = {}) {
    let filt = "";
    let sortQuery = "asc%20id";
    let path = `${getUrl()}/drive/v2.0/file${folderPath}/listLibrary`;
    let selectQuery = "id,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId,createdon,version";
    if (Object.keys(filter).length > 0) {
        Object.keys(filter).forEach(e => {
            if (filter[e]?.value !== undefined) {
                if (filt)
                    filt += " AND "
                if (filter[e].op === "LIKE") {
                    filt += `${e} LIKE '%25${filter[e].value}%25'`;
                } else {
                    filt += `${e} ${filter[e].op} ${filter[e].value}`;
                }
            }
        })
    }

    path += `?$select=${selectQuery}&sort=${sortQuery}&offset=${offset}&limit=${limit}${filt ? "&$filter=" + filt : ''}`;
    const [error, data] = await get(path + (projectName ? `&project_name=${projectName}` : ""));
    return [error, data];
}
export async function getLibraryFolders({ offset, limit }, filter = {}) {
    let filt = "";
    let sortQuery = "asc%20id";
    let path = `${getUrl()}/library/v2.0/tables/listDrive`;
    // let selectQuery = "displayname,created_by,createdbyname,size,modifiedon,type";
    let selectQuery = "id,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId,createdon,version";
    if (Object.keys(filter).length > 0) {
        Object.keys(filter).forEach(e => {
            if (filter[e]?.value !== undefined) {
                if (filt)
                    filt += " AND "
                if (filter[e].op === "LIKE") {
                    filt += `${e} LIKE '%25${filter[e].value}%25'`;
                } else {
                    filt += `${e} ${filter[e].op} ${filter[e].value}`;
                }
            }
        })
    }

    path += `?$select=${selectQuery}&sort=${sortQuery}&offset=${offset}&limit=${limit}${filt ? "&$filter=" + filt : ''}`;
    const [error, data] = await get(path);
    return [error, data];
}

export async function getRecentFiles(projectName = "") {
    let project = projectName ? '?project_name=' + projectName : '';
    const uri = `${getUrl()}/drive/v2.0/file/recentFiles${project}`;
    const [error, data] = await get(uri);
    return [error, data];
}

export async function getStorage(projectName = "") {
    let project = projectName ? '?project_name=' + projectName : '';
    const uri = `${getUrl()}/drive/v1.0/file/availableSpace${project}`;
    const [error, data] = await get(uri);
    return [error, data];
}

export async function createFolder(parent = "", name = "", isShared = false, projectName = "") {
    let project = projectName ? '&project_name=' + projectName : '';
    const uri = `${getUrl()}/drive/v2.0/file${parent}/${name}?is_shared=${isShared}${project}`;
    const [error, data] = await post(uri);
    return [error, data];
}

export async function uploadFile(parent, file, name, projectName = "") {
    let project = projectName ? '?project_name=' + projectName : "";
    const uri = `${getUrl()}/drive/v2.0/file/${parent}/${name}${project}`;
    const [error, data] = await post(uri, file);
    return [error, data];
}

function getSharedTableFilter(filter){
    let filt = "?$filter=";
    let obj=[];
    if(filter)
        Object.keys(filter).map(function (key, index) {
            if(filter[key]!=undefined && filter[key]!=''){
                if(key==='project')
                    obj.push('projectName LIKE '+encodeFilter(filter[key]));
                else if(key==='use'){
                    if(filter[key]==='Yes')
                        obj.push('isShared = 1');
                    else
                        obj.push('isShared is null');
                } 
            }
        });
    obj.length>0?filt+=obj.join(" AND "):filt="";
    return filt;
}


export async function getDriveDetail(name,filter) {
    const uri = `${getUrl()}/library/v2.0/tables/${name}/detailDrive${getSharedTableFilter(filter)}`;
    const [error, data] = await get(uri);
    return [error, data];
}

export async function updateDrivePermission(name, projects) {
    let path = `${getUrl()}/library/v2.0/tables/${name}/shareDrive?is_shared=true`
    const [error, data] = await put(path, projects);
    return [error, data]
}

export async function getFileUrl(projectName, filePath) {
    let uri = `${getUrl()}/drive/v2.0/file${filePath}?project_name=${projectName}`;
    const [error, data] = await get(uri);
    return [error, data];
}

export async function deleteFolderFile(projectName = "", filePath = "") {
    let project = projectName ? '?project_name=' + projectName : '';
    let uri = `${getUrl()}/drive/v2.0/file${filePath}${project}`;
    const [error, data] = await deleteAPI(uri);
    return [error, data];
}

export async function renameFolderFile(projectName = "", oldPath = "", newName = "") {
    let project = projectName ? `project_name=${projectName}&` : '';
    let path = `${getUrl()}/drive/v2.0/file${oldPath}/rename?${project}new_name=/${newName}`;
    const [error, data] = await put(path);
    return [error, data]
}

export async function shareToLibrary(projectName = "", filePath = "") {
    let project = projectName ? `project_name=${projectName}` : '';
    let path = `${getUrl()}/library/v1.0/tables${filePath}/shareToLib?${project}`;
    const [error, data] = await put(path);
    return [error, data]
}

export async function getAllFoldersForCopyOrMove(projectName = "") {
    let path = `${getUrl()}/drive/v2.0/file/list`;
    let selectQuery = "id,companyid,path,displayname,createdbyname,modifiedbyname,mimetype,size,extension,type,parent,modifiedon,parentId";

    path += `?$select=${selectQuery}${"&$filter=type EQ 'Folder'"}`;
    const [error, data] = await get(path + (projectName ? `&project_name=${projectName}` : ""));
    return [error, data];
}

export async function driveCopyOrMove(projectName = "",destination="",fileName="",copyOrMove='copy') {
    let path = `${getUrl()}/drive/v2.0/file${fileName}/${copyOrMove}?project_name=${projectName}&destination=${destination}`;
    const [error, data] = await put(path);
    return [error, data];
}