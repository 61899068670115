import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { CheckBoxOutlineBlank, CheckOutlined, EmojiSymbolsOutlined, Edit } from '@material-ui/icons';
import { Box, Grid, Typography, Button, Checkbox, InputBase } from '@material-ui/core';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Dialog from '../../../../../views/dialog';
import DropDown from '../../../../../common/components/TableHeadSelectInput';
import SearchInput from '../../../../../common/components/TableHeadSearchInput';
import useStyles from './style';



const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '4px 8px'
  },
}))(InputBase);

const Accordion = withStyles({
  root: {
    border: '0.5px solid #F0F2F7',
    boxShadow: '0px 1px 0px rgba(205, 212, 228, 0.2) !important',
    marginTop: -4,
    width: '100%',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      marginTop: '-4px !important',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '0.5px solid #F0F2F7',
    boxShadow: 'none !important',
    width: '100%',
    marginBottom: -1,
    padding: "0px",
    minHeight: "auto",
    maxHeight: "auto",
    '&$expanded': {
      minHeight: "auto",
    },
  },
  content: {
    '&$expanded': {
      margin: '0px 0',
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0px 16px',
    minHeight: 48
  },
}))(MuiAccordionDetails);

const data = [
  {
    permissions: 'Project',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete'
  },
  {
    permissions: 'Business Process',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete'
  },
  {
    permissions: 'Business Function',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete'
  },
  {
    permissions: 'Task Manager',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete'
  },
  {
    permissions: 'Board',
    create: 'Use',
    read: 'Read',
    update: 'Update',
    delete: 'Delete'
  }
]

export default function (props) {
  const { showDialog, psnHeaderData, psnListData, psnDetailData, modalTitletext, permissionColumnStyle, permissionFooterDetail, permissionContainerStyle, canUpdate = false, onChangesCheckbox = () => { }, editOnCheck,handleSearchChange=()=>{} } = props;
  const [rowCheckList, setrowCheckList] = useState([]);
  const [selectCheck, setselectCheck] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [permissionData, setPermissionData] = useState([]);
  const [saveActive, setSaveActive] = useState(false);
  const [listPermData, setListPermData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (psnListData.length > 0) {
      var finalArray = [];
      psnListData.map((projObj, mainIndex) => {

        var isAvail = false;
        var checkDisable = false;
        var projDict = { "name": projObj }

        if (psnDetailData != undefined && psnDetailData.length > 0) {
          psnDetailData.map((detailObj, mainIndex) => {
            if (detailObj.projectName == projDict.name) {
              isAvail = true;
              checkDisable = detailObj["checkDisable"] ? true : false;
              return;
            }
          });
        }

        if (isAvail) {
          var dict = projDict;
          dict.status = "true";
          dict.checkDisable = checkDisable;
          finalArray.push(dict)
        }
        else {
          var dict = projDict;
          dict.status = "false";
          dict.checkDisable = checkDisable;
          finalArray.push(dict)
        }
      })
      setPermissionData(finalArray);
      setListPermData(finalArray)

    }
  }, [psnDetailData, psnListData]);


  const onChangeText = (e) => {
    if (e.target.value) {
      const searchValue = e.target.value.toLowerCase();
      setSearchValue(searchValue);
      handleSearchChange(searchValue);
    } else {
      setSearchValue("");
    }
  }

  const onChangeCheckbox = (index) => {

    var list = permissionData
    var data = list[index];

    if (data.status == "true") {
      data.status = "false";
    }
    else {
      data.status = "true";
    }
    list[index] = data;
    setPermissionData(list)
    setselectCheck(!selectCheck);

    var postArray = [];
    setSaveActive(true);
    onChangesCheckbox({ ...data });
  }

  const submitForm = async () => {
    props.handlePermissionModal("submit", permissionData, listPermData)
  }


  const checkboxWithText = (row, index, rowIndex, obj) => {
    return (
      <Grid container direction="row" display="flex" justify="flex-start" alignItems="center" xs={row.rowxs}>
        <Box style={(permissionColumnStyle[index]).row}>
          {renderCheckbox(rowIndex, obj)}
          <Typography variant="label">{row?.placeholder}</Typography>
        </Box>
        {editOnCheck && (obj.status == "true" && !obj?.checkDisable) && (
          <Button color="primary" small style={{ width: 38, minWidth: 8 }} variant="text"
            onClick={(e) => {
              editOnCheck(obj);
            }}>
            <Edit />
          </Button>
        )}

      </Grid>
    );
  }

  const titleText = (row, index, rowIndex, obj) => {
    return (
      <Grid item xs={row.rowxs}>
        <Box style={(permissionColumnStyle[index]).row}>
          <Typography variant="label">{obj.name}</Typography></Box>
      </Grid>
    );
  }

  const renderAccordionContent = (obj, rowIndex) => {
    return (
      <Accordion style={{ borderBottomWidth: 0.5, borderColor: '#F0F2F7', borderStyle: 'solid' }} expanded="false">
        <AccordionSummary>
          <Grid container xs={12} alignItems="center" justify="space-between">
            {psnHeaderData.map((row, index) => {
              let tableRowInput;
              if (row.rowType == "text") {
                tableRowInput = titleText(row, index, rowIndex, obj)
              }
              else if (row.rowType == "checkbox_with_text") {
                tableRowInput = checkboxWithText(row, index, rowIndex, obj)
              }
              return (
                tableRowInput
              )
            })}
          </Grid>
        </AccordionSummary>
      </Accordion>
    )
  }

  const modalTitle = () => {

    return (
      <Box className={classes.mb32}>
        <Typography className={classes.modalTitle}>{modalTitletext}</Typography>
      </Box>
    )

  };

  const renderCheckbox = (rowIndex, obj) => {
    return <Checkbox
      checked={(obj.status == "true") ? true : false}
      disabled={obj.checkDisable}
      icon={<CheckBoxOutlineBlank style={{ fontSize: 12, width: 16, height: 16, color: 'white', border: '1px solid #CDD4E4' }} />}
      checkedIcon={<CheckOutlined style={{ fontSize: 12, width: 16, height: 16, color: 'black', fontColor: 'black', border: '1px solid #CDD4E4' }} />}
      onChange={() => (onChangeCheckbox(rowIndex))}
    />
  }

  const textBoxUI = (column, index) => {
    return (
      <Grid xs={column.xs}>
        <Box style={(permissionColumnStyle[index]).column}>
          <SearchInput
            style={{ backgroundColor: "white" }}
            placeholder={column.placeholder}
            onChange={(e) => (onChangeText(e))}
          /></Box></Grid>
    );
  }

  const handleCheckBoxChange = (event, column) => {
    if (event.target.value === 'Yes') {
      setCheckboxValue("true");
    }
    else if (event.target.value === 'No') {
      setCheckboxValue("false");
    }
    else {
      setCheckboxValue("");
    }
  }

  const dropdownUI = (column, index) => {
    return (
      <Grid xs={column.xs}>
        <Box style={(permissionColumnStyle[index]).column}>
          <DropDown
            options={column.options}
            labelKey={""}
            placeHolder={column.placeholder}
            disabled={column?.disabled}
            column={column}
            value={[]}
            handleChange={handleCheckBoxChange}
          ></DropDown></Box></Grid>
    );
  }

  const handleClose = (event, column) => {
    setSearchValue("");
    setCheckboxValue("");
    setSaveActive(false)
    setListPermData([]);
    props.handlePermissionModal("close")
  }

  useEffect(() => {
    if (props.getPermissionResp != undefined) {
      if (props.getPermissionResp.status != undefined) {
        if (props.getPermissionResp.status.code == 1) {
          setSearchValue("");
          setCheckboxValue("");
          setSaveActive(false)
          setListPermData([]);
        }
      }
    }
  }, [props.getPermissionResp]);

  useEffect(() => {

    listFilterData();

  }, [checkboxValue, searchValue]);

  const listFilterData = () => {

    if (searchValue.length > 0 || checkboxValue.length > 0) {
      setPermissionData(listPermData.filter(item => {
        if (searchValue.length > 0 && checkboxValue.length > 0) {
          return item.name.toLowerCase().search(searchValue) !== -1 && item.status == checkboxValue;
        }
        else if (searchValue.length > 0 && checkboxValue.length == 0) {
          return item.name.toLowerCase().search(searchValue) !== -1;
        }
        else if (searchValue.length == 0 && checkboxValue.length > 0) {
          return item.status == checkboxValue;
        }
      }))
    }
    else {
      setPermissionData(listPermData);
    }
  }

  const container = () => {
    return (
      <Grid xs={12} justify="center" container>
        <Grid style={permissionContainerStyle}>
          <Grid container xs={12} justify="space-between" alignItems="center" className={`${classes.p8} ${classes.permissionHeader}`}>
            {psnHeaderData.map((column, index) => {
              let tableHeadCellInput;
              if (column.type == "text") {
                tableHeadCellInput = textBoxUI(column, index)
              }
              else if (column.type == "dropdown") {
                tableHeadCellInput = dropdownUI(column, index)
              }
              return (
                tableHeadCellInput
              )
            })}
          </Grid>
          <Grid container xs={12} justify="space-between" alignItems="center" className={classes.contentLayout}>
            {
              (permissionData && permissionData.length > 0) ?
                permissionData.map((obj, index) => {
                  return renderAccordionContent(obj, index);
                })
                : <></>
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const modalFooter = () => {
    const style = {
      fontSize: "40px"
    }
    return (

      <Box>
        <Button className={classes.primaryButtonLayout} color="secondary" variant="contained" onClick={(e) => handleClose(e)}>{permissionFooterDetail.secondaryBtn}</Button>
        {
          permissionFooterDetail.primaryBtn && canUpdate &&
          <Button className={classes.primaryButtonLayout} color="primary" variant="contained" type="submit" onClick={submitForm} disabled={(saveActive) ? false : true}>{permissionFooterDetail.primaryBtn}</Button>
        }
      </Box>
    )
  }

  return (
    <Dialog
      open={showDialog}
      onClose={(e) => handleClose(e)}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={modalFooter()}
      fullWidth={true}
      maxWidth={"md"}
    />
  );
}

