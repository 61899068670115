import { ModuleTitle } from "../../../components/module-title"
import { messages } from "./schedulerMessage"
import AddIcon from '@mui/icons-material/Add';
import "./scheduler.css"
import Table from "../../../components/Table/table";
import Pagination from "../../../components/Pagination/pagination";
import { NewSchedulerStart } from "./schedulerPopups/NewScheduleForm/NewScheduleForm";
import React, { useEffect, useState } from "react";
import { TimeBasedSchedule } from "./schedulerPopups/TimeBasedSchedule/TimeBasedSchedule";
import { SelectBos } from "./schedulerPopups/SelectBos/SelectBos";
import { Parameters } from "./schedulerPopups/Parameters/Parameters";
import { TriggerPopups } from "./schedulerPopups/TriggerPopups/TriggerPopups";
import { FinalStep } from "./schedulerPopups/FinalStep/FinalStep";
import eye from "./SchedulerAssets/eye.png"
import edit from "./SchedulerAssets/edit.png"
import { Loglist } from "./schedulerPopups/Loglist/Loglist";
import { useScheduler } from "./schedulerContext";
import { useAppContext } from "../../../components/activity/AppContext";
import { SchedulerActions } from "./scheduler.action";
import { LogReport } from "./schedulerPopups/LogReport/LogReport";
import { useLocation } from "react-router-dom";


function Scheduler({perm}) {

    let style = { marginBottom: "1rem" }

    const app = useAppContext();
    const location = useLocation();

    const { loglist, setLoglist, finalStep, setFinalStep, setEdit, show, setShow, timeBased, setTimeBased,
        bospopup, setBospopup, parameter, setParameter, trigger, setTrigger, createScheduler,
        params, setParams, timeBasedSchedulerForm, setTimeBasedSchedulerForm,
        freq, setFreq, freqObj, setFreqObj, paramObject, setParamObject,
        isTimeBased, setIsTimeBased, setId, setPrevName, isEnabled, setIsEnabled,
        setCreateScheduler, report, setReport, allProjects, setAllProjects } = useScheduler()

    const UpdateSchedulers = async (payload, id) => {
        const actions = SchedulerActions(app());
        const [error, data] = await actions.UpdateScheduler(payload, id);
        if (!error) {
            console.log(data)
        }
    }

    const handleUpdate = (payload, prevName) => {
        UpdateSchedulers(payload, prevName)
        handleClose()
        setTimeout(() => setCreateScheduler(!createScheduler), 1000)

    }

    const [schedulerId, setSchedulerId] = useState(0);

    const handleImagePopup = (name, item) => {
        if (name === "Logs") {
            setLoglist(true)
            setSchedulerId(item.id)
        }
        else if (name === "report") {
            setReport(true)
            loadLogReport(item.scheduleId, item.id)
        }
        else if (name === "All") {
            if (item.isEnabled === 0) {
                handleUpdate({ ...item, isEnabled: 1 }, item.name)
            }
            if (item.isEnabled === 1) {
                handleUpdate({ ...item, isEnabled: 0 }, item.name)
            }
        }
        else {
            setIsEnabled(item.isEnabled)
            setPrevName(item.name)
            setId(item.id)
            setTimeBasedSchedulerForm({
                ...timeBasedSchedulerForm, name: item.name,
                description: item.description,
                serviceType: item.serviceType
            })
            setParams({
                ...params,
                serviceId: item.bosName,
                projectComponentId: item.projectComponentId,
                bosName: item.serviceName
            })
            setParamObject(item.parameters)
            setEdit(true)
            setFinalStep(true)
            setFreq(JSON.parse(item.frequencyParam))
        }

    }

    const roleData = [
        { name: "Enabled" },
        { name: "Disabled" },
        { name: "Draft" },
    ]

    let schedulerTableHeader = [
        { id: "name", name: "Name", type: "input", width: "50px" },
        { id: "description", name: "Description", type: "input", width: "100px" },
        { id: "serviceName", name: "serviceName", type: "input", width: "100px" },
        { id: "lastExecution", name: "Last Execution", type: "input", width: "100px", disabled: "true" },
        { id: "nextExecution", name: "Next Execution", type: "input", width: "100px", disabled: "true" },
        { id: "logs", name: "Logs", width: "50px", image: eye, size: "32px", handleImagePopup: { handleImagePopup } },
        { id: "edit", name: "", width: "50px", image: edit, size: "24px", handleImagePopup: { handleImagePopup } },
        { id: "All", type: "role", width: "80px", name: "All", icon: true, handleImagePopup: handleImagePopup, disabled: !location.state.permission?.canUpdate },
    ]

    if(!location.state.permission?.canUpdate) {
        schedulerTableHeader = schedulerTableHeader.filter((e)=>e.id!=="edit")
    }

    const handleOpen = () => {
        setShow(true)
    }

    const handleClose = () => {
        if (show) setShow(false)
        if (timeBased) setTimeBased(false)
        if (bospopup) setBospopup(false)
        if (parameter) setParameter(false)
        if (trigger) setTrigger(false)
        if (finalStep) setFinalStep(false)
        if (loglist) setLoglist(false)
        if (report) setReport(false)
    }

    const [schedulerFilter, setSchedulerFilter] = useState({
        bosname: "",
        description: "",
        frequency: "",
        frequencyParam: "",
        id: "",
        isEnabled: "",
        lastExecution: "",
        name: "",
        nextExecution: "",
        parameters: "",
        projectComponentId: "",
        serviceName: "",
        serviceType: ""
    })
    const [data, setData] = useState({totalRecords:0,data:[]})
    //const [totalRecords, setTotalRecords] = useState(0)
    const [displayRows, setDisplayRows] = useState({ offset: 0, limit: 10 });
    const [logReportData, setLogReportData] = useState("")

    const loadSchedulerList = async () => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetSchdulerList(displayRows.offset, displayRows.limit, schedulerFilter)
        if (!error) {
            //setTotalRecords(data.noOfRecords);
            setData({totalRecords:data.noOfRecords,data:data.data})
        }
    }

    const onChange = (key, value) => {
        let _schedulerFilter = { ...schedulerFilter, [key]: value }
        setSchedulerFilter(_schedulerFilter);
    }

    const loadLogReport = async (schedulerId, logId) => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetReportURL(schedulerId, logId)
        if (!error) {
            const res = await actions.GetReport(data.url);
            setLogReportData(res);
        }
    }
    const loadProjectsList = async () => {
        const actions = SchedulerActions(app())
        const [error, data] = await actions.GetAllProject()
        if (!error) {
            setAllProjects(data.data)
        }
    }

    useEffect(() => {
        loadSchedulerList()
    }, [displayRows, schedulerFilter, createScheduler])


    useEffect(() => {
        loadProjectsList();
    }, [])

    return <div className="scheduler-container">
        <ModuleTitle
            title={messages.scheduler}
        >
            {
                location.state.permission?.canCreate
                ?<button data-clickable-testid={messages.newScheduler} className='primary btn-add-scheduler' onClick={handleOpen}  >
                    <AddIcon />
                    {messages.newScheduler}
                </button>
                :<></>
            }
        </ModuleTitle>
        <div className="scheduler-table">
            <Table
                headerColumns={schedulerTableHeader}
                roleData={roleData}
                data={data.data}
                name={"scheduler"}
                handleHeaderInputChange={(id, value) => onChange(id, value)}
                handleHeaderSelectChange={(id, value) => onChange(id, value)} />
        </div>
        <div className="scheduler-pagination" style={style}>
            <Pagination
                userDataCount={data.totalRecords}
                displayRows={displayRows}
                setDisplayRows={setDisplayRows} />
        </div>
        {
            show && <NewSchedulerStart show={show} handleClose={handleClose} />
        }
        {
            timeBased && <TimeBasedSchedule show={timeBased} handleClose={handleClose} />
        }
        {
            bospopup && <SelectBos show={bospopup} handleClose={handleClose} />
        }

        {
            parameter && <Parameters show={parameter} handleClose={handleClose} />
        }
        {
            trigger && <TriggerPopups show={trigger} handleClose={handleClose} />
        }
        {
            finalStep && <FinalStep show={finalStep} handleClose={handleClose} loadSchedulerList={loadSchedulerList} />
        }
        {
            loglist && <Loglist show={loglist} handleClose={handleClose} schedulerId={schedulerId} handleImagePopup={handleImagePopup} />
        }
        {
            report && <LogReport show={report} handleClose={handleClose} logReportData={logReportData} />
        }

    </div>

}

export default Scheduler;