import { commentEvent, copyEvent, cutEvent, deleteNode, expandEvent, minimizeEvent, pasteEvent, redoEvent, uncommentEvent, undoEvent } from "../resources/events/menuEvents";
import React from "react";

const menuItems = {
    delete : {name:"Delete Node",handler:new deleteNode(),disabled:1},
    minimize : {name:"Minimize",handler:new minimizeEvent(),disabled:1},           
    expand : {name:"expand",handler:new expandEvent(),disabled:1},
    cut : {name:"Cut",handler:new cutEvent(),disabled:1},
    copy : {name:"Copy",handler:new copyEvent(),disabled:1},
    paste : {name:"Paste",handler:new pasteEvent(),disabled:1},
    comment : {name:"Comment",handler:new commentEvent(),disabled:1},
    uncomment : {name:"UnComment",handler:new uncommentEvent(),disabled:1},
    undo : {name:"undo",handler:new undoEvent(),disabled:1},
    redo : {name:"redo",handler:new redoEvent(),disabled:1}
};

export default menuItems;