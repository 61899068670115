import adminMmessages from "../../../common/validationMessages/administration"
import { validate,validateName,validateEmail,required,validateNumeric, updateValidation } from "../../../common/validations/Validations";

const validatePwd = (errors, name, value,msg) => {
  let flag = false;
  if (!value || value.length < 6) {
    flag = true;
  }
  return updateValidation(errors, flag, name, msg);
}

const validateSampleOutput = (errors, name, value, msg, typeValidation) => {
  let flag = false;
  if (!value || value.length < 6 || !typeValidation) {
    flag = true;
  }
  return updateValidation(errors, flag, name, msg);
}

const validateValue = (errors, name, value, typeValidation = true, outputMsg) => {
  let isValid = true;
  let msg = 'PLease enter valid!!';
  switch (name) {
    case 'name':
      msg = adminMmessages.E8001;
      isValid = validateName(errors, name, value, msg)
      break;
    case "type":
      msg = adminMmessages.E8004;
      isValid = required(errors, name, value, msg)
      break;
    case 'plugInName':
      msg = adminMmessages.E8003;
      isValid = validateName(errors, name, value, msg)
      break;
    case 'description':
      if(value){
        msg = adminMmessages.E8002
        isValid = required(errors, name, value, msg)
      }
      break;
    case 'rfcDestination':
      msg = adminMmessages.E8005
      isValid = validate(errors, name, value, msg)
      break;
    case 'clientId':
      msg = adminMmessages.E8006
      isValid = validate(errors, name, value, msg)
      break;
    case 'clientSecret':
      msg = adminMmessages.E8007
      isValid = validate(errors, name, value, msg)
      break;
    case 'url':
      msg = adminMmessages.E8008
      isValid = validate(errors, name, value, msg)
      break;
    case 'uri':
      msg = adminMmessages.E8009
      isValid = validate(errors, name, value, msg)
      break;
    case 'domain':
      msg = adminMmessages.E8010
      isValid = validate(errors, name, value, msg)
      break;
    case 'method':
      msg = adminMmessages.E8011
      isValid = validate(errors, name, value, msg)
      break;
    case 'outputType':
      msg = adminMmessages.E8012
      isValid = validate(errors, name, value, msg)
      break;
    case 'sampleOutput':
      msg = outputMsg?outputMsg:adminMmessages.E8013
      isValid = validateSampleOutput(errors, name, value, msg, typeValidation)
      break;
    case 'dataSource':
      msg = adminMmessages.E8014
      isValid = validate(errors, name, value, msg)
      break;
    case 'dbType':
      msg = adminMmessages.E8015
      isValid = validate(errors, name, value, msg)
      break;
    case 'dbName':
      msg = adminMmessages.E8016
      isValid = validate(errors, name, value, msg)
      break;
    case 'dbPwd':
    case 'password':
        msg = adminMmessages.E8017
      isValid = validatePwd(errors, name, value, msg)
      break;
    case 'host':
      msg = adminMmessages.E8018
      isValid = validate(errors, name, value, msg)
      break;
    case 'port':
      msg = adminMmessages.E8019
      isValid = validateNumeric(errors, name, value, msg)
      break;
    case 'email':
      msg = adminMmessages.E8020
      isValid = validateEmail(errors, name, value, msg)//validate email
      break;
    case 'username':
      msg = adminMmessages.E8021
      isValid = validate(errors, name, value, msg)
      break;
    case 'userId':
      msg = adminMmessages.E8022
      isValid = validate(errors, name, value, msg)
      break;
    default:
      break;
  }
  return isValid;
}

const validateValues = (values, errors, fields, typeValidation,outputMsg) => {
  let validate = [];
  Object.keys(values).forEach((name) => {
    const value = values[name]
    if (fields.includes(name))
      validate.push(validateValue(errors, name, value, typeValidation,outputMsg));
  })
  return validate.includes(false);
}

export { validateValue, validateValues }
