import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import { getVariablesList, createVariable, updateVariable, deleteVariable } from '../../../api/library/variables/variables.js';
import messages from './variableMessages';
import React from 'react';

export function VariableActions({ show, close, notify }) {

    const loadVariableLists = async (offset, limit, variableFilter = {}) => {
        show(() => <ActivityIndicator text={messages.loadVariables} />)
        const [error, data] = await getVariablesList(offset, limit, variableFilter);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        return [null, data];
    }

    const saveVariable = async (json) => {
        show(() => <ActivityIndicator text={messages.saveVariable} />)
        const [error, data] = await createVariable(json);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        notify(data.status);
        return [null, data];
    }

    const modifyVariable = async (name, json) => {
        show(() => <ActivityIndicator text={messages.modifyVariable} />)
        const [error, data] = await updateVariable(name, json);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        notify(data.status);
        return [null, data];
    }

    const removeVariable = async (name) => {
        show(() => <ActivityIndicator text={messages.removeVariable} />)
        const [error, data] = await deleteVariable(name);
        close()
        if (error)
            return [error, null];
        if (data.code >= 400)
            return [data.message, null];
        notify(data.status);
        return [null, data];
    }

    return { loadVariableLists, saveVariable, modifyVariable, removeVariable };
}