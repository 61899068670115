import { useAppContext } from "../../components/activity/AppContext";
import FileUpload from "../../components/fileUpload";
import PopUp from "../../components/layouts/PopUp";
import { DriveActions } from "./drive.actions";
import React ,{ useEffect, useState } from "react";
import styles from "./index.module.css";
import { messages } from "./messages";

function UploadFile(props) {
    const [fileData, setFileData] = useState(null);
    const [error, setError] = useState('');
    const app = useAppContext();
    const { 
        show = false,
        projectName = "",
        selectedPath = [],
        setShow = () => null,
        getList = () => null
    } = props;

    useEffect(() => {
        setError("");
    }, [show]);

    const handleClose = () => {
        setShow("");
    }

    const validate = () => {
        return !!fileData;
    }

    const onWatch = (file) => {
        setError("");
        setFileData(file);
    }
  
    const onSubmit = async () =>{
        let parent = "";
        setError('');
        
        if(!validate()){
          setError(messages.invalidFile);
          return false;
        }
    
        if(selectedPath.length){
            parent = selectedPath.at(-1)?.path || "";
        }

        const drive = DriveActions(app())
        let [err, data] = await drive.UploadFile(parent, fileData, projectName);

        if(err || !(data.status?.code) || data.status?.type === "E"){
            setError(data.status.message)
            return false;
        } else {
            handleClose();
            getList();
            return true;
        }
    }

    return (
        <PopUp
            popUp={show}
            testId={"upload-file"}
            setPopUp={handleClose}
            title={messages.uploadFile}
            titleClassName = {styles.popupTitle}
        >
                <div className={styles.formArea}>
                    <FileUpload onChange = {onWatch} error={error} setError={setError}/>
                    {/* <p style={{color: "red"}}>{error}</p> */}
                </div>

                <div className={styles.afFooter}>
                    <button className="secondary" onClick={handleClose} data-clickable-testid={"Cancel"}> Cancel </button>
                    <button className="primary" onClick={onSubmit} data-clickable-testid={"Submit"}> Submit </button>
                </div>
        </PopUp>
    )
}

export default UploadFile;