import messages from "./messages";
import { validateName, validateLength, validate } from "../../../common/validations/Validations";

const required = (errors, name, value, msg) => {
  if (!value) {
    errors.set(name, msg);
    return false;
  }
  errors.clear(name);
  return true;
};

const validateTime = (errors, value) => {
  return required(errors, "time", value, "Please enter scheduler value");
};

const validateValue = (errors, name, value) => {
  switch (name) {
    case "name":
      return validateName(errors, name, value, messages.nameErrMsg);
    case "serviceType":
      return required(errors, name, value, messages.bosErrMsg);
    case "project":
      return validateName(errors, name, value, messages.projectErrMsg);
    case "bos":
      return required(errors, name, value, messages.bosNameErrMsg);
    case "time":
      return validateTime(errors, value);
    case "runAsUser":
      return validateName(errors, name, value, messages.userNameErrMsg);
    default:
      return true;
  }
};

const validateValues = (values, errors) => {
  let valid = true;
  Object.keys(values).forEach((name) => {
    const value = values[name];
    if (!validateValue(errors, name, value)) valid = false;
  });
  return valid;
};

export { validateValue, validateValues, required };
