import { ReactComponent as KeyboardBackArrow } from "../../images/keyboardBackArrow.svg";
import styles from "./index.module.css";
import React from "react";

function DrivePaths(props) {
    const {
        selectedPath = [],
        setSelectedPath = () => null
    } = props;

    const onPathClick = (index) => {
        if (index < 0) {
            setSelectedPath([]);
        } else {
            setSelectedPath((prev) => prev.splice(0, index + 1));
        }
    }

    const getPath = (path, index) => {
        return (
            <p onClick={() => onPathClick(index)}>{path}</p>
        )
    }

    return (
        <div className={styles.paths}>
            <KeyboardBackArrow data-clickable-testid="Back" onClick={() => onPathClick(selectedPath.length - 2)} />
            {getPath("Drive", -1)}
            {selectedPath.map((item, index) => getPath(`/ ${item.displayname}`, index))}
        </div>
    )
}

export default DrivePaths;