import React, { useState } from 'react'
import PopUp from '../../components/layouts/PopUp'
// import { useForm } from "../../../../../components/forms/Form";
import { useForm } from "../../components/forms/Form";
import styles from './driveDetails.module.css'

function Drivedetails(props) {
    const {
        setDriveDetailModal,
        driveDetailModal,
        driveDetail
    } = props;

    let style = { marginTop: "8px" };
    let style1 = { cursor: "pointer" };

    const { Form, Label, Input } = useForm();
    return (
        <PopUp setPopUp={setDriveDetailModal} popUp={driveDetailModal} title={driveDetail.displayname} testId={"folder-info"}>
            <div className={styles.formArea}>
                <div className={styles.column}>
                    <div className={styles.finalComp}>
                        <Label text={"Type"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.type} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Location"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.path} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Owner"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.createdbyname} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Size"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.size} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Modified"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.modifiedon} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Created"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.createdon} style={style} className={styles.label2} />
                    </div>
                </div>
                <div className={styles.column}>
                    <h4>Versions</h4>
                    <div className={styles.finalComp}>
                        <Label text={"Created"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.createdon} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Version"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.version} style={style} className={styles.label2} />
                    </div>
                    <div className={styles.finalComp}>
                        <Label text={"Owner"} style={style} className={styles.driveDetailHeading} />
                        <Label text={driveDetail.createdbyname} style={style} className={styles.label2} />
                    </div>
                </div>
            </div>
        </PopUp>
    )
}

export default Drivedetails