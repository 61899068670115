import messages from "./messages";

const required = (errors, name, value, msg) => {
  if (!value) {
    errors.set(name, msg);
    return false;
  }
  errors.clear(name);
  return true;
};

const validateName = (errors, value) => {
  return required(errors, "name", value, messages.reqName);
};

const validateDescription = (errors, value) => {
  return required(errors, "description", value, messages.reqDesc);
};

const validateProject = (errors, value) => {
  return required(errors, "project", value, "Please select a project");
};

const validateBos = (errors, value) => {
  return required(errors, "bos", value, "Please select a bos");
};

const validateTime = (errors,name, value) => {
  return required(errors, name, value, "Please enter scheduler value");
};

const validateHours = (errors,name,value) => {
  const msg = "Please enter valid hour"
  let flag = false;
  if(!value)
    errors.set(name, msg);
  else if(!(value>=0 && value<=23))
    errors.set(name, msg);  
  else{
    flag=true
    errors.clear(name)
  }

  return flag;
};

const validateMinutes = (errors,name,value) => {
  const msg = "Please enter valid minutes"
  let flag = false;
  if(!value)
    errors.set(name, msg);
  else if(!(value>=0 && value<=59))
    errors.set(name, msg);  
  else{
    flag=true
    errors.clear(name)
  }

  return flag;
};

const validateValue = (errors, name, value) => {
  switch (name) {
    case "name":
      return validateName(errors, value);
    case "description":
      return validateDescription(errors, value);
    case "project":
      return validateProject(errors, value);
    case "bos":
      return validateBos(errors, value);
    case "time":
      return validateTime(errors,name, value);

    default:
      return true;
  }
};

const validateValues = (values, errors) => {
  let valid = true;
  Object.keys(values).forEach((name) => {
    const value = values[name];
    if (!validateValue(errors, name, value)) valid = false;
  });
  return valid;
};

export { validateValue, validateValues, required,validateTime,validateHours, validateMinutes };
