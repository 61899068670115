import React, { useEffect, useState } from "react";
import { ToolTip } from "../../components/ToolTip/ToolTip";
import "./folderTree.css";

const FolderTree = (props) => {
  const { name, 
          data = [], 
          setSelectedNodes = () => null, 
          validateDestination = () => null, 
          setDisableSubmit = () => null } = props;
  const [treeData, setTreeData] = useState(data);
  const [lastNode, setLastNode] = useState(null);
  useEffect(() => {
  }, [treeData]);
  return (
    <div data-tree-testid={name}>
      {treeData.map((node, index) => (
        <ul className="rootT">
          <TreeNode 
              key={index} 
              node={node} 
              data={treeData}  
              setSelectedNodes={setSelectedNodes} 
              validateDestination={validateDestination} 
              lastNode={lastNode} 
              setLastNode={setLastNode}
              setDisableSubmit={setDisableSubmit}
          />
        </ul>
      ))}
    </div>
  );
};

const TreeNode = ({ node, key, data, setSelectedNodes, validateDestination, lastNode, setLastNode,setDisableSubmit}) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  }

  const onLeave = () => {
    setHover(false);
  }
  

  const openTreeNode = (node) => {
    node.open == "nestedNode" ? node.open = "activeNode" : node.open = "nestedNode";
    if(lastNode.id != node.id)
      lastNode.open = "nestedNode";
    setLastNode(node);
    setDisableSubmit(true);
  }
  const selectNode = (node) => {
    node.open == "nestedNode" ? node.open = "activeLeaf" : node.open = "nestedNode";
    if(lastNode && lastNode.id!=node.id && lastNode.id != node.parentId)
      lastNode.open = "nestedNode";
    setLastNode(node);
    setSelectedNodes(node);
    validateDestination(node.path || '/');
  }

  return (
    <li data-row-testid={node.name} style={{ width: "100%" }} key={key}>
      {node.children.length === 0
        ? (<>
            <span data-clickable-testid={`${node.name}`} className={`span_open ${node.open === "activeLeaf" ? "activeRoot" : ""}`} onClick={() => selectNode(node)}>
            <div style={{ height: "20px", width: "20px" }}><img src={"/images/drive/leaf.svg"} /></div>
              {/* <ToolTip content={node.name} direction={"bottom"}> */}
                {node.name}
              {/* </ToolTip> */}
            </span>
        </>)
        : (<>

          <div onMouseEnter={onHover} onMouseLeave={onLeave} className={`workspaceTableT ${node.open === "activeLeaf" ? "activeRoot" : ""}`}>
          <div style={{ height: "20px", width: "20px" }}><img src={`${node.open === "activeNode" ? "/images/drive/minus.svg" : "/images/drive/plus.svg"}`} onClick={() => openTreeNode(node)} /></div>
            <span data-clickable-testid={`${node.name}`} className="leafnode" onClick={() => openTreeNode(node)}>
              {/* <ToolTip content={node.name} direction={"bottom"}> */}
                {node.name}
              {/* </ToolTip> */}
            </span>
          </div>

          <ul className={node.open} key={key}>
            {node.children?.map((child) => <TreeNode 
                                              node={child} 
                                              data={data} 
                                              setSelectedNodes={setSelectedNodes} 
                                              validateDestination={validateDestination} 
                                              lastNode={lastNode}
                                              setLastNode={setLastNode}
                                              setDisableSubmit={setDisableSubmit}
                                              />)}
          </ul>
        </>)}
    </li>
  );
};

export default FolderTree;