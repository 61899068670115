import { useAppContext } from '../../../components/activity/AppContext';
import { ModuleTitle } from '../../../components/module-title';
import React, { useEffect, useState } from 'react';
import { LoginsActions } from './logins.actions';
// import { useNavigate } from 'react-router-dom';
import messages from './loginsMessages';
import LoginsList from './loginsList';
import NewLogin from './newLogin';
import { useHistory, useLocation } from 'react-router-dom';


function Logins({perm}) {
    const [newLogin, setNewLogin] = useState(false);
    const [appPage,setAppPage] = useState(false)
    const [loginsList, setLoginsList] = useState(null);
    // const navigate = useNavigate();
    const history = useHistory()
    const app = useAppContext();
    const location = useLocation();
    location.state = {permission:perm}

    const getDependencies = () => {

        const getProjectsList = async () => {
            let logins = [];
            const actions = LoginsActions(app());
            let [error, data] = await actions.getList();
            if(!error){
                logins = data.data || [];
            }
            setLoginsList(logins);
        }

        getProjectsList();
    }

    useEffect(getDependencies, []);

    const navigateToBf = (obj = {}) => {
        // navigate(`/project/${obj.projectName || "LoginMicroApp"}/bf/${obj.name}`);
        history.push(`/project/${obj.projectName || "LoginMicroApp"}/BusinessFunction/${obj.name}`);
    }

    return (
        <>
            <ModuleTitle
                title={messages.logins}
            >
            {
                location.state.permission?.canCreate
                ?<button data-clickable-testid={messages.newLogin} className='primary' onClick={() => setNewLogin(true)} >
                    {messages.newLogin}
                </button>
                :<></>
            }
            </ModuleTitle>
            <div className='afModule'>
                <LoginsList 
                    list={loginsList || []} 
                    navigate = {navigateToBf}
                    isLoading={!Array.isArray(loginsList)}
                />
            </div>
            {
                //Creating Login
                (newLogin || appPage) &&
                <NewLogin 
                    show={newLogin} 
                    setShow={setNewLogin} 
                    navigate={navigateToBf}
                    appPage={appPage}
                    setAppPage={setAppPage}
                    getList={getDependencies}
                />
            }
        </>
    )
}

export default Logins