// import { ActionTypes } from '@mui/base';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../../components/activity/AppContext';
import PopUp from '../../../../components/layouts/PopUp'
import { ServiceActions } from '../services.actions';
import './basApiPopup.css'

function BasApiPopUp(props) {
    const { setUserModal, userModal } = props;
    const [apiKey, setApiKey] = useState({});
    const app = useAppContext();
    const location = useLocation();


    const handleRegenerateApi = async () => {
        const { notify } = app()
        const action = ServiceActions(app());
        const [error, data] = await action.regenerateBaasApiKeys();
        if (!error) {
            setApiKey(data?.detail);
            notify({ code: 1, message: "Successfully regenerated!", type: "S" })
        }
    }

    const getApiKey = async () => {
        const action = ServiceActions(app());
        const [error, data] = await action.getBaasApiKeys();
        if (!error)
            setApiKey(data?.detail);
    }

    useEffect(() => {
        getApiKey()
    }, [])

    const copyTextHandler = (name, value) => {
        const { notify } = app();
        navigator.clipboard.writeText(value);
        if (name === "key") {
            notify({ code: 1, message: "Key copied to clipboard !", type: "S" })
        }
        if (name === "secret") {
            notify({ code: 1, message: "Secret copied to clipboard!", type: "S" })
        }

    }

    return (
        <>
            <PopUp setPopUp={setUserModal} popUp={userModal} testId={"Baas-Api-information"}>
                <div className='basMain'>
                    <div className='basHeading'>
                        <h1>BAAS API Information
                        </h1>
                    </div>
                    <div className='basContentMain'>
                        <div className='basContentWrap'>
                            <p className='basSubHeading'>{"Key : "}</p>
                            <p className='valueText'>{apiKey?.keyValue}</p>
                            <button data-clickable-testid="copy-key" className="copyBtn" onClick={() => { copyTextHandler("key", apiKey.keyValue) }}>
                                <svg viewBox="0 0 24 24" style={{ width: "24px" }}>
                                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='basContentMainVaried'>
                        <div className='basContentWrap'>
                            <p className='basSubHeading'>{"Secret : "}</p>
                            <p className='valueText'>{apiKey?.secretValue}</p>
                            <button data-clickable-testid="copy-secret" className="copyBtn" onClick={() => { copyTextHandler("secret", apiKey.secretValue) }}>
                                <svg viewBox="0 0 24 24" style={{ width: "24px" }}>
                                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='basContentMain'>
                    {
                        location.state.permission.childPermissions[0]?.canCreate
                        ?<button
                            style={{ width: "auto" }}
                            className='primary'
                            data-clickable-testid={"regenerate"}
                            onClick={handleRegenerateApi}
                        >
                            {"Regenerate"}
                        </button>
                        :<></>
                    }
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default BasApiPopUp