import getComponentDefaultSchema from '../../app-designer/utils/configuration/defaultSchema/components.defaultSchema';
import { EVENTS_SCHEMA } from '../../app-designer/utils/configuration/schema';
import React from 'react';
import { connect } from 'react-redux';
import './BlocklyComponent.css';
import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import { COMPONENT_ARGS } from './componentArgs';
import 'blockly/blocks';
import { styles } from './styles';
import { renderImage } from './blocklyimage';
import {
  getStepTransitions,
  getStepNavigation,
  pageViewPermissions,
} from '../../../utils/common';
import { EVENT_ARGS } from '../generator/eventArgs';


let userTask = 'UserTask';
let serviceTask = 'ServiceTask';
let selectedComponentType = '';
let gCompType='';
let selectedCompId = '';
const VAR_ERR_OBJ = "err";
Blockly.setLocale(locale);
const dPath = 'M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z';
const flyoutPathWidth =
  'M 0,0 h 474.74218749999994 a 8 8 0 0 1 8 8 v 484 a 8 8 0 0 1 -8 8 h -474.74218749999994 z';
let serviceNameVariable = [];
let screenNameVariable = [];


Blockly.BlockDragger.prototype.startBlockDrag = function(currentDragDeltaXY,
  healStack) {
    healStack = true; // Allow to move block in workspace individually
  if (!Blockly.Events.getGroup()) {
    Blockly.Events.setGroup(true);
  }
  this.fireDragStartEvent_();

  // Mutators don't have the same type of z-ordering as the normal workspace
  // during a drag.  They have to rely on the order of the blocks in the SVG.
  // For performance reasons that usually happens at the end of a drag,
  // but do it at the beginning for mutators.
  if (this.workspace_.isMutator) {
    this.draggingBlock_.bringToFront();
  }

  // During a drag there may be a lot of rerenders, but not field changes.
  // Turn the cache on so we don't do spurious remeasures during the drag.
  Blockly.utils.dom.startTextWidthCache();
  this.workspace_.setResizesEnabled(false);
  Blockly.blockAnimations.disconnectUiStop();

  if (this.draggingBlock_.getParent() ||
      (healStack && this.draggingBlock_.nextConnection &&
      this.draggingBlock_.nextConnection.targetBlock())) {
    this.draggingBlock_.unplug(healStack);
    var delta = this.pixelsToWorkspaceUnits_(currentDragDeltaXY);
    var newLoc = Blockly.utils.Coordinate.sum(this.startXY_, delta);

    this.draggingBlock_.translate(newLoc.x, newLoc.y);
    Blockly.blockAnimations.disconnectUiEffect(this.draggingBlock_);
    this.draggedConnectionManager_.updateAvailableConnections();
  }
  this.draggingBlock_.setDragging(true);
  // For future consideration: we may be able to put moveToDragSurface inside
  // the block dragger, which would also let the block not track the block drag
  // surface.
  this.draggingBlock_.moveToDragSurface();

  var toolbox = this.workspace_.getToolbox();
  if (toolbox && typeof toolbox.addStyle == 'function') {
    var style = this.draggingBlock_.isDeletable() ? 'blocklyToolboxDelete' :
        'blocklyToolboxGrab';
    toolbox.addStyle(style);
  }
};


Blockly.Events.VarRename.prototype.run = function (forward) {
  var workspace = this.getEventWorkspace_();
  if (forward) {
    workspace.renameVariableById(this.varId, this.newName);
  } else {
    workspace.renameVariableById(this.varId, this.oldName);
  }
};
const keydown=Blockly.onKeyDown;
Blockly.onKeyDown=(a)=>{
    try{
        keydown(a);
    }catch(e){
        //console.log("Error",e);
  }
}
Blockly.Blocks['procedures_mutatorarg'].validator_= function(varName) {
  const sourceBlock = this.getSourceBlock();
  const outerWs = Blockly.Mutator.findParentWs(sourceBlock.workspace);
  varName = varName.replace(/[\s\xa0]+/g, ' ').replace(/^ | $/g, '');
  if (!varName) {
    return null;
  }
  // Prevents duplicate parameter names in functions
  const workspace =
      sourceBlock.workspace.targetWorkspace || sourceBlock.workspace;
  const blocks = workspace.getAllBlocks(false);
  if(varName==='x'){
    varName=`${varName}${blocks.length-1}`;
  }
  const caselessName = varName.toLowerCase();
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].id === this.getSourceBlock().id) {
      continue;
    }
    // Other blocks values may not be set yet when this is loaded.
    const otherVar = blocks[i].getFieldValue('NAME');
    if (otherVar && otherVar.toLowerCase() === caselessName) {
      return null;
    }
  }

  // Don't create variables for arg blocks that
  // only exist in the mutator's flyout.
  if (sourceBlock.isInFlyout) {
    return varName;
  }

  let model = outerWs.getVariable(varName, '');
  if (model && model.name !== varName) {
    // Rename the variable (case change)
    outerWs.renameVariableById(model.getId(), varName);
  }
  if (!model) {
    model = outerWs.createVariable(varName, '');
    if (model && this.createdVariables_) {
      this.createdVariables_.push(model);
    }
  }
  return varName;
}
const Touch = Blockly.Touch;
const {globalThis} = Blockly.utils.global;

const conditionalBind = function(
  node, name, thisObject, func, opt_noCaptureIdentifier,
  opt_noPreventDefault) {
let handled = false;
const wrapFunc = function(e) {
  const captureIdentifier = !opt_noCaptureIdentifier;
  // Handle each touch point separately.  If the event was a mouse event, this
  // will hand back an array with one element, which we're fine handling.
  const events = Touch.splitEventByTouches(e);
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    if (captureIdentifier && !Touch.shouldHandleEvent(event)) {
      continue;
    }
    Touch.setClientFromTouch(event);
    if (thisObject) {
      func.call(thisObject, event);
    } else {
      func(event);
    }
    handled = true;
  }
};

const bindData = [];
if (globalThis['PointerEvent'] && (name in Touch.TOUCH_MAP)) {
  for (let i = 0; i < Touch.TOUCH_MAP[name].length; i++) {
    const type = Touch.TOUCH_MAP[name][i];
    node.addEventListener(type, wrapFunc, false);
    bindData.push([node, type, wrapFunc]);
  }
} else {
  node.addEventListener(name, wrapFunc, false);
  bindData.push([node, name, wrapFunc]);

  // Add equivalent touch event.
  if (name in Touch.TOUCH_MAP) {
    const touchWrapFunc = function(e) {
      wrapFunc(e);
      // Calling preventDefault stops the browser from scrolling/zooming the
      // page.
      const preventDef = !opt_noPreventDefault;
      if (handled && preventDef) {
        e.preventDefault();
      }
    };
    for (let i = 0; i < Touch.TOUCH_MAP[name].length; i++) {
      const type = Touch.TOUCH_MAP[name][i];
      node.addEventListener(type, touchWrapFunc, false);
      bindData.push([node, type, touchWrapFunc]);
    }
  }
}
return bindData;
};
const aria = Blockly.utils.aria;
//const menuRender=Blockly.Menu.prototype.render;
Blockly.Menu.prototype.render=function(container){
  const searchFunction= function(e) {
    var input, filter, ul, li, a, i;
    input = e.currentTarget;
    filter = input.value.toUpperCase();
    ul = document.getElementById("blocklyMenu");
    li = ul.getElementsByClassName("blocklyMenuItemContent");
    for (i = 0; i < li.length; i++) {
      a = li[i];
      if (a.textContent.toUpperCase().indexOf(filter) > -1) {
        li[i].parentElement.style.display = "";
      } else {
        li[i].parentElement.style.display = "none";
      }
    }
  }
  const element =
        /** @type {!HTMLDivElement} */ (document.createElement('div'));
    // goog-menu is deprecated, use blocklyMenu.  May 2020.
    element.id ="blocklyMenu";
    element.className = 'blocklyMenu goog-menu blocklyNonSelectable';
    element.tabIndex = 0;
    if (this.roleName_) {
      aria.setRole(element, this.roleName_);
    }
    this.element_ = element;

    // Add menu items.
    for (let i = 0, menuItem; (menuItem = this.menuItems_[i]); i++) {
      element.appendChild(menuItem.createDom());
    }

    // Add event handlers.
    this.mouseOverHandler_ = conditionalBind(
        element, 'mouseover', this, this.handleMouseOver_, true);
    this.clickHandler_ = conditionalBind(
        element, 'click', this, this.handleClick_, true);
    this.mouseEnterHandler_ = conditionalBind(
        element, 'mouseenter', this, this.handleMouseEnter_, true);
    this.mouseLeaveHandler_ = conditionalBind(
        element, 'mouseleave', this, this.handleMouseLeave_, true);
    this.onKeyDownHandler_ = conditionalBind(
        element, 'keydown', this, this.handleKeyEvent_);

    
    if(this.roleName_==="listbox"){
      var x = document.createElement("INPUT");
      x.setAttribute("id", "myMenuSearch");
      x.setAttribute("type", "text");
      x.setAttribute("placeholder", "Search");
      x.setAttribute("style",'width: 100%;font-size: 18px;padding: 11px;border: 1px solid #ddd;');
      x.setAttribute("autocomplete", "off");
      x.onkeyup=searchFunction;
      container.appendChild(x);
    }
    
    container.appendChild(element);
    
}
class BlocklyComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.blocklyDiv = React.createRef();
    this.toolbox = React.createRef();
    this.state = {
      selectedCategory: '',
      // loadFunc:this.loadServiceAndScreenData.bind(this),
      searchValue: false,
    };
  }

  myUpdateFunction = () => {
    let code = Blockly.JavaScript.workspaceToCode(this.primaryWorkspace);
    document.getElementById('textarea').value = code;
  };

  /* load xml file of particular category */
  getCategoryXMLFile = (categoryInfo, callback) => {
    let request = new XMLHttpRequest();
    request.open('GET', '/json/blocks/' + categoryInfo.xml, false);
    request.setRequestHeader('Content-Type', 'text/xml');
    request.onreadystatechange = function () {
      if (request.response != null && request.response != '') {
        callback(request.response);
      }
    };
    request.send();
  };

  flyoutBgWidth = () => {
    document
      .getElementsByClassName('blocklyFlyoutBackground')[0]
      .setAttribute('d', flyoutPathWidth);
  };

  /* select category in toolbox */
  categorySelected = (e) => {
    let selectedCategory = e.info.category;
    const workSpace = this.primaryWorkspace;
    if (this.state.selectedCategory === e.info.category) {
      this.setState({ selectedCategory: '' });
      this.collapseToolbox();
      workSpace.updateToolbox(document.getElementById('toolbox'));
      renderImage(document.getElementsByClassName('blocklyFlyoutButton'));
      Blockly.svgResize(workSpace);
      this.flyoutBgWidth();
    } else {
      this.setState({ selectedCategory: e.info.category });
      this.getCategoryXMLFile(e.info, function (XML) {
        document.getElementById('toolbox').innerHTML = XML;
        workSpace.updateToolbox(document.getElementById('toolbox'));
        renderImage(document.getElementsByClassName('blocklyFlyoutButton'));
        Blockly.svgResize(workSpace);
        const gButton = document.getElementsByClassName('blocklyFlyoutButton');
        for (const item of gButton) {
          if (item.children[2].textContent === e.info.category) {
            const arrowIcon = document.getElementsByClassName(
              `${e.info.category}-down-arrow`
            );
            if (arrowIcon && arrowIcon[0].nodeName === 'svg') {
              arrowIcon[0].children[0].setAttribute('d', dPath);
            }
          }
        }
      });
      this.flyoutBgWidth();
    }
    const flyoutButton = document.getElementsByClassName(
      'blocklyFlyoutButtonBackground'
    );
    for (const button of flyoutButton) {
      button.setAttribute('rx', 0);
      button.setAttribute('ry', 0);
    }
    const hideBlock = document.getElementsByClassName('blocklyDraggable');
    hideBlock[hideBlock.length - 2].style.visibility = 'hidden';
  };

  componentDidUpdate(prevProps) {
    const prevPropsData = JSON.stringify(prevProps.taskVariables?.data);
    const currentPropsData = JSON.stringify(this.props.taskVariables?.data);

    const prevGraphJson = JSON.stringify(prevProps.graphJson?.steps);
    const currentGraphJson = JSON.stringify(this.props.graphJson?.steps);

    if (
      prevPropsData !== currentPropsData ||
      prevGraphJson !== currentGraphJson ||
      prevProps.pageName !== this.props.pageName
    ) {
      Blockly.Extensions.unregister('getServices');
      Blockly.Extensions.unregister('getNavigationScreens');
      this.navigateScreenExtensionRegister();
    }
  }

  getScreenNamesForWorkflow = () => {
    let stepTransitions = [];
    const getTask = this.props.graphJson.steps?.filter((item) => {
      return item.type === userTask;
    });
    if (getTask && getTask.length > 0) {
      let arrSteps = [];
      let arrStepTransitions = [];
      getTask.map((item) => {
        arrSteps = [...arrSteps, ...(item?.steps || [])];
        let a = this.props.graphJson?.stepTransitions.map((val) => {
          if (item.name === val.stepName) {
            arrStepTransitions = [
              ...arrStepTransitions,
              ...(val?.stepTransitions || []),
            ];
          }
        });
        let groups = item.steps.filter((sT) => {
          return sT.type === 'Task';
        });
        groups &&
          groups.map((group) => {
            if (group.hasOwnProperty('steps'))
              arrSteps = [...arrSteps, ...group.steps];
            if (group.hasOwnProperty('stepTransitions'))
              arrStepTransitions = [
                ...arrStepTransitions,
                ...(group.stepTransitions || []),
              ];
          });
      });

      let graphSteps = {
        ...(this.props?.graphJson || {}),
        steps: arrSteps,
        stepTransitions: arrStepTransitions,
        parentStepTransitions: this.props.graphJson.stepTransitions,
        parentSteps: this.props.graphJson.steps,
      };

      const isWorkflow = this.props.bfDetails?.type === 'WFM';
      stepTransitions = getStepTransitions(
        graphSteps,
        this.props.pageName || '',
        isWorkflow
      );
    }
    // stepTransitions = stepTransitions?.map((e) => {
    //   return { ...e, uid: e.objectId || '' };
    // });

    return stepTransitions.filter(
      (f) => f.type === serviceTask && f.uid && f.name
    );
  };

  /* screen and navigation events register */
  navigateScreenExtensionRegister = () => {
    let steps = this.props.graphJson?.steps;
    let serviceNames = [];
    let stepTransitions = [];
    // if (this.props.bfDetails?.type !== 'WFM') {
    //   if (steps && steps.length > 0) {
    //     stepTransitions = getStepTransitions(
    //       this.props.graphJson,
    //       this.props.pageName || ''
    //     );

    //   }
    // } else {
    //   serviceNames = this.getScreenNamesForWorkflow();
    // }
    stepTransitions = getStepNavigation(
      this.props.graphJson,
      this.props.pageName || '',
      true
    );
    serviceNames = stepTransitions.filter(
      (e) => e.type === serviceTask && e.uid && e.name
    );
    serviceNames = [{name: "Select", type: serviceTask, objectId: "", uid: ""}, ...(serviceNames || [])];

    let screenNames = this.props.taskVariables?.data?.screens;
    if (serviceNames && serviceNames.length > 0) {
      serviceNameVariable = serviceNames.flatMap((x) => [[x.name, x.uid]]);
    } else {
      serviceNameVariable = [['', '']];
    }

    if (screenNames && screenNames.length > 0) {
      screenNameVariable = screenNames.flatMap((x) => [[x.objectId, x.name]]);
    } else {
      screenNameVariable = [['', '']];
    }
    try {
      if (Blockly.Extensions) {
        this.registerInvokeServiceExtension();
        Blockly.Extensions.register('getNavigationScreens', function () {
          if (screenNameVariable?.length > 0) {
            this.getInput('INPUT').appendField(
              new Blockly.FieldDropdown(screenNameVariable),
              'naviagtescreens'
            );
          }
        });
      }
    } catch (e) {
      // console.log('Extension failed to register');
    }
  };

  getPageComponentName = (id, graphJson) => {
      const pageName = graphJson?.PageList?.find(item => {
        return item.uid === id;
      });
      return pageName?.name || "";
    };

  /* setcomponentAndEvents extension register */
  eventStartExtensionRegister = () => {
    let pageEvent = [];
    let componentEvent = [];
    const { selectedComponent, graphJson } = this.props;
    let componentId = selectedComponent
      ? selectedComponent.id
      : 'path';
    let componentIdName = selectedComponent
      ? selectedComponent.propertyValue?.component?.id
      : 'path';
    let componentName = selectedComponent
      ? selectedComponent.propertyValue?.component?.componentName
      : 'path';
    let gComponentName = selectedComponent?selectedComponent.gComponentName?selectedComponent.gComponentName:gCompType:gCompType;
    const getPageName = this.getPageComponentName(this.props.matchParams?.PageId, graphJson);
    
    if (
      this.props.openBehavior &&
      !(
        this.props.selectedComponent &&
        this.props.selectedComponent.propertyValue
      )
    ) {
      componentId = this.props.behaviourData?.behaviourName;
      componentIdName = this.props.behaviourData?.behaviourName;
      if (this.props.behaviourData?.isPageScript) componentName = 'page';
      else {
        componentName = this.props.behaviourData?.behaviourName;
      }
    }

    /**
    *  Commenting below code to render COMPONENT specific EVENTS
    * */

    /**
    const componentEvent = [
      ['click', 'click'],
      ['change', 'change'],
      ['keyDown', 'keyDown'],
      ['keyUp', 'keyUp'],
      ['Scanned', 'Scanned'],
      ['PullToRefresh', 'PullToRefresh'],
      ['SwipeLeft', 'swipeLeft'],
      ['SwipeRight', 'swipeRight']
    ];
    
    if (
      componentName?.toLowerCase() === 'page' ||
       getPageName === componentId
    ) {
      pageEvent = [
        ['onPageShow', 'onPageShow'],
        ['onPageHide', 'onPageHide'],
        ['SwipeLeft', 'swipeLeft'],
        ['SwipeRight', 'swipeRight']
      ];
      // ["onPageCreate", "onPageCreate"],["onPageChange", "onPageChange"]];
    } else {
      pageEvent = [];
    }
    **/

    let obj = {
      data:{},
      componentId:componentIdName, 
      gComponentName: gComponentName,
      componentName:selectedComponentType?.toLowerCase() || ''
    };
    console.log("selected comp",obj);
    if(selectedComponentType === 'PAGE') {
      pageEvent = getComponentDefaultSchema({ ...obj, isPage: true })?.events || [];
    }else if(selectedComponentType){
      componentEvent = getComponentDefaultSchema({ ...obj, isPage: false })?.events || [];
    }else {
      componentEvent = Object.keys(EVENTS_SCHEMA).map(e => { return EVENTS_SCHEMA[e] });
    }

    Blockly.Extensions.unregister('setcomponentAndEvents');

    Blockly.Extensions.register('setcomponentAndEvents', function () {
      this.getInput('INPUT')
        .appendField(
          new Blockly.FieldImage('/jsbuilder/Fill.svg', 15, 15, {
            alt: '*',
            flipRtl: 'FALSE',
          })
        )
        .appendField('When')
        .appendField(
          new Blockly.FieldDropdown([[componentIdName,selectedCompId]]),
          'component'
        )
        .appendField('is');

      var thisBlock = this

      var validator = function (newValue) {
        let parameters = `with: ${EVENT_ARGS[newValue] || EVENT_ARGS['DEFAULT']}`;
        thisBlock.getField("fun_params").setValue(parameters)
        return newValue;
      };
      
      if (pageEvent.length > 0) {
        this.getInput('INPUT').appendField(
          new Blockly.FieldDropdown(pageEvent),
          'event'
        );
      } else if (componentEvent.length > 0){
        this.getInput('INPUT').appendField(
          new Blockly.FieldDropdown(componentEvent, validator),
          'event'
        );
      }
      let parameters = 'with: ';
      let args = COMPONENT_ARGS[selectedComponentType?.toLowerCase()];
      parameters += ( args || COMPONENT_ARGS.DEFAULT );
      this.appendDummyInput().appendField(parameters, 'fun_params');
      this.appendStatementInput('NAME').setCheck(null);
      this.setColour(160);
      this.setTooltip('main event');
      this.setHelpUrl('');
    });
  };

  registerInvokeServiceExtension = () => {
    Blockly.Extensions.register('getServices', function () {
      if (serviceNameVariable?.length > 0) {
        this.getInput('SERVICE').appendField(
          new Blockly.FieldDropdown(serviceNameVariable),
          'services'
        );
      }
    });
  };
  /* cleanup */
  componentWillUnmount() {
    this.mounted = false;
    this.primaryWorkspace.dispose();
    Blockly.Extensions.unregister('setcomponentAndEvents');
    Blockly.Extensions.unregister('getNavigationScreens');
    Blockly.Extensions.unregister('getServices');
    if (Blockly.ContextMenuRegistry.registry.getItem('workspaceDelete')) {
      Blockly.ContextMenuRegistry.registry.unregister('workspaceDelete');
    }
    this.props.loadXmlData({ xmlData: '', componentData: '' });
    serviceNameVariable = [];
    screenNameVariable = [];
    selectedComponentType = '';
    gCompType = '';
    selectedCompId = '';
  }

  /* toggle open/close toolbox */
  collapseToolbox = () => {
    document.getElementById('toolbox').innerHTML =
      document.getElementById('refernceToolbox').innerHTML;
  };

  /* initial render */
  async componentDidMount() {
    this.mounted = true;
    const { initialXml, children, ...rest } = this.props;
    this.collapseToolbox();
    this.primaryWorkspace = Blockly.inject(this.blocklyDiv.current, {
      toolbox: this.toolbox.current,
      renderer: 'zelos',
      isFlyout: true,
      toolboxPosition: 'left',
      ...rest,
    });
    this.registerButtonCallback();
    if (initialXml) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initialXml),
        this.primaryWorkspace
      );
      this.primaryWorkspace.addEventListener = new Function();
      this.primaryWorkspace.addEventListener(this.myUpdateFunction());
    }
    this.initialLoad();
    renderImage(document.getElementsByClassName('blocklyFlyoutButton'));
    this.undoRedoAction();

    const [blocklyFlyoutBg] = document.getElementsByClassName(
      'blocklyFlyoutBackground'
    );
    if (blocklyFlyoutBg) {
      blocklyFlyoutBg.setAttribute('d', flyoutPathWidth);
    }

    const [_,blBlockCanvas] =
      document.getElementsByClassName('blocklyBlockCanvas');
    if (blBlockCanvas) {
      for (var item of blBlockCanvas.children) {
        if (item.classList.contains('blocklyDraggable')) {
          item.style.visibility = 'hidden';
        }
      }
    }

    const flyoutButton = document.getElementsByClassName(
      'blocklyFlyoutButtonBackground'
    );
    for (const button of flyoutButton) {
      button.setAttribute('rx', 0);
      button.setAttribute('ry', 0);
    }
    setTimeout(() => {
      const debounce = (func, delay) => {
        let debounceTimer
        return function() {
            const context = this
            const args = arguments
                clearTimeout(debounceTimer)
                    debounceTimer
                = setTimeout(() => func.apply(context, args), delay)
        }
    } 
      this.primaryWorkspace.addChangeListener(debounce(this.updateData,200));
      this.primaryWorkspace.addChangeListener(this.changeEvent);
    }, 3000);
    //this.scrollLeft();

    if (this._hasDeletePermssion()) {
      // If has delete permission, then register delete context item into workspace
      this.customDeleteContextMenu();
      Blockly.ContextMenuItems.registerDeleteAll1();
    } else if (Blockly.ContextMenuRegistry.registry.getItem('blockDelete')) {
      // Else remove delete the default context item from block too
      Blockly.ContextMenuRegistry.registry.unregister('blockDelete');
    }

    await this.navigateScreenExtensionRegister();
    // Checking delete permission
   
  }

  triggerEventExtensionRegister = () => {
    Blockly.Extensions.unregister('setcomponentAndEvents');
    this.eventStartExtensionRegister();
  }

  _hasDeletePermssion = () => {
    let allowed = false;
    const pageViewPolicy = pageViewPermissions();
    if (pageViewPolicy && pageViewPolicy.canDelete) {
      allowed = true;
    }
    return allowed;
  };

  registerButtonCallback = () => {
    this.primaryWorkspace.registerButtonCallback(
      'startButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'logicButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'loopsButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'mathButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'textButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'elementsButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'functionsButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'variablesButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'listButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'eventsButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'storageButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'serviceButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'advanceButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'utilitiesButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'dateButton',
      this.categorySelected
    );
    this.primaryWorkspace.registerButtonCallback(
      'bluetoothButton',
      this.categorySelected
    );

    this.primaryWorkspace.registerButtonCallback(
      'createVariableFunction',
      function (button) {
        Blockly.Variables.createVariable(button.getTargetWorkspace());
      }
    );
  };

  /* initial api call */
  initialLoad = async () => {
    Blockly.Events.recordUndo = false;
    let pageId;
    let componentName;
    if (
      this.props.openBehavior &&
      !(
        this.props.selectedComponent &&
        this.props.selectedComponent.propertyValue
      )
    ) {
      pageId = this.props.behaviourData?.pageId;
      componentName = this.props.behaviourData?.behaviourName;
    }
    this.workspace.options.oneBasedIndex = false;
    const initData = await this.props.fetchJsBuilderComponent(
      pageId,
      componentName
    );
    let componentType = this.props.selectedComponent?.type || 'PAGE';
    let compId = this.props.selectedComponent?.cellId || this.props.selectedComponent?.id || this.props.selectedComponent?.uuid || '';
    compId=compId.replace(/-/g, '_');
    let gComp = this.props.selectedComponent?.gComponentName || '';
    const initXmlData = `<xml xmlns="https://developers.google.com/blockly/xml"> <block type="event_start" componentId="${compId}" componentType="${componentType}" gComp="${gComp}" id="RZ$k1B0#mb*N!d|TfNrD" x="478" y="384">     <field name="component">componentName</field> <field name="event">onPageShow</field> </block> </xml>`;
    if ((initData.xmlData || initData.componentData?.id) && this.mounted) {
      let xml = null;
      if (initData.xmlData) {
        xml = Blockly.Xml.textToDom(initData.xmlData);
        selectedComponentType = xml.getElementsByTagName("block")?.[0]?.getAttribute("componentType") || "";
        selectedCompId = xml.getElementsByTagName("block")?.[0]?.getAttribute("componentId") || "";
        gCompType =   xml.getElementsByTagName("block")?.[0]?.getAttribute("gComp") || "";
        if(!selectedCompId || selectedCompId===""){ //Handling scenarios where the previous xml wont have component ids
          selectedCompId = compId;
        }
      }else{
        xml = Blockly.Xml.textToDom(initXmlData);
        selectedComponentType = componentType;
        selectedCompId = compId;
        gCompType =gComp;
      }
      
      this.triggerEventExtensionRegister();
      Blockly.Xml.domToWorkspace(xml, this.primaryWorkspace);
    } else {
      const pageViewPolicy = pageViewPermissions();
      //TO DO: Read only don't call create Behaviour
      // if (this.mounted && !this.props.readOnly) {
      if (this.mounted && pageViewPolicy.allowUpdate()) {
        const xml = Blockly.Xml.textToDom(initXmlData);
        selectedComponentType = componentType;
        selectedCompId = compId;
        gCompType = gComp;
        this.triggerEventExtensionRegister();
        Blockly.Xml.domToWorkspace(xml, this.primaryWorkspace);
        const jsCode = Blockly.JavaScript.workspaceToCode(
          this.primaryWorkspace
        );
        this.props.createBehaviour(initXmlData, jsCode);
      }
    }
    let _bf_var = await this.props.varList?.variable;
    try {
      // console.log("Variable List", _bf_var)
      if (_bf_var && _bf_var.length > 0) {
        _bf_var.map((e) => {
          if (
            !Blockly.Variables.getVariable(
              this.workspace,
              null,
              e.name,
              'bf_var'
            )
          )
            Blockly.Variables.getOrCreateVariablePackage(
              this.workspace,
              null,
              e.name,
              'bf_var'
            );
        });
      }
      var evntVar = COMPONENT_ARGS[selectedComponentType?.toLowerCase()] || COMPONENT_ARGS.DEFAULT;
      evntVar = evntVar.split(',');
      evntVar.push(VAR_ERR_OBJ); // TryCatch error object
      evntVar.map((e) => {
        if (!Blockly.Variables.getVariable(this.workspace, null, e, '_event'))
          Blockly.Variables.getOrCreateVariablePackage(
            this.workspace,
            null,
            e,
            '_event'
          );
      });
      let variable = Blockly.Variables.getVariable(
        this.workspace,
        null,
        'item'
      );
      if (variable)
        this.workspace.renameVariableById(variable.getId(), '__item');
    } catch (e) {
      // console.log(e)
    }
    Blockly.Events.recordUndo = true;
  };

  /* search value in leftpanel toolbox */
  handleSearchValue = (event) => {
    if (event?.target?.value) {
      this.setState({
        searchValue: true,
      });
      this.props.searchBlocks(event.target.value);
    } else {
      this.initialLoad();
    }
  };

  /* custom delete modal */
  customDeleteContextMenu = () => {
    /** Option to delete all blocks. */
    Blockly.ContextMenuItems.registerDeleteAll1 = new Function();
    Blockly.ContextMenuItems.registerDeleteAll1 = function () {
      /** @type {!Blockly.ContextMenuRegistry.RegistryItem} */
      var deleteOption = {
        displayText: function (
          /** @type {!Blockly.ContextMenuRegistry.Scope} */ scope
        ) {
          if (!scope.workspace) {
            return;
          }
          var deletableBlocksLength =
            Blockly.ContextMenuItems.getDeletableBlocks_(
              scope.workspace
            ).length;
          if (deletableBlocksLength <= 1) {
            return Blockly.Msg['DELETE_BLOCK'];
          } else {
            return Blockly.Msg['DELETE_X_BLOCKS'].replace(
              '%1',
              String(deletableBlocksLength)
            );
          }
        },
        preconditionFn: function (
          /** @type {!Blockly.ContextMenuRegistry.Scope} */ scope
        ) {
          if (!scope.workspace) {
            return;
          }
          var deletableBlocksLength =
            Blockly.ContextMenuItems.getDeletableBlocks_(
              scope.workspace
            ).length;
          return deletableBlocksLength > 0 ? 'enabled' : 'disabled';
        },
        callback: function (
          /** @type {!Blockly.ContextMenuRegistry.Scope} */ scope
        ) {
          if (!scope.workspace) {
            return;
          }
          scope.workspace.cancelCurrentGesture();
          var deletableBlocks = Blockly.ContextMenuItems.getDeletableBlocks_(
            scope.workspace
          );
          var eventGroup = Blockly.utils.genUid();
          if (deletableBlocks.length < 2) {
            Blockly.ContextMenuItems.deleteNext_(deletableBlocks, eventGroup);
          } else {
            Blockly.confirm(
              Blockly.Msg['DELETE_ALL_BLOCKS'].replace(
                '%1',
                deletableBlocks.length
              ),
              function (ok) {
                if (ok) {
                  Blockly.ContextMenuItems.deleteNext_(
                    deletableBlocks,
                    eventGroup
                  );
                }
              }
            );
          }
        },
        scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
        id: 'workspaceDelete',
        weight: 0,
      };
      Blockly.ContextMenuRegistry.registry.register(deleteOption);
    };
  };

  /* custom function to check if block is droped without parent */
  _isOrphanBlock(event, block) {
    let isOrphan = false;
    if (
      !block.getParent() &&
      !event.oldParentId &&
      block.type !== 'event_start'
    ) {
      isOrphan = true;
    }
    return isOrphan;
  }
  /* update api call */
  changeEvent = (event)=>{
    if (event.type === Blockly.Events.BLOCK_MOVE) {
      let block = this.primaryWorkspace.getBlockById(event.blockId);
      if (block && this._isOrphanBlock(event, block)) {
        Blockly.Events.recordUndo = false;
        this.primaryWorkspace.undo(false);
        Blockly.Events.recordUndo = true;
      }else if(block && block.create){
        Blockly.Events.recordUndo = false;
        block.setCollapsed(false);
        block.create = false;
        Blockly.Events.recordUndo = true;
      }
    }

    if (event.type === 'create') {
      //making the collapsed left side block to non-collapsed on drag
      let block = this.primaryWorkspace.getBlockById(event.blockId);
      if(block && typeof block === "object") {
        block.create = true;
      }
      // block && block.setCollapsed(false);
    }
  }
  updateData = (event) => {
     
    /*
     *    https://appsfreedom.atlassian.net/wiki/spaces/EngDesign/pages/2785247403/TDD+-+BackLog-May10+-+AB
     *    T2081163-B, Ideally any other blocks that are dragged and dropped should be inside the default event block.
     *    The JS builder does not validate where the blocks are dragged and drop.
     *    Allow user to add blocks only inside the event block.
     */
    if (event.type !== 'ui' && event.type !== 'finished_loading' && !(event.type=="var_create" && !event.recordUndo)) {
      const blockCode = Blockly.JavaScript.workspaceToCode(
        this.primaryWorkspace
      );
      let xmlDom = Blockly.Xml.workspaceToDom(this.primaryWorkspace);
      xmlDom = xmlDom.getElementsByTagName("block")?.[0]?.setAttribute("componentType", selectedComponentType) || xmlDom;
      xmlDom = xmlDom.getElementsByTagName("block")?.[0]?.setAttribute("componentId", selectedCompId) || xmlDom;
      xmlDom = xmlDom.getElementsByTagName("block")?.[0]?.setAttribute("gComp",gCompType ) || xmlDom;

      const xmlString = Blockly.Xml.domToPrettyText(xmlDom);
      const variablesUsed = this.primaryWorkspace.getAllVariableNames();
      const updatedBlocks = {
        block: xmlString,
        code: blockCode,
        variablesUsed: variablesUsed,
      };
      var componentName = undefined;
      if (
        this.props.openBehavior &&
        !(
          this.props.selectedComponent &&
          this.props.selectedComponent.propertyValue
        )
      ) {
        componentName = this.props.behaviourData?.behaviourName;
      }
      this.props.updateJsComponent(updatedBlocks, false, componentName)
    }
    // console.log('event:', this.primaryWorkspace.getBlockById(event.blockId)
    let canUndo = this.primaryWorkspace.undoStack_.length;
    let canRedo = this.primaryWorkspace.redoStack_.length;
    let undoDoc = document.getElementsByClassName('blocklyUndo')[0];
    let redoDoc = document.getElementsByClassName('blocklyRedo')[0];
    undoDoc.style.pointerEvents = canUndo ? "auto" : "none";
    redoDoc.style.pointerEvents = canRedo ? "auto" : "none";
    undoDoc.disabled = !canUndo;
    redoDoc.disabled = !canRedo;
  };

  scrollLeft = () => {
    this.primaryWorkspace.scrollCenter = new Function();
    this.primaryWorkspace.scrollCenter = function () {
      if (!this.isMovable()) {
        console.warn(
          'Tried to move a non-movable workspace. This could result' +
            ' in blocks becoming inaccessible.'
        );
        return;
      }

      var metrics = this.getMetrics();
      var x = (metrics.contentWidth - metrics.viewWidth) / 1;
      var y = (metrics.contentHeight - metrics.viewHeight) / 1;

      // Convert from workspace directions to canvas directions.
      x = -x - metrics.contentLeft;
      y = -y - metrics.contentTop;
      this.scroll(x, y);
      // this.scroll(-metrics.contentLeft, -metrics.contentTop);
    };
  };

  /* undo-redo listeners */
  undoRedoAction = () => {
    const workSpace = this.primaryWorkspace;
    workSpace.MAX_UNDO = 20;
    const undoButton = document.getElementsByClassName('blocklyUndo')[0];
    undoButton.style.pointerEvents = "none";
    undoButton.disabled = true;
    undoButton.addEventListener(
      'click',
      function (event) {
        workSpace.undo(false);
      },
      false
    );

    const redoButton = document.getElementsByClassName('blocklyRedo')[0];
    redoButton.style.pointerEvents = "none";
    redoButton.disabled = true;
    redoButton.addEventListener(
      'click',
      function (event) {
        workSpace.undo(true);
      },
      false
    );
  };

  cleanUp() {
    document
      .getElementsByClassName('blocklyBlockCanvas')[0]
      .setAttribute('transform', 'translate(180,0) scale(0.7)');
  }

  get workspace() {
    return this.primaryWorkspace;
  }

  recentreBlocks() {
    /*this.primaryWorkspace.markFocused();
        this.primaryWorkspace.setScale(this.primaryWorkspace.options.zoomOptions.startScale);
        this.primaryWorkspace.beginCanvasTransition();
        this.primaryWorkspace.scrollCenter();
        setTimeout(this.primaryWorkspace.endCanvasTransition.bind(this.primaryWorkspace), 500);
        */
  }

  setXml(xml) {
    Blockly.Xml.domToWorkspace(
      Blockly.Xml.textToDom(xml),
      this.primaryWorkspace
    );
  }

  /* left panel toolbox */
  loadLeftPanelBlocksData = () => {
    return this.props?.leftPanel?.map((item, i) => {
      return (
        <button
          key={i}
          text={item}
          callbackKey={`${item.charAt(0).toLowerCase() + item.slice(1)}Button`}
          category={item}
          xml={`${item.toLowerCase()}blocks.xml`}
          web-class='buttonStyle'
        ></button>
      );
    });
  };

  _getPermisionBasedStyle = () => {
    let style = {};
    if (this.props.readOnly === true) {
      style['paddingLeft'] = '312px';
    }
    return style;
  };

  render() {
    return (
      <section id='blocklyContainer'>
        <div
          ref={this.blocklyDiv}
          id='blocklyDiv'
          className={
            this.props.disableToolbox
              ? 'disableToolbox'
              : styles.blocklyFragment
          }
          // style={this._getPermisionBasedStyle()}
        />
        <xml
          xmlns='http://www.w3.org/1999/xhtml'
          style={{ display: 'none' }}
          id='toolbox'
          ref={this.toolbox}
        ></xml>
        <xml
          xmlns='http://www.w3.org/1999/xhtml'
          style={{ display: 'none' }}
          id='refernceToolbox'
        >
          <button
            text='Logic'
            callbackKey='logicButton'
            category='Logic'
            xml='logicblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Loops'
            callbackkey='loopsButton'
            category='Loops'
            xml='loopsblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Math'
            callbackkey='mathButton'
            category='Math'
            xml='mathblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Text'
            callbackkey='textButton'
            category='Text'
            xml='textblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Elements'
            callbackkey='elementsButton'
            category='Elements'
            xml='elementsblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Functions'
            callbackkey='functionsButton'
            category='Functions'
            xml='functionsblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Variables'
            callbackkey='variablesButton'
            category='Variables'
            xml='variablesblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='List'
            callbackkey='listButton'
            category='List'
            xml='listblocks.xml'
            web-class='liststyle buttonStyle'
          ></button>
          <button
            text='Events'
            callbackkey='eventsButton'
            category='Events'
            xml='eventblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Storage'
            callbackkey='storageButton'
            category='Storage'
            xml='storageblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Advance'
            callbackkey='advanceButton'
            category='Advance'
            xml='advanceblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Utilities'
            callbackkey='utilitiesButton'
            category='Utilities'
            xml='utilitiesblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Date'
            callbackkey='dateButton'
            category='Date'
            xml='dateblocks.xml'
            web-class='buttonStyle'
          ></button>
          <button
            text='Bluetooth'
            callbackkey='bluetoothButton'
            category='Bluetooth'
            xml='bluetooth.xml'
            web-class='buttonStyle'
          ></button>
          <block type='text_getSubstring' web-class='hiddenBlock'>
            <mutation at1='true' at2='true'></mutation>
            <field name='WHERE1'>FROM_START</field>
            <field name='WHERE2'>FROM_START</field>
            <value name='STRING'>
              <block type='variables_get'>
                <field name='VAR' id='i.JV=qU2w`Z_DQtv]P3!'>
                  text
                </field>
              </block>
            </value>
          </block>
        </xml>
        {/* <Box component="div" className='blocklySearchInput'>
                    <SearchInputField
                        propContainer={{
                            startAdornment: false,
                            endAdornment: true,
                        }}
                        type='IBSearchIcon'
                        changeValue={this.handleSearchValue}
                    />
                </Box> */}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    componentData: state.jsBuilder.componentData,
    graphJson: state.businessFunction.graphJson,
    xmlData: state.jsBuilder.xmlData,
    pageDetail:
      state.jsBuilder.pageDetail?.uuid || state.jsBuilder.pageDetail.detail?.id,
    taskVariables: state.userTaskDetails.taskVariables,
    leftPanel: state.jsBuilder.leftPanel,
    pageName:
      state.jsBuilder.pageDetail?.name ||
      state.jsBuilder.pageDetail.detail?.name ||
      '',
    bfDetails: state.businessFunction.details,
  };
};

BlocklyComponent = connect(mapStateToProps)(BlocklyComponent);

export default BlocklyComponent;
