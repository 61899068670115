import store from '../stores';
import * as actions from '../actions/user-profile';
import DataService from '../utils/data-service';
import { getCookie } from '../utils/cookieServices';
import { fileToBlob, s3Url } from '../utils/common';
import { showNotification } from '../actions/app-root';
import { pageDetail } from '../actions/jsbuilder';
import { BUSINESS_FUNCTION_API, PROJECT_API } from '../constants';
import { COMPONENT_NAMES } from '../containers/app-designer/utils/configuration/componentName.contants';
import { isEnabled } from '../containers/app-designer/utils/common';
var _ = require('lodash');

const KEY_SELFVAL = 'selfVal';


export const alertShow = (data) => {
  let param = {
    message: data.message,
    show: true,
    type: data.type,
  };
  store.dispatch(showNotification(param));
};

const errorData = (res) => {
  let errorData = {
    type: 'error',
    message: res.message,
  };

  alertShow(errorData);
};

const path = `${PROJECT_API}/`;
const pagePath = `${BUSINESS_FUNCTION_API}/page`;

export async function fetchImages(projectName) {
  let images = [];
  const params = {
    uri: `${path}${projectName}/listComponent?$select=id,name,object&$filter=type EQ 'IMG'`,
  };

  await DataService.read(params).then((result) => {
    if (result.status === 200) {
      images = result.data.data;
    }
  });
  return images;
}

export async function getImage(projectName, imageName) {
  let images = [];
  if (projectName) {
    const params = {
      uri: `${path}${projectName}/listComponent?$select=id,name,object&$filter=type EQ 'IMG' and name EQ '${imageName}'`,
    };

    await DataService.read(params).then((result) => {
      if (result.status === 200) {
        images = result.data.data;
      }
    });
  }
  return images;
}

export async function uploadImage({
  file,
  fileType,
  projectName,
  imageName,
  callback,
}) {
  const params = {
    uri: `${path}${projectName}/image/${imageName}?Content_Type=${fileType}`,
    data: file,
  };

  await DataService.update(params)
    .then((result) => {
      if (result.status === 200) {
        result.data.status.type = 'success';
        callback();
        alertShow(result.data.status);
      }
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function createPage(data, success) {
  let response;
  const params = {
    uri: `${pagePath}`,
    data: data,
  };
  await DataService.create(params)
    .then((result) => {
      // console.warn('inside create page response', result);
      if (result.status === 200) {
        store.dispatch(
          pageDetail({ uuid: result.data?.uuid, name: data?.name || '' })
        );
        success();
      }
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function updatePage({
  data,
  pageName,
  functionName,
  pageFlowName,
}) {
  const params = {
    uri: `${pagePath}/${pageName}?function_name=${functionName}`,
    data: data,
  };
  await DataService.update(params)
    .then((result) => {
      if (result.status === 200) {
        //
      }
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function getPage({
  projectName,
  pageName,
  functionName,
  pageFlowName,
  isMenu = false
}) {
  let response;
  const params = {
    uri: `${pagePath}/${projectName}/${pageName}?function_name=${functionName}`,
  };
  let resultPromise = new Promise((resolve, reject) => {
    // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
    // In this example, we use setTimeout(...) to simulate async code.
    // In reality, you will probably be using something like XHR or an HTML5 API.
    DataService.read(params)
    .then((result) => {
      // console.warn('getpage results', result);
      if (result.status === 200) {
        if (
          result.data.status.type === 'S' &&
          Object.keys(result.data.detail).length
        ) {
          if(!isMenu){
            store.dispatch(pageDetail(result.data));
          }
          response = {};
          response.detail = result.data.detail;
          response.ui = s3Url(result.data.detail.uiObject);
          response.data = s3Url(result.data.detail.dataObject);
          resolve(response);
        }else{
          reject(result);
        }
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  })
  
  return resultPromise;
}

export async function getPageList({ projectName, functionName }) {
  let response = [];
  let filter = '$select=id,name,dataObject,uid';
  const params = {
    uri: `${pagePath}/${functionName}/list?project_name=${projectName}&${filter}`,
    data: '',
  };
  await DataService.read(params)
    .then((result) => {
      // console.warn('getpage results', result);
      if (result.status === 200) {
        if (
          result.data.status.type === 'S' &&
          Object.keys(result.data).length
        ) {
          //store.dispatch(pageDetail(result.data));
          //response.detail = result.data.detail;
          //response.ui = s3Url(result.data.detail.uiObject);
          response = result.data.data;
        }
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  return response;
}

export async function deletePage(obj) {
  let response;
  const { projectName, businessFunctionName, userTaskName, pageName } = obj;

  let params = {
    uri: `${pagePath}/${projectName}/${pageName}?function_name=${businessFunctionName}`,
    data: '',
  };
  await DataService.delete(params).then(
    (res) => {
      if (res.status === 200) {
        if (res.data.status.type === 'E' || res.data.status.type === 'error') {
          res.data.status.type = 'error';
          alertShow(res.data.status);
          response = false;
        } else if (res.data.status.type === 'S') {
          res.data.status.type = 'success';
          response = true;
        }
      }
    },
    (error) => {
      if (error.response) {
        errorData(error.response.data);
        return false;
      }
    }
  );
  return response;
}

export const getVarType = (varName, varList) => {
  let varType = undefined;
  const [table, field] = varName.split('.');
  if (varList) {
    const tableInfo = varList.find((vr) => {
      return vr.name === table;
    });

    if (tableInfo) {
      if (field) {
        if (field.startsWith('$')) {
          varType = 'keyword';
        } else {
          const fieldInfo = tableInfo.json.find((j) => {
            return j.key === field;
          });
          if (fieldInfo) {
            varType = !fieldInfo.value
              ? typeof fieldInfo.value
              : fieldInfo.value;
          }
        }
      } else {
        varType = tableInfo.type;
      }
    }
  }
  return varType;
};

export function updateComponentHelper(reactComponent, key, value,cb) {
  let data = {},
    that = reactComponent;
  if (['currentIndex', 'rowsPerPage'].indexOf(key) > -1) { // Pagination property, specifically for Datagrid, etc
    data = {
      ...that.state.selectedComponent,
      propertyValue: {
        ...that.state.selectedComponent.propertyValue,
        pagination: {
          ...(that.state.selectedComponent.propertyValue?.pagination || {}),
          [key]: value,
        },
      },
    };
  } else if (['placeholder', 'classes', 'status','enabled','show', 'autoclose','progressStatus','progressPercentage','percentageValue'].indexOf(key) > -1) { // Component label property
    let component={
      ...that.state.selectedComponent.propertyValue.component,
      [key]: value,
    }
    let pv={
      ...that.state.selectedComponent.propertyValue,
      component:{...component},
    };
    if(key==='enabled' && that.state.selectedComponent.type!=COMPONENT_NAMES.PANEL.toUpperCase()){
      let style={...pv.style,opacity:1};   
      pv={...pv,style};
    }
    data = {
      ...that.state.selectedComponent,
      propertyValue: {...pv},
    };
  } else if (key.startsWith('datasource_')) { // Datasource label property
    const [propertyType, sourcePart] = key.split('_');
    if (sourcePart) {
      let dataSource = _.cloneDeep(that.state.selectedComponent.propertyValue.dataSource)||[];
      const source = JSON.parse(sourcePart);

      const src = source.src || '';
      let label = source.label || '';
      const index = source.index || 0;

      if (src && label) {
        if (label === src) {
          label = KEY_SELFVAL;
        }
        if(!dataSource[index]){
          dataSource[index]={}
        }
        if (!dataSource[index][src]) {
          dataSource[index][src] = {};
        }

        dataSource[index][src][label] = value;

        data = {
          ...that.state.selectedComponent,
          propertyValue: {
            ...that.state.selectedComponent.propertyValue,
            dataSource: _.cloneDeep(dataSource),
          },
        };
      }
      cb(dataSource);
    }
  } else { // Top label property
    
    data = {
      ...that.state.selectedComponent,
      [key]: value,
    };
  }
  if (data) {
    that._onSelect(data, true);
  }
}

export function getMaxIDByType(idocument, compType) {
  let uniquekey = 1;
  const similarElements = idocument.querySelectorAll(
    `[compid^="${compType.toLowerCase()}_"]`
  );
  let numbers = [];
  similarElements.forEach((e) => {
    let idArr = e.getAttribute('compid').split('_');
    if (idArr.length > 1) {
      let suffixNumber = idArr[idArr.length - 1];
      if (suffixNumber) {
        suffixNumber = parseInt(suffixNumber);
      }
      if (suffixNumber && !isNaN(suffixNumber)) {
        numbers.push(suffixNumber);
      }
    }
  });
  if (numbers.length > 0) {
    uniquekey = Math.max(...numbers) + 1;
  }
  return uniquekey;
}
