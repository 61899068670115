export function removeValue(array,item){
    var index = array.indexOf(item);
    if (index !== -1) {
        array.splice(index, 1);
    }
    return array;
}

export function removeValues(array,items){
    items.map((item)=>{
        var index = array.indexOf(item);
        if (index !== -1) {
            array.splice(index, 1);
        }
    });
    return array;
}
export function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export function getPermissions(name) {
    let permissions = JSON.parse(sessionStorage.getItem("__er__p__userPermission"));
    permissions = permissions.filter(e => e.name === name);
    return permissions.length>0?permissions[0]:{};

}