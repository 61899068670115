import React, { useEffect, useState } from 'react'
import './newUserDialog.css'
import { validateValue, validateValues, validateSystem, validateSystemObj } from './newUserValidation';
import { useForm } from '../../../../components/forms/Form';
import { getCurrencyList, getDateFormatsList, getLanguageList, getTimezoneList } from '../../../../api/administration/organisation/organisation';
import { getUserPropertyList } from '../../../../api/administration/usermanagement/userproperty/userproperty';
import { IntegrationActions } from '../../integrations/Integration.Actions';
import { useAppContext } from '../../../../components/activity/AppContext';
import { createUser, updateUser } from '../../../../api/administration/usermanagement/userlist';
import Popup from '../../../../components/layouts/PopUp'
import UserActions from './users.actions';
import { getCookie } from '../../../../common/utils/cookieServices';
import { useUserContext } from '../../../../auth/auth';
import System from './systems';
import { generateUuid } from '../../../../common';
import DeleteIcon from "../../../../images/delete.png";
import { useLocation } from 'react-router-dom';
import SmallPopup from '../../../../components/layouts/smallPopup';

function NewUserDialog(props) {
    const { setUserModal, userData, createuser, userModal, testId, assignedRole, updateUserList } = props;
    const watch = ["firstName", "lastName", "loginId", "email", "languageCode", "salutation", "dateFormat", "title", "timeZone", "currency", "timeFormat"];
    const [showSystemsFields, setShowSystemsFields] = useState(false);
    // const errors = useErrors();
    const widthStyle = { width: "100%" }
    const inputCheckBoxStyle = { display: "flex", height: "16px", width: "16px", marginLeft: "30px" }
    const [fields, setFields] = useState({});
    const [systemFields, setSystemFields] = useState([]);
    const loginId = "Username/Password";
    const [language, setLanguage] = useState([])
    const [currency, setCurrency] = useState([])
    const [timeZones, setTimeZones] = useState([])
    const [dateFormat, setDateFormat] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [systemList, setSystemList] = useState();
    const [updateUserData, setUpdateUserData] = useState(userData)
    const [userDetails] = useUserContext();
    const [userDataItem, setUserDataItem] = useState({
        firstName: '',
        lastName: '',
        loginId: '',
        email: '',
        language: '',
        salutation: '',
        dateFormat: '',
        title: '',
        timeZone: '',
        currency: '',
        isActive: true,
        isLocked: false,
    })
    const [userUnlock, setUserUnlock] = useState(false);
    const [resetPswd, setResetPswd] = useState(false);
    const [userDelete, setUserDelete] = useState(false)

    const userUnlockClick = () => {
        setUserUnlock(true)
    }

    const userDeleteClick = () => {
        setUserDelete(true)
    }

    const resetPswdClick = () => {
        setResetPswd(true)
    }

    const close = () => {
        setUserUnlock(false)
        setResetPswd(false)
        setUserModal(false)
        setUserDelete(false)
        updateUserList()
    }

    const location = useLocation();
    const app = useAppContext();
    const getAdapterList = async () => {
        const actions = IntegrationActions(app());
        const [error, data] = await actions.loadAdapters();
        setSystemList(data.data)
    };

    useEffect(() => {
        getAdapterList();
        getFields(userData?.systems || {});
        getUserPropertyList().then(result => {
            let data = result;
            data = data?.map((item) => {
                item.value = item?.value?.slice(2, -2)?.split('"');
                return item;
            });
            setPropertyList(data);
        })
    }, [])

    const getFields = (system) => {
        let sysFlds = [];
        let flds = {}
        // console.log(system)
        if (system) {
            Object.values(system).map((obj) => {
                let uuid = generateUuid();
                sysFlds.push(uuid)
                flds[uuid] = {}
                flds[uuid]["connectionId_" + uuid] = obj.connectionId
                flds[uuid]["ssUserId_" + uuid] = obj.ssUserId
            })
            // console.log(sysFlds,flds)
            setFields(flds)
            setSystemFields(sysFlds)
        }
    }

    const getDependencies = () => {
        const userValues = UserActions(app());

        const getCurrencyList = async () => {
            let [err, data] = await userValues.GetCurrencyList();
            if (!err && !data.error) {
                setCurrency(data.data)
            }
        }

        const getDateFormatsList = async () => {
            let [err, data] = await userValues.GetDateFormatsList();
            if (!err && !data.error) {
                setDateFormat(data.data)
            }
        }

        const getTimezoneList = async () => {
            let [err, data] = await userValues.GetTimezoneList();
            if (!err && !data.error) {
                setTimeZones(data.data)
            }
        }

        const GetLanguageList = async () => {
            let [err, data] = await userValues.GetLanguageList();
            if (!err && !data.error) {
                setLanguage(data.data)
            }
        }

        getCurrencyList()
        getDateFormatsList()
        getTimezoneList()
        GetLanguageList()
    }

    useEffect(getDependencies, []);


    const onWatch = (errors, data) => {
        if (data.name.startsWith("connectionId") || data.name.startsWith("ssUserId")) {
            validateSystemObj(errors, data.name, data.value)
            handleUpdate(data.name, data.value)
        } else {
            validateValue(errors, data.name, data.value)
            setUpdateUserData((prev) => ({ ...prev, [data.name]: data.value }));
        }
    }

    const resolveFields = () => {
        let flds = [];
        systemFields.map((uuid) => {
            flds.push({ "connectionId": fields[uuid]["connectionId_" + uuid], "ssUserId": fields[uuid]["ssUserId_" + uuid] })
        })
        // console.log(flds)
        return flds
    }

    const onSubmit = async (values, errors) => {
        const rolesName = assignedRole === "" ? '' : assignedRole;
        // console.log(values,fields,systemFields)
        if (!validateValues(values, errors)) {
            validateSystem(fields, errors, systemFields)
            return false;
        }
        if (validateSystem(fields, errors, systemFields))
            return false;
        const userValues = UserActions(app());
        // console.log(values)
        let userProperties = {};
        propertyList.map((item) => {
            userProperties[item.propertyUuid] = values[item.propertyUuid] || userData?.properties[item.propertyUuid]
        })
        values["properties"] = userProperties;
        values["systems"] = resolveFields();
        // console.log(values)
        if (userUnlock) {
            const [err, data] = await userValues.UnlockUser(props.userData.loginName)
            close()
        } else if (userDelete) {
            setUserDelete(true)
            // const [err, data] = await userValues.DeleteUser(props.userData.loginName)
            // close()
        } else if (resetPswd) {
            // const userDataDetails = {};
            // userDataDetails.tenantId = userDetails.auth.tenantId
            // userDataDetails.userName = userData?.loginName;
            let [err, data] = await userValues.ResetPassword({ tenantId: userDetails.auth.tenantId, userName: userData?.loginName })
            if (!err && !data.error) {
                // console.log(data)
            }
            close()
        } else if (createuser === "updateUser") {
            let [err, data] = await userValues.UpdateUserValues(props.userData.loginName, values)
            if (!err && !data.error) {
                // console.log(data)
            }
            close()
        } else {
            let [err, data] = await userValues.CreateUserValues({ user: values }, rolesName)
            if (!err && !data.error) {
                // console.log(data)
            }
            close()
        }
        return true;
    };

    const { Form, Label, Input, Error, Select, } = useForm(userDataItem, onSubmit, onWatch);
    useEffect(() => {
        if (userData === undefined) {
            setUserDataItem({
                firstName: "",
                lastName: "",
                loginName: "",
                email: "",
                languageCode: "",
                salutation: "",
                dateFormat: "",
                title: "",
                timeZone: "",
                currency: "",
                isActive: true,
                isLocked: false,
            })
        } else {
            setUserDataItem({
                firstName: userData.firstName,
                lastName: userData.lastName,
                loginName: userData.loginName,
                email: userData.email,
                languageCode: userData.languageCode,
                salutation: userData.salutation,
                dateFormat: userData.dateFormat,
                title: userData.title,
                timeZone: userData.timeZone,
                currency: userData.currency,
                isActive: userData.isActive,
                isLocked: userData.isLocked,
            })
        }
    }, [userData])

    const titles = [
        { name: 'Mr', code: 'Mr' },
        { name: 'Mrs', code: 'Mrs' },
        { name: 'Ms', code: 'Ms' },
        { name: 'Dr', code: 'Dr' },
        { name: 'Prof', code: 'Prof' },
        { name: 'Rev', code: 'Rev' },
        { name: 'Other', code: 'Other' },
    ]

    const handleAdd = () => {
        let uuid = generateUuid()
        // console.log(fields)
        let obj = {}
        obj['connectionId_' + uuid] = 0
        obj['ssUserId_' + uuid] = ""
        let temp = { ...fields }
        temp[uuid] = obj
        const list = [...systemFields];
        list.push(uuid)
        setSystemFields(list)
        setFields(temp);
    }

    const handleDelete = (index) => {
        let list = [...systemFields];
        // console.log(index,list)
        list = list.filter(e => e !== index);
        // console.log(list)
        let flds = { ...fields, [index]: null }
        // console.log(flds)
        setFields(flds)
        setSystemFields(list);
    }

    const handleUpdate = (name, value) => {
        let uuid = ""
        if (name.startsWith("connectionId"))
            uuid = name.substring(13)
        else if (name.startsWith('ssUserId'))
            uuid = name.substring(9)
        let flds = { ...fields }
        let sysObj = { ...fields[uuid] }
        sysObj[name] = value
        flds[uuid] = sysObj
        setFields(flds)
    }

    const handleDeleteUser = async (loginID) => {
        const userValues = UserActions(app());
        const [err, data] = await userValues.DeleteUser(loginID)
        close()
    }

    return (
        <Popup setPopUp={setUserModal} popUp={userModal} testId={testId === "update-user-dialog" ? "update-user-dialog" : "create-user-dialog"}>
            <div className="mainWrapper">
                <div className="wrapper">
                    <div>
                        <div></div>
                        <h2 className='user-heading'>
                            {createuser && createuser === "createUser" ? "Create User" : "Edit User"}
                        </h2>
                    </div>
                    <Form
                    >
                        <div className='user-form-wrapper'>
                            <div className='use-form-container'>
                                <div className='field-holder'>
                                    <div className='form-field'>
                                        <Label text="First name" htmlFor="firstname" />
                                        <Input
                                            // defaultValue={userData ? userData.firstName : ""}
                                            // value={updateUserData?.firstName}
                                            type='text'
                                            className='input-align'
                                            id="firstName"
                                            name={"firstName"} autoComplete="off"

                                        />
                                        <Error name={'firstName'} />
                                    </div>
                                    <div className='form-field'>
                                        <Label text="Last Name" htmlFor="lastName" />
                                        <Input
                                            type='text'
                                            id="lastName"
                                            name="lastName" autoComplete="off"
                                            className='input-align'
                                        />
                                        <Error name={'lastName'} />
                                    </div>
                                </div>
                                <div className='field-holder'>
                                    <div className='form-field'>
                                        <Label text="Salutation" htmlFor="salutation" />
                                        <div>
                                            <Select
                                                style={widthStyle}
                                                className='select-align'
                                                name="salutation"
                                                id="salutation"
                                            >  <option value='' disabled>
                                                    Salutation
                                                </option>
                                                {titles.map((title, index) => {
                                                    return (
                                                        <option key={`title-${index}`} value={title.name}>
                                                            {title.name}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                            <Error name={'salutation'} />
                                        </div>
                                    </div>
                                    <div className='form-field'>
                                        <Label text="Email" htmlFor="email" />
                                        <Input
                                            type='text'
                                            id="email"
                                            name="email" autoComplete="off"
                                            className='input-align'
                                        />
                                        <Error name={'email'} />
                                    </div>
                                </div>
                                <div className='field-holder'>
                                    <label className='form-label'>
                                        Title</label>
                                    <div className='user-input-title'>
                                        <Input
                                            type='text'
                                            id="title"
                                            name="title" autoComplete="off"
                                            className='input-align'
                                        />
                                        <Error name={'title'} />
                                    </div>
                                </div>
                                <div className='field-holder'>
                                    <div className='form-field'>
                                        <label className='form-label'>Login type</label>
                                        <div>
                                            <Select
                                                style={widthStyle}
                                                className='select-align'
                                                data-option-testid='loginType'
                                            // value={loginId}
                                            >
                                                <option>
                                                    Username/Password
                                                </option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='form-field'>
                                        <Label text="Login Id" htmlFor="loginId" />
                                        <Input
                                            id="loginName"
                                            name="loginName"
                                            type='text'
                                            className='input-align'
                                            disabled={props.createuser === "createUser" ? false : true}
                                        />
                                        <Error name={'loginName'} />
                                    </div>
                                </div>
                                <div className='checkbox-field'>
                                    <Label text="Is Active" htmlFor="isActive" />
                                    <Input
                                        id="isActive"
                                        name="isActive"
                                        type='checkbox'
                                        style={inputCheckBoxStyle}
                                        className='input-align'
                                    />
                                    <Error name={'isActive'} />
                                </div>                                <div className='field-holder'>
                                    <div className='form-field'>
                                        <Label text="Language" htmlFor="language" />
                                        <div>
                                            <Select
                                                style={widthStyle}
                                                className='select-align'
                                                id="languageCode"
                                                name="languageCode"
                                            >
                                                <option value='' disabled>
                                                    Language
                                                </option>
                                                {language.map((language, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={language.code}
                                                            selected={userData && userData.languageCode === language.code ? true : false}
                                                        >
                                                            {language.name}
                                                        </option>
                                                    )
                                                }
                                                )}
                                            </Select>
                                        </div>
                                        <Error name={'languageCode'} />
                                    </div>
                                    <div className='form-field'>
                                        <Label text="Currency" htmlFor="currency" />
                                        <div>
                                            <Select
                                                style={{ width: "184px" }}
                                                id="currency"
                                                name="currency"
                                                className='select-align'
                                            >
                                                <option value='' disabled>
                                                    Currency
                                                </option>
                                                {currency.map((currency, index) => {
                                                    return (
                                                        <option
                                                            key={`title-${index}`}
                                                            value={currency.code}
                                                            selected={userData && userData.currency === currency.code ? true : false}
                                                        >
                                                            {currency.name}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                            <Error name={'currency'} />
                                            {/* <Error message={errors.message('currency')} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='field-holder'>
                                    <div className='form-field'>
                                        <Label text="Date Format" htmlFor="dateFormat" />
                                        <div>
                                            <Select
                                                style={widthStyle}
                                                className='select-align'
                                                id="dateFormat"
                                                name="dateFormat"
                                            >
                                                <option value='' disabled>
                                                    Date Format
                                                </option>
                                                {dateFormat.map((date, index) => {
                                                    return (
                                                        <option
                                                            key={`title-${index}`}
                                                            value={date.code}
                                                            selected={userData && userData.dateFormat === date.code ? true : false}
                                                        >
                                                            {date.name}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                            <Error name={'dateFormat'} />
                                            {/* <Error message={errors.message('dateFormat')} /> */}
                                        </div>
                                    </div>
                                    <div className='form-field'>
                                        <Label text="Timezone" htmlFor="timeZone" />
                                        <div style={{ width: "184px" }}>
                                            <Select
                                                className='select-align-timezone'
                                                id="timeZone"
                                                name="timeZone"
                                            >
                                                <option value='' disabled>
                                                    Timezone
                                                </option>
                                                {timeZones.map((timeZone, index) => {
                                                    return (
                                                        <option
                                                            key={`title-${index}`}
                                                            value={timeZone.code}
                                                            selected={userData && userData.timeZone === timeZone.code ? true : false}
                                                        >
                                                            {timeZone.name}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                            <Error name={'timeZone'} />
                                        </div>
                                    </div>
                                </div>
                                <div className='field-holder'>
                                    <Label text="Properties" htmlFor="Properties" />
                                    {propertyList?.map((item, index) => {
                                        return (
                                            <div className='form-field-varied'>
                                                <label className='form-label-property'>{item.name}</label>
                                                <div className='form-select-varied'>
                                                    <Select
                                                        className='select-align'
                                                        name={item.propertyUuid}
                                                        id={item.propertyUuid}
                                                        data-option-testid={item.name}
                                                    // value={updateUserData?.[item.propertyUuid]}
                                                    >
                                                        <option value=''> User Property </option>
                                                        {item.value.join(',').split(',').map((val) => {
                                                            if (val) {
                                                                return (
                                                                    <option
                                                                        selected={userData && userData.properties && userData.properties[item.propertyUuid] === val ? true : false}
                                                                        key={index}
                                                                        value={val}
                                                                    >
                                                                        {val}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return []
                                                            }
                                                        })
                                                        }
                                                    </Select>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='hide-show-field-holder'>
                                    <label className='form-label'>Systems</label>
                                </div>
                                <>
                                    <div className='selectInputFieldsContainer'>
                                        {/* <button className="userm-button" data-clickable-testid="new-system" onClick={handleAdd}> + New System </button> */}
                                        <div> {systemFields?.map((uuid, index) => {
                                            return (
                                                // <System  item={fields[uuid]} index={uuid} systemList={systemList} handleDelete = {handleDelete} handleUpdate={handleUpdate} uuid={uuid}/>

                                                <div className='form-field-varied'>
                                                    <div>
                                                        <Select
                                                            style={{ width: "100px" }}
                                                            placeholder='Select'
                                                            defaultValue={fields[uuid].connectionId}
                                                            data-option-testid="system-select"
                                                            id="connectionId"
                                                            name={"connectionId_" + uuid}>
                                                            <option value='' disabled>Systems</option>
                                                            {systemList?.map((system, index) => {
                                                                return (
                                                                    <option
                                                                        key={index}
                                                                        value={system.id}
                                                                        selected={fields[uuid]["connectionId_" + uuid] === system.id ? true : false}
                                                                        id={system.id}
                                                                    >
                                                                        {system.name}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Select>
                                                        <Error name={"connectionId_" + uuid} />
                                                    </div>
                                                    <div className='form-select-varied-hide'>
                                                        <Input
                                                            className='input-align'
                                                            placeholder="Enter text"
                                                            name={"ssUserId_" + uuid}
                                                            id="ssUserId"
                                                            data-input-testid="system-input"
                                                            value={fields[uuid]["ssUserId_" + uuid]}
                                                        />
                                                        <Error name={"ssUserId_" + uuid} />
                                                    </div>
                                                    <div style={{ height: "100%", cursor: "pointer", paddingBottom: "16px" }}>
                                                        <img
                                                            data-clickable-testid="system-delete"
                                                            src={DeleteIcon}
                                                            alt="Delete System"
                                                            onClick={() => handleDelete(uuid)} />
                                                    </div>
                                                </div>


                                            )
                                        })}
                                        </div>
                                        <Input data-clickable-testid="new-system" type="button" className="secondary" value={"+ New System"} onClick={handleAdd} />
                                    </div>
                                </>
                            </div>
                        </div>

                        <div className='afPopupFooter'>
                            <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                                <div>
                                    {createuser && createuser === "createUser" ? <></> : 
                                    location.state.permission.childPermissions[1]?.canUpdate
                                    ?<div className='button-holder'>
                                        <input data-clickable-testid="reset-password" style={{ width: "auto", marginRight: "12px" }} type="submit" className="secondary" value={"Reset password"} onClick={resetPswdClick} disabled={userData ? userData.isLocked : ""} />
                                        <input data-clickable-testid="unlock-user" style={{ width: "auto" }} type="submit" className="primary" value={"Unlock User"} onClick={userUnlockClick} disabled={userData ? !userData.isLocked : ""} />
                                        <input data-clickable-testid="delete-user" style={{ width: "auto" }} type="submit" className="primary" value={"Delete User"} onClick={userDeleteClick} /*disabled={}*/ />
                                    </div>:<></>}
                                </div>
                                <div>
                                    <Input data-clickable-testid="Cancel" type="button" style={{ marginRight: "12px" }} className="secondary" value={"Cancel"} onClick={close} />
                                    {createuser && createuser === "createUser" ?<Input type="submit" value={"Save"} />:
                                        location.state.permission.childPermissions[1]?.canUpdate
                                        ?<Input type="submit" value={"Save"} />
                                        :<></>
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {userDelete
                ?<SmallPopup
                    popUp={userDelete}
                    title={"Delete User"}
                    subtitle={`Do you want to delete user ${props.userData.loginName} ?`}
                    handlePopUp={() => setUserDelete(false)}
                    handleSubmit={() => handleDeleteUser(props.userData.loginName)}
                />
                : ""
            }
        </Popup>

    )
}

export default NewUserDialog