import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";
import styles from "./metadataBox.module.css"

export default function MetadataBox(props) {
    const { data } = props;
    const [optionData, setOptionData] = useState(data);

    const expandSubOption = (option) => {
        setOptionData(val => ({ ...val, showContent: !val.showContent }))
    }

    return (
        <div key={optionData.name}>
            <div className={styles.option} onClick={() => expandSubOption(optionData.name)}>
                <span>{optionData.name}</span>
                {optionData.showContent ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {optionData.showContent ? <div className={styles.optionContent}>
                {optionData.content}
            </div> : ""}
        </div>
    )
}
