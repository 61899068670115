import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./tab";
import "./tab.css";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0]?.props?.tId,
      activeTabId: this.props.children[0]?.props?.tId
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(
        prevProps.activeTab !== this.props.activeTab && 
        this.props.activeTab !== this.state.activeTab
    ){
        this.setState({ activeTab: this.props.activeTab, activeTabId: this.props.activeTabId })
    }
  }

  onClickTabItem = (tab,tId) => {
   // const { handleTabChange = () => null } = this.props;
    this.setState({ activeTab: tId });
    //handleTabChange(tId);
  };

  onCloseTabItem = (tab,tId,index) => {
    const { handleTabClose = () => null } = this.props;
    handleTabClose(index,tId);
  };

  onOpenTabItem = () => {
    const { handleTabOpen = () => null } = this.props;
    handleTabOpen();
  };

  render() {
    const {
      onClickTabItem,
      onCloseTabItem,
      onOpenTabItem,
      props: { children },
      state: { activeTab,activeTabId },
    } = this;

    return (
      <div className={`tabs ${this.props.tabsClassName}`}>
        <ol className={`tab-list ${this.props.headerStyle}`}>
          {children.map((child) => {
            if(child?.props)
            {
              const { isClick,label,tId,canClose,canOpen,index,sid,fileIcon,libraryIcon } = child.props;
              return (
                <>
                <Tab
                  activeTab={activeTab}
                  activeTabId={activeTabId}
                  tId={tId}
                  sid={sid}
                  label={label}
                  index={index}
                  canClose={canClose}
                  onClick={onClickTabItem}
                  onClose={onCloseTabItem}
                  fileIcon={fileIcon}
                  libraryIcon = {libraryIcon}
                  isClick={isClick}
                />
                </>
              );
              
            }
            return null;
          })}
         {this.props.canOpen && this.props.canOpen === true && <span data-clickable-testid={"newQueryTab"} className="new-btn" onClick={onOpenTabItem}><img src="/images/variables/addIcon.svg"></img></span>}
        </ol>
        <div className={`tab-content ${this.props.contentStyle}`}>
          {children.map((child) => {
            if (child?.props?.label !== activeTab) return null;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;