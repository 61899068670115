import { 
    deleteTableDataApi, 
    executeQueryApi, 
    getAllTablesApi, 
    getSharedTablesApi,
    getTableColumnsApi, 
    getTableDetailApi, 
    importFileApi, 
    importTableApi, 
    shareTableDataApi, 
    updateTablePermissionApi ,
    exportTableData
} from '../../api/library/database-tables/database';
import ActivityIndicator from '../../components/activity/ActivityIndicator';
import { messages } from './messages';
import React from "react";

export function DatabaseActions({ show, close, notify }){
    const getAllTables = async (projectName) =>{
        show(()=><ActivityIndicator text={messages.loadingTables}/>)
        const [error, data] = await getAllTablesApi(projectName);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
    const getSharedTables = async (projectName) =>{
        show(()=><ActivityIndicator text={messages.loadingTables}/>)
        const [error, data] = await getSharedTablesApi(projectName);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const getTableColumns = async (tableName) =>{
        show(()=><ActivityIndicator text={messages.loadingColumns}/>)
        const [error, data] = await getTableColumnsApi(tableName);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const getTableExport = async (tableName,projectName) =>{
        show(()=><ActivityIndicator text={messages.exportTable}/>)
        const [error, data] = await exportTableData(tableName, projectName);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data){
          notify({ message: data, type: "S" });
          return [data.message, data];
        }
        return [null,data];
      }
     const executeQuery = async (query, project,isLib) =>{
        show(()=><ActivityIndicator text={messages.executingQuery}/>)
        const [error, data] = await executeQueryApi(query, project,isLib);
        close()
        if(error){
          notify({ message: error, type: "E" });
        }else if(data === null){
          notify({ message: messages.success, type: "S" });
        } else if(data.error){
          notify({ message: data.errmsg, type: "E" });
        }else{
          if(!data.error && data.status && data.status !== 'Error') {
              if(query.toLowerCase().includes("select")) {
                if(data.noOfRowsEffected === 200 && !query.toLowerCase().includes(" limit "))
                notify({ message: messages.displayRecordLimit, type: "S"})
                else
                notify({ message: messages.displayRecordCount(data.noOfRowsEffected), type: "S"})
              } else {
                notify({ message: data.status?data.status:messages.success, type: "S" });
              }
          }
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const getTableDetail = async (tableName,filter) =>{
        show(()=><ActivityIndicator text={messages.fetchingDetails}/>)
        const [error, data] = await getTableDetailApi(tableName,filter);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const updateTablePermission = async (sharedTableName,sharedProjects) =>{
        show(()=><ActivityIndicator text={messages.updatingPermissions}/>)
        const [error, data] = await updateTablePermissionApi(sharedTableName,sharedProjects);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        } else if(data.status.type==='S') {
            notify({ message: data.status.message, type: "S" });
        } else if(data.status.type==='E') {
            notify({ message: data.status.message, type: "E" });
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const shareTableData = async (tableName,projectName) =>{
        show(()=><ActivityIndicator text={messages.sharingTable}/>)
        const [error, data] = await shareTableDataApi(tableName,projectName);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }else
        {
            if(data.error || data.status === 'Error')
               notify({ message: data.errmsg, type: "E" });
            else
            {
                notify({ message: data.status.message, type: "S" });
            }
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const deleteTableData = async (tableName,projectName) =>{
        show(()=><ActivityIndicator text={messages.deletingTable}/>)
        const [error, data] = await deleteTableDataApi(tableName,projectName);
        close()
        if(error)
               notify({ message: error, type: "E" });
        else if(data === null)
        {
            notify({ message: messages.success, type: "S" });

        }else
        {
            if(data.error || data.status === 'Error')
                notify({ message: data.errmsg, type: "E" });
            else
            {
                notify({ message: data.status, type: "S" });
            }
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const importFile = async (tableName,projectName,file) =>{
        show(()=><ActivityIndicator text={messages.importingFiles}/>)
        const [error, data] = await importFileApi(tableName,projectName,file);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
      const importTable = async (tableName,projectName,importId,payload) =>{
        show(()=><ActivityIndicator text={messages.importingTables}/>)
        const [error, data] = await importTableApi(tableName,projectName,importId,payload,notify);
        close()
        if(error){
          notify({ message: error, type: "E" });
          return [error, data];
        }
        if(data.code >= 400){
          notify({ message: data.message, type: "E" });
          return [data.message, data];
        }
        return [null,data];
      }
    return {
        getAllTables,getSharedTables,getTableColumns,executeQuery,getTableDetail,updateTablePermission,shareTableData,deleteTableData,importFile,importTable,getTableExport
    };
}

