import {
  getStorage,
  uploadFile,
  getFileUrl,
  createFolder,
  getAllFolders,
  getDriveDetail,
  getRecentFiles,
  updateDrivePermission,
  deleteFolderFile,
  renameFolderFile,
  shareToLibrary,
  getProjectLibraryFolders,
  getLibraryFolders,
  getAllFoldersForCopyOrMove,
  drivecopy,
  driveCopyOrMove
} from '../../api/drive/drive';
import ActivityIndicator from '../../components/activity/ActivityIndicator';
import { downloadFileFromUrl } from '../../api/url';
import { readFileAsync } from '../../common';
import { messages } from './messages';
import React from "react";

export function DriveActions({ show, close, notify }) {

  const GetFolders = async (projectName, path, pagination, filter) => {
    show(() => <ActivityIndicator text={messages.loading} />)
    const [error, data] = await getAllFolders(projectName, path, pagination, filter);
    close()
    if (error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }
  const GetProjectsLibraryFolders = async (projectName, path, pagination, filter) => {
    show(() => <ActivityIndicator text={messages.loading} />)
    const [error, data] = await getProjectLibraryFolders(projectName, path, pagination, filter);
    close()
    if (error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }
  const GetLibraryFolders = async ( pagination, filter) => {
    show(() => <ActivityIndicator text={messages.loading} />)
    const [error, data] = await getLibraryFolders( pagination, filter);
    close()
    if (error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }
  const sharedDriveDetail = async (name,filter) => {
    show(() => <ActivityIndicator text={messages.loadingRecents} />)
    const [error, data] = await getDriveDetail(name,filter);
    close();
    if (error) {
      notify({ message: error, type: "E" });
      return [error, null];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, null];
    }
    return [null, data];
  }

  const GetRecentFiles = async (projectName) => {
    show(() => <ActivityIndicator text={messages.loadingRecents} />)
    const [error, data] = await getRecentFiles(projectName);
    close();
    if (error) {
      notify({ message: error, type: "E" });
      return [error, null];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, null];
    }
    return [null, data];
  }

  const GetStorage = async (projectName) => {
    show(() => <ActivityIndicator text={messages.loadingStorage} />)
    const [error, data] = await getStorage(projectName);
    close()
    if (error)
      return [error, null];
    if (data.code >= 400)
      return [data.message, null];
    return [null, data];
  }

  const NewFolder = async (projectName, parent, name, isShared) => {
    show(() => <ActivityIndicator text={messages.creatingFolder} />)
    const [error, data] = await createFolder(parent, name, isShared, projectName);
    close()

    if (error) {
      notify({ type: 'E', message: error });
      return [error, null];
    }
    if (data.code >= 400)
      return [data.message, null];

    if (data.status.code === 1) {
      notify({ type: 'S', message: data.status.message });
    }
    return [null, data];
  }

  const UploadFile = async (parent, file, projectName) => {
    show(() => <ActivityIndicator text={messages.uploadingFile} />);
    let blob = await readFileAsync(file);
    const [error, data] = await uploadFile(parent, blob, file.name, projectName);
    close();
    if (error)
      return [error, null];
    if (data.code >= 400)
      return [data.message, null];

    if (data.status) {
      notify(data.status);
    }
    return [null, data];
  }

  const shareDriveToProject = async (fileName, projects) => {
    show(() => <ActivityIndicator text={messages.updatePermission} />);
    const [error, data] = await updateDrivePermission(fileName, projects);
    close();
    if (error)
      return [error, null];
    if (data.code >= 400)
      return [data.message, null];

    if (data.status) {
      notify(data.status);
    }
    return [null, data];
  }

  const GetFileUrl = async (projectName = "", path = "", name = "") => {
    show(() => <ActivityIndicator text={messages.loadingFileUrl} />);
    const [error, data] = await getFileUrl(projectName, path);
    close();
    if (error)
      return [error, null];
    if (!data.url)
      return [data.data, null];

    if (data.url) {
      downloadFileFromUrl(data.url, name);
      notify({ message: messages.initiatedDownload, type: "S" });
    }
    return [null, data.url];
  }
  const DeleteFolderFile = async (projectName = "", path = "") => {
    show(() => <ActivityIndicator text={messages.deleteFolderFile} />);
    const [error, data] = await deleteFolderFile(projectName, path);
    close();

    if (error) {
      notify({ type: 'E', message: error });
      return [error, null];
    }
    if (data.code >= 400)
      return [data.message, null];

    if (data.status === "Success") {
      notify({ type: 'S', message: data.data });
    }
    return [null, data];
  }

  const RenameFolder = async (projectName, oldPath, newName) => {
    show(() => <ActivityIndicator text={messages.renameFolderFile} />);
    const [error, data] = await renameFolderFile(projectName, oldPath, newName);
    close();

    if (error) {
      notify({ type: 'E', message: error });
      return [error, null];
    }
    if (data.code >= 400)
      return [data.message, null];

    if (data.status === "Success") {
      notify({ type: 'S', message: data.data });
    }
    return [null, data];

  }

  const ShareToLibrary = async (projectName, filePath) => {
    show(() => <ActivityIndicator text={messages.sharingFile} />);
    const [error, data] = await shareToLibrary(projectName, filePath);
    close();

    if (error) {
      notify({ type: 'E', message: error });
      return [error, null];
    }
    if (data.code >= 400)
      return [data.message, null];

    if (data.status.code) {
      notify({ type: 'S', message: data.status?.message || "" });
    }
    return [null, data];
  }

  const GetAllFolders = async (projectName) => {
    show(() => <ActivityIndicator text={messages.loading} />)
    const [error, data] = await getAllFoldersForCopyOrMove(projectName);
    close()
    if (error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }

  const DriveCopyOrMove = async (projectName,destination,fileName,copyOrMove) => {
    show(() => <ActivityIndicator text={messages.loading} />)
    const [error, data] = await driveCopyOrMove(projectName,destination,fileName,copyOrMove);
    close()
    if (error) {
      notify({ message: error, type: "E" });
      return [error, data];
    }
    if (data.code >= 400) {
      notify({ message: data.message, type: "E" });
      return [data.message, data];
    }
    return [null, data];
  }

  return {
    GetFolders,
    GetRecentFiles,
    GetStorage,
    NewFolder,
    UploadFile,
    sharedDriveDetail,
    shareDriveToProject,
    GetFileUrl,
    DeleteFolderFile,
    RenameFolder,
    ShareToLibrary,
    GetProjectsLibraryFolders,
    GetLibraryFolders,
    GetAllFolders,
    DriveCopyOrMove
  }
}