import { getLicenseKey, updateLicenseKey } from '../../../api/administration/licenseRenewal/licenseRenewal'
import ActivityIndicator from '../../../components/activity/ActivityIndicator'
import React from "react";

function LicenseActions({ show, close, notify }) {

    const GetLicenseKeyValue = async () => {
        show(() => <ActivityIndicator text={"Loading License Key"} />)
        const [error, data] = await getLicenseKey()
        close()
        if (error) {
            notify({ message: data.status.message, type: "E" });
            return [data.status.messag, data];
        }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        return [null, data];
    }


    const UpdateLicenseKeyValue = async (input) => {
        show(() => <ActivityIndicator text={"Updating License Key"} />)
        const [error, data] = await updateLicenseKey(input)
        close()
        if (error) {
            notify({ message: error, type: "E" });
            return [error, null];
        }
        // if (data.status.code === 1) {
        //     notify({ message: data.status.message, type: "S" });
        //     return [data.status.message, data];
        // }
        if (data.code >= 400) {
            notify({ message: data.message, type: "E" });
            return [data.message, data];
        }
        if (data) {
            notify(data.status)
        }
        return [null, data];
    }

    return { GetLicenseKeyValue, UpdateLicenseKeyValue }
}

export default LicenseActions