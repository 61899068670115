const messages = {
    name: "Name",
    logins: "Logins",
    desc: "Description",
    newLogin: "New Login",
    loading: "Loading Logins",
    invalidName: "Invalid Name",
    emptyLogins: "No Logins found",
    creating: "Creating New Login",
    invalidDesc: "Invalid Description",
    newLoginTitle: "Tell us more about your login",
    createAppTitle:"Creating Login"
};

export default messages;