export const SHAPE_TYPES = {
    ID: 'id',
    ACTIVITY:'Activity',
    SCREEN:'Screen',
    XOR:'XOR',
    CASE:'Case',
    START:'Start',
    END:'End',
    ENDICON:'EndIcon',
    STARTDEFAULT:'StartDefault',
    ENDDEFAULT:'EndDefault',
    CONNECTOR:'connector',
    BOS:'ServiceTask',
    DMN:'DMN',
    ICON:'Icon',
    EYE:'eye',
    LANE:'Lane',
    TASK:'Task',
    NOTE:'Note',
    SCRIPT: 'Script',
    MORE_OPTIONS_ICON:  'moreOptionsIcon',
    TABLE_ICON: 'tableIcon',
    PROCESS_CONDITION:'ProcessCondition',
    LANE_NAME:'LaneName',
    STATE_LABEL:'stateLabel',
    MENU:'Menu',
    INTRACTION_LABEL:'intractionLabel',
    EMAIL: 'Email',
    ASSIGNMENT: 'Assignment'
}