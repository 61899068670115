import { 
    ServiceTask, 
    Activity, 
    ManualTask, 
    Email,
    Assignment,
    Task, Condition, Note, Lane, BusinessRules, AND, OR, XOR, Script, Start, End, StartTimer, EndTimer, StartMessage, EndMessgae, Template, Screen, Title, StartDefault, EndDefault, DMN, ProcessCondition, CASE, Menu  } from "./shape/";


export const processObjects = {
    "Task": () => { return new Task(); },
    "Activity": () => { return new Activity(); },
    "Lane": (Name) => { return new Lane(Name); },
    "ManualTask": () => { return new ManualTask(); },
    "ServiceTask": () => { return new ServiceTask(); },
    "Condition": () => { return new Condition(); },
    "Note": () => { return new Note(); },
    "BusinessRules": () => { return new BusinessRules(); },
    "AND": () => { return new AND(); },
    "OR": () => { return new OR(); },
    "XOR": () => { return new XOR(); },
    "Case": () => { return new CASE(); },
    "Script": () => { return new Script(); },
    "Start": (type) => { return new Start(type); },
    "StartMessage": () => { return new StartMessage(); },
    "StartTimer": () => { return new StartTimer(); },
    "End": () => { return new End(); },
    "EndMessage": () => { return new EndMessgae(); },
    "EndTimer": () => { return new EndTimer(); },
    "Screen": (obj) => { return new Screen(obj); },
    "StartDefault": () => { return new StartDefault() },
    "EndDefault": () => { return new EndDefault(); },
    "DMN": () => { return new DMN(); },
    "ProcessCondition":()=> { return new ProcessCondition();},
    "Menu": () => { return new Menu(); },
    "Email": () => { return new Email(); },
    "Assignment": () => { return new Assignment(); },
};