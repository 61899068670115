import PopUp from '../../../components/layouts/PopUp';
import React, { useEffect, useState } from 'react';
import './userLogs.css';

function UserLogsDetail(props) {

    const [userLogData, setUserLogData] = useState({});
    const { setUserModal, userModal } = props;

    useEffect(() => {
        let l = { 
            ...(userModal || {}),
            ...(userModal?.data || {}) 
        };
        delete l.data;
        setUserLogData(l);
    }, [userModal])

    return (
        <PopUp 
            popUp={!!userModal} 
            title={"More Details"}
            setPopUp={setUserModal}
            testId = {"userlogs-detail-popup"}
        >
            <div className='mainUserLogs' data-table-testid="userlogs-details">
                {Object.keys(userLogData).map((item, index) => {
                    return (
                        <div className='userLogsContent' data-row-testid={index}>
                            <div className='userLogsTextWrap'>
                                <label className='userTextLabel' data-col-testid={"logKey"}>{item}</label>
                                <p className='userLogsValue' data-col-testid={"logValue"}>{userLogData[item]}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='afFooter'>
                <input 
                    type={"button"}
                    value={"Close"}
                    className={"secondary"}
                    data-clickable-testid = {"Close"}
                    onClick={(() => setUserModal(null))} 
                />
            </div>
        </PopUp>
    )
}

export default UserLogsDetail