import React from 'react';
import Dialog from '../../../../views/dialog/index';
import {
  Box, Button,  
  DialogActions
} from '@material-ui/core';
import { DataGrid, GridColDef ,GridCellEditStopParams,MuiEvent,GridCellEditStopReasons,GridRowModes,GridPreProcessEditCellProps } from '@mui/x-data-grid';


const EditLanguage = (props) => {
    const { showDialog,onClose,modifyLanguage } = props;
    const {target,rows,detail}=showDialog;
    
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 90,hide:true },
        {
          field: 'english',
          headerName: 'English',
          width: 500,
          editable: false,
        },
        {
          field: 'target',
          headerName: target,
          width: 500,
          editable: true,
        }
      ];
  const handleClose = () => {
    onClose();
  }
  
  const footer = () => {
    return(
      <Box >
        <DialogActions>
          <Box>
            <Button variant="contained" color="secondary" onClick={(e) => handleClose()}>Close</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleClose();
              }}>
              Ok
            </Button>
          </Box>
          </DialogActions>
      </Box>
    );
  };
  
  const modalTitle = () => {
    return( 
      <Box id="scroll-dialog-title">
          <h2 id="modal-title" >
           { "Edit Language "+ target}
          </h2>
      </Box>
    )  
  };
  const handleRowEditCommit = React.useCallback(
    (params) => {
        const id = params.id;
        const key = params.field;
        const value = params.value; 
        console.log("Edit Rows",{id,key,value,detail});
        let {project,fName,version,lcode,payload}=detail;
        payload[id]=value;
        modifyLanguage(project,fName,version,lcode,payload);
    },
    []
 );
 const handlePocessRowUpdate= React.useCallback(
  (params,oldRow) => {
      const id = params.id;
      const value = params.target; 
      console.log("processRowUpdate",{id,value,detail,oldRow});
      let {project,fName,version,lcode,payload}=detail;
      payload[id]=value;
      modifyLanguage(project,fName,version,lcode,payload);
  },
  []
);

  const DiaLogContainer =() => {
    return(
        <Box sx={{ height: 400,padding:50,paddingBottom:20,justifyContent:"center" }}>
          <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                onRowEditCommit={handleRowEditCommit}
                processRowUpdate={handlePocessRowUpdate}
                columnVisibilityModel={{
                  // Hide columns id, the other columns will remain visible
                  id: false,
                }}
            />
        </Box>   
    )
  }

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      dialogTitle={modalTitle()}
      dialogoContent={DiaLogContainer()}
      dialogFooter={footer()}
      isCustomFooter={true}
      fullWidth={true}
      maxWidth={'xl'}
    />
  );
};

export default EditLanguage;
