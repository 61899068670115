import {getTypes,getLogs,getRequestId} from '../../../api/administration/userLogs/userlogs'
import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import React from "react";


export function UserLogActions({show,close, notify}){

    const getUserLogs = async (limit,log, nextKey) =>{

        return new Promise(async (resolve, reject) => {

            const gets3Logs = async () => {
                const [error, data] = await getLogs(requestId);
                const status = data?.status?.message;
                if(status !== "Pending") {
                    close();
                    if(error)
                        resolve([error, null]);
                    else if(data.code >= 400)
                        resolve([data.message, null]);
                    else
                        resolve([null, data]);
                } else {
                    setTimeout(gets3Logs, 10000);
                }
            }

            show(()=><ActivityIndicator text={"loading UserLogs"}/>)
            const [error, requestId] = await getRequestId(limit,log,nextKey);
            if(!error){
                gets3Logs();
            }else {
                close();
                notify({ type: "E", message: error });
                resolve([error, {}]);
            }
        })
    }

    const getLogTypes = async () => {
        show(()=><ActivityIndicator text={"Loading Logtypes"}/>)
        const [error, data] = await getTypes();
        close()
        if(error)
            return [error, null];
        if(data.code >= 400)
            return [data.message, null];
        return [null,data];
    }

    return {
        getUserLogs,getLogTypes
    }
}