import React from "react";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../components/activity/AppContext";
import { ServiceActions } from "../services.actions";
import styles from "./transport.module.css";

export default function TransportProject(props) {
    const { handleClose, serviceData, transportService } = props;
    const [succesMsg, setSuccessMsg] = useState(false);
    const [failMsg, setFailMsg] = useState({ show: false, msg: "" });
    const app = useAppContext();

    const transportProject = async () => {
        const actions = ServiceActions(app());
        const sendData = { projectName: serviceData.projectName, uuid: serviceData.serviceId, version: transportService.item.version, comment: transportService.comment };
        const [error, data] = await actions.transportServiceData(sendData);

        if (!error) {
            let reqId = JSON.parse(data.object).requestId;
            checkStatus(reqId)
        }
    }

    const checkStatus = async (reqId) => {
        const actions = ServiceActions(app());
        const [error, data] = await actions.getTransportStatus(reqId, serviceData.projectName);

        if (data) {
            if (data.detail.status === "finish") {
                checkBosFunction(data.detail.fileAddress);
            } else if (data.detail.status === "pending") {
                checkStatus(reqId);
            } else if (data.detail.status === "failed") {
                showResult(data.detail.fileAddress)
            }
        }
    }

    const checkBosFunction = async (url) => {
        const actions = ServiceActions(app());
        const [error, data] = await actions.getBosFunction(serviceData.serviceId, serviceData.projectName);

        if (!error) {
            const result = await fetch(url);
            const res = await result.text();
            setSuccessMsg(true);
        }
    }

    const showResult = async (url) => {
        const result = await fetch(url);
        const res = await result.text();
        setFailMsg({ show: true, msg: res })
    }

    useEffect(() => {
        transportProject();
    }, []);

    return (
        <div className={styles.popupCont}>
            <div className={styles.titleCont}>
                <h2 className={styles.title}>{"Transport Complete"}</h2>
            </div>
            <div className={styles.transportMsg}>
                {succesMsg ? <div>
                    <span>{"Successfully Transported"}</span>
                </div> : ""}
                {failMsg.show ? <div>
                    <span>{failMsg.msg}</span>
                </div> : ""}
            </div>
            <div className={styles.btnCont}>
                <input
                    type="button"
                    className="primary"
                    value={"Done"}
                    onClick={handleClose}
                    data-clickable-testid={"Done"}
                />
            </div>
        </div>
    )
}
