import React, { useState, useEffect } from 'react';
import NewProject from '../../views/new-project/index.js';
import { Container, Box, Grid, Button } from '@material-ui/core';
import ContentHeader from '../../common/components/ContentHeader/index.js';
import { getPermissions, getEnvironment } from '../../utils/common.js';
import { configMessage } from '../../common/messages/config.js';
import { allProjectConstants } from '../../constants';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './style';


export default function (props) {
  const { checkMaximumNumberOfProjects, navigateToLicenseRenewal } = props;
  const classes = useStyles();
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    const firstName = `${configMessage.T4501} ${props.userFirstName}`;
    setFirstName(firstName);
  }, [props.userFirstName]);

  const openNewProject = () => {
    // if(checkMaximumNumberOfProjects()) {
      setShowNewProjectModal(true);
    // }
  }

  return (
    <Box component='div' className={classes.dashboardPage}>
      <Grid container spacing={3}>
        <Grid item xs={12} className='layout'>
          <ContentHeader
            left={9}
            right={3}
            title={firstName}
            subtitle={`${getEnvironment(sessionStorage.getItem('env'))} ${configMessage.T4502}`}
          >
            <Box variant='div'>
              {getPermissions()?.projects?.canCreate && <Button
                variant='contained'
                id='newproject_btn'
                title={configMessage.T4503}
                color='primary'
                onClick={openNewProject}
                startIcon={<AddIcon />}
              >
               {configMessage.T4503}
              </Button>
              }
              <NewProject
                show={showNewProjectModal}
                onClose={() => setShowNewProjectModal(false)}
                navigateTo={props.navigateToProcessDesigner}
                managerListData={props.managerlist}
                templateCardData={props.templateCardList}
                createNewProject={props.createNewProject}
                handleNewProjectValue={props.handleNewProjectValue}
                alertOpen={props.alertOpen}
                projects={props.projects}
              />
            </Box>
          </ContentHeader>

          <Container className='widgets'>
            {/* <Box component="div">
              <Grid xs={12}>
                {sessionStorage.getItem('licenseStatus').includes("expires")?<p style={{textAlign:"center"}}>{sessionStorage.getItem('licenseStatus')}. Contact administrator for new license Key.<span><Button onClick={navigateToLicenseRenewal} style={{color:"blue"}}>Renew</Button></span></p>:<></>}
              </Grid>
            </Box> */}
            <Box className='recent-projects-container'>
              <Grid xs={12} item={true}>
                {props.recentProjectsWidget}
              </Grid>
              {/* <Grid xs={12} sm={12} md={4} lg={3}>
                {props.tasklistWidget}
              </Grid> */}
            </Box>
            <Box>
              <Grid xs={12}>
                  {props.needHelp}
              </Grid>
            </Box>
            {/* <Box className='app-performance'>
              <Box className='app-performance-container'>
                <Grid
                  xs={12}
                  sm={12}
                  md={8}
                  lg={9}
                  className='app-performance-grid'
                >
                  <p className='head-name'>Apps Performance</p>
                  <Box component='div' className='head d-flex flex-row-reverse'>
                    <Button
                      id='all_analytics'
                      variant='outlined'
                      color='secondary'
                    >
                      All Analytics
                    </Button>
                    <Form.Control
                      as='select'
                      className='select-btn btn-secondary date-range'
                    >
                      <option>Date range: Aug 01-Aug 30</option>
                    </Form.Control>
                  </Box>

                  <Box component='div' className='chart-li align'>
                    <Box component='div' className='grid-1'>
                      <p>Monthly Active users</p>
                    </Box>
                    <Box component='div' className='grid-2'>
                      <img src={graph} alt='' className='chart-img' />
                    </Box>
                  </Box>

                  <Box className='row-3'>
                    <Grid xs={12} md={6} lg={5} className='load-li align'>
                      <Box className='grid-1'>
                        <p>Apps</p>
                      </Box>
                      <Box className='grid-2'>
                        <img src={load} alt='' className='load-img'></img>
                      </Box>
                      <Box className='grid-3'>
                        <div className='status'>
                          <p>
                            <img src={green} alt='' className='green-dot'></img>
                            Active
                          </p>
                          <label>10</label>
                        </div>
                        <div className='status'>
                          <p>
                            <img src={green} alt='' className='green-dot'></img>
                            In Development
                          </p>
                          <label>5</label>
                        </div>
                        <div className='status'>
                          <p>
                            <img src={green} alt='' className='green-dot'></img>
                            In Quality
                          </p>
                          <label>2</label>
                        </div>
                      </Box>
                    </Grid>

                    <Grid xs={12} md={6} lg={6} className='bar-li align'>
                      <Box className='grid-1'>
                        <p>Active Daily Usage</p>
                      </Box>
                      <Box className='grid-2'>
                        {' '}
                        <img src={bar} alt='' className='bar-img'></img>{' '}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={3} className='drive-items'>
                  {props.driveItemsWidget}
                </Grid>
              </Box>
            </Box> */}
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}
