import { getUrl, get, deleteAPI, post, put } from "../../url"

export async function getRoleMasterData() {
    const uri = getUrl() + "/configurator/v2.0/role/listWithCount?$select=companyId,name,description,isDefault,isPlatform,userCount&$sort=asc name";
    const [error, data] = await get(uri);
    return [error, data];
}

export async function deleteRole(uuid) {
    const uri = getUrl() + "/configurator/v1.0/role/" + uuid;
    const [error, data] = await deleteAPI(uri);
    return [error, data];
}

export async function unassignRole(updateData, role) {
    if (role.includes(" ")) role = role.replaceAll(" ", "%20");
    const url = getUrl() + `/configurator/v1.0/user/${updateData.loginName}/${role}/revokeRole`
    const [error, data] = await put(url);
    return [error, data];
}

export async function postCreateAndUpdateRoles(value, type) {
    let postValue = {};
    postValue.name = value.data.name;
    postValue.isPlatform = value.data.roleAdmin;
    postValue.description = value.data.description;
    postValue.properties = "";

    for (let i = 0; i < value.expressions.values.length; i++) {
        if (value.expressions.values[i].select1) postValue.properties += `${value.expressions.values[i].select1} EQ '${value.expressions.values[i].select3}' ${value.expressions.key}, `;
    }

    if (value.expressions.key === "AND") {
        postValue.properties = postValue.properties.substring(0, postValue.properties.length - 6);
    }
    else if (value.expressions.key === "OR") {
        postValue.properties = postValue.properties.substring(0, postValue.properties.length - 5);
    }
    else {
        postValue.properties = postValue.properties.substring(0, postValue.properties.length - 3);
    }
    let url = getUrl();
    if (type) {
        let defaultName = value.defaultName.replaceAll(" ", "%20");
        url += `/configurator/v1.0/role/${defaultName}`;
        if (value.data.roleAdmin) {
            postValue.rolePermissions = [];
            for (let j = 0; j < value.permissions.length; j++) {
                let permissionsVal = `${+value.permissions[j].canView}${+value.permissions[j].canCreate}${+value.permissions[j].canUpdate}${+value.permissions[j].canDelete}`;
                postValue.rolePermissions.push({ permissionId: value.permissions[j].id, access: permissionsVal });
            }
        }
        const [error, data] = await put(url, postValue);
        return [error, data];
    }
    else {
        if (value.data.roleAdmin) {
            url += "/configurator/v1.0/role/createWtPerm";
            postValue.rolePermissions = [];
            for (let j = 0; j < value.permissions.length; j++) {
                let permissionsVal = `${+value.permissions[j].canView}${+value.permissions[j].canCreate}${+value.permissions[j].canUpdate}${+value.permissions[j].canDelete}`;
                postValue.rolePermissions.push({ permissionId: value.permissions[j].id, access: permissionsVal });
            }
        }
        else {
            url += "/configurator/v1.0/role";
            postValue = { role: postValue };
        }
        const [error, data] = await post(url, postValue);
        return [error, data];
    }
}

export async function fetchGetPermissions(id) {
    let name = id.replaceAll(" ", "%20");
    const url = getUrl() + "/configurator/v1.0/role/" + name;
    const [error, data] = await get(url);
    return [error, data];
}

export async function fetchPutPermissions(value) {
    let postValue = {};
    postValue.name = value.data.name;
    postValue.isPlatform = value.data.roleAdmin;
    postValue.description = value.data.description;
    postValue.properties = "";

    for (let i = 0; i < value.expressions.values.length; i++) {
        if (value.expressions.values[i].select1) postValue.properties += `${value.expressions.values[i].select1} EQ '${value.expressions.values[i].select3}' ${value.expressions.key}, `;
    }

    if (value.expressions.key === "AND") {
        postValue.properties = postValue.properties.substring(0, postValue.properties.length - 6);
    }
    else if (value.expressions.key === "OR") {
        postValue.properties = postValue.properties.substring(0, postValue.properties.length - 5);
    }
    else {
        postValue.properties = postValue.properties.substring(0, postValue.properties.length - 3);
    }

    postValue.rolePermissions = [];

    for (let j = 0; j < value.permissions.length; j++) {
        let permissionsVal = `${+value.permissions[j].canView}${+value.permissions[j].canCreate}${+value.permissions[j].canUpdate}${+value.permissions[j].canDelete}`;
        postValue.rolePermissions.push({ permissionId: value.permissions[j].id, access: permissionsVal });
    }

    let defaultName = value.defaultName.replaceAll(" ", "%20");
    let url = getUrl() + `/configurator/v1.0/role/updateWtPerm/${defaultName}`;
    const [error, data] = await put(url, postValue);
    return [error, data];
}