import React, { Component } from 'react';
import { fetchfunctionslist, handleProjectRestore, clearRestoreProject } from '../../helpers/all-projects';
import { saveProjectPathUrl } from '../../helpers/project-detail';
import { configMessage } from '../../common/messages/config';
import RecentProjects from '../../views/recent-projects/';
import { showNotification } from '../../actions/app-root';
import { getCookie } from '../../utils/cookieServices';
import { dashboardConstants } from '../../constants';
import { openProject } from '../../helpers/app-root';
import { getCookies } from '../../utils/common';
import DriveList from '../../views/drive-list';
import TaskList from '../../views/task-list';
import NeedHelp from '../../views/need-help';
import DashboardLayout from './layout';
import { connect } from 'react-redux';
import {
  fetchRecentTasks,
  fetchRecentDriveItems,
  fetchRecentProjects
} from '../../helpers/dashboard';
import {
  fetchManagerData,
  fetchTemplateList,
  handleNewProjectCreate
} from '../../helpers/all-projects';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createData: {},
      modalShow: false,
      userFirstName: getCookie('firstName'),
      environment: 'Development',
    };
    this.handleNewProjectValue = this.handleNewProjectValue.bind(this);
  }

  handleNewProjectValue(data) {
    this.setState({ createData: data });
    handleNewProjectCreate(data);
  }

  handleShowNewProjectModal = () => {
    this.setState({ modalShow: true });
  };
  handlehideNewProjectModal = () => {
    this.setState({ modalShow: false });
  };

  navigateToProcessDesigner = () => {
    setTimeout(() => this.pushNavigation(), 3000);
  };

  navigateToLicenseRenewal = () => {
    this.props.history.push({
      pathname:`/Administration/licenserenewal`,
    });
  }

  pushNavigation() {
    if (Object.keys(this.props.createNewProject).length > 0) {
      if (this.props.createNewProject.status.code == 1) {
        this.props.history.push({
          pathname:`/Project/${this.state.createData.nameText}/`,
          state:{name:this.state.createData.nameText,isNew:true}
        });
      }
    }
  }

  handleOpenProject = (project) => {
    if(this.checkMaximumNumberOfProjects(project)) {
      const { name } = project;
      project.savedUrlPath = `/Project/${name}/`;
      //saveProjectPathUrl(name, `/Project/${name}/`);
      openProject(project);
      this.props.history.push({
        pathname: `/Project/${name}/`,
        state: project,
      });
    }
  };

  handleOpenProcess = (project) => {
    if(this.checkMaximumNumberOfProjects(project)) {
      const { name } = project;
      project.savedUrlPath = `/Project/${name}/BusinessProcess/1`
      openProject(project);
      saveProjectPathUrl(name, `/Project/${name}/BusinessProcess/1`)
      this.props.history.push({
        pathname: `/Project/${name}/BusinessProcess/1`,
        state: project,
      });
    }
  };

  checkMaximumNumberOfProjects = project => {
    let enabled = false;
    if(this.props.openedProjects && this.props.openedProjects.length < 3) {
      enabled = true;
    } else {
      if(project) {
        this.props.openedProjects.map(item => {
          if(item.name === project.name) {
            enabled = true;
          }
        });
      }
    }
    if(!enabled) {
      let param = {
        message: configMessage.T4538,
        show: true,
        type: 'info',
      };
      this.props.showNotification(param);
    }
    return enabled;
  }

  componentDidMount() {
    let limit = 5;
    fetchRecentProjects(limit, getCookies('loginName'));
    fetchfunctionslist(100);
    fetchRecentTasks();
    fetchRecentDriveItems();
    fetchManagerData();
    fetchTemplateList();
    if(sessionStorage.getItem('licenseStatus').includes("expires")) {
      this.props.showNotification({
        message:sessionStorage.getItem('licenseStatus'),
        show:true,
        type: "error"
      })
    }
  }

  handleOpenBusinessFunctions = (project, businessFunction) => {
    if(this.checkMaximumNumberOfProjects(project)) {
      const { name } = project;
      project.savedUrlPath = `/Project/${name}/BusinessFunction/${businessFunction.name}`
      openProject(project);
      saveProjectPathUrl(name, `/Project/${name}/BusinessFunction/${businessFunction.name}`)
      this.props.history.push({
        pathname:`/Project/${name}/BusinessFunction/${businessFunction.name}`,
        state:project
      });
    }
  }

  handleRestoreProject = (project) => {
    // handleProjectRestore(project);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if(this.props.restoreProject != undefined)
    // {
    //   if(Object.keys(this.props.restoreProject).length > 0)
    //   {
    //     clearRestoreProject();
    //     fetchRecentProjects(5, getCookies('loginName'));
    //   }
    // }
  }

  render() {
    return (
      <DashboardLayout
        userFirstName={this.state.userFirstName}
        recentProjectsWidget={
          <RecentProjects
            projects={this.props.projects}
            onClick={this.handleNewProjectOnClick}
            handleOpenProject={this.handleOpenProject}
            handleOpenProcess={this.handleOpenProcess}
            handleRestoreProject={this.handleRestoreProject}
            handleOpenBusinessFunctions={this.handleOpenBusinessFunctions}
            functions={this.props.functions}
          />
        }
        needHelp={
          <NeedHelp
            onClick={this.handleNewProjectOnClick}
          />
        }
        // tasklistWidget={<TaskList tasklist={this.props.tasklist} />}
        // driveItemsWidget={<DriveList driveItems={this.props.driveItems} />}
        // appPerformanceWidget=
        // {
        //     <AppPerformance />
        // }
        checkMaximumNumberOfProjects={() => this.checkMaximumNumberOfProjects()}
        environment={this.state.environment}
        modalState={this.state.modalShow}
        hideNewProject={this.handlehideNewProjectModal}
        showNewProject={this.handleShowNewProjectModal}
        managerlist={this.props.managerlist}
        templateCardList={this.props.templateCardList}
        navigateToProcessDesigner={this.navigateToProcessDesigner}
        handleNewProjectValue={this.handleNewProjectValue}
        createNewProject={this.props.createNewProject}
        navigateToLicenseRenewal={this.navigateToLicenseRenewal}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projects: state.dashboard.projects,
    tasklist: state.dashboard.tasklist,
    driveItems: state.dashboard.driveItems,
    managerlist: state.allprojects.managerlist,
    templateCardList: state.allprojects.templateCardList,
    createNewProject: state.allprojects.createNewProject,
    functions: state.allprojects.functions,
    openedProjects: state.application.openedProjects,
    restoreProject: state.allprojects.restoreProject,
  };
};

const mapDispatchToProps = dispatch => ({
  showNotification: data => dispatch(showNotification(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
