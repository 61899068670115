import React ,{ useRef, useState } from "react";
import styles from "./selectComponent.module.css";

export default function SelectComponent({ id,value, options, onSelect, onClick, disabled=false }) {

    const handleClick = (event) => {
        // onClick(event.target.value);
    }

    const handleChange = (event) => {
        onSelect(event.target.value);
    }
    return (
        <div className={styles.container}>
            <select className={styles.button} data-option-testid={id} value={value} onChange={handleChange} onClick={handleClick} disabled={disabled}>
                <option key="select" value="">Select</option>
                    {options && options.map((dt, index) => {
                        return <option value={dt} key={index}>{dt} </option>
                    })}
            </select>
        </div>
    );
}