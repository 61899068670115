import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
// Icons
import {
  PhonePortraitIcon,
  PhoneLandscapeIcon,
  TabletIcon,
  LaptopIcon,
  NextIcon,
  PreviousIcon,
  
} from '../../../../assets/designer-icons';
import useStyles from './style';

import { pageViewPermissions } from '../../../../utils/common';

const AppDesignerRightTab = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [zoomVal, setZoomVal] = useState(100);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ZOOM_OPTIONS = [150, 125, 100, 75, 50];
  const pageViewPolicy = pageViewPermissions();

  // React.useEffect(() => {
  //   if (['iPhone', 'Android'].includes(props.deviceLayout)) {
  //     if (props.deviceOrientation === 'Portrait' && activeTab !== 0) {
  //       setActiveTab(0);
  //     } else if (props.deviceOrientation === 'Landscape' && activeTab !== 1) {
  //       setActiveTab(1);
  //     }
  //   } else if (
  //     ['iPad', 'Windows', 'Tablet'].includes(props.deviceLayout) &&
  //     activeTab !== 2
  //   ) {
  //     setActiveTab(2);
  //   } else if (props.deviceLayout === 'Web' && activeTab !== 3) {
  //     setActiveTab(3);
  //   }
  // }, [props.deviceLayout]);

  useEffect(() => {
    let iframeContainer = document.getElementById('iframeContainer');
    if (iframeContainer) {
      iframeContainer.style.zoom = zoomVal / 100;
    }
  }, [zoomVal]);

  let showUndoRedo = props.showUndoRedo || false;

  return (
    <Grid
      xs={5}
      container
      className='subHeadGrid'
      justify='flex-end'
      alignItems='center'
    >
      <div className={`option leftBorder`}>
        <Select
          className='zoomSelector'
          defaultValue={zoomVal}
          value={zoomVal}
          IconComponent={ExpandMore}
          onChange={(e) => {
            e.stopPropagation();
            setZoomVal(e.target.value);
          }}
          onOpen={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          onClose={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
          MenuProps={{
            anchorEl: anchorEl,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
        >
          {ZOOM_OPTIONS.map((datum) => {
            return (
              <MenuItem
                value={datum}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {datum}%
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <div className='breadcrumb'>
        {!props.deploymentWebLayout && (
          <>
            <div
              className={`option leftBorder ${activeTab === 0 && 'active'}`}
              onClick={() => {
                setActiveTab(0);
                props.setState({
                  deviceLayout: ['iPhone', 'Android'].includes(props.deviceLayout) ? props.deviceLayout : 'iPhone',
                  deviceOrientation: 'Portrait',
                });
              }}
            >
              {activeTab === 0 ? (
                <Select
                  defaultValue=''
                  id='grouped-select'
                  value={props.deviceLayout}
                  IconComponent={ExpandMore}
                  onChange={(e) => {
                    e.stopPropagation();
                    props.setState({ deviceLayout: e.target.value,deviceOrientation: 'Portrait',});
                  }}
                  onOpen={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  MenuProps={{
                    anchorEl: anchorEl,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  }}
                >
                  {['iPhone', 'Android'].map((datum) => {
                    return (
                      <MenuItem
                        value={datum}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <PhonePortraitIcon
                          style={{ width: 16, height: 16, margin: '6px 8px' }}
                        />{' '}
                        <span>{datum}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <PhonePortraitIcon className='gearIcon' />
              )}
            </div>
            <div
              className={`option ${activeTab === 1 && 'active'}`}
              onClick={() => {
                setActiveTab(1);
                props.setState({
                  deviceLayout: ['iPhone', 'Android'].includes(
                    props.deviceLayout
                  )
                    ? props.deviceLayout
                    : 'iPhone',
                  deviceOrientation: 'Landscape',
                });
              }}
            >
              {activeTab === 1 ? (
                <Select
                  defaultValue=''
                  id='grouped-select'
                  value={props.deviceLayout}
                  IconComponent={ExpandMore}
                  onChange={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                    props.setState({
                      deviceLayout: e.target.value,
                      deviceOrientation: 'Landscape',
                    });
                  }}
                  style={{ display: 'flex' }}
                  onOpen={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  MenuProps={{
                    anchorEl: anchorEl,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  }}
                >
                  {['iPhone', 'Android'].map((datum) => {
                    return (
                      <MenuItem
                        value={datum}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <PhoneLandscapeIcon
                          style={{ width: 16, height: 16, margin: '6px 8px' }}
                        />{' '}
                        <span>{datum}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <PhoneLandscapeIcon className='gearIcon' />
              )}
            </div>
            {<div
              className={`option ${activeTab === 2 && 'active'}`}
              onClick={() => {
                setActiveTab(2);
                props.setState({ deviceLayout: 'iPad', deviceOrientation:"Portrait" });
                
              }}
            >
              {activeTab === 2 ? (
                <Select
                  defaultValue=''
                  id='grouped-select'
                  value={props.deviceLayout}
                  IconComponent={ExpandMore}
                  onChange={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                    props.setState({ deviceLayout: e.target.value, deviceOrientation: "Portrait" });
                  }}
                  onOpen={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  MenuProps={{
                    anchorEl: anchorEl,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  }}
                >
                  {['iPad', 'Windows', 'Tablet'].map((datum) => {
                    return (
                      <MenuItem
                        value={datum}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <TabletIcon
                          style={{ width: 16, height: 16, margin: '6px 8px' }}
                        />{' '}
                        <span>{datum}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                <TabletIcon className='gearIcon' />
              )}
            </div>}
            <div
              className={`option ${activeTab === 3 && 'active'}`}
              onClick={() => {
                setActiveTab(3);
                props.setState({ deviceLayout: 'iPad', deviceOrientation: "Landscape" });
              }}
            >
              {activeTab === 3 ? (
                <Select
                  defaultValue=''
                  id='grouped-select'
                  value={props.deviceLayout}
                  IconComponent={ExpandMore}
                  onChange={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                    props.setState({ deviceLayout: e.target.value, deviceOrientation: "Landscape" });
                  }}
                  onOpen={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                  MenuProps={{
                    anchorEl: anchorEl,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  }}
                >
                  {['iPad', 'Windows', 'Tablet'].map((datum) => {
                    return (
                      <MenuItem
                        value={datum}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <TabletIcon
                          style={{ width: 16, height: 16, margin: '6px 8px', transform: "rotate(-90deg)"}}
                        />{' '}
                        <span>{datum}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : (
                  <TabletIcon className='gearIcon' style={{ transform: "rotate(-90deg)" }} />
              )}
            </div>
          </>
        )}
        <div
          className={`option ${activeTab === 4 && 'active'}`}
          style={
            props.deploymentWebLayout ? { borderLeft: '1px solid #e0e0e0' } : {}
          }
          onClick={() => {
            setActiveTab(4);
            props.setState({ deviceLayout: 'Web' });
          }}
        >
          <LaptopIcon className='gearIcon' />
          {activeTab === 4 && <span>Web</span>}
        </div>
      </div>
      {showUndoRedo && (
        <ButtonGroup color='light'>
          <Button
            className='option leftBorder'
            onClick={props.undoActions}
            title='undo'
            disabled={!pageViewPolicy.allowUpdate()}
          >
            <PreviousIcon className='gearIcon undoIcon' />
          </Button>
          <Button
            className='option rightBorder'
            onClick={props.redoActions}
            title='redo'
            disabled={!pageViewPolicy.allowUpdate()}
          >
            <NextIcon className='gearIcon redoIcon' />
          </Button>

          {/* Preview Hidden TDD 006(FR-01-1#) - Preview - BL
        <Button className="option" onClick={() => props.setState({ previewOpen: true })}
          disabled={!isViewOnly()}
        >
          {
            isViewOnly() &&
            <PreviewIcon className="gearIcon" />
          }
          <span>Preview</span>
        </Button> */}
        </ButtonGroup>
      )}
    </Grid>
  );
};

export default AppDesignerRightTab;
