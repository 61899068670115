const required = (errors, name, value, msg) => {
    if (!value) {
        errors.set(name, msg)
        return false;
    }
    errors.clear(name)
    return true;
}

const validateComment = (errors, value) => {
    return required(errors, 'comment', value, "Should not be empty")
}

const validateNotes = (errors, value) => {
    return required(errors, 'notes', value, "Should not be empty")
}

const validateValue = (errors, name, value) => {
    switch (name) {
        case 'comment':
            return validateComment(errors, value)
        case 'notes':
            return validateNotes(errors, value)
        default:
            return true;
    }
}

const validateValues = (values, errors) => {
    let valid = true
    Object.keys(values).forEach((name) => {
        const value = values[name]
        if (!validateValue(errors, name, value))
            valid = false
    })
    return valid
}

export { validateValue, validateValues, required }