import SubHeader from "../../components/header/subheader";
import Schedulers from "../library/scheduler/scheduler";
import Variables from "../library/variables/variables";
import IntegrationBuilder from "../../../containers/integration-builder";
import { SchedulerProvider } from "../library/scheduler/schedulerContext";
import Services from "../library/services/services";
import { Route, Switch } from "react-router-dom";
import Logins from "../library/logins/logins";
import Assets from "../library/assets/assets";
import Database from "../database/database";
import NotFound from "../not-found";
import Drive from "../drive";
import React from "react";
import "../../App.css";
import { getPermissions } from "../../common/utils/utils";

const Library = () => {
  const links = getPermissions("Library")?.childPermissions;

  return (
    <div>
      <SubHeader links={links} />
      <Switch>
        <Route exact path={"/Library/database"} render={()=><Database perm={links.find((e)=>e.name==="Database Tables")}/>} />
        <Route exact path={"/Library/databasetables"} render={()=><Database perm={links.find((e)=>e.name==="Database Tables")}/>}/>
        <Route exact path={"/Library/shareddrive"} render={()=><Drive perm={links.find((e)=>e.name==="Shared Drive")}/>} />
        <Route exact path={"/Library/assets"} render={()=><Assets perm={links.find((e)=>e.name==="Assets")}/>} />
        <Route exact path={"/Library/variables"} render={()=><Variables perm={links.find((e)=>e.name==="Variables")}/>} />
        <Route exact path={"/Library/logins"} render={()=><Logins perm={links.find((e)=>e.name==="Logins")}/>} />
        <Route exact path={"/Library/services"} render={()=><Services perm={links.find((e)=>e.name==="Services")}/>} />
        <Route exact path={"/Library/scheduler"} render={()=><SchedulerProvider perm={links.find((e)=>e.name==="Scheduler")}/>} />
        <Route exact path={"/Library/services/:builderId"} render={()=><IntegrationBuilder perm={links.find((e)=>e.name==="Services")}/>} />
        <Route exact path={"/Library/"} render={()=><Database perm={links.find((e)=>e.name==="Database Tables")}/>} />
        <Route exact path={"*"} component={NotFound } />
      </Switch>
    </div>
  );
}

export default Library;