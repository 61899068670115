import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    modalTitle: {
        fontSize: 40,
        lineHeight: '48px',
        color: '#000000',
        backgroundColor: '#fff'
    },
    modalSubTitle: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#000000',
        backgroundColor: '#fff',
        paddingBottom:'0px'
    },
    desc:{
     marginTop:theme.spacer*2
    },
    modalLabel: {
        fontSize: 13,
        lineHeight: '24px',
        color: '#000000'
    },
    mb8: {
        marginBottom: 8
    },
    mb32: {
        marginBottom: 32
    },
    mt16: {
      width:"645px",
      margin:"16px auto"
    },
  newFunctionSelectionContainer : {
      display: 'flex',
      minHeight: '100%',
      alignItems: 'center',
    '& > .grid-2' : { 
        margin: "0",
        // marginTop: theme.spacer * 4,
        textAlignLast: "center",
        '& .img-row': {
          height: theme.spacer * 22,
          display: "inherit"
        },
        '& .text-row': { 
            justifyContent: "space-evenly", 
            height: "30%" ,
           '& h5' :{
                fontWeight: "500",
                marginBottom: "0px",
                fontSize: "16px"
            },
            '& p': {
                width: "92%",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: theme.spacer * 3+'px',
                marginLeft:'auto',
                marginRight:'auto',
            }
        },
        '& .btn-row':{
            justifyContent: 'center',
           '& button' :{
                fontSize:'13px',
                width: "162px"
            }
        }
        
      }
    },
    newFunctionTemplateContainer:  {
        "& .grid-3": {
            position: "absolute",
            overflow: "scroll",
            maxHeight:theme.spacer * 46 ,
            marginLeft:theme.spacer * -4 ,
            '& .template-card-container':{
                display: 'flex',
                flexWrap: 'wrap',
                paddingLeft: 0,
                paddingRight: 0,
            
            }
          },
        '& .grid-2': {
            marginLeft: theme.spacer * -3,
            marginRight: theme.spacer * -4
         }
    },
   newFunctionCreateappContainer : {
       '& .grid-1' : {
        justifyContent: 'space-evenly'
    },
        "& .grid-2": {display:'flex', marginTop: theme.spacer * 9 , justifyContent: "space-around" }
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacer * 7,
  },
  descRow: {
    width: '645px',
    display: 'flex',
    justifyContent: 'center',
    margin: '54px auto 0',
    textAlign: 'center',
    fontSize: theme.spacer * 2,
    lineHeight: theme.spacer * 3 + 'px',
  },
}));