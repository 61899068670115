import ActivityIndicator from '../../../components/activity/ActivityIndicator';
import { loginsList, createLogin } from '../../../api/library/logins/logins';
import messages from './loginsMessages';
import React from 'react';

export function LoginsActions({ show, close }){
  const getList = async () =>{
    show(()=><ActivityIndicator text={messages.loading}/>)
    const [error, data] = await loginsList();
    close()
    if(error)
      return [error, data];
    if(data.code >= 400)
      return [data.message, data];
    return [null,data];
  }

  const createNewLogin = async (payload) => {
    show(()=><ActivityIndicator text={messages.creating}/>)
    const [error, data] = await createLogin(payload);
    close()
    if(error)
      return [error, data];
    if(data.code >= 400)
      return [data.message, data];
    return [null,data];
  }
  
  return {
    getList, createNewLogin
  }
}