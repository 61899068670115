import store from '../stores';
import {
  getProjectList,
  getAccordionData,
  loadingProjects,
  loadingAccordionData,
  saveIsRejected,
  saveIsAccepted,
  updateVersion
} from '../actions/create-project-details';
import DataService from '../utils/data-service';
import { showNotification } from '../actions/app-root';
import { getEnvironment,s3Url } from '../utils/common';


export function alertShow(data) {
  if(data.message != undefined && data.message.length > 0)
  {
    var param = {
      message: data.message,
      show: true,
      type: data.type.toLowerCase()
    }
    store.dispatch(showNotification(param));
  }
}

export function fetchProjectsList() {
  let params = {
    uri: `/json/projects.json`,
  };
  store.dispatch(loadingProjects());
  fetch(params.uri).then(
    (result) => {
      return result.json()
    },
  ).then(data => {
    store.dispatch(getProjectList(data));
  }).catch((error) => {
    // console.log('Project error: ', error);
  })
}

export function fetchAccordionData(project, 
  fName,
  screenType,
  bosUuid) {
  // let params = { uri: `/json/create-project-detail.json` };

  store.dispatch(loadingAccordionData());
  var params = {};

  if(screenType === "services")
  {
    params = {
      uri:
        `/baas/v1.0/baas/` +
        `listVersions?$bos_uuid=${bosUuid}` +
        `&$select=id,createdTimestamp,version,status,userName,comment`,
      data: {},
    };
  } 
  else 
  {
    params = {
      uri: `/businessfunction/v1.0/function/listVersions?project_name=${project}&function_name=${fName}&$select=id,comment,createdTimestamp,projectId,version,status,userName`,
    };
  }

  // if(limit) {
  //   params.uri = `${params.uri}&limit=${limit}`
  // }

  DataService.read(params).then(
    (result) => {
      var data = result.data.data;

      store.dispatch(getAccordionData(data));
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}


export function rejectSelectedVersion(project, fName, version, reasons) {
  let params = {
    uri: `/businessfunction/v1.0/function/rejectVersion?project_name=${project}&function_name=${fName}&version=${version}`,
    data: {
      reason: reasons.reason1
    }
  };


  DataService.update(params).then(
    (result) => {
      if (result.status === 200) {
        let isSuccess = {
          status: result.data.status.type === 'S' ? 'success' : 'error',
          message: result.data.status.message,
        };
        store.dispatch(saveIsRejected(isSuccess))
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );

}

export function updateSelectedVersion(data) {

  var postData = {
    status: data.status,
    comment: data.comment
  }

  if (data.status === "Rejected") {
    postData = {
      status: data.status,
      comment: data.comment,
      reason: data.reason
    }
  }

  var params = {};

  if(data.screenName === "services")
  {
    params = {
      uri: `/baas/v1.0/baas/${data.bosUuid}/updateVersion?&project_name=${data.pName}&version=${data.version}`,
      data: postData
    };
  }
  else
  {
    params = {
      uri: `/businessfunction/v1.0/function/updateVersion?function_name=${data.bfName}&project_name=${data.pName}&version=${data.version}`,
      data: postData
    };
  }


  DataService.update(params).then(
    (result) => {
      if (result.status === 200) {
        var environment = getEnvironment(sessionStorage.getItem('env'))
        if((data.status === "Rejected" && result.data.status.code === 1) || (data.status === "Active" && result.data.status.code === 1) || (data.status === "Approved" && environment !== "Production")) {
          
        }
        else
        {
          alertShow(result.data.status); 
        }
        if(data.status === 'Active' && !result.data.status.code){
          let param = { ...result.data.status, type: 'error' }
          alertShow(param);
        }
        store.dispatch(updateVersion(result.data))
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );

}

export function clearDeleteResponse(type) {
  if (type == "accept") {
    store.dispatch(updateVersion({}))
  }

}


export function transportVersion(project, fName, version) {
  let params = {
    uri: `/businessfunction/v1.0/function/${fName}/transportFunction?project_name=${project}&version=${version}`,
  };

  DataService.create(params).then(
    (result) => {
      if (result.status === 200) {
        let isSuccess = {
          status: result.data.status.type === 'S' ? 'success' : 'error',
          message: result.data.status.message,
        };
        store.dispatch(saveIsAccepted(isSuccess))
      }
    },
    (error) => {
      // console.log('Project error: ', error);
    }
  );
}

export  function addOrRemoveLanguage(project,fName,version, payload = {},cb=()=>{}) {
  let params = {
    uri: `/businessfunction/v1.0/function/${fName}/addLanguage?project_name=${project}&version=${version}`,
    data:payload,
  };
  DataService.update(params).then(
    (result) => {
      if (result.status === 200) {
          cb(result.data.status);
          //alertShow(result.data.status); 
      }
    },
    (error) => {
      // console.log('Project error: ', error);
      // alertShow({ status:'error', message:error}); 
    }
  );


}

export function getUsedLanguage(project,fName,version,cb=()=>{}) {
  //let path = `${getUrl()}/businessfunction/v1.0/function/newtest/getLanguage?project_name=${projectName}&version=4.0`;
  let params = {
    uri: `/businessfunction/v1.0/function/${fName}/getLanguage?project_name=${project}&version=${version}`,
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      cb(data.detail);
    },
    (error) => {
      alertShow({ status:'error', message:error}); 
    }
  );

}

export  function readLanguage(project,fName,version,language_code,cb=()=>{}) {
  let params = {
    uri:`/businessfunction/v1.0/function/${fName}/${language_code}/readLanguage?project_name=${project}&version=${version}`
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data;
      let out={};
      DataService.download({uri:s3Url(data.uuid)}).then(
        (r)=>{
          out["en"]=r.data;
          DataService.download({uri:s3Url(data.url)}).then(
            (r2)=>{
              out[language_code]=r2.data;
              cb(out);
            },
            (e2)=>{
    
            }
          );
        },
        (e)=>{

        }
      );
    },
    (error) => {
      alertShow({ status:'error', message:error}); 
    }
  );
}

export  function modifyLanguage(project,fName,version,language_code, payload,cb=()=>{}) {
  let params = {
    uri: `/businessfunction/v1.0/function/${fName}/${language_code}/modifyLanguage?project_name=${project}&version=${version}`,
    data:payload 
  };
  DataService.update(params).then(
    (result) => {
      if (result.status === 200) {
        cb(result.data.status); 
      }
    },
    (error) => {
      cb({ status:'error', message:error}); 
    }
  );
}

export function getLanguageList(cb = ()=>{ }){
  let params={
    uri:`/configurator/v1.0/masters/values/LAN/list`
  };
  DataService.read(params).then(
    (result) => {
      var data = result.data.data;
      cb(data);
    },
    (error) => {
      alertShow({ status:'error', s:error}); 
    }
  );
}