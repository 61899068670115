import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useState } from 'react';
import styles from "../services.module.css";

export default function MetadataOption(props) {
    const { data } = props;
    const [optionData, setOptionData] = useState(data);

    const expandSubOption = (option) => {
        setOptionData(val => ({ ...val, showContent: !val.showContent }))
    }

    return (
        <div className={styles.subOptionCont} key={optionData.name}>
            <div className={styles.subOption} onClick={() => expandSubOption(optionData.name)}>
                <span>{optionData.name}</span>
                {optionData.showContent ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {optionData.showContent ? <div className={styles.subOptionContent}>
                <span>{optionData.type}</span>
            </div> : ""}
        </div>
    )
}
