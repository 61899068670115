import { ChangeCommand, CommentCommand, CopyCommand, CreateNodeCommand, ExpandCommand, MinimizeCommand, UnCommentCommand } from "../js/commands";
import { Property } from "../js/functions";
import React from "react";

export class deleteNode {
	execute() {
		let node = window.application.selectedNode;
		let object = window.application.selectedObj;
		if (object){
			if (object.type === 'node'){
				if (object.node.type.startsWith('db') || object.node.type.startsWith('sf') || object.node.type === 'func' || object.node.type === 'rpr'){
					window.application.ui.removeSystem(object.node.system.system);
				}
				object.node.delete();
			} else{
				if (object.property instanceof Property){
					let prev_val = object.property.value;
					object.property.setValue('');
					window.application.addCommand(new ChangeCommand(object.property, ['value'], 
						prev_val,[object.property.value]));
				}
				else if (object.propertyKey && object.position){
					let prev_val = [object.node[object.propertyValue][object.position][object.propertyKey]];
					object.node[object.propertyValue][object.position][object.propertyKey] =  '';
					window.application.addCommand(new ChangeCommand(object.node[object.propertyValue][object.position], [object.propertyKey], 
						prev_val,[object.node[object.propertyValue][object.position][object.propertyKey]]));
				}
				else if (object.propertyKey){
					let prev_val = [object.node[object.propertyValue][object.propertyKey]];
					object.node[object.propertyValue][object.propertyKey] =  '';
					window.application.addCommand(new ChangeCommand(object.node[object.propertyValue], [object.propertyKey], 
						prev_val,[object.node[object.propertyValue][object.propertyKey]]));
				}
				else{
					let prev_val = [object.node[object.propertyValue]];
					object.node[object.propertyValue] = '';
					window.application.addCommand(new ChangeCommand(object.node, [object.propertyValue], 
						prev_val,[object.node[object.propertyValue]]));
				}
			}
			window.application.refresh();
			window.application.selectedObj = null;
		}
		else if(node){
			if(node.type==="fun"){
				if ((node.type.startsWith('db') || node.type.startsWith('sf') || node.type === 'func' || node.type === 'rpr') 
						&&  node.system && node.system.system){
					window.application.ui.removeSystem(node.system.system);
				}
				node.delete();
			}else{
				let parent=node.parent;
				if(parent){
					if ((node.type.startsWith('db') || node.type.startsWith('sf') || node.type === 'func' || node.type === 'rpr')
							&&  node.system && node.system.system){
						window.application.ui.removeSystem(node.system.system);
					}
					if(node.type === 'boss'){
						window.application.ui.removeServices(node.uuid);
					}
					if (parent.type === 'ie'){
						if (node.ifChildType === 'else')
							window.application.deleteNode(parent.elseChilds,node);	
						else
							window.application.deleteNode(parent.ifChilds,node);	
					}	
					else
						window.application.deleteNode(parent.childs,node);
				}
				else{
					// window.application.deleteNode(window.application.ui.nodeList,node);
				}
			}
		}
	}
}

export class minimizeEvent {
	execute() {
		const node = window.application.selectedNode;
		if(node){
			node.minimize=1;
		}
		window.application.addCommand(new MinimizeCommand(node));
		window.application.refresh();
		window.application.hideContextMenu();
	}
}
export class expandEvent {
	execute() {
		const node = window.application.selectedNode;
		if(node){
			node.minimize=0;
		}
		window.application.addCommand(new ExpandCommand(node));
		window.application.refresh();
		window.application.hideContextMenu();
	}
}

export class commentEvent {
	execute() {
		const node = window.application.selectedNode;
		if(node){
			node.isCommented=1;
			window.application.addCommand(new CommentCommand(node));
			window.application.refresh();
			window.application.hideContextMenu();
		}
	}
}

export class uncommentEvent {
	execute() {
		const node = window.application.selectedNode;
		if(node){
			node.isCommented=0;
			window.application.addCommand(new UnCommentCommand(node));
			window.application.refresh();
			window.application.hideContextMenu();
		}
	}
}

export class cutEvent {
	execute() {
		if (window.application.selectedNode && window.application.selectedNode.parent) {
			window.application.copyNode = window.application.selectedNode;
			let parent=window.application.selectedNode.parent;
			if(parent){
				parent.deleteChild(window.application.selectedNode);
			}
			else{
				window.application.deleteNode(window.application.ui.nodeList,window.application.selectedNode);
			}
			window.application.refresh();
			window.application.hideContextMenu();
		}
	}
}

export class copyEvent {
	execute() {
		if (window.application.selectedNode) {
			const cpnode = window.application.selectedNode.copy();
			window.application.copyNode = cpnode;
			window.application.addCommand(new CopyCommand(window.application.copyNode));
			window.application.hideContextMenu();
		}
	}
}
export class undoEvent {
	execute() {
		window.application.commands.undo();
		window.application.refresh();
		window.application.save();
	};
}
export class redoEvent {
	execute() {
		window.application.commands.redo();
		window.application.refresh();
		window.application.save();
	}
}

export class pasteEvent {
	execute() {
		if (window.application.selectedNode) {
			const node = window.application.copyNode;
			if (node && window.application.selectedNode.haveChild) {
				window.application.selectedNode.addChild(node, window.application.selectedNode.childs.length );
				node.parent=window.application.selectedNode;
				if(node.type == 'rc' || node.type == 'rpr')
					node.lane=node.type;
				else
					node.lane = window.application.selectedNode.lane;
				const index=node.parent.childs.indexOf(node);
				window.application.addCommand(new CreateNodeCommand(node,index));
				window.application.refresh();
			} else {
				window.application.handleError("Error", "E010");
			}
			window.application.hideContextMenu();
			window.application.copyNode=null;
		} 
	}
}