import React, { useEffect, useState } from 'react';
import image1 from '../../../assets/images/info-image1.svg';
import image2 from '../../../assets/images/info-image2.svg';
import image3 from '../../../assets/images/creating-app.svg';
import image4 from '../../../assets/images/info-image1.svg';
import { apmMessage } from '../../../common/messages/apm';
import { configMessage } from '../../../common/messages/config';
import Template from '../../../views/template-card/index';
import Dialog from '../../../views/dialog/index';
import { withStyles } from '@material-ui/styles';
import useStyle from './style';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  InputLabel,
  InputBase,
  FormGroup,
  FilledInput,
} from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '4px 8px',
  },
}))(InputBase);

function NewFunction(props) {
  const [pageNo, setPageNo] = useState(0);
  const [input, setInput] = useState({
    name: props.businessFunction ? props.businessFunction.name : '',
    desc: props.businessFunction ? props.businessFunction.description : '',
  });
  const [namealertMsg, setnamealertMsg] = useState('');
  const [descalertMsg, setdescalertMsg] = useState('');
  const [checked, setChecked] = useState(false);
  const classes = useStyle();

  const pageRedirection = (e) => {
    setPageNo(e);
  };

  const clearForm = () => {
    setPageNo(0);
    setChecked(false);
    setInput({
      name: props.businessFunction ? props.businessFunction.name : '',
      desc: props.businessFunction ? props.businessFunction.description : '',
    });
    setdescalertMsg('');
    setnamealertMsg('');
  };

  const closeDialogBox = (e) => {
    clearForm();
    props.clicked();
  };

  useEffect(() => {
    setInput({
      name: props.businessFunction ? props.businessFunction.name : '',
      desc: props.businessFunction ? props.businessFunction.description : '',
    });
  }, [props.businessFunction]);

  useEffect(() => {
    if (props.creatingFunction) {
      if (props.creatingFunction.isCreating && input.name)
        pageRedirection('create_app');
      else if (props.creatingFunction.status === 'success') {
        if (props.clearCreateFunction) {
          props.clearCreateFunction();
        }
        props.reloadFunctionList(input.name, props.show);
        clearForm();
        props.clicked();
      } else if (props.creatingFunction.status === 'error') {
        setnamealertMsg(props.creatingFunction.message);
        pageRedirection(0);
      }
    }
  }, [props.creatingFunction]);

  useEffect(() => {
    if (props.type === undefined) {
      pageRedirection(0);
    }
    if (props.type !== 'editFunction') {
      if (props.isExistingName.status === 'success') {
        pageRedirection('selection_page');
      } else {
        setnamealertMsg(props.isExistingName.message);
      }
    }
  }, [props.isExistingName]);

  const handleOnchange = (e) => {
    let value = e.currentTarget.value;
    if (e.currentTarget.id === 'name') {
      if (value.trim().length == 0) {
        value = '';
      } else if (value.length != 0) {
        value = isAlphanumeric(value)
          ? value.replace(/[^\w\s-]+/g, '')
          : input.name;
      }

      setnamealertMsg('');
    }
    if (e.currentTarget.id === 'desc') setdescalertMsg('');

    setInput({
      ...input,
      [e.currentTarget.id]: value,
    });
  };

  const isAlphanumeric = (value, type) => {
    // alpanumeric regex validtion
    // Alphanumeric and should not start with space _ -
    if (/^[A-Za-z](?:[A-Za-z0-9\s-_]*)?$/.test(value)) {
      return true;
    }
    return false;
  };

  const validateform = (e) => {
    if (input.name !== '' && input.desc !== '') {
      if (isAlphanumeric(input.name)) {
        if (props.type === 'editFunction') {
          let payl = { ...input, name: input.name.trim() };
          props.editBusinessFunction(payl);
        } else {
          props.checkExistingName(input.name.trim());
          // pageRedirection("selection_page");
        }
      } else {
        setnamealertMsg(isAlphanumeric(input.name) ? '' : configMessage.T4556);
      }
    } else if (input.name !== '') {
      setnamealertMsg(isAlphanumeric(input.name) ? '' : configMessage.T4556);
      setdescalertMsg(configMessage.T4555);
    } else if (input.desc !== '') {
      setnamealertMsg(configMessage.T4554);
    } else if (input.name === '' && input.desc === '') {
      setnamealertMsg(configMessage.T4553);
      setdescalertMsg(configMessage.T4553);
    }
  };

  const createBusinessFunction = (src, type = '') => {
    input.name = input.name.trim();
    props.createBusinessFunction(input, type, src);
  };

  const container = () => {
    if (pageNo === 0)
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <FormGroup>
              <Box>
                <InputLabel htmlFor='name' className='nameLayout'>
                  {' '}
                  Name{' '}
                </InputLabel>
                <FilledInput
                  type='text'
                  id='name'
                  value={input.name}
                  autoFocus
                  disabled= {props.type === 'editFunction'?true:false}
                  onChange={props.type === 'editFunction'?null:handleOnchange}
                  inputProps={{ maxLength: 45 }}
                  disableUnderline
                />
                <small className='red'>{namealertMsg}</small>
              </Box>
              <Box className='descBoxLayout'>
                <InputLabel htmlFor='description' className={classes.desc}>
                  Description
                </InputLabel>
                <FilledInput
                  type='text'
                  id='desc'
                  className='descriptionTextBox'
                  value={input.desc}
                  onChange={handleOnchange}
                  inputProps={{ maxLength: 250 }}
                  disableUnderline
                  multiline
                  rows={3}
                  rowsMax={15}
                />
              </Box>
              <small className='text-muted red' style={{ marginTop: '2px' }}>
                {descalertMsg}
              </small>
            </FormGroup>
          </Grid>
        </Grid>
      );
    else if (pageNo === 'selection_page')
      return (
        <Container className={classes.newFunctionSelectionContainer}>
          <Grid className='grid-2' container>
            <Grid xs={6} md={6} item>
              <Box className='img-row'>
                <img src={image2} alt='' />
              </Box>
              <Box className='text-row'>
                <h5>{configMessage.T4560}</h5>
                <p>{configMessage.T4561}</p>
              </Box>
              <Box className='btn-row'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setPageNo("template_page")}
                >
                  {configMessage.T4562}
                </Button>
              </Box>
            </Grid>
            <Grid xs={6} md={6} item>
              <Box className='img-row'>
                <img src={image1} alt='' />
              </Box>
              <Box className='text-row'>
                <h5>{configMessage.T4563}</h5>
                <p>{configMessage.T4564}</p>
              </Box>
              <Box className='btn-row'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => createBusinessFunction('ABAP')}
                  // onClick={() => props.setAbapFlow(true)}
                  disabled={false}
                >
                  {configMessage.T4565}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      );
    else if (pageNo === 'template_page')
      return (
        <Grid xs={12} justify='center' container>
          <Grid xs={6}>
            <Box className={classes.imgRow}>
              <img src={image4} alt='' />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className={classes.descRow}>{apmMessage.T3512.desc}</Box>
          </Grid>
        </Grid>
      );
    else if (pageNo === 'create_app')
      return (
        <Container className={classes.newFunctionCreateappContainer}>
          <Box className='grid-2'>
            <img src={image3} alt='' />
          </Box>
        </Container>
      );
  };

  const footer = () => {
    if (pageNo === 0)
      return (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={pageRedirection.bind(this)}
            disabled
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => validateform(e)}
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={pageRedirection.bind(this, 0)}
          >
            Back
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={pageRedirection.bind(this)}
            disabled
          >
            Next
          </Button>
        </Box>
      );
    else if (pageNo === 'template_page')
      return (
        <Box className='drive-new-folder-footer'>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => setPageNo('selection_page')}
          >
            Back{' '}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={(e) => createBusinessFunction('SCRATCH', 'APP')}
          >
            No{' '}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => createBusinessFunction('SCRATCH', 'WFM')}
          >
            Yes
          </Button>
        </Box>
      );
  };

  const modalTitle = () => {
    if (pageNo === 0)
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {props.type === 'editFunction'
              ? configMessage.T4559
              : configMessage.T4507}
          </Typography>
          <Box className={classes.mt16}>
            {props.type !== 'editFunction' && (
              <Typography className={classes.modalSubTitle}>
                {configMessage.T4508}
              </Typography>
            )}
          </Box>
        </Box>
      );
    else if (pageNo === 'selection_page')
      return (
        <Box className='grid-1'>
          <Typography className={classes.modalTitle}>{}</Typography>
        </Box>
      );
    else if (pageNo === 'template_page')
      return (
        <Box className={classes.mb32}>
          <Typography className={classes.modalTitle}>
            {apmMessage.T3512.title}
          </Typography>
        </Box>
      );
    else if (pageNo === 'create_app')
      return (
        <Box className='grid-1'>
          <Typography className={classes.modalTitle}>
            {configMessage.T4557}
          </Typography>
        </Box>
      );
  };
  return (
    <Dialog
      open={props.show}
      onClose={(e) => closeDialogBox(e)}
      dialogTitle={modalTitle()}
      dialogoContent={container()}
      dialogFooter={footer()}
      fullWidth={true}
      // maxWidth={pageNo ==="enable_workflow" ? "sm" : "md"}
      maxWidth={'md'}
    />
  );
}

export default NewFunction;
